import { ca } from '@cian/analytics';

import { IAppState, TActions } from '../../../common/state';

export interface IOfferComplaintAddAction {
  type: 'serp/offer_card/COMPLAINT_ADD';
  offerId: number;
  name: string;
  message?: string;
}

export function addOfferComplaint(offerId: number, name: string, message?: string): IOfferComplaintAddAction {
  return {
    type: 'serp/offer_card/COMPLAINT_ADD',
    offerId,
    name,
    message,
  };
}

export function offerComplaintsReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'serp/offer_card/COMPLAINT_ADD':
      ca('event', {
        name: 'oldevent',
        category: 'Listing',
        action: 'complain',
        label: 'send',
      });

      return {
        ...state,
        results: {
          ...state.results,
          offers: state.results.offers.map(offer => {
            let newOffer = offer;

            if (newOffer.cianId === action.offerId || newOffer.id === action.offerId) {
              newOffer = Object.assign({}, offer);

              newOffer.notes = Object.assign({}, newOffer.notes);
              newOffer.notes.offer = {
                text: `Жалоба: ${action.name}${(action.message && `. ${action.message}`) || ''}`,
                timestamp: new Date().toISOString(),
              };
            }

            return newOffer;
          }),
        },
        simplifiedOfferCardPopupsState: {},
      };
    default:
      return state;
  }
}
