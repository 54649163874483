import { ca } from '@cian/analytics';

import { IUserGADataLayerData } from 'shared/common/state';
import { offerHelper } from 'shared/offer/presenters';
import { IJsonQuery } from 'shared/repositories/common/json_query';
import { IOffer } from 'shared/repositories/search-offers.legacy/v2/types';
import { TUser } from 'shared/types/user';
import { normalizeAbGroup } from 'shared/utils/normalizeAbGroup';
import { getOfferListingType } from 'shared/utils/offer/tracking';
import { getOfferParentId } from 'shared/utils/trackings';

export interface ITrackFirstMessageSentWithProductsParams {
  offer: IOffer;
  user: TUser;
  userGALayerData: IUserGADataLayerData | null;
  jsonQuery: IJsonQuery;
  position: number;
  pageNum?: number;
  queryString: string;
  offersQty: number;
}

export interface IParams {
  position: number;
  offer: IOffer;
  jsonQuery: IJsonQuery;
}

export const trackFirstMessageSentWithProducts =
  (params: ITrackFirstMessageSentWithProductsParams) => (trackingLabel?: string | null, chatId?: string) => {
    const { offer, jsonQuery, queryString, pageNum, offersQty, user, userGALayerData, position } = params;
    const { id } = offerHelper(offer).getAnalyticsInfo();
    const parentId = getOfferParentId(offer, jsonQuery);
    let extra = {};

    if (parentId) {
      extra = {
        parentId,
      };
    }

    ca('eventSite', {
      name: 'oldevent',
      ec: 'Message_popup',
      ea: 'send_message',
      el: chatId,
      modelVersion: offer.modelVersion !== null ? String(offer.modelVersion) : undefined,
      products: [
        {
          id,
          offerType: 'offer',
          position: Number(position),
          extra,
        },
      ],
      page: {
        listingType: getOfferListingType(offer),
        pageNumber: Number(pageNum || 1),
        queryString,
        offersQty,
        pageType: 'Listing',
        siteType: 'desktop',
      },
      user: {
        userId: user && user.isAuthenticated && user.userId ? Number(user.userId) : undefined,
        abGroup: normalizeAbGroup(userGALayerData && userGALayerData.abGroup),
      },
    });
  };

export function trackFirstMessageSent(trackingLabel?: string | null, chatId?: string) {
  ca('event', {
    action: 'send_message',
    category: 'Message_popup',
    label: chatId,
    name: 'oldevent',
    customUrl: trackingLabel || undefined,
  });
}

export function trackFirstMessageClick(params: IParams, trackingLabel?: string | null) {
  const { position, offer, jsonQuery } = params;

  ca('eventSite', {
    action: 'click',
    category: 'Listing',
    label: 'message',
    name: 'oldevent',
    customPageURL: trackingLabel || undefined,
    searchConditions: jsonQuery,
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position,
      },
    ],
  });
}

export function trackFirstMessageOpen(params: IParams, trackingLabel?: string) {
  const { position, offer, jsonQuery } = params;

  ca('eventSite', {
    action: 'open',
    category: 'Message_popup',
    name: 'oldevent',
    customUrl: trackingLabel,
    searchConditions: jsonQuery,
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position,
      },
    ],
  });
}
