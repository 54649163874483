import { ICoordinatesOffers, ISuggestionsDistances } from '../serp/state/suggestions';
import { IMakeRequest } from '../utils/request';

interface ISuggestionsGeoResponse {
  data: {
    offerDistances: ISuggestionsDistances[];
  };
  status: 'ok';
}

export interface ISuggestionsDistancesReqBody {
  queryString: string;
  coordinatesOffers: ICoordinatesOffers[];
}

export function getSuggestionsDistances(
  makeRequest: IMakeRequest,
  reqBody: ISuggestionsDistancesReqBody,
): Promise<ISuggestionsDistances[] | null> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-qs-coordinates-distances/',
    parameters: reqBody,
    method: 'POST',
  })
    .then((res: ISuggestionsGeoResponse) => {
      if (res.status !== 'ok') {
        return null;
      }

      return res.data.offerDistances;
    })
    .catch(() => null);
}
