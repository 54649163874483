/**
 * @todo Удалить файл если эксперимент не заапрувится
 * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
 */
import { RemoveAllComponentCopiesService } from '../../../../serp/components/offers/CoworkingProfitBanner/internal/services/RemoveAllComponentCopiesService';

export class UserProfilingModalService extends RemoveAllComponentCopiesService {
  private static readonly WAS_USER_PROFILING_MODAL_CLOSED = 'wasUserProfilingModalClosed';

  public static get isUserProfilingModalWasShown(): boolean {
    const userProflingModalWasShown =
      sessionStorage.getItem(UserProfilingModalService.WAS_USER_PROFILING_MODAL_CLOSED) ?? 'false';

    return JSON.parse(userProflingModalWasShown);
  }

  public static setUserProfilingModalWasShown(): void {
    if (!UserProfilingModalService.isUserProfilingModalWasShown) {
      sessionStorage.setItem(UserProfilingModalService.WAS_USER_PROFILING_MODAL_CLOSED, 'true');
    }
  }
}
