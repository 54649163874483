import { call, fork, put, select, take } from 'redux-saga/effects';

import * as Agent from 'shared/api/agent';
import { IAppState, TActions } from 'shared/common/state';
import { IOffer } from 'shared/offer';

export interface IAgentRatingSaveRequestedAction {
  type: 'serp/offer_card/AGENT_RATING_SAVE_REQUESTED';
  offer: IOffer;
  rating: Agent.TAgentRating;
}

export interface IAgentRatingSaveFinishedAction {
  type: 'serp/offer_card/AGENT_RATING_SAVE_FINISHED';
  offer: IOffer;
  rating: Agent.TAgentRating;
}

export function updateAgentRating(offer: IOffer, rating: Agent.TAgentRating): IAgentRatingSaveRequestedAction {
  return {
    type: 'serp/offer_card/AGENT_RATING_SAVE_REQUESTED',
    offer,
    rating,
  };
}

const getChangedState = (state: IAppState, action: IAgentRatingSaveFinishedAction): IAppState => {
  return {
    ...state,
    results: {
      ...state.results,
      offers: state.results.offers.map(offer => {
        if (offer.user && action.offer.user && offer.user.cianUserId === action.offer.user.cianUserId) {
          const user = { ...offer.user };

          if (action.rating !== 'neutral') {
            user.personalRating = action.rating;
          } else {
            user.personalRating = null;
          }

          return Object.assign({}, offer, {
            user,
          });
        }

        return offer;
      }),
    },
  };
};

function* updateAgentRatingGenerator(offer: IOffer, rating: Agent.TAgentRating) {
  try {
    const state: IAppState = yield select();
    const { makeRequest } = state;

    yield call(Agent.updateRating, makeRequest, offer, rating);

    yield put({
      type: 'serp/offer_card/AGENT_RATING_SAVE_FINISHED',
      offer,
      rating,
    } as IAgentRatingSaveFinishedAction);
  } catch (error) {
    // Error
  }
}

export function* agentRatingSaga() {
  while (true) {
    const { offer, rating } = yield take('serp/offer_card/AGENT_RATING_SAVE_REQUESTED');
    yield fork(updateAgentRatingGenerator, offer, rating);
  }
}

export function agentRatingReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'serp/offer_card/AGENT_RATING_SAVE_REQUESTED': {
      const { user } = action.offer;
      if (!user) {
        return state;
      }

      return {
        ...state,
        results: {
          ...state.results,
          ratingBlockedAgents: state.results.ratingBlockedAgents.concat(user.cianUserId),
        },
      };
    }

    case 'serp/offer_card/AGENT_RATING_SAVE_FINISHED': {
      const { user } = action.offer;
      if (!user) {
        return state;
      }

      const newState = getChangedState(state, action);

      return {
        ...newState,
        results: {
          ...newState.results,

          ratingBlockedAgents: newState.results.ratingBlockedAgents.filter(agentId => agentId !== user.cianUserId),
        },
      };
    }

    default:
      return state;
  }
}
