/* eslint-disable max-lines */

import { TBuildingType, TPropertyType } from './commercial';

export type IJsonQueryType =
  | 'flatrent'
  | 'flatsale'
  | 'newobjectsale'
  | 'commercialrent'
  | 'commercialsale'
  | 'suburbanrent'
  | 'suburbansale';

export interface IJsonQueryTerm<T> {
  type: 'term';
  value: T;
}

export interface IJsonQueryTerms<T> {
  type: 'terms';
  value: T[];
}

export interface IJsonQueryRangeValue {
  gte?: number;
  lte?: number;
}

export interface IJsonQueryRange {
  type: 'range';
  value: IJsonQueryRangeValue;
}

export interface IJsonQueryGeo {
  type: 'geo';
  value: IGeoValue[];
}

export type IGeoValue =
  | IJsonQueryLocation
  | IJsonQueryHighway
  | IJsonQueryDistrict
  | IJsonQueryStreet
  | IJsonQueryHouse
  | IJsonQueryUnderground
  | IJsonQueryRailway
  | IJsonQueryDeveloper
  | IJsonQueryNewbuilding
  | IJsonQueryPolygon
  | IJsonQueryCircle;

export interface IJsonQueryLocation {
  type: 'location';
  id: number;
}

export interface IJsonQueryNewbuilding {
  type: 'newobject';
  id: number;
}

export interface IJsonQueryStreet {
  type: 'street';
  id: number;
}

export interface IJsonQueryHouse {
  type: 'house';
  id: number;
}

export interface IJsonQueryUnderground {
  type: 'underground';
  id: number;
}

export interface IJsonQueryRailway {
  type: 'railway';
  id: number;
}

export interface IJsonQueryDistrict {
  type: 'district';
  id: number;
}

export interface IJsonQueryHighway {
  type: 'highway';
  id: number;
}

export interface IJsonQueryDeveloper {
  type: 'builder';
  id: number;
}

export type TCoordinate = [string, string];

export interface IJsonQueryPolygon {
  type: 'polygon';
  name: string;
  coordinates: TCoordinate[];
}

export interface IJsonQueryCircle {
  type: 'distance';
  name: string;
  radius: number;
  center: TCoordinate;
}

export type TRentTime = '0' | '1' | '!1' | '2' | '*';
export const RentTime = {
  FromYear: '0' as const,
  Daily: '1' as const,
  FewMonths: '2' as const,
  NotDaily: '!1' as const,
  AnyTerm: '*' as const,
};

export type TBuildingStatus = 1 | 2;
export const BuildingStatus = {
  Old: 1 as const,
  New: 2 as const,
};

export type TPublishPeriod = 0 | -2 | 3600 | 86400 | 864000 | 2592000;
export const PublishPeriod = {
  Any: 0 as const,
  Today: -2 as -2,
  Hour: 3600 as const,
  Day: 86400 as const,
  TenDays: 864000 as const,
  Month: 2592000 as const,
};

export type THouseMaterial = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export const HouseMaterial = {
  Brick: 1 as const,
  Monolith: 2 as const,
  Panel: 3 as const,
  Block: 4 as const,
  Wood: 5 as const,
  Stalin: 6 as const,
  Shield: 7 as const,
  BrickMonolith: 8 as const,
};

export type TRepair = 1 | 2 | 3 | 4;
export const Repair = {
  Without: 1 as const,
  Cosmetic: 2 as const,
  Euro: 3 as const,
  Design: 4 as const,
};

export type TMaxPrepay = 0 | 1 | 2 | 3;
export const MaxPrepay = {
  Any: 0 as const,
  Month: 1 as const,
  TwoMonths: 2 as const,
  ThreeMonths: 3 as const,
};

export type TRoomType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
export const RoomType = {
  Room: 0 as const,
  FlatOneRoom: 1 as const,
  FlatTwoRooms: 2 as const,
  FlatThreeRooms: 3 as const,
  FlatFourRooms: 4 as const,
  FlatFiveRooms: 5 as const,
  FlatManyRooms: 6 as const,
  FlatFreeLayout: 7 as const,
  FlatShared: 8 as const,
  Studio: 9 as const,
  Bed: 10 as const,
};

export type TRoomsTotal = 0 | 2 | 3 | 4 | 5;
export const RoomsTotal = {
  Any: 0 as const,
  Two: 2 as const,
  Three: 3 as const,
  Four: 4 as const,
  Five: 5 as const,
};

export type TTenant = 1 | 2 | 3 | 4;
export const Tenant = {
  Any: 1 as const,
  Family: 2 as const,
  Woman: 3 as const,
  Man: 4 as const,
};

export type TBedroomsCount = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export const BedroomsCount = {
  Any: 0 as const,
  One: 1 as const,
  Two: 2 as const,
  Three: 3 as const,
  Four: 4 as const,
  Five: 5 as const,
  Six: 6 as const,
  Seven: 7 as const,
};

export type TParkingType = 1 | 2 | 3 | 4;
export const ParkingType = {
  Rooftop: 1 as const,
  Underground: 2 as const,
  Ground: 3 as const,
  Multilevel: 4 as const,
};

export type THeatingType = 1 | 2 | 3 | 4 | 5;
export const HeatingType = {
  None: 1 as const,
  Gas: 2 as const,
  Coal: 3 as const,
  Furnace: 4 as const,
  Fireplace: 5 as const,
};

export type TLandStatusType = 1 | 2 | 3 | 4 | 5 | 6;
export const LandStatusType = {
  Gardening: 1 as const,
  IndividualHousingConstruction: 2 as const,
  IndustrialLand: 3 as const,
  Farm: 4 as const,
  CountryhouseNonCommercialPartnership: 5 as const,
  InvestmentProject: 6 as const,
};

export type TWCType = 0 | 1 | 2;
export const WCType = {
  Any: 0 as const,
  United: 1 as const,
  Separated: 2 as const,
};

export type TSuburbanWCType = 0 | 1 | 2;
export const SuburbanWCType = {
  Any: 0 as const,
  Inside: 1 as const,
  Outside: 2 as const,
};

export type TWCCount = 0 | 1 | 2 | 3 | 4;
export const WCCount = {
  Any: 0 as const,
  One: 1 as const,
  Two: 2 as const,
  Three: 3 as const,
  Four: 4 as const,
};

export type TWindowsType = -1 | 0 | 1;
export const WindowsType = {
  Any: -1 as -1,
  Courtyard: 0 as const,
  Street: 1 as const,
};

export type TMetroTravelType = '-2' | '-1' | '0' | '1' | '2';
export const MetroTravelType = {
  Any: '0' as const,
  AgentFoot: '1' as const,
  AgentTransport: '-1' as const,
  MapFoot: '2' as const,
  MapTransport: '-2' as const,
};

export type TLayoutType = 0 | 1 | 2;
export const LayoutType = {
  Any: 0 as const,
  IsolatedRooms: 1 as const,
  UnitedRooms: 2 as const,
};

export type TGarageType = 0 | 1 | 2 | 3;
export const GarageType = {
  Any: 0 as const,
  CarPlace: 1 as const,
  Garage: 2 as const,
  Box: 3 as const,
};

export type TGarageKind = 1 | 2 | 3 | 4;
export const GarageKind = {
  Builtin: 1 as const,
  Capital: 2 as const,
  Shell: 3 as const,
  Selfbuilt: 4 as const,
};

export type TGarageMaterial = 1 | 2;
export const GarageMaterial = {
  Metal: 1 as const,
  Brick: 2 as const,
};

export type TGarageStatus = 1 | 2 | 3;
export const GarageStatus = {
  Cooperative: 1 as const,
  Ownership: 2 as const,
  Agreement: 3 as const,
};

export type TPriceType = 1 | 2;
export const PriceType = {
  SM: 1 as const,
  Total: 2 as const,
};

export type TSaleType = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;
export const SaleType = {
  Any: 0 as const,
  Free: 1 as const,
  Alternative: 2 as const,
  F3: 3 as const,
  Reassignment: 4 as const,
  GSKContract: 5 as const,
  PreliminaryContract: 6 as const,
  InvestmentContract: 7 as const,
};

export type TElevators = 0 | 1 | 2 | 3 | 4;
export const Elevators = {
  Any: 0 as const,
  One: 1 as const,
  Two: 2 as const,
  Three: 3 as const,
  Four: 4 as const,
};

export type TCurrencyType = 1 | 2 | 3;
export const CurrencyType = {
  Dollar: 1 as const,
  Rouble: 2 as const,
  Euro: 3 as const,
};

export type TObjectType = 1 | 2 | 3 | 4;
export const ObjectType = {
  House: 1 as const,
  Housepart: 2 as const,
  Land: 3 as const,
  Townhouse: 4 as const,
};

export type TOfficeType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 9 | 10 | 11 | 12;
export const OfficeType = {
  Office: 1 as const,
  TradeArea: 2 as const,
  Warehouse: 3 as const,
  PublicCatering: 4 as const,
  FreeAppointmentObject: 5 as const,
  Garage: 6 as const,
  Manufacture: 7 as const,
  AutoService: 9 as const,
  Business: 10 as const,
  Building: 11 as const,
  DomesticServices: 12 as const,
};

export type TCategoryType = 'commercialLandRent' | 'commercialLandSale';
export const CategoryType = {
  CommercialLandRent: 'commercialLandRent' as const,
  CommercialLandSale: 'commercialLandSale' as const,
};

export type TBuildingClassType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;
export const BuildingClassType = {
  A: 1 as const,
  APlus: 2 as const,
  B: 3 as const,
  BPlus: 4 as const,
  C: 5 as const,
  CPlus: 6 as const,
  D: 7 as const,
  BMinus: 8 as const,
};

export type TBuildingElevatorType = 1 | 2 | 3;
export const BuildingElevatorType = {
  Service: 1 as const,
  Telpher: 2 as const,
  Passenger: 3 as const,
};

export type TBuildingCraneType = 1 | 2 | 3 | 4;
export const BuildingCraneType = {
  Overhead: 1 as const,
  Beam: 2 as const,
  Railway: 3 as const,
  Goat: 4 as const,
};

export type TBuildingGatesType = 1 | 2 | 3;
export const BuildingGatesType = {
  Ramp: 1 as const,
  Dock: 2 as const,
  Ground: 3 as const,
};

export type TBuildingHeatingType = 1 | 2 | 3;
export const BuildingHeatingType = {
  Without: 1 as const,
  Central: 2 as const,
  Autonomous: 3 as const,
};

export type TPlacementType = 1 | 2;
export const PlacementType = {
  ShoppingMall: 1 as const,
  StreetRetail: 2 as const,
};

export type TEntranceType = 1 | 2 | 3 | 4;
export const EntranceType = {
  SeparateCourtyard: 1 as const,
  SeparateStreet: 2 as const,
  CommonCourtyard: 3 as const,
  CommonStreet: 4 as const,
};

export type TConditionType = 1 | 2 | 3 | 4 | 5 | 6;
export const ConditionType = {
  OfficeDecoration: 1 as const,
  FullDecorationNeeded: 2 as const,
  MajorRepairNeeded: 3 as const,
  CosmeticRepairNeeded: 4 as const,
  RegularRepair: 5 as const,
  DesignerRepair: 6 as const,
};

export type THouseLineType = 1 | 2;
export const HouseLineType = {
  First: 1 as const,
  Second: 2 as const,
};

export type TEstateType = 1 | 2;
export const EstateType = {
  Owned: 1 as const,
  Rent: 2 as const,
};

export type TContractType = 1 | 2 | 3 | 4;
export const ContractType = {
  DirectRent: 1 as const,
  SubRent: 2 as const,
  PermTransfer: 3 as const,
  ObjSale: 4 as const,
};

export type TSortQuality = 0 | 1 | undefined;

export type TSortValue =
  | 'default'
  | 'price_object_order'
  | 'price_square_order'
  | 'area_order'
  | 'site_order'
  | 'walking_time'
  | 'mcad'
  | 'street_name'
  | 'id_user';

export const EOfferCategory = {
  AutoService: 'autoService',
  Bedroom: 'bedroom',
  Building: 'building',
  CommercialLand: 'commercialLand',
  DomesticServices: 'domesticServices',
  Flat: 'flat',
  FlatNew: 'flatNew',
  FlatOld: 'flatOld',
  FreeAppointmentOffice: 'freeAppointmentOffice',
  Garage: 'garage',
  House: 'house',
  Housepart: 'housepart',
  Manufacture: 'manufacture',
  Office: 'office',
  Part: 'part',
  PublicCatering: 'publicCatering',
  ReadyMadeBusiness: 'readyMadeBusiness',
  Room: 'room',
  Storehouse: 'storehouse',
  Townhouse: 'townhouse',
  Tradearea: 'tradearea',
};

export type TFacility =
  | 'has_furniture'
  | 'has_fridge'
  | 'has_washer'
  | 'has_kitchen_furniture'
  | 'internet'
  | 'tv'
  | 'conditioner'
  | 'dish_washer'
  | 'phone'
  | 'bath'
  | 'shower'
  | 'kids'
  | 'pets'
  | 'bathhouse'
  | 'garage'
  | 'pool'
  | 'security';
export const Facility: {
  [index: string]: TFacility;
} = {
  Bath: 'bath' as const,
  Conditioner: 'conditioner' as const,
  Dishwasher: 'dish_washer' as const,
  Fridge: 'has_fridge' as const,
  Garage: 'garage' as const,
  Internet: 'internet' as const,
  KidsAllowed: 'kids' as const,
  KitchenFurniture: 'has_kitchen_furniture' as const,
  PetsAllowed: 'pets' as const,
  Phone: 'phone' as const,
  Pool: 'pool' as const,
  Sauna: 'bathhouse' as const,
  Security: 'security' as const,
  Shower: 'shower' as const,
  TV: 'tv' as const,
  Washer: 'has_washer' as const,
};

export type TSuburbanUtility = 'gas' | 'electricity' | 'has_drainage' | 'has_water';
export const SuburbanUtility: {
  [index: string]: TSuburbanUtility;
} = {
  Gas: 'gas' as const,
  Electricity: 'electricity' as const,
  Drainage: 'has_drainage' as const,
  Water: 'has_water' as const,
};

export interface IJsonQueryTermsParams {
  bs_center?: IJsonQueryTerms<number>;
  building_class_type?: IJsonQueryTerms<TBuildingClassType>;
  building_class_type__warehouse?: IJsonQueryTerms<TBuildingClassType>;
  building_cranage_types_type?: IJsonQueryTerms<TBuildingCraneType>;
  building_gates_type?: IJsonQueryTerms<TBuildingGatesType>;
  building_heating_type?: IJsonQueryTerms<TBuildingHeatingType>;
  building_house_line_type?: IJsonQueryTerms<THouseLineType>;
  building_house_line_type__shopping_area?: IJsonQueryTerms<THouseLineType>;
  building_lift_types_type?: IJsonQueryTerms<TBuildingElevatorType>;
  building_type2?: IJsonQueryTerms<TBuildingType>;
  building_type__building?: IJsonQueryTerms<TBuildingType>;
  category?: IJsonQueryTerms<TCategoryType>;
  composition?: IJsonQueryTerms<TTenant>;
  condition_type?: IJsonQueryTerms<TConditionType>;
  condition_type__free_appointment_object?: IJsonQueryTerms<TConditionType>;
  condition_type__industry?: IJsonQueryTerms<TConditionType>;
  contract?: IJsonQueryTerms<TContractType>;
  description_exclude?: IJsonQueryTerms<string>;
  description_include?: IJsonQueryTerms<string>;
  estate_type?: IJsonQueryTerms<TEstateType>;
  garage_garage_type?: IJsonQueryTerms<TGarageKind>;
  garage_material?: IJsonQueryTerms<TGarageMaterial>;
  garage_status?: IJsonQueryTerms<TGarageStatus>;
  garage_type?: IJsonQueryTerms<TGarageType>;
  heating_source_type?: IJsonQueryTerms<THeatingType>;
  house_material?: IJsonQueryTerms<THouseMaterial>;
  input_type?: IJsonQueryTerms<TEntranceType>;
  kp_id?: IJsonQueryTerms<number>;
  land_status?: IJsonQueryTerms<TLandStatusType>;
  object_type?: IJsonQueryTerms<TObjectType>;
  office_type?: IJsonQueryTerms<TOfficeType>;
  parking_type?: IJsonQueryTerms<TParkingType>;
  placement_type?: IJsonQueryTerms<TPlacementType>;
  property_type?: IJsonQueryTerms<TPropertyType>;
  region?: IJsonQueryTerms<number>;
  repair?: IJsonQueryTerms<TRepair>;
  room?: IJsonQueryTerms<TRoomType>;
  rooms_count?: IJsonQueryTerms<TRoomsTotal>;
  sost_type?: IJsonQueryTerms<TSaleType>;
  status?: IJsonQueryTerms<number>;
  specialty_types?: IJsonQueryTerms<number>;
  year?: IJsonQueryTerms<number>;
}

export interface IJsonQueryBooleanParams {
  enter?: IJsonQueryTerm<boolean>;
  demolished_in_moscow_programm?: IJsonQueryTerm<boolean>;
  has_video?: IJsonQueryTerm<boolean>;
}

export interface IJsonQueryInfrastructureParams {
  has_light?: IJsonQueryTerm<boolean>;
  has_electricity?: IJsonQueryTerm<boolean>;
  has_heating?: IJsonQueryTerm<boolean>;
  has_water?: IJsonQueryTerm<boolean>;
  has_extinguishing_system?: IJsonQueryTerm<boolean>;
  infrastructure_has_slot_machines?: IJsonQueryTerm<boolean>;
  infrastructure_has_notary_office?: IJsonQueryTerm<boolean>;
  infrastructure_has_hotel?: IJsonQueryTerm<boolean>;
  infrastructure_has_office_space?: IJsonQueryTerm<boolean>;
  infrastructure_has_atm?: IJsonQueryTerm<boolean>;
  infrastructure_has_aquapark?: IJsonQueryTerm<boolean>;
  infrastructure_has_cafe?: IJsonQueryTerm<boolean>;
  infrastructure_has_clothes_studio?: IJsonQueryTerm<boolean>;
  infrastructure_has_basement?: IJsonQueryTerm<boolean>;
  infrastructure_has_tire?: IJsonQueryTerm<boolean>;
  infrastructure_has_fitness_centre?: IJsonQueryTerm<boolean>;
  infrastructure_has_shopping_area?: IJsonQueryTerm<boolean>;
  infrastructure_has_pool?: IJsonQueryTerm<boolean>;
  infrastructure_has_cinema?: IJsonQueryTerm<boolean>;
  infrastructure_has_car_wash?: IJsonQueryTerm<boolean>;
  infrastructure_has_food_court?: IJsonQueryTerm<boolean>;
  infrastructure_has_medical_center?: IJsonQueryTerm<boolean>;
  infrastructure_has_restaurant?: IJsonQueryTerm<boolean>;
  infrastructure_has_video_surveillance?: IJsonQueryTerm<boolean>;
  infrastructure_has_minimarket?: IJsonQueryTerm<boolean>;
  infrastructure_has_beauty_shop?: IJsonQueryTerm<boolean>;
  infrastructure_has_warehouse?: IJsonQueryTerm<boolean>;
  infrastructure_has_bowling?: IJsonQueryTerm<boolean>;
  infrastructure_has_baby_sitting?: IJsonQueryTerm<boolean>;
  infrastructure_has_canteen?: IJsonQueryTerm<boolean>;
  infrastructure_has_pharmacy?: IJsonQueryTerm<boolean>;
  infrastructure_has_supermarket?: IJsonQueryTerm<boolean>;
  infrastructure_has_buffet?: IJsonQueryTerm<boolean>;
  infrastructure_has_conference_room?: IJsonQueryTerm<boolean>;
  infrastructure_has_inspection_pit?: IJsonQueryTerm<boolean>;
  infrastructure_has_park?: IJsonQueryTerm<boolean>;
  infrastructure_has_rink?: IJsonQueryTerm<boolean>;
  infrastructure_has_hour_security?: IJsonQueryTerm<boolean>;
  infrastructure_has_game_room?: IJsonQueryTerm<boolean>;
  infrastructure_has_exhibition_and_warehouse_complex?: IJsonQueryTerm<boolean>;
  infrastructure_has_bank_department?: IJsonQueryTerm<boolean>;
  infrastructure_has_automatic_gates?: IJsonQueryTerm<boolean>;
  infrastructure_has_central_reception?: IJsonQueryTerm<boolean>;
  infrastructure_has_entry_by_pass?: IJsonQueryTerm<boolean>;
  infrastructure_has_car_service?: IJsonQueryTerm<boolean>;
  infrastructure_has_studio?: IJsonQueryTerm<boolean>;
}

export interface IJsonQuery extends IJsonQueryTermsParams, IJsonQueryBooleanParams {
  _type: IJsonQueryType;
  engine_version: IJsonQueryTerm<number>;

  apartment?: IJsonQueryTerm<boolean>;
  balconies?: IJsonQueryRange;
  bath?: IJsonQueryTerm<boolean>;
  bathhouse?: IJsonQueryTerm<boolean>;
  bedroom_total?: IJsonQueryRange;
  bs_center_id?: IJsonQueryTerm<number>;
  build_quarter?: IJsonQueryTerm<number>;
  build_year?: IJsonQueryTerm<number>;
  building_status?: IJsonQueryTerm<TBuildingStatus>;
  ceiling_height?: IJsonQueryRange;
  cl?: IJsonQueryTerm<boolean>;
  commission_type?: IJsonQueryTerm<number>;
  conditioner?: IJsonQueryTerm<boolean>;
  contact?: IJsonQueryTerm<string>;
  currency?: IJsonQueryTerm<TCurrencyType>;
  developer_sort_type?: IJsonQueryTerm<string>;
  dish_washer?: IJsonQueryTerm<boolean>;
  electricity?: IJsonQueryTerm<boolean>;
  floor?: IJsonQueryRange;
  floorn?: IJsonQueryRange;
  foot_min?: IJsonQueryRange;
  for_day?: IJsonQueryTerm<TRentTime>;
  from_developer?: IJsonQueryTerm<boolean>;
  from_jk?: IJsonQueryTerm<boolean>;
  from_mcad_km?: IJsonQueryRange;
  from_offrep?: IJsonQueryTerm<boolean>;
  garage?: IJsonQueryTerm<boolean>;
  gas?: IJsonQueryTerm<boolean>;
  geo?: IJsonQueryGeo;
  hand_over?: IJsonQueryTerm<boolean>;
  has_decoration?: IJsonQueryTerm<boolean>;
  has_drainage?: IJsonQueryTerm<boolean>;
  has_electricity?: IJsonQueryTerm<boolean>;
  has_equipment?: IJsonQueryTerm<boolean>;
  has_extinguishing_system?: IJsonQueryTerm<boolean>;
  has_fridge?: IJsonQueryTerm<boolean>;
  has_furniture?: IJsonQueryTerm<boolean>;
  has_heating?: IJsonQueryTerm<boolean>;
  has_kitchen_furniture?: IJsonQueryTerm<boolean>;
  has_light?: IJsonQueryTerm<boolean>;
  has_washer?: IJsonQueryTerm<boolean>;
  has_water?: IJsonQueryTerm<boolean>;
  house_year?: IJsonQueryRange;
  id?: IJsonQueryTerm<number>;
  id_user?: IJsonQueryTerm<number>;
  include_new_moscow?: IJsonQueryTerm<boolean>;
  internet?: IJsonQueryTerm<boolean>;
  ipoteka?: IJsonQueryTerm<boolean>;
  isWCfixed?: boolean;
  is_basement?: IJsonQueryTerm<boolean>;
  is_by_homeowner?: IJsonQueryTerm<boolean>;
  is_by_commercial_owner?: IJsonQueryTerm<boolean>;
  is_first_floor?: IJsonQueryTerm<boolean>;
  is_in_hidden_base?: IJsonQueryTerm<boolean>;
  is_semibasement?: IJsonQueryTerm<boolean>;
  jk_flat_type_id?: IJsonQueryTerm<string>;
  kids?: IJsonQueryTerm<boolean>;
  kitchen?: IJsonQueryRange;
  lift_service?: IJsonQueryRange;
  lifts?: IJsonQueryTerm<TElevators>;
  living_area?: IJsonQueryRange;
  loggia?: IJsonQueryTerm<boolean>;
  maxprepay?: IJsonQueryTerm<TMaxPrepay>;
  multi_id?: IJsonQueryTerm<number>;
  not_last_floor?: IJsonQueryTerm<boolean>;
  only_flat?: IJsonQueryTerm<boolean>;
  only_foot?: IJsonQueryTerm<TMetroTravelType>;
  page?: IJsonQueryTerm<number>;
  penthouse?: IJsonQueryTerm<boolean>;
  pets?: IJsonQueryTerm<boolean>;
  phone?: IJsonQueryTerm<boolean>;
  pool?: IJsonQueryTerm<boolean>;
  price?: IJsonQueryRange;
  price_sm?: IJsonQueryTerm<boolean>;
  promo?: IJsonQueryTerm<boolean>;
  publish_period?: IJsonQueryTerm<TPublishPeriod>;
  quality?: IJsonQueryTerm<boolean>;
  room_area?: IJsonQueryRange;
  room_type?: IJsonQueryTerm<TLayoutType>;
  rooms_for_sale?: IJsonQueryRange;
  security?: IJsonQueryTerm<boolean>;
  shower?: IJsonQueryTerm<boolean>;
  site?: IJsonQueryRange;
  sort?: IJsonQueryTerm<string>;
  total_area?: IJsonQueryRange;
  tv?: IJsonQueryTerm<boolean>;
  wc?: IJsonQueryRange;
  wc_site?: IJsonQueryTerm<TSuburbanWCType>;
  wc_type?: IJsonQueryTerm<TWCType>;
  windows_type?: IJsonQueryTerm<TWindowsType>;
  with_newobject?: IJsonQueryTerm<boolean>;
  wp?: IJsonQueryTerm<boolean>;
  yeargte?: IJsonQueryTerm<number>;
  zalog?: IJsonQueryTerm<boolean>;
}
