import { Button, IconHeartOff16, IconHeartOn16, UIText2 } from '@cian/ui-kit';

import { LikeButton } from '../like';

import * as styles from './index.css';

/**
 * Адаптивная кнопка избранного, которая схлопывается в иконку на разрешении width < 1240px
 */
export class AdaptiveLikeButton extends LikeButton {
  public render() {
    const { isActive } = this.state;

    return (
      <Button
        beforeIcon={
          <span className={styles['like-button']}>
            {isActive ? <IconHeartOn16 color="error_100" /> : <IconHeartOff16 color="gray_icons_100" />}
            <span className={styles['button-text']}>
              <UIText2 color="black_100" fontWeight="700">
                {isActive ? 'В избранном' : 'В избранное'}
              </UIText2>
            </span>
          </span>
        }
        size="XS"
        theme="stroke_secondary"
        onClick={this.handleClick}
      />
    );
  }
}
