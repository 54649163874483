/**
 * @todo Удалить строку с экспериментом
 * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
 */
export const COMMERCIAL_USER_PROFILING_EXPERIMENT = 'commercial_user_profiling';
/**
 * @todo Удалить строку с экспериментом
 * @description Данный функционал появился в задаче CD-128496, будет удалён в задаче CD-128497
 */
export const COMMERCIAL_BLOCKED_ADVANCED_FILTERS_EXPERIMENT = 'commercial_blocked_advanced_filters_experiment';
