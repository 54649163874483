import { MultiAdsBanner } from '../../components/MultiAdsBanner';

import { useIsMultiAdsBannerEnabled } from './internal/hooks';

export const MultiAdsBannerContainer = () => {
  const onBannerClick = () => {
    window.open('https://cian.ru/razmestit-obyavlenie/');
  };

  const isVisible = useIsMultiAdsBannerEnabled();

  if (!isVisible) {
    return null;
  }

  return <MultiAdsBanner onClick={onBannerClick} />;
};
