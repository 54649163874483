import { IMultiSelectProps, MultiSelect, Paragraph2 } from '@cian/ui-kit';

import * as React from 'react';

import * as styles from './styles.css';

export interface IMultiselect extends IMultiSelectProps {
  label?: string;
}

export const Multiselect: React.FC<IMultiselect> = ({ label, ...props }) => (
  <div className={styles['root']}>
    {label && (
      <div className={styles['label']}>
        <Paragraph2>{label}</Paragraph2>
      </div>
    )}
    <MultiSelect {...props} size="M" />
  </div>
);

Multiselect.displayName = 'Multiselect';
