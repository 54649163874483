import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IHouseYearTagType = 'advanced-houseYear';

export interface IHouseYearTag {
  type: 'advanced-houseYear';
  min: number | undefined;
  max: number | undefined;
}

const isHouseYearTag = (tag: TTag): tag is IHouseYearTag => {
  return tag.type === IHouseYearTagType;
};

export const houseYearTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.house_year && jsonQuery.house_year.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isHouseYearTag, () => ({
        type: IHouseYearTagType,
        min: value.gte,
        max: value.lte,
      }));
    } else {
      return ensureTagNotExists(tags, isHouseYearTag);
    }
  },
};
