import { useSelector } from 'react-redux';

import { ProfessionalSearchPromo } from '../../components/ProfessionalSearchPromo';
import { selectIsProfessionalSearchPromoBannerVisible } from '../../selectors/selectIsProfessionalSearchPromoBannerVisible';
import { selectProfessionalSearchUrl } from '../../selectors/selectProfessionalSearchUrl';

import { trackProfessionalSearchClick } from './tracking';

export const ProfessionalSearchPromoContainer = () => {
  const link = useSelector(selectProfessionalSearchUrl);
  const isVisible = useSelector(selectIsProfessionalSearchPromoBannerVisible);

  if (!isVisible) {
    return null;
  }

  return <ProfessionalSearchPromo link={link} onClick={trackProfessionalSearchClick} />;
};
