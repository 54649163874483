export const IconCheck = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg height="13" width="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h16v13H0z" />
      <path
        d="M6.443 12.495a.36.36 0 0 1-.54-.007L.109 6.21a.437.437 0 0 1-.005-.584L1.38 4.24a.36.36 0 0 1 .537.003l4.26 4.614L14.032.295a.36.36 0 0 1 .542-.002l1.294 1.403c.15.162.145.43.004.582l-9.43 10.217z"
        fill="#2E9E00"
      />
    </g>
  </svg>
);
