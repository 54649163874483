import { IconFunctionalInformationCircle16 } from '@cian/ui-kit/icons';
import { InlineText2 } from '@cian/ui-kit/typography';

import * as React from 'react';

import { Tooltip } from '../../../../tooltip/Tooltip';

import { ICoworkingWorkplacesCountSubtitleViewProps } from './types';

const styles = require('./CoworkingWorkplacesCountSubtitleView.css');

export const CoworkingWorkplacesCountSubtitleView: React.FC<ICoworkingWorkplacesCountSubtitleViewProps> = props => {
  const { title, tooltipTitle } = props;

  return (
    <div className={styles['container']}>
      <span className={styles['title']}>
        <InlineText2>{title}</InlineText2>
      </span>
      {tooltipTitle && (
        <Tooltip
          containerClassName={styles['tooltip-container']}
          icon={
            <span className={styles['info-icon']}>
              <IconFunctionalInformationCircle16 color="primary_10" />
            </span>
          }
          iconColor=""
          iconSize={16}
        >
          <InlineText2 color="white_100">{tooltipTitle}</InlineText2>
        </Tooltip>
      )}
    </div>
  );
};

CoworkingWorkplacesCountSubtitleView.displayName = 'CoworkingWorkplacesCountSubtitleView';
