import { connect } from 'react-redux';

import { Dispatch, IAppState } from '../../../common/state';
import { closeOfficeCalculator } from '../../state/office_calculator';

import { OfficeCalculator } from './index';

export const OfficeCalculatorContainer = connect(
  (state: IAppState) => ({
    isOfficeCalculatorVisible: state.isOfficeCalculatorVisible,
  }),
  (dispatch: Dispatch) => ({
    onClose: () => {
      dispatch(closeOfficeCalculator());
    },
  }),
)(OfficeCalculator);
