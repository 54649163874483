/* eslint-disable max-lines */
import { Component } from 'react';

import { IGeoDistrict } from 'shared/api/geo/geo';
import { Button } from 'shared/common/components/button';
import { IJsonQueryDistrict } from 'shared/json_query';
import { TLocation } from 'shared/types/location';

const style = require('./index.css');

export interface IAreasProps {
  areas: IGeoDistrict[] | undefined;
  selectedDistricts: IJsonQueryDistrict[];
  selectedLocation: TLocation | undefined;
  onSelected(id: number, name: string): void;
  onRemoved(id: number): void;
}

export interface IRegionGroup {
  direction: string | null;
  districts: IGeoDistrict[];
  letter: string;
  id: number;
}

const LETTER_TITLE_HEIGHT = 4;
const DistrictDirectionName: { [key: string]: string } = {
  ['ЗАО']: 'запад',
  ['СЗАО']: 'северо-запад',
  ['САО']: 'север',
  ['ЦАО']: 'центр',
  ['ЮЗАО']: 'юго-запад',
  ['ЮАО']: 'юг',
  ['ЮВАО']: 'юго-восток',
  ['ВАО']: 'восток',
  ['СВАО']: 'северо-восток',
};

export function isMoscowLocation(selectedLocation: TLocation | undefined) {
  if (!selectedLocation) {
    return false;
  }
  if (selectedLocation !== 'moscow_mo' && selectedLocation !== 'spb_lo') {
    return selectedLocation.id === 1;
  } else {
    return selectedLocation === 'moscow_mo';
  }
}

export function isStartFromNumber(value: string, divider: string) {
  const firstChunk = value.split(divider)[0];

  return !isNaN(parseInt(firstChunk, 10));
}

export class DistrictsLayout extends Component<IAreasProps> {
  private districtGroups: IRegionGroup[];

  public render() {
    const showFlat = this.parseDistricts();
    const isMoscow = isMoscowLocation(this.props.selectedLocation);

    let result;
    if (isMoscow) {
      result = this.sortMoscowRegions(this.districtGroups);
    } else {
      result = this.sortRegions([[], [], [], this.districtGroups]);
    }

    return (
      <div className={isMoscow ? style.columnWrapperMoskow : style.columnWrapper}>
        {result.map((column, id) => {
          return this.getColumn(column, id, showFlat);
        })}
      </div>
    );
  }

  private getColumn(column: IRegionGroup[], id: number, showFlat: boolean) {
    return (
      <div className={style.column} key={id}>
        {column.map(group => {
          return this.getDistrict(group, showFlat);
        })}
      </div>
    );
  }

  private getDistrict(group: IRegionGroup, showFlat: boolean) {
    return (
      <div key={group.id}>
        {this.getDistrictName(group, showFlat)}
        {group.districts.map(region => {
          return region.childs && this.getChilds(region.childs, region.id);
        })}
      </div>
    );
  }

  private parseDistricts() {
    this.districtGroups = [];

    if (!this.props.areas) {
      return false;
    }

    let isFlatStructure = true;
    this.props.areas.forEach(district => {
      if (district.childs.length > 0) {
        isFlatStructure = false;
      }

      this.addDistrict(district);
    });

    this.districtGroups.sort((a, b) => {
      const letterA = a.letter;
      const letterB = b.letter;

      const valueForSortA = isStartFromNumber(letterA, '-') ? parseInt(letterA, 10) : letterA.toUpperCase();
      const valueForSortB = isStartFromNumber(letterB, '-') ? parseInt(letterB, 10) : letterB.toUpperCase();
      if (valueForSortA < valueForSortB) {
        return -1;
      }
      if (valueForSortA > valueForSortB) {
        return 1;
      }

      return 0;
    });

    return isFlatStructure;
  }

  private addDistrict(district: IGeoDistrict) {
    const letter: string = district.name;
    let isAdded = false;

    this.districtGroups.forEach((value: IRegionGroup) => {
      if (value.letter === letter) {
        value.districts.push(district);
        isAdded = true;
      }
    });

    if (!isAdded) {
      this.createGroup(letter, district);
    }
  }

  private createGroup(letter: string, district: IGeoDistrict) {
    this.districtGroups.push({
      letter,
      id: district.id,
      direction: district.direction.name,
      districts: [district],
    });
  }

  private sortRegions(initialColumns: IRegionGroup[][]): IRegionGroup[][] {
    const columns: IRegionGroup[][] = initialColumns;

    let isMoved = false;

    if (columns[2].length === 0 || columns[3].length > 1) {
      isMoved = this.moveColumns(columns[2], columns[3]) ? true : isMoved;
    }

    if (columns[1].length === 0 || columns[3].length > 0) {
      isMoved = this.moveColumns(columns[1], columns[2]) ? true : isMoved;
    }

    isMoved = this.moveColumns(columns[0], columns[1]) ? true : isMoved;

    if (isMoved) {
      return this.sortRegions(columns);
    }

    return columns;
  }

  private moveColumns(left: IRegionGroup[], right: IRegionGroup[]) {
    const leftCount = this.getColumnLength(left);
    const rightCount = this.getColumnLength(right);

    if (leftCount <= rightCount && rightCount - leftCount > LETTER_TITLE_HEIGHT) {
      const group = right.shift();
      if (group) {
        left.push(group);
      }

      return true;
    }

    return false;
  }

  private getColumnLength = (arr: IRegionGroup[]) => {
    return arr.reduce((acc, value) => {
      const length = value.districts[0].childs.length;
      const increment = length === 0 ? 1 : length;

      return acc + increment;
    }, arr.length * LETTER_TITLE_HEIGHT);
  };

  private sortMoscowRegions(groups: IRegionGroup[]): IRegionGroup[][] {
    const columns: IRegionGroup[][] = [[], [], [], []];
    this.setDistrictGroupByName(0, 'НАО (Новомосковский)', groups, columns);
    this.setDistrictGroupByName(0, 'ТАО (Троицкий)', groups, columns);
    this.setDistrictGroupByName(0, 'ЗелАО', groups, columns);

    this.setDistrictGroupByName(1, 'СЗАО', groups, columns);
    this.setDistrictGroupByName(1, 'ЗАО', groups, columns);
    this.setDistrictGroupByName(1, 'ЮЗАО', groups, columns);

    this.setDistrictGroupByName(2, 'САО', groups, columns);
    this.setDistrictGroupByName(2, 'ЦАО', groups, columns);
    this.setDistrictGroupByName(2, 'ЮАО', groups, columns);

    this.setDistrictGroupByName(3, 'СВАО', groups, columns);
    this.setDistrictGroupByName(3, 'ВАО', groups, columns);
    this.setDistrictGroupByName(3, 'ЮВАО', groups, columns);

    return columns;
  }

  private setDistrictGroupByName = (
    columnId: number,
    name: string,
    groups: IRegionGroup[],
    columns: IRegionGroup[][],
  ) => {
    groups.forEach((value: IRegionGroup) => {
      if (value.letter === name) {
        columns[columnId].push(value);
      }
    });
  };

  private getChilds(childs: IGeoDistrict[], index: number) {
    return (
      <ul key={index}>
        {childs.map((item: IGeoDistrict) => {
          return this.isSelected(item.id) ? (
            <li key={item.id}>
              <Button
                className={style['endButton']}
                theme="primary"
                onClick={() => {
                  this.props.onRemoved(item.id);
                }}
              >
                {item.name}
              </Button>
            </li>
          ) : (
            <li key={item.id}>
              <div
                className={style['column-item']}
                onClick={() => {
                  this.props.onSelected(item.id, item.name);
                }}
              >
                {item.name}
              </div>
            </li>
          );
        })}
      </ul>
    );
  }

  private getDistrictName(group: IRegionGroup, isFlat: boolean) {
    return (
      <div>
        {this.isSelected(group.id) ? (
          <Button
            className={isFlat ? style['endButton'] : style['endTitleButton']}
            theme="primary"
            onClick={() => {
              this.props.onRemoved(group.id);
            }}
          >
            {group.letter}
          </Button>
        ) : (
          <div
            className={isFlat ? style['column-item'] : style.letterTitle}
            onClick={() => {
              this.props.onSelected(group.id, group.letter);
            }}
          >
            {group.letter}
          </div>
        )}

        <div className={style.directionTitle}>
          {group.direction ? group.direction : DistrictDirectionName[group.letter]}
        </div>
      </div>
    );
  }

  private isSelected(id: number) {
    return this.props.selectedDistricts.reduce((acc, value) => {
      if (acc) {
        return acc;
      }

      return value.id === id;
    }, false);
  }
}
