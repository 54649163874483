import { Header1, InlineText2, LinkButton } from '@cian/ui-kit';

import classNames from 'classnames';
import { FC } from 'react';

import { IconCactus } from '../Icons/IconCactus';
import { IconCoffeeCup } from '../Icons/IconCoffeeCup';
import { IconForefinger } from '../Icons/IconForefinger';
import { IconHorns } from '../Icons/IconHorns';
import { IconLamp } from '../Icons/IconLamp';
import { IconMapMark } from '../Icons/IconMapMark';

import { ICoworkingProfitBannerProps } from './types';

import * as styles from './CoworkingProfitBanner.css';

export const CoworkingProfitBanner: FC<ICoworkingProfitBannerProps> = props => {
  const { containerClassName } = props;

  return (
    <div className={classNames(styles['container'], containerClassName)}>
      <div className={styles['icons-group']}>
        <div className={styles['icon-cactus']}>
          <IconCactus />
        </div>
        <div className={styles['icon-coffee-cup']}>
          <IconCoffeeCup />
        </div>
        <div className={styles['icon-forefinger']}>
          <IconForefinger />
        </div>
      </div>
      <div className={styles['content-container']}>
        <h2 className={styles['title']}>
          <Header1 color="primary_100">Коворкинг на 38% выгоднее</Header1>
          <Header1 color="black_100">обычного офиса</Header1>
        </h2>
        <div className={styles['subtitle']}>
          <InlineText2 color="gray60_100">при сравнении стоимости аренды в первый год</InlineText2>
        </div>
        <LinkButton href="https://promo.cian.ru/coworking" size="XS" target="_blank" theme="fill_primary">
          Почему?
        </LinkButton>
      </div>
      <div className={styles['icons-group']}>
        <div className={styles['icon-map-mark']}>
          <IconMapMark />
        </div>
        <div className={styles['icon-horns']}>
          <IconHorns />
        </div>
        <div className={styles['icon-lamp']}>
          <IconLamp />
        </div>
      </div>
    </div>
  );
};

CoworkingProfitBanner.displayName = 'CoworkingProfitBanner';
