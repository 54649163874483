import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export interface IHasVideoTag {
  type: 'advanced-has-video';
}

function isHasVideoTag(tag: TTag): tag is IHasVideoTag {
  return tag.type === 'advanced-has-video';
}

export const hasVideoTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_video && jsonQuery.has_video.value;

    if (value) {
      return ensureSingleTagExists(tags, isHasVideoTag, () => ({ type: 'advanced-has-video' }));
    }

    return ensureTagNotExists(tags, isHasVideoTag);
  },
};
