import * as PropTypes from 'prop-types';
import { PureComponent } from 'react';
import * as ReactDOM from 'react-dom';

import { EMaxAuctionService } from 'shared/repositories/search-offers.legacy/v2/search-offers-desktop.data';
import { IOffer } from 'shared/repositories/search-offers.legacy/v2/types';

import { VasComponent } from '../../../vas-tooltip-component';
import { IUser } from '../../types/user';
import { getVasLabel } from '../../utils/getVasLabel';
import { getOfferVasList } from '../../utils/offer_vas';
import { AuctionApplyNewBetHint } from '../AuctionApplyNewBetHint';
import { AuctionPopupContainer, AuctionPopupKind, TAuctionPopupKind } from '../AuctionPopup';
import { TimeLabel } from '../time_label';

import { getAucionPopupTypeForNotOwn, getServiceName } from './utils';

import * as styles from './RightPanel.css';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IRightPanelProps {}

export interface IRightPanelState {
  isAuctionPopupShow: boolean;
  auctionPopupKind: TAuctionPopupKind;
  initialBet: number;
  isFromOwn: boolean;
}

export interface IRightPanelContext {
  offer: IOffer;
  user: IUser | null;
  /** Перечень регионов ранжированного поиска */
  mlSearchRegions?: number[];
  /** ML-ранжирование, без проверки региона  */
  isMlSearchForAll?: boolean;
  /** Максимальная ставка аукциона на выдаче */
  maxAuctionBet: number;
  /** Максимальная услуга выдачи */
  maxAuctionService?: EMaxAuctionService;
}

export interface IAuctionBetManagerEventDetail {
  currentBet: number;
  userOfferId: number;
}

export interface IAuctionRightPanelRerenderEventDetail {
  offerId: number;
}

export class RightPanel extends PureComponent<IRightPanelProps, IRightPanelState> {
  public context: IRightPanelContext;

  public static contextTypes: Record<keyof RightPanel['context'], PropTypes.Requireable<unknown>> = {
    mlSearchRegions: PropTypes.array,
    isMlSearchForAll: PropTypes.bool,
    offer: PropTypes.object,
    user: PropTypes.object,
    maxAuctionBet: PropTypes.number,
    maxAuctionService: PropTypes.string,
  };

  public constructor(props: IRightPanelProps) {
    super(props);

    this.state = {
      isAuctionPopupShow: false,
      auctionPopupKind: null,
      initialBet: 0,
      isFromOwn: false,
    };
  }

  public componentDidMount() {
    const { auction } = this.context.offer;

    if (auction && auction.isOwn && auction.isEditable && typeof document !== 'undefined') {
      document.addEventListener('auctionBetManagerShow', this.handleAuctionBetManagerShow);
      document.addEventListener('auctionRightPanelRerender', this.handleAuctionRightPanelRerender);
    }
  }

  public render() {
    const { offer, user, maxAuctionBet, isMlSearchForAll, maxAuctionService } = this.context;
    const { isAuctionPopupShow, auctionPopupKind, initialBet, isFromOwn } = this.state;

    const { auction, isAuction, added, humanizedTimedelta, id } = offer;

    const vasList = getOfferVasList(offer);
    const vasLabel = getVasLabel(offer, isMlSearchForAll);

    const auctionBet = auction && auction.currentBet;

    const isAgentUser = (!!user && user.isAgent) || false;

    return (
      <div className={styles['right-panel']} onClick={e => e.stopPropagation()}>
        <div className={styles['time']}>
          <TimeLabel added={added} humanizedTime={humanizedTimedelta} />

          {auction && isAuctionPopupShow && auctionPopupKind != null && (
            <AuctionPopupContainer
              auction={auction}
              initialBet={initialBet}
              isFromOwn={isFromOwn}
              kind={auctionPopupKind}
              onClose={this.handleAuctionPopupClose}
            />
          )}
        </div>

        {isAgentUser && (
          <div className={styles['vas_label']}>
            <div className="vas_component_wrapper">
              <VasComponent
                auctionBet={auctionBet ?? undefined}
                direction="left"
                label={vasLabel}
                uniqueKey={id}
                vasList={vasList}
              />

              {auction && auction.isOwn && auction.isEditable && typeof auction.nextBet === 'number' && (
                <AuctionApplyNewBetHint applyNewBetDate={auction.applyNewBetDate} nextBet={auction.nextBet} />
              )}
            </div>

            {auction && auction.isOwn && auction.isEditable && (
              <span
                className={styles['auction_link']}
                onClick={this.showAuctionPopup(
                  getServiceName<IOffer>(offer).toLowerCase() === `is${maxAuctionService}`
                    ? AuctionPopupKind.MANAGE
                    : AuctionPopupKind.NOT_PREMIUM,
                  maxAuctionBet,
                  true,
                )}
              >
                Поднять объявление в поиске
              </span>
            )}

            {auction && isAuction && !auction.isOwn && (
              <span
                className={styles['auction_link']}
                onClick={this.showAuctionPopup(getAucionPopupTypeForNotOwn(offer))}
              >
                Как опередить это объявление?
              </span>
            )}
          </div>
        )}
      </div>
    );
  }

  public componentWillUnmount() {
    /* istanbul ignore next */
    if (typeof document === 'undefined') {
      return;
    }

    document.removeEventListener('auctionBetManagerShow', this.handleAuctionBetManagerShow);
    document.removeEventListener('auctionRightPanelRerender', this.handleAuctionRightPanelRerender);
  }

  private handleAuctionBetManagerShow = (event: CustomEvent<IAuctionBetManagerEventDetail>) => {
    const { currentBet, userOfferId } = event.detail;
    const { offer } = this.context;

    if (offer.id !== userOfferId) {
      return;
    }

    // eslint-disable-next-line react/no-find-dom-node
    const node = ReactDOM.findDOMNode(this) as Element;
    node.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });

    this.showAuctionPopup(AuctionPopupKind.MANAGE, currentBet)();
  };

  private showAuctionPopup =
    (auctionPopupKind: TAuctionPopupKind, initialBet: number = 0, isFromOwn: boolean = false) =>
    () => {
      const event = new Event('auctionNotificationHide');
      document.dispatchEvent(event);

      this.setState({
        isAuctionPopupShow: true,
        auctionPopupKind,
        initialBet,
        isFromOwn,
      });
    };

  private handleAuctionPopupClose = () => {
    this.setState({ isAuctionPopupShow: false, auctionPopupKind: null });
  };

  private handleAuctionRightPanelRerender = (event: CustomEvent<IAuctionRightPanelRerenderEventDetail>) => {
    const { offerId } = event.detail;
    const { offer } = this.context;

    if (offer.id !== offerId) {
      return;
    }

    // Делаем принудительный rerender
    this.forceUpdate();
  };
}
