import { IStyleConfig } from '@cian/utils';

import * as React from 'react';

export interface IButtonCheckboxProps {
  children?: React.ReactNode;
  onValueChange(value: boolean): void;
  value: boolean;
  buttonStyle?: IStyleConfig;
}

// Не используется, удалить в будущем
export class ButtonCheckbox extends React.Component<IButtonCheckboxProps> {
  public render() {
    // Заменил Button из @cian/components на нативный button, в дальнейшем этот компонент будет целиком удален
    return <button onClick={this.handleClick}>{this.props.children}</button>;
  }

  private handleClick = () => {
    this.props.onValueChange(!this.props.value);
  };
}
