import { Dispatch, IAppState } from 'shared/common/state';
import { IAddOfferToComparisonRequest } from 'shared/repositories/offers-comparison/v1/add-offer-to-comparison';

import { addOfferToComparisonRequest } from '../../../../services/offersComparison';
import { EComparisonNotificationCategory, SetComparisonNotificationType } from '../comparisonNotification';
import { AddToComparisonActionType } from '../offersComparison';

export const addOfferToComparison =
  (parameters: IAddOfferToComparisonRequest) => async (dispatch: Dispatch, getState: () => IAppState) => {
    const { httpApi } = getState();
    const { offerId } = parameters;
    const response = await addOfferToComparisonRequest({ httpApi, parameters: { offerId } });

    if (response) {
      const { success, text, comparisonUrl, offerId } = response;

      if (success) {
        dispatch({
          type: AddToComparisonActionType,
          offerId,
        });
      }

      dispatch({
        type: SetComparisonNotificationType,
        text,
        url: comparisonUrl,
        category: success ? EComparisonNotificationCategory.Add : EComparisonNotificationCategory.Limit,
        offerId,
      });
    }
  };
