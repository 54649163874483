import { TTag } from './index';

export function ensureSingleTagExists<ITag extends TTag>(
  tags: TTag[],
  filter: (tag: TTag) => tag is ITag,
  mapper: (tag?: ITag) => ITag,
): TTag[] {
  const reversedTags = tags.reverse();

  const result = reversedTags.reduce(
    (acc, tag) => {
      if (filter(tag)) {
        if (!acc.applied) {
          acc.tags.push(mapper(tag));
          acc.applied = true;
        }
      } else {
        acc.tags.push(tag);
      }

      return acc;
    },
    { tags: [] as TTag[], applied: false },
  );

  const newTags = result.tags.reverse();

  if (!result.applied) {
    newTags.push(mapper());
  }

  return newTags;
}

export function ensureTagNotExists<ITag extends TTag>(tags: TTag[], filter: (tag: TTag) => tag is ITag): TTag[] {
  return tags.filter(tag => !filter(tag));
}

export function findFirstTag<ITag extends TTag>(tags: TTag[], filter: (tag: TTag) => tag is ITag): ITag {
  return tags.filter(filter).shift() as ITag;
}
