import { IAreaPart } from '../../../../../repositories/search-offers.legacy/v2/types';

import { prepareFloorsRange } from './prepareFloorsRange';

export const getFormattedFloor = (areaPart: IAreaPart) => {
  const { floorFrom, floorTo } = areaPart;

  if (!floorFrom && !floorTo) {
    return '';
  }

  if (floorFrom && floorTo) {
    return prepareFloorsRange({ floorFrom, floorTo });
  }

  const floor = (floorFrom || floorTo) as number;

  return prepareFloorsRange({ floorFrom: floor, floorTo: floor });
};
