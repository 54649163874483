import { Component } from 'react';

import { Button } from '../../../../common/components/button';
import { ILocation, TLocation } from '../../../../types/location';

const style = require('./index.css');

export interface IBreadcrumbsProps {
  regions: ILocation[];
  selectedCity: ILocation | undefined;
  selectedRegion: TLocation | undefined;

  onCountrySelected(): void;
  onRegionSelected(region: TLocation): void;
  onCitySelected(city: ILocation): void;
}

enum REGIONS {
  Moscow = 1,
  Petersburg = 2,
  MoscowArea = 4593,
  LeningradArea = 4588,
}

export class Breadcrumbs extends Component<IBreadcrumbsProps> {
  public render() {
    const { selectedRegion, selectedCity } = this.props;

    const fakeRegion = selectedRegion && this.getFakeRegion(selectedRegion);

    const isCapital =
      selectedRegion &&
      selectedRegion !== 'moscow_mo' &&
      selectedRegion !== 'spb_lo' &&
      (selectedRegion.id === 1 || selectedRegion.id === 2);

    return (
      <div className={style['breadcrumbContainer']}>
        <div className={style['container']}>
          <div className={style['breadcrumb']} onClick={() => this.props.onCountrySelected()}>
            Россия
          </div>
          {fakeRegion}
          {selectedRegion && !isCapital && (
            <div className={style['breadcrumbWrapper']}>
              <div className={style['arrow']} />
              {selectedCity ? (
                <div className={style['breadcrumb']} onClick={() => this.props.onRegionSelected(selectedRegion)}>
                  {this.getName(selectedRegion)}
                </div>
              ) : (
                <Button
                  className={style['endButton']}
                  theme="primary"
                  onClick={() => {
                    if (fakeRegion && selectedRegion !== 'moscow_mo' && selectedRegion !== 'spb_lo') {
                      this.props.onRegionSelected(
                        selectedRegion.id === REGIONS.Moscow || selectedRegion.id === REGIONS.MoscowArea
                          ? 'moscow_mo'
                          : 'spb_lo',
                      );
                    } else {
                      this.props.onCountrySelected();
                    }
                  }}
                >
                  {this.getName(selectedRegion)}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }

  private getName = (selectedRegion: TLocation) => {
    if (selectedRegion === 'moscow_mo') {
      return 'Москва и МО';
    } else if (selectedRegion === 'spb_lo') {
      return 'Санкт-Петербург и ЛО';
    }

    return selectedRegion.displayName;
  };

  private getFakeRegion(selectedRegion: TLocation) {
    if (selectedRegion !== 'moscow_mo' && selectedRegion !== 'spb_lo') {
      if (
        selectedRegion.id === REGIONS.MoscowArea ||
        selectedRegion.id === REGIONS.Moscow ||
        selectedRegion.id === REGIONS.Petersburg ||
        selectedRegion.id === REGIONS.LeningradArea
      ) {
        return (
          <div className={style['breadcrumbWrapper']}>
            <div className={style['arrow']} />
            <div
              className={style['breadcrumb']}
              onClick={() =>
                this.props.onRegionSelected(
                  selectedRegion.id === REGIONS.Moscow || selectedRegion.id === REGIONS.MoscowArea
                    ? 'moscow_mo'
                    : 'spb_lo',
                )
              }
            >
              {selectedRegion.id === REGIONS.Moscow || selectedRegion.id === REGIONS.MoscowArea
                ? 'Москва и МО'
                : 'Санкт Петербург и ЛО'}
            </div>
          </div>
        );
      }
    }

    return '';
  }
}
