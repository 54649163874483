import { PureComponent } from 'react';

import { IOfficialRepresentativeAgent } from 'shared/api/bs_centers';
import { isArrayWithItems } from 'shared/utils';
import { getFormattedPhoneNumber } from 'shared/utils/format';

const styles = require('./index.css');

export interface IOfficialRepresentativeProps {
  agent: IOfficialRepresentativeAgent;
}

export interface IOfficialRepresentativeState {
  isPhonesVisible: boolean;
}

export class OfficialRepresentative extends PureComponent<IOfficialRepresentativeProps, IOfficialRepresentativeState> {
  public state = {
    isPhonesVisible: false,
  };

  public render() {
    return (
      <div className={styles['official-representative']}>
        Официальный представитель:{' '}
        <strong>
          {this.props.agent.name}・{this.showPhones()}
        </strong>
      </div>
    );
  }

  private showPhones = () => {
    const { agent } = this.props;

    if (!isArrayWithItems(agent.visiblePhones)) {
      return null;
    }

    if (this.state.isPhonesVisible) {
      return agent.visiblePhones
        .map(({ countryCode, code, number: phoneNumber }) =>
          getFormattedPhoneNumber({
            countryCode,
            number: `${code}${phoneNumber}`,
          }),
        )
        .join(', ');
    } else {
      return (
        <a className={styles['link']} href="#" onClick={this.toggleIsPhonesVisible}>
          Показать телефон
        </a>
      );
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private toggleIsPhonesVisible = (e: any) => {
    e.preventDefault();
    this.setState({
      isPhonesVisible: !this.state.isPhonesVisible,
    });
  };
}
