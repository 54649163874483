import { Component } from 'react';

import { Button } from 'shared/common/components/button';

import { IHighway } from '../../../../api/directions';

const style = require('./index.css');

export interface IHighwayProps {
  highway: IHighway;
  selected: boolean;

  deselectHighways(highwaysIds: number[]): void;
  selectHighways(highwaysIds: number[]): void;
}

export class Highway extends Component<IHighwayProps> {
  public render() {
    const { selected, highway } = this.props;

    return (
      <div className={style['highway']} onClick={this.handleClick}>
        {selected ? (
          <Button style={style['button']} theme="primary">
            {highway.fullName}
          </Button>
        ) : (
          <span className={style['highway-title']}>{highway.fullName}</span>
        )}
      </div>
    );
  }

  private handleClick = () => {
    const { deselectHighways, highway, selected, selectHighways } = this.props;

    if (selected) {
      return deselectHighways([highway.id]);
    }

    selectHighways([highway.id]);
  };
}
