import { IQuickLinks } from '../common/state';
import { EOfficeType, IJsonQuery } from '../json_query';
import { IMakeRequest } from '../utils/request';

interface IGetQuickLinksResponse {
  data: {
    quickLinks: IQuickLinks;
  };
  status: 'ok';
}

export const getQuickLinks = (makeRequest: IMakeRequest, jsonQuery: IJsonQuery): Promise<IQuickLinks | null> => {
  const isOffice = jsonQuery.office_type && jsonQuery.office_type.value[0] === EOfficeType.Office;
  const isCoworking = jsonQuery.coworking_offer_type && jsonQuery.coworking_offer_type.value.length > 0;

  if (isOffice && isCoworking) {
    return Promise.resolve(null);
  }

  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-quick-links/',
    parameters: {
      jsonQuery,
    },
    method: 'POST',
  })
    .then((response: IGetQuickLinksResponse) => response.data.quickLinks)
    .catch(() => {
      return null;
    });
};
