import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IPromoTagType = 'advanced-developer-promo';

export interface IPromoTag {
  type: 'advanced-developer-promo';
}

const isPromoTag = (tag: TTag): tag is IPromoTag => tag.type === IPromoTagType;

export const promoTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.promo && jsonQuery.promo.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isPromoTag, () => ({ type: IPromoTagType, value }));
    } else {
      return ensureTagNotExists(tags, isPromoTag);
    }
  },
};
