import { Button, IconMore16, IconWarningSign16, UIText2 } from '@cian/ui-kit';

import cn from 'classnames';

import { JoinElectronicTradingButtonContainer } from 'shared/containers/JoinElectronicTradingButtonContainer';

import { Buttons } from '../buttons';
import { LoginMotivationPopup } from '../login_motivation_popup';
import { AdaptiveLikeButton } from '../menu/adaptiveLikeButton';
import { ReportPopup } from '../menu/more/report_popup';
import { PushSubscription } from '../push_subscription';

import * as styles from './TopButtons.css';
import * as buttonsStyles from '../buttons/index.css';

/**
 * Блок кнопок для футера царь-карточки
 *
 * В отличие от базовой версии (Buttons) умеет схлопывать кнопку Избранного (AdaptiveLikeButton) на узких экранах
 * Также убрана кнопка "Подробнее", так как переехала в блок описания (TopDescription)
 */
export class TopButtons extends Buttons {
  public render() {
    const { containerClassName } = this.props;

    return (
      <div className={containerClassName || ''} onClick={e => e.stopPropagation()}>
        {this.renderPhoneButton()}
        {this.renderElectronicTradingButton()}
        {this.renderLikeButton()}
        {this.renderReport()}
        {this.renderMap()}
        {this.renderHideButton()}
        {this.renderMore()}
      </div>
    );
  }

  protected renderReport() {
    const {
      buttonClassName,
      complaintsHelpers: { sendComplaintFeedback, statusChanger, complaintsFormStatus },
    } = this.props;
    const { user } = this.context;
    const { isReportActive } = this.state;

    return (
      <div
        className={cn(buttonClassName, styles['report-button'], isReportActive && buttonsStyles['button-active'])}
        data-testid="report-button"
        ref={this.complaintButtonRef}
      >
        <div title="Пожаловаться на объявление">
          <Button
            beforeIcon={
              <span className={cn(buttonsStyles['report-button'], styles['report-button'])}>
                <IconWarningSign16 />
                <span className={styles['report-button-text']}>
                  <UIText2 color="black_100" fontWeight="700">
                    Пожаловаться
                  </UIText2>
                </span>
              </span>
            }
            size="XS"
            theme="stroke_secondary"
            onClick={this.handleReportButtonClick}
          />
        </div>
        {isReportActive && (
          <div className={cn(buttonsStyles['popup-container'], buttonsStyles['popup-container--big'])}>
            <ReportPopup
              complaintsFormStatus={complaintsFormStatus}
              open={isReportActive}
              popupRef={this.complaintButtonRef}
              sendComplaintFeedback={sendComplaintFeedback}
              statusChanger={statusChanger}
              userEmail={user && user.isAuthenticated ? user.email : ''}
              onClose={this.handleReportClose}
            />
          </div>
        )}
      </div>
    );
  }

  protected renderMore() {
    const { buttonClassName, dynamicButtonClassName } = this.props;
    const { isMorePopupOpen } = this.state;

    return (
      <div
        className={cn(buttonClassName, styles['more-button'], isMorePopupOpen && styles['button-active'])}
        data-testid="more-button"
        ref={this.moreButtonRef}
      >
        <span className={cn(this.isButtonHidden() && dynamicButtonClassName)}>
          <div title="Ещё">
            <Button beforeIcon={<IconMore16 />} size="XS" theme="stroke_secondary" onClick={this.handleMoreClick} />

            {this.renderMorePopup()}
          </div>
        </span>
      </div>
    );
  }

  protected renderLikeButton() {
    const { loginMotivationPopup } = this.context.api;
    const { offer, buttonClassName } = this.props;
    const { isPushSubscriptionPopupOpen } = this.state;

    return (
      <div className={cn(buttonClassName, buttonsStyles['like-container'])} data-testid="like-button">
        <AdaptiveLikeButton isActive={!!offer.isFavorite} toggleActivity={this.handleFavoriteClick} />
        <LoginMotivationPopup id={offer.id} {...loginMotivationPopup} isAdaptive />
        <PushSubscription isOpen={isPushSubscriptionPopupOpen} />
      </div>
    );
  }

  private renderElectronicTradingButton() {
    return <JoinElectronicTradingButtonContainer text="К торгам" />;
  }
}
