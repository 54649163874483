import { numberToPrettyString } from '@cian/utils';

export const getFormattedArea = (partArea?: number) => {
  if (partArea === undefined) {
    return '';
  }

  const area = numberToPrettyString(partArea);

  return `${area} м²`;
};
