import { ca } from '@cian/analytics';

import { IOffer } from 'shared/offer/types';

export const trackJoinTradeClick = (offer: IOffer) => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Phones_Rosimuschestvo_popup_click',
    category: 'Listing',
    products: [offer],
  });
};

export const trackShowTradingWidgetModalClick = (offer: IOffer) => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Open_listing_rosimuschestvo',
    category: 'Phones',
    label: offer.gaLabel,
    products: [offer],
  });
};
