import { IQuestionLayoutProps, useFormField } from '@cian/frontend-request-form-widget';
import { Header2, IMultiSelectProps } from '@cian/ui-kit';

import { head, last } from 'ramda';
import * as React from 'react';

import { EOfficeType } from 'shared/json_query';

import { CustomRadioButtonGroup, ICustomRadioButtonGroup } from '../../components/CustomRadioButtonGroup';
import { Multiselect } from '../../components/Multiselect';

import * as styles from './styles.css';

const FIELDS_TO_EXCLUDE: { [key in 'rent' | 'sale']: string[] } = {
  rent: [],
  sale: [String(EOfficeType.Coworking)],
};

export const ObjectTypeLayout: React.FC<IQuestionLayoutProps> = props => {
  const {
    question: { fields, title },
    actions,
  } = props;

  const {
    field: { value: dealType },
    helpers: { setValue: setDealType },
  } = useFormField<'rent' | 'sale'>({ name: fields[0].name });

  const {
    field: { value: objectType },
    helpers: { setValue: setObjectType },
  } = useFormField<string[]>({ name: fields[1].name });

  const multiselectOptions = React.useMemo(() => {
    const exludedFields = FIELDS_TO_EXCLUDE[dealType];
    const fieldsOptions = fields[1].answers;

    if (!fieldsOptions) {
      return [];
    }

    return fieldsOptions.filter(option => !exludedFields.includes(option.value));
  }, [dealType, fields]);

  const handleDealTypeChange = React.useCallback<NonNullable<ICustomRadioButtonGroup['onChange']>>(
    (_, value) => {
      setDealType(value as 'rent' | 'sale');

      const fieldsToExlude = FIELDS_TO_EXCLUDE[value as 'rent' | 'sale'];
      const hasObjectTypeExludedValues = objectType.some(el => fieldsToExlude.includes(el));

      if (hasObjectTypeExludedValues) {
        const filteredObjectType = objectType.filter(type => !fieldsToExlude.includes(type));
        const values = filteredObjectType.length ? filteredObjectType : [String(EOfficeType.Office)];

        setObjectType(values);
      }
    },
    [objectType, setDealType, setObjectType],
  );

  const onMultiselectChange = React.useCallback<NonNullable<IMultiSelectProps['onChange']>>(
    value => {
      if (!value.length) {
        return;
      }

      if (value.length > 1 && last(value) === String(EOfficeType.Coworking)) {
        setObjectType([String(EOfficeType.Coworking)]);

        return;
      }

      if (value.length > 1 && head(value) === String(EOfficeType.Coworking)) {
        const filteredValues = value.filter(el => el !== String(EOfficeType.Coworking));

        setObjectType(filteredValues as string[]);

        return;
      }

      setObjectType(value as string[]);
    },
    [setObjectType],
  );

  return (
    <div>
      <div className={styles['element']}>
        <Header2>{title}</Header2>
      </div>
      <div className={styles['element']}>
        <CustomRadioButtonGroup
          label={fields[0].label}
          options={fields[0].answers || []}
          value={dealType}
          onChange={handleDealTypeChange}
        />
      </div>
      <div className={styles['element']}>
        <Multiselect
          label={fields[1].label}
          options={multiselectOptions}
          value={objectType}
          onChange={onMultiselectChange}
        />
      </div>
      <div className={styles['element']}>{actions}</div>
    </div>
  );
};

ObjectTypeLayout.displayName = 'ObjectTypeLayout';
