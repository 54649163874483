import { mergeStyles } from '@cian/utils';
import { plural } from '@cian/utils/lib/shared/plural';

import * as React from 'react';

import { IUnderground } from '../../types/offer';
import { isDefined } from '../../utils/isDefined';
import { UndergroundIcon } from '../underground_icon';

const styles = require('./index.css');

export interface IUndergroundProps {
  url: string;
  locationId?: number;
  underground: IUnderground;
  onClick?(e: React.MouseEvent<HTMLAnchorElement>): void;
  hideDot?: boolean;
}

const INTERPUNCT = '\u22C5';

export const Underground: React.FC<IUndergroundProps> = ({ url, locationId, underground, onClick, hideDot }) => {
  const { underConstruction, releaseYear } = underground;

  return (
    <div {...mergeStyles(styles['underground-container'], hideDot && styles['without-dot'])}>
      <a className={styles['underground-link']} href={url} rel="noreferrer" target="_blank" onClick={onClick}>
        <div className={styles['icons']}>
          <UndergroundIcon color={`#${underground.lineColor}`} height={16} regionId={locationId} width={16} />
        </div>
        <div {...mergeStyles(styles['underground-name'], underConstruction && styles['color-grey'])}>
          {underground.name}
        </div>
      </a>
      {underConstruction && <span className={styles['constructing']}>откроется в {`${releaseYear}`}</span>}
      {!hideDot && INTERPUNCT}
      {isDefined(underground.time) && underground.time > 0 && (
        <div className={styles['remoteness']}>
          {underground.time}&nbsp;
          {plural(underground.time, ['минута', 'минуты', 'минут'])}&nbsp;
          {underground.transportType === 'walk' ? 'пешком' : 'на транспорте'}
        </div>
      )}
    </div>
  );
};
