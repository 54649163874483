import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as React from 'react';

export interface ICommonContainerProps extends React.DOMAttributes<HTMLDivElement> {
  content(className?: string): React.ReactNode;
  media?: React.ReactNode;
  containerStyle?: IStyleConfig;
  contentStyle?: IStyleConfig;
  mediaStyle?: IStyleConfig;
  isColorized?: boolean;
  isTop3?: boolean;
  isPaid?: boolean;
  isPremium?: boolean;
  isMainCard?: boolean;
  isRepresentative?: boolean;
  experimentGroup?: number;
}

const style = require('./index.css');

export class HorizontalCard extends React.Component<ICommonContainerProps> {
  public render() {
    const {
      containerStyle,
      content,
      contentStyle,
      isColorized,
      isTop3,
      isRepresentative,
      media,
      mediaStyle,
      onMouseEnter,
      onTouchStart,
      onMouseLeave,
      onTouchMove,
    } = this.props;

    return (
      <div
        {...mergeStyles(
          style['container'],
          isColorized && style['container--colorized'],
          !isColorized && !isTop3 && isRepresentative && style['represent'],
          containerStyle,
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onTouchMove={onTouchMove}
        onTouchStart={onTouchStart}
      >
        <div {...mergeStyles(style['media'], mediaStyle)}>{media}</div>
        <div {...mergeStyles(style[isTop3 ? 'content-top3' : 'content'], contentStyle)}>{content(style['price'])}</div>
      </div>
    );
  }
}
