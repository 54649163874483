import { Dispatch, IAppState, TActions } from '../../common/state';
import { ESortValue, jq } from '../../json_query';

import { makeSearch } from './search';

export type TSortChangedAction = 'filters/sort/SORT_CHANGED';

export interface ISortChangedAction {
  type: TSortChangedAction;
  sort: ESortValue;
}

export function changeSort(nextSort: ESortValue, withoutSearch?: boolean) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const {
      filters: {
        jsonQuery: { sort },
      },
    } = getState();
    if ((!sort && nextSort === ESortValue.Default) || (sort && sort.value === nextSort)) {
      return;
    }

    dispatch({
      type: 'filters/sort/SORT_CHANGED',
      sort: nextSort,
    });

    if (!withoutSearch) {
      dispatch(makeSearch());
    }
  };
}

export function sortReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/sort/SORT_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setSort(action.sort),
        },
      };
    default:
      return state;
  }
}
