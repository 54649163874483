import { ILogger } from '@cian/logger';
import { Button } from '@cian/ui-kit/button';

import * as React from 'react';

import * as styles from './WidgetErrorBoundary.css';

interface IWidgetErrorBoundaryProps {
  logger: ILogger;
  onRetry(): void;
}

interface IWidgetErrorBoundaryState {
  hasError: boolean;
}

export class WidgetErrorBoundary extends React.Component<
  React.PropsWithChildren<IWidgetErrorBoundaryProps>,
  IWidgetErrorBoundaryState
> {
  public state: IWidgetErrorBoundaryState = {
    hasError: false,
  };

  public static getDerivedStateFromError(): IWidgetErrorBoundaryState {
    return {
      hasError: true,
    };
  }

  public componentDidCatch(error: Error, info: React.ErrorInfo) {
    this.props.logger.error(error, { ...info });
  }

  public render(): JSX.Element {
    if (this.state.hasError) {
      return (
        <div className={styles['container']}>
          <p className={styles['text']}>
            Во время загрузки произошла ошибка. Пожалуйста, проверьте своё интернет соединение и попробуйте ещё раз.
          </p>
          <Button onClick={this.handleRetryClick}>Повторить</Button>
        </div>
      );
    }

    return <>{this.props.children}</>;
  }

  private handleRetryClick = () => {
    this.props.onRetry();

    this.setState({
      hasError: false,
    });
  };
}
