import { array, bool, number, object, string } from 'prop-types';

import { CommercialOwner } from '../utils/CommercialOwner';

import type { CommercialOfferCardChildContextTypes } from './types';

export const childContextTypes: CommercialOfferCardChildContextTypes = {
  abUseExperiments: array,
  api: object,
  auctionMaxBet: number,
  auctionMinBet: number,
  baseUrl: string,
  captchaSpamSiteKey: string,
  commercialOwner: CommercialOwner.contextTypes,
  currentSubdomain: string,
  httpApi: object,
  isMlSearchForAll: bool,
  isSimilar: bool,
  jsonQuery: object,
  logger: object,
  maxAuctionBet: number,
  maxAuctionService: string,
  mlSearchRegions: array,
  offer: object,
  offerHelper: object,
  user: object,
  validateStep: bool,
};
