import { mergeStyles } from '@cian/utils';

import { EAnimationClassnames, ETooltipDirection } from './PartnerTooltip';

import * as styles from './PartnerTooltipContent.css';

export interface IPartnerTooltipPopupProps {
  direction: ETooltipDirection;
  animationClassname: EAnimationClassnames;
}

export const PartnerTooltipContent = ({ direction, animationClassname }: IPartnerTooltipPopupProps) => {
  return (
    <div
      {...mergeStyles(styles['tooltip_content'], styles[`tooltip_content--${direction}`], styles[animationClassname])}
    >
      <div className={styles['tooltip_wrapper']}>
        <span className={styles['tooltip_text']}>Партнер ЦИАН по контролю качества объявлений</span>
      </div>
    </div>
  );
};
