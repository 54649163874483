import { Header5 } from '@cian/ui-kit/typography';

import * as React from 'react';

import * as styles from './SummaryHeader.css';

export interface IHeaderProps {
  header: string;
}

export const SummaryHeader: React.FC<IHeaderProps> = ({ header }) => {
  return (
    <div className={styles['header']}>
      <Header5 as="h5" color="black_100" fontWeight="bold">
        {header}
      </Header5>
    </div>
  );
};

SummaryHeader.displayName = 'SummaryHeader';
