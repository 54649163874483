import bag from './bag.png';
import calendar from './calendar.png';
import clocks from './clocks.png';
import eyes from './eyes@2x.png';
import hourglass from './hourglass.png';
import noEntry from './noEntry.png';
import thumbUp from './thumbsUp@2x.png';

export const imagesByValue: { [key: string]: string } = {
  '3m': clocks,
  '6m': hourglass,
  year: calendar,
  broc: bag,
  rent: eyes,
  yes: thumbUp,
  no: noEntry,
};
