import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import * as styles from './AuctionStatusNotification.css';

export type TSetAuctionBetStatus =
  /** Ставка успешно применена */
  | 'completed'
  /** Ставка не применена, ошибка */
  | 'failed'
  /** Ставка не применена, недостаточно средств */
  | 'notEnoughMoney';

export interface IAuctionStatusNotificationState {
  isShow: boolean;
  status: TSetAuctionBetStatus | null;
  onClose?(): void;
  onRetryClick?(): void;
  onPayClick?(): void;
}

const initialState = {
  isShow: false,
  status: null,
  onClose: () => {},
  onRetryClick: () => {},
  onPayClick: () => {},
};

export class AuctionStatusNotification extends React.Component<{}, IAuctionStatusNotificationState> {
  public state = { ...initialState };

  public componentDidMount() {
    document.addEventListener('auctionNotificationShow', this.handleShow);
    document.addEventListener('auctionNotificationHide', this.handleHide);
  }

  public render() {
    if (!this.state.isShow) {
      return null;
    }

    const { status } = this.state;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isError = !!status && (['failed', 'notEnoughMoney'] as any).includes(status);

    return (
      <div
        {...mergeStyles(styles['notification'], isError && styles['notification-error'])}
        onClick={this.handleNotificationClick}
      >
        <div className={styles['close']} onClick={this.handleCloseClick} />

        {status === 'completed' && <div>Ставка применится в течение нескольких минут</div>}

        {status === 'failed' && (
          <div>
            Ошибка на сервере.&nbsp;
            <a className={styles['link']} onClick={this.handleRetryClick}>
              Повторить попытку
            </a>
          </div>
        )}

        {status === 'notEnoughMoney' && (
          <div>
            Недостаточно средств.&nbsp;
            <a className={styles['link']} href="https://my.cian.ru/pay" onClick={this.handlePayClick}>
              Пополнить счёт
            </a>
          </div>
        )}
      </div>
    );
  }

  public componentWillUnmount() {
    document.removeEventListener('auctionNotificationShow', this.handleShow);
    document.removeEventListener('auctionNotificationHide', this.handleHide);
  }

  private handleNotificationClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  private handleRetryClick = () => {
    if (this.state.onRetryClick) {
      this.state.onRetryClick();
    }
  };

  private handlePayClick = () => {
    if (this.state.onPayClick) {
      this.state.onPayClick();
    }
  };

  private handleShow = ({ detail: props }: CustomEvent) => {
    this.setState({ ...props, isShow: true });
  };

  private handleHide = () => {
    this.setState(initialState);
  };

  private handleCloseClick = () => {
    if (this.state.onClose) {
      this.state.onClose();
    }

    this.handleHide();
  };
}
