import * as React from 'react';

import { WrapElementsSpacer } from 'shared/components/WrapElementsSpacer';
import { CoworkingProfitLabel } from 'shared/serp/components/offers/CoworkingProfitBanner';

import { IShieldsOnPhotoProps } from './types';

export const ShieldsOnPhoto: React.FC<IShieldsOnPhotoProps> = props => {
  const { offer, jsonQuery } = props;

  const isMixedOfficeSearch = !!offer.coworkingOfferType && !jsonQuery.coworking_offer_type;

  return (
    <WrapElementsSpacer horizontalSpace={8} verticalSpace={4}>
      {isMixedOfficeSearch && <CoworkingProfitLabel />}
    </WrapElementsSpacer>
  );
};

ShieldsOnPhoto.displayName = 'ShieldsOnPhoto';
