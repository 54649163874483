import { IHttpApi } from '@cian/http-api';
import { IModelResponse } from '@cian/http-api/shared/model';

export type TSetAuctionBetStatus =
  /** Ставка успешно применена */
  | 'completed'
  /** Ставка не применена, ошибка */
  | 'failed'
  /** Ставка не применена, недостаточно средств */
  | 'notEnoughMoney';

export interface ISetAuctionBetResponseSchema {
  status: TSetAuctionBetStatus;
  errorMessage?: string;
}

export interface ISetAuctionBetResponse extends IModelResponse<ISetAuctionBetResponseSchema> {
  statusCode: 200;
}

export interface ISetAuctionBetRequest {
  announcementId: number;
  bet: number;
}

export const setOfferAuctionBet = (httpApi: IHttpApi, offerId: number, bet: number) => {
  return httpApi
    .fetch<ISetAuctionBetRequest, ISetAuctionBetResponse>(
      {
        apiType: 'legacy',
        assertStatusCodes: [200],
        method: 'POST',
        microserviceName: 'announcementApi',
        pathApi: '/v1/announcement-set-bet/',
        parameters: {
          announcementId: offerId,
          bet,
        },
      },
      {
        requestConfig: {
          headers: [['Content-Type', 'application/json']],
          withCredentials: true,
        },
      },
    )
    .then(({ response }) => response);
};
