import { IStyleProps, mergeStyles } from '@cian/utils';

import { Component } from 'react';

const style = require('./index.css');

export interface IPromoInfoProps {
  name: string;
  href: string;
  isSpecProject?: boolean;
  containerStyle?: IStyleProps | string;
}

export class PromoInfo extends Component<IPromoInfoProps> {
  public static defaultProps: IPromoInfoProps = {
    href: '',
    isSpecProject: false,
    name: '',
  };

  public render() {
    return (
      <a href={this.props.href} rel="noreferrer" target="_blank">
        <div
          {...mergeStyles(
            style['promoInfo'],
            !this.props.isSpecProject && style['promoSaleInfo'],
            this.props.containerStyle,
          )}
        >
          <span className={style['promoInfo-text']}>{this.props.name}</span>
        </div>
      </a>
    );
  }
}
