import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IElevatorsHasServiceTagType = 'advanced-elevators-hasService';

export interface IElevatorsHasServiceTag {
  type: 'advanced-elevators-hasService';
}

export const isElevatorsHasServiceTag = (tag: TTag): tag is IElevatorsHasServiceTag =>
  tag.type === IElevatorsHasServiceTagType;

export const elevatorsHasServiceTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.lift_service && jsonQuery.lift_service.value;

    if (value) {
      return ensureSingleTagExists(tags, isElevatorsHasServiceTag, () => ({
        type: IElevatorsHasServiceTagType,
      }));
    } else {
      return ensureTagNotExists(tags, isElevatorsHasServiceTag);
    }
  },
};
