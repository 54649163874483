import { getEventBusIfDefined } from '@cian/event-bus';

import { IJsonQuery } from '../json_query';
import { TLocation } from '../types/location';

import { FDealType, FOfferType, dealTypeFromJsonQuery, isCommercial, offerTypeFromJsonQuery } from './category';
import { LOId, MOId } from './geo';

export function triggerHeaderCategoryChanged(jsonQuery: IJsonQuery) {
  const eventBus = getEventBusIfDefined();
  if (!eventBus) {
    return;
  }

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  let type = 'rent';

  if (isCommercial(offerType)) {
    type = 'commercial';
  } else if (offerType === FOfferType.FlatNew) {
    type = 'newbuildings';
  } else if (dealType === FDealType.Sale) {
    type = 'sale';
  }

  eventBus.emit('header:category_changed', { type });
}

// Событие для смены ссылки на спецпроект в хедере.
// Передаем id региона, для Москвы и СПБ берем области
export function triggerHeaderRegionChanged(location: TLocation) {
  const eventBus = getEventBusIfDefined();
  if (!eventBus) {
    return;
  }

  let regionId: number;

  switch (location) {
    case 'moscow_mo':
      regionId = MOId;
      break;
    case 'spb_lo':
      regionId = LOId;
      break;
    default:
      regionId = location.parentId ? location.parentId : location.id;
  }

  eventBus.emit('header:region_changed', { type: String(regionId) });
}
