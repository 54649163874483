import * as React from 'react';

import { TopPopupContainer } from '../top_popup/container';

import * as styles from './index.css';

export interface IHideOfferTopPupupProps {
  isError: boolean;
  lkUrl: string;
  errorMessage: string;
}

export const HideOfferTopPopup: React.FC<IHideOfferTopPupupProps> = props => {
  const { isError, lkUrl, errorMessage } = props;

  return (
    <TopPopupContainer isError={isError}>
      {isError ? (
        errorMessage
      ) : (
        <>
          Объявление скрыто из результатов поиска. Вы можете восстановить объявление в{' '}
          <a className={styles['popup-link']} href={lkUrl} rel="noreferrer" target="_blank">
            личном кабинете
          </a>
        </>
      )}
    </TopPopupContainer>
  );
};
