import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const TAG_TYPE = 'livingArea';

export interface ILivingAreaTag {
  type: 'livingArea';
  min: number | undefined;
  max: number | undefined;
}

function isLivingAreaTag(tag: TTag): tag is ILivingAreaTag {
  return tag.type === TAG_TYPE;
}

export const livingAreaTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.living_area && jsonQuery.living_area.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isLivingAreaTag, () => ({ type: TAG_TYPE, min: value.gte, max: value.lte }));
    } else {
      return ensureTagNotExists(tags, isLivingAreaTag);
    }
  },
};
