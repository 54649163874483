import { ca } from '@cian/analytics';

import { IUserGADataLayerData } from 'shared/common/state';
import { IJsonQuery } from 'shared/json_query';
import { IOffer, offerHelper } from 'shared/offer';
import { getOfferExtra } from 'shared/tracking/getOfferExtra';
import { TUser } from 'shared/types/user';
import { dealTypeFromJsonQuery } from 'shared/utils/category';
import { isSaleFromSuburbanBuilder } from 'shared/utils/isSaleFromSuburbanBuilder';
import { normalizeAbGroup } from 'shared/utils/normalizeAbGroup';
import { getExtendedOfferEventExtra, getOfferListingType } from 'shared/utils/offer/tracking';
import {
  getDealType,
  getHeadline,
  getObjectTypeWithCategory,
  getObjectTypeWithJsonQuery,
  getOfferParentId,
} from 'shared/utils/trackings';

export function trackPhoneOpened(
  offer: IOffer,
  position: number,
  jsonQuery: IJsonQuery,
  user: TUser,
  userGADataLayerData: IUserGADataLayerData | null,
  offersQty: number,
  queryString: string,
  isSimilar?: boolean,
  isFromBrandBlock?: boolean,
) {
  const { top3Place, ...analyticsInfo } = offerHelper(offer).getAnalyticsInfo();
  const parentId = getOfferParentId(offer, jsonQuery);
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const isCountrysideDeveloper = isSaleFromSuburbanBuilder(offer);
  let extra = getOfferExtra(offer) || {};

  if (parentId) {
    extra = {
      ...extra,
      parentId,
    };
  }

  const product = {
    ...analyticsInfo,
    cianId: offer.cianId,
    dealType: getDealType(dealType),
    headline: getHeadline(offer),
    hiddenBase: Boolean(offer.isInHiddenBase),
    objectType: getObjectTypeWithCategory(offer.category, isCountrysideDeveloper),
    owner: Boolean(offer.isByHomeowner),
    ownerCianId: offer.cianUserId,
    ownerId: offer.userId,
    photosCount: offer.photos ? offer.photos.length : 0,
    position,
    published: offer.status === 'published',
    variant: analyticsInfo.variant ? analyticsInfo.variant.split('/') : undefined,
    podSnos: Boolean(offer.demolishedInMoscowProgramm),
    ...(top3Place && { top3Place }),
    category: undefined,
    quantity: undefined,
    consultant: offer.isDealRequestSubstitutionPhone,
    extra,
  };

  const actionName = isSimilar
    ? 'Open_listing_similar'
    : isFromBrandBlock
      ? 'Open_listing_agent_block'
      : 'Open_listing';

  const pageNumber = window.location.search
    .substr(1)
    .split('&')
    .find(queryPair => /^p$/.test(queryPair.split('=')[0]));

  ca('eventEnrich', {
    name: 'oldevent',
    category: 'Phones',
    action: actionName,
    label: offer.gaLabel,
    event: 'oldevent',

    user: {
      cianUserId: user.isAuthenticated ? user.id : undefined,
      userId: user.isAuthenticated ? user.userId : undefined,
      email: user.isAuthenticated ? user.email : undefined,
      type: (userGADataLayerData && userGADataLayerData.userType) || 'not_authorized',
      abGroup: normalizeAbGroup(userGADataLayerData && Number(userGADataLayerData.abGroup)),
    },

    page: {
      pageType: 'Listing',
      region: jsonQuery.region ? String(jsonQuery.region.value[0]) : '1',
      objectType: getObjectTypeWithJsonQuery(jsonQuery),
      dealType: getDealType(dealType),
      siteType: 'desktop',
      sortType: (jsonQuery.sort && jsonQuery.sort.value) || 'default',
      listingType: getOfferListingType(offer),
      ...getExtendedOfferEventExtra(offer),
      offersQty,
      queryString,
      pageNumber: pageNumber ? Number(pageNumber.split('=')[1]) : 1,
    },

    products: [product],

    searchConditions: jsonQuery,
  });
}
