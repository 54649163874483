import cn from 'classnames';
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { getAgentLevelInfo } from 'shared/utils/getAgentLevelInfo';
import { showAgentLevel } from 'shared/utils/showAgentLevel';

import { CommercialOwner } from '../../utils/CommercialOwner';
import { AgentLevelView } from '../AgentLevelView';
import { AgentWorkHonestlyProofs, showAgentHonestlyProofs } from '../AgentWorkHonestlyProofs';
import { ContactStatus } from '../ContactStatus';
import { PartnerTooltip } from '../PartnerTooltip';
import { RosreestrVerified } from '../RosreestrVerified';
import { UserInfoBrandPopup } from '../UserInfoBrandPopup';
import { ShowMoreOffers } from '../showMoreOffers';

import { IAgentBrandContext } from './types';

import * as styles from './HorizontalAgentBrand.css';

export interface IHorizontalAgentBrandProps {
  wrapperClass?: string;
}

/**
 * Компонент информации об агентстве для узкой версии царь-карточки.
 *
 * Рендерится как горизонтальный блок под галереей начиная с разрешения 1240px по ширине (и меньше).
 * Имеет существенные структурные отличия от основного блока (AgentBrand), поэтому не наследуется от него.
 */
export class HorizontalAgentBrand extends React.Component<IHorizontalAgentBrandProps> {
  public readonly context: IAgentBrandContext;

  public static contextTypes: Record<keyof HorizontalAgentBrand['context'], PropTypes.Requireable<unknown>> = {
    offer: PropTypes.object,
    user: PropTypes.object,
    offerHelper: PropTypes.object,
    countrysideOffersBuilderBadge: PropTypes.bool,
    onlineShowEnabled: PropTypes.bool,
    commercialOwner: CommercialOwner.contextTypes,
    abUseExperiments: PropTypes.arrayOf(
      PropTypes.shape({ experimentName: PropTypes.string, string: PropTypes.string }),
    ),
  };

  public render() {
    return (
      <div className={cn(styles['agent'], this.props.wrapperClass)} onClick={this.handleClickWithStopPropagation}>
        {this.renderContactLink()}

        <UserInfoBrandPopup
          isReverseOrder
          content={this.renderBlock()}
          userInfoStyle={styles['user-info']}
          userInfoStyleAction={styles['user-info-action']}
        />
      </div>
    );
  }

  private renderContactLink() {
    const { offer, onlineShowEnabled } = this.context;
    const canShowOnline = onlineShowEnabled && Boolean(offer.user && offer.user.canShowOnline);

    if (!canShowOnline) {
      return null;
    }

    return (
      <ContactStatus
        labelText="Онлайн-показ"
        tooltipText="Вам могут провести онлайн-показ объекта при помощи видеозвонка в WhatsApp, Viber, FaceTime или другой программы"
        tooltipTitle="Выбирайте не выходя из дома"
      />
    );
  }

  private renderBlock() {
    const companyName = this.context.offerHelper.presenters.getCompanyName();
    const { offer, commercialOwner } = this.context;
    const { isTop3, user: offerUser, isPremium } = offer;
    const experienceString = this.context.offerHelper.presenters.getExperienceString();

    const masterAgent = offerUser ? offerUser.masterAgent : null;
    const name = this.context.offerHelper.presenters.getDisplayName();
    const agentAvatarUrl = this.context.offerHelper.presenters.getAvatarUrl();
    const isCianPartner = !!(offer.user && offer.user.isCianPartner);
    const userLevelInfo = getAgentLevelInfo(offer);

    return (
      <div className={styles['main-info']}>
        {(isTop3 || isPremium) && agentAvatarUrl ? (
          <img alt="avatar" className={styles['avatar']} src={agentAvatarUrl} />
        ) : null}

        <div className={styles['main_info-data']}>
          <div className={styles['name']}>
            <span className={styles['name_wrapper']}>{name}</span>
            <div className={styles['badge-container']}>
              {showAgentLevel(offer) && !!userLevelInfo && (
                <div
                  className={styles['honest-realtor-new-commercial-wrapper']}
                  data-testid="honest-realtor-new-commercial-wrapper"
                >
                  <AgentLevelView extendedView agentLevelInfo={userLevelInfo} />
                </div>
              )}

              {isCianPartner && (
                <PartnerTooltip
                  master={<div className={cn(styles['badge'], styles['text-badge'], styles['green'])}>Партнёр</div>}
                />
              )}
            </div>
          </div>

          {showAgentHonestlyProofs(offer) && <AgentWorkHonestlyProofs offer={offer} />}

          {commercialOwner?.showRosreestrVerified && (
            <div data-testid="rosreestr-verified">
              <RosreestrVerified />
            </div>
          )}

          {!!(companyName && !masterAgent) && <div className={styles['company-name']}>{companyName}</div>}

          {experienceString && <div className={styles['experience']}>{experienceString}</div>}

          {offer.isTop3 && (
            <div onClick={this.handleClickWithStopPropagation}>
              <ShowMoreOffers />
            </div>
          )}
        </div>
      </div>
    );
  }

  private handleClickWithStopPropagation = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };
}
