import { IOffer } from '../repositories/search-offers.legacy/v2/types/offer';

/** Находит первое объявление текущего пользователя в списке объявлений */
export const getFirstNextUserOffer = (offers: IOffer[], startIndex: number = 0): IOffer | undefined => {
  for (let i = startIndex; i < offers.length; i++) {
    const offer = offers[i];
    const { auction } = offer;

    if (auction && auction.isOwn && auction.isEditable) {
      return offer;
    }
  }

  return undefined;
};
