import { sendFeedbackFrom } from '../../../api/complaints/complaints';
import { Dispatch, IAppState } from '../../../common/state';

export interface IComplaintFeedbackBody {
  email: string;
  complaintId: number;
}

export enum EFeedbackComplaint {
  default,
  loading,
  success,
  error,
}

export interface IFeedbackComplaintState {
  status: EFeedbackComplaint;
}

export interface IFeedbackComplaintStateChanger {
  type: 'IFeedbackComplaintStateChanger';
  status: EFeedbackComplaint;
}

export type TFeedbackComplaintActions = IFeedbackComplaintStateChanger;

export function statusChanger(status: EFeedbackComplaint): IFeedbackComplaintStateChanger {
  return {
    type: 'IFeedbackComplaintStateChanger',
    status,
  };
}

export function sendFeedbackComplaint(body: IComplaintFeedbackBody) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { httpApi } = getState();

    dispatch(statusChanger(EFeedbackComplaint.loading));

    return sendFeedbackFrom(httpApi, body)
      .then(() => {
        dispatch(statusChanger(EFeedbackComplaint.success));
      })
      .catch(() => {
        dispatch(statusChanger(EFeedbackComplaint.error));
      });
  };
}

export const defaultState: IFeedbackComplaintState = {
  status: EFeedbackComplaint.default,
};

export function complaintFeedbackReducer(state: IAppState, action: TFeedbackComplaintActions): IAppState {
  switch (action.type) {
    case 'IFeedbackComplaintStateChanger':
      return {
        ...state,
        complaintFeedbackFrom: {
          ...state.complaintFeedbackFrom,
          status: action.status,
        },
      };

    default:
      return state;
  }
}
