export interface IPriorities {
  [index: string]: number;
}

const PRIORITIES: IPriorities = {
  auction: 4,
  colorized: 1,
  payed: 2,
  premium: 3,
  top3: 5,
};

export function sortVas(vasList: string[], priorities: IPriorities = PRIORITIES): string[] {
  return vasList.slice(0).sort((a, b) => {
    const aPriority = priorities[a];
    const bPriority = priorities[b];

    // Не переписывать на тернарник, иначе падают тесты
    if (aPriority <= bPriority) {
      return 1;
    }

    return -1;
  });
}
