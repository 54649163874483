import { mergeStyles } from '@cian/utils';

import { EGalleryItemStatus, GalleryItem, IGalleryItemProps, IGalleryItemState } from '../item';

const style = require('./index.css');

export interface IGalleryImageProps extends IGalleryItemProps {
  src: string;
  alt?: string;
  isLayout?: boolean;
  layoutPhotoStyle?: string;
}

export type IGalleryImageState = IGalleryItemState;

export class GalleryImage extends GalleryItem<IGalleryImageProps, IGalleryImageState> {
  private image: HTMLImageElement;

  public constructor(props: IGalleryImageProps) {
    super(props);

    if (!this.props.onStateChange) {
      throw new Error('onStateChange is required');
    }

    this.state = {
      status: EGalleryItemStatus.Pending,
    };
  }

  public componentWillUnmount() {
    this.destroyLoader();
  }

  public load() {
    if (this.state.status === EGalleryItemStatus.Pending) {
      this.createLoader();
    }
  }

  public render() {
    const { isLayout, layoutPhotoStyle } = this.props;

    return (
      <img
        {...mergeStyles(style['image'], isLayout && layoutPhotoStyle, isLayout && style['image--layout'])}
        alt={this.props.alt}
        src={this.props.src}
      />
    );
  }

  private createLoader() {
    this.stateChange(EGalleryItemStatus.Loading);

    this.image = new Image();
    this.image.onload = this.handleLoad;
    this.image.onerror = this.image.onabort = this.handleError;
    this.image.src = this.props.src;
  }

  private destroyLoader() {
    if (this.image) {
      // @ts-ignore
      delete this.image.onload;
      // @ts-ignore
      delete this.image.onerror;
      // @ts-ignore
      delete this.image.onabort;
      // @ts-ignore
      delete this.image;
    }
  }

  private handleLoad = () => {
    this.destroyLoader();
    this.stateChange(EGalleryItemStatus.Loaded);
  };

  private handleError = () => {
    this.destroyLoader();
    this.stateChange(EGalleryItemStatus.Erroneous);
  };

  private stateChange(status: EGalleryItemStatus, callback?: () => void) {
    this.setState(
      {
        status,
      },
      () => callback && callback(),
    );

    if (this.props.onStateChange) {
      this.props.onStateChange(status);
    }
  }
}
