import { plural } from '@cian/utils';

const styles = require('./LinkToDetails.css');

interface ILinkToDetails {
  count: number;
  url: string;
}

export function LinkToDetails({ count, url }: ILinkToDetails) {
  return (
    <a className={styles['link-to-details']} href={`${url}#amenities`}>
      {`+ ${count} ${plural(count, ['удобство', 'удобства', 'удобств'])}`}
    </a>
  );
}
