import { IAppState } from '../../../common/state';
import { INewbuilding } from '../../../types/newbuildingHeader';

const defaultState: INewbuilding | null = null;

export function newbuildingInformationReducer(state: IAppState): IAppState {
  return {
    ...state,
    newbuildingInformation: state.newbuildingInformation || defaultState,
  };
}
