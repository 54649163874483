import * as React from 'react';

import { ICoworking } from '../../types/offer';

import * as styles from './index.css';

export const Coworking: React.FC<ICoworking> = ({ name, url }) => {
  if (!name) {
    return null;
  }
  const coworkingName = `Коворкинг «${name}»`;

  if (url) {
    return (
      <a className={styles['building-link']} href={url}>
        {coworkingName}
      </a>
    );
  }

  return <div>{coworkingName}</div>;
};

Coworking.displayName = 'Coworking';
