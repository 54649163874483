import { Tooltip, UIHeading5, UIText2 } from '@cian/ui-kit';

import { PureComponent } from 'react';

import * as styles from './ContactStatus.css';

export interface IContactStatusProps {
  labelText: string;
  tooltipTitle: string;
  tooltipText: string;
}

interface IContactStateProps {
  popupShown: boolean;
}

export class ContactStatus extends PureComponent<IContactStatusProps, IContactStateProps> {
  public constructor(props: IContactStatusProps) {
    super(props);
    this.state = {
      popupShown: false,
    };
  }

  public render() {
    const { labelText, tooltipText, tooltipTitle } = this.props;
    const { popupShown } = this.state;

    return (
      <div className={styles['container']} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
        <Tooltip
          open={popupShown}
          placement="bottom"
          theme="white"
          title={
            <div className={styles['tooltip-container']}>
              <UIHeading5>{tooltipTitle}</UIHeading5>
              <UIText2 color="black_60">{tooltipText}</UIText2>
            </div>
          }
        >
          <div className={styles['label']}>{labelText}</div>
        </Tooltip>
      </div>
    );
  }

  private handleMouseEnter = () => {
    this.setState({ popupShown: true });
  };

  private handleMouseLeave = () => {
    this.setState({ popupShown: false });
  };
}
