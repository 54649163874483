import {
  IAddOfferToComparisonResponse,
  IAddOfferToComparisonResponse200,
} from 'shared/repositories/offers-comparison/v1/add-offer-to-comparison';

export const mapAddOfferToComparisonResponse = (
  response: IAddOfferToComparisonResponse,
): IAddOfferToComparisonResponse200 => {
  return {
    statusCode: 200,
    headers: [],
    response,
  };
};
