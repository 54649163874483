//функции отправки аналитики по всем событиям, связанным со скрытием офера
// описание событиий https://cianru.atlassian.net/wiki/spaces/ML/pages/671222253

import { ca } from '@cian/analytics';

import { getCustomUrl } from '../filters/tracking';
import { IJsonQuery } from '../json_query';
import { IOffer, offerHelper } from '../offer';
import { dealTypeFromJsonQuery } from '../utils/category';
import { getDealType, getObjectTypeWithJsonQuery, getOfferParentId } from '../utils/trackings';

export interface IHideOfferProducts {
  brand: string | undefined;
  id: number | undefined;
  cianId: number;
  price: number | undefined;
  dealType: string;
  objectType: string;
  variant: string[];
  parentId: number | undefined;
  quantity: 1;
  published: boolean;
  owner: boolean;
  ownerCianId: number | null;
  ownerId: number | null;
  consultant?: boolean;
}

export interface IHideOfferAnalyticsParams {
  offer: IOffer;
  jsonQuery: IJsonQuery;
  action: string;
}

/* istanbul ignore next */
export function getProducts(offer: IOffer, jsonQuery: IJsonQuery): IHideOfferProducts {
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const parentId = getOfferParentId(offer, jsonQuery);
  const { id, price, brand, variant } = offerHelper(offer).getAnalyticsInfo();

  return {
    brand,
    cianId: offer.cianId,
    dealType: getDealType(dealType),
    id,
    objectType: getObjectTypeWithJsonQuery(jsonQuery),
    owner: Boolean(offer.isByHomeowner),
    ownerCianId: offer.cianUserId,
    ownerId: offer.userId,
    parentId: parentId || undefined,
    price,
    published: offer.status === 'published',
    variant: variant ? variant.split('/') : [],
    quantity: 1,
    consultant: offer.isDealRequestSubstitutionPhone,
  };
}

//функция для отправки событий скрытия/восстановления

/* istanbul ignore next */
export function trackHiddenObjects(params: IHideOfferAnalyticsParams) {
  const { action, offer, jsonQuery } = params;

  ca('event', {
    action,
    category: 'HideObject',
    name: 'oldevent',
    products: getProducts(offer, jsonQuery),
    label: getCustomUrl(jsonQuery),
  });
}
