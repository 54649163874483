/**
 * @todo Удалить директорию
 * @description Данный функционал появился в задаче CD-162829, будет удалён в задаче CD-162909
 */
import { BestPlaceBanner } from 'shared/components/BestPlaceBanner';

export const BestPlaceBannerContainer = () => {
  const onBannerClick = () => {
    window.open('http://promo.cian.ru/geoanalytics');
  };

  return <BestPlaceBanner onClick={onBannerClick} />;
};
