import { IAppState, TActions } from '../../../common/state';
import { jq, jqs } from '../../../json_query';

const IBuildingDoneChangedActionType = 'filters/done_year/BUILDING_DONE_CHANGED';
const IBuildingDoneInYearChangedActionType = 'filters/done_year/BUILDING_DONE_IN_YEAR_CHANGED';
const IBuildingDoneLaterChangedActionType = 'filters/done_year/BUILDING_DONE_LATER_CHANGED';

export interface IBuildingDoneChangedAction {
  type: 'filters/done_year/BUILDING_DONE_CHANGED';
  value: boolean;
}

export interface IBuildingDoneInYearChangedAction {
  type: 'filters/done_year/BUILDING_DONE_IN_YEAR_CHANGED';
  value: boolean;
  year: number;
}

export interface IBuildingDoneLaterChangedAction {
  type: 'filters/done_year/BUILDING_DONE_LATER_CHANGED';
  year: number | undefined;
}

export function changeBuildingDone(value: boolean): IBuildingDoneChangedAction {
  return {
    type: IBuildingDoneChangedActionType,
    value,
  };
}

export function changeBuildingDoneInYear(value: boolean, year: number): IBuildingDoneInYearChangedAction {
  return {
    type: IBuildingDoneInYearChangedActionType,
    value,
    year,
  };
}

export function changeBuildingDoneLater(year: number | undefined): IBuildingDoneLaterChangedAction {
  return {
    type: IBuildingDoneLaterChangedActionType,
    year,
  };
}

export function doneYearReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IBuildingDoneChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setBuildingDone(action.value),
        },
      };

    case IBuildingDoneInYearChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setBuildingDoneInYear(action.year)
            : jq(state.filters.jsonQuery).unsetBuildingDoneInYear(action.year),
        },
      };

    case IBuildingDoneLaterChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery:
            action.year === undefined
              ? jq(state.filters.jsonQuery).unsetBuildingDoneLater()
              : jq(state.filters.jsonQuery).setBuildingDoneLater(action.year),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-doneYear-isDone') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setBuildingDone(false),
          },
        };
      }

      if (action.tag.type === 'advanced-doneYear-years') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetBuildingDoneInYear(action.tag.value),
          },
        };
      }

      if (action.tag.type === 'advanced-doneYear-after') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetBuildingDoneLater(),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jqs(state.filters.jsonQuery, [
            jsonQuery => jq(jsonQuery).setBuildingDone(false),
            jsonQuery => jq(jsonQuery).unsetBuildingDoneInYears(),
            jsonQuery => jq(jsonQuery).unsetBuildingDoneLater(),
          ]),
        },
      };

    default:
      return state;
  }
}
