import { Ref, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import { getCommercialFiltersMarkup } from '../../selectors/getCommercialFiltersMarkup';

import { WrapperWithBorder } from './internal/components/WrapperWithBorder';

export const CommercialFiltersContainer = forwardRef(function CommercialFilters(_, ref: Ref<HTMLDivElement>) {
  const { bodyHtml = '' } = useSelector(getCommercialFiltersMarkup) || {};

  if (!bodyHtml) {
    return null;
  }

  return (
    <WrapperWithBorder>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: bodyHtml }} ref={ref} suppressHydrationWarning={true} />
    </WrapperWithBorder>
  );
});

CommercialFiltersContainer.displayName = 'CommercialFiltersContainer';
