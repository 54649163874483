import { IJsonQueryUnderground } from '../types/json-query';
import { IOffer, IUnderground } from '../types/offer';

import { getOrder } from './getOrder';

/**
 * Берем метро из гео офера geo.undergrounds
 * выводим первый элемент из списка, отсортировав в соответствии с geo.sortedGeoIds
 * @param offer
 * @return IUnderground | undefined
 */
export function getUnderground(offer: IOffer): IUnderground | undefined {
  const { geo } = offer;
  if (geo && geo.undergrounds) {
    const undergroundOrderMap: Map<number, number> = (
      (geo.sortedGeoIds || []).filter(item => item.type === 'underground') as IJsonQueryUnderground[]
    ).reduce((map, item, index) => {
      map.set(item.id, index);

      return map;
    }, new Map<number, number>());

    /**
     * Сортируем согласно порядку полученному из geo.sortedGeoId
     * станции, по каким то причинам, не попавшие в geo.sortedGeoId, идут низшим приоритетом
     */
    const sortedUndergrounds = geo.undergrounds
      .concat()
      .sort(
        (a, b) =>
          getOrder<number, number>(undergroundOrderMap, a.id, Infinity) -
          getOrder<number, number>(undergroundOrderMap, b.id, Infinity),
      );

    return sortedUndergrounds[0];
  }

  return undefined;
}
