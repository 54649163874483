import { TBSCentersSuggestionShort } from '../../api/geosuggest/bs_centers';
import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export const IBSCentersChangedActionType = 'filters/bs_centers/BS_CENTERS_CHANGED';

export interface IBSCentersChangedAction {
  type: 'filters/bs_centers/BS_CENTERS_CHANGED';
  bsCenterId: number;
  text: string;
}

export function selectBSCenter(bsCenter: TBSCentersSuggestionShort): IBSCentersChangedAction {
  return {
    type: IBSCentersChangedActionType,
    bsCenterId: bsCenter.id,
    text: bsCenter.name,
  };
}

export function bsCenterReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IBSCentersChangedActionType: {
      const tagsData = { ...state.filters.tagsData };

      tagsData.bsCenters = { ...tagsData.bsCenters, [action.bsCenterId]: action.text };

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setBSCenter(action.bsCenterId, true),
          tagsData,
        },
      };
    }

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'bs_center') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setBSCenter(action.tag.value, false),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetBSCenters(),
        },
      };

    default:
      return state;
  }
}
