import * as React from 'react';

type IIconProps = React.SVGProps<SVGSVGElement>;

export function CloseIcon(props: IIconProps) {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg height="12" viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6 4.574L1.723.297a1.007 1.007 0 0 0-1.427 0 1.006 1.006 0 0 0 0 1.426L4.575 6 .297 10.277a1.007 1.007 0 0 0 0 1.427 1.006 1.006 0 0 0 1.426 0L6 7.425l4.277 4.277a1.007 1.007 0 0 0 1.427 0 1.006 1.006 0 0 0 0-1.426L7.425 6l4.277-4.277a1.007 1.007 0 0 0 0-1.427 1.006 1.006 0 0 0-1.426 0L6 4.575z"
        fill="#7A7A7A"
        fillRule="evenodd"
      />
    </svg>
  );
}
