import { ECoworkingAccess } from 'shared/json_query';

import { ITagDefinition, TTag } from '..';
import { getCoworkingAccessFromJsonQuery } from '../../../../utils/coworkingAccess';
import { ensureSingleTagExists } from '../helpers';

export const COWORKING_ACCESS_TAG_TYPE = 'coworking-access';

export interface ICoworkinAccessTag {
  type: 'coworking-access';
  value: ECoworkingAccess;
}

export const isCoworkingAccessTag = (tag: TTag): tag is ICoworkinAccessTag => tag.type === COWORKING_ACCESS_TAG_TYPE;

export const coworkingAccessTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = getCoworkingAccessFromJsonQuery(jsonQuery);

    const nextTags = tags.filter(
      tag => tag.type !== COWORKING_ACCESS_TAG_TYPE || (values && values.some(value => value === tag.value)),
    );

    return values.reduce(
      (result, value: ECoworkingAccess) =>
        ensureSingleTagExists(
          result,
          (tag: TTag): tag is ICoworkinAccessTag => isCoworkingAccessTag(tag) && tag.value === value,
          () => ({ type: COWORKING_ACCESS_TAG_TYPE, value }),
        ),
      nextTags,
    );
  },
};
