import { connect } from 'react-redux';

import { IAppState } from 'shared/common/state';
import { prepareTrackingData } from 'shared/utils/prepareTrackingData';

import { IMortgageSimpleBannerOwnProps, IMortgageSimpleBannerStateProps, MortgageSimpleBanner } from './mortgage';

export function mapStateToProps(state: IAppState): IMortgageSimpleBannerStateProps {
  const { filters, results, userGADataLayerData, breadcrumbs } = state;

  return {
    tracking: prepareTrackingData(filters, results, breadcrumbs, userGADataLayerData),
  };
}

export const MortgageSimpleBannerContainer = connect<
  IMortgageSimpleBannerStateProps,
  {},
  IMortgageSimpleBannerOwnProps
>(mapStateToProps)(MortgageSimpleBanner);
