import { numberToPrettyString } from '@cian/utils';

import { IAreaPart } from '../../../../../repositories/search-offers.legacy/v2/types';

export const getPrice = (isRent?: boolean, part?: IAreaPart): string => {
  if (part && part.price) {
    const { price } = part;

    return `${numberToPrettyString(price)} ₽${isRent ? '/мес.' : ''}`;
  }

  return '';
};
