import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const INotLastFloorTagType = 'advanced-floor-notLastFloor';

export interface INotLastFloorTag {
  type: 'advanced-floor-notLastFloor';
  value: boolean;
}

function isNotLastFloorTag(tag: TTag): tag is INotLastFloorTag {
  return tag.type === INotLastFloorTagType;
}

export const notLastFloorTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.not_last_floor && jsonQuery.not_last_floor.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isNotLastFloorTag, () => ({ type: INotLastFloorTagType, value }));
    } else {
      return ensureTagNotExists(tags, isNotLastFloorTag);
    }
  },
};
