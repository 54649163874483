import { Header5, IconArrowUpRight16, InlineText2 } from '@cian/ui-kit';

import * as React from 'react';

import * as styles from './index.css';

export const TooltipContent: React.FC = () => {
  return (
    <div className={styles['container']}>
      <Header5>Коворкинг на 38% дешевле</Header5>
      <InlineText2>обычного офиса, по результатам</InlineText2>
      <InlineText2>нашего исследования</InlineText2>
      <a className={styles['link']} href="https://promo.cian.ru/coworking" rel="noreferrer" target="_blank">
        <InlineText2 color="primary_100">Подробный разбор</InlineText2>
        <span className={styles['icon']}>
          <IconArrowUpRight16 color="primary_100" />
        </span>
      </a>
    </div>
  );
};

TooltipContent.displayName = 'TooltipContent';
