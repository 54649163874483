import { IAppState, TActions } from '../../../common/state';
import { ERoomsTotal } from '../../../json_query';

const IRoomsTotalChangedActionType = 'filters/rooms_total/ROOMS_TOTAL_CHANGED';

export interface IRoomsTotalChangedAction {
  type: 'filters/rooms_total/ROOMS_TOTAL_CHANGED';
  roomsTotal: ERoomsTotal;
}

export function changeRoomsTotal(roomsTotal: ERoomsTotal): IRoomsTotalChangedAction {
  return {
    type: IRoomsTotalChangedActionType,
    roomsTotal,
  };
}

export function roomsTotalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IRoomsTotalChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            rooms_count:
              action.roomsTotal !== ERoomsTotal.Any
                ? {
                    type: 'terms',
                    value: [action.roomsTotal],
                  }
                : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-roomsTotal') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              rooms_count: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            rooms_count: undefined,
          },
        },
      };

    default:
      return state;
  }
}
