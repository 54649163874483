import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IDomrfTagType = 'domrf';

export interface IDomrfTag {
  type: 'domrf';
}

function isDomrfTag(tag: TTag): tag is IDomrfTag {
  return tag.type === IDomrfTagType;
}

export const domrfTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.domrf && jsonQuery.domrf.value;

    if (value) {
      return ensureSingleTagExists(tags, isDomrfTag, () => ({ type: IDomrfTagType }));
    } else {
      return ensureTagNotExists(tags, isDomrfTag);
    }
  },
};
