import { ECoworkingOfferType } from 'shared/repositories/common/json_query/enums';

import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export interface ICoworkingOfferTypeChangedAction {
  type: 'filters/coworking_offer_type/COWORKING_OFFER_TYPE_SELECTED';
  coworkingOfferType: ECoworkingOfferType;
}

export function selectCoworkingOfferType(coworkingOfferType: ECoworkingOfferType): ICoworkingOfferTypeChangedAction {
  return {
    type: 'filters/coworking_offer_type/COWORKING_OFFER_TYPE_SELECTED',
    coworkingOfferType,
  };
}

export function coworkingOfferTypeReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/coworking_offer_type/COWORKING_OFFER_TYPE_SELECTED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.selectCoworkingOfferType(action.coworkingOfferType),
        },
      };
    default:
      return state;
  }
}
