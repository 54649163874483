import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import * as style from './index.css';
import * as danger from './themes/danger.css';
import * as primary from './themes/primary.css';

export type TStyle = 'primary' | 'danger';
export type TSize = 'default' | 'small' | 'large' | 'xlarge';

type IThemesMap = {
  [key in TStyle]: {
    [key: string]: string;
  };
};

const themesMap: IThemesMap = {
  danger,
  primary,
};

export interface IButtonProps {
  className?: string;
  style?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;

  /**
   * Тема
   * @type {TStyle}
   * @memberof IButtonProps
   */
  theme: TStyle;

  /**
   * Режим отображения
   * @type {('light' | 'outline')}
   * @memberof IButtonProps
   */
  mode?: 'light' | 'outline';

  /**
   * Размер
   * @type {TSize}
   * @memberof IButtonProps
   */
  size?: TSize;
}

export const Button: React.FC<React.PropsWithChildren<IButtonProps>> = props => {
  const { theme, mode, size, ...sharedProps } = props;

  const mainStyle = mergeStyles(
    style['main'],
    size === 'large' && style['main--large'],
    size === 'xlarge' && style['main--xlarge'],
    size === 'small' && style['main--small'],
    mode === 'outline' && style['outline'],
    mode === 'outline' && size === 'large' && style['outline--large'],
    mode === 'outline' && size === 'xlarge' && style['outline--xlarge'],
  );

  let themeStyle = themesMap[theme]['default'];
  if (mode) {
    if (mode === 'light') {
      themeStyle = themesMap[theme]['light'];
    } else if (mode === 'outline') {
      themeStyle = themesMap[theme]['outline'];
    }
  }

  return <button {...sharedProps} {...mergeStyles(mainStyle, themeStyle, props.className, props.style)} />;
};
