import { IAdFoxBannerContext } from '@cian/adfox/build/react';

import * as React from 'react';

const style = require('./index.css');

export interface ISeoBannerProps {
  context: IAdFoxBannerContext;
}

export const SeoBanner: React.FC<ISeoBannerProps> = props => {
  return (
    <a className={style.link} href={props.context.url} rel="noreferrer" target="_blank">
      <div className={style['bannerContainer']}>
        <div
          className={style['photo']}
          style={{
            backgroundImage: `url(${props.context.bgImageUrl})`,
          }}
        />
        <div className={style['infoContainer']}>
          <div className={style['title']}>{props.context.title}</div>
          <div className={style['description']}>{props.context.description}</div>
          <div className={style['phone']}>{props.context.phone}</div>
        </div>
        <div className={style['note']}>{props.context.note}</div>
      </div>
    </a>
  );
};
