import { call, put, select, take } from 'redux-saga/effects';

import { TActions } from '../../../common/state/actions';
import { IAppState } from '../../../common/state/app_state';
import { ILocation, TLocation } from '../../../types/location';
import { IRequestCitiesResponse, requestCities } from '../../api';

export interface ILocationSwitcherOpenRequestedAction {
  type: 'filters/location_switcher/LOCATION_SWITCHER_OPEN_REQUESTED';
}

export interface ILocationSwitcherOpenedAction {
  type: 'filters/location_switcher/LOCATION_SWITCHER_OPENED';
  selectedRegion: ILocation | undefined;
  selectedCity: ILocation | undefined;
  cities: ILocation[];
}

export interface ILocationSwitcherClosedAction {
  type: 'filters/location_switcher/LOCATION_SWITCHER_CLOSED';
}

export function openLocationSwitcher(): ILocationSwitcherOpenRequestedAction {
  return {
    type: 'filters/location_switcher/LOCATION_SWITCHER_OPEN_REQUESTED',
  };
}

export function closeLocationSwitcher(): ILocationSwitcherClosedAction {
  return {
    type: 'filters/location_switcher/LOCATION_SWITCHER_CLOSED',
  };
}

function findRegion(regions: ILocation[], id: number): ILocation | undefined {
  return regions.filter(region => region.id === id).shift();
}

export function* locationSwitcherOpenSaga() {
  while (true) {
    yield take('filters/location_switcher/LOCATION_SWITCHER_OPEN_REQUESTED');

    const state: IAppState = yield select();

    const currentLocation = state.filters.currentLocation;
    let selectedRegion: TLocation | undefined;
    let selectedCity: ILocation | undefined;
    let cities: ILocation[] = [];
    if (currentLocation === 'moscow_mo' || currentLocation === 'spb_lo') {
      selectedRegion = currentLocation;
    } else if (currentLocation.parentId) {
      selectedCity = currentLocation;
      selectedRegion = findRegion(state.filters.regions, currentLocation.parentId);
      const res: IRequestCitiesResponse = yield call(requestCities, state.makeRequest, currentLocation.parentId);
      cities = res.items;
    } else {
      selectedRegion = currentLocation;
      const res: IRequestCitiesResponse = yield call(requestCities, state.makeRequest, selectedRegion.id);
      cities = res.items;
    }

    yield put({
      type: 'filters/location_switcher/LOCATION_SWITCHER_OPENED',
      selectedRegion,
      selectedCity,
      cities,
    } as ILocationSwitcherOpenedAction);
  }
}

export function locationSwitcherPopupReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/location_switcher/LOCATION_SWITCHER_OPEN_REQUESTED':
      return {
        ...state,
        isFetching: true,
      };

    case 'filters/location_switcher/LOCATION_SWITCHER_OPENED':
      return {
        ...state,
        isFetching: false,
        filters: {
          ...state.filters,
          backup: {
            jsonQuery: state.filters.jsonQuery,
            tags: state.filters.tags,
          },
          locationSwitcher: {
            isLocationSwitcherOpened: true,
            selectedRegion: action.selectedRegion,
            selectedCity: action.selectedCity,
            cities: action.cities,
            isCitiesLoading: false,
            autocompleteSuggestions: [],
            isAutocompleteLoading: false,
          },
        },
      };

    case 'filters/location_switcher/LOCATION_SWITCHER_CLOSED':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters.backup,
          backup: undefined,
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            isLocationSwitcherOpened: false,
          },
        },
      };

    case 'filters/location_switcher/LOCATION_APPLIED':
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: undefined,
          locationSwitcher: {
            ...state.filters.locationSwitcher,
            isLocationSwitcherOpened: false,
          },
          districtsSwitcher: {
            ...state.filters.districtsSwitcher,
            districts: undefined,
            selectedLocation: undefined,
            selectedDistricts: [],
          },
        },
      };

    default:
      return state;
  }
}
