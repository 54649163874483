import { ECompareType, EFieldSubType, EHelpTextPosition, IStep } from '@cian/frontend-request-form-widget';

import { ExtendedFieldType } from '../extendedFieldType';

import { ABOUT_COMPANY_STEP } from './aboutCompany';

export const PERSON_TYPE_STEP: IStep = {
  code: 'personType',
  question: {
    code: 'personTypeQuestion',
    title: 'Для кого ищете помещение?',
    fields: [
      {
        name: 'personType',
        type: ExtendedFieldType.OneChoice,
        subType: EFieldSubType.Icon,
        answers: [
          {
            label: 'Я брокер, ищу помещение для клиента',
            value: 'broc',
          },
          {
            label: 'Я арендатор, ищу помещение для себя',
            value: 'rent',
          },
        ],
      },
    ],
    helpText: {
      text: 'Мы адаптируем поиск недвижимости под ваш опыт',
      position: EHelpTextPosition.Above,
    },
  },
  nextStep: [
    {
      conditions: [
        {
          answerField: 'personType',
          compareType: ECompareType.Eq,
          value: ['broc'],
        },
      ],
      nextStepCode: ABOUT_COMPANY_STEP.code,
      nextStepArgs: [
        {
          path: ['title'],
          value: 'Для какой компании будем искать?',
        },
      ],
    },
    {
      conditions: [
        {
          answerField: 'personType',
          compareType: ECompareType.Eq,
          value: ['rent'],
        },
      ],
      nextStepCode: ABOUT_COMPANY_STEP.code,
      nextStepArgs: [
        {
          path: ['title'],
          value: 'Расскажите о вашей компании',
        },
      ],
    },
  ],
};
