import { Header4 } from '@cian/ui-kit/typography';

import * as React from 'react';

import * as styles from './QuickLinksTitle.css';

export const QuickLinksTitle: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles['title']}>
      <Header4 as="h4" whiteSpace="nowrap">
        {children}
      </Header4>
    </div>
  );
};

QuickLinksTitle.displayName = 'QuickLinksTitle';
