/* eslint-disable max-lines */
import { ITargetsParams } from '@cian/adfox/build/api';
import { IAdFoxBannerTypeParams, IAdFoxCalculatedParams, IAdFoxParams } from '@cian/adfox/build/middlewares';
import { AdFoxBanner, IAdFoxBannerContext } from '@cian/adfox/build/react';
import { TAdFoxBannerSizeProps } from '@cian/adfox-component';

import cn from 'classnames';

import { sendStats } from 'shared/api/stats';
import { RenderInViewPort } from 'shared/components/RenderInViewPort';
import { IOffer } from 'shared/offer';
import { sendAdsEvent } from 'shared/tracking/sendAdsEvent';
import { IMakeRequest } from 'shared/utils/request';

import { AdFoxBanner as AdFoxBannerNew } from './new';
import { AdFoxBannerType, BANNER_TEMPLATE_TYPE, getBannerByType } from './templates';
import { CommercialMortgageBanner } from './templates/commercial_mortgage/';
import { GoogleDefaultBanner } from './templates/google_default';
import { HTML5Banner } from './templates/html_5';
import { MortgageSimpleBannerContainer } from './templates/mortgage';
import { MortgageInListing } from './templates/mortgage_in_listing';
import { MortgageBanner } from './templates/mortgage_in_price';
import { ParallaxBanner } from './templates/parallax';
import { TGFullBanner } from './templates/tgb_in_card/full';
import { TGSimplifiedBannerContainer } from './templates/tgb_in_card/simplified';
import { SeoBanner } from './templates/tgb_seo_top/';
import { VideoBanner } from './templates/video';
import { AdFoxContext } from './utils/context';

import * as styles from './index.css';

export type TAddFoxBannerConfig = {
  type: AdFoxBannerType;
  key: string | number;
  makeRequest: IMakeRequest;
  offer?: IOffer;
  locationId?: number;
  isCommercial?: boolean;
  additionalPuids?: { [key: string]: string };
  isAgent?: boolean;
  isNew?: boolean;
  realtyUserId?: number | null;
  shouldSendAdsEvent?: boolean;
} & TAdFoxBannerSizeProps;

interface IAdFoxMiddlewareConfig {
  id?: string;
  type: AdFoxBannerType;
  offer?: IOffer;
  additionalPuids?: { [key: string]: string };
}

// Зачение pr должно быть одно для всех баннеров в рамках одной загрузки страницы
const PR_RANDOM = getRandomId();

export function getBannerMiddleware({ type, offer, id, additionalPuids }: IAdFoxMiddlewareConfig) {
  const adFoxTargets = ((): Record<string, string> => {
    const targetParams: ITargetsParams = {};

    if (type === 'video' || type === 'parallax') {
      Object.assign(targetParams, {
        pr: PR_RANDOM,
        puid20: 'abtest',
      });
    } else {
      Object.assign(targetParams, {
        pr: PR_RANDOM,
        pr1: id || getRandomId(),
      });
    }

    if (offer && offer.adfoxParams) {
      Object.assign(targetParams, offer.adfoxParams);
    }

    return Object.assign(targetParams, additionalPuids);
  })();

  return () => bannerMiddleware(type, adFoxTargets);
}

export function addAdFoxBanner(config: TAddFoxBannerConfig) {
  const {
    isCommercial,
    key,
    locationId,
    makeRequest,
    offer,
    type,
    additionalPuids = { puid6: '' },
    isNew,
    isAgent,
    realtyUserId,
    shouldSendAdsEvent,
    ...sizeParameters
  } = config;

  const randomId = getRandomId();
  let savedBannerId: string;

  const onBannerClick = () => {
    if (savedBannerId) {
      sendAdsEvent({
        realtyUserId,
        action: 'click',
        bannerId: savedBannerId,
        bannerPlace: 'offersSerp',
      });
    }
  };

  const onBannerLoad = (context: IAdFoxBannerContext) => {
    if (!context || !shouldSendAdsEvent) {
      return;
    }

    const bannerId = context.bannerId;
    savedBannerId = bannerId;

    sendAdsEvent({
      realtyUserId,
      action: 'show',
      bannerId,
      bannerPlace: 'offersSerp',
    });
  };

  return getBanner({
    key,
    makeRequest,
    middleware: getBannerMiddleware({ type, additionalPuids, id: randomId, offer }),
    locationId,
    randomId,
    isCommercial,
    isNew,
    isAgent,
    onBannerLoad,
    onBannerClick,
    ...sizeParameters,
  });
}

export function getRandomId() {
  return String(Math.floor(Math.random() * 4294967295) + 1);
}

type TGetBannerParameters = {
  key: string | number;
  makeRequest: IMakeRequest;
  middleware: () => IAdFoxParams;
  locationId: number | undefined;
  randomId: string;
  isCommercial?: boolean;
  isNew?: boolean;
  isAgent?: boolean;
  onBannerLoad?: (context: IAdFoxBannerContext) => void;
  onBannerClick?: () => void;
} & TAdFoxBannerSizeProps;

function getBanner({
  key,
  makeRequest,
  middleware,
  locationId,
  randomId,
  isCommercial,
  isNew,
  isAgent,
  onBannerLoad,
  onBannerClick,
  ...sizeParameters
}: TGetBannerParameters): JSX.Element | null {
  return (
    <RenderInViewPort key={key}>
      {() => {
        if (isNew) {
          const params = middleware();
          delete params.pr;
          delete params.pr1;

          return (
            <AdFoxBannerNew
              {...sizeParameters}
              disableYan={isAgent}
              ownerId={202100}
              params={params as Record<string, string>}
            />
          );
        }

        return (
          <AdFoxContext.Consumer>
            {() => {
              const result = middleware();

              return (
                <AdFoxBanner
                  middleware={() => Promise.resolve(result)}
                  renderFailure={error => {
                    let errorType = 'template-error';

                    if (error.message === 'Unpossible to load script') {
                      errorType = !window._cianCanRunAds ? 'request-error' : 'adblock-error';
                    }

                    sendStats(makeRequest, errorType, key.toString());

                    return getBannerMarkup(false, randomId, <span />, isAgent, isCommercial);
                  }}
                  renderFetched={(context: IAdFoxBannerContext) => {
                    if (!!onBannerLoad && context['type'] !== 'nativeTGB') {
                      onBannerLoad(context);
                    }

                    switch (context['type'] as BANNER_TEMPLATE_TYPE) {
                      case 'nativeTGB':
                        return isCommercial
                          ? getBannerMarkup(true, randomId, <TGFullBanner context={context} />, isAgent, isCommercial)
                          : getBannerMarkup(
                              true,
                              randomId,
                              <TGSimplifiedBannerContainer context={context} locationId={locationId} />,
                              isAgent,
                              isCommercial,
                            );
                      case 'mortgage_banner':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <MortgageSimpleBannerContainer context={context} />,
                          isAgent,
                          isCommercial,
                        );
                      case 'mortgage':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <MortgageBanner context={context} isCommercial={isCommercial} />,
                          isAgent,
                          isCommercial,
                        );
                      case 'commercial_mortgage':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <CommercialMortgageBanner context={context} />,
                          isAgent,
                          isCommercial,
                        );
                      case 'seo':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <SeoBanner context={context} />,
                          isAgent,
                          isCommercial,
                          onBannerClick,
                        );
                      case 'video':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <VideoBanner context={context} />,
                          isAgent,
                          isCommercial,
                          onBannerClick,
                        );
                      case 'parallax':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <ParallaxBanner context={context} />,
                          isAgent,
                          isCommercial,
                          onBannerClick,
                        );
                      case 'stretch':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <HTML5Banner context={context} />,
                          isAgent,
                          isCommercial,
                          onBannerClick,
                        );
                      case 'google_default':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <GoogleDefaultBanner context={context} />,
                          isAgent,
                          isCommercial,
                          onBannerClick,
                        );
                      case 'mortgage_in_listing':
                        return getBannerMarkup(
                          true,
                          randomId,
                          <MortgageInListing context={context} />,
                          isAgent,
                          isCommercial,
                        );
                      default:
                        return getBannerMarkup(false, randomId, <span />, isAgent, isCommercial);
                    }
                  }}
                />
              );
            }}
          </AdFoxContext.Consumer>
        );
      }}
    </RenderInViewPort>
  );
}

function getBannerMarkup(
  isValidBanner: boolean,
  randomId: string,
  markup: JSX.Element,
  isAgent?: boolean,
  isCommercial?: boolean,
  onBannerClick?: () => void,
) {
  return (
    <div
      className={cn(
        !isCommercial && styles['simplified-banner'],
        !isCommercial && !isValidBanner && styles['no-banner'],
      )}
      key={randomId}
      onClick={onBannerClick}
    >
      <div>{markup}</div>
      <div id={`AdFox_banner_${randomId}`} />
    </div>
  );
}

function bannerMiddleware(bannerType: AdFoxBannerType, adFoxTargets: Record<string, string>): IAdFoxParams {
  return mergeMiddlewares(adFoxTargets, typeParamsMiddleware(bannerType), calculatedParamsMiddleware()) as IAdFoxParams;
}

export function mergeMiddlewares(...data: object[]) {
  return data.reduce((acc, x) => Object.assign(acc, x), {});
}

export function calculatedParamsMiddleware(): IAdFoxCalculatedParams {
  const now = new Date();

  return {
    pd: now.getDate().toString(),
    pw: now.getDay().toString(),
    pv: now.getHours().toString(),
    prr: document.referrer ? encodeURIComponent(document.referrer) : '',
    dl: location.href,
  };
}

function typeParamsMiddleware(type: AdFoxBannerType): IAdFoxBannerTypeParams {
  return getBannerByType(type);
}
