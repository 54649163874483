import { IQuestionField, useFormField } from '@cian/frontend-request-form-widget';

import * as React from 'react';

import { NonUndefinable } from '../../../../../../../../packages/JsonQuery';

import { CustomRadioButtonGroup as Component, ICustomRadioButtonGroup } from './CustomRadioButtonGroup';

export const CustomRadioButtonGroup: React.FC<IQuestionField> = props => {
  const {
    field: { value },
    helpers: { setValue },
  } = useFormField<'rent' | 'sale'>({ name: props.name });

  const handleChange: ICustomRadioButtonGroup['onChange'] = React.useCallback<
    NonUndefinable<ICustomRadioButtonGroup['onChange']>
  >((_, value) => setValue(value as 'rent' | 'sale'), []);

  const options: ICustomRadioButtonGroup['options'] = React.useMemo(
    () =>
      props.answers?.map(answer => ({
        label: answer.label,
        value: answer.value,
      })) || [],
    [],
  );

  return <Component label={props.label} options={options} value={value} onChange={handleChange} />;
};

CustomRadioButtonGroup.displayName = 'CustomRadioButtonGroupContainer';
