import { IJsonQuery } from 'shared/repositories/common/json_query';
import { IOffer } from 'shared/repositories/search-offers.legacy/v2/types';

import { getOfferPrice } from '../../serp/components/offers/helpers';

/**
 * Функция для получения правильного значения puid6
 * (puid6 - используется для таргентинга баннеров на выдаче по цене)
 *
 * @param priceMin минимальная цена оффера на выдаче
 * @param priceMax максимальная цена оффера на выдаче
 */
export function getPriceRangeForPuidAdFox(priceMin: number, priceMax: number): string {
  const intervals = [
    [0, 990000],
    [1000000, 2990000],
    [3000000, 4990000],
    [5000000, 6990000],
    [7000000, 9990000],
    [10000000, 14900000],
    [15000000, 19900000],
    [20000000, 24900000],
    [25000000, 29900000],
    [30000000, 39900000],
    [40000000, 49900000],
    [50000000, Infinity],
    [Infinity, Infinity],
  ];

  const MIN_PRICE_SPECIAL = 39900000;
  const MAX_PRICE_SPECIAL = 50000000;

  if (priceMin >= MAX_PRICE_SPECIAL) {
    return `${MAX_PRICE_SPECIAL}_max`;
  }

  /* логика поиска максиимума:
  если максимум меньше нижней границы интервала, значит индекс максимума попадает в предыдущиий интервал */
  let maxIndex = 0;
  for (let i = 1; i < intervals.length; i++) {
    if (priceMax < intervals[i][0]) {
      maxIndex = i - 1;
      break;
    }
  }

  /* логика поиска минимума:
  пока минимум больше иили равен правой границе иинтервала приравниваем
  индексу минимума ииндекс следующего иинтервала */
  let minIndex = 0;
  if (priceMin >= MIN_PRICE_SPECIAL) {
    minIndex = 10;
  } else {
    for (let i = 0; i < intervals.length; i++) {
      if (priceMin >= intervals[i][1]) {
        minIndex = i + 1;
        continue;
      }
      break;
    }
  }

  return intervals
    .slice(minIndex, maxIndex + 1)
    .map(interval => `${interval[0]}_${interval[1]}`.replace('Infinity', 'max'))
    .join(':');
}

export function getPriceRangePuidFromOffers(jsonQuery: IJsonQuery, offers: IOffer[]): string {
  const { price } = jsonQuery;

  const pricesSorted = offers.map(getOfferPrice).sort((p1, p2) => p1 - p2);

  /**
   * Отправляем кастомный puid6, только если
   * пользователь задал фильтр цены "От"
   */
  return price && price.value.gte
    ? getPriceRangeForPuidAdFox(pricesSorted[0], pricesSorted[pricesSorted.length - 1])
    : '';
}
