import { Header1 } from '@cian/ui-kit/typography';

import * as React from 'react';

import { IBreadcrumb } from 'shared/offer';

import * as style from './Breadcrumbs.css';

interface IBreadcrumbsProps {
  breadcrumbs: IBreadcrumb[];
  h1?: React.ReactNode;
}

export function Breadcrumbs({ breadcrumbs, h1 }: IBreadcrumbsProps) {
  return (
    <div itemScope itemType="http://schema.org/BreadcrumbList">
      {breadcrumbs.length > 0 && (
        <div className={style['breadcrumbs']}>
          {breadcrumbs.map((breadcrumb, index, arr) => {
            return (
              <span
                itemScope
                itemProp="itemListElement"
                itemType="http://schema.org/ListItem"
                key={breadcrumb.title || index}
              >
                <a
                  className={style['breadcrumbs-link']}
                  href={breadcrumb.url || undefined}
                  itemProp="item"
                  key={index}
                  title={breadcrumb.title || ''}
                >
                  <span itemProp="name">{breadcrumb.title}</span>
                  <meta content={`${index + 1}`} itemProp="position" />
                  {index < arr.length - 1 && <div className={style['breadcrumbs-delimiter']} />}
                </a>
              </span>
            );
          })}
        </div>
      )}
      {h1 && <Header1 as="h1">{h1}</Header1>}
    </div>
  );
}
