import { IAppState } from '../common/state';

import { selectProfessionalSearchUrl as selectProfessionalSearchUrlWithoutQuery } from './filters/selectProfessionalSearchUrl';

export function selectProfessionalSearchUrl(state: IAppState) {
  const hostPart = selectProfessionalSearchUrlWithoutQuery(state);

  const {
    results: { queryString },
  } = state;

  const encodeQueryString = queryString ? encodeURIComponent(queryString) : '';

  return queryString ? `${hostPart}?from_full_url=${encodeQueryString}` : hostPart;
}
