import { getLocationById } from '../../api/geo/geo';
import { Dispatch, IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';
import { FOfferType, isSuburban, offerTypeFromJsonQuery } from '../../utils/category';
import { trackGeoSuggestAutoOfferChange } from '../components/geo_suggest/tracking';

import { applyLocation } from './geo';
import { selectOfferType } from './offer_type';

export interface ISelectKPParams {
  id: number;
  name: string;
  regionId?: number;
}
export interface IKPChangedAction {
  type: 'filters/kp/KP_CHANGED';
  id: number;
  name: string;
}

export function changeKP(id: number, name: string): IKPChangedAction {
  return {
    type: 'filters/kp/KP_CHANGED',
    id,
    name,
  };
}

export interface IAutoOfferChangeTooltip {
  type: 'filters/kp/autoOfferChangeTooltip';
  payload: {
    visible: boolean;
  };
}

export function changeAutoOfferTooltipVisibility(visible: boolean): IAutoOfferChangeTooltip {
  return {
    type: 'filters/kp/autoOfferChangeTooltip',
    payload: { visible },
  };
}

export function selectKP(params: ISelectKPParams) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const { id, name, regionId } = params;
    const {
      makeRequest,
      filters: { currentLocation, jsonQuery },
    } = state;
    const offerType = offerTypeFromJsonQuery(jsonQuery);
    let locationPromise = Promise.resolve(currentLocation);
    if (regionId) {
      locationPromise = getLocationById(makeRequest, regionId).catch(() => currentLocation);
    }

    return locationPromise.then(locationInfo => {
      //для КП сразу переключаем регион в фильтрах на тот который пришел из саджеста
      if (locationInfo) {
        dispatch(applyLocation(locationInfo));
      }
      if (!isSuburban(offerType)) {
        dispatch(selectOfferType(FOfferType.Suburban));
        dispatch(changeAutoOfferTooltipVisibility(true));
        trackGeoSuggestAutoOfferChange(id, offerType);
      }
      dispatch(changeKP(id, name));
    });
  };
}

export function kpIdReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'kp-id') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.setKpId(undefined),
          },
        };
      }

      return state;

    case 'filters/geo/GEO_SELECTED':
    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setKpId(undefined),
        },
      };

    case 'filters/kp/autoOfferChangeTooltip':
      return {
        ...state,
        isAutoOfferChangeTooltipVisible: action.payload.visible,
      };

    case 'filters/kp/KP_CHANGED': {
      const { name, id } = action;

      return {
        ...state,
        villageName: name,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setKpId(id),
        },
      };
    }

    default:
      return state;
  }
}
