/* eslint-disable max-lines */
import { ca } from '@cian/analytics';
import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { UnknownError, ValidationError } from '@cian/peperrors/shared';
import { CustomTooltip, FormField, Input, Spinner } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import * as React from 'react';

import { IOffer } from 'shared/offer';
import { EFeedbackComplaint, IComplaintFeedbackBody } from 'shared/serp/state/offer_card/feedback_complaint';

import { EBlockType, IComplaint, addComplaint, getComplaints } from '../../../../api/complaints';
import { IUser } from '../../../../types/user';
import { Button } from '../../../button';
import { Textarea } from '../../../textarea';

import { IconCheck, IconClose } from './icons';

const style = require('./index.css');

export interface IReportPopupProps {
  onClose(): void;
  sendComplaintFeedback(body: IComplaintFeedbackBody): void;
  statusChanger(status: EFeedbackComplaint): void;
  complaintsFormStatus: EFeedbackComplaint;
  userEmail: string | undefined;
  open: boolean;
  popupRef: React.RefObject<HTMLDivElement>;
}

export interface IReportPopupAPI {
  onComplaintSent(offerId: number, name: string, message?: string): void;
}

export interface IReportPopupState {
  isLoading: boolean;
  isSubmitted: boolean;
  error?: string;
  complaints?: IComplaint[];
  selectionTree: number[];
  selected?: number;
  message?: string;
  messageError: boolean;
  complaintEmail: string;
  complaintId: number | undefined;
  complaintSendFeeadbackError: string;
  getUserFeedback: boolean;
}

export interface IReportPopupContext {
  currentSubdomain: string;
  httpApi: IHttpApi;
  logger: ILogger;
  offer: IOffer;
  user: IUser;

  api: { reportPopup: IReportPopupAPI };
}

const MAX_EMAIL_LENGTH = 254;

const DEFAULT_ERROR_MESSAGE = 'Не удалось отправить. Попробуйте позже.';
const INVALIDE_EMAIL_ERROR_MESSAGE = 'Некорректный "E-mail"';
const LONG_EMAIL_ERROR = 'E-mail не может быть более 254 символов';
const EMAIL_MASK = /^.+@.+\.[a-zа-яё]+$/;

/* istanbul ignore next */
export class ReportPopup extends React.Component<IReportPopupProps, IReportPopupState> {
  public context: IReportPopupContext;

  private preventClose = false;

  public static contextTypes = {
    api: PropTypes.object,
    currentSubdomain: PropTypes.string,
    httpApi: PropTypes.object,
    logger: PropTypes.object,
    offer: PropTypes.object,
  };

  public constructor(props: IReportPopupProps) {
    super(props);

    this.state = {
      complaintEmail: props.userEmail || '',
      complaintId: undefined,
      complaintSendFeeadbackError: '',
      isLoading: false,
      isSubmitted: false,
      messageError: false,
      selectionTree: [],
      getUserFeedback: true,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IReportPopupProps) {
    if (nextProps.complaintsFormStatus === EFeedbackComplaint.success) {
      this.setState({
        complaintEmail: '',
        complaintId: undefined,
        complaintSendFeeadbackError: '',
      });

      this.onOutsideClick();
    }
  }

  public componentDidMount() {
    const { httpApi, offer, logger } = this.context;
    const { geo } = offer;
    const region = geo && geo.address.length > 0 ? geo.address[0].id : 1;

    if (!offer.categoriesIds || offer.categoriesIds.length < 1) {
      const dataError = new ValidationError({
        message: `Failed to get complaints for offer ${offer.cianId || offer.id}: offer not categorized`,
        domain: 'report_popup',
      });
      logger.error(dataError);
      this.setState({
        error: 'Произошла внутренняя ошибка сервиса. Информация об ошибке была направлена нашим разработчикам.',
      });

      return;
    }

    this.setState({
      isLoading: true,
    });

    getComplaints(httpApi, offer.categoriesIds[offer.categoriesIds.length - 1], region)
      .then(complaintsRaw => {
        const skipIds = ['89_1089'];

        let complaints = complaintsRaw;

        if (!offer.isByHomeowner) {
          complaints = complaints.filter(item => skipIds.indexOf(item.id) < 0);
        }

        this.setState({
          complaints,
          isLoading: false,
        });
      })
      .catch(reason => {
        const dataError = new UnknownError({
          message: `Failed to get complaints for offer ${offer.cianId || offer.id}: offer not categorized`,
          domain: 'report_popup',
          details: {
            message: reason.message,
          },
        });
        logger.error(dataError);
        this.setState({
          error:
            'Не удалось получить информацию о предложении. Проверьте своё интернет соединение и попробуйте еще раз.',
          isLoading: false,
        });
      });
  }

  public render() {
    const { isLoading, isSubmitted, error } = this.state;
    const {
      offer: { offerType },
    } = this.context;
    const { complaintsFormStatus, open, popupRef } = this.props;

    return (
      <CustomTooltip
        anchorRef={popupRef}
        arrow={true}
        content={
          <div
            className={classNames(
              style['container'],
              isSubmitted && style['container--submitted'],
              ['suburban', 'commercial'].includes(offerType) ||
                (complaintsFormStatus === EFeedbackComplaint.success && style['container--submitted-small']),
            )}
          >
            {isLoading && (
              <div className={style['preloader']}>
                <Spinner size={50} />
              </div>
            )}
            {!!error && error}
            {this.renderLastStep()}
            {!isLoading && !error && !isSubmitted && this.renderForm()}
          </div>
        }
        flip={false}
        open={open}
        outside={true}
        placement="bottom-end"
        theme="white"
        onClose={this.onOutsideClick}
      />
    );
  }

  private renderLastStep = () => {
    const { isSubmitted } = this.state;
    const { complaintsFormStatus } = this.props;

    if (isSubmitted && complaintsFormStatus !== EFeedbackComplaint.success) {
      return this.renderFeedbackForm();
    }

    return null;
  };

  private renderForm() {
    const { complaints, selectionTree, selected, message, messageError } = this.state;

    if (!complaints) {
      return null;
    }

    const selectedComplaint = getSelectedComplaint(complaints, selectionTree);
    const selectedComplaints = (selectedComplaint && selectedComplaint.items) || complaints;

    return (
      <div className={style['form']}>
        {selectionTree.length > 0 && (
          <button className={style['button_back']} onClick={this.handlePrev}>
            {/* eslint-disable-next-line react/forbid-elements */}
            <svg height="10" viewBox="0 0 5.925 10" width="6" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M.29 4.342L4.297.182c.474-.324 1.12-.204 1.446.27.23.334.244.77.037 1.12L2.495 5.06 5.78 8.43c.29.496.126 1.134-.37 1.425-.347.203-.78.19-1.113-.038L.29 5.66C.13 5.496.03 5.287 0 5.062c0-.27.104-.527.29-.72z"
                fill="currentColor"
              />
            </svg>
          </button>
        )}
        <h3 className={style['header']}>
          Шаг {selectionTree.length + 1}. {!selectedComplaint ? 'На что жалуетесь?' : selectedComplaint.title}
        </h3>
        <ul className={style['links']}>
          {selectedComplaints.map((complaint, index) => {
            const { blockType, id, name, hint, url } = complaint;

            if (blockType === EBlockType.Transitional || blockType === EBlockType.Finish) {
              return (
                <li className={style['links-link']} key={`complaint-${id}`}>
                  <a
                    className={style['links-linkAnchor']}
                    href="#"
                    onClick={event => {
                      event.preventDefault();

                      if (blockType === EBlockType.Transitional) {
                        this.handleNext(index);
                      } else {
                        this.handleFinish(complaint);
                      }
                    }}
                  >
                    {name}
                  </a>
                </li>
              );
            } else if (blockType === EBlockType.Textarea || blockType === EBlockType.Link) {
              return (
                <li
                  key={`complaint-${id}`}
                  {...mergeStyles(style['links-link'], selected === index && style['links-link--active'])}
                >
                  {selected === index && (
                    // eslint-disable-next-line react/forbid-elements
                    <svg
                      className={style['links-linkIcon']}
                      height="10"
                      viewBox="0 0 13.994 9.499"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.698.305c-.4-.404-1.052-.408-1.456-.008L5.447 7.01 1.76 3.24c-.396-.4-1.04-.406-1.444-.016-.41.394-.422 1.046-.028 1.456l4.41 4.51c.19.195.452.306.726.31h.01c.27-.002.53-.11.724-.3l7.53-7.44c.406-.4.41-1.05.01-1.455z"
                        fill="#2B87DB"
                      />
                    </svg>
                  )}
                  <a
                    href="#"
                    onClick={event => {
                      event.preventDefault();
                      // eslint-disable-next-line no-unused-expressions
                      blockType === EBlockType.Textarea
                        ? this.handleDropdown(index)
                        : this.handleComplaintLinkClick(name, url);
                    }}
                    {...mergeStyles(style['links-linkAnchor'], selected === index && style['links-linkAnchor--active'])}
                    rel="noreferrer"
                  >
                    {name}
                  </a>
                  {selected === index && (
                    <Textarea
                      {...mergeStyles(style['textarea'], messageError && style['textarea--error'])}
                      placeholder={hint}
                      value={message}
                      onValueChange={value => this.setState({ message: value, messageError: false })}
                    />
                  )}
                  {selected === index && (
                    <Button theme="primary" onClick={() => this.handleSubmit(complaint)}>
                      Отправить жалобу
                    </Button>
                  )}
                </li>
              );
            }

            return null;
          })}
        </ul>
      </div>
    );
  }

  private handleComplaintLinkClick = (name: string, url?: string | null) => {
    const {
      offer: { fullUrl },
    } = this.context;

    if (url) {
      window.open(fullUrl ? `${url}&object_url=${fullUrl}` : url, '_blank');

      ca('event', {
        name: 'oldevent',
        category: 'Listing',
        action: 'complain',
        label: 'send',
      });
    }
  };

  private getFeedbackFormErrorMessage = () => {
    const { complaintsFormStatus } = this.props;
    const { complaintSendFeeadbackError } = this.state;

    if (complaintSendFeeadbackError) {
      return complaintSendFeeadbackError;
    }

    if (complaintsFormStatus === EFeedbackComplaint.error) {
      return DEFAULT_ERROR_MESSAGE;
    }

    return '';
  };

  private renderFeedbackForm() {
    const { complaintsFormStatus } = this.props;
    const { getUserFeedback } = this.state;

    const errorMessage = this.getFeedbackFormErrorMessage();

    return (
      <div className={style['feedback']}>
        {complaintsFormStatus === EFeedbackComplaint.loading && (
          <div className={style['feedback-preloader-body']}>
            <div className={style['feedback-preloader-item']}>
              <Spinner size={24} />
            </div>
          </div>
        )}
        <button className={style['feedback-close']} type="button" onClick={this.onOutsideClick}>
          <IconClose />
        </button>
        <div className={style['feedback-title']}>
          <IconCheck />
          <span>
            Спасибо за помощь. Жалоба
            <br />
            принята к рассмотрению
          </span>
        </div>
        {getUserFeedback && (
          <>
            <div className={style['feedback-body']}>
              Вы можете оставить ваш e-mail, и мы
              <br />
              пришлем вам информацию о нашем решении
              <br />
              по рассмотрению жалобы в случае подтверждения нарушения правил
            </div>
            <form className={style['feedback-form']} onSubmit={this.sendFeedbackComplaint}>
              <div>
                <FormField errorMessage={errorMessage}>
                  <Input
                    placeholder="Электронная почта"
                    value={this.state.complaintEmail}
                    onChange={(_event, value) => this.setState({ complaintEmail: value })}
                    onFocus={this.handleOnFocusField}
                  />
                </FormField>
              </div>
              <div>
                <Button theme="primary" type="submit">
                  Отправить
                </Button>
              </div>
            </form>
          </>
        )}
      </div>
    );
  }

  private handleOnFocusField = (event: React.FormEvent) => {
    event.preventDefault();

    this.setState({ complaintSendFeeadbackError: '' });
    this.props.statusChanger(EFeedbackComplaint.default);
  };

  private sendFeedbackComplaint = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    const { complaintEmail, complaintId } = this.state;

    if (complaintEmail && !EMAIL_MASK.test(complaintEmail)) {
      this.setState({
        complaintSendFeeadbackError: INVALIDE_EMAIL_ERROR_MESSAGE,
      });

      return;
    }

    if (!complaintEmail) {
      this.onOutsideClick();

      return;
    }

    if (complaintEmail && complaintEmail.length > MAX_EMAIL_LENGTH) {
      this.setState({
        complaintSendFeeadbackError: LONG_EMAIL_ERROR,
      });

      return;
    }

    if (!complaintId) {
      this.setState({
        complaintSendFeeadbackError: DEFAULT_ERROR_MESSAGE,
      });

      return;
    }

    this.props.sendComplaintFeedback({
      complaintId,
      email: complaintEmail,
    });
  };

  private onOutsideClick = () => {
    if (this.preventClose) {
      this.preventClose = false;

      return;
    }

    this.props.onClose();

    this.props.statusChanger(EFeedbackComplaint.default);

    this.setState({
      complaintEmail: '',
      complaintId: undefined,
      complaintSendFeeadbackError: '',
    });
  };

  private handlePrev = () => {
    this.preventClose = true;
    this.setState({
      message: undefined,
      selected: undefined,
      selectionTree: this.state.selectionTree.slice(0, this.state.selectionTree.length - 1),
    });
  };

  private handleNext = (index: number) => {
    this.preventClose = true;
    this.setState({
      message: undefined,
      selected: undefined,
      selectionTree: this.state.selectionTree.concat(index),
    });
  };

  private handleDropdown = (index: number) => {
    if (this.state.selected === index) {
      return;
    }

    this.setState({
      message: undefined,
      selected: index,
    });
  };

  private handleSubmit = (complaint: IComplaint) => {
    const { api, offer, httpApi, logger } = this.context;
    const { message } = this.state;

    if (complaint.isCommentRequired && (!message || message.length < 1)) {
      this.setState({
        messageError: true,
      });

      return;
    }

    this.preventClose = true;
    this.setState({
      isLoading: true,
    });

    addComplaint(httpApi, {
      complaintId: complaint.id,
      message,
      offerId: offer.cianId || offer.id,
    })
      .then(res => {
        this.setState({
          complaintId: Number(res.complaintId),
          isLoading: false,
          isSubmitted: true,
          getUserFeedback: res.getUserFeedback,
        });

        api.reportPopup.onComplaintSent(offer.cianId || offer.id, complaint.name, message);
      })
      .catch(reason => {
        const dataError = new UnknownError({
          message: `Failed to add complaint for offer ${offer.id}`,
          domain: 'report_popup',
          details: {
            message: reason.message,
          },
        });
        logger.error(dataError);

        this.setState({
          error: 'Не удалось отправить жалобу. Проверьте своё интернет соединение и попробуйте еще раз.',
          isLoading: false,
        });
      });
  };

  private handleFinish = (complaint: IComplaint) => {
    const { api, offer, httpApi, logger } = this.context;

    this.preventClose = true;
    this.setState({
      isLoading: true,
    });

    addComplaint(httpApi, {
      complaintId: complaint.id,
      offerId: offer.cianId || offer.id,
    })
      .then(res => {
        api.reportPopup.onComplaintSent(offer.cianId || offer.id, complaint.name);

        this.setState({
          getUserFeedback: res.getUserFeedback,
          complaintId: Number(res.complaintId),
          isLoading: false,
          isSubmitted: true,
        });
      })
      .catch(reason => {
        const dataError = new UnknownError({
          message: `Failed to add complaint for offer ${offer.id}`,
          domain: 'report_popup',
          details: { message: reason.message },
        });
        logger.error(dataError);

        this.setState({
          error: 'Не удалось отправить жалобу. Проверьте своё интернет соединение и попробуйте еще раз.',
          isLoading: false,
        });
      });
  };
}

/* istanbul ignore next */
function getSelectedComplaint(complaints: IComplaint[], selectionTree: number[]): IComplaint | null {
  let selectedComplaint: IComplaint | null = null;
  selectionTree.forEach(value => {
    selectedComplaint = (selectedComplaint && selectedComplaint.items[value]) || complaints[value];
  });

  return selectedComplaint;
}
