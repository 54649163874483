import { ICircleObjectData } from '../../../common/components/map/draw_objects/circle';
import { IPolygonObjectData } from '../../../common/components/map/draw_objects/polygon';
import { IAppState, TActions } from '../../../common/state';
import { TGeoValue } from '../../../json_query';

export type TMapObjectData = IPolygonObjectData | ICircleObjectData;

export interface IMapChangedAction {
  type: 'filters/map/MAP_CHANGED_ACTION';
  areas: TMapObjectData[];
}

export function changeMap(areas: TMapObjectData[]): IMapChangedAction {
  return {
    type: 'filters/map/MAP_CHANGED_ACTION',
    areas,
  };
}

export function changeMapReducer(state: IAppState, action: TActions): IAppState {
  if (action.type === 'filters/map/MAP_CHANGED_ACTION') {
    let areas: TGeoValue[] = [];
    if (state.filters.jsonQuery.geo) {
      areas = state.filters.jsonQuery.geo.value.filter(({ type }) => type !== 'polygon' && type !== 'distance');
    }

    action.areas.reduce((acc: TGeoValue[], value: TMapObjectData) => {
      switch (value.type) {
        case 'polygon':
          acc.push({
            type: 'polygon',
            name: (value as IPolygonObjectData).name,
            coordinates: (value as IPolygonObjectData).coordinates,
          });

          return acc;
        case 'circle':
          acc.push({
            type: 'distance',
            name: value.name,
            center: (value as ICircleObjectData).center,
            radius: (value as ICircleObjectData).radius,
          });

          return acc;
        default:
          return acc;
      }
    }, areas);

    return {
      ...state,
      filters: {
        ...state.filters,
        jsonQuery: {
          ...state.filters.jsonQuery,
          geo: {
            type: 'geo',
            value: areas,
          },
        },
      },
    };
  }

  return state;
}
