import { Header2, LinkButton } from '@cian/ui-kit';

import imgLeftSideCenter from './assets/img_left_side--center.png';
import imgLeftSideLeft from './assets/img_left_side--left.png';
import imgLeftSideRight from './assets/img_left_side--right.png';
import imgRightSideCenter from './assets/img_right_side--center.png';
import imgRightSideLeft from './assets/img_right_side--left.png';
import imgRightSideRight from './assets/img_right_side--right.png';

import * as styles from './ProfessionalSearchPromo.css';

export interface IProfessionalSearchPromoProps {
  link: string;

  onClick(): void;
}

export const ProfessionalSearchPromo = ({ link, onClick }: IProfessionalSearchPromoProps) => {
  return (
    <div className={styles['banner']}>
      <img className={styles['img_left_side--left']} src={imgLeftSideLeft} />
      <img className={styles['img_left_side--center']} src={imgLeftSideCenter} />
      <img className={styles['img_left_side--right']} src={imgLeftSideRight} />
      <img className={styles['img_right_side--left']} src={imgRightSideLeft} />
      <img className={styles['img_right_side--center']} src={imgRightSideCenter} />
      <img className={styles['img_right_side--right']} src={imgRightSideRight} />
      <div className={styles['content']}>
        <div className={styles['header']}>
          <Header2>
            Профессионалы ищут объекты <span className={styles['name']}>в «Профпоиске»</span>
          </Header2>
        </div>
        <div className={styles['button']}>
          <LinkButton href={link} size="XS" onClick={onClick}>
            Хочу попробовать
          </LinkButton>
        </div>
      </div>
    </div>
  );
};
