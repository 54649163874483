import { numberToPrettyString } from '@cian/utils';

import * as React from 'react';

import { ECoworkingOfferType } from '../../types/offer';

import { CoworkingWorkplacesCountSubtitleView } from './internal/components/CoworkingWorkplacesCountSubtitleView';
import { getTitle } from './internal/helpers/getTitle';
import { ICoworkingWorkplacesCountSubtitleProps } from './types';

export const CoworkingWorkplacesCountSubtitle: React.FC<ICoworkingWorkplacesCountSubtitleProps> = props => {
  const { coworkingOfferType, workplaceCount, isOfficeSearchExperiment, priceForWorkplace } = props;

  const getTitleWithWorkplaceCount = getTitle(workplaceCount);

  switch (coworkingOfferType) {
    case ECoworkingOfferType.FlexibleWorkplace: {
      const title = getTitleWithWorkplaceCount(
        ['незакреплённое место', 'незакреплённых места', 'незакреплённых мест'],
        'Незакреплённое место',
      );

      return (
        <CoworkingWorkplacesCountSubtitleView title={title} tooltipTitle="Любое доступное рабочее место в общей зоне" />
      );
    }

    case ECoworkingOfferType.FixedWorkplace: {
      const title = getTitleWithWorkplaceCount(
        ['закреплённое место', 'закреплённых места', 'закреплённых мест'],
        'Закреплённое место',
      );

      return (
        <CoworkingWorkplacesCountSubtitleView title={title} tooltipTitle="Этим столом будете пользоваться только вы" />
      );
    }

    case ECoworkingOfferType.Office: {
      if (!isOfficeSearchExperiment || !priceForWorkplace) {
        return null;
      }

      const title = `${numberToPrettyString(priceForWorkplace)} руб./мес. за рабочее место`;

      return <CoworkingWorkplacesCountSubtitleView title={title} />;
    }

    case ECoworkingOfferType.ConferenceHall:
    case ECoworkingOfferType.MeetingRoom:
    default:
      return null;
  }
};

CoworkingWorkplacesCountSubtitle.displayName = 'CoworkingWorkplacesCountSubtitle';
