import { TAddFeedbackModel } from './types';

const defaultConfig: TAddFeedbackModel = {
  apiType: 'public',
  assertStatusCodes: [204, 400],
  method: 'POST',
  microserviceName: 'moderationComplaints',
  pathApi: '/v1/add-feedback-request/',
};

export function createModerationComplaintsModel(config: Partial<TAddFeedbackModel>): TAddFeedbackModel {
  return {
    ...defaultConfig,
    ...config,
  };
}
