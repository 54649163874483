import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IHasElectricityTagType = 'advanced-has-electricity';

export interface IHasElectricityTag {
  type: 'advanced-has-electricity';
}

function isHasElectricityTag(tag: TTag): tag is IHasElectricityTag {
  return tag.type === IHasElectricityTagType;
}

export const hasElectricityTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_electricity && jsonQuery.has_electricity.value;

    if (value) {
      return ensureSingleTagExists(tags, isHasElectricityTag, () => ({ type: IHasElectricityTagType }));
    } else {
      return ensureTagNotExists(tags, isHasElectricityTag);
    }
  },
};
