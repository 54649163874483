import { Label, Tooltip, UIHeading4, UIText2 } from '@cian/ui-kit';

import { FC } from 'react';

import styles from './BestPlaceAnalyticsBadge.css';

export interface IBestPlaceAnalyticsBadge {
  fullUrl: string;
  onBadgeClick: VoidFunction;
}

export const BestPlaceAnalyticsBadge: FC<IBestPlaceAnalyticsBadge> = props => {
  const { fullUrl, onBadgeClick } = props;

  return (
    <a
      className={styles['link']}
      data-testid="best-place-badge-link"
      href={fullUrl}
      rel="noreferrer"
      target="_blank"
      onClick={onBadgeClick}
    >
      <Tooltip
        arrow={false}
        placement="bottom-start"
        theme="white"
        title={
          <div className={styles['content']}>
            <UIHeading4>Оцените привлекательность объекта</UIHeading4>
            <UIText2>Узнайте, насколько помещение и район подходят для вашего бизнеса</UIText2>
          </div>
        }
      >
        <Label background="#f3f6ff" color="primary_100">
          Отчёт по объекту
        </Label>
      </Tooltip>
    </a>
  );
};

BestPlaceAnalyticsBadge.displayName = 'BestPlaceAnalyticsBadge';
