import { IOffer } from 'shared/repositories/search-offers.legacy/v2/types';
import { EAccountType } from 'shared/repositories/search-offers.legacy/v2/types/identicalOffers';

import { EAgentTypes } from '../enums/EAgentTypes';

export function getAgentType(offer: IOffer): EAgentTypes {
  const { user } = offer;

  if (!user) {
    return EAgentTypes.Agent;
  }

  const { accountType, masterAgent } = user;

  if (accountType === EAccountType.Agency || masterAgent) {
    return EAgentTypes.Agency;
  }

  return EAgentTypes.Agent;
}
