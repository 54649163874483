import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as React from 'react';

import { IOfferPhoto } from 'shared/offer';

import { Gallery, GalleryImage, IGalleryProps } from '../gallery';
import { ImageNone } from '../image-none-component';

const style = require('./index.css');

export interface IPhotoProps {
  photos: IOfferPhoto[];
  containerStyle?: IStyleConfig;
  controlButtonsFullHeight?: boolean;
  galleryControlsHidden: boolean;
  hasLayoutPhoto?: boolean;
  layoutPhotoStyle?: string;
  onSlideChanged?(): void;
  /** Использовать ли в галерее фото более высокого качества */
  useThumbnail2Url?: boolean;
  counter?: IGalleryProps['counter'];
  counterHidden?: IGalleryProps['counterHidden'];
}

export interface IPhotoState {
  photos: React.ReactChild[];
}

export class Photo extends React.Component<IPhotoProps, IPhotoState> {
  public context: {};

  public static contextTypes = {};

  public constructor(props: IPhotoProps) {
    super(props);

    const defaultPhoto = Math.max(
      this.props.photos.findIndex(photo => photo.isDefault),
      0,
    );

    this.state = {
      photos: props.photos.map((photo, index) => (
        <GalleryImage
          alt=""
          isLayout={this.props.hasLayoutPhoto && defaultPhoto === index}
          key={index}
          layoutPhotoStyle={this.props.layoutPhotoStyle}
          src={props.useThumbnail2Url ? photo.thumbnail2Url : photo.thumbnailUrl}
        />
      )),
    };
  }

  public render() {
    const defaultPhoto = Math.max(
      this.props.photos.findIndex(photo => photo.isDefault),
      0,
    );
    const defaultPhotoImg = this.props.photos[defaultPhoto];
    const defaultPhotoUrl = defaultPhotoImg ? defaultPhotoImg.fullUrl : '';

    const microData = {};

    return (
      <div {...microData} {...mergeStyles(style['container'], this.props.containerStyle)}>
        <span {...{ itemProp: 'url', content: defaultPhotoUrl }} />
        {this.props.photos.length > 0 && (
          <Gallery
            controlButtonsFullHeight={this.props.controlButtonsFullHeight}
            controlsHidden={this.props.galleryControlsHidden}
            counter={this.props.counter}
            counterHidden={this.props.counterHidden}
            defaultIndex={defaultPhoto}
            onSlideChanged={this.props.onSlideChanged}
          >
            {this.state.photos}
          </Gallery>
        )}
        {this.props.photos.length === 0 && <ImageNone containerStyle={style['noImage']} />}
      </div>
    );
  }
}
