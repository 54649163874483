import { IOffer } from 'shared/offer';
import { getAgentLevelInfo } from 'shared/utils/getAgentLevelInfo';

import { getIsUserIdentifiedByDocuments } from '../internal/utils/getIsUserIdentifiedByDocuments';

export function showAgentHonestlyProofs(offer: IOffer): boolean {
  const agentLevelInfo = getAgentLevelInfo(offer);

  return !!agentLevelInfo?.hasVerifiedDocuments || getIsUserIdentifiedByDocuments(offer);
}
