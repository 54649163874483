import * as React from 'react';

import * as styles from './CommercialFactoid.css';

export interface ICommercialFactoidProps {
  shouldRenderContent?: boolean;
  content: React.ReactNode;
}

export function CommercialFactoid(props: ICommercialFactoidProps): JSX.Element | null {
  const { shouldRenderContent = true, content } = props;

  if (!shouldRenderContent || !content) {
    return null;
  }

  return <li className={styles['factoid-item']}>{content}</li>;
}
