import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import { IPriorities, sortVas } from '../../../utils/sort';
import { VasLabel } from '../VasLabel';
import { VasList } from '../VasList';

import * as styles from './VasTooltip.css';

export type TPopupDirection = 'left' | 'right' | 'center';
export const PopupDirection = {
  CENTER: 'center' as TPopupDirection,
  LEFT: 'left' as TPopupDirection,
  RIGHT: 'right' as TPopupDirection,
};

export interface IVasComponentProps {
  /** Неотсортированный список мнемоник VAS'ов объявления */
  vasList: string[];
  /** Любой уникальный ключ (в идеале id объявления), чтобы сгенерировать элементы svg */
  uniqueKey: number | string;
  /** Используется для переопределения лейбла  */
  label?: string;
  /** Направление отрисовки попапа */
  direction?: TPopupDirection;
  /** Хендлер клика на контейнере */
  onClick?(e: React.MouseEvent<HTMLElement>): void;
  /** Стиль контейнера */
  className?: string;
  /** Стили для лейбла с иконками и именем VAS */
  labelStyles?: {
    [index: string]: string;
  };
  /** Ставка в аукционе */
  auctionBet?: number;
  /** Приоритеты для кастомной сортировки */
  priorities?: IPriorities;
  /** Стиль для лейбла */
  labelStyle?: string;
}

export interface IVasComponentState {
  isVisible: boolean;
  isHovered: boolean;
  animationClassname: 'leave' | 'entering' | 'enter';
  vasList: string[];
}

/**
 * Компонент для отображения примененного VAS в объявлении
 * Отображается в виде иконок примененных VAS и имени приоритетного VAS
 * При наведении отображается тултип с описанием примененных VAS
 */
export class VasComponent extends React.Component<IVasComponentProps, IVasComponentState> {
  private enteringTimer: number | null = null;
  private leavingTimer: number | null = null;

  public constructor(props: IVasComponentProps) {
    super(props);

    this.state = {
      animationClassname: 'leave',
      isHovered: false,
      isVisible: false,
      vasList: sortVas(props.vasList, props.priorities),
    };
  }

  public static defaultProps: Partial<IVasComponentProps> = {
    direction: PopupDirection.LEFT,
  };

  public UNSAFE_componentWillReceiveProps(nextProps: IVasComponentProps) {
    if (this.props.vasList && this.props.vasList.length !== nextProps.vasList.length) {
      this.setState({
        vasList: sortVas(nextProps.vasList, this.props.priorities),
      });
    }
  }

  public render() {
    const { label, className, labelStyles, auctionBet, labelStyle } = this.props;
    const { vasList, isHovered } = this.state;

    return (
      <div
        onClick={this.handleContainerClick}
        onMouseEnter={() => this.showPopup()}
        onMouseLeave={() => this.hidePopup()}
        {...mergeStyles(styles['container'], className)}
      >
        {this.state.isVisible && this.renderPopup()}
        <div {...mergeStyles(styles['label'], labelStyles)}>
          <VasLabel
            auctionBet={auctionBet}
            isHovered={isHovered}
            label={label}
            labelStyle={labelStyle}
            vasList={vasList}
          />
        </div>
      </div>
    );
  }

  public componentWillUnmount() {
    this.clearAnimationTimeout(this.enteringTimer);
    this.clearAnimationTimeout(this.leavingTimer);
  }

  private renderPopup() {
    const { direction } = this.props;
    const { vasList } = this.state;

    return (
      <div
        {...mergeStyles(
          styles['popup-container'],
          styles[`popup-container--${direction}`],
          styles[this.state.animationClassname],
        )}
      >
        <div className={styles['popup']}>
          <div className={styles['popup-content']}>
            <VasList vasList={vasList} />
          </div>
        </div>
      </div>
    );
  }

  private animateEntering = () => {
    this.enteringTimer = window.setTimeout(() => {
      this.setState({
        animationClassname: 'enter',
      });
    }, 300);
  };

  private animateLeaving = () => {
    this.leavingTimer = window.setTimeout(() => {
      this.setState({
        isVisible: false,
      });
    }, 300);
  };

  private clearAnimationTimeout = (timerId: number | null) => {
    if (timerId) {
      window.clearInterval(timerId);
    }
  };

  private handleContainerClick = (e: React.MouseEvent<HTMLElement>) => {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  };

  private showPopup() {
    this.clearAnimationTimeout(this.leavingTimer);

    this.setState(
      {
        animationClassname: 'entering',
        isHovered: true,
        isVisible: true,
      },
      this.animateEntering,
    );
  }

  private hidePopup() {
    this.clearAnimationTimeout(this.enteringTimer);

    this.setState(
      {
        animationClassname: 'leave',
        isHovered: false,
      },
      this.animateLeaving,
    );
  }
}
