import { IOffer } from 'shared/packages/search-card-fragment/types/offer';
import { useApplicationContext } from 'shared/serp/containers/ApplicationContext';

export function useGetIsElectronicTrading(offer: IOffer): boolean {
  const { config } = useApplicationContext();

  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-198059, будет удалён в задаче CD-198513
   */
  const isElectronicTradingEnabled = config.getStrict<boolean>('Commercial.ElectronicTrading.Enabled');

  return isElectronicTradingEnabled && !!offer.tradingLink;
}
