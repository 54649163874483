import { ca } from '@cian/analytics';

export function trackDealDateClick(dealDate: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Profile_user_comm_popup',
    action: 'Click',
    label: `Step_3_${dealDate}`,
  });
}
