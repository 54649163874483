import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export interface ICoworkingIdAction {
  type: 'filters/coworking_id/COWORKING_ID_SELECTED';
  coworkingId: number;
  coworkingName: string;
}

export interface ICoworkingSuggestShort {
  id: number;
  title: string;
}

export const setCoworkingId = (coworkingSuggested: ICoworkingSuggestShort): ICoworkingIdAction => ({
  type: 'filters/coworking_id/COWORKING_ID_SELECTED',
  coworkingId: coworkingSuggested.id,
  coworkingName: coworkingSuggested.title,
});

export const coworkingIdReducer = (state: IAppState, action: TActions) => {
  switch (action.type) {
    case 'filters/coworking_id/COWORKING_ID_SELECTED': {
      const tagsData = { ...state.filters.tagsData };

      tagsData.coworkings = { ...tagsData.coworkings, [action.coworkingId]: action.coworkingName };

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setCoworkingId(action.coworkingId),
          tagsData,
        },
      };
    }
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'coworking_id') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).removeCoworkingId(action.tag.value),
          },
        };
      }

      return state;
    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetCoworkingId(),
        },
      };
    default:
      return state;
  }
};
