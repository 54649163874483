export enum EFacility {
  Bath = 'bath',
  Conditioner = 'conditioner',
  Dishwasher = 'dish_washer',
  Fridge = 'has_fridge',
  Garage = 'garage',
  Internet = 'internet',
  KidsAllowed = 'kids',
  KitchenFurniture = 'has_kitchen_furniture',
  PetsAllowed = 'pets',
  Phone = 'phone',
  Pool = 'pool',
  RoomFurniture = 'has_furniture',
  Sauna = 'bathhouse',
  Security = 'security',
  Shower = 'shower',
  TV = 'tv',
  Washer = 'has_washer',
}
