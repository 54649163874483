import { EWindowsType } from 'shared/json_query';

import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IWindowsTypeTagType = 'advanced-windows';

export interface IWindowsTypeTag {
  type: 'advanced-windows';
  value: EWindowsType;
}

export const isWindowsTypeTag = (tag: TTag): tag is IWindowsTypeTag => tag.type === IWindowsTypeTagType;

export const windowsTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.windows_type && jsonQuery.windows_type.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isWindowsTypeTag, () => ({ type: IWindowsTypeTagType, value }));
    } else {
      return ensureTagNotExists(tags, isWindowsTypeTag);
    }
  },
};
