import { ELayoutType } from 'shared/json_query';

import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const ILayoutTypeTagType = 'advanced-layout';

export interface ILayoutTypeTag {
  type: 'advanced-layout';
  value: ELayoutType;
}

export const isLayoutTypeTag = (tag: TTag): tag is ILayoutTypeTag => tag.type === ILayoutTypeTagType;

export const layoutTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.room_type && jsonQuery.room_type.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isLayoutTypeTag, () => ({ type: ILayoutTypeTagType, value }));
    } else {
      return ensureTagNotExists(tags, isLayoutTypeTag);
    }
  },
};
