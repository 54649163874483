import * as React from 'react';

import { JoinElectronicTradingButton } from 'shared/components/JoinElectronicTradingButton/JoinElectronicTradingButton';
import {
  trackJoinTradeClick,
  trackShowTradingWidgetModalClick,
} from 'shared/components/JoinElectronicTradingButton/tracking';
import { useCardContext } from 'shared/context/CardContext';
import { useGetIsElectronicTrading } from 'shared/hooks/useGetIsElectronicTrading';
import { offerHelper } from 'shared/offer';
import { ElectronicTradingWidgetProps } from 'shared/packages/electronic-trading-widget';
import { useApplicationContext } from 'shared/serp/containers/ApplicationContext';
import { getFormattedPhoneNumber } from 'shared/utils/format';

type JoinElectronicTradingButtonContainerProps = {
  text: string;
};

export const JoinElectronicTradingButtonContainer: React.FC<JoinElectronicTradingButtonContainerProps> = props => {
  const { text } = props;
  const { config } = useApplicationContext();
  const { offer } = useCardContext();
  const isElectronicTrading = useGetIsElectronicTrading(offer);

  const legalLink = config.getStrict<string>('electronicTrading.legalDocsUrl');

  const widgetProps = React.useMemo((): ElectronicTradingWidgetProps => {
    const prettyPhones = offer.phones?.map(getFormattedPhoneNumber) || [];
    const publisherTypeLabel = offer.user?.formattedPublisherType || null;

    const {
      presenters: { getAgentName, getCianUserId },
    } = offerHelper(offer);

    const userId = getCianUserId();
    const agentName = getAgentName();

    const publisherName = agentName || userId ? `ID ${userId}` : '';

    return {
      deviceType: 'desktop',
      joinTradeButtonLink: offer.tradingLink || '',
      legalLink,
      publisherAvatarSrc: offer.user?.agentAvatarUrl || null,
      publisherName,
      publisherPhoneNumbers: prettyPhones,
      publisherTypeLabel,
      trackJoinTrade: () => trackJoinTradeClick(offer),
      /**
       * Эти события трекать не требуется. Добавить, если требования поменяются.
       */
      trackLegalLinkClick: /* istanbul ignore next */ () => {},
      trackPublisherPhoneNumberClick: /* istanbul ignore next */ () => {},
    };
  }, [legalLink, offer]);

  if (!isElectronicTrading) {
    return null;
  }

  return (
    <JoinElectronicTradingButton
      {...widgetProps}
      text={text}
      trackShowTradingWidgetModal={() => trackShowTradingWidgetModalClick(offer)}
    />
  );
};
