import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as PropTypes from 'prop-types';
import { Component } from 'react';

import { IOffer } from 'shared/offer';
import { IAbUseExperiment } from 'shared/types/abUseExperiments';
import { showAgentLevel } from 'shared/utils/showAgentLevel';

import { IOfferHelper } from '../../presenter';
import { IUser } from '../../types/user';
import { CommercialOwner } from '../../utils/CommercialOwner';
import { showAgentHonestlyProofs } from '../AgentWorkHonestlyProofs';
import { UserInfoPopup } from '../user-info-popup';

import { UserInfoHorizontal } from './UserInfoHorizontal';
import { UserInfoVertical } from './UserInfoVertical';
import { isAgentBrandShow as getIsAgentBrandShow, isDealAndProfi } from './helpers';

import * as style from './index.css';

export type TUserInfoType = 'vertical' | 'horizontal';

export interface IUserInfoProps {
  containerStyle?: IStyleConfig;
  type?: TUserInfoType;
}

export interface IUserInfoContext {
  offerHelper: IOfferHelper;
  offer: IOffer;
  user: IUser;
  commercialOwner: CommercialOwner;
  abUseExperiments?: IAbUseExperiment[];
}

export class UserInfo extends Component<IUserInfoProps> {
  public context: IUserInfoContext;

  public static contextTypes: Record<keyof UserInfo['context'], PropTypes.Requireable<unknown>> = {
    offer: PropTypes.object,
    offerHelper: PropTypes.object,
    user: PropTypes.object,
    commercialOwner: CommercialOwner.contextTypes,
    abUseExperiments: PropTypes.arrayOf(
      PropTypes.shape({ experimentName: PropTypes.string, string: PropTypes.string }),
    ),
  };

  public static defaultProps = {
    type: 'vertical' as TUserInfoType,
  };

  public render() {
    const isVertical = this.props.type === 'vertical';

    const { offer, offerHelper, user, commercialOwner } = this.context;
    const { isTop3, isByHomeowner } = offer;
    const { tariff } = user;

    const isFromBuilder = offerHelper.isFromBuilder();
    const isFromSeller = offerHelper.isFromSeller();
    const agentAvatarUrl = offerHelper.presenters.getAvatarUrl();
    const isRepresentative = !!(offer.businessShoppingCenter && offer.businessShoppingCenter.fromRepresentative);
    const userPersonalRating = offerHelper.presenters.getUserPersonalRating();
    const accountType = offerHelper.presenters.getAccountType();
    const isAgentBrandShow = getIsAgentBrandShow(offer);
    const isShowLabelOfAccountType = isDealAndProfi(tariff, accountType);

    if (
      [
        isAgentBrandShow,
        isFromBuilder,
        isFromSeller,
        isByHomeowner,
        isRepresentative,
        isShowLabelOfAccountType,
        userPersonalRating,
        commercialOwner?.showOwner,
        showAgentHonestlyProofs(offer),
        showAgentLevel(offer),
      ].every(el => !el)
    ) {
      return <div />;
    }

    const avatar = isAgentBrandShow && isTop3 && agentAvatarUrl && isVertical && (
      <div className={`${style['userAvatar']}`}>
        <img alt="avatar" src={`${agentAvatarUrl.replace(/(p=\d)$/g, 'p=1')}`} />
      </div>
    );

    const content = isVertical ? <UserInfoVertical /> : <UserInfoHorizontal />;

    return (
      <UserInfoPopup
        avatar={avatar}
        containerStyle={mergeStyles(
          isVertical ? style['container'] : style['containerHorizontal'],
          this.props.containerStyle,
        )}
        content={content}
        userInfoStyle={mergeStyles(!isVertical && style['userInfo--horizontal'])}
      />
    );
  }
}
