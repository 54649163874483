import { IConfig } from '@cian/config/shared';

import { clone } from 'ramda';

import { EOfficeType, IJsonQuery, IJsonQueryUnderground, TGeoValue } from '../json_query';
import { setTerm, setTerms } from '../json_query/reducers/helpers';
import { IUndergroundShortcuts } from '../types/undergroundStationData';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from '../utils/category';
import { getIsSaleBusinessOffer } from '../utils/filters/getIsSaleBusinessOffer';
import { includesAll } from '../utils/includesAll';
import { getJsonQueryForSort } from '../utils/sort_request';

export const isNewobjectPage = (jsonQuery: IJsonQuery): boolean =>
  !!jsonQuery.geo && jsonQuery.geo.value.length === 1 && jsonQuery.geo.value[0].type === 'newobject';

export const isDeveloperPage = (jsonQuery: IJsonQuery): boolean =>
  !!jsonQuery.geo && jsonQuery.geo.value.length === 1 && jsonQuery.geo.value[0].type === 'builder';

export const isBSCenterPage = (jsonQuery: IJsonQuery): boolean =>
  !!jsonQuery.bs_center_id || (!!jsonQuery.bs_center && jsonQuery.bs_center.value.length === 1);

export const getBSCenterId = (jsonQuery: IJsonQuery): number | undefined => {
  let bsCenterId;

  if (jsonQuery.bs_center_id) {
    bsCenterId = jsonQuery.bs_center_id.value;
  }

  if (jsonQuery.bs_center && jsonQuery.bs_center.value.length === 1) {
    bsCenterId = jsonQuery.bs_center.value[0];
  }

  return bsCenterId;
};

export const getSortReplaceCoworkingOfficeType = (jsonQuery: IJsonQuery): IJsonQuery => {
  const jsonQueryForSort: IJsonQuery = getJsonQueryForSort(jsonQuery) || jsonQuery;
  if (
    jsonQueryForSort &&
    jsonQueryForSort.office_type &&
    jsonQueryForSort.office_type.value.length === 1 &&
    jsonQueryForSort.office_type.value[0] === EOfficeType.Coworking &&
    jsonQueryForSort.coworking_offer_type &&
    jsonQueryForSort.coworking_offer_type.value.length > 0
  ) {
    return setTerms('office_type')(jsonQueryForSort)([EOfficeType.Office]);
  }

  return jsonQueryForSort;
};

export function jsonQueryReadyBusinessFilter(jsonQuery: IJsonQuery): IJsonQuery {
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  let nextJsonQuery = clone(jsonQuery);

  if (getIsSaleBusinessOffer(dealType, offerType)) {
    nextJsonQuery = setTerm('is_by_commercial_owner')(nextJsonQuery)(undefined);
  }

  return nextJsonQuery;
}

export function enhanceSearchConditions(jsonQuery: IJsonQuery, region: string, config: IConfig) {
  const undergroundIds = jsonQuery.geo?.value.filter(findUndergroundGeo).map(underground => underground.id) || [];
  const data = config.get<IUndergroundShortcuts>('undergroundMapShortcuts') || {};

  if (data[region]) {
    const shortcuts = data[region];
    for (const shortcut of shortcuts) {
      if (includesAll(undergroundIds, shortcut.ids)) {
        return {
          ...jsonQuery,
          geo: {
            type: 'geo',
            value: [
              ...(jsonQuery.geo?.value || []),
              {
                type: 'underground_all',
                value: shortcut.label,
              } as unknown,
            ],
          },
        };
      }
    }
  }

  return jsonQuery;
}

function findUndergroundGeo(geo: TGeoValue): geo is IJsonQueryUnderground {
  return geo.type === 'underground';
}
