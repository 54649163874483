import { ICrosslinkBlockSchema } from '../../../types/seo';

import * as styles from './SeoCrossLinks.css';

interface ISeoLinksProps {
  seoBlocks: ICrosslinkBlockSchema[];
}

export function SeoCrossLinks({ seoBlocks }: ISeoLinksProps) {
  return (
    <div className={styles['container']}>
      {seoBlocks.map((seoBlock, blockIndex) => {
        return (
          seoBlock.links && (
            <div className={styles['seo-block']} key={`seo-block-${blockIndex}`}>
              <div className={styles['title']}>{seoBlock.title}</div>
              <div className={styles['links']}>
                {seoBlock.links.map((seoLink, linkIndex) => {
                  return (
                    <a className={styles['link']} href={seoLink.uri || ''} key={`seo-link-${linkIndex}`}>
                      {seoLink.name}
                    </a>
                  );
                })}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
}
