import { CustomTooltip } from '@cian/ui-kit';
import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as PropTypes from 'prop-types';
import * as React from 'react';

import { TAgentRating } from 'shared/api/agent';
import { IOffer } from 'shared/offer';
import { IJsonQuery } from 'shared/repositories/common/json_query';

import { IOfferHelper } from '../../presenter';

import { IconBlacklist, IconWhitelist } from './icons';

const style = require('./index.css');

export interface IUserInfoAPI {
  ratingBlockedAgents: number[];
  userIsAuthenticated: boolean;
  onAgentRatingChanged(offer: IOffer, rating: TAgentRating): void;
  onPopupOpen?(): void;
  onPopupClose?(): void;
}

export interface IUserInfoProps {
  content: React.ReactNode;
  avatar?: React.ReactNode | null;
  containerStyle?: IStyleConfig;
  userInfoStyle?: IStyleConfig;
  userInfoStyleAction?: IStyleConfig;
  isReverseOrder?: boolean;
}

export interface IUserInfoState {
  isPopupVisible: boolean;
}

export interface IUserInfoPopupContext {
  baseUrl: string;
  jsonQuery: IJsonQuery;
  offer: IOffer;
  offerHelper: IOfferHelper;

  api: { userInfo: IUserInfoAPI };
}

export class UserInfoPopup extends React.Component<IUserInfoProps, IUserInfoState> {
  private userInfoRef = React.createRef<HTMLDivElement>();
  public state = {
    isPopupVisible: false,
  };

  public context: IUserInfoPopupContext;

  public static contextTypes = {
    api: PropTypes.object,
    baseUrl: PropTypes.string,
    jsonQuery: PropTypes.object,
    offer: PropTypes.object,
    offerHelper: PropTypes.object,
  };

  public componentDidUpdate(prevProps: IUserInfoProps, prevState: IUserInfoState) {
    if (prevState.isPopupVisible !== this.state.isPopupVisible) {
      if (this.state.isPopupVisible) {
        if (this.context.api.userInfo.onPopupOpen) {
          this.context.api.userInfo.onPopupOpen();
        }
      } else {
        if (this.context.api.userInfo.onPopupClose) {
          this.context.api.userInfo.onPopupClose();
        }
      }
    }
  }

  public render() {
    const { userIsAuthenticated } = this.context.api.userInfo;
    const { avatar, content, userInfoStyle, userInfoStyleAction, isReverseOrder } = this.props;
    const { isPopupVisible } = this.state;

    const offer = this.context.offerHelper;

    const userPersonalRating = offer.presenters.getUserPersonalRating();
    const userId = offer.presenters.getCianUserId();

    return (
      <div
        {...mergeStyles([style['container'], isReverseOrder && style['container-reverse'], this.props.containerStyle])}
      >
        <div
          {...mergeStyles([
            style['user_info'],
            isPopupVisible && style['user_info--active'],
            userInfoStyle,
            isPopupVisible && userInfoStyleAction,
          ])}
          ref={this.userInfoRef}
          onClick={this.handleInfoClick}
        >
          {content}
        </div>
        <CustomTooltip
          anchorRef={this.userInfoRef}
          arrow={true}
          content={
            <div className={style['popup']}>
              {isReverseOrder && this.renderLink(userId)}
              {isReverseOrder && userIsAuthenticated && <hr className={style['delimiter']} />}
              {userIsAuthenticated && (
                <ul className={style['links']}>
                  <li className={style['links-link']}>
                    <a
                      className={style['link-item']}
                      href="#"
                      onClick={event =>
                        this.handleListClick(event, userPersonalRating !== 'negative' ? 'negative' : 'neutral')
                      }
                    >
                      <IconBlacklist isActive={userPersonalRating === 'negative'} />
                      {userPersonalRating !== 'negative' ? 'В чёрный список' : 'Убрать из чёрного списка'}
                    </a>
                  </li>
                  <li className={style['links-link']}>
                    <a
                      className={style['link-item']}
                      href="#"
                      onClick={event =>
                        this.handleListClick(event, userPersonalRating !== 'positive' ? 'positive' : 'neutral')
                      }
                    >
                      <IconWhitelist isActive={userPersonalRating === 'positive'} />
                      {userPersonalRating !== 'positive' ? 'В белый список' : 'Убрать из белого списка'}
                    </a>
                  </li>
                </ul>
              )}
              {!isReverseOrder && userIsAuthenticated && <hr className={style['delimiter']} />}
              {!isReverseOrder && this.renderLink(userId)}
            </div>
          }
          flip={false}
          maxWidth={238}
          open={isPopupVisible}
          outside={true}
          placement="bottom-end"
          theme="white"
          onClose={this.handleOutsideClick}
        />
        {avatar}
      </div>
    );
  }

  private renderLink(userId: number | string | undefined) {
    const { isReverseOrder } = this.props;
    const { baseUrl, offer } = this.context;
    const userQuery = userId ? `id_user=${userId}&` : '';
    const profileUri = offer && offer.user && offer.user.profileUri;

    return (
      <div className={style['all_offers']}>
        {isReverseOrder && profileUri && (
          <a className={style['offer-link']} href={`${baseUrl || ''}${profileUri}`} rel="noreferrer" target="_blank">
            Профиль агента
          </a>
        )}
        <a
          className={style['offer-link']}
          href={`/cat.php?${userQuery}deal_type=${offer.dealType}&offer_type=office&engine_version=2`}
          rel="noreferrer"
          target="_blank"
        >
          Все объявления агента
        </a>
      </div>
    );
  }

  private handleListClick = (event: React.MouseEvent<HTMLAnchorElement>, rating: TAgentRating) => {
    event.preventDefault();

    this.context.api.userInfo.onAgentRatingChanged(this.context.offer, rating);
  };

  private handleInfoClick = () => {
    this.setState({
      isPopupVisible: !this.state.isPopupVisible,
    });
  };

  private handleOutsideClick = () => {
    if (!this.state.isPopupVisible) {
      return;
    }

    this.setState({
      isPopupVisible: false,
    });
  };
}
