import { mergeStyles } from '@cian/utils/lib/shared/style';

import * as React from 'react';

import { ImageNone } from '../image-none-component';
import { Slider } from '../slider';

import { GalleryItemPreloader } from './preloader';

const style = require('./index.css');

export interface IGalleryCounterProps {
  currentIndex: number;
  itemsTotal: number;
}

export interface IGalleryProps extends Readonly<{ children?: React.ReactNode[] }> {
  defaultIndex?: number;
  controlsHidden?: boolean;
  counterHidden?: boolean;
  onSlideChanged?(): void;
  controlButtonsFullHeight?: boolean;
  counter?: React.ElementType<IGalleryCounterProps>;
}

export interface IGalleryState {
  items: React.ReactNode[];
  itemsTotal: number;
  sliderInitialized: boolean;
}

export class Gallery extends React.Component<IGalleryProps, IGalleryState> {
  public slider: Slider | null = null;

  public constructor(props: IGalleryProps) {
    super(props);

    this.state = {
      items: React.Children.toArray(
        React.Children.map(props.children, (child, index) => {
          return <GalleryItemPreloader key={`item_${index}`}>{React.Children.only(child)}</GalleryItemPreloader>;
        }),
      ),
      itemsTotal: React.Children.count(props.children),
      sliderInitialized: false,
    };
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IGalleryProps) {
    if (this.props.children !== nextProps.children) {
      this.setState({
        items: React.Children.toArray(
          React.Children.map(nextProps.children, (child, index) => {
            return <GalleryItemPreloader key={`item_${index}`}>{React.Children.only(child)}</GalleryItemPreloader>;
          }),
        ),
        itemsTotal: React.Children.count(nextProps.children),
      });
    }
  }

  public render() {
    /**
     * Для обратной совместимости делаем "counterHidden = controlsHidden".
     * Если явно не передано какого-либо значения для "counterHidden" считаем, что видимость контролов
     * галереи и каунтера управляется как и раньше только одним флагом "controlsHidden"
     */
    const { controlsHidden, counterHidden = controlsHidden } = this.props;

    const isControlsHidden = controlsHidden && counterHidden;

    return (
      <div className={style.container}>
        {React.Children.count(this.props.children) > 0 && (
          <Slider
            defaultIndex={this.props.defaultIndex}
            ref={slider => (this.slider = slider)}
            onSlideChange={this.onSlideChange}
            onSliderInitialized={this.onSliderInitialized}
          >
            {this.state.items}
          </Slider>
        )}
        {React.Children.count(this.props.children) === 0 && <ImageNone />}
        <div className={style.controls} style={{ display: isControlsHidden ? 'none' : '' }}>
          {this.renderControls()}
          {this.renderCounter()}
        </div>
      </div>
    );
  }

  private renderControls() {
    if (this.props.controlsHidden) {
      return null;
    }

    if (!this.state.sliderInitialized || this.state.itemsTotal < 2) {
      return null;
    }

    return (
      <div {...mergeStyles(this.props.controlButtonsFullHeight && style['controls-full-height'])}>
        <button
          className={style['controls-prev']}
          onClick={e => {
            e.stopPropagation();
            if (this.slider) {
              this.slider.prev();
            }
          }}
        >
          {/* eslint-disable-next-line react/forbid-elements */}
          <svg height="23" viewBox="0 0 20.57 38" width="13" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.54 2L2 18.86 18.57 36"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </svg>
        </button>
        <button
          className={style['controls-next']}
          onClick={e => {
            e.stopPropagation();
            if (this.slider) {
              this.slider.next();
            }
          }}
        >
          {/* eslint-disable-next-line react/forbid-elements */}
          <svg height="23" viewBox="0 0 20.57 38" width="13" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2.03 36l16.54-16.86L2 2"
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="4"
            />
          </svg>
        </button>
      </div>
    );
  }

  private renderCounter() {
    const { counterHidden, counter: Counter } = this.props;

    if (counterHidden) {
      return null;
    }

    if (!this.slider || !this.state.sliderInitialized || this.state.itemsTotal < 1) {
      return null;
    }

    if (Counter) {
      return <Counter currentIndex={this.slider.currentIndex} itemsTotal={this.state.itemsTotal} />;
    }

    return (
      <div className={style['controls-counter']}>{`${this.slider.currentIndex + 1} из ${this.state.itemsTotal}`}</div>
    );
  }

  private onSliderInitialized = () => {
    this.setState({
      sliderInitialized: true,
    });
  };

  private onSlideChange = () => {
    this.forceUpdate();

    if (this.props.onSlideChanged) {
      this.props.onSlideChanged();
    }
  };
}

export * from './image';
