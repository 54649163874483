import { ModalWindow, Spinner } from '@cian/ui-kit';

import { Component } from 'react';

import { IGeoDistrict } from '../../../api/geo/geo';
import { IJsonQueryDistrict } from '../../../json_query';
import { TLocation } from '../../../types/location';

import { MoscowDistrictsHeader } from './header/';
import { DistrictsLayout, isMoscowLocation } from './layout';

const style = require('./index.css');

export interface IStateProps {
  isDataLoading: boolean;
  districts: IGeoDistrict[] | undefined;
  selectedLocation: TLocation;
  selectedDistricts: IJsonQueryDistrict[];
  includeNewMoscow: boolean | undefined;
  isDistrictModalVisible: boolean;
}

export interface IDispatchProps {
  onClose(): void;
  onSave(): void;
  onSelected(id: number, name: string): void;
  onNewMoscowSwitched(includeNewMoscow: boolean | undefined): void;
  onRemoved(id: number): void;
  onSearchButtonClick(): void;
}

export type TDistrictsModalProps = IStateProps & IDispatchProps;

// Не используется, нужно удалить в будущем
export class DistrictsModal extends Component<TDistrictsModalProps> {
  public render() {
    return (
      <ModalWindow
        // В дальнейшем будет удален весь файл, оставил на всякий случай
        footer={null}
        header={
          isMoscowLocation(this.props.selectedLocation) && (
            <MoscowDistrictsHeader
              includeNewMoscow={this.props.includeNewMoscow}
              onNewMoscowSwitched={this.props.onNewMoscowSwitched}
            />
          )
        }
        open={this.props.isDistrictModalVisible}
        width="85%"
        onClose={this.props.onClose}
      >
        <div>
          {this.props.isDataLoading && (
            <div className={style.preloaderWrapper}>
              <div className={style['preloader']}>
                {/* Заменил Preloader из cian/components на Spinner
                  В дальнейшем будет удален весь файл, оставил на всякий случай */}
                <Spinner size={48} />
              </div>
            </div>
          )}
          {this.props.districts && this.renderDistricts(this.props.districts)}
        </div>
      </ModalWindow>
    );
  }

  private renderDistricts(districts: IGeoDistrict[]) {
    return (
      <div className={style.districts}>
        <DistrictsLayout
          areas={districts}
          selectedDistricts={this.props.selectedDistricts}
          selectedLocation={this.props.selectedLocation}
          onRemoved={this.props.onRemoved}
          onSelected={this.props.onSelected}
        />
      </div>
    );
  }
}
