import { FC, useCallback, useEffect, useRef } from 'react';

import { easeIn, getMask } from './helpers';

import styles from './ScrollFade.css';

interface IScrollFadeProps {
  size: number;
}

export const ScrollFade: FC<IScrollFadeProps> = ({ size }) => {
  const rootRef = useRef<HTMLDivElement>(null);

  const onScroll = useCallback(() => {
    const scrollElement = rootRef.current?.parentElement;
    if (scrollElement) {
      const { offsetHeight, scrollHeight, scrollTop } = scrollElement;
      const opacityTop = easeIn((scrollHeight - offsetHeight - scrollTop) / (offsetHeight - scrollHeight), 10);
      const opacityBottom = easeIn(scrollTop / (offsetHeight - scrollHeight), 10);
      const mask = getMask({ size, opacityTop, opacityBottom });

      scrollElement.style.mask = mask;
      scrollElement.style.webkitMask = mask;
    }
  }, [size]);

  useEffect(() => {
    const scrollElement = rootRef.current?.parentElement;
    if (!scrollElement) {
      return;
    }

    const { offsetHeight, scrollHeight } = scrollElement;

    if (offsetHeight !== scrollHeight) {
      const mask = getMask({ size, opacityTop: 1, opacityBottom: 0 });
      scrollElement.style.mask = mask;
      scrollElement.style.webkitMask = mask;
    }

    scrollElement.addEventListener('scroll', onScroll, { passive: true });

    return () => scrollElement.removeEventListener('scroll', onScroll);
  }, [onScroll, size]);

  return <span className={styles['container']} ref={rootRef} />;
};
