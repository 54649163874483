import { IAbUseExperiment } from '../types/abUseExperiments';

export function isAbUseExperimentGroup(experiments: IAbUseExperiment[], experimentName: string, targetGroup: string) {
  const experiment = experiments.find(e => e.experimentName === experimentName);

  if (experiment) {
    return experiment.groupName === targetGroup;
  }

  return false;
}
