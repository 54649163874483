export * from '../repositories/search-offers.legacy/v2/search-offers-desktop.data';
export * from '../repositories/search-offers.legacy/v2/types';
export type { ISearchOffersDesktopResponseDataSchema as IOffersData } from '../repositories/search-offers.legacy/v2/search-offers-desktop.data';

export interface IOfferAnalyticsInfo {
  id?: number;
  name?: string;
  price?: number;
  brand?: string;
  category?: string;
  variant?: string;
  quantity: number;
  ownerId?: number;
  top3Place?: string;
}

export const BargainTermsSaleTypePresenter: {
  [index: string]: string;
} = {
  alternative: 'альтернатива',
  dupt: 'переуступка',
  dzhsk: 'договор ЖСК',
  free: 'свободная',
  fz214: '214-ФЗ',
  investment: 'договор инвестирования',
  pdkp: 'предварительный договор',
};

interface IBuildingMaterialTypePresenter {
  [index: string]: string;
}

export const BuildingMaterialTypePresenter: IBuildingMaterialTypePresenter = {
  block: 'блочный',
  brick: 'кирпичный',
  monolith: 'монолитный',
  monolithBrick: 'кирпично-монолитный',
  panel: 'панельный дом',
  stalin: 'сталинский',
  wood: 'деревянный',
};

interface IBuldingQuarterPresenter {
  [index: string]: string;
}

export const BuildingQuarter: IBuldingQuarterPresenter = {
  first: '1',
  fourth: '4',
  second: '2',
  third: '3',
};

export type TLeaseTerm = 'longTerm' | 'fewMonths' | null;
export const LeaseTerm = {
  FewMonths: 'fewMonths' as const,
  Long: 'longTerm' as const,
};

export const ERegions = {
  moscow: 1 as number,
  moscowArea: 4593 as number,
  spb: 2 as number,
  spbArea: 4588 as number,
};

export const PrimaryMetroRegions = [ERegions.moscow, ERegions.moscowArea, ERegions.spb, ERegions.spbArea];
