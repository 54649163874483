import { ESuburbanOfferFilter as ESuburbanOfferFilterV2 } from 'shared/repositories/geo-suggest/v2/suggest';

import { ESuburbanOfferFilter } from '../../../json_query';
import { TLocation } from '../../../types/location';
import { FDealType, FOfferType } from '../../../utils/category';
import { getRegionId, isNewbuildingsRegion } from '../../../utils/geo';

export type TGeoSuggestItemType = 1 | 2 | 3 | 4 | 5;
export const GeoSuggestItemType = {
  Address: 1 as const,
  Newbuilding: 2 as const,
  BSCenter: 3 as const,
  Highway: 4 as const,
  Railway: 5 as const,
};

export const isNewbuildingsAvailable = (dealType: FDealType, offerType: FOfferType, currentLocation: TLocation) =>
  dealType & FDealType.Any && offerType & FOfferType.Urban && isNewbuildingsRegion(getRegionId(currentLocation));

export const removeCountryName = (value: string) => value.replace(/(^Россия, )|(, Россия$)/i, '');

export const SUBURBAN_OFFER_FILTER = {
  [ESuburbanOfferFilter.Any]: ESuburbanOfferFilterV2.All,
  [ESuburbanOfferFilter.Builder]: ESuburbanOfferFilterV2.Builder,
  [ESuburbanOfferFilter.Individual]: ESuburbanOfferFilterV2.Individual,
};

export const getSuburbanOfferFilterValue = (suburbanOfferFilterValue: ESuburbanOfferFilter | undefined) => {
  if (suburbanOfferFilterValue == null) {
    return suburbanOfferFilterValue;
  }

  return SUBURBAN_OFFER_FILTER[suburbanOfferFilterValue];
};
