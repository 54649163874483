import { FC } from 'react';

import { useCardContext } from '../../../../context/CardContext';
import { useApplicationContext } from '../../../../serp/containers/ApplicationContext';

import { AuctionPopup, IAuctionPopupProps } from './AuctionPopup';

type AuctionPopupContainerProps = Omit<
  IAuctionPopupProps,
  'httpApi' | 'auctionMaxBet' | 'validateStep' | 'offer' | 'maxAuctionBet' | 'maxAuctionService' | 'isMlSearchForAll'
>;

export const AuctionPopupContainer: FC<AuctionPopupContainerProps> = props => {
  const { httpApi } = useApplicationContext();
  const { auctionMaxBet, validateStep, offer, maxAuctionBet, maxAuctionService, isMlSearchForAll } = useCardContext();

  return (
    <AuctionPopup
      auctionMaxBet={auctionMaxBet}
      httpApi={httpApi}
      isMlSearchForAll={isMlSearchForAll}
      maxAuctionBet={maxAuctionBet}
      maxAuctionService={maxAuctionService}
      offer={offer}
      validateStep={validateStep}
      {...props}
    />
  );
};
