import { ErrorLogComponent } from '@cian/error-log-component';
import { DynamicCalltrackingContext } from '@cian/frontend-dynamic-calltracking-component';

import * as React from 'react';
import { Provider } from 'react-redux';

import { SearchEngineResultsPageContainer } from '../../common/components/serp/container';
import { IAppStore } from '../../common/state';
import { ApplicationContext } from '../../serp/containers/ApplicationContext';
import { IApplicationContext } from '../../types/applicationContext';

export interface IApplicationProps {
  context: IApplicationContext;
  reduxStore: IAppStore;
}

export const Application: React.FC<IApplicationProps> = ({ reduxStore, context }) => {
  const { logger } = context;

  return (
    <ErrorLogComponent logger={logger}>
      <ApplicationContext.Provider value={context}>
        <DynamicCalltrackingContext.Provider value={context}>
          <Provider store={reduxStore}>
            <SearchEngineResultsPageContainer />
          </Provider>
        </DynamicCalltrackingContext.Provider>
      </ApplicationContext.Provider>
    </ErrorLogComponent>
  );
};
