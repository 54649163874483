import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import * as styles from './Tooltip.css';

export interface ITooltipProps {
  containerClassName?: string;
  text?: string | React.ReactNode;
  textClass?: string;
  master?: React.ReactNode;
  icon: React.ReactNode;
  iconColor: string;
  iconSize: number;
  customStyles?: React.CSSProperties;
  onOpen?(): void;
  onClose?(): void;
}

export interface ITooltipState {
  isOpen: boolean;
}

export class Tooltip extends React.Component<React.PropsWithChildren<ITooltipProps>, ITooltipState> {
  public timer: number;
  public constructor(props: ITooltipProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  public openSlave = () => {
    if (!this.state.isOpen) {
      this.setState({
        isOpen: true,
      });

      const { onOpen } = this.props;
      if (onOpen) {
        onOpen();
      }
    }
  };

  private handleMouseEnter = () => {
    this.timer = window.setTimeout(this.openSlave, 0);
  };

  public close = () => {
    this.setState({
      isOpen: false,
    });

    const { onClose } = this.props;
    if (onClose) {
      onClose();
    }
  };

  private handleMouseLeave = () => {
    const { isOpen } = this.state;

    if (isOpen) {
      this.timer = window.setTimeout(this.close, 200);
    }
  };

  public componentWillUnmount() {
    window.clearTimeout(this.timer);
  }

  public render() {
    const { children, master, text, textClass, icon, iconColor, iconSize, customStyles, containerClassName } =
      this.props;
    const { isOpen } = this.state;

    return (
      <div {...mergeStyles([styles['tooltip'], customStyles, isOpen && styles['open']])}>
        {text && <span className={textClass}>{text}</span>}
        {master || (
          <span onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
            <span
              className={styles['icon']}
              style={{
                width: `${iconSize}px`,
                height: `${iconSize}px`,
                color: iconColor,
              }}
            >
              {icon}
            </span>
            <div {...mergeStyles([styles['container'], containerClassName])}>
              <div className={styles['content']}>{children}</div>
            </div>
          </span>
        )}
      </div>
    );
  }
}
