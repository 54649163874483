import { ca } from '@cian/analytics';

import { IJsonQuery } from 'shared/json_query';
import { offerHelper } from 'shared/offer/presenters';
import { IBreadcrumb, IOffer } from 'shared/offer/types';
import { TUser } from 'shared/types/user';

export const trackMLSuggestionsShown = (
  jsonQuery: IJsonQuery,
  recommendations: IOffer[],
  allRecommendations: IOffer[],
  modelVersion: number | null,
  pageNumber: number,
  breadcrumbs: IBreadcrumb[],
  queryString: string,
  offersQty: number,
  user: TUser,
  abGroup: number,
) => {
  const products = recommendations.map((offer, index) => {
    const { id, variant, ownerId } = offerHelper(offer).getAnalyticsInfo();

    const i = allRecommendations.length && allRecommendations.findIndex(o => o.id === offer.id);
    const position = i ? i + 1 : index + 1;

    return {
      id,
      offerType: 'offer',
      position,
      modelVersion: modelVersion || 0,
      published: offer.status === 'published',
      variant: variant ? variant.split('/') : [],
      ownerId,
    };
  });

  const ebcObject = {
    name: 'oldevent',
    category: 'page',
    action: 'newpage_infinite_search',
    label: '',
    searchConditions: jsonQuery,
    modelVersion:
      typeof modelVersion !== 'object' && typeof modelVersion !== 'undefined' ? String(modelVersion) : undefined,
    products,
    page: {
      listingType: 'list',
      sortType: jsonQuery.sort ? jsonQuery.sort : 'default',
      pageNumber,
      breadCrumbs: breadcrumbs.map(breadcrumb => breadcrumb.title),
      queryString,
      offersQty,
      pageType: 'Listing',
      siteType: 'desktop',
    },
    user: {
      userId: user.isAuthenticated && user.userId ? Number(user.userId) : undefined,
      isAuthorized: user.isAuthenticated,
      abGroup,
    },
  };

  ca('eventEbc', ebcObject);
};
