import { ESaleType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const ISaleTypesTagType = 'advanced-saleType-saleType';

export interface ISaleTypesTag {
  type: 'advanced-saleType-saleType';
  value: ESaleType;
}

function isSaleTypesTag(tag: TTag): tag is ISaleTypesTag {
  return tag.type === ISaleTypesTagType;
}

export const saleTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.sost_type && jsonQuery.sost_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== ISaleTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: ESaleType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is ISaleTypesTag => isSaleTypesTag(tag) && tag.value === value,
            () => ({ type: ISaleTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
