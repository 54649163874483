import { IIconProps, ISVGProps, createIcon } from '@cian/ui-kit/icons/internal';

import * as React from 'react';

// eslint-disable-next-line react/display-name
const IconComponent = React.forwardRef((props: ISVGProps, ref: React.Ref<SVGSVGElement>) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg {...props} fill="none" height="12" ref={ref} viewBox="0 0 12 12" width="12" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="m4.32 9.354.807.646.687-.77L11 3.418 9.373 2 4.802 7.123 2.364 5.035 1 6.703l3.32 2.651Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
});

/**
 * Компоненты иконки <br />
 * Некоторые иконки красятся в любые цвета из палитры, некоторые имеют зафиксированные цвета.<br />
 * Подключение иконки: `import { CheckboxSuccess12 } from '@cian/ui-kit/icons';`, <br />
 * где 12 - это размер иконки в пикселях.
 */
export const CheckboxSuccess12: React.FunctionComponent<IIconProps> = createIcon(IconComponent);
