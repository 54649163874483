const style = require('./index.css');

interface ITimeLabelProps {
  added: string;
  humanizedTime: string;
}

export const TimeLabel = (props: ITimeLabelProps) => {
  return (
    <div className={style['timeLabel']}>
      <div className={style['relative']}>{props.humanizedTime}</div>
      <div className={style['absolute']}>{props.added}</div>
    </div>
  );
};
