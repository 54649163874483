import { TUser } from '../types/user';

export function canModerateAnnouncements(user: TUser): boolean {
  return user.isAuthenticated && user.permissions.canModerateAnnouncements;
}

export function canModerateUsers(user: TUser): boolean {
  return user.isAuthenticated && user.permissions.canModerateUsers;
}

export function canViewUsers(user: TUser): boolean {
  return user.isAuthenticated && user.permissions.canViewUsers;
}

export function canUseHiddenBase(user: TUser): boolean {
  return user.isAuthenticated && user.permissions.canUseHiddenBase;
}
