/* eslint-disable no-empty */
import { IJsonQuery } from '../json_query';

export const HISTORY_KEY = '__serp_history_search__';

export interface IHistorySearch {
  title: string;
  url: string;
  activated: boolean;
  jsonQuery: IJsonQuery;
}

export function saveHistorySearch(title: string | null, url: string, jsonQuery: IJsonQuery) {
  /* istanbul ignore next */
  if (typeof window === 'undefined' || !title) {
    return;
  }

  const result = {
    title,
    url,
    activated: false,
    jsonQuery,
  };

  try {
    const currentHistorySearch: IHistorySearch[] = JSON.parse(window.localStorage.getItem(HISTORY_KEY) as string) || [];

    /**
     * Не сохраняем текущий поиск, если он уже есть в localStorage
     */
    if (currentHistorySearch && currentHistorySearch.some(history => history.url === url)) {
      return;
    }

    currentHistorySearch.push(result);

    if (currentHistorySearch.length > 5) {
      currentHistorySearch.shift();
    }

    try {
      window.localStorage.setItem(HISTORY_KEY, JSON.stringify(currentHistorySearch));
      /* istanbul ignore next */
    } catch (e) {}
    /* istanbul ignore next */
  } catch (e) {}
}

export function getLastHistorySearch(): IHistorySearch | undefined {
  try {
    const historySearches: IHistorySearch[] = JSON.parse(window.localStorage.getItem(HISTORY_KEY) as string);

    if (historySearches) {
      return historySearches[historySearches.length - 1];
    }

    return undefined;

    /* istanbul ignore next */
  } catch (e) {}

  return undefined;
}
