import { IAdFoxBannerContext } from '@cian/adfox/build/react';

import * as React from 'react';

const style = require('./index.css');

export interface IMortgageProps {
  context: IAdFoxBannerContext;
}

export const CommercialMortgageBanner: React.FC<IMortgageProps> = ({ context }) => {
  return (
    <span className={style['container']}>
      <a className={style['link']} href={context.link} rel="nofollow noopener noreferrer" target="_blank">
        {context.name}
      </a>
      <span className={style['dot']}>&nbsp;·&nbsp;</span>
      <a className={style['title']} href={context.link} rel="nofollow noopener noreferrer" target="_blank">
        Бизнес-ипотека
      </a>
    </span>
  );
};
