import { ArticleHeading4, IconCianLogo16, Label, Tooltip, UIText2 } from '@cian/ui-kit';

import { FC } from 'react';

import styles from './UniqueForCianBadge.css';

export const UniqueForCianBadge: FC = () => (
  <Tooltip
    placement="bottom-start"
    theme="white"
    title={
      <div className={styles['content']}>
        <ArticleHeading4>Только на Циан</ArticleHeading4>
        <UIText2>
          За последние сутки мы не нашли этот объект
          <br />в открытом доступе на других агрегаторах
        </UIText2>
      </div>
    }
  >
    <Label background="primary_10" color="primary_100" icon={<IconCianLogo16 color="primary_100" />}>
      Только на Циан
    </Label>
  </Tooltip>
);

UniqueForCianBadge.displayName = 'UniqueForCianBadge';
