import { connect } from 'react-redux';

import { IAppState } from 'shared/common/state';
import { IWithTrackingProps } from 'shared/serp/hoc/withTracking';
import { prepareTrackingData } from 'shared/utils/prepareTrackingData';
import { shouldSendAdsEvent } from 'shared/utils/shouldSendAdsEvent';

import { ITGSimplifiedBannerOwnProps, TGSimplifiedBanner } from './TGSimplifiedBanner';

export function mapStateToProps(state: IAppState): IWithTrackingProps {
  const { filters, results, userGADataLayerData, breadcrumbs, user, featureToggle } = state;

  return {
    tracking: prepareTrackingData(filters, results, breadcrumbs, userGADataLayerData),
    shouldSendAdsEvent: shouldSendAdsEvent(filters.jsonQuery) || !featureToggle.isFilterByFlatsEnabled,
    realtyUserId: user.isAuthenticated ? user.userId : undefined,
  };
}

export const TGSimplifiedBannerContainer = connect<IWithTrackingProps, {}, ITGSimplifiedBannerOwnProps>(
  mapStateToProps,
)(TGSimplifiedBanner);
