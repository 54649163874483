import { IHttpApi } from '@cian/http-api';

import * as PropTypes from 'prop-types';
import { Component } from 'react';

export interface ICallbackModalProps {
  agentPhone: string;
  callFrom?: string;
  callTo?: string;
  close(): void;
  gaLabel: string;
  httpApi: IHttpApi;
  isAvailableToCall?: boolean;
  timezoneText?: string;
  useOldWidget?: boolean;
}

export interface ICallbackModalContainerContext {
  currentSubdomain: string;
  httpApi: IHttpApi;
}

export class CallbackModalContainer extends Component<ICallbackModalProps> {
  public context: ICallbackModalContainerContext;
  private container: HTMLDivElement | null = null;

  public static contextTypes = {
    httpApi: PropTypes.object,
    currentSubdomain: PropTypes.string,
  };

  public constructor(props: ICallbackModalProps) {
    super(props);

    if (document) {
      this.container = document.createElement('div');
      document.body.appendChild(this.container);
    }
  }

  public componentWillUnmount() {
    if (document && this.container) {
      document.body.removeChild(this.container);
    }
  }

  public render() {
    return null;
  }
}
