import { IAppState, TActions } from '../../../common/state';
import { EPublishPeriod } from '../../../json_query';

const PublishPeriodChangedActionType = 'filters/publish_period/PUBLISH_PERIOD_CHANGED';

export interface IPublishPeriodChangedAction {
  type: 'filters/publish_period/PUBLISH_PERIOD_CHANGED';
  publishPeriod: EPublishPeriod;
}

export function changePublishPeriod(publishPeriod: EPublishPeriod): IPublishPeriodChangedAction {
  return {
    type: 'filters/publish_period/PUBLISH_PERIOD_CHANGED',
    publishPeriod,
  };
}

export function publishPeriodReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case PublishPeriodChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            publish_period:
              action.publishPeriod === EPublishPeriod.Any
                ? undefined
                : {
                    type: 'term',
                    value: action.publishPeriod,
                  },
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'publishPeriod') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              publish_period: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            publish_period: undefined,
          },
        },
      };

    default:
      return state;
  }
}
