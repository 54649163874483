/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TOffersGetOffersModel,
  IOffersGetOffersRequest,
  IMappers,
  IOffersGetOffersResponse,
  IOffersGetOffersResponseError,
  TOffersGetOffersResponse,
} from './types';

const defaultConfig: TOffersGetOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'commercial-search-offers',
  pathApi: '/desktop/v1/offers/get-offers/',
  hostType: 'api',
} as TOffersGetOffersModel;

function createOffersGetOffersModel(parameters: IOffersGetOffersRequest): TOffersGetOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IOffersGetOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IOffersGetOffersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchOffersGetOffers<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IOffersGetOffersOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TOffersGetOffersResponse
> {
  const { response, statusCode } = await httpApi.fetch(createOffersGetOffersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IOffersGetOffersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IOffersGetOffersResponseError);
    }
  }

  return { response, statusCode } as TOffersGetOffersResponse;
}

export { defaultConfig, createOffersGetOffersModel, fetchOffersGetOffers };
