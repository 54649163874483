import { EHelpTextPosition, EQuestionActionsTypes, IStep } from '@cian/frontend-request-form-widget';

import { ExtendedFieldType } from '../extendedFieldType';

import { NEWS_SUBSCRIPTION_STEP } from './newsSubscribtion';

export const ABOUT_COMPANY_STEP: IStep = {
  code: 'aboutCompany',
  question: {
    code: 'aboutCompanyQuestion',
    title: 'Что будем искать?',
    fields: [
      {
        name: 'companyName',
        type: ExtendedFieldType.String,
        label: 'Название компании',
      },
      {
        type: ExtendedFieldType.String,
        name: 'specialty',
        label: 'Род деятельности',
        placeholder: 'Например, торговля',
        size: {
          xs: 12,
          m: 8,
        },
      },
      {
        type: ExtendedFieldType.Integer,
        name: 'companyCount',
        unit: 'чел.',
        placeholder: 'Кол-во',
        label: 'Размер команды',
        size: {
          xs: 12,
          m: 4,
        },
      },
      {
        type: ExtendedFieldType.GeoSuggest,
        label: 'Юридический адрес',
        name: 'address',
        placeholder: 'Город, улица, дом',
      },
    ],
    helpText: {
      text: 'Можно пропустить этот шаг',
      position: EHelpTextPosition.Above,
    },
    actions: [{ type: EQuestionActionsTypes.NextQ, text: 'Далее' }],
  },
  nextStep: [
    {
      conditions: [],
      nextStepCode: NEWS_SUBSCRIPTION_STEP.code,
    },
  ],
};
