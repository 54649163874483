import { directionsModalOpenSaga } from '../../filters/state/directions';
import { districtSwitcherOpenSaga } from '../../filters/state/districts/popup';
import {
  locationSwitcherAutocompleteSaga,
  locationSwitcherCitySelectionSaga,
  locationSwitcherOpenSaga,
  locationSwitcherRegionSelectionSaga,
} from '../../filters/state/location_switcher';
import { metaSaga } from '../../filters/state/meta';
import { searchSaga } from '../../filters/state/search';
import { hideOfferSaga } from '../../serp/state/hide_offer';
import { iapRequestPopupSagas } from '../../serp/state/iapRequestPopup';
import { agentRatingSaga } from '../../serp/state/offer_card/agent';
import { offerCommentsSaga } from '../../serp/state/offer_card/comments';
import { suggestionsDistacesSaga } from '../../serp/state/suggestions';

import { IAppStore } from './store';

export function attachSagas(store: IAppStore) {
  store.runSaga(metaSaga);
  store.runSaga(locationSwitcherOpenSaga);
  store.runSaga(locationSwitcherRegionSelectionSaga);
  store.runSaga(locationSwitcherCitySelectionSaga);
  store.runSaga(locationSwitcherAutocompleteSaga);
  store.runSaga(districtSwitcherOpenSaga);
  store.runSaga(directionsModalOpenSaga);
  store.runSaga(searchSaga);
  store.runSaga(offerCommentsSaga);
  store.runSaga(suggestionsDistacesSaga);
  store.runSaga(agentRatingSaga);
  store.runSaga(hideOfferSaga);
  store.runSaga(iapRequestPopupSagas);
}
