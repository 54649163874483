import { ICommercialSpecialty } from '../../../api/commercial_specialties';
import { Dispatch, IAppState, TActions } from '../../../common/state';

import { pushTermsValue, removeTermsValue } from './terms';

export interface ICommercialSpecialtyTypeMetaChanged {
  type: 'filters/advanced/SPECIALTY_TYPE_META_CHANGED';
  commercialSpecialties: ICommercialSpecialty[];
}

export function addSpecialtyTypes(value: number) {
  return (dispatch: Dispatch) => {
    dispatch(pushTermsValue('specialty_types')(value));
  };
}

export function removeSpecialtyTypes(value: number) {
  return (dispatch: Dispatch) => {
    dispatch(removeTermsValue('specialty_types')(value));
  };
}

export function addCommercialSpecialtyTypeMeta(item: ICommercialSpecialty) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const { commercialSpecialties } = state.filters.tagsData;

    if (item.id && commercialSpecialties.every(({ id }) => id !== item.id)) {
      dispatch({
        type: 'filters/advanced/SPECIALTY_TYPE_META_CHANGED',
        commercialSpecialties: commercialSpecialties.concat([item]),
      });
    }
  };
}

export function commercialSpecialtyTypesReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/advanced/SPECIALTY_TYPE_META_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          tagsData: {
            ...state.filters.tagsData,
            commercialSpecialties: action.commercialSpecialties,
          },
        },
      };

    default:
      return state;
  }
}
