import * as React from 'react';

import * as styles from './TGSimplifieldBannerWrapper.css';

interface ITGSimplifieldBannerWrapperProps {
  children: React.ReactNode;
}

export function TGSimplifieldBannerWrapper({ children }: ITGSimplifieldBannerWrapperProps) {
  return <div className={styles['simplified-banner']}>{children}</div>;
}
