import { EQuestionActionsTypes, IStep } from '@cian/frontend-request-form-widget';

import { number, object, ref, string } from 'yup';

import { ECoworkingOfferType } from 'shared/json_query';

import { ExtendedFieldType } from '../extendedFieldType';

import { DEAL_DATE_STEP } from './dealDate';

export const COWORKING_PAGE: IStep = {
  code: 'coworkingPage',
  question: {
    code: 'coworkingQuestion',
    title: 'Укажите параметры объекта',
    fields: [
      {
        type: ExtendedFieldType.MultiselectWithoutEmptyChoice,
        name: 'coworkingObjectType',
        label: 'Тип аренды',
        answers: [
          {
            value: String(ECoworkingOfferType.Office),
            label: 'Отдельный офис',
          },
          {
            value: String(ECoworkingOfferType.FlexibleWorkplace),
            label: 'Незакреплённое рабочее место',
          },
          {
            value: String(ECoworkingOfferType.FixedWorkplace),
            label: 'Закреплённое рабочее место',
          },
        ],
      },
      {
        type: ExtendedFieldType.GeoSuggest,
        name: 'geo',
        label: 'Местоположение',
        placeholder: 'Город, адрес, метро, район или шоссе',
      },
      {
        type: ExtendedFieldType.Integer,
        name: 'workplaceCountFrom',
        label: 'Кол-во рабочих мест',
        prefix: 'от',
        size: {
          xs: 12,
          m: 6,
        },
      },
      {
        type: ExtendedFieldType.Integer,
        name: 'workplaceCountTo',
        label: '\u00A0',
        prefix: 'до',
        size: {
          xs: 12,
          m: 6,
        },
      },
      {
        type: ExtendedFieldType.Price,
        name: 'priceFrom',
        unit: '₽',
        prefix: 'от',
        label: 'Цена',
        size: {
          xs: 12,
          m: 6,
        },
      },
      {
        type: ExtendedFieldType.Price,
        name: 'priceTo',
        unit: '₽',
        prefix: 'до',
        label: '\u00A0',
        size: {
          xs: 12,
          m: 6,
        },
      },
    ],
    actions: [{ type: EQuestionActionsTypes.NextQ, text: 'Далее' }],
    validation: object().shape(
      {
        geo: string().when(['workplaceCountFrom', 'workplaceCountTo', 'priceFrom', 'priceTo'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: string().required('Укажите значение поля'),
          otherwise: string(),
        }),
        workplaceCountFrom: number().when(['geo', 'workplaceCountTo', 'priceFrom', 'priceTo'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: number().required('Укажите значение поля'),
          otherwise: number(),
        }),
        workplaceCountTo: number()
          .when(['geo', 'workplaceCountFrom', 'priceFrom', 'priceTo'], {
            is: (...fields: unknown[]) => fields.every(field => field == null),
            then: number().required('Укажите значение поля'),
          })
          .when('workplaceCountFrom', {
            is: (workplaceCountFrom: number) => workplaceCountFrom != null,
            then: number().min(ref('workplaceCountFrom'), 'Некорректно указано значение'),
          }),
        priceFrom: number().when(['geo', 'workplaceCountFrom', 'workplaceCountTo', 'priceTo'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: number().required('Укажите значение поля'),
          otherwise: number(),
        }),
        priceTo: number()
          .when(['geo', 'workplaceCountFrom', 'workplaceCountTo', 'priceFrom'], {
            is: (...fields: unknown[]) => fields.every(field => field == null),
            then: number().required('Укажите значение поля'),
            otherwise: number(),
          })
          .when('priceFrom', {
            is: (priceFrom: number) => priceFrom != null,
            then: number().min(ref('priceFrom'), 'Некорректно указано значение'),
          }),
      },
      [
        ['geo', 'workplaceCountFrom'],
        ['geo', 'workplaceCountTo'],
        ['geo', 'priceFrom'],
        ['geo', 'priceTo'],
        ['workplaceCountFrom', 'workplaceCountTo'],
        ['priceFrom', 'priceTo'],
        ['priceFrom', 'workplaceCountFrom'],
        ['priceFrom', 'workplaceCountTo'],
        ['workplaceCountTo', 'priceTo'],
        ['workplaceCountFrom', 'priceTo'],
      ],
    ),
  },
  nextStep: [
    {
      conditions: [],
      nextStepCode: DEAL_DATE_STEP.code,
    },
  ],
};
