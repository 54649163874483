import { isBusinessSale, isCommercial, isSuburban, offerTypeFromJsonQuery } from '../../../../utils/category';
import { isGeoAvailable, moscowMoIds } from '../../../../utils/geo';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const TAG_TYPE = 'totalArea';

export interface ITotalAreaTag {
  type: 'totalArea';
  min: number | undefined;
  max: number | undefined;
}

function isTotalAreaTag(tag: TTag): tag is ITotalAreaTag {
  return tag.type === TAG_TYPE;
}

export const totalAreaTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData, currentLocation) => {
    const value = jsonQuery.total_area && jsonQuery.total_area.value;
    const offerType = offerTypeFromJsonQuery(jsonQuery);

    const isNewReadyBusinessSaleCategory = isBusinessSale(jsonQuery);

    if (
      value !== undefined &&
      (!isCommercial(offerType) || isNewReadyBusinessSaleCategory) &&
      (isGeoAvailable(moscowMoIds, currentLocation) || !isSuburban(offerType))
    ) {
      return ensureSingleTagExists(tags, isTotalAreaTag, () => ({ type: TAG_TYPE, min: value.gte, max: value.lte }));
    } else {
      return ensureTagNotExists(tags, isTotalAreaTag);
    }
  },
};
