import { IAppState, TActions } from '../../../common/state';
import { ECoworkingAccess } from '../../../json_query';
import { setTerm } from '../../../json_query/reducers/helpers';
import { COWORKING_ACCESS_TAG_TYPE } from '../tags/definitions/coworking_access';

export interface ICoworkingAccessChanged {
  type: 'filters/coworking_access/COWORKING_ACCESS_CHANGED';
  payload: { accessKey: ECoworkingAccess };
}

export function changeCoworkingAccess(accessKey: ECoworkingAccess): ICoworkingAccessChanged {
  return {
    type: 'filters/coworking_access/COWORKING_ACCESS_CHANGED',
    payload: { accessKey },
  };
}

export function coworkingAccessReducer(state: IAppState, action: TActions) {
  switch (action.type) {
    case 'filters/coworking_access/COWORKING_ACCESS_CHANGED': {
      const { accessKey } = action.payload;
      const nextValue = Number(!state.filters.jsonQuery[accessKey]?.value) || undefined;

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: setTerm(accessKey)(state.filters.jsonQuery)(nextValue),
        },
      };
    }

    case 'filters/tags/TAG_REMOVED': {
      if (action.tag.type !== COWORKING_ACCESS_TAG_TYPE) {
        return state;
      }

      const nextTagValue = action.tag.value;

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: setTerm(nextTagValue)(state.filters.jsonQuery)(undefined),
        },
      };
    }

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            all_day_access: undefined,
            all_week_access: undefined,
          },
        },
      };

    default:
      return state;
  }
}
