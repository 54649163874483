import * as React from 'react';

export const AreaPartsGalleryPlaceholder: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg fill="none" height="120" viewBox="0 0 120 120" width="120" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.1429 10.745C49.9115 5.27591 54.9682 1.46543 60.4373 2.23406L104.999 8.49685C110.468 9.26548 114.279 14.3222 113.51 19.7913L105.856 74.256C105.087 79.7251 100.03 83.5356 94.5614 82.767L86.698 81.6618L83.8691 61.0677L97.3448 62.9616L103.608 18.3995L59.0456 12.1367L57.601 22.4151L47.2814 23.99L49.1429 10.745Z"
      fill="#CED1D7"
    />
    <path
      clipRule="evenodd"
      d="M15.0003 38.4977C9.53116 39.2663 5.72068 44.323 6.48931 49.7921L14.1438 104.257C14.9125 109.726 19.9691 113.536 25.4382 112.768L70.0003 106.505C75.4694 105.736 79.2799 100.68 78.5113 95.2106L70.8567 40.7458C70.0881 35.2767 65.0314 31.4663 59.5623 32.2349L15.0003 38.4977ZM23.4309 62.5585C26.1655 62.1742 28.0707 59.6459 27.6864 56.9113C27.3021 54.1768 24.7737 52.2715 22.0392 52.6558C19.3046 53.0401 17.3994 55.5685 17.7837 58.303C18.168 61.0376 20.6964 62.9428 23.4309 62.5585ZM63.7373 61.943L67.9125 91.6508L23.3506 97.9135L37.5561 77.7402L47.1642 85.4784L63.7373 61.943Z"
      fill="#CED1D7"
      fillRule="evenodd"
    />
  </svg>
);
