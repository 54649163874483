import { IAppState, TActions } from '../../common/state';
import { EJsonQueryPriceType, jq } from '../../json_query';
import { FOfferType } from '../../utils/category';

import { OFFER_TYPE_SELECTED } from './offer_type';

export interface IJsonQueryPriceTypeChangedAction {
  payload: EJsonQueryPriceType | undefined;
  type: 'filters/priceType/JSON_QUERY_PRICE_TYPE_CHANGED';
}

export function setJsonQueryPriceType(payload: EJsonQueryPriceType | undefined): IJsonQueryPriceTypeChangedAction {
  return {
    payload,
    type: 'filters/priceType/JSON_QUERY_PRICE_TYPE_CHANGED',
  };
}

export function jsonQueryPriceTypeReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/priceType/JSON_QUERY_PRICE_TYPE_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setJsonQueryPriceType(action.payload),
        },
      };

    case OFFER_TYPE_SELECTED: {
      const offerType = action.offerType;

      if (offerType === FOfferType.Coworking) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            price_type: undefined,
          },
        },
      };
    }

    default:
      return state;
  }
}
