export const IAP_POPUP_REQUEST_OPENED = 'modals/iap_request_popup/MODAL_OPENED';
export interface IIapRequestPopupOpened {
  type: typeof IAP_POPUP_REQUEST_OPENED;
}
export const openIapRequestPopup = (): IIapRequestPopupOpened => ({ type: IAP_POPUP_REQUEST_OPENED });

export const IAP_POPUP_REQUEST_CLOSED = 'modals/iap_request_popup/MODAL_CLOSED';
export interface IIapRequestPopupClosed {
  type: typeof IAP_POPUP_REQUEST_CLOSED;
}
export const closedIapRequestPopup = (): IIapRequestPopupClosed => ({
  type: IAP_POPUP_REQUEST_CLOSED,
});

export type TIapRequestPopupActions = IIapRequestPopupOpened | IIapRequestPopupClosed;
