import { Spinner } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import { pathOr } from 'ramda';
import * as React from 'react';

import { getFormattedPhoneNumber } from 'shared/utils/format';

import { IPhone } from '../../../../types/offer';

import * as styles from './PhoneNumbers.css';

export interface IPhoneNumbersProps {
  phones: IPhone[];
  isSimplified: boolean;
  warningMessage: React.ReactNode;
  workTime?: React.ReactNode;
}

export function PhoneNumbers({ phones, isSimplified, warningMessage, workTime }: IPhoneNumbersProps) {
  return (
    <div
      {...mergeStyles([
        styles['text'],
        isSimplified && styles['simplified-text'],
        phones.length > 1 && styles['multiple-phones'],
      ])}
    >
      {phones.map((phone, index, array) => {
        return (
          <React.Fragment key={index}>
            <PhoneRenderer phone={phone} />
            {array.length - 1 !== index && <br />}
          </React.Fragment>
        );
      })}
      {workTime}
      {warningMessage}
    </div>
  );
}

export interface IPhoneRendererProps {
  phone: IPhone;
}

export function PhoneRenderer({ phone }: IPhoneRendererProps) {
  return pathOr(null, ['number'], phone) ? <FormattedPhone phone={phone} /> : <PhonePreloader />;
}

export interface IFormattedPhoneProps {
  phone: IPhone;
}

export function FormattedPhone({ phone }: IFormattedPhoneProps) {
  return <span>{getFormattedPhoneNumber(phone)}</span>;
}

export function PhonePreloader() {
  return (
    <span className={styles['preloader']}>
      <Spinner size={16} />
    </span>
  );
}
