import { IAppState, TActions } from '../../../common/state';
import { jq } from '../../../json_query';

export const IMinFromMKADChangedActionType = 'filters/from_mkad/MIN_DISTANCE_CHANGED';
export const IMaxFromMKADChangedActionType = 'filters/from_mkad/MAX_DISTANCE_CHANGED';

export interface IMinFromMKADChangedAction {
  type: 'filters/from_mkad/MIN_DISTANCE_CHANGED';
  value?: number;
}

export interface IMaxFromMKADChangedAction {
  type: 'filters/from_mkad/MAX_DISTANCE_CHANGED';
  value?: number;
}

export function changeMinFromMKAD(value: number | undefined): IMinFromMKADChangedAction {
  return {
    type: IMinFromMKADChangedActionType,
    value,
  };
}

export function changeMaxFromMKAD(value: number | undefined): IMaxFromMKADChangedAction {
  return {
    type: IMaxFromMKADChangedActionType,
    value,
  };
}

export function fromMKADReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IMinFromMKADChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setMinFromMKAD(action.value),
        },
      };

    case IMaxFromMKADChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setMaxFromMKAD(action.value),
        },
      };

    default:
      return state;
  }
}
