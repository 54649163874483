import { IFavoritesAssignWidgetState, favoritesAssignWidgetReducer } from '@cian/favorites-assign-widget';

import { IAppState, TActions } from '../../common/state';

/**
 * Этот редьюсер нужен для проксирования данных в настоящий редьюсер виджета добавления в избранное.
 * Виджет расчитан на то, что store состоит из множетсва редьюсеров и подключает туда свой, но сейчас серп
 * это 1 гигантский редьюсер.
 *
 * @param state
 * @param action
 */
export function favoritesAssignReducer(state: IAppState, action: TActions): IAppState {
  const widgetState = {
    ...state.favoritesAssignWidget,
  } as IFavoritesAssignWidgetState;

  const newState = favoritesAssignWidgetReducer(widgetState, action);

  return {
    ...state,
    favoritesAssignWidget: newState,
  };
}
