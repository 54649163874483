import { ca } from '@cian/analytics';

export function trackHidePrintButtonClick() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'moreMenu_hide_from_print',
    label: 'from_newlist',
  });
}
