import { Dispatch, IAppState, TActions } from '../../../common/state';
import {
  EHouseLineType,
  EPlacementType,
  IJsonQuery,
  IJsonQueryTerms,
  IJsonQueryTermsParams,
} from '../../../json_query';
import { getTermsValue, setTerms } from '../../../json_query/reducers/helpers';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from '../../../utils/category';
import { getIsSaleBusinessOffer } from '../../../utils/filters/getIsSaleBusinessOffer';
import { unique } from '../../../utils/normalize';

export interface IAdvancedTermsChanged {
  type: 'filters/advanced/TERMS_CHANGED';
  key: keyof IJsonQuery;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  termsValue: any;
}

export function pushTermsValue<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerms<T>>(key: K) {
  return (value: T) => (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    let termsValue = getTermsValue<T, K, V>(key)(state.filters.jsonQuery);
    termsValue = termsValue.concat([value]);
    termsValue = unique<T>(termsValue);

    dispatch({
      type: 'filters/advanced/TERMS_CHANGED',
      key,
      termsValue,
    });
  };
}

export function removeTermsValue<T, K extends keyof IJsonQuery, V extends IJsonQuery[K] & IJsonQueryTerms<T>>(key: K) {
  return (value: T) => (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    let termsValue = getTermsValue<T, K, V>(key)(state.filters.jsonQuery);

    termsValue = termsValue.filter(item => item !== value);

    dispatch({
      type: 'filters/advanced/TERMS_CHANGED',
      key,
      termsValue,
    });
  };
}

interface IMultiSelectTag {
  type: string;
  value: EHouseLineType | EPlacementType;
}

export type TAllowedKeys =
  | 'contract'
  | 'specialty_types'
  | 'estate_type'
  | 'placement_type'
  | 'building_house_line_type__shopping_area'
  | 'building_house_line_type';

function removeInfoAboutTagFromJsonQuery(key: TAllowedKeys, tag: IMultiSelectTag, jsonQuery: IJsonQuery): IJsonQuery {
  const termsValue = getTermsValue(key)(jsonQuery).filter(item => item !== tag.value);

  return setTerms(key)(jsonQuery)(termsValue);
}

export function getMultiSelectJsonQueryKeys(jsonQuery: IJsonQuery): Array<TAllowedKeys> {
  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  const multiSelectJsonQueryKeys = Array.of<TAllowedKeys>(
    'contract',
    'estate_type',
    'placement_type',
    'building_house_line_type__shopping_area',
    'building_house_line_type',
  );

  if (!getIsSaleBusinessOffer(dealType, offerType)) {
    multiSelectJsonQueryKeys.push('specialty_types');
  }

  return multiSelectJsonQueryKeys;
}

export function advancedTermsReducer(state: IAppState, action: TActions): IAppState {
  let jsonQuery = state.filters.jsonQuery;

  const multiSelectJsonQueryKeys = getMultiSelectJsonQueryKeys(jsonQuery);

  switch (action.type) {
    case 'filters/advanced/TERMS_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: setTerms(action.key)(jsonQuery)(action.termsValue),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      multiSelectJsonQueryKeys.forEach(item => {
        if (
          [
            'advanced-contract-type',
            'advanced-commercial-specialty',
            'advanced-estate-type',
            'advanced-placement-type',
            'advanced-house-line-building',
            'advanced-house-line-shopping-area',
          ].includes(action.tag.type)
        ) {
          jsonQuery = removeInfoAboutTagFromJsonQuery(item, action.tag as IMultiSelectTag, jsonQuery);
        }
      });

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery,
        },
      };

    case 'filters/tags/ALL_TAGS_REMOVED':
      multiSelectJsonQueryKeys.forEach((jsonQueryKey: keyof IJsonQueryTermsParams) => {
        jsonQuery = setTerms(jsonQueryKey)(jsonQuery)([]);
      });

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery,
        },
      };

    default:
      return state;
  }
}
