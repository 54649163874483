import { IAppState, TActions } from '../../../common/state';
import { EWindowsType } from '../../../json_query';

export const IWindowsTypeChangedActionType = 'filters/windows/WINDOWS_TYPE_CHANGED';

export interface IWindowsTypeChangedAction {
  type: 'filters/windows/WINDOWS_TYPE_CHANGED';
  windowsType: EWindowsType;
}

export function changeWindowsType(windowsType: EWindowsType): IWindowsTypeChangedAction {
  return {
    type: IWindowsTypeChangedActionType,
    windowsType,
  };
}

export function windowsTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IWindowsTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            windows_type:
              action.windowsType !== EWindowsType.Any
                ? {
                    type: 'term',
                    value: action.windowsType,
                  }
                : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-windows') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              windows_type: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            windows_type: undefined,
          },
        },
      };

    default:
      return state;
  }
}
