import { IStep } from '@cian/frontend-request-form-widget';

import { ICommercialSpecialty } from 'shared/api/commercial_specialties';

import { USER_PROFILING_STEPS_SCHEMA } from '../constants';

import { getBusinessPageSchema } from './getBusinessPageSchema';

interface IGetFormStepsSchemaParams {
  specialties: ICommercialSpecialty[];
}

export type TFormSchema = { [key in keyof typeof USER_PROFILING_STEPS_SCHEMA]: IStep };

export const getFormStepsSchema = (params: IGetFormStepsSchemaParams): TFormSchema => ({
  ...USER_PROFILING_STEPS_SCHEMA,
  businessPage: getBusinessPageSchema(params.specialties),
});
