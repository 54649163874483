import { IAppState, TActions } from '../../../common/state';

export const IPhoneChangedActionType = 'filters/phone/CONTACT_CHANGED';

export interface IPhoneChangedAction {
  type: 'filters/phone/CONTACT_CHANGED';
  phone: string | undefined;
}

export function changePhone(phone: string | undefined): IPhoneChangedAction {
  return {
    type: IPhoneChangedActionType,
    phone,
  };
}

export function phoneReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IPhoneChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            contact: action.phone
              ? {
                  type: 'term',
                  value: action.phone,
                }
              : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-phone') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              contact: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            contact: undefined,
          },
        },
      };

    default:
      return state;
  }
}
