import { ensureSingleTagExists, ensureTagNotExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

export const TAG_TYPE = 'geo-bbox';

export interface IGeoBboxTag {
  type: 'geo-bbox';
  text: string;
}

const isBboxTag = (tag: TTag): tag is IGeoBboxTag => tag.type === TAG_TYPE;

export const geoBboxTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.bbox && jsonQuery.bbox.value;

    if (value) {
      return ensureSingleTagExists(tags, isBboxTag, () => ({ type: 'geo-bbox', text: 'Область поиска' }));
    } else {
      return ensureTagNotExists(tags, isBboxTag);
    }
  },
};
