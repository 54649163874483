import { LinkButton } from '@cian/ui-kit/button';
import { IconMap16 } from '@cian/ui-kit/icons';

import { compose } from 'ramda';
import { connect } from 'react-redux';

import { IAppState } from 'shared/common/state';

import { isRedesignExperimentEnabled } from '../../../../selectors/isRedesignExperimentEnabled';
import { withEmptyRendering } from '../../../../utils/hoc/withEmptyRendering';
import { SummaryButtonWrapper } from '../../../components/Summary';
import { getOffersCount } from '../utils/selectors';
import { trackMapViewButtonClicked } from '../utils/tracking';

export interface IMapViewButtonProps {
  href: string;
  mapViewAllowed: boolean;
  isRedesignEnabled: boolean;
}

export function MapViewButton({ href, isRedesignEnabled }: IMapViewButtonProps) {
  return (
    <SummaryButtonWrapper>
      <LinkButton
        beforeIcon={<IconMap16 color="primary_100" />}
        href={href}
        size="XS"
        target="_blank"
        theme={isRedesignEnabled ? 'fill_white_primary' : 'fill_secondary'}
        onClick={trackMapViewButtonClicked}
      >
        На карте
      </LinkButton>
    </SummaryButtonWrapper>
  );
}

export function mapStateToProps(state: IAppState): IMapViewButtonProps {
  const {
    results: { queryString },
  } = state;
  const offerCount = getOffersCount(state);

  return {
    href: `/map/${queryString ? `?${queryString}` : ''}`,
    mapViewAllowed: offerCount > 0,
    isRedesignEnabled: isRedesignExperimentEnabled(state),
  };
}

export function shouldHideContainer(props: IMapViewButtonProps) {
  return !props.mapViewAllowed;
}

export const SummaryMapViewButtonContainer = compose(
  connect(mapStateToProps),
  withEmptyRendering(shouldHideContainer),
)(MapViewButton);
