import { Header5 } from '@cian/ui-kit/typography';
import { mergeStyles } from '@cian/utils';

import { ICoworking } from '../../types/offer';

import { Amenity } from './components/Amenity';
import { LinkToDetails } from './components/LinkToDetails';

const styles = require('./CoworkingAmenities.css');

interface ICoworkingAmenities extends Pick<ICoworking, 'includedAmenities' | 'moreAmenitiesCount'> {
  className?: string;
  url: string;
}

export function CoworkingAmenities({ includedAmenities, moreAmenitiesCount, className, url }: ICoworkingAmenities) {
  if (!includedAmenities?.length) {
    return null;
  }

  return (
    <div {...mergeStyles(styles['root'], className)}>
      <Header5 as="h5">Включено в стоимость аренды:</Header5>

      <div className={styles['amenities-list']}>
        {includedAmenities.map(({ key, title }) => (
          <Amenity className={styles['amenity-item']} key={key}>
            {title}
          </Amenity>
        ))}

        {!!moreAmenitiesCount && <LinkToDetails count={moreAmenitiesCount} url={url} />}
      </div>
    </div>
  );
}
