import { range } from 'ramda';

const MAX_OFFERS = 1500;
export const PAGINATION_WIDTH = 7;

export function getTotalPagesCount(perPage: number, aggregatedOffers: number): number {
  return aggregatedOffers <= MAX_OFFERS ? Math.ceil(aggregatedOffers / perPage) : Math.ceil(MAX_OFFERS / perPage);
}

export function getStartPage(currentPageNumber: number, totalPages: number): number {
  let startPage = currentPageNumber - PAGINATION_WIDTH;

  if (startPage > totalPages) {
    startPage = totalPages - PAGINATION_WIDTH;
  }

  if (startPage < 1) {
    startPage = 1;
  }

  return startPage;
}

export function getEndPage(currentPageNumber: number, totalPages: number): number {
  let endPage = currentPageNumber + PAGINATION_WIDTH;

  if (endPage > totalPages) {
    endPage = totalPages;
  }

  return endPage;
}

export function getPageList(currentPageNumber: number, totalPages: number): number[] {
  const startPage = getStartPage(currentPageNumber, totalPages);
  const endPage = getEndPage(currentPageNumber, totalPages);
  const pageList = range(startPage, endPage + 1);

  if (startPage > 1) {
    if (startPage > 2) {
      pageList.unshift(startPage - 1);
    }

    pageList.unshift(1);
  }

  if (endPage < totalPages) {
    pageList.push(endPage + 1);
  }

  return pageList;
}
