import { IOffer, IOfferAnalyticsInfo } from './types';

const parseCategory = (offer: IOffer): string => {
  const { dealType, offerType, category } = offer;
  const isNewBuilding = category && category.toLowerCase().includes('newbuilding');

  const getDealType = (): string => {
    if (offerType !== 'flat' || dealType !== 'rent' || !offer.category) {
      return dealType as string;
    }

    return offer.category.toLowerCase().includes('daily') ? 'rent_day' : 'rent_long';
  };

  const getOfferType = (): string => {
    if (offerType === 'flat') {
      return isNewBuilding ? 'flat_new' : 'flat_old';
    }

    return offerType as string;
  };

  return `${getDealType()}/${getOfferType()}`;
};

const parseBrand = (offer: IOffer): string | undefined => {
  const { user, cianUserId, fromDeveloper, isPro } = offer;

  const getAutorType = (): string | undefined => {
    if (fromDeveloper) {
      return 'developer';
    }
    if (isPro) {
      return 'realtor_prof';
    }
    if (user && !user.isAgent) {
      return 'physical';
    }

    return undefined;
  };

  const getRealtorAccountType = (): string | undefined => {
    if (user && user.isAgent) {
      return user.isHidden ? 'privat' : 'public';
    }

    return undefined;
  };

  return [cianUserId, getAutorType(), getRealtorAccountType()].filter(Boolean).join('/') || undefined;
};

const parseVariant = (offer: IOffer): string | undefined => {
  /**
   * Не добавлен в api models, т.к. эксперимент и потом сложно будет выпиливать
   */
  //todo выпилить, или внести в api-models
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { isAuction, isPaid, isPremium, isTop3, isColorized, isSlonoTop } = offer as any;
  const isFree = !isPaid && !isPremium && !isTop3;

  const params = [
    isAuction && 'auction',
    isPremium && 'premium',
    isTop3 && 'top3',
    isPaid && 'paid',
    isFree && 'free',
    isSlonoTop && 'slonotop',
    isColorized && !isTop3 && 'color',
  ]
    .filter(Boolean)
    .join('/');

  return params || undefined;
};

const parsePrice = (offer: IOffer): number | undefined => {
  if (offer.bargainTerms && offer.bargainTerms.priceRur) {
    return offer.bargainTerms.priceRur;
  }

  return undefined;
};

export const gatherAnalyticsInfo = (offer: IOffer): IOfferAnalyticsInfo => {
  const newState: IOfferAnalyticsInfo = {
    brand: parseBrand(offer),
    category: parseCategory(offer),
    id: offer.id,
    price: parsePrice(offer),
    quantity: 1,
    variant: parseVariant(offer),
    ownerId: offer.userId ? Number(offer.userId) : undefined,
    top3Place: offer.top3Place || undefined,
  };

  return newState;
};
