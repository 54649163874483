import { EFieldSubType, EHelpTextPosition, IStep } from '@cian/frontend-request-form-widget';

import { ExtendedFieldType } from '../extendedFieldType';

import { REGISTRATION_STEPS } from './registration';

export const NEWS_SUBSCRIPTION_STEP: IStep = {
  code: 'newsSubscription',
  question: {
    code: 'newsSubscriptionQuestion',
    title: 'Хотите получать предложения от агентов и собственников?',
    fields: [
      {
        name: 'needNewsSubscription',
        type: ExtendedFieldType.OneChoice,
        subType: EFieldSubType.Icon,
        answers: [
          {
            label: 'Да, хочу',
            value: 'yes',
          },
          {
            label: 'Нет, буду искать сам',
            value: 'no',
          },
        ],
      },
    ],
    helpText: {
      text: 'Никакого спама, только подходящие вам объекты',
      position: EHelpTextPosition.Above,
    },
  },
  nextStep: [
    {
      conditions: [],
      nextStepCode: REGISTRATION_STEPS.code,
    },
  ],
};
