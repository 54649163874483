import { TDelayedMarkSavedSearchAsReadDesktopModel } from './types';

const defaultConfig: TDelayedMarkSavedSearchAsReadDesktopModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'savedSearches',
  pathApi: '/v1/delayed-mark-saved-search-as-read-desktop/',
};

export function createDelayedMarkSavedSearchAsReadestopModel(
  config: Partial<TDelayedMarkSavedSearchAsReadDesktopModel>,
): TDelayedMarkSavedSearchAsReadDesktopModel {
  return {
    ...defaultConfig,
    ...config,
  };
}
