import { connect } from 'react-redux';

import { IAppState } from '../../../common/state';

import { FastLinks } from './index';

export const mapStateToProps = (state: IAppState) => {
  const {
    config,
    results: { fastLinks, aggregatedOffers, jsonQuery },
  } = state;

  return {
    fastLinks,
    aggregatedOffers,
    jsonQuery,
    offersPerPage: Number(config.get('serp.offersPerPage')),
  };
};

export const FastLinksContainer = connect(mapStateToProps)(FastLinks);
