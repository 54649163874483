import { ReactNode, ReactNodeArray } from 'react';

interface IData {
  elements: ReactNodeArray;
  countInGroup: number;
}

/**
 * @description Схема разделения элементов на группы:
 *    Входные данные:
 *    countInGroup = 3;
 *    [элемент 0, элемент 1, элемент 2, элемент 3]
 *
 *    Выходные данные:
 *    [|0| элемент 0, элемент 1, элемент 2 |0||1| элемент 3, элемент-заглушка 4, элемент-заглушка 5 |1|]
 *
 *    где, |N| - номер группы;
 *    элемент-заглушка 4 - клон элемента 1;
 *    элемент-заглушка 5 - клон элемента 2;
 *    элементы-заглушки не попадают во viewport зону.
 *
 * P.S. В случае, если в качестве элементов-заглушек потребуются определенные элементы из списка,
 * возможно расширение данной функции таким образом, чтобы она принимала дополнительный параметр -
 * массив индексов элементов-заглушек: stubElementsIndexes: number[].
 */
export const groupElements = ({ elements, countInGroup }: IData): ReactNode[][] => {
  const groups: ReactNode[][] = [];

  elements.forEach((element, index) => {
    const groupIndex = Math.floor(index / countInGroup);

    if (Array.isArray(groups[groupIndex])) {
      groups[groupIndex].push(element);

      return;
    }

    groups[groupIndex] = [element];
  });

  const lastGroup = groups[groups.length - 1];

  if (lastGroup.length < countInGroup) {
    const stubElements = elements.slice(lastGroup.length, countInGroup);
    lastGroup.push(...stubElements);
  }

  return groups;
};
