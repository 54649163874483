import { ca } from '@cian/analytics';

interface ITrackPopupLimit {
  offerId: number;
}

export function trackPopupLimit({ offerId }: ITrackPopupLimit) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Compare',
    action: 'show_popup_limit',
    products: [
      {
        id: offerId,
        offerType: 'offer',
      },
    ],
  });
}
