import { EBuildingType, EPropertyType } from '../json_query';

/* Тип здания */
export const BuildingTypeName = new Map<EBuildingType, string>([
  [EBuildingType.BusinessCenter, 'Бизнес-центр'],
  [EBuildingType.ShoppingEntertainmentCenter, 'Торгово-развлекательный центр'],
  [EBuildingType.OldFund, 'Старый фонд'],
  [EBuildingType.OfficeBuilding, 'Офисное здание'],
  [EBuildingType.BusinessPark, 'Бизнес-парк'],
  [EBuildingType.OfficeIndustrialComplex, 'Офисно-производственный комплекс'],
  [EBuildingType.OfficeWarehouseComplex, 'Офисно-складской комплекс'],
  [EBuildingType.WarehouseComplex, 'Складской комплекс'],
  [EBuildingType.IndustrialWarehouseComplex, 'Производственно-складской комплекс'],
  [EBuildingType.LogisticsCenter, 'Логистический центр'],
  [EBuildingType.Warehouse, 'Склад'],
  [EBuildingType.AdministrativeBuilding, 'Административное здание'],
  [EBuildingType.IndustrialPark, 'Индустриальный парк'],
  [EBuildingType.LogisticsComplex, 'Логистический комплекс'],
  [EBuildingType.ShoppingBusinessComplex, 'Торгово-деловой комплекс'],
  [EBuildingType.MultifunctionalComplex, 'Многофункциональный комплекс'],
  [EBuildingType.OfficeResidentialComplex, 'Офисно-жилой комплекс'],
  [EBuildingType.TradingOfficeComplex, 'Торгово-офисный комплекс'],
  [EBuildingType.OfficeHotelComplex, 'Офисно-гостиничный комплекс'],
  [EBuildingType.BusinessCenter2, 'Деловой центр'],
  [EBuildingType.Mansion, 'Особняк'],
  [EBuildingType.Technopark, 'Технопарк'],
  [EBuildingType.BusinessQuarter, 'Бизнес-квартал'],
  [EBuildingType.StandaloneBuilding, 'Отдельно стоящее здание'],
  [EBuildingType.ResidentialHouse, 'Жилой дом'],
  [EBuildingType.ResidentialComplex, 'Жилой комплекс'],
  [EBuildingType.ShoppingCenter, 'Торговый центр'],
  [EBuildingType.SpecializedShoppingCenter, 'Специализированный торговый центр'],
  [EBuildingType.ShoppingCommunityCenter, 'Торгово-общественный центр'],
  [EBuildingType.Outlet, 'Аутлет'],
  [EBuildingType.IndustrialComplex, 'Производственный комплекс'],
  [EBuildingType.IndustrialSite, 'Промплощадка'],
  [EBuildingType.ManufacturingFacility, 'Производственный цех'],
  [EBuildingType.FreeAppointmentObject, 'Объект свободного назначения'],
  [EBuildingType.PropertyComplex, 'Имущественный комплекс'],
  [EBuildingType.ManufactureBuilding, 'Производственное здание'],
  [EBuildingType.ModularBuilding, 'Модульное здание'],
  [EBuildingType.FreeBuilding, 'Свободное здание'],
  [EBuildingType.OfficeWarehouse, 'Офисно-складское здание'],
  [EBuildingType.OfficeCenter, 'Офисный центр'],
  [EBuildingType.OfficeQuarter, 'Офисный квартал'],
  [EBuildingType.BusinessQuarter2, 'Деловой квартал'],
  [EBuildingType.BusinessHouse, 'Деловой дом'],
  [EBuildingType.TradingHouse, 'Торговый дом'],
  [EBuildingType.Other, 'Другое'],
]);
export const BuildingTypeBackend = new Map<string, EBuildingType>([
  ['administrativeBuilding', EBuildingType.AdministrativeBuilding],
  ['businessCenter', EBuildingType.BusinessCenter],
  ['businessCenter2', EBuildingType.BusinessCenter2],
  ['businessHouse', EBuildingType.BusinessHouse],
  ['businessPark', EBuildingType.BusinessPark],
  ['businessQuarter', EBuildingType.BusinessQuarter],
  ['businessQuarter2', EBuildingType.BusinessQuarter2],
  ['free', EBuildingType.FreeBuilding],
  ['freeAppointmentObject', EBuildingType.FreeAppointmentObject],
  ['industrialComplex', EBuildingType.IndustrialComplex],
  ['industrialPark', EBuildingType.IndustrialPark],
  ['industrialSite', EBuildingType.IndustrialSite],
  ['industrialWarehouseComplex', EBuildingType.IndustrialWarehouseComplex],
  ['logisticsCenter', EBuildingType.LogisticsCenter],
  ['logisticsComplex', EBuildingType.LogisticsComplex],
  ['mansion', EBuildingType.Mansion],
  ['manufactureBuilding', EBuildingType.ManufactureBuilding],
  ['manufacturingFacility', EBuildingType.ManufacturingFacility],
  ['modular', EBuildingType.ModularBuilding],
  ['multifunctionalComplex', EBuildingType.MultifunctionalComplex],
  ['officeAndHotelComplex', EBuildingType.OfficeHotelComplex],
  ['officeAndResidentialComplex', EBuildingType.OfficeResidentialComplex],
  ['officeAndWarehouse', EBuildingType.OfficeWarehouse],
  ['officeAndWarehouseComplex', EBuildingType.OfficeWarehouseComplex],
  ['officeBuilding', EBuildingType.OfficeBuilding],
  ['officeCenter', EBuildingType.OfficeCenter],
  ['officeIndustrialComplex', EBuildingType.OfficeIndustrialComplex],
  ['officeQuarter', EBuildingType.OfficeQuarter],
  ['old', EBuildingType.OldFund],
  ['outlet', EBuildingType.Outlet],
  ['propertyComplex', EBuildingType.PropertyComplex],
  ['residentialComplex', EBuildingType.ResidentialComplex],
  ['residentialHouse', EBuildingType.ResidentialHouse],
  ['shoppingAndBusinessComplex', EBuildingType.ShoppingBusinessComplex],
  ['shoppingAndCommunityCenter', EBuildingType.ShoppingCommunityCenter],
  ['shoppingAndEntertainmentCenter', EBuildingType.ShoppingEntertainmentCenter],
  ['shoppingCenter', EBuildingType.ShoppingCenter],
  ['specializedShoppingCenter', EBuildingType.SpecializedShoppingCenter],
  ['standaloneBuilding', EBuildingType.StandaloneBuilding],
  ['technopark', EBuildingType.Technopark],
  ['tradingHouse', EBuildingType.TradingHouse],
  ['tradingOfficeComplex', EBuildingType.TradingOfficeComplex],
  ['warehouse', EBuildingType.Warehouse],
  ['warehouseComplex', EBuildingType.WarehouseComplex],
]);

/* Класс здания */
export type TCommercialClass = 'aPlus' | 'a' | 'bPlus' | 'b' | 'bMinus' | 'cPlus' | 'c' | 'd';
export const CommercialClassName = new Map<TCommercialClass, string>([
  ['aPlus', 'A+'],
  ['a', 'A'],
  ['bPlus', 'B+'],
  ['b', 'B'],
  ['bMinus', 'B-'],
  ['cPlus', 'C+'],
  ['c', 'C'],
  ['d', 'D'],
]);

/* Тип недвижимости */
export const PropertyTypeName = new Map<EPropertyType, string>([
  [EPropertyType.Office, 'Офис'],
  [EPropertyType.Warehouse, 'Склад'],
  [EPropertyType.TradeArea, 'Торговая площадь'],
  [EPropertyType.Manufacture, 'Производство'],
  [EPropertyType.Building, 'Здание'],
  [EPropertyType.FreeAppointmentObject, 'Помещение свободного назначения'],
  [EPropertyType.Land, 'Земля'],
  [EPropertyType.Garage, 'Гараж'],
]);
