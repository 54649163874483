import * as R from 'ramda';
import { connect } from 'react-redux';

import { TAgentRating } from 'shared/api/agent';
import { Dispatch, IAppState } from 'shared/common/state';
import { changeFavorite } from 'shared/filters/state/favorite';
import { IKpOffersByCategoryCounter, IOffer } from 'shared/offer';
import { isRedesignExperimentEnabled } from 'shared/selectors/isRedesignExperimentEnabled';
import { isSuburban, offerTypeFromJsonQuery } from 'shared/utils/category';
import { prepareTrackingData } from 'shared/utils/prepareTrackingData';
import { isGoogleBot, isYandexBot } from 'shared/utils/user_agent';

import { IHidingOfferInfo, hideOfferAction } from '../../state/hide_offer';
import { hideMotivationPopup } from '../../state/login_motivation_popup';
import { updateAgentRating } from '../../state/offer_card/agent';
import { changeOfferComments } from '../../state/offer_card/comments';
import { addOfferComplaint } from '../../state/offer_card/complaints';
import {
  EFeedbackComplaint,
  IComplaintFeedbackBody,
  sendFeedbackComplaint,
  statusChanger,
} from '../../state/offer_card/feedback_complaint';
import {
  simplifiedOfferCardPopupCloseAction,
  simplifiedOfferCardPopupOpenAction,
} from '../../state/offer_card/simplified_card_popups';
import { addOfferToComparison } from '../../state/offersComparison/actions/addOfferToComparison';
import { deleteOfferFromComparison } from '../../state/offersComparison/actions/deleteOfferFromComparison';

import { IOffersDispatchProps, IOffersStateProps, Offers } from './index';

/* istanbul ignore next */
export const mapStateToProps = (state: IAppState): IOffersStateProps => {
  const {
    config,
    currentPath,
    currentSubdomain,
    logger,
    loginMotivationPopupOnFavorite,
    makeRequest,
    print,
    results,
    simplifiedOfferCardPopupsState,
    suggestionDistancesSeoText,
    userAgent,
    userGADataLayerData,
    user,
    showPushNotification,
    hideOffer,
    complaintFeedbackFrom,
    maxAuctionBet,
    maxAuctionService,
    deviceType,
    soprApi,
    villagesLink,
    isCountrysideTrapEnabled,
    isQaMode,
    excludedUtilitiesTermsRegions,
    featureToggle,
    filters,
    breadcrumbs,
    auctionBanks,
    abUseExperiments,
    villagePromotionLabel,
    knAuctionCompanies,
  } = state;

  const {
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    jsonQuery,
    kp,
    offers,
    qsToUris,
    ratingBlockedAgents,
    aggregatedOffers,
    extendedOffersCount,
  } = results;
  const emptyKpOffersCounter = (kp && kp.offers_by_category_counter) || {};
  const hasKpEmptyResults =
    Object.keys(emptyKpOffersCounter).filter((key: keyof IKpOffersByCategoryCounter) =>
      Number(emptyKpOffersCounter[key]),
    ).length > 0;
  const showCountrysideTrap = isCountrysideTrapEnabled && isSuburban(offerTypeFromJsonQuery(jsonQuery));
  const isOfferInSavedSearch = R.pathOr(undefined, ['results', 'jsonQuery', 'saved_search_id'], state);
  const isPopupsOpened = () => {
    const popupsStateKeys = Object.keys(simplifiedOfferCardPopupsState).map(Number);

    return popupsStateKeys.some(offerId => Boolean(simplifiedOfferCardPopupsState[offerId].length));
  };

  return {
    queryString: results.queryString,
    offersQty: results.totalOffers,
    baseUrl: config.get('apiBaseUrl') || '',
    breadcrumbs,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    currentPageNumber: jsonQuery.page ? jsonQuery.page.value : 1,
    currentPath,
    currentSubdomain,
    isBot: isGoogleBot(userAgent) || isYandexBot(userAgent),
    isPopupsOpened,
    isPrintEnabled: print.enabled,
    jsonQuery,
    logger,
    makeRequest,
    offers,
    hideOffer,
    offersPerPage: Number(config.get('serp.offersPerPage')),
    qsToUris,
    ratingBlockedAgents,
    showEmptyKpSearch: hasKpEmptyResults,
    shownId: loginMotivationPopupOnFavorite.shownId,
    suggestionDistancesSeoText,
    user,
    userGADataLayerData,
    maxAuctionBet,
    maxAuctionService,
    mlRankingGuid: state.mlRankingGuid,
    isOfferInSavedSearch,

    // лимит избранного для неавторизованного юзера
    favoritesLimitForUnauthUser: 5,

    showPushNotification,

    complaintsFormStatus: complaintFeedbackFrom.status,
    isLightView: false,
    soprApi,
    deviceType,
    villagesLink,
    showCountrysideTrap,

    // количество всех и расширенных офферов, используем для вычисления позиции в выдаче
    aggregatedOffersCount: aggregatedOffers,
    extendedOffersCount,
    isQaMode,
    excludedUtilitiesTermsRegions,
    isRedesignEnabled: isRedesignExperimentEnabled(state),
    featureToggle,
    trackingData: prepareTrackingData(filters, results, breadcrumbs, userGADataLayerData),
    auctionBanks,
    abUseExperiments,
    villagePromotionLabel,
    knAuctionCompanies,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): IOffersDispatchProps => {
  return {
    closePopup: () => {
      dispatch(hideMotivationPopup());
    },
    onAgentRatingChanged: (offer: IOffer, rating: TAgentRating) => {
      dispatch(updateAgentRating(offer, rating));
    },
    onComplaintSent: (offerId: number, name: string, message?: string) => {
      dispatch(addOfferComplaint(offerId, name, message));
    },
    onFavoriteChanged: (offer: IOffer, isFavorite: boolean) => {
      dispatch(changeFavorite(offer, isFavorite));
    },
    onOfferCommentsChanged: (offer: IOffer, commentOffer: string | undefined, commentAgent: string | undefined) => {
      dispatch(changeOfferComments(offer, commentOffer, commentAgent));
    },
    onPopupMoreOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'more'));
    },
    onPopupReportOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'report'));
    },
    onPopupMoreClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'more'));
    },
    onPopupReportClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'report'));
    },
    onUserInfoPopupOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'agent'));
    },
    onUserInfoPopupClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'agent'));
    },
    hideOfferAction: (hidingOffer: IHidingOfferInfo) => {
      dispatch(hideOfferAction(hidingOffer));
    },
    sendComplaintFeedback: (body: IComplaintFeedbackBody) => {
      dispatch(sendFeedbackComplaint(body));
    },
    statusChanger: (status: EFeedbackComplaint) => {
      dispatch(statusChanger(status));
    },
    addToComparison: ({ offerId }) => {
      dispatch(addOfferToComparison({ offerId }));
    },
    deleteFromComparison: ({ offerId }) => {
      dispatch(deleteOfferFromComparison({ offerId }));
    },
  };
};

export const OffersContainer = connect<IOffersStateProps, IOffersDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Offers);
