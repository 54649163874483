import { Component } from 'react';

import { ButtonCheckbox } from '../../../../common/components/button_checkbox';

const style = require('./index.css');

export interface IHeaderProps {
  includeNewMoscow: boolean | undefined;
  onNewMoscowSwitched(includeNewMoscow: boolean | undefined): void;
}

export class MoscowDistrictsHeader extends Component<IHeaderProps> {
  public render() {
    return (
      <div className={style.header}>
        <ButtonCheckbox
          buttonStyle={style.districtButton}
          value={this.props.includeNewMoscow === undefined ? false : this.props.includeNewMoscow}
          onValueChange={() => {
            this.props.onNewMoscowSwitched(this.props.includeNewMoscow ? undefined : true);
          }}
        >
          Новая Москва
        </ButtonCheckbox>

        <ButtonCheckbox
          buttonStyle={style.districtButton}
          value={this.props.includeNewMoscow === undefined ? false : !this.props.includeNewMoscow}
          onValueChange={() => {
            this.props.onNewMoscowSwitched(
              this.props.includeNewMoscow === undefined
                ? false
                : this.checkWithoutNewMoscow(this.props.includeNewMoscow),
            );
          }}
        >
          Москва без Новой Москвы
        </ButtonCheckbox>
      </div>
    );
  }

  private checkWithoutNewMoscow = (includeNewMoscow: boolean) => {
    return includeNewMoscow ? false : undefined;
  };
}
