import { ESuburbanOfferFilter } from 'shared/repositories/common/json_query';

import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';
import { FOfferType } from '../../utils/category';

interface IDropdownValue {
  value: string | number;
  label: string;
}

export enum ESuburbanOfferFilterActions {
  Changed = 'filters/suburban_offer_filter/SET_SUBURBAN_OFFER_FILTER',
  Removed = 'filters/suburban_offer_filter/UNSET_SUBURBAN_OFFER_FILTER',
}

export interface ISuburbanOfferFilterChangedAction {
  type: ESuburbanOfferFilterActions.Changed;
  suburbanOfferFilter: ESuburbanOfferFilter;
}

export interface ISuburbanOfferFilterRemovedAction {
  type: ESuburbanOfferFilterActions.Removed;
}

export function setSuburbanOfferFilter(suburbanOfferFilter: ESuburbanOfferFilter): ISuburbanOfferFilterChangedAction {
  return {
    type: ESuburbanOfferFilterActions.Changed,
    suburbanOfferFilter,
  };
}

export function unsetSuburbanOfferFilter(): ISuburbanOfferFilterRemovedAction {
  return {
    type: ESuburbanOfferFilterActions.Removed,
  };
}

export const SUBURBAN_OFFER_FILTER_LABEL_MAP: { [key: number]: string } = {
  [ESuburbanOfferFilter.Any]: 'От любого владельца',
  [ESuburbanOfferFilter.Builder]: 'От застройщиков',
  [ESuburbanOfferFilter.Individual]: 'От собственников и агентов',
};

export const SUBURBAN_OFFER_FILTER_VALUES: IDropdownValue[] = Object.keys(SUBURBAN_OFFER_FILTER_LABEL_MAP).reduce(
  (acc, key) => {
    const value = Number(key);

    return acc.concat({
      label: SUBURBAN_OFFER_FILTER_LABEL_MAP[value],
      value,
    });
  },
  [] as IDropdownValue[],
);

export function suburbanOfferFilterReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case ESuburbanOfferFilterActions.Changed:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setSuburbanOfferFilter(action.suburbanOfferFilter),
        },
      };

    case ESuburbanOfferFilterActions.Removed:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.unsetSuburbanOfferFilter(),
        },
      };

    case 'filters/offer_type/OFFER_TYPE_SELECTED':
      if ([FOfferType.House, FOfferType.Townhouse, FOfferType.Land, FOfferType.HousePart].includes(action.offerType)) {
        return state;
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.unsetSuburbanOfferFilter(),
        },
      };

    default:
      return state;
  }
}
