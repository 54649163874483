import { Button, Outside } from '@cian/ui-kit';
import { mergeStyles, parseCookies } from '@cian/utils';
import { setCookie } from '@cian/utils/lib/browser/cookie';

import * as PropTypes from 'prop-types';
import { Component } from 'react';

import { TUser } from '../../../../types/user';

import { CheckIcon, CloseIcon } from './icons';
import { trackPushNotificationEnable } from './tracking';

import * as styles from './index.css';

export interface IPushSubscriptionProps {
  isOpen: boolean;
}

export interface IPushSubscriptionState {
  isHide: boolean;
}

export interface IPushSubscriptionContext {
  user: TUser;
}

interface IFlocktorySubscribeStatus {
  subscribed: boolean;
  profile_id: number | string;
}

declare global {
  interface IFlocktory {
    getSubscriptionStatus(): Promise<IFlocktorySubscribeStatus>;
  }
}

/**
 * Название события, которое отправляем в dataLayer.push,
 * для показа системного попапа разрешения пуш уведомлений
 */
const FLOCKTORY_KEY_EVENT = 'show_push_cian_site';
const FLOCTORY_CHECK_TIMEOUT = 1500;
const FIFTEEN_DAYS = 3600 * 24 * 15;
const PUSH_SUBSCRIPTION_COOKIE_KEY = 'push_subscription';

export class PushSubscription extends Component<IPushSubscriptionProps, IPushSubscriptionState> {
  public context: IPushSubscriptionContext;

  public static contextTypes = {
    user: PropTypes.object,
  };

  public state = {
    isHide: true,
  };

  public UNSAFE_componentWillReceiveProps(nextProps: IPushSubscriptionProps) {
    const cookies = parseCookies(document.cookie);

    /**
     * Если есть кука и только что добавили в избранное,
     * попап не показываем
     */
    if (cookies[PUSH_SUBSCRIPTION_COOKIE_KEY] && nextProps.isOpen && !this.props.isOpen) {
      this.setState({ isHide: true });
    }
  }

  public componentDidMount() {
    const cookies = parseCookies(document.cookie);
    const { user } = this.context;

    /**
     * Если есть кука или пользователь не авторизован,
     * не показываем попап
     */
    if (cookies[PUSH_SUBSCRIPTION_COOKIE_KEY] || !user.isAuthenticated) {
      return;
    }

    const checkFlocktoryLoad: Promise<IFlocktory> = new Promise(resolve => {
      let timeIntervalFlocktoryCheck: number;

      const flocktoryAttempt = () => {
        if (window.flocktory && typeof window.flocktory.getSubscriptionStatus === 'function') {
          clearTimeout(timeIntervalFlocktoryCheck);

          return resolve(window.flocktory);
        }

        timeIntervalFlocktoryCheck = window.setTimeout(flocktoryAttempt, FLOCTORY_CHECK_TIMEOUT);
      };

      timeIntervalFlocktoryCheck = window.setTimeout(flocktoryAttempt, FLOCTORY_CHECK_TIMEOUT);
    });

    checkFlocktoryLoad.then(flocktory => {
      flocktory.getSubscriptionStatus().then(response => {
        /**
         * Если flocktory вернули поле subscribed и оно равно false,
         * значит текущий пользователь не подписан на пуши и нужно показать попап
         */
        if ('subscribed' in response && !response.subscribed) {
          this.setState({ isHide: false });
        }
      });
    });
  }

  public render() {
    const { isOpen } = this.props;
    const { isHide } = this.state;

    if (isHide || !isOpen) {
      return null;
    }

    return this.renderPopup();
  }

  private renderPopup = () => {
    setCookie(PUSH_SUBSCRIPTION_COOKIE_KEY, '1', {
      expires: FIFTEEN_DAYS,
      secure: false,
    });

    return (
      <Outside onOutside={this.hidePopup}>
        <div {...mergeStyles(styles['container'], styles['container--visible'])}>
          <div className={styles['header']}>
            <span className={styles['check']}>
              <CheckIcon />
            </span>
            Добавлено{' '}
            <a className={styles['link']} href="#" onClick={this.onHandleLinkClick}>
              в избранное
            </a>
            <button className={styles['close']} type="button" onClick={this.hidePopup}>
              <CloseIcon />
            </button>
          </div>
          <div className={styles['body']}>
            <p>Следить за изменением цены на этот объект</p>
            <Button size="XS" theme="stroke_primary" type="button" onClick={this.enableSystemPushNotificationPopup}>
              Включить уведомления
            </Button>
          </div>
        </div>
      </Outside>
    );
  };

  private onHandleLinkClick = () => {
    window.open('/rent/flat/favorites/', '_blank');
  };

  private hidePopup = () => {
    this.setState({ isHide: true });
  };

  private enableSystemPushNotificationPopup = () => {
    this.hidePopup();

    /**
     * Пушим кастомное событие, на которое настроен flocktory триггер,
     * по этому событию показываем системный попап разрешения на пуш уведомления
     */
    window.dataLayer.push({ event: FLOCKTORY_KEY_EVENT });

    trackPushNotificationEnable();
  };
}
