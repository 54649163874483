import { IPhone } from '../offer/types';

export function getFormattedPhoneNumber(phone: IPhone): string {
  if (!phone || !phone.number) {
    return '';
  }

  return (
    `${phone.countryCode && phone.countryCode.startsWith('+') ? '' : '+'}${phone.countryCode} ` +
    `${phone.number.substr(0, 3)} ` +
    `${phone.number.substr(3, 3)}-${phone.number.substr(6, 2)}-${phone.number.substr(8, 2)}`
  );
}

export function validateEmailFormat(email: string): boolean {
  return /.+@.+\..+/gi.test(email);
}

export function formatBedRoomsLabel(count: number) {
  if (count === 0) {
    return 'Спален';
  }

  return `${count}+ спален`;
}
