import { IAppState, TActions } from '../../common/state';

export interface IOfficeCalculatorOpenedAction {
  type: 'serp/office_calculator/MODAL_OPENED';
}

export interface IOfficeCalculatorClosedAction {
  type: 'serp/office_calculator/MODAL_CLOSED';
}

export function openOfficeCalculator(): IOfficeCalculatorOpenedAction {
  return {
    type: 'serp/office_calculator/MODAL_OPENED',
  };
}

export function closeOfficeCalculator(): IOfficeCalculatorClosedAction {
  return {
    type: 'serp/office_calculator/MODAL_CLOSED',
  };
}

export function toggleOfficeCalculatorReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'serp/office_calculator/MODAL_OPENED':
      return {
        ...state,
        isOfficeCalculatorVisible: true,
      };
    case 'serp/office_calculator/MODAL_CLOSED':
      return {
        ...state,
        isOfficeCalculatorVisible: false,
      };
    default:
      return state;
  }
}
