import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IKitchenAreaTagType = 'kitchenArea';

export interface IKitchenAreaTag {
  type: 'kitchenArea';
  min: number | undefined;
  max: number | undefined;
}

function isKitchenAreaTag(tag: TTag): tag is IKitchenAreaTag {
  return tag.type === IKitchenAreaTagType;
}

export const kitchenAreaTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.kitchen && jsonQuery.kitchen.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isKitchenAreaTag, () => ({
        type: IKitchenAreaTagType,
        min: value.gte,
        max: value.lte,
      }));
    } else {
      return ensureTagNotExists(tags, isKitchenAreaTag);
    }
  },
};
