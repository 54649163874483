import { mergeStyles } from '@cian/utils';

import { isEmpty } from 'ramda';
import * as React from 'react';

const style = require('./index.css');

export type TStyle = 'primary' | 'danger';
export type TSize = 'default' | 'small' | 'large' | 'xlarge';

type IThemesMap = {
  [key in TStyle]: {
    default: React.CSSProperties;
    light: React.CSSProperties;
    outline: React.CSSProperties;
  };
};

const themesMap: IThemesMap = {
  primary: require('./themes/primary.css'),
  danger: require('./themes/danger.css'),
};

export interface IButtonProps {
  className?: string;
  style?: React.CSSProperties;
  type?: string;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  title?: string;

  /**
   * Тема
   * @type {TStyle}
   * @memberof IButtonProps
   */
  theme: TStyle;

  /**
   * Режим отображения
   * @type {('light' | 'outline')}
   * @memberof IButtonProps
   */
  mode?: 'light' | 'outline';

  /**
   * Размер
   * @type {TSize}
   * @memberof IButtonProps
   */
  size?: TSize;
}

export const Button: React.FC<React.PropsWithChildren<IButtonProps>> = props => {
  const { theme, mode, size, ...sharedProps } = props;

  const mainStyle = mergeStyles(
    style['main'],
    size === 'large' && style['main--large'],
    size === 'xlarge' && style['main--xlarge'],
    size === 'small' && style['main--small'],
    mode === 'outline' && style['outline'],
    mode === 'outline' && size === 'large' && style['outline--large'],
    mode === 'outline' && size === 'xlarge' && style['outline--xlarge'],
  );

  let themeStyle = themesMap[theme]['default'];
  if (mode) {
    if (mode === 'light') {
      themeStyle = themesMap[theme]['light'];
    } else if (mode === 'outline') {
      themeStyle = themesMap[theme]['outline'];
    }
  }

  return (
    <button
      {...sharedProps}
      {...(mergeStyles(
        mainStyle,
        !isEmpty(themeStyle) ? themeStyle : null,
        props.className,
        props.style,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      ) as any)}
    />
  );
};
