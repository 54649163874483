const point = require('./assets/point.svg');
const hoverPoint = require('./assets/point_hover.svg');
const smallPoint = require('./assets/small_point.svg');
const startPoint = require('./assets/start_point.svg');

export const YMAPS_PRESETS_COMMON = {
  draggable: true,
  strokeColor: '#007aeb',
  fillColor: '#007aeb',
  strokeWidth: 2,
  fillOpacity: 0.3,
  editorDrawingCursor: 'crosshair',
  hasBalloon: false,
  openBalloonOnClick: false,
};

export const YMAPS_PRESETS_CIRCLE = {
  CIRCLE: {
    ...YMAPS_PRESETS_COMMON,
  },
  PIN_CIRCLE_RESIZER: {
    draggable: true,
    iconLayout: 'default#image',
    iconImageHref: startPoint,
    iconImageSize: [27, 27],
    iconImageOffset: [-13, -13],
  },
};

export const YMAPS_PRESETS_POLYGON = {
  POLYGON: {
    ...YMAPS_PRESETS_COMMON,
  },
  POLYGON_PIN: {
    vertexLayout: 'default#image',
    vertexIconImageHref: point,
    vertexIconImageSize: [10, 10],
    vertexIconImageOffset: [-5, -5],

    vertexLayoutHover: 'default#image',
    vertexIconImageHrefHover: hoverPoint,
    vertexIconImageSizeHover: [10, 10],
    vertexIconImageOffsetHover: [-5, -5],

    vertexLayoutActive: 'default#image',
    vertexIconImageHrefActive: hoverPoint,
    vertexIconImageSizeActive: [16, 16],
    vertexIconImageOffsetActive: [-8, -8],

    vertexLayoutDrag: 'default#image',
    vertexIconImageHrefDrag: hoverPoint,
    vertexIconImageSizeDrag: [16, 16],
    vertexIconImageOffsetDrag: [-8, -8],

    edgeIconLayout: 'default#image',
    edgeIconImageHref: smallPoint,
    edgeIconImageSize: [6, 6],
    edgeIconImageOffset: [-3, -3],

    edgeIconLayoutHover: 'default#image',
    edgeIconImageHrefHover: hoverPoint,
    edgeIconImageSizeHover: [10, 10],
    edgeIconImageOffsetHover: [-5, -5],

    edgeIconLayoutDrag: 'default#image',
    edgeIconImageHrefDrag: hoverPoint,
    edgeIconImageSizeDrag: [10, 10],
    edgeIconImageOffsetDrag: [-5, -5],
  },
  START_PIN: {
    iconLayout: 'default#image',
    iconImageHref: startPoint,
    iconImageSize: [28, 28],
    iconImageOffset: [-14, -14],
    iconLayoutHover: 'default#image',
    iconImageHrefHover: startPoint,
    iconImageSizeHover: [28, 28],
    iconImageOffsetHover: [-14, -14],
  },
  DEFAULT_PIN: {
    iconLayout: 'default#image',
    iconImageHref: point,
    iconImageSize: [10, 10],
    iconImageOffset: [-5, -5],

    iconLayoutHover: 'default#image',
    iconImageHrefHover: hoverPoint,
    iconImageSizeHover: [10, 10],
    iconImageOffsetHover: [-5, -5],
  },
};
