import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IPhoneTagType = 'advanced-phone';

export interface IPhoneTag {
  type: 'advanced-phone';
  value: string;
}

export const isPhoneTag = (tag: TTag): tag is IPhoneTag => tag.type === IPhoneTagType;

export const phoneTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.contact && jsonQuery.contact.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isPhoneTag, () => ({ type: IPhoneTagType, value }));
    } else {
      return ensureTagNotExists(tags, isPhoneTag);
    }
  },
};
