import { setCookie } from '@cian/utils/lib/browser/cookie';

import { Dispatch, IAppState, TActions } from '../../common/state';

export interface IShowMotivationPopupAction {
  type: 'serp/login_motivation_popup/SHOWED_POPUP';
  offerId: number;
}

export interface IHideMotivationPopupAction {
  type: 'serp/login_motivation_popup/HIDDEN_POPUP';
}

export function showMotivationPopup(offerId: number) {
  return (dispatch: Dispatch<TActions>) => {
    setCookie('serp_login_motivation_popup', '1', {
      domain: '.cian.ru',
      secure: false,
    });

    dispatch({ type: 'serp/login_motivation_popup/SHOWED_POPUP', offerId });
  };
}

export function hideMotivationPopup() {
  return (dispatch: Dispatch<TActions>) => {
    dispatch({
      type: 'serp/login_motivation_popup/HIDDEN_POPUP',
    });
  };
}

export function showMotivationPopupReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'serp/login_motivation_popup/SHOWED_POPUP':
      return {
        ...state,
        loginMotivationPopupOnFavorite: {
          ...state.loginMotivationPopupOnFavorite,
          isActive: false,
          shownId: action.offerId,
        },
      };
    case 'serp/login_motivation_popup/HIDDEN_POPUP':
      return {
        ...state,
        loginMotivationPopupOnFavorite: {
          ...state.loginMotivationPopupOnFavorite,
          shownId: undefined,
        },
      };
    default:
      return state;
  }
}
