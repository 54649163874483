import { useBooleanState } from '@cian/react-utils';

import { FC, useEffect, useRef } from 'react';

type RenderInViewPortProps = {
  children(): JSX.Element | null;
};

export const RenderInViewPort: FC<RenderInViewPortProps> = props => {
  const { children } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  const { state: wasShown, setTrue: setWasShown } = useBooleanState();

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting) {
          observer.disconnect();
          setWasShown();
        }
      });

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }

    return undefined;
  }, [setWasShown]);

  return wasShown ? children() : <div ref={ref} />;
};
