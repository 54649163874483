import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as React from 'react';

import { IPhone } from 'shared/repositories/search-offers.legacy/v2/types';

import { PhoneButton } from './PhoneButton';
import { PhoneNumbers } from './PhoneNumbers';

import * as styles from './SimplePhoneButton.css';

export interface ISimplePhoneButtonProps {
  phones: IPhone[];
  text?: React.ReactNode;
  isSimplified?: boolean;
  workTime?: React.ReactNode;
  warningMessage?: React.ReactNode;
  containerStyle?: IStyleConfig;
  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseOut?: React.MouseEventHandler<HTMLDivElement>;
  onPhoneOpened?(): void;
  isFullWidth: boolean;
}

export enum EPhoneState {
  BUTTON,
  TEXT,
}

export interface ISimplePhoneButtonState {
  type: EPhoneState;
}

export class SimplePhoneButton extends React.Component<ISimplePhoneButtonProps, ISimplePhoneButtonState> {
  public constructor(props: ISimplePhoneButtonProps) {
    super(props);

    this.state = {
      type: EPhoneState.BUTTON,
    };
  }

  public render() {
    const {
      containerStyle,
      onMouseOver,
      onMouseOut,
      isSimplified,
      workTime,
      text,
      phones,
      warningMessage,
      isFullWidth,
    } = this.props;
    let viewer: React.ReactNode;

    if (this.state.type === EPhoneState.BUTTON) {
      viewer = <PhoneButton isFullWidth={isFullWidth} text={text} onPhoneOpened={this.handlePhoneOpen} />;
    }
    if (this.state.type === EPhoneState.TEXT) {
      viewer = (
        <PhoneNumbers
          isSimplified={!!isSimplified}
          phones={phones}
          warningMessage={warningMessage}
          workTime={workTime}
        />
      );
    }

    return (
      <div {...mergeStyles([styles['container'], containerStyle])} onMouseOut={onMouseOut} onMouseOver={onMouseOver}>
        {viewer}
      </div>
    );
  }

  private handlePhoneOpen = () => {
    this.setState({
      type: EPhoneState.TEXT,
    });
    if (this.props.onPhoneOpened) {
      this.props.onPhoneOpened();
    }
  };
}
