import { ICommentsAPI } from 'shared/packages/search-card-fragment/components/comments';
import { ILoginMotivationPopupAPI } from 'shared/packages/search-card-fragment/components/login_motivation_popup';
import { IUserInfoAPI } from 'shared/packages/search-card-fragment/components/user-info-popup';
import {
  ICommercialCardAPI,
  ICommercialCardCompoentsProps,
  TCommercialCardTracking,
} from 'shared/packages/search-card-fragment/types/commercialCard';

import { EFeedbackComplaint, IComplaintFeedbackBody } from '../../state/offer_card/feedback_complaint';

import { IOfferCardCreatorProps } from './helpers';
import {
  ITrackFirstMessageSentWithProductsParams,
  trackAddCommentClick,
  trackAddressClick,
  trackDocLinkClicked,
  trackFirstMessageClick,
  trackFirstMessageOpen,
  trackFirstMessageSentWithProducts,
  trackGoToMessages,
  trackHidePrintButtonClick,
  trackMapClosed,
  trackMapOpened,
  trackMenuOpened,
  trackMultiOfferClick,
  trackPDFLinkClicked,
  trackReportPopupOpened,
  trackShowAllIdenticalOffers,
  trackingCreate,
  trackingEnter,
  trackingShowPopup,
} from './tracking';

export interface IPrepareCommercialCardProps {
  onFavoriteChange(isFavorite: boolean): void;
  onPageJumped(label?: string): void;
  onPopupClose(): void;
  onPopupOpen(): void;
  onPhoneOpened(): void;
  onHideOffer(): void;
  onPhotoSlideChangedOnce(): void;
  trackTradePopupShow(): void;
  trackTradePopupClick(): void;
  analyticsPopup: {
    onButtonClick(offerId: number): void;
  };
}

export interface IComplaintsHelpers {
  sendComplaintFeedback(body: IComplaintFeedbackBody): void;
  statusChanger(status: EFeedbackComplaint): void;
  complaintsFormStatus: EFeedbackComplaint;
}

export interface ICommercialCardComponentExtension {
  reportPopup: {
    onComplaintSent(offerId: number, name: string, message?: string): void;
  };
  complaintsHelpers: IComplaintsHelpers;
  analyticsPopup: {
    onButtonClick(offerId: number): void;
  };
  onOfferCardClick?(): void;
}

export type CommercialCardApiProps = ICommercialCardCompoentsProps & ICommercialCardComponentExtension;

export function prepareCommercialCardComponentsProps(
  creatorProps: IOfferCardCreatorProps,
  prepareProps: IPrepareCommercialCardProps,
  params: ITrackFirstMessageSentWithProductsParams,
): CommercialCardApiProps {
  const {
    closePopup,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    onAgentRatingChanged,
    onOfferCommentsChanged,
    shownId,
    ratingBlockedAgents,
    user,
    favoritesLimitForUnauthUser,
    onComplaintSent,
    sendComplaintFeedback,
    statusChanger,
    complaintsFormStatus,
  } = creatorProps;

  const {
    onFavoriteChange,
    onPageJumped,
    onPopupClose,
    onPopupOpen,
    onPhoneOpened,
    onHideOffer,
    onPhotoSlideChangedOnce,
    trackTradePopupShow,
    trackTradePopupClick,
    analyticsPopup,
  } = prepareProps;

  const comments: ICommentsAPI = {
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    isAuthenticated: user.isAuthenticated,
    onOfferCommentsChanged,
  };

  const loginMotivationPopup: ILoginMotivationPopupAPI = {
    closePopup,
    shownId,
  };

  const card: ICommercialCardAPI = {
    onPhotoSlideChangedOnce,
    onMapOpened: trackMapOpened,
    onMapClosed: trackMapClosed,
    onMenuOpened: trackMenuOpened,
    onPDFLinkClicked: trackPDFLinkClicked,
    onDocLinkClicked: trackDocLinkClicked,
    onFavoriteChange,
    onPageJumped,
    onPhoneOpened,
    onHideOffer,
  };

  const userInfo: IUserInfoAPI = {
    onAgentRatingChanged,
    onPopupClose,
    onPopupOpen,
    ratingBlockedAgents,
    userIsAuthenticated: user.isAuthenticated,
  };

  const { position, offer, jsonQuery } = params;
  const tracking: TCommercialCardTracking = {
    trackAddCommentClick,
    trackAddressClick,
    trackFirstMessageClick: (trackingLabel: string) =>
      trackFirstMessageClick({ position, offer, jsonQuery }, trackingLabel),
    trackFirstMessageSent: trackFirstMessageSentWithProducts(params),
    trackFirstMessageOpen: (trackingLabel: string) =>
      trackFirstMessageOpen({ position, offer, jsonQuery }, trackingLabel),
    trackGoToMessages,
    trackHidePrintButtonClick,
    trackingCreate,
    trackingEnter,
    trackingShowPopup,
    trackMultiOfferClick,
    trackAllDuplicatesClick: () => trackShowAllIdenticalOffers({ position, offer, jsonQuery }),
    trackReportPopupOpened,
    trackTradePopupShow,
    trackTradePopupClick,
  };

  return {
    card,
    comments,
    favoritesLimitForUnauthUser,
    loginMotivationPopup,
    userInfo,
    tracking,
    reportPopup: {
      onComplaintSent,
    },
    complaintsHelpers: {
      sendComplaintFeedback,
      statusChanger,
      complaintsFormStatus,
    },
    analyticsPopup,
  };
}
