import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const ILoggiaTagType = 'advanced-balconies-loggia';

export interface ILoggiaTag {
  type: 'advanced-balconies-loggia';
}

function isLoggiaTag(tag: TTag): tag is ILoggiaTag {
  return tag.type === ILoggiaTagType;
}

export const loggiaTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.loggia && jsonQuery.loggia.value;

    if (value) {
      return ensureSingleTagExists(tags, isLoggiaTag, () => ({ type: ILoggiaTagType }));
    } else {
      return ensureTagNotExists(tags, isLoggiaTag);
    }
  },
};
