import { Component } from 'react';

import { BUTTON_SVGS, DrawMode, TDrawMode } from './helpers';

const style = require('./index.css');

export interface IMenuProps {
  drawMode: TDrawMode;
  onChange(drawMode: TDrawMode): void;
}

export class MapTopMenu extends Component<IMenuProps, object> {
  public static defaultProps: IMenuProps = {
    onChange: () => {},
    drawMode: DrawMode.None,
  };

  public render() {
    const getButton = this.getMapMenuButton(this.props.drawMode);

    return (
      <div className={style['mapMenu']}>
        <div className={style['mapMenu-title']}>Выделение области:</div>
        {[DrawMode.Polygon, DrawMode.Shape, DrawMode.Circle].map(drawMode => getButton(drawMode))}
      </div>
    );
  }

  // eslint-disable-next-line react/display-name
  private getMapMenuButton = (currentDrawMode: TDrawMode) => (buttonDrawMode: TDrawMode) => {
    const SVG = BUTTON_SVGS[buttonDrawMode];

    const className =
      style['mapMenuIcon'] + ' ' + (currentDrawMode !== buttonDrawMode ? '' : style['mapMenuIcon--active']);

    return (
      <button className={className} key={`button-${buttonDrawMode}`} onClick={() => this.setMode(buttonDrawMode)}>
        <SVG />
      </button>
    );
  };

  private setMode = (drawMode: TDrawMode) => {
    this.props.onChange(drawMode);
  };
}
