import {
  IAdFoxBannerOnLoadData,
  TAdFoxBannerProps as TAdFoxBannerComponentProps,
  connect as adFoxConnect,
} from '@cian/adfox-component';

import { Component } from 'react';

import { OwnTemplates } from './OwnTemplates';

const AdFoxBannerComponent = adFoxConnect(OwnTemplates);

type TAdFoxBannerProps = TAdFoxBannerComponentProps & {
  disableYan?: boolean;
};

interface IAdFoxBannerState {
  isHidden: boolean;
}

export class AdFoxBanner extends Component<TAdFoxBannerProps, IAdFoxBannerState> {
  public state: IAdFoxBannerState = {
    isHidden: false,
  };

  public render() {
    if (this.state.isHidden) {
      return null;
    }

    return <AdFoxBannerComponent {...this.props} onLoad={this.handleLoad} onStub={this.handleStub} />;
  }

  private handleLoad = (data: IAdFoxBannerOnLoadData) => {
    const { bundleName } = data;
    const { disableYan } = this.props;

    if (disableYan && bundleName === 'banner.direct') {
      this.setState({
        isHidden: true,
      });
    }
  };

  private handleStub = () => {
    this.setState({
      isHidden: true,
    });
  };
}
