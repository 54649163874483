import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { IContext, ISaveSearchProps } from '@cian/save-search-widget';
import { ModalWindow } from '@cian/ui-kit/modal';

import loadable from '@loadable/component';
import * as React from 'react';

import { IJsonQuery } from '../../../json_query';

import { WidgetErrorBoundary } from './components/WidgetErrorBoundary';
import { WidgetFallback } from './components/WidgetFallback';

import * as styles from './SaveSearchModal.css';

const SaveSearchWidgetLoadable = loadable.lib(() => import('@cian/save-search-widget'), {
  fallback: <WidgetFallback />,
});

export interface ISaveSearchModalProps {
  httpApi: IHttpApi;
  logger: ILogger;
  subdomain: string;
  jsonQuery: IJsonQuery;
  title: string | null;
  userEmail: string | null;
  hideLicense?: boolean;
  subscribeToPushAvailable?: boolean;
  open: boolean;
  onPushSubscribe?(): Promise<void>;
  onSubscribed?(parameters: Parameters<Required<ISaveSearchProps>['onSubscribed']>[0]): void;
  onClose(): void;
}

export const SaveSearchModal: React.FC<ISaveSearchModalProps> = ({
  hideLicense,
  httpApi,
  jsonQuery,
  logger,
  open: isOpen,
  subdomain,
  subscribeToPushAvailable,
  title,
  userEmail,
  onClose,
  onPushSubscribe,
  onSubscribed,
}) => {
  const widgetContext = React.useRef<IContext | null>(null);
  const handleRetry = React.useCallback(() => {
    SaveSearchWidgetLoadable.load();
  }, []);

  React.useEffect(() => {
    if (!isOpen) {
      widgetContext.current = null;
    }
  }, [isOpen]);

  return (
    <ModalWindow
      content={
        <div className={styles['container']}>
          <WidgetErrorBoundary logger={logger} onRetry={handleRetry}>
            <SaveSearchWidgetLoadable>
              {({ createContext, Provider, SaveSearch, EFrequency, ESource }) => {
                if (!widgetContext.current) {
                  widgetContext.current = createContext({
                    subdomain,
                    httpApi,
                    logger,
                  });
                }

                return (
                  <Provider context={widgetContext.current}>
                    <SaveSearch
                      defaultEmail={userEmail || undefined}
                      defaultFrequency={EFrequency.Hourly}
                      defaultSubscribeToNews={true}
                      defaultSubscribeToPush={true}
                      defaultTitle={title || undefined}
                      hideLicense={hideLicense}
                      jsonQuery={jsonQuery}
                      source={ESource.Listing}
                      subscribeToPushAvailable={subscribeToPushAvailable}
                      onPushSubscribe={onPushSubscribe}
                      onSubscribed={onSubscribed}
                    />
                  </Provider>
                );
              }}
            </SaveSearchWidgetLoadable>
          </WidgetErrorBoundary>
        </div>
      }
      open={isOpen}
      size="M"
      title="Сохранение поиска"
      onClose={onClose}
    />
  );
};
