/* eslint-disable max-lines */

import { IOffer as IOfferReal } from 'shared/repositories/search-offers.legacy/v2/types';

import { EAccountType } from './identicalOffers';
import { IGeoValue } from './json-query';

export interface IOfferAnalyticsInfo {
  id?: number;
  name?: string;
  price?: number;
  brand?: string;
  category?: string;
  variant?: string;
  quantity: number;
}

export interface IOfferPhoto {
  id: number;
  fullUrl: string;
  thumbnailUrl: string;
  thumbnail2Url: string;
  rotateDegree: number | null;
  isDefault: boolean;
  miniUrl: string;
}

export interface IOfferVideo {
  duration: number | null;
  type: string | null;
  uploadDate: string | null;
  url: string | null;
}

export type TLeaseTerm = 'longTerm' | 'fewMonths' | null;
export const LeaseTerm = {
  FewMonths: 'fewMonths' as const,
  Long: 'longTerm' as const,
};

export type TLeaseType = 'direct' | 'sublease' | 'jointVenture';
export type TAccessType = 'free' | 'passSystem' | null;
export type TOfferDetailLayout = 'cabinet' | 'mixed' | 'openSpace';

export const ERegions = {
  moscow: 1 as number,
  moscowArea: 4593 as number,
  spb: 2 as number,
  spbArea: 4588 as number,
};

export const PrimaryMetroRegions = [ERegions.moscow, ERegions.moscowArea, ERegions.spb, ERegions.spbArea];

export type TBargainTermsSaleType = 'alternative' | 'dupt' | 'dzhsk' | 'free' | 'fz214' | 'investment' | 'pdkp';

export const BargainTermsSaleTypePresenter: {
  [index: string]: string;
} = {
  alternative: 'альтернатива',
  dupt: 'переуступка',
  dzhsk: 'договор ЖСК',
  free: 'свободная',
  fz214: '214-ФЗ',
  investment: 'договор инвестирования',
  pdkp: 'предварительный договор',
};

interface IBuildingMaterialTypePresenter {
  [index: string]: string;
}

export const BuildingMaterialTypePresenter: IBuildingMaterialTypePresenter = {
  block: 'блочный',
  brick: 'кирпичный',
  monolith: 'монолитный',
  monolithBrick: 'кирпично-монолитный',
  panel: 'панельный дом',
  stalin: 'сталинский',
  wood: 'деревянный',
};

export type TBargainTermsPriceType = 'all' | 'hectare' | 'sotka' | 'squareMeter';

export interface IUtilitiesTerms {
  includedInPrice: boolean;
  price: number;
  flowMetersNotIncludedInPrice?: boolean | null;
}

export interface IAddress {
  id: number;
  name: string;
  fullName: string;
  shortName: string;
  type: 'location' | 'street' | 'house' | 'metro' | 'okrug' | 'raion';
  isFormingAddress: boolean | null;
  locationTypeId: ELocationTypeId | null;
  qs: string;
}

export enum ELocationTypeId {
  City = 1,
}

export interface IUnderground {
  cianId: number | null;
  id: number;
  isDefault: boolean;
  lineColor: string;
  lineId: number;
  name: string;
  qs: string;
  time: number;
  transportType: 'walk' | 'transport';
  underConstruction?: boolean;
  releaseYear?: number;
}

export interface IRailway {
  directionIds?: number[];
  distance?: string | null;
  geoType: string;
  gs?: string;
  id: number;
  name: string;
  time: number;
  travelType: 'byFoot' | 'byCar';
}

export interface IDistrictInfo {
  id?: number;
  locationId?: number;
  name?: string;
  parentId: number | null;
}

export type GarageGarageType =
  // Встроенный
  | 'builtIn'
  // Капитальный
  | 'capital'
  // Самострой
  | 'samostroy'
  // Ракушка
  | 'shell';

export type GarageMaterial =
  // Кирпичный
  | 'brick'
  // Металлический
  | 'metal';

export type GarageStatus =
  // По доверенности
  | 'byProxy'
  // Кооператив
  | 'cooperative'
  // Собственность
  | 'ownership';

export type GarageType =
  // Бокс
  | 'box'
  // Гараж
  | 'garage'
  // Машиноместо
  | 'parkingPlace';

export type THomeownerProof =
  /** Прошёл идентификацию по паспорту */
  | 'passport'
  /** Прошёл проверку в Росреестре */
  | 'rosreestr'
  /** Зарегистрирован в ФНС как самозанятый */
  | 'selfEmployed';

export interface IGarage {
  // Тип гаража
  garageType?: GarageGarageType;
  // Тип бокса
  material?: GarageMaterial;
  // Статус
  status?: GarageStatus;
  // Тип
  type?: GarageType;
}

export interface IHighwayInfo {
  distance?: string | number;
  id?: number;
  isDefault?: boolean;
  name?: string;
}

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface IGeo {
  address: IAddress[];
  buildingAddress: string | null;
  coordinates: ICoordinates;
  countryId: number;
  districts: IDistrictInfo[];
  highways: IHighwayInfo[];
  undergrounds?: IUnderground[];
  railways?: IRailway[];
  userInput: string;
  sortedGeoIds?: IGeoValue[];
  jk?: IJK;
}

export interface ILivingAreaDetails {
  roomArea: string | null;
  totalArea: string;
  livingArea: string;
  kitchenArea: string;
  roomsCount: number | null;
}

export interface ILand {
  area?: string | number;
  areaUnitType?: LandAreaUnitType;
  possibleToChangeStatus?: boolean;
  status?: LandStatus;
  type?: LandType;
}

export type LandAreaUnitType = 'hectare' | 'sotka';

export type LandStatus =
  // Фермерское хозяйство
  | 'farm'
  // Участок сельскохозяйственного назначения
  | 'forAgriculturalPurposes'
  // Участок лесного фонда
  | 'forestArea'
  // Садоводство
  | 'gardening'
  // Садовое некоммерческое товарищество
  | 'gardeningNonProfitPartnership'
  // Индивидуальное жилищное строительство
  | 'individualHousingConstruction'
  // Земля промышленного назначения
  | 'industrialLand'
  // Участок промышленности, транспорта, связи и иного не сельхоз. назначения
  | 'industryTransportCommunications'
  // Инвестпроект
  | 'investmentProject'
  // Особо охраняемых категорий
  | 'ofProtectedCategories'
  // Участок запаса
  | 'reserve'
  // Участок поселений
  | 'settlements'
  // Дачное некоммерческое партнерство
  | 'suburbanNonProfitPartnership'
  // Дачное некоммерческое партнерство поселений
  | 'suburbanNonProfitSettlementsPartnership'
  // Участок водного фонда
  | 'waterArea';

// Тип участка
export type LandType = 'owned' | 'rent';

export interface IFloorDetails {
  floorNumber: number | null;
  balconiesCount: number | null;
  loggiasCount: number | null;
}

export type IBuildingMaterialType =
  | 'monolith'
  | 'monolithBrick'
  | 'brick'
  | 'panel'
  | 'block'
  | 'wood'
  | 'stalin'
  | 'boards'
  | 'old';

interface IBuldingQuarterPresenter {
  [index: string]: string;
}

export const BuildingQuarter: IBuldingQuarterPresenter = {
  first: '1',
  fourth: '4',
  second: '2',
  third: '3',
};

export interface IBuildingDeadline {
  isComplete: boolean;
  quarter: string;
  year: number;
}

export interface ISpecialty {
  // Английское название
  engName?: string;
  // ID
  id?: number;
  // Русское название
  rusName?: string;
}

export interface ICommercialSpecialty {
  // Дополнительные виды
  additionalTypes?: string[];
  // Cпециализация
  specialties?: ISpecialty[];
  // Коды специализаций помещений
  types?: string[];
}

export enum EBuildingClassType {
  /** A **/
  A = 'a',
  /** A+ **/
  APlus = 'aPlus',
  /** B **/
  B = 'b',
  /** B- **/
  BMinus = 'bMinus',
  /** B+ **/
  BPlus = 'bPlus',
  /** C **/
  C = 'c',
  /** C+ **/
  CPlus = 'cPlus',
  /** D **/
  D = 'd',
}

export interface IParkingSchema {
  placesCount?: number | null;
}

export interface IBuilding {
  accessType?: TAccessType;
  buildYear?: number;
  cargoLiftsCount: number | null;
  deadline: IBuildingDeadline | null;
  floorsCount: number;
  materialType: IBuildingMaterialType | null;
  passengerLiftsCount: number | null;
  type: TBuilding | null;
  classType?: TCommercialClass | null;
  parking?: IParkingSchema | null;
}

export interface IPhone {
  countryCode: string | null;
  number: string | null;
}

export interface IPromoInfo {
  priceBeforeDiscount: string | null;
  certificate: boolean;
  end: string;
  name: string;
  certificateUrl: string | null;
  specProject: boolean;
}

export type TrustLevelInvolved = 'involved';
export type UserTrustLevel = TrustLevelInvolved;
export const EUserTrustLevel = {
  Involved: 'involved' as TrustLevelInvolved,
};

export type TAgentRating = 'negative' | 'positive' | 'neutral';

export interface IAgent {
  accountType?: EAccountType | null;
  agencyName: string | null;
  agentAvatarUrl?: string;
  cianUserId: number | string;
  cianProfileStatus?: ECianProfileStatus;
  isAgent: boolean;
  isBuilder?: boolean;
  isCallbackUser?: boolean;
  isCianPartner: boolean;
  isChatsEnabled: boolean;
  isHidden: boolean;
  /** Прошёл идентификацию по паспорту */
  isPassportVerified: boolean | null;
  /** Зарегистрирован в ФНС как самозанятый */
  isSelfEmployed: boolean | null;
  personalRating: 'negative' | 'positive' | null;
  phoneNumbers?: IPhone[];
  subAgentCompanyName: string | null;
  userTrustLevel: UserTrustLevel;
  profileUri?: string | null;
  experience?: string | null;
  /** Доступность агента */
  agentAvailability: IAgentAvailability | null;
  masterAgent?: IMasterAgent | null;
  /** Доступен онлайн просмотр */
  canShowOnline?: boolean | null;
  agentModerationInfo?: IAgentModerationInfoSchema | null;
}

export interface IUserLevelInfoSchema {
  /** Идентифицирован ли пользователь по паспорту **/
  hasVerifiedDocuments?: boolean | null;
  /** Имя уровня РЧ **/
  levelName?: string | null;
  /** Порядок отображения уровня РЧ **/
  levelOrder?: number | null;
  /** Системное имя уровня РЧ **/
  levelSystemName?: string | null;
}

export interface IAgentModerationInfoSchema {
  userLevelInfo?: IUserLevelInfoSchema | null;
  isUserIdentifiedByDocuments?: boolean | null;
}

export interface IMasterAgent {
  id: number | string | null;
  isModerationPassed?: boolean | null;
  absoluteMiniAvatarUrl?: string | null;
  miniAvatarUrl?: string | null;
  absoluteAvatarUrl?: string | null;
  avatarUrl?: string | null;
  name: string | null;
  accountType: string | null;
  profileUri: string | null;
}

export interface IAgentAvailability {
  /** Доступен ли агент в данный промежуток времени в таймзоне агента */
  available: boolean | null;
  /** тут пользователь - автор объявления */
  userId: number | string | null;
  /** Заголовок с текстом для не рабочего времени в таймзоне +3 */
  title?: string | null;
  /** Сообщение с текстом для не рабочего времени в таймзоне +3 */
  message?: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableFrom: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableTo: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  vacationTo: string | null;
}

export interface IDeveloper {
  id?: number;
  name?: string;
}

export interface IJkGaGeo {
  cityId?: number;
  moId?: number;
  oblId?: number;
}

export interface IJKHouse {
  id?: number;
  name?: string;
}

export interface IJK {
  developer?: IDeveloper;
  displayName?: string;
  fullUrl?: string;
  gaGeo?: IJkGaGeo;
  house?: IJKHouse;
  id?: number;
  name?: string;
  webSiteUrl?: string;
}

export type TOfferCategory =
  | 'bedRent'
  | 'commercialLandRent'
  | 'commercialLandSale'
  | 'cottageRent'
  | 'cottageSale'
  | 'dailyBedRent'
  | 'dailyFlatRent'
  | 'dailyHouseRent'
  | 'dailyRoomRent'
  | 'flatRent'
  | 'flatSale'
  | 'flatShareSale'
  | 'freeAppointmentObjectRent'
  | 'freeAppointmentObjectSale'
  | 'garageRent'
  | 'garageSale'
  | 'houseRent'
  | 'houseSale'
  | 'houseShareRent'
  | 'houseShareSale'
  | 'industryRent'
  | 'industrySale'
  | 'landRent'
  | 'landSale'
  | 'newBuildingFlatSale'
  | 'officeRent'
  | 'officeSale'
  | 'roomRent'
  | 'roomSale'
  | 'shoppingAreaRent'
  | 'shoppingAreaSale'
  | 'townhouseRent'
  | 'townhouseSale'
  | 'warehouseRent'
  | 'warehouseSale'
  | 'buildingRent'
  | 'buildingSale'
  | 'businessSale';

export type TBuilding =
  | 'Бизнес-центр'
  | 'Торгово-развлекательный центр'
  | 'Старый фонд'
  | 'Офисное здание'
  | 'Бизнес-парк'
  | 'Офисно-производственный комплекс'
  | 'Офисно-складской комплекс'
  | 'Складской комплекс'
  | 'Производственно-складской комплекс'
  | 'Другое'
  | 'Логистический центр'
  | 'Склад'
  | 'Административное здание'
  | 'Индустриальный парк'
  | 'Логистический комплекс'
  | 'Торгово-деловой комплекс'
  | 'Многофункциональный комплекс'
  | 'Офисно-жилой комплекс'
  | 'Торгово-офисный комплекс'
  | 'Офисно-гостиничный комплекс'
  | 'Деловой центр'
  | 'Особняк'
  | 'Технопарк'
  | 'Бизнес-квартал'
  | 'Отдельно стоящее здание'
  | 'Жилой дом'
  | 'Жилой комплекс'
  | 'Торговый центр'
  | 'Специализированный торговый центр'
  | 'Торгово-общественный центр'
  | 'Аутлет'
  | 'Производственный комплекс'
  | 'Промплощадка'
  | 'Производственный цех'
  | 'Объект свободного назначения'
  | 'Имущественный комплекс'
  | 'Производственное здание'
  | 'Модульное здание'
  | 'Свободное'
  | 'Офисно-складское'
  | 'Нежилой фонд'
  | 'Жилой фонд'
  | 'Коворкинги';

export interface ISimilar {
  count?: number;
  url?: string;
}

export type IAllFromOffrep = ISimilar;

export type TOfferBSCenterType = 'businessCenter' | 'shoppingCenter';

export type TCommercialClass = 'aPlus' | 'a' | 'bPlus' | 'b' | 'bMinus' | 'cPlus' | 'c' | 'd';

export const CommercialClassName = new Map<TCommercialClass, string>([
  ['aPlus', 'A+'],
  ['a', 'A'],
  ['bPlus', 'B+'],
  ['b', 'B'],
  ['bMinus', 'B-'],
  ['cPlus', 'C+'],
  ['c', 'C'],
  ['d', 'D'],
]);

export interface IOfferBSCenter {
  buildingClassType: TCommercialClass;
  fromRepresentative: boolean;
  fullUrl: string;
  name: string;
  parentId: string;
  parentName: string;
  parentUrl: string;
  type: TOfferBSCenterType;
  useParentName: boolean;
}

export type TDealType = 'rent' | 'sale' | 'daily';
export type TOfferType = 'flat' | 'suburban' | 'commercial';

export interface IPessimizationDetails {
  isRecidivist?: boolean;
  isExcludedFromAction?: boolean;
}

export interface IWorkTimeInfo {
  timezoneText: string;
  callFrom: string;
  callTo: string;
  isAvailableToCall: boolean;
}

export type TVillageClass = 'economy' | 'comfort' | 'business' | 'elite';

export interface IVillageCounterSet {
  total: number;
  onSale: number;
  sold: number;
  underConstruction: number;
}

export type TRosreestrCheckStatus =
  // Проверка на стороне росреестра
  | 'inProgress'
  // Проверка прошла успешно
  | 'success'
  // Проверка прошла неуспешно
  | 'fail';

export enum EBrandingLevel {
  FirstLevel = 'firstLevel',
  SecondLevel = 'secondLevel',
  ThirdLevel = 'thirdLevel',
}

export type TUserOfferId = number | undefined;

export interface IOfferServices {
  isTop3: boolean | null;
  isPremium: boolean | null;
  isColorized: boolean | null;
  isPaid: boolean | null;
  isAuction: boolean | null;
}

export interface IAuction {
  /** Возможность изменения ставки */
  isEditable: boolean;
  /** Объявление текущего пользователя или сабаккаунта */
  isOwn: boolean;
  /** Текущая ставка */
  currentBet: number;
  /** ID объявления текущего пользователя ниже на странице */
  userOfferId?: TUserOfferId;
  /** Сервисы объявления пользователя на странице выдачи, ниже текущего объявления */
  userOfferServices?: IOfferServices;
  /** Предыдущая ставка на объявление(возвращается в случае понижения ставки) */
  nextBet?: number | null;
  /** Дата применения новой ставки(возвращается в случае понижения ставки) */
  applyNewBetDate?: string | null;
  /** Максимально доступная ставка */
  maxAvailableBet: number;
}

export enum ECoworkingOfferType {
  /** Гибкий офис */
  Office = 'office',
  /** Закрепленное рабочее место */
  FixedWorkplace = 'fixedWorkplace',
  /** Незакрепленное рабочее место */
  FlexibleWorkplace = 'flexibleWorkplace',
  /** Конференц-зал */
  ConferenceHall = 'conferenceHall',
  /** Переговорная комната */
  MeetingRoom = 'meetingRoom',
}

export interface ICoworkingWorkTime {
  /** Работает с */
  timeFrom: string;
  /** Работает до */
  timeTo: string;
}

export interface ICoworkingAmenity {
  key: string;
  title: string;
}

export interface ICoworking {
  /** Ссылка на коворкинг */
  url?: string;
  /** Название */
  name?: string;
  /** Доступ - С понедельника по пятницу | Ежедневно */
  accessType?: 'weekdays' | 'daily';
  /** Круглосуточно */
  isRoundTheClock?: boolean;
  /** Рабочее время */
  workTime?: ICoworkingWorkTime;
  /** Удобства */
  includedAmenities?: ICoworkingAmenity[];
  /** Чисто удобств */
  moreAmenitiesCount?: number;
}

export type IOffer = IOfferReal;

export enum ECianProfileStatus {
  Approved = 'approved',
  Hide = 'hide',
}

export interface ICountrysidePromoItem {
  type: 'sale' | 'mortgage' | 'bonus';
  id: number;
  name: string;
  label: string;
  endDate: string;
  description?: string;
}
