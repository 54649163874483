import { EQuestionActionsTypes, IStep } from '@cian/frontend-request-form-widget';

import { array, number, object, ref, string } from 'yup';

import { EBuildingClassType } from 'shared/json_query';

import { ExtendedFieldType } from '../extendedFieldType';

import { DEAL_DATE_STEP } from './dealDate';

export const COMMON_OBJECT_STEP: IStep = {
  code: 'commonObjectPage',
  question: {
    code: 'commonObjectQuestion',
    title: 'Укажите параметры объекта',
    fields: [
      {
        name: 'geo',
        type: ExtendedFieldType.GeoSuggest,
        label: 'Местоположение',
        placeholder: 'Город, адрес, метро, район или шоссе',
      },
      {
        type: ExtendedFieldType.Price,
        name: 'priceFrom',
        unit: '₽',
        prefix: 'от',
        label: 'Цена',
        size: {
          xs: 12,
          m: 6,
        },
      },
      {
        type: ExtendedFieldType.Price,
        name: 'priceTo',
        unit: '₽',
        prefix: 'до',
        label: '\u00A0',
        size: {
          xs: 12,
          m: 6,
        },
      },
      {
        type: ExtendedFieldType.Float,
        name: 'areaFrom',
        unit: 'м²',
        prefix: 'от',
        label: 'Площадь',
        size: {
          xs: 12,
          m: 6,
        },
      },
      {
        type: ExtendedFieldType.Float,
        name: 'areaTo',
        unit: 'м²',
        prefix: 'до',
        label: '\u00A0',
        size: {
          xs: 12,
          m: 6,
        },
      },
      {
        type: ExtendedFieldType.CheckboxButtonGroup,
        name: 'objectClass',
        label: 'Класс объекта',
        answers: [
          {
            label: ' A+',
            value: String(EBuildingClassType.APlus),
          },
          {
            label: 'A',
            value: String(EBuildingClassType.A),
          },
          {
            label: 'B+',
            value: String(EBuildingClassType.BPlus),
          },
          {
            label: 'B',
            value: String(EBuildingClassType.B),
          },
          {
            label: 'B-',
            value: String(EBuildingClassType.BMinus),
          },
          {
            label: 'C',
            value: String(EBuildingClassType.C),
          },
        ],
      },
    ],
    actions: [{ type: EQuestionActionsTypes.NextQ, text: 'Далее' }],
    validation: object().shape(
      {
        geo: string().when(['areaFrom', 'areaTo', 'priceFrom', 'priceTo', 'objectClass'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: string().required('Укажите значение поля'),
          otherwise: string(),
        }),
        areaFrom: number().when(['geo', 'areaTo', 'priceFrom', 'priceTo', 'objectClass'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: number().required('Укажите значение поля'),
          otherwise: number(),
        }),
        areaTo: number()
          .when(['geo', 'areaFrom', 'priceFrom', 'priceTo', 'objectClass'], {
            is: (...fields: unknown[]) => fields.every(field => field == null),
            then: number().required('Укажите значение поля'),
          })
          .when('areaFrom', {
            is: (areaFrom: number) => areaFrom != null,
            then: number().min(ref('areaFrom'), 'Некорректно указано значение'),
          }),
        priceFrom: number().when(['geo', 'areaFrom', 'areaTo', 'priceTo', 'objectClass'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: number().required('Укажите значение поля'),
          otherwise: number(),
        }),
        priceTo: number()
          .when(['geo', 'areaFrom', 'areaTo', 'priceFrom', 'objectClass'], {
            is: (...fields: unknown[]) => fields.every(field => field == null),
            then: number().required('Укажите значение поля'),
            otherwise: number(),
          })
          .when('priceFrom', {
            is: (priceFrom: number) => priceFrom != null,
            then: number().min(ref('priceFrom'), 'Некорректно указано значение'),
          }),
        objectClass: array().when(['geo', 'areaFrom', 'areaTo', 'priceFrom', 'priceTo'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: array().required('Укажите значение поля'),
        }),
      },
      [
        ['geo', 'areaFrom'],
        ['geo', 'areaTo'],
        ['geo', 'priceFrom'],
        ['geo', 'priceTo'],
        ['areaFrom', 'areaTo'],
        ['priceFrom', 'priceTo'],
        ['priceFrom', 'areaFrom'],
        ['priceFrom', 'areaTo'],
        ['areaTo', 'priceTo'],
        ['areaFrom', 'priceTo'],
        ['objectClass', 'geo'],
        ['objectClass', 'areaFrom'],
        ['objectClass', 'areaTo'],
        ['objectClass', 'priceFrom'],
        ['objectClass', 'priceTo'],
      ],
    ),
  },
  nextStep: [
    {
      conditions: [],
      nextStepCode: DEAL_DATE_STEP.code,
    },
  ],
};
