import { EBuildingCraneType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBuildingCraneTypesTagType = 'advanced-buildingCrane';

export interface IBuildingCraneTypesTag {
  type: 'advanced-buildingCrane';
  value: EBuildingCraneType;
}

const isBuildingCraneTypesTag = (tag: TTag): tag is IBuildingCraneTypesTag => tag.type === IBuildingCraneTypesTagType;

export const buildingCraneTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.building_cranage_types_type && jsonQuery.building_cranage_types_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IBuildingCraneTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EBuildingCraneType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IBuildingCraneTypesTag => isBuildingCraneTypesTag(tag) && tag.value === value,
            () => ({ type: IBuildingCraneTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
