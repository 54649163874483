import { Paragraph1 } from '@cian/ui-kit';

import classNames from 'classnames';
import * as React from 'react';

import { imagesByValue } from './images/imagesMapper';

import * as styles from './styles.css';

export interface IOneChoiceSwitcher {
  value: string;
  options: { label: string; value: string }[];
  onClick(value: string): void;
}

export const OneChoiceSwitcher: React.FC<IOneChoiceSwitcher> = props => {
  const { onClick, options, value } = props;

  const handleClick = (value: string) => () => onClick(value);

  return (
    <div className={styles['root']}>
      {options.map((option, index) => (
        <div
          className={classNames(styles['item'], { [styles['active']]: option.value === value })}
          key={index}
          onClick={handleClick(option.value)}
        >
          <div className={styles['label']}>
            <Paragraph1>{option.label}</Paragraph1>
          </div>
          <img alt={option.label} height={40} src={imagesByValue[option.value]} width={40} />
        </div>
      ))}
    </div>
  );
};

OneChoiceSwitcher.displayName = 'OneChoiceSwitcher';
