export interface IComparisonNotification {
  text: string;
  category: EComparisonNotificationCategory;
  offerId: number;
  url?: string | null;
}

export enum EComparisonNotificationCategory {
  Add = 'add',
  Limit = 'limit',
  Remove = 'remove',
}

export const SetComparisonNotificationType = 'comparisonNotification/SET_COMPARISON_NOTIFICATION';
export const ClearComparisonNotificationType = 'comparisonNotification/CLEAR_COMPARISON_NOTIFICATION';

export interface ISetComparisonNotificationAction {
  type: 'comparisonNotification/SET_COMPARISON_NOTIFICATION';
  text: string;
  category: EComparisonNotificationCategory;
  offerId: number;
  url?: string | null;
}

export interface IClearComparisonNotificationAction {
  type: 'comparisonNotification/CLEAR_COMPARISON_NOTIFICATION';
}

export type TComparisonNotificationActions = ISetComparisonNotificationAction | IClearComparisonNotificationAction;
