interface IData {
  rightSlidePosition: number;
  visibleSlidesCount: number;
  step: number;
  slidesCount: number;
}

interface INextRightPosition {
  rightSlidePosition: number;
  leftSlidePosition: number;
  groupPosition: number;
  rightSlidePositionInsideGroup: number;
}

export function computeNextRightSlidePosition({
  rightSlidePosition: currentRightSlidePosition,
  visibleSlidesCount,
  step,
  slidesCount,
}: IData): INextRightPosition {
  const maxRightSlidePosition = slidesCount - 1;
  const rightSlidePosition = Math.min(currentRightSlidePosition + step, maxRightSlidePosition);
  const leftSlidePosition = rightSlidePosition - visibleSlidesCount + 1;
  const groupPosition = Math.floor(rightSlidePosition / visibleSlidesCount);
  const rightSlidePositionInsideGroup = rightSlidePosition - groupPosition * visibleSlidesCount;

  return {
    rightSlidePosition,
    leftSlidePosition,
    groupPosition,
    rightSlidePositionInsideGroup,
  };
}
