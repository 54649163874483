import * as React from 'react';

export const CheckIcon: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg height="16" width="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="#2E9E00">
      <path d="M14.073 2.8L7.8 9.073l-1.927-2.2a1.094 1.094 0 1 0-1.637 1.454l2.71 3.073c.2.218.49.364.781.364h.037c.29 0 .563-.11.763-.328l7.091-7.09a1.109 1.109 0 0 0 0-1.546 1.109 1.109 0 0 0-1.545 0z" />
      <path d="M14.91 6.91c-.6 0-1.092.49-1.092 1.09 0 .255-.018.51-.054.764-.364 2.872-2.855 5.054-5.764 5.054-3.2 0-5.818-2.618-5.818-5.818C2.182 4.8 4.8 2.182 8 2.182c1.036 0 2.055.273 2.927.8a1.091 1.091 0 0 0 1.491-.382 1.091 1.091 0 0 0-.382-1.49A7.885 7.885 0 0 0 8 0a8 8 0 0 0 0 16c4 0 7.418-2.982 7.927-6.964A9.73 9.73 0 0 0 16 8c0-.6-.49-1.09-1.09-1.09z" />
    </g>
  </svg>
);
