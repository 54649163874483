import { EElevators } from 'shared/json_query';

import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IElevatorsMinCountTagType = 'advanced-elevators-minCount';

export interface IElevatorsMinCountTag {
  type: 'advanced-elevators-minCount';
  value: EElevators;
}

export const isElevatorsMinCountTag = (tag: TTag): tag is IElevatorsMinCountTag =>
  tag.type === IElevatorsMinCountTagType;

export const elevatorsMinCountTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.lifts && jsonQuery.lifts.value;

    if (value !== undefined && value !== EElevators.Any) {
      return ensureSingleTagExists(tags, isElevatorsMinCountTag, () => ({
        type: IElevatorsMinCountTagType,
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isElevatorsMinCountTag);
    }
  },
};
