import * as React from 'react';

import * as styles from './SummarySection.css';

export interface ISummarySectionProps {
  leftColumn: React.ReactNode;
  rightColumn?: React.ReactNode;
}

export function SummarySection({ leftColumn, rightColumn }: ISummarySectionProps) {
  return (
    <div className={styles['section']}>
      <div className={styles['left-column']}>{leftColumn}</div>
      <div className={styles['right-column']}>{rightColumn}</div>
    </div>
  );
}

SummarySection.displayName = 'SummarySection';
