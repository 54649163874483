import { IAdFoxBannerContext } from '@cian/adfox/build/react';

import { Component } from 'react';

const style = require('./index.css');
const BACKGROUND_MAX_HEIGHT = 740;

export interface IParallaxBannerProps {
  context: IAdFoxBannerContext;
}

export interface IParallaxBannerState {
  bgPosition: number;
}

export class ParallaxBanner extends Component<IParallaxBannerProps, IParallaxBannerState> {
  private container: HTMLDivElement | null = null;
  private animatedBg: HTMLDivElement | null = null;
  private topPosition: number;
  private bottomPosition: number;
  private containerHeight: number;
  private scrollHandler: () => void;
  private resizeHandler: () => void;

  public constructor(props: IParallaxBannerProps) {
    super(props);

    this.state = { bgPosition: 0 };
  }

  public componentDidMount() {
    if (this.container) {
      let shouldChange = false;
      this.containerHeight = this.container.offsetHeight;

      const scrollHandler = () => {
        if (!shouldChange) {
          return;
        }
        shouldChange = false;
        if (window.scrollY > this.topPosition && window.scrollY < this.bottomPosition && this.animatedBg) {
          const bgOffset = (window.scrollY - this.topPosition) / (window.innerHeight + BACKGROUND_MAX_HEIGHT);
          const translate = bgOffset * (this.containerHeight - BACKGROUND_MAX_HEIGHT);

          this.animatedBg.style.transform = `translateY(${Math.round(translate)}px)`;
        }
      };

      const img = new Image();

      this.scrollHandler = () => {
        shouldChange = true;
        requestAnimationFrame(scrollHandler);
      };

      this.resizeHandler = () => {
        this.getParams();
        requestAnimationFrame(scrollHandler);
      };

      img.onload = () => {
        this.getParams();
        this.setState({ bgPosition: window.innerHeight - BACKGROUND_MAX_HEIGHT });

        window.addEventListener('scroll', this.scrollHandler);
        window.addEventListener('resize', this.resizeHandler);
      };

      img.src = this.props.context.bg;
    }
  }

  public componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('resize', this.resizeHandler);
  }

  public render() {
    const { bg, img, url, annotation } = this.props.context;

    return (
      <a href={url} rel="noreferrer" target="_blank">
        <div
          className={style['parallaxContainer']}
          ref={container => {
            this.container = container;
          }}
        >
          <div
            className={style['parallaxBg']}
            ref={animatedBg => {
              this.animatedBg = animatedBg;
            }}
            style={{
              backgroundImage: `url(${bg})`,
            }}
          />
          <div className={style['imageContent']} style={{ backgroundImage: `url(${img})` }}></div>
          {annotation && <div className={style['annotation']}>{annotation}</div>}
        </div>
      </a>
    );
  }

  private getParams() {
    if (this.container) {
      this.topPosition = this.container.offsetTop - window.innerHeight;
      this.bottomPosition = this.topPosition + this.container.offsetHeight + window.innerHeight;
    }
  }
}
