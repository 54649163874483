import { attachReducers } from 'shared/common/state/attach_reducers';
import { attachSagas } from 'shared/common/state/attach_sagas';

import { createApplicationState } from '../applicationState';
import { renderApplication } from '../renderApplication';

import { createContext } from './createContext';
import { legacyInitBeforeHydration } from './legacyInitialization';

export function runApp() {
  const context = createContext();
  const store = createApplicationState(context);

  legacyInitBeforeHydration(context, store);

  attachReducers(store);
  attachSagas(store);

  renderApplication({ store, context });
}
