import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IIsSemibasementFloorTagType = 'advanced-floor-isSemibasementFloor';

export interface IIsSemibasementFloorTag {
  type: 'advanced-floor-isSemibasementFloor';
  value: boolean;
}

function isSemibasementFloorTag(tag: TTag): tag is IIsSemibasementFloorTag {
  return tag.type === IIsSemibasementFloorTagType;
}

export const isSemibasementFloorTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.is_semibasement && jsonQuery.is_semibasement.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isSemibasementFloorTag, () => ({
        type: IIsSemibasementFloorTagType,
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isSemibasementFloorTag);
    }
  },
};
