import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IIncludeNewMoscowTagType = 'IncludeNewMoscow';

export interface IIncludeNewMoscowTag {
  type: 'IncludeNewMoscow';
  value: boolean;
}

function isIncludeNewMoscowTag(tag: TTag): tag is IIncludeNewMoscowTag {
  return tag.type === IIncludeNewMoscowTagType;
}

export const includeNewMoscowTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.include_new_moscow && jsonQuery.include_new_moscow.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isIncludeNewMoscowTag, () => ({
        type: IIncludeNewMoscowTagType,
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isIncludeNewMoscowTag);
    }
  },
};
