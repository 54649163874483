import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../repositories/common/json_query';

export function trackOfferCardShow({
  position,
  offerId,
  jsonQuery,
}: {
  position: number;
  offerId: number;
  jsonQuery: IJsonQuery;
}): void {
  ca('teaser', {
    product: {
      block: 'Listing_snippet',
      extra: {
        offerType: 'offer',
      },
      id: offerId,
      name: 'show_card',
      position,
      screen: 'Listing',
    },
    sc: jsonQuery,
  });
}
