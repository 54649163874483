import { mergeStyles } from '@cian/utils';

import autosize from 'autosize';
import * as React from 'react';

const style = require('./index.css');
const containerStyle = require('../index.css');

interface ICommentProps {
  label: string;
  value?: string;

  editing: boolean;
  disabled: boolean;
  isSimplified?: boolean;

  onDelete(): void;
  onChange(value: string): void;
  onFocus(): void;
  onKeyDown(e: React.KeyboardEvent<EventTarget>): void;
}

export class Comment extends React.Component<ICommentProps> {
  private textarea: HTMLTextAreaElement | null = null;

  public getTextfield() {
    return this.textarea;
  }

  public componentDidMount() {
    if (this.textarea) {
      autosize(this.textarea);
    }
  }

  public componentWillUnmount() {
    if (this.textarea) {
      autosize.destroy(this.textarea);
    }
  }

  public componentDidUpdate(prevProps: ICommentProps) {
    if (this.props.value !== prevProps.value && this.textarea) {
      autosize.update(this.textarea);
    }
  }

  public render() {
    const { label, value, editing, disabled, onFocus, isSimplified, onKeyDown } = this.props;

    return (
      <div
        {...mergeStyles(
          style['container'],
          value && value.length > 0 && style['container--notEmpty'],
          isSimplified && style['simplified'],
        )}
      >
        <div {...mergeStyles(style['comment'], value && value.length > 0 && style['comment--notEmpty'])}>
          {value && value.length > 0 && <div className={style['comment-label']}>{label}</div>}
          <textarea
            className={style['comment-textarea']}
            disabled={disabled}
            maxLength={255}
            placeholder={isSimplified ? '' : label}
            ref={textarea => (this.textarea = textarea)}
            rows={1}
            value={value}
            onChange={this.handleChange}
            onFocus={onFocus}
            onKeyDown={onKeyDown}
          />
        </div>
        {!editing && value && value.length > 0 && (
          <div {...mergeStyles(style['comment-delete'], containerStyle['comment-delete'])}>
            <button className={style['comment-deleteButton']} onClick={this.props.onDelete}>
              {/* eslint-disable-next-line react/forbid-elements */}
              <svg height="14px" viewBox="0 0 12.728 12.728" width="14px" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.414 0L0 1.414l4.95 4.95L0 11.314l1.414 1.414 4.95-4.95 4.95 4.95 1.414-1.414-4.95-4.95 4.95-4.95L11.314 0l-4.95 4.95L1.414 0z"
                  fill="currentColor"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    );
  }

  private handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    this.props.onChange(value.replace('\n', ' '));
  };
}
