import { ca } from '@cian/analytics';

export function trackAddressClick(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_address',
    label,
  });
}
