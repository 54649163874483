import { IAppState, TActions } from '../../../common/state';

import { COMMERCIAL_SAVE_SEARCH_MODAL_CLOSED, COMMERCIAL_SAVE_SEARCH_MODAL_OPENED } from './actions';

export const commercialSaveSearchModalReducer = (state: IAppState, action: TActions) => {
  switch (action.type) {
    case COMMERCIAL_SAVE_SEARCH_MODAL_OPENED:
      return {
        ...state,
        isCommercialSaveSearchModalOpened: true,
      };

    case COMMERCIAL_SAVE_SEARCH_MODAL_CLOSED:
      return {
        ...state,
        isCommercialSaveSearchModalOpened: false,
      };

    default:
      return state;
  }
};
