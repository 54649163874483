import { ca } from '@cian/analytics';

interface IParams {
  offerId: number;
  position: number;
}

export function trackNotificationSaveToFolder(params: IParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'Click',
    label: 'add_to_collection',
    products: [
      {
        id: params.offerId,
        offerType: 'offer',
        position: params.position,
      },
    ],
  });
}

export function trackNotificationChangeFolder(params: IParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'Click',
    label: 'change_collection',
    products: [
      {
        id: params.offerId,
        offerType: 'offer',
        position: params.position,
      },
    ],
  });
}
