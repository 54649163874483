import cn from 'classnames';
import * as PropTypes from 'prop-types';
import { Component } from 'react';

import { getAgentLevelInfo } from 'shared/utils/getAgentLevelInfo';
import { showAgentLevel } from 'shared/utils/showAgentLevel';

import { ECianProfileStatus } from '../../types/offer';
import { CommercialOwner } from '../../utils/CommercialOwner';
import { AgentLevelView } from '../AgentLevelView';
import { AgentWorkHonestlyProofs, showAgentHonestlyProofs } from '../AgentWorkHonestlyProofs';
import { PartnerTooltip } from '../PartnerTooltip';
import { RosreestrVerified } from '../RosreestrVerified';

import { IconBlacklist, IconWhitelist } from './icons';

import { IUserInfoContext, TUserInfoType } from './index';

import style from './index.css';

export class UserInfoHorizontal extends Component {
  public context: IUserInfoContext;

  public static contextTypes: Record<keyof UserInfoHorizontal['context'], PropTypes.Requireable<unknown>> = {
    offer: PropTypes.object,
    offerHelper: PropTypes.object,
    user: PropTypes.object,
    commercialOwner: CommercialOwner.contextTypes,
    abUseExperiments: PropTypes.arrayOf(
      PropTypes.shape({ experimentName: PropTypes.string, string: PropTypes.string }),
    ),
  };

  public static defaultProps = {
    type: 'vertical' as TUserInfoType,
  };

  public render() {
    const { offer, offerHelper, commercialOwner } = this.context;
    const { isByHomeowner } = offer;

    const userPersonalRating = offerHelper.presenters.getUserPersonalRating();
    const userId = offerHelper.presenters.getCianUserId();
    const agencyName = offerHelper.presenters.getAgentName();
    const isRepresentative = !!(offer.businessShoppingCenter && offer.businessShoppingCenter.fromRepresentative);
    const isAgentNameShow = offer.user && offer.user.cianProfileStatus === ECianProfileStatus.Approved;
    const isCianPartner = offer.user && offer.user.isCianPartner;

    const userLevelInfo = getAgentLevelInfo(offer);

    return (
      <div className={style['userInfoHorizontalContainer']}>
        {isAgentNameShow && (
          <span className={`${style['userInfo-name']} ${style['userInfo-name--horizontal']}`}>
            <noindex>{agencyName || `ID ${userId}`}</noindex>
          </span>
        )}

        <span className={`${style['userInfo-badges']} ${style['userInfo-badges--horizontal']}`}>
          {showAgentLevel(offer) && !!userLevelInfo && (
            <div
              className={cn(style['userInfo-badge'], style['userInfo-badge--horizontal'])}
              data-testid="honest-realtor-new-commercial-wrapper"
            >
              <AgentLevelView extendedView agentLevelInfo={userLevelInfo} />
            </div>
          )}

          {showAgentHonestlyProofs(offer) && (
            <div
              className={cn(style['userInfo-badge'], style['userInfo-badge--horizontal'])}
              data-testid="agent-work-honestly-proofs-wrapper"
            >
              <AgentWorkHonestlyProofs offer={offer} />
            </div>
          )}

          {commercialOwner?.showRosreestrVerified && (
            <div className={style['rosreestr-verified-wrapper']} data-testid="rosreestr-verified-wrapper">
              <RosreestrVerified />
            </div>
          )}

          {isCianPartner && (
            <div className={`${style['userInfo-badge']} ${style['userInfo-badge--horizontal']}`}>
              <PartnerTooltip
                master={<div className={`${style['userInfo-textBadge']} ${style['greenLight']}`}>Партнёр</div>}
              />
            </div>
          )}

          {isByHomeowner && (
            <div className={`${style['userInfo-badge']} ${style['userInfo-badge--horizontal']}`}>
              <div className={`${style['userInfo-textBadge']}`}>Собственник</div>
            </div>
          )}

          {isRepresentative && (
            <div className={style['userInfo-badge']}>
              <div className={`${style['userInfo-textBadge']} ${style['greenLight']}`}>Оф. представитель</div>
            </div>
          )}

          {userPersonalRating === 'positive' && (
            <div className={`${style['userInfo-badge']} ${style['userInfo-badge--horizontal']}`}>
              <IconWhitelist isActive />
            </div>
          )}

          {userPersonalRating === 'negative' && (
            <div className={`${style['userInfo-badge']} ${style['userInfo-badge--horizontal']}`}>
              <IconBlacklist isActive />
            </div>
          )}
        </span>
      </div>
    );
  }
}
