import { useMemo } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { getUserEmail } from 'shared/selectors/user/getUserEmail';
import { getUserProfilingAvalablesEmails } from 'shared/utils/userProfilingAvalablesEmails';

import { ContentLayout } from './ContentLayout';
import {
  CONTENT_LAYOUT_SUBTITLES,
  CONTENT_LAYOUT_SUBTITLE_DEFAULT,
  CONTENT_LAYOUT_TITLE,
  CONTENT_LAYOUT_TITLE_DEFAULT,
} from './constants';

export const ContentLayoutContainer: React.VFC = () => {
  const userEmail = useSelector(getUserEmail);
  const availableUsersEmails = getUserProfilingAvalablesEmails();
  const userEmailPosition = availableUsersEmails.findIndex(email => email === userEmail);

  const [title, subtitle] = useMemo((): [string, string] => {
    if (!~userEmailPosition || userEmailPosition > CONTENT_LAYOUT_SUBTITLES.length - 1) {
      return [CONTENT_LAYOUT_TITLE_DEFAULT, CONTENT_LAYOUT_SUBTITLE_DEFAULT];
    }

    return [CONTENT_LAYOUT_TITLE, CONTENT_LAYOUT_SUBTITLES[userEmailPosition]];
  }, [userEmailPosition]);

  return <ContentLayout subtitle={subtitle} title={title} />;
};

ContentLayoutContainer.displayName = 'ContentLayoutContainer';
