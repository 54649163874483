/**
 * @todo Удалить строку с RS
 * @description Данный функционал появился в задаче CD-124247, будет удалён в задаче CD-124570
 * Игнорирование добавлено, так как из-за комментариев файл раздулся
 */
/* eslint-disable max-lines */

import { numberToPrettyString } from '@cian/utils';

import { EReadyBusiness, IOffer, TOfferCategory } from 'shared/repositories/search-offers.legacy/v2/types';

import { CommercialClassName, ECoworkingOfferType, LandAreaUnitType } from '../types/offer';

export interface ICommercialParams {
  isOfficeSearch?: boolean;
  isOfficeInCoworkingTitleVisible?: boolean;
  isFlexOfficeCardSameAsOffice?: boolean;
}

export interface ICommercialHelper {
  getBuildingClassType(): string | void;
  getBuildingType(): string;
  getCommercialLandArea(): string | number | null;
  getCommercialLandPerMeterByYear(): string | null;
  getCommercialLandUnit(): ICommercialLandUnit | null;
  getCommercialLandUnitType(): LandAreaUnitType | null;
  getMinArea(): string | void;
  getSpecialty(): string[];
  getType(params: ICommercialParams): string;
  getVatTypeWithVatPrice(): string | void;
  isCommercialLand(): boolean;
  isReadyBusiness(): boolean;
  getMultiAdsAreaPartType(): string;
}

/**
 * @todo Удалить параметр функции с RS
 * @description Данный функционал появился в задаче CD-143093, будет удалён в задаче CD-143213
 */
export function commercialHelper(offer: IOffer, isCommercialNewVatTextEnabled?: boolean): ICommercialHelper {
  return {
    getBuildingClassType: () => getBuildingClassType(offer),
    getBuildingType: () => getBuildingType(offer),
    getCommercialLandArea: () => getCommercialLandArea(offer),
    getCommercialLandPerMeterByYear: () => getCommercialLandPerMeterByYear(offer),
    getCommercialLandUnit: () => getCommercialLandUnit(offer),
    getCommercialLandUnitType: () => getCommercialLandUnitType(offer),
    getMinArea: () => getMinArea(offer),
    getSpecialty: () => getSpecialty(offer),
    getType: params => getType(offer, params),
    getVatTypeWithVatPrice: () => getVatTypeWithVatPrice(offer, isCommercialNewVatTextEnabled),
    isCommercialLand: () => isCommercialLand(offer),
    isReadyBusiness: () => isReadyBusiness(offer),
    getMultiAdsAreaPartType: () => getMultiAdsAreaPartType(offer),
  };
}

const buildingTypes: { [key: string]: string } = {
  administrativeBuilding: 'Административное здание',
  businessCenter: 'Бизнес-центр',
  businessCenter2: 'Деловой центр',
  businessPark: 'Бизнес-парк',
  businessQuarter: 'Бизнес-квартал',
  free: 'Свободное',
  freeAppointmentObject: 'Объект свободного назначения',
  industrialComplex: 'Производственный комплекс',
  industrialPark: 'Индустриальный парк',
  industrialSite: 'Промплощадка',
  industrialWarehouseComplex: 'Производственно-складской комплекс',
  logisticsCenter: 'Логистический центр',
  logisticsComplex: 'Логистический комплекс',
  mansion: 'Особняк',
  manufactureBuilding: 'Производственное здание',
  manufacturingFacility: 'Производственный цех',
  modular: 'Модульное здание',
  multifunctionalComplex: 'Многофункциональный комплекс',
  officeAndHotelComplex: 'Офисно-гостиничный комплекс',
  officeAndResidentialComplex: 'Офисно-жилой комплекс',
  officeAndWarehouse: 'Офисно-складское',
  officeAndWarehouseComplex: 'Офисно-складской комплекс',
  officeBuilding: 'Офисное здание',
  officeIndustrialComplex: 'Офисно-производственный комплекс',
  old: 'Старый фонд',
  outlet: 'Аутлет',
  propertyComplex: 'Имущественный комплекс',
  residentialComplex: 'Жилой комплекс',
  residentialFund: 'Жилой фонд',
  residentialHouse: 'Жилой дом',
  shoppingAndBusinessComplex: 'Торгово-деловой комплекс',
  shoppingAndCommunityCenter: 'Торгово-общественный центр',
  shoppingAndEntertainmentCenter: 'Торгово-развлекательный центр',
  shoppingCenter: 'Торговый центр',
  specializedShoppingCenter: 'Специализированный торговый центр',
  standaloneBuilding: 'Отдельно стоящее здание',
  technopark: 'Технопарк',
  tradingOfficeComplex: 'Торгово-офисный комплекс',
  uninhabitedFund: 'Нежилой фонд',
  warehouse: 'Склад',
  warehouseComplex: 'Складской комплекс',
};

function getType(offer: IOffer, params: ICommercialParams) {
  return findTypeById(
    [
      createSearchType('office', () => getCommercialCoworkingTitle(offer, 'Офис', params)),
      createSearchType('shoppingArea', () => {
        if (isCommercialOfferMultiAds(offer)) {
          return 'Торговые площади';
        }

        return 'Торговая площадь';
      }),
      createSearchType('warehouse', () => {
        if (isCommercialOfferMultiAds(offer)) {
          return 'Склады';
        }

        return 'Склад';
      }),
      createSearchType('freeAppointmentObject', () => 'Своб. назнач.'),
      createSearchType('industry', () => {
        if (isCommercialOfferMultiAds(offer)) {
          return 'Помещения под производство';
        }

        return 'Помещение под производство';
      }),
      createSearchType('publicCatering', () => 'Общепит'),
      createSearchType('manufacture', () => {
        if (isCommercialOfferMultiAds(offer)) {
          return 'Производства';
        }

        return 'Производство';
      }),
      createSearchType('autoService', () => 'Автосервис'),
      createSearchType('business', () => getBusinessLabel(offer)),
      createSearchType('building', () => 'Здание'),
      createSearchType('domesticServices', () => 'Бытовые услуги'),
      createSearchType('commercialLand', () => 'Коммерческая земля'),
      createSearchType('garage', () => {
        if (!offer.garage) {
          return 'Гараж';
        }

        switch (offer.garage.type) {
          case 'box':
            return 'Бокс';
          case 'garage':
            return 'Гараж';
          case 'parkingPlace':
            return 'Машиноместо';
          default:
            return '';
        }
      }),
    ],
    offer,
  );
}

/**
 * Только для коммерческих мультиобъявок.
 * Мы выводим в табличке слотов тип помещения - данная утилита предоставляет корректное форматирование такого типа.
 * В мультиобъявках участвуют только перечисленные типы объектов.
 */
function getMultiAdsAreaPartType(offer: IOffer) {
  return findTypeById(
    [
      createSearchType('office', () => 'Офис'),
      createSearchType('shoppingArea', () => 'Торговая площадь'),
      createSearchType('warehouse', () => 'Склад'),
      createSearchType('freeAppointmentObject', () => 'Своб. назнач.'),
      createSearchType('industry', () => 'Под производство'),
      createSearchType('manufacture', () => 'Производство'),
    ],
    offer,
  );
}

interface IOfferSearchType {
  getRightName(): string;
  name: string;
}

function createSearchType(name: string, getRightName: () => string): IOfferSearchType {
  return {
    getRightName,
    name,
  };
}

function findTypeById(values: IOfferSearchType[], offer: IOffer) {
  return values.reduce((acc, item) => {
    if (offer.category === item.name + 'Rent') {
      return item.getRightName();
    } else if (offer.category === item.name + 'Sale') {
      return item.getRightName();
    }

    return acc;
  }, '');
}

function getSpecialty(offer: IOffer) {
  if (offer.specialty && offer.specialty.specialties) {
    return offer.specialty.specialties.reduce((acc: string[], value) => {
      if (value.rusName && acc.length < 2) {
        acc.push(value.rusName.toLowerCase());
      }

      return acc;
    }, []);
  }

  return [];
}

function getBuildingType(offer: IOffer) {
  if (offer.building && offer.building.type) {
    const type = buildingTypes[offer.building.type];

    return type ? type.toLowerCase() : '';
  }

  return '';
}

function getBuildingClassType(offer: IOffer) {
  if (offer.businessShoppingCenter && offer.businessShoppingCenter.buildingClassType) {
    return CommercialClassName.get(offer.businessShoppingCenter.buildingClassType);
  }

  return undefined;
}

function getMinArea(offer: IOffer): string | undefined {
  return offer.minArea ? offer.minArea.split('.')[0] : undefined;
}

function isCommercialLand(offer: IOffer) {
  return (
    !!offer.category && Array.of<TOfferCategory>('commercialLandRent', 'commercialLandSale').includes(offer.category)
  );
}

export interface ICommercialLandUnit {
  text: string;
  type: LandAreaUnitType;
}

function getCommercialLandUnit(offer: IOffer): ICommercialLandUnit | null {
  if (!offer.land || !offer.land.areaUnitType) {
    return null;
  }

  return {
    text: offer.land.areaUnitType === 'sotka' ? 'сот.' : 'га.',
    type: offer.land.areaUnitType,
  };
}

function getCommercialLandPerMeterByYear(offer: IOffer) {
  if (!offer.pricePerUnitAreaPerYearRur) {
    return null;
  }

  const unit = getCommercialLandUnit(offer);

  return `${numberToPrettyString(offer.pricePerUnitAreaPerYearRur)} руб. за ${unit ? unit.text : 'м\u00b2'} в год`;
}

function getCommercialLandArea(offer: IOffer) {
  return offer.land && offer.land.area ? offer.land.area : null;
}

function getCommercialLandUnitType(offer: IOffer) {
  return offer.land && offer.land.areaUnitType ? offer.land.areaUnitType : null;
}

function getVatPrice(to?: number | null, from?: number | null): string {
  if (to) {
    return `${numberToPrettyString(to)} руб.`;
  }

  if (from) {
    return `${numberToPrettyString(from)} руб.`;
  }

  return '';
}

/**
 * @todo Удалить параметр функции с RS
 * @description Данный функционал появился в задаче CD-143093, будет удалён в задаче CD-143213
 */
function getVatTypeWithVatPrice(offer: IOffer, isCommercialNewVatTextEnabled?: boolean): string | undefined {
  const { bargainTerms, minVatPriceTotalRur, vatPriceTotalRur, minVatPriceTotalPerMonthRur, vatPriceTotalPerMonthRur } =
    offer;
  const { vatType } = bargainTerms;

  switch (vatType) {
    case 'usn':
      return 'УСН';

    case 'included':
      switch (offer.dealType) {
        case 'sale':
          return `Включая НДС ${getVatPrice(vatPriceTotalRur, minVatPriceTotalRur)}`;
        case 'rent':
          return `Включая НДС ${getVatPrice(vatPriceTotalPerMonthRur, minVatPriceTotalPerMonthRur)}`;
        default:
          return 'Включая НДС';
      }

    case 'notIncluded':
      /**
       * @todo Удалить RS и оставить только "НДС не облагается"
       * @description Данный функционал появился в задаче CD-143093, будет удалён в задаче CD-143213
       */
      return isCommercialNewVatTextEnabled ? 'НДС не облагается' : 'Без НДС';

    default:
      return undefined;
  }
}

function isReadyBusiness(offer: IOffer): boolean {
  return Boolean(offer.readyBusinessType);
}

function getBusinessLabel(offer: IOffer): string {
  const { readyBusinessType } = offer;

  const businessTypeLabel = (() => {
    switch (readyBusinessType) {
      case EReadyBusiness.ReadyBusiness:
        return 'Готовый бизнес';
      case EReadyBusiness.RentalBusiness:
        return 'Арендный бизнес';
      default:
        return 'Готовый бизнес';
    }
  })();

  const [specialtyLabel] = getSpecialty(offer);

  if (businessTypeLabel && specialtyLabel) {
    const formattedSpecialtyLabel = specialtyLabel[0].toUpperCase() + specialtyLabel.slice(1);

    return `${businessTypeLabel} ⋅ ${formattedSpecialtyLabel}`;
  }

  return businessTypeLabel;
}

export function isCommercialOfferMultiAds(offer: IOffer) {
  return offer.areaParts && offer.areaParts.length !== 0;
}

const coworkingOfferTypeTitle = {
  flexOffice: 'Отдельный офис',
  fixedWorkplace: 'Закреплённое рабочее место',
  flexibleWorkplace: 'Незакреплённое рабочее место',
  conferenceHall: 'Конференц-зал',
  meetingRoom: 'Переговорная комната',
  office: 'Офис',
};

export function getCommercialCoworkingTitle(offer: IOffer, defaultTitle: string, params: ICommercialParams = {}) {
  const { coworkingOfferType } = offer;
  const {
    isOfficeSearch = false,
    isOfficeInCoworkingTitleVisible = false,
    isFlexOfficeCardSameAsOffice = false,
  } = params;

  if (isCommercialOfferMultiAds(offer)) {
    return 'Офисы';
  }

  if (!coworkingOfferType) {
    return defaultTitle;
  }

  if (coworkingOfferType === ECoworkingOfferType.Office) {
    if (isFlexOfficeCardSameAsOffice) {
      return defaultTitle;
    }

    if (isOfficeInCoworkingTitleVisible) {
      return 'Офис в коворкинге';
    }

    return isOfficeSearch ? coworkingOfferTypeTitle.office : coworkingOfferTypeTitle.flexOffice;
  }

  return coworkingOfferTypeTitle[coworkingOfferType] || defaultTitle;
}
