import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as React from 'react';

import { IAreaPart } from 'shared/repositories/search-offers.legacy/v2/types';

import { AlarmSVG } from './icn-alarm';

const style = require('./index.css');

export interface IDescriptionProps {
  containerStyle?: IStyleConfig;
  description: string;
  descriptionWordsHighlighted: [number, number][] | null;
  excludedFromActionStyle?: IStyleConfig;
  isExcludedFromAction?: boolean;
  isRecidivist?: boolean;
  recidivistStyle?: IStyleConfig;
  isBot: boolean;
  areaParts?: IAreaPart[];

  onComplainStateChanged(isReporting: boolean): void;
}

export const Description = (props: IDescriptionProps) => {
  const { description, descriptionWordsHighlighted, excludedFromActionStyle, isBot, recidivistStyle, areaParts } =
    props;

  let { isExcludedFromAction, isRecidivist } = props;

  if (isBot) {
    isRecidivist = false;
    isExcludedFromAction = false;
  }

  const descriptionNodes: React.ReactNode[] = [];
  let currentPosition = 0;

  const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.stopPropagation(); // не открываем объявление при клике на ссылку
  };

  const complain = (
    <span
      className={style['complaint']}
      onClick={() => {
        props.onComplainStateChanged(true);
      }}
    >
      оставьте жалобу
    </span>
  );

  const link = (
    <a className={style['link']} href="/shared/components/description" target="_blank" onClick={handleLinkClick}>
      Подробнее
    </a>
  );

  const recidivistDescription = (
    <span className={style['recidivistDescription']} key="recidivistDescription">
      Этот агент замечен в размещении недостоверных предложений. Возможно, это объявление вымышленное. Служба качества
      ЦИАН не рекомендует звонить по этому объявлению. Если вы позвонили, но объекта нет, {complain}. {link}
    </span>
  );

  const excludedFromActionDescription = (
    <span className={style['recidivistDescription']} key="excludedFromActionDescription">
      В объявлениях этого агента встречаются ошибки. Объект может отличаться от предложенного в объявлении. Уточняйте
      параметры объекта до просмотра. Оставьте жалобу, если обнаружите ошибку. {link}
    </span>
  );

  if (isRecidivist) {
    descriptionNodes.push(recidivistDescription);
  } else if (isExcludedFromAction) {
    descriptionNodes.push(excludedFromActionDescription);
  } else if (descriptionWordsHighlighted) {
    descriptionWordsHighlighted.forEach(range => {
      descriptionNodes.push(
        description.substring(currentPosition, range[0]),
        <span className={style['highlightedWord']} key={range.toString()}>
          {description.substring(range[0], range[1])}
        </span>,
      );
      currentPosition = range[1];
    });

    if (currentPosition < description.length) {
      descriptionNodes.push(description.substring(currentPosition));
    }
  } else {
    descriptionNodes.push(description);
  }

  const isAreaParts = areaParts && areaParts.length > 0;

  const containerStyle = mergeStyles(
    style['description'],
    isAreaParts && style['description-with-areaParts'],
    isRecidivist
      ? mergeStyles(style['recidivist'], excludedFromActionStyle)
      : isExcludedFromAction
        ? mergeStyles(style['excludedFromAction'], recidivistStyle)
        : undefined,
    props.containerStyle,
  );

  return (
    <div {...containerStyle}>
      {isRecidivist || isExcludedFromAction ? (
        <div className={style['svgContainer']}>
          <AlarmSVG />
        </div>
      ) : null}
      {descriptionNodes}
    </div>
  );
};
