import { numberToPrettyString } from '@cian/utils';

import { ECoworkingOfferType } from '../types/offer';

export function getFormattedCoworkingTitle(
  coworkingOfferType: ECoworkingOfferType,
  coworkingTitle: string,
  workplaceCount: number | null,
  priceForWorkplace: number | null,
): string {
  switch (coworkingOfferType) {
    case ECoworkingOfferType.FlexibleWorkplace:
    case ECoworkingOfferType.FixedWorkplace: {
      if (priceForWorkplace) {
        return `Рабочее место за ${numberToPrettyString(priceForWorkplace)} руб./мес.`;
      }

      return 'Рабочее место';
    }

    case ECoworkingOfferType.Office: {
      if (workplaceCount) {
        if (priceForWorkplace) {
          return `${coworkingTitle} на ${workplaceCount} чел., ${numberToPrettyString(
            priceForWorkplace,
          )} руб./мес. за рабочее место`;
        }

        return `${coworkingTitle} на ${workplaceCount} чел.`;
      }

      return coworkingTitle;
    }

    case ECoworkingOfferType.ConferenceHall:
    case ECoworkingOfferType.MeetingRoom:
    default:
      return coworkingTitle;
  }
}
