import { TooltipDesktop } from '@cian/ui-kit';

import classNames from 'classnames';

import { IUserLevelInfoSchema } from 'shared/repositories/search-offers.legacy/v2/types/UserLevelInfoSchema';

import { getTooltipContent, stopPropagation } from './utils';

import styles from './AgentLevelView.css';

interface IAgentLevelViewProps {
  agentLevelInfo: IUserLevelInfoSchema;
  extendedView?: boolean;
}

export function AgentLevelView({ agentLevelInfo, extendedView }: IAgentLevelViewProps) {
  const { levelName, levelOrder } = agentLevelInfo;

  if (!levelOrder || levelOrder <= 2) {
    return null;
  }

  return (
    <TooltipDesktop
      placement="left"
      theme="white"
      title={
        <div className={styles['tooltip']} onClick={stopPropagation}>
          <h4 className={styles['title']}>{levelName}</h4>
          <a
            className={styles['link']}
            href="https://hc.cian.ru/hc/ru/articles/360061362792"
            rel="noreferrer"
            target="_blank"
          >
            Участник программы «Работаю честно»
          </a>
          <div>{getTooltipContent(levelOrder)}</div>
        </div>
      }
    >
      <div className={styles['title-property-container']}>
        <div className={classNames(styles['title-icon'], styles[`title-icon-${levelOrder}`])} />
        {extendedView && <div className={styles['title-property']}>{levelName}</div>}
      </div>
    </TooltipDesktop>
  );
}
