import { IOffer } from '../offer';
import { IMakeRequest } from '../utils/request';

export type TAgentRating = 'negative' | 'positive' | 'neutral';

export const updateRating = (
  makeRequest: IMakeRequest,
  offer: IOffer,
  rating: TAgentRating,
): Promise<{ status: string }> => {
  if (!offer.user) {
    return Promise.reject(new Error('offer.user is null'));
  }

  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: `/ajax/${offer.dealType}/${offer.offerType}/realtor_ratings`,
    parameters: {
      action: 'vote',
      rid: offer.user.cianUserId,
      rating,
    },
    headers: [['Content-Type', 'application/x-www-form-urlencoded']],
    bodyAsEncodeString: true,
    method: 'POST',
  }).then((response: { status: string }) => {
    return response;
  });
};
