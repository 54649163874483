import { mergeStyles } from '@cian/utils/lib/shared/style';

import { Component } from 'react';

import * as styles from './index.css';

export interface ISuggestionAddressPathItem {
  currentPath: string | undefined;
  distance: number | undefined;
  text: string;
}

export class SuggestionAddressPathItem extends Component<ISuggestionAddressPathItem, {}> {
  public render() {
    return (
      <span {...mergeStyles(styles['container'], this.props.distance !== undefined && styles['container-active'])}>
        от {this.renderLink()} <span>{this.renderDistance()}</span>
      </span>
    );
  }

  private renderDistance = () => {
    const { distance } = this.props;

    if (distance !== undefined) {
      return distance > 0 ? `${String(distance).replace('.', ',')}\u00a0км` : 'менее 100 метров';
    }

    return null;
  };

  private renderLink = () => {
    const { currentPath, text } = this.props;

    if (currentPath) {
      return (
        <a href={currentPath} rel="noreferrer" target="_blank" onClick={e => e.stopPropagation()}>
          {text}
        </a>
      );
    }

    return this.props.text;
  };
}
