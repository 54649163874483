import { ISuggestionsDataSchema } from 'shared/repositories/geo-suggest/entities/entities/SuggestionsDataSchema';
import { IMappers } from 'shared/repositories/geo-suggest/v2/suggest/types';

import { IApplicationContext } from '../../types/applicationContext';

export enum EGeoSuggestStatus {
  CriticalError,
  Error,
  Pending,
  Success,
}

export interface IGeoSuggestResult {
  data: ISuggestionsDataSchema;
  message: string | null;
  status: EGeoSuggestStatus;
}

export type GeoSuggestDependencies = Pick<IApplicationContext, 'logger' | 'httpApi'>;

export type GeoSuggestMappers = IMappers<IGeoSuggestResult, IGeoSuggestResult>;
