import { Component } from 'react';

import { IOffer } from 'shared/offer';
import { IMakeRequest } from 'shared/utils/request';

import { addAdFoxBanner } from '../';
import { AdFoxBannerType } from '../templates';

const style = require('./index.css');

export interface ISeoTopBannersProps {
  baseUrl: string;
  type: AdFoxBannerType;
  offers: IOffer[];
  isAgent: boolean;
  makeRequest: IMakeRequest;
  shouldSendAdsEvent?: boolean;
  additionalPuids?: { [key: string]: string };
}

export class SeoTopBanners extends Component<ISeoTopBannersProps> {
  public render() {
    const banners = [];

    banners.push(this.getBanner(1), this.getBanner(2), this.getBanner(3));

    return <div className={style['container']}>{banners}</div>;
  }

  private getBanner = (id: number) => {
    return addAdFoxBanner({
      type: this.props.type,
      key: `tgb_in_seo-${id}`,
      makeRequest: this.props.makeRequest,
      offer: this.props.offers[0],
      additionalPuids: this.props.additionalPuids,
      isAgent: this.props.isAgent,
      isNew: true,
      shouldSendAdsEvent: this.props.shouldSendAdsEvent,
      width: '100%',
      minHeight: '200px',
    });
  };
}
