import { IAreaPart } from '../../../../../repositories/search-offers.legacy/v2/types';

import { getIsRent } from './getIsRent';
import { getPrice } from './getPrice';

export const getMinAreaPrice = (areas?: IAreaPart[], paymentPeriod?: string | null): string => {
  if (areas === undefined) {
    return '';
  }

  const sortedAreas = areas.sort((a, b) => (a.price && b.price ? a.price - b.price : 0));

  const minArea = sortedAreas[0];
  const isRent = getIsRent(paymentPeriod || '');

  return getPrice(isRent, minArea);
};
