export interface IOfferComparisonStatus {
  status: EStatus;
  description?: string | null;
}

export enum EStatus {
  Added = 'added',
  Available = 'available',
  Unavailable = 'unavailable',
}
