import { EContractType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IContractTypeTagType = 'advanced-contract-type';

export interface IContractTypeTag {
  type: 'advanced-contract-type';
  value: EContractType;
}

function isContractTypeTag(tag: TTag): tag is IContractTypeTag {
  return tag.type === IContractTypeTagType;
}

export const contractTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.contract && jsonQuery.contract.value;

    let nextTags = tags.filter(
      tag => tag.type !== IContractTypeTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EContractType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IContractTypeTag => isContractTypeTag(tag) && tag.value === value,
            () => ({ type: IContractTypeTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
