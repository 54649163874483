import { Button, UIHeading2, UIText1 } from '@cian/ui-kit';

import * as React from 'react';

import * as styles from './MultiAdsBanner.css';

interface IMultiAdsBannerProps {
  onClick: VoidFunction;
}

export const MultiAdsBanner: React.VFC<IMultiAdsBannerProps> = props => {
  return (
    <div className={styles['container']} onClick={props.onClick}>
      <div className={styles['content']}>
        <div className={styles['heading-wrapper']}>
          <UIHeading2>Создайте мультиобъявление</UIHeading2>
        </div>
        <UIText1>Добавьте сразу несколько площадей из одного здания и получите больше просмотров</UIText1>
        <div className={styles['button']}>
          <Button size="XS" theme="fill_primary">
            Создать
          </Button>
        </div>
      </div>
      <div className={styles['image-container']} />
    </div>
  );
};
