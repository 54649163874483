import { IJsonQuery } from '../json_query';

/**
 * Когда в jsonQuery есть параметры id_user и sort - предложения по какому-то конкретному агенту
 * и сортировка не по дефолту, убираем параметр region,
 * чтобы search-offers возвращал отсортированные предложения именно этого агента.
 */
export function getJsonQueryForSort(jsonQuery: IJsonQuery): IJsonQuery | undefined {
  if (jsonQuery.id_user && jsonQuery.sort) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { region, ...jsonQueryForSort } = jsonQuery;

    return jsonQueryForSort;
  }

  return undefined;
}
