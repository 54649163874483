import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IIsBasementFloorTagType = 'advanced-floor-isBasementFloor';

export interface IIsBasementFloorTag {
  type: 'advanced-floor-isBasementFloor';
  value: boolean;
}

function isBasementFloorTag(tag: TTag): tag is IIsBasementFloorTag {
  return tag.type === IIsBasementFloorTagType;
}

export const isBasementFloorTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.is_basement && jsonQuery.is_basement.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isBasementFloorTag, () => ({ type: IIsBasementFloorTagType, value }));
    } else {
      return ensureTagNotExists(tags, isBasementFloorTag);
    }
  },
};
