import { EAmenityType } from 'shared/repositories/common/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const AmenityTagType = 'advanced-amenity';

export interface IAmenityTag {
  type: 'advanced-amenity';
  value: EAmenityType;
}

const isAmenityTag = (tag: TTag): tag is IAmenityTag => tag.type === AmenityTagType;

export const amenityTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.amenity && jsonQuery.amenity.value;

    let nextTags = tags.filter(
      tag => tag.type !== AmenityTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EAmenityType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IAmenityTag => isAmenityTag(tag) && tag.value === value,
            () => ({ type: AmenityTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
