export type OkResponse<T> = {
  status: 'ok';
  data: T;
};

export type ValidationErrorResponse = {
  status: 'validationError';
  data: {
    message: string;
  };
};

export type RegularResponse<T> = OkResponse<T> | ValidationErrorResponse;

export function returnResponseDataIfOk<T>(res: RegularResponse<T>): T {
  if (res.status !== 'ok') {
    throw new Error(`${res.status}: ${res.data.message}`);
  }

  return res.data;
}
