import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IWithoutDepositTagType = 'advanced-rentTerms-withoutDeposit';

export interface IWithoutDepositTag {
  type: 'advanced-rentTerms-withoutDeposit';
}

function isWithoutDepositTag(tag: TTag): tag is IWithoutDepositTag {
  return tag.type === IWithoutDepositTagType;
}

export const withoutDepositTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.zalog && jsonQuery.zalog.value;

    if (value === false) {
      return ensureSingleTagExists(tags, isWithoutDepositTag, () => ({ type: IWithoutDepositTagType }));
    } else {
      return ensureTagNotExists(tags, isWithoutDepositTag);
    }
  },
};
