import { EHouseMaterial } from 'shared/json_query';

import { FOfferType, offerTypeFromJsonQuery } from '../../../../utils/category';
import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const flatNewMaterials: EHouseMaterial[] = [EHouseMaterial.Panel, EHouseMaterial.Brick, EHouseMaterial.Monolith];

export const suburbanMaterials: EHouseMaterial[] = [
  EHouseMaterial.Wood,
  EHouseMaterial.Brick,
  EHouseMaterial.Monolith,
  EHouseMaterial.Shield,
  EHouseMaterial.Wireframe,
  EHouseMaterial.AerocreteBlock,
  EHouseMaterial.GasSilicateBlock,
  EHouseMaterial.FoamConcreteBlock,
];

const IHouseMaterialTagType = 'houseMaterial';

export interface IHouseMaterialTag {
  type: 'houseMaterial';
  value: EHouseMaterial;
}

function isHouseMaterialTag(tag: TTag): tag is IHouseMaterialTag {
  return tag.type === IHouseMaterialTagType;
}

export const houseMaterialTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.house_material && jsonQuery.house_material.value;

    let nextTags = tags.filter(
      tag => tag.type !== IHouseMaterialTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EHouseMaterial) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IHouseMaterialTag => isHouseMaterialTag(tag) && tag.value === value,
            () => ({ type: IHouseMaterialTagType, value }),
          ),
        nextTags,
      );
    }

    const offerType = offerTypeFromJsonQuery(jsonQuery);

    if (offerType === FOfferType.FlatNew) {
      nextTags = (nextTags as IHouseMaterialTag[]).filter(
        tag => !isHouseMaterialTag(tag) || flatNewMaterials.includes(tag.value),
      );
    }

    if ((offerType & (FOfferType.Suburban & ~FOfferType.Land)) !== 0) {
      nextTags = (nextTags as IHouseMaterialTag[]).filter(
        tag => !isHouseMaterialTag(tag) || suburbanMaterials.includes(tag.value),
      );
    }

    return nextTags;
  },
};
