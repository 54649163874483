import { ESuggestType, IAddressSuggest, IMultiSuggestResult } from '@cian/geosuggest-widget';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';

import { isEmpty } from 'ramda';

import { ISuggestItemsSchema } from 'shared/repositories/geo-suggest/entities/entities/SuggestItemsSchema';
import { EOfferType, ISuggestRequest } from 'shared/repositories/geo-suggest/v2/suggest';

import * as geoApi from '../../../api/geo/geo';
import { IGeoSuggestResult, getGeoSuggestions } from '../../../services/geoSuggest';
import { FOfferType, isCommercial, isSuburban } from '../../../utils/category';
import { TGeoType } from '../../state/geo';

export interface IAddressSuggestionOptions {
  boundedBy: [IYmapsCoord, IYmapsCoord] | undefined;
  results: number;
}

export interface IMultiSuggestParams {
  structured: ISuggestRequest;
  yandex: {
    value: string;
    options?: IAddressSuggestionOptions;
  };
}

export interface IResultSuggestions {
  addresses?: IAddressSuggest[];
  businessCenters?: ISuggestItemsSchema['items'];
  cities?: ISuggestItemsSchema['items'];
  districts?: ISuggestItemsSchema['items'];
  newbuildings?: ISuggestItemsSchema['items'];
  shoppingCenters?: ISuggestItemsSchema['items'];
  undergrounds?: ISuggestItemsSchema['items'];
  villages?: ISuggestItemsSchema['items'];
  roads?: ISuggestItemsSchema['items'];
  railways?: ISuggestItemsSchema['items'];
  coworkings?: ISuggestItemsSchema['items'];
}

export interface IMultiSuggestFull extends Omit<IMultiSuggestResult, 'suggestions'> {
  suggestions: IResultSuggestions;
}

export enum ESuggestOfferType {
  FLAT = 'flat',
  SUBURBAN = 'suburban',
  COMMERCIAL = 'commercial',
  NEWOBJECT = 'newobject',
}

export function geoTypeMapper(geoType: ESuggestType): TGeoType {
  switch (geoType) {
    case ESuggestType.city:
      return 'location';
    case ESuggestType.district:
      return 'district';
    case ESuggestType.underground:
      return 'underground';
    case ESuggestType.newbuilding:
      return 'newobject';
    case ESuggestType.road:
      return 'highway';
    case ESuggestType.railway:
      return 'railway';
    case ESuggestType.village:
      return 'village';
    default:
      return 'location';
  }
}

export function getSuggestOfferType(offerType: FOfferType) {
  if (isCommercial(offerType)) {
    return EOfferType.Commercial;
  } else if (isSuburban(offerType)) {
    return EOfferType.Suburban;
  } else if (offerType === FOfferType.FlatNew) {
    return EOfferType.Newobject;
  }

  return EOfferType.Flat;
}

//для коммерческой недвижимости не показываем ЖД,
//КП показываем только для загородной
export function filterSuggest(
  offerType: string | null | undefined,
  suggestions: IResultSuggestions,
): IResultSuggestions {
  let filtered: IResultSuggestions = { ...suggestions };

  if (offerType === ESuggestOfferType.COMMERCIAL) {
    filtered = { ...filtered, railways: undefined };
  }

  return filtered;
}

export function getMultiSuggest(
  httpApi: IHttpApi,
  params: IMultiSuggestParams,
  logger: ILogger,
): Promise<IMultiSuggestFull> {
  const {
    yandex: { value, options },
    structured: structuredParams,
  } = params;

  return Promise.all([
    geoApi.suggest(value, options).catch(() => null),
    getGeoSuggestions({ httpApi, logger }, structuredParams).catch(() => null),
  ]).then((results: [IAddressSuggest[], IGeoSuggestResult | null]) => {
    const [yandex, structured] = results;

    if (!yandex && (!structured || (structured && !structured.data.suggestions))) {
      throw new Error('Failed to load suggest data');
    }

    let suggestions: IResultSuggestions = {};
    const sortOrder: string[] = [];

    if (structured && !isEmpty(structured.data.suggestions)) {
      const {
        businessCenters,
        cities,
        districts,
        newbuildings,
        shoppingCenters,
        undergrounds,
        railways,
        roads,
        villages,
        coworkings,
      } = structured.data.suggestions;

      suggestions = {
        businessCenters: businessCenters && businessCenters.items,
        cities: cities && cities.items,
        districts: districts && districts.items,
        newbuildings: newbuildings && newbuildings.items,
        shoppingCenters: shoppingCenters && shoppingCenters.items,
        undergrounds: undergrounds && undergrounds.items,
        villages: villages && villages.items,
        roads: roads && roads.items,
        railways: railways && railways.items,
        coworkings: coworkings && coworkings.items,
      };
    }

    suggestions = (() => {
      const result = filterSuggest(structuredParams.offerType, suggestions);

      Object.keys(result).forEach((el: keyof typeof result) => {
        if (isEmpty(result[el])) {
          delete result[el];
        }
      });

      return result;
    })();

    if (structured && !isEmpty(suggestions)) {
      sortOrder.push(...structured.data.sortOrder);
    }

    if (yandex && !isEmpty(yandex)) {
      suggestions.addresses = Object.keys(suggestions).length ? yandex.slice(0, 5) : yandex;

      if (!sortOrder.includes('addresses')) {
        sortOrder.push('addresses');
      }
    }

    return {
      suggestions,
      sortOrder,
    };
  });
}
