import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { IStyleConfig, mergeStyles } from '@cian/utils/lib/shared/style';

import * as PropTypes from 'prop-types';
import * as React from 'react';

import { IOffer, IPhone, IWorkTimeInfo } from 'shared/offer';

import { getFormattedPhoneNumber } from '../../../../utils/format';
import { getDynamicCalltrackingPhone } from '../../api/dynamicCalltracking';
import { ICalltrackingPlaceTypes } from '../../types/dynamicCalltracking';

import { WarningMessage } from './WarningMessage';
import { WorkTimeInfo } from './WorkTimeInfo';
import { SimplePhoneButton } from './simple_button';

const style = require('./index.css');

const EMPTY_PHONES = [{ countryCode: null, number: null }];

export interface IPhoneProps {
  phones: IPhone[];
  workTimeInfo: IWorkTimeInfo | null;
  isSimplified?: boolean;
  showWarningMessage: boolean;
  dynamicCalltrackingPlaceType?: ICalltrackingPlaceTypes;

  onPhoneOpened?(): void;

  offer: IOffer;
}

enum EPhoneState {
  BUTTON,
  TEXT,
}

interface IPhoneState {
  type: EPhoneState;
  text: string;
  textStyle: IStyleConfig;
  comagicPhone: IPhone | null;
  comagicLoaded: boolean;
}

export interface IPhoneContext {
  httpApi: IHttpApi;
  logger: ILogger;
  queryString?: string | null;
}

export class Phone extends React.Component<IPhoneProps, IPhoneState> {
  public context: IPhoneContext;

  public static defaultProps = {
    showWarningMessage: false,
  };

  public static contextTypes: Record<keyof Phone['context'], PropTypes.Requireable<unknown>> = {
    httpApi: PropTypes.object,
    logger: PropTypes.object,
    queryString: PropTypes.string,
  };

  private phoneNumber: string;
  private hoverText = 'Показать телефон';

  public constructor(props: IPhoneProps) {
    super(props);
    this.phoneNumber = getFormattedPhoneNumber(props.phones[0]);
    this.state = {
      text: this.hideLastDigits(this.phoneNumber),
      textStyle: style['phone'],
      type: EPhoneState.BUTTON,
      comagicPhone: null,
      comagicLoaded: false,
    };
  }

  public render() {
    let viewer: React.ReactNode;
    const { workTimeInfo, isSimplified, showWarningMessage } = this.props;

    if (this.state.type === EPhoneState.BUTTON) {
      viewer = <PhoneText isSimplified={!!isSimplified} text={this.state.text} textStyle={this.state.textStyle} />;
    }

    const phones = this.state.comagicLoaded
      ? this.state.comagicPhone
        ? [this.state.comagicPhone]
        : this.props.phones
      : EMPTY_PHONES;

    const workTime = <WorkTimeInfo workTimeInfo={workTimeInfo} />;
    const warningMessage = showWarningMessage ? <WarningMessage /> : null;

    return (
      <SimplePhoneButton
        isFullWidth={true}
        phones={phones}
        text={viewer}
        warningMessage={warningMessage}
        workTime={workTime}
        onMouseOut={this.onMouseOut}
        onMouseOver={this.onMouseOver}
        onPhoneOpened={this.handleOpen}
      />
    );
  }

  private onMouseOver = () => {
    if (this.state.type === EPhoneState.BUTTON) {
      this.setState({
        text: this.hoverText,
        textStyle: style['hoverText'],
      });
    }
  };

  private onMouseOut = () => {
    if (this.state.type === EPhoneState.BUTTON) {
      this.setState({
        text: this.hideLastDigits(this.phoneNumber),
        textStyle: style['phone'],
      });
    }
  };

  private hideLastDigits = (phoneNumber: string) => {
    return phoneNumber.slice(0, -2).concat('…');
  };

  private handleOpen = async () => {
    const { newbuildingDynamicCalltracking, id } = this.props.offer;
    const siteBlockId = newbuildingDynamicCalltracking && newbuildingDynamicCalltracking.siteBlockId;
    let comagicPhone = null;
    let cianFormatPhone = null;

    if (siteBlockId && this.context.httpApi && this.context.logger) {
      try {
        const comagicData = await getDynamicCalltrackingPhone({
          httpApi: this.context.httpApi,
          logger: this.context.logger,
          blockId: siteBlockId,
          announcementId: id,
          placeType: 'offer',
          dynamicCalltrackingPlaceType: this.props.dynamicCalltrackingPlaceType,
          queryString: this.context.queryString,
        });
        comagicPhone = comagicData.phone;
        if (comagicPhone) {
          cianFormatPhone = {
            countryCode: comagicPhone[0],
            number: comagicPhone.slice(1),
          };
        }
        // eslint-disable-next-line no-empty
      } catch (err) {}
    }

    this.setState({
      text: comagicPhone || this.phoneNumber,
      textStyle: style['phone'],
      type: EPhoneState.TEXT,
      comagicPhone: cianFormatPhone,
      comagicLoaded: true,
    });
    // else невозможно покрыть
    /* istanbul ignore else */
    if (this.props.onPhoneOpened) {
      this.props.onPhoneOpened();
    }
  };
}

export interface IPhoneTextProps {
  text: string;
  textStyle: IStyleConfig;
  isSimplified: boolean;
}

export function PhoneText({ text, textStyle, isSimplified }: IPhoneTextProps) {
  return <span {...mergeStyles([textStyle, isSimplified && style['simplified-phone']])}>{text}</span>;
}
