/* eslint-disable max-lines */
import { ca } from '@cian/analytics';

import { ICommercialTrackingOfferParams, IUserGADataLayerData } from 'shared/common/state';
import { IJsonQuery } from 'shared/json_query';
import { offerHelper } from 'shared/offer';
import { IOffer } from 'shared/offer/types';
import { TUser } from 'shared/types/user';
import { normalizeAbGroup } from 'shared/utils/normalizeAbGroup';
import { getOfferListingType } from 'shared/utils/offer/tracking';

interface IParams {
  offerId: number;
  position: number;
}

interface IFolderNameClickParams {
  offerId: number;
  folderId: number;
  position: number;
}

export function trackFavoriteAdded(searchConditions: IJsonQuery, params: IParams, label?: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'favorite',
    action: 'add_from_newlist',
    label,
    searchConditions,
    products: [
      {
        id: params.offerId,
        offerType: 'offer',
        position: params.position,
      },
    ],
  });
}

export function trackFavoriteRemoved(searchConditions: IJsonQuery, params: IParams, label?: string) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'favorite',
    action: 'remove_from_newlist',
    label,
    searchConditions,
    products: [
      {
        id: params.offerId,
        offerType: 'offer',
        position: params.position,
      },
    ],
  });
}

export function trackCreateFolder(params: IParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'add_collection',
    label: 'from_card',
    products: [
      {
        id: params.offerId,
        offerType: 'offer',
        position: params.position,
      },
    ],
  });
}

export function trackSaveToFolder(params: IParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'add_to_collection',
    label: '',
    products: [
      {
        id: params.offerId,
        offerType: 'offer',
        position: params.position,
      },
    ],
  });
}

export function trackFolderNameClick(params: IFolderNameClickParams) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ListingFavorites',
    action: 'click_collection_name',
    label: params.folderId,
    products: [
      {
        id: params.offerId,
        offerType: 'offer',
        position: params.position,
      },
    ],
  });
}

export function trackPhotoSlideChanged(offerId: number, position: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'slide_photo',
    label: 'from_newlist',
    products: [
      {
        id: offerId,
        offerType: 'offer',
        position,
      },
    ],
  });
}

export function trackJKLinkClicked(offer: IOffer) {
  let label = '';

  if (offer.geo && offer.geo.jk && offer.geo.jk.gaGeo) {
    const { moId, oblId, cityId } = offer.geo.jk.gaGeo;
    label = `/newobject_name/mo_id=${moId}/obl_id=${oblId}/city_id=${cityId}/`;
  }

  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'to_card_newbuilding',
    label,
  });
}

export function trackMenuOpened() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'moreMenu_open',
  });
}

export function trackPDFLinkClicked() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'moreMenu_download_pdf',
    label: 'from_newlist',
  });
}

export function trackDocLinkClicked() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'moreMenu_download_doc',
    label: 'from_newlist',
  });
}

export const trackMoreSuggestionsButtonClicked = (label: string = '') => {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'show_more_similar',
    label,
  });
};

export function trackGoToMessages() {
  ca('event', {
    action: 'go_to_messages',
    category: 'card',
    label: '',
    name: 'oldevent',
  });
}

export function trackMapOpened() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'open_map',
    label: 'from_newlist',
  });
}

export function trackMapClosed() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'hide_map',
    label: 'from_newlist',
  });
}

export interface ITrackTradePopupOptions {
  jsonQuery: IJsonQuery;
  user?: TUser;
  userGALayerData?: IUserGADataLayerData | null;
  position: number;
  pageNum?: number;
  queryString: string;
  offersQty: number;
}

interface IAnalyticsParams {
  action?: string;
  label?: string;
}

export type IOfferPullActions = ITrackTradePopupOptions & IAnalyticsParams;

export const trackTradePopupClick = (offer: IOffer, options: ITrackTradePopupOptions) => {
  const { jsonQuery, queryString, pageNum, offersQty, user, userGALayerData, position } = options;
  const { id } = offerHelper(offer).getAnalyticsInfo();

  const params = {
    name: 'oldevent',
    ec: 'Listing',
    ea: 'trade_popup_click',
    el: '',
    searchConditions: jsonQuery,
    modelVersion:
      typeof offer.modelVersion !== 'object' && typeof offer.modelVersion !== 'undefined'
        ? String(offer.modelVersion)
        : undefined,
    products: [
      {
        id,
        offerType: 'offer',
        position: Number(position),
      },
    ],
    page: {
      listingType: getOfferListingType(offer),
      pageNumber: Number(pageNum || 1),
      queryString,
      offersQty,
      pageType: 'Listing',
      siteType: 'desktop',
    },
    user: {
      userId: user && user.isAuthenticated && user.userId ? Number(user.userId) : undefined,
      abGroup: normalizeAbGroup(userGALayerData && userGALayerData.abGroup),
    },
  };

  ca('eventSite', params);
};

export const trackTradePopupShow = (offer: IOffer, options: ITrackTradePopupOptions) => {
  const { jsonQuery, queryString, pageNum, offersQty, user, userGALayerData, position } = options;
  const { id } = offerHelper(offer).getAnalyticsInfo();

  const params = {
    name: 'oldevent',
    ec: 'Listing',
    ea: 'trade_popup_show',
    el: '',
    searchConditions: jsonQuery,
    modelVersion:
      typeof offer.modelVersion !== 'object' && typeof offer.modelVersion !== 'undefined'
        ? String(offer.modelVersion)
        : undefined,
    products: [
      {
        id,
        offerType: 'offer',
        position: Number(position),
      },
    ],
    page: {
      listingType: getOfferListingType(offer),
      pageNumber: Number(pageNum || 1),
      queryString,
      offersQty,
      pageType: 'Listing',
      siteType: 'desktop',
    },
    user: {
      userId: user && user.isAuthenticated && user.userId ? Number(user.userId) : undefined,
      abGroup: normalizeAbGroup(userGALayerData && userGALayerData.abGroup),
    },
  };

  ca('eventSite', params);
};

export const makeOfferAnalyticsClick = (offer: IOffer, options: ITrackTradePopupOptions) => {
  const { jsonQuery, queryString, pageNum, offersQty, user, userGALayerData, position } = options;
  const { id } = offerHelper(offer).getAnalyticsInfo();
  const params = {
    name: 'oldevent',
    ec: 'Listing',
    ea: 'click',
    el: 'offer_analytics_with_poll',
    searchConditions: jsonQuery,
    modelVersion:
      typeof offer.modelVersion !== 'object' && typeof offer.modelVersion !== 'undefined'
        ? String(offer.modelVersion)
        : undefined,
    products: [
      {
        id,
        offerType: 'offer',
        position: Number(position),
      },
    ],
    page: {
      listingType: getOfferListingType(offer),
      pageNumber: Number(pageNum || 1),
      queryString,
      offersQty,
      pageType: 'Listing',
      siteType: 'desktop',
    },
    user: {
      userId: user && user.isAuthenticated && user.userId ? Number(user.userId) : undefined,
      abGroup: normalizeAbGroup(userGALayerData && userGALayerData.abGroup),
    },
  };

  ca('eventSite', params);
};

export const getTrackingParamsOfferPull = (
  offer: IOffer,
  options: IOfferPullActions,
): ICommercialTrackingOfferParams => {
  const { queryString, pageNum, offersQty, user, userGALayerData, position } = options;

  const { id } = offerHelper(offer).getAnalyticsInfo();

  return {
    name: 'oldevent',
    ec: 'Offer_analytics',
    ea: '',
    el: '',
    modelVersion:
      typeof offer.modelVersion !== 'object' && typeof offer.modelVersion !== 'undefined'
        ? String(offer.modelVersion)
        : undefined,
    products: [
      {
        id,
        offerType: 'offer',
        position: Number(position),
      },
    ],
    page: {
      listingType: getOfferListingType(offer),
      pageNumber: Number(pageNum || 1),
      queryString,
      offersQty,
      pageType: 'Listing',
      siteType: 'desktop',
    },
    user: {
      userId: user && user.isAuthenticated && user.userId ? Number(user.userId) : undefined,
      abGroup: normalizeAbGroup(userGALayerData && userGALayerData.abGroup),
    },
  };
};
