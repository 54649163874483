import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export interface IIdenticalOffersIdTag {
  type: 'identicalOffersId';
  value: number;
}

function isIdenticalOffersIdTag(tag: TTag): tag is IIdenticalOffersIdTag {
  return tag.type === 'identicalOffersId';
}

export const identicalOffersIdTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.identical_id && jsonQuery.identical_id.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isIdenticalOffersIdTag, () => ({ type: 'identicalOffersId', value }));
    } else {
      return ensureTagNotExists(tags, isIdenticalOffersIdTag);
    }
  },
};
