const accountTypeToTitle = {
  managementCompany: 'управляющей компании',
  rentDepartment: 'отдела аренды',
  specialist: 'брокера',
};

const isAccountType = (accountType: string | undefined): accountType is keyof typeof accountTypeToTitle =>
  !!accountType && accountType in accountTypeToTitle;

export const getAccountTypeTitle = (accountType: string | undefined, agencyName: string): string => {
  if (isAccountType(accountType)) {
    const accountCaption = accountTypeToTitle[accountType];

    return accountType === 'specialist' ? accountCaption : `${accountCaption} «${agencyName}»`;
  }

  return `${accountTypeToTitle['specialist']} «${agencyName}»`;
};
