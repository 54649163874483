import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IFloorTagType = 'advanced-floor-floor';

export interface IFloorTag {
  type: 'advanced-floor-floor';
  min: number | undefined;
  max: number | undefined;
}

function isFloorTag(tag: TTag): tag is IFloorTag {
  return tag.type === IFloorTagType;
}

export const floorTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.floor && jsonQuery.floor.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isFloorTag, () => ({
        type: IFloorTagType,
        min: value.gte,
        max: value.lte,
      }));
    } else {
      return ensureTagNotExists(tags, isFloorTag);
    }
  },
};
