import { Dispatch, IAppState, TActions } from '../../../common/state';

import { trackMetroModalSaveEvent } from './tracking';

export interface IMetroAppliedAction {
  type: 'filters/map/METRO_APPLIED';
}

export function applyMetroModal() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'filters/map/METRO_APPLIED',
    });

    dispatch({
      type: 'filters/search/SEARCH_REQUEST_START_REQUESTED',
    });
  };
}

export function saveMetroModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/map/METRO_APPLIED':
      trackMetroModalSaveEvent(getMetroSelectedCount(state));

      return {
        ...state,
        filters: {
          ...state.filters,
          backup: undefined,
        },
        isMetroModalVisible: false,
      };
    default:
      return state;
  }
}

function getMetroSelectedCount(state: IAppState) {
  return state.filters.jsonQuery.geo
    ? state.filters.jsonQuery.geo.value.filter(value => {
        return value.type === 'underground';
      }).length
    : 0;
}
