import { Header5 } from '@cian/ui-kit';

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { NOTIFICATION_EXPIRATION_DELAY } from '../../../constants/offersComparison';
import { ComparisonNotification } from '../../components/ComparisonNotification';
import {
  ClearComparisonNotificationType,
  EComparisonNotificationCategory,
  IComparisonNotification,
} from '../../state/offersComparison/comparisonNotification';

import { trackClickPopup, trackPopupLimit } from './tracking';

import * as styles from './ComparisonNotificationContainer.css';

interface IComparisonNotificationContainerProps {
  notification: IComparisonNotification;
}

const ComparisonNotificationContainer: React.FC<IComparisonNotificationContainerProps> = ({ notification }) => {
  const [timeoutId, setNotificationTimeout] = React.useState<ReturnType<typeof setTimeout> | null>(null);
  const dispatch = useDispatch();

  const { category, offerId, url, text } = notification;

  const isLimitNotification = React.useMemo(
    () => category === ('limit' as EComparisonNotificationCategory) || false,
    [category],
  );

  React.useEffect(() => {
    if (isLimitNotification && offerId) {
      trackPopupLimit({ offerId });
    }

    setNotificationTimeout(
      setTimeout(() => {
        dispatch({
          type: ClearComparisonNotificationType,
        });
      }, NOTIFICATION_EXPIRATION_DELAY),
    );

    return () => {
      if (timeoutId != null) {
        clearTimeout(timeoutId);
      }
    };
  }, [notification]);

  const handleLinkClick = React.useCallback(() => {
    trackClickPopup({ offerId, category });

    setNotificationTimeout(
      setTimeout(() => {
        dispatch({
          type: ClearComparisonNotificationType,
        });
      }, NOTIFICATION_EXPIRATION_DELAY),
    );
  }, [offerId, category, dispatch]);

  const handleClose = React.useCallback(() => {
    dispatch({
      type: ClearComparisonNotificationType,
    });
  }, [dispatch]);

  const handleMouseEnter = React.useCallback(() => {
    if (timeoutId != null) {
      clearTimeout(timeoutId);
    }
  }, [timeoutId]);

  const control = url ? (
    <Header5 color="white_100">
      <a className={styles['link']} href={url} rel="noreferrer" target="_blank" onClick={handleLinkClick}>
        Перейти
      </a>
    </Header5>
  ) : (
    <Header5 color="white_100">
      <button className={styles['button']} onClick={handleClose}>
        Понятно
      </button>
    </Header5>
  );

  if (text.length === 0) {
    return null;
  }

  return <ComparisonNotification control={control} text={text} onMouseEnter={handleMouseEnter} />;
};

// eslint-disable-next-line import/no-default-export
export default ComparisonNotificationContainer;
