import { mergeStyles } from '@cian/utils';

import { Component } from 'react';

import { PreInfiniteSaveSearchContainer } from '../../../containers/PreInfiniteSaveSearchContainer';

import { EmptySearchContent } from './EmptySearchContent';

import * as styles from './styles.css';

export enum EPreInfiniteBannerTypes {
  subscription = 'subscription',
  empty = 'empty',
}
export interface IPreInfiniteBannerProps {
  type: EPreInfiniteBannerTypes;
  isOfferInSavedSearch?: boolean;
}

export class PreInfiniteBanner extends Component<IPreInfiniteBannerProps, {}> {
  private getBannerContent = (type: EPreInfiniteBannerTypes) => {
    switch (type) {
      case EPreInfiniteBannerTypes.subscription: {
        return <PreInfiniteSaveSearchContainer />;
      }
      case EPreInfiniteBannerTypes.empty: {
        return <EmptySearchContent />;
      }
      default:
        return null;
    }
  };

  public render() {
    const { type, isOfferInSavedSearch } = this.props;

    return (
      !isOfferInSavedSearch && (
        <aside {...mergeStyles(styles['banner-container'], styles[type])}>
          <div className={styles['banner']}>
            <div className={styles['banner-image']}></div>
            <div className={styles['banner-content-wrapper']}>{this.getBannerContent(type)}</div>
          </div>
        </aside>
      )
    );
  }
}
