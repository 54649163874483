import { ERentTime } from 'shared/json_query';

import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IRentTimeTagType = 'advanced-rentTime';

export interface IRentTimeTag {
  type: 'advanced-rentTime';
  value: ERentTime;
}

function isRentTimeTag(tag: TTag): tag is IRentTimeTag {
  return tag.type === IRentTimeTagType;
}

export const rentTimeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.for_day && jsonQuery.for_day.value;

    if (
      value !== undefined &&
      value !== ERentTime.NotDaily &&
      value !== ERentTime.Daily &&
      value !== ERentTime.AnyTerm
    ) {
      return ensureSingleTagExists(tags, isRentTimeTag, () => ({ type: IRentTimeTagType, value }));
    } else {
      return ensureTagNotExists(tags, isRentTimeTag);
    }
  },
};
