import { ca } from '@cian/analytics';

import { OFFER_TYPE_TRACKING_LABEL } from '../../../../filters/components/geo_suggest/tracking';
import { FOfferType } from '../../../../utils/category';

export function trackEmptyListingLinkClick(offerType: FOfferType, oid: number) {
  ca('event', {
    action: 'emptylisting_in_KP',
    category: 'Listing',
    label: OFFER_TYPE_TRACKING_LABEL[offerType],
    name: 'oldevent',
    oid,
  });
}
