import { connect } from 'react-redux';

import { IAppState } from 'shared/common/state';

import { prepareTrackingData } from '../../../../utils/prepareTrackingData';
import { shouldSendAdsEvent } from '../../../../utils/shouldSendAdsEvent';
import { IWithTrackingProps } from '../../../hoc/withTracking';

import { AdFoxBannerTracker } from './tracker';

export function mapStateToProps(state: IAppState): IWithTrackingProps {
  const { filters, results, userGADataLayerData, breadcrumbs, featureToggle } = state;

  return {
    tracking: prepareTrackingData(filters, results, breadcrumbs, userGADataLayerData),
    shouldSendAdsEvent: shouldSendAdsEvent(filters.jsonQuery) || !featureToggle.isFilterByFlatsEnabled,
  };
}

export const AdFoxBannerTrackerContainer = connect<IWithTrackingProps>(mapStateToProps)(AdFoxBannerTracker);
