import { Component } from 'react';

import { Button } from '../../../common/components/button';

import { PrintPopupContainer } from './print_popup/container';
import { trackExcelSaveClicked, trackPrintOffersClicked } from './tracking';

const style = require('./index.css');

export interface IButtonPrintExcelGroupState {
  isPrintPopupVisible: boolean;
}

export interface IButtonPrintExcelGroupProps {
  queryString: string;
}

export class ButtonPrintExcelGroup extends Component<IButtonPrintExcelGroupProps, IButtonPrintExcelGroupState> {
  public state = {
    isPrintPopupVisible: false,
  };

  public componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown);
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown);
  }

  public render() {
    return (
      <div className={style['buttonGroup']}>
        <Button mode="light" theme="primary" onClick={() => this.onExcelSaveClick(this.props.queryString)}>
          Сохранить файл в Excel
        </Button>
        <Button mode="light" theme="primary" onClick={this.onPrintOffersClick}>
          Распечатать объявления
        </Button>

        <PrintPopupContainer close={this.hidePrintPopup} open={this.state.isPrintPopupVisible} />
      </div>
    );
  }

  private handleKeyDown = (e: KeyboardEvent) => {
    const KEY_CODE_P = 80;
    if (e.keyCode === KEY_CODE_P && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      this.showPrintPopup();
    }
  };

  private hidePrintPopup = () => {
    this.setState({
      isPrintPopupVisible: false,
    });
  };

  private showPrintPopup = () => {
    this.setState({
      isPrintPopupVisible: true,
    });
  };

  private onExcelSaveClick = (queryString: string) => {
    trackExcelSaveClicked();
    window.open(`/export/xls/offers/?${queryString}`, '_blank');
  };

  private onPrintOffersClick = () => {
    trackPrintOffersClicked();

    this.showPrintPopup();
  };
}
