import * as React from 'react';

import { Proof } from '../Proof';

import { showAgentHonestlyProofs } from './external/showAgentHonestlyProofs';
import { getAgentType } from './internal/utils/getAgentType';
import { getProofMessage } from './internal/utils/getProofMessage';
import { IAgentWorkHonestlyProofsProps } from './types';

import * as styles from './AgentWorkHonestlyProofs.css';

export const AgentWorkHonestlyProofs: React.FC<IAgentWorkHonestlyProofsProps> = props => {
  const { offer } = props;

  if (!showAgentHonestlyProofs(offer)) {
    return null;
  }

  const agentType = getAgentType(offer);
  const message = getProofMessage(agentType);

  return (
    <article className={styles['container']}>
      <Proof text={message} />
    </article>
  );
};

AgentWorkHonestlyProofs.displayName = 'AgentWorkHonestlyProofs';
