import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBalconyTagType = 'advanced-balconies-balcony';

export interface IBalconyTag {
  type: 'advanced-balconies-balcony';
}

function isBalconyTag(tag: TTag): tag is IBalconyTag {
  return tag.type === IBalconyTagType;
}

export const balconyTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.balconies && jsonQuery.balconies.value && jsonQuery.balconies.value.gte;

    if (value !== undefined && value > 0) {
      return ensureSingleTagExists(tags, isBalconyTag, () => ({ type: IBalconyTagType }));
    } else {
      return ensureTagNotExists(tags, isBalconyTag);
    }
  },
};
