import { IAppState, TActions } from '../../../common/state';
import { IJsonQueryRange } from '../../../json_query';

const IMinAreaChangedActionType = 'filters/area/MIN_AREA_CHANGED';
const IMaxAreaChangedActionType = 'filters/area/MAX_AREA_CHANGED';

export type TJQArea = 'total_area' | 'room_area' | 'kitchen' | 'living_area' | 'site';
export type TArea = 'total_area' | 'room_area' | 'kitchen' | 'living_area' | 'site_area';

const paramNameToJQName: { [P in TArea]: TJQArea } = {
  site_area: 'site',
  total_area: 'total_area',
  room_area: 'room_area',
  kitchen: 'kitchen',
  living_area: 'living_area',
};

const getJQParamName = (area: TArea): TJQArea => {
  return paramNameToJQName[area];
};

export interface IMinAreaChangedAction {
  type: 'filters/area/MIN_AREA_CHANGED';
  param: TArea;
  value: number | undefined;
  needTag: boolean;
}

export interface IMaxAreaChangedAction {
  type: 'filters/area/MAX_AREA_CHANGED';
  param: TArea;
  value: number | undefined;
  needTag: boolean;
}

export function changeMinArea(param: TArea, value: number | undefined, needTag: boolean = true): IMinAreaChangedAction {
  return {
    type: IMinAreaChangedActionType,
    param,
    value,
    needTag,
  };
}

export function changeMaxArea(param: TArea, value: number | undefined, needTag: boolean = true): IMaxAreaChangedAction {
  return {
    type: IMaxAreaChangedActionType,
    param,
    value,
    needTag,
  };
}

export function areaReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IMinAreaChangedActionType: {
      const paramName = getJQParamName(action.param);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const param = (state.filters.jsonQuery as any)[paramName] as IJsonQueryRange;

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            [paramName]:
              action.value !== undefined || (param && param.value.lte !== undefined)
                ? {
                    type: 'range',
                    value: {
                      gte: action.value,
                      lte: param && param.value.lte,
                    },
                  }
                : undefined,
          },
        },
      };
    }

    case IMaxAreaChangedActionType: {
      const paramName = getJQParamName(action.param);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const param = (state.filters.jsonQuery as any)[paramName] as IJsonQueryRange;

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            [paramName]:
              action.value !== undefined || (param && param.value.gte !== undefined)
                ? {
                    type: 'range',
                    value: {
                      gte: param && param.value.gte,
                      lte: action.value,
                    },
                  }
                : undefined,
          },
        },
      };
    }

    case 'filters/tags/TAG_REMOVED':
      switch (action.tag.type) {
        case 'totalArea':
          return {
            ...state,
            filters: {
              ...state.filters,
              jsonQuery: {
                ...state.filters.jsonQuery,
                total_area: undefined,
              },
            },
          };

        case 'roomArea':
          return {
            ...state,
            filters: {
              ...state.filters,
              jsonQuery: {
                ...state.filters.jsonQuery,
                room_area: undefined,
              },
            },
          };

        case 'livingArea':
          return {
            ...state,
            filters: {
              ...state.filters,
              jsonQuery: {
                ...state.filters.jsonQuery,
                living_area: undefined,
              },
            },
          };

        case 'kitchenArea':
          return {
            ...state,
            filters: {
              ...state.filters,
              jsonQuery: {
                ...state.filters.jsonQuery,
                kitchen: undefined,
              },
            },
          };

        case 'siteArea':
          return {
            ...state,
            filters: {
              ...state.filters,
              jsonQuery: {
                ...state.filters.jsonQuery,
                site: undefined,
              },
            },
          };

        default:
          return state;
      }

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            total_area: undefined,
            room_area: undefined,
            kitchen: undefined,
            living_area: undefined,
            site: undefined,
          },
        },
      };

    default:
      return state;
  }
}
