import { IconSuccess16, InlineText3 } from '@cian/ui-kit';

import * as React from 'react';

import { IProofProps } from './types';

import * as styles from './Proof.css';

export const Proof: React.FC<IProofProps> = props => {
  const { text } = props;

  return (
    <div className={styles['proof']}>
      <div className={styles['icon']}>
        <IconSuccess16 color="primary_100" />
      </div>
      <InlineText3 color="gray60_100">{text}</InlineText3>
    </div>
  );
};

Proof.displayName = 'Proof';
