import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IIsFirstFloorTagType = 'advanced-floor-isFirstFloor';

export interface IIsFirstFloorTag {
  type: 'advanced-floor-isFirstFloor';
  value: boolean;
}

function isFirstFloorTag(tag: TTag): tag is IIsFirstFloorTag {
  return tag.type === IIsFirstFloorTagType;
}

export const isFirstFloorTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.is_first_floor && jsonQuery.is_first_floor.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isFirstFloorTag, () => ({ type: IIsFirstFloorTagType, value }));
    } else {
      return ensureTagNotExists(tags, isFirstFloorTag);
    }
  },
};
