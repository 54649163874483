import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IOfferIdTagType = 'advanced-offerId';

export interface IOfferIdTag {
  type: 'advanced-offerId';
  value: number;
}

export const isOfferIdTag = (tag: TTag): tag is IOfferIdTag => tag.type === IOfferIdTagType;

export const offerIdTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.id && jsonQuery.id.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isOfferIdTag, () => ({ type: IOfferIdTagType, value }));
    } else {
      return ensureTagNotExists(tags, isOfferIdTag);
    }
  },
};
