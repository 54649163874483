import { EPlacementType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IPlacementTypeTagType = 'advanced-placement-type';

export interface IPlacementTypeTag {
  type: 'advanced-placement-type';
  value: EPlacementType;
}

function isPlacementTypeTag(tag: TTag): tag is IPlacementTypeTag {
  return tag.type === IPlacementTypeTagType;
}

export const placementTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.placement_type && jsonQuery.placement_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IPlacementTypeTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EPlacementType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IPlacementTypeTag => isPlacementTypeTag(tag) && tag.value === value,
            () => ({ type: IPlacementTypeTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
