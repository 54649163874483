import { all, call, select, takeLatest } from 'redux-saga/effects';

import { IAppState } from '../../../common/state';

import { IAP_POPUP_REQUEST_CLOSED } from './actions';

export const SESSION_STORAGE_IAP_REQUEST_POPUP_ITEM = 'isIapRequestPopupClosed';

export function* handleIapRequestPopupClosed() {
  const { logger } = (yield select()) as IAppState;

  try {
    yield call([sessionStorage, sessionStorage.setItem], SESSION_STORAGE_IAP_REQUEST_POPUP_ITEM, 'true');
  } catch (error) {
    logger.warning(error, {
      description: 'Попытка обращения к sessionStorage',
      domain: 'handleIapRequestPopupClosed',
      message: 'Ошибка призаписи данных в sessionStorage',
    });
  }
}

export function* iapRequestPopupSagas() {
  yield all([takeLatest(IAP_POPUP_REQUEST_CLOSED, handleIapRequestPopupClosed)]);
}
