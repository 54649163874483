export enum ExtendedFieldType {
  /** Целое **/
  Integer = 'integer',
  /** Дробное **/
  Float = 'float',
  /** Цена **/
  Price = 'price',
  /** Строка **/
  String = 'string',
  /** Ввод строки по маске **/
  Masked = 'masked',
  /** Выбор одной карточки из списка **/
  OneChoice = 'oneChoice',
  /** Выбор нескольких карточек из списка **/
  MultiChoice = 'multiChoice',
  /** Чекбокс */
  Checkbox = 'checkbox',
  /** Строковое поле в виде textarea */
  Textarea = 'textArea',
  /** Дата с дейтпикером */
  Date = 'date',
  /** Радио-группа */
  RadioButtonGroup = 'radioButtonGroup',
  /** Чекбокс-группа */
  CheckboxButtonGroup = 'checkboxButtonGroup',
  /** Мультиселект */
  Multiselect = 'multiselect',
  /** Мультиселект, у которого нельзя выбрать пустое значение */
  MultiselectWithoutEmptyChoice = 'multiselectWithoutEmptyChoice',
  /** Геосаджест */
  GeoSuggest = 'geosuggest',
}
