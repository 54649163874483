interface IData {
  leftSlidePosition: number;
  visibleSlidesCount: number;
  step: number;
}

interface INextLeftPosition {
  rightSlidePosition: number;
  leftSlidePosition: number;
  groupPosition: number;
  leftSlidePositionInsideGroup: number;
}

export function computeNextLeftSlidePosition({
  leftSlidePosition: currentLeftSlidePosition,
  visibleSlidesCount,
  step,
}: IData): INextLeftPosition {
  const leftSlidePosition = Math.max(currentLeftSlidePosition - step, 0);
  const rightSlidePosition = leftSlidePosition + visibleSlidesCount - 1;
  const groupPosition = Math.floor(leftSlidePosition / visibleSlidesCount);
  const leftSlidePositionInsideGroup = leftSlidePosition - groupPosition * visibleSlidesCount;

  return {
    rightSlidePosition,
    leftSlidePosition,
    groupPosition,
    leftSlidePositionInsideGroup,
  };
}
