import { numberToPrettyString } from '@cian/utils';
import { plural } from '@cian/utils/lib/shared/plural';

import { connect } from 'react-redux';

import { IAppState } from 'shared/common/state';

import { SummaryHeader } from '../../../components/Summary';
import { getOffersCount } from '../utils/selectors';

export function mapStateToProps(state: IAppState) {
  const offersCount = getOffersCount(state);

  return {
    header: `Найдено ${numberToPrettyString(offersCount)} ${plural(offersCount, [
      'объявление',
      'объявления',
      'объявлений',
    ])}`,
  };
}

export const SummaryHeaderContainer = connect(mapStateToProps)(SummaryHeader);
