import { UIText2 } from '@cian/ui-kit';

import { IOffer } from 'shared/offer';

import { offerHelper } from '../../presenter';

import { MoreAreasPartsLink } from './components';
import { AreaLink } from './components/AreaLink';
import { getFormattedArea, getFormattedFloor, getIsRent, getPrice } from './helpers';

import * as styles from './AreaPartsMultiAds.css';

export interface IAreaPartsMultiAdsProps {
  offer: IOffer;
  onPageJumped(label?: string): void;
}

export const MAX_AREA_PARTS_TO_SHOW = 5;

export function AreaPartsMultiAds(props: IAreaPartsMultiAdsProps) {
  const { offer, onPageJumped } = props;
  const { areaParts, fullUrl, bargainTerms } = offer;
  const { paymentPeriod } = bargainTerms;

  const isMultiAds = areaParts && areaParts.length;

  if (!isMultiAds) {
    return null;
  }

  const { commercial } = offerHelper(offer);
  const commercialTypeTitle = commercial.getMultiAdsAreaPartType();
  const shouldRenderFloorColumn = areaParts.some(({ floorFrom, floorTo }) => floorFrom || floorTo);

  const rows = areaParts.slice(0, MAX_AREA_PARTS_TO_SHOW).map((part, index) => {
    const floor = getFormattedFloor(part);
    const isRent = getIsRent(paymentPeriod || '');
    const price = getPrice(isRent, part);
    const area = getFormattedArea(part.area);

    return (
      <div className={styles['row']} key={index}>
        {shouldRenderFloorColumn && (
          <div className={styles['floor']} data-testid="area-parts-floor-column">
            <UIText2>{floor}</UIText2>
          </div>
        )}
        <div className={styles['type']}>
          <UIText2>{commercialTypeTitle}</UIText2>
        </div>
        <div className={styles['area']}>
          <UIText2>
            <AreaLink handleClick={onPageJumped.bind(null, 'card_areas_in_newlist')} href={`${fullUrl}#/area-parts`}>
              {area}
            </AreaLink>
          </UIText2>
        </div>
        <div className={styles['price']}>
          <UIText2>{price}</UIText2>
        </div>
      </div>
    );
  });

  return (
    <div className={styles['container']}>
      {rows}
      <MoreAreasPartsLink
        handleClick={onPageJumped.bind(null, 'card_areas_in_newlist')}
        numberOfPartsToShow={MAX_AREA_PARTS_TO_SHOW}
        offer={offer}
      />
    </div>
  );
}
