import { ITypedReduxAction } from '../../../types/redux/actionType';

export enum EStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IState {
  status: EStatus;
  email: string;
  subscribeToNews: boolean;
  emailConfirmationRequired: boolean | null;
  errors: string[] | null;
}

export enum EAction {
  SetEmail = 'setEmail',
  SetSubscribeToNews = 'setSubscribeToNews',
  SetStatusLoading = 'setStatusLoading',
  SetStatusSucceed = 'setStatusSucceed',
  SetStatusFailed = 'setStatusFailed',
}
export type TSetEmail = ITypedReduxAction<EAction.SetEmail, string>;
export type TSetSubscribeToNews = ITypedReduxAction<EAction.SetSubscribeToNews, boolean>;
export type TSetStatusLoading = ITypedReduxAction<EAction.SetStatusLoading>;
export type TSetStatusSucceed = ITypedReduxAction<EAction.SetStatusSucceed, boolean>;
export type TSetStatusFailed = ITypedReduxAction<EAction.SetStatusFailed, string[]>;
export type TActions = TSetEmail | TSetSubscribeToNews | TSetStatusLoading | TSetStatusSucceed | TSetStatusFailed;
