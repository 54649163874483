import { IHttpApi } from '@cian/http-api/shared/http';
import { Button } from '@cian/ui-kit';
import { getNearestUpperMultiple, keepValueInRange } from '@cian/utils';

import { Component, RefObject } from 'react';

import { IAuction, IOffer } from 'shared/repositories/search-offers.legacy/v2/types';

import { TSetAuctionBetStatus } from '../../../../serp/components/AuctionStatusNotification';
import { getApplyNewBetDate } from '../../api/auction/getApplyNewBetDate';
import { setOfferAuctionBet } from '../../api/setOfferAuctionBet';
import { AuctionBetInput } from '../AuctionBetInput';
import { AuctionReduceBetModal } from '../AuctionReduceBetModal';

import {
  trackAuctionChangeBetClick,
  trackAuctionErrorPopupRetry,
  trackAuctionErrorPopupShow,
  trackAuctionHint,
  trackAuctionNotEnoughMoneyPopupPayClick,
  trackAuctionNotEnoughMoneyPopupShow,
  trackAuctionSendBetClick,
} from './tracking';

import styles from './AuctionBetManager.css';

export interface IAuctionBetManagerProps {
  auctionMaxBet: number;
  httpApi: IHttpApi;
  initialBet: number;
  isFromOwn: boolean;
  offer: IOffer;
  auction: IAuction;
  validateStep: boolean;
  auctionBetCalculator(value: number | null): number;
  onClose(): void;
  setBetModalRefs: (refs: RefObject<HTMLElement>[]) => void;
}

export interface IAuctionBetManagerState {
  bet: number;
  isPlacingBet: boolean;
  isBetValid: boolean;
  isReducingBet: boolean;
}

export interface IAuctionStatusNotificationProps {
  status: TSetAuctionBetStatus;
  onClose?(): void;
  onRetryClick?(): void;
  onPayClick?(): void;
}

export class AuctionBetManager extends Component<IAuctionBetManagerProps, IAuctionBetManagerState> {
  public constructor(props: IAuctionBetManagerProps) {
    super(props);

    const { initialBet, auctionMaxBet, validateStep, auction } = this.props;

    const stepBet = auction ? auction.stepBet : 1;

    const newBet = validateStep ? getNearestUpperMultiple(initialBet + 1, stepBet) || 0 : initialBet + 1;

    this.state = {
      bet: keepValueInRange(newBet, 0, auctionMaxBet),
      isPlacingBet: false,
      isBetValid: true,
      isReducingBet: false,
    };
  }

  public render() {
    const { isFromOwn, validateStep, offer, auction, onClose, auctionBetCalculator, setBetModalRefs } = this.props;
    const { bet, isPlacingBet, isBetValid, isReducingBet } = this.state;
    const isSubmitButtonShow = !!auction && bet !== auction.currentBet;

    return (
      <div className={styles['auction_manage_bet']}>
        <div className={styles['raise_bet_image']} />
        <div>
          {isFromOwn ? (
            <>
              Хотите поднять объявление
              <br />
              на первую страницу поиска?
              <br />
              Повысьте ставку:
            </>
          ) : (
            <>
              Хотите опередить объявление
              <br />
              конкурента? Повысьте ставку:
            </>
          )}
          <div className={styles['raise_bet_wrapper']}>
            <div className={styles['input_wrapper']}>
              <AuctionBetInput
                auctionBetCalculator={auctionBetCalculator}
                initialBet={bet}
                stepBet={auction ? auction.stepBet : 1}
                trackChangeBetClick={() => trackAuctionChangeBetClick(offer)}
                validateStep={validateStep}
                onChange={this.handleBetChange}
              />
            </div>

            {isSubmitButtonShow && (
              <Button
                disabled={!isBetValid || isPlacingBet}
                size="XS"
                theme="fill_primary"
                onClick={this.handleBetSubmit}
              >
                Ок
              </Button>
            )}
          </div>
        </div>

        <AuctionReduceBetModal
          auctionBetCalculator={auctionBetCalculator}
          bet={bet}
          open={isReducingBet}
          setBetModalRefs={setBetModalRefs}
          stepBet={auction ? auction.stepBet : 1}
          validateStep={validateStep}
          onCancel={onClose}
          onSubmit={this.setOfferBet}
        />
      </div>
    );
  }

  private handleBetChange = (bet: number, isBetValid: boolean) => {
    this.setState({ bet, isBetValid });
  };

  private handleBetSubmit = () => {
    const { offer, auction } = this.props;
    const { bet } = this.state;

    const isReducingBet = bet < auction.currentBet;

    this.setState({ isPlacingBet: !isReducingBet, isReducingBet });

    trackAuctionHint('up_search', 'click', offer);

    if (!isReducingBet) {
      this.setOfferBet(bet);
    }
  };

  private setOfferBet = (bet: number) => {
    const { httpApi, offer, auction } = this.props;
    const { isReducingBet } = this.state;

    const event = new Event('auctionNotificationHide');
    document.dispatchEvent(event);

    setOfferAuctionBet(httpApi, offer.id, bet)
      .then(({ status }) => {
        const { onClose } = this.props;

        let props: IAuctionStatusNotificationProps = { status };

        switch (status) {
          case 'completed':
            if (isReducingBet) {
              getApplyNewBetDate(httpApi, offer.id).then(({ applyNewBetDate, nextBet }) => {
                auction.applyNewBetDate = applyNewBetDate;
                auction.nextBet = nextBet;

                this.triggerRerender();
              });
            } else {
              auction.currentBet = bet;
              auction.applyNewBetDate = undefined;
              auction.nextBet = bet;

              this.triggerRerender();
            }

            trackAuctionSendBetClick(offer, bet);

            this.showNotification(props);

            onClose();

            break;

          case 'failed':
            props = this.getFailedState();

            this.showNotification(props);
            this.setState({ isPlacingBet: false, isReducingBet: false });

            break;

          case 'notEnoughMoney':
            props = {
              status,
              onPayClick: this.handlePayClick,
            };

            this.showNotification(props);
            this.setState({ isPlacingBet: false, isReducingBet: false });

            trackAuctionNotEnoughMoneyPopupShow();

            break;

          default:
            this.showNotification(props);

            onClose();
        }
      })
      .catch(() => {
        const props = this.getFailedState();

        this.showNotification(props);

        this.setState({ isPlacingBet: false, isReducingBet: false });
      });
  };

  private getFailedState = (): IAuctionStatusNotificationProps => {
    trackAuctionErrorPopupShow();

    return {
      status: 'failed',
      onRetryClick: this.handleRetryClick,
    };
  };

  private handleRetryClick = () => {
    trackAuctionErrorPopupRetry();

    this.handleBetSubmit();
  };

  private handlePayClick = () => {
    trackAuctionNotEnoughMoneyPopupPayClick();

    location.href = 'https://my.cian.ru/pay';
  };

  private showNotification = (detail: IAuctionStatusNotificationProps) => {
    const event = new CustomEvent('auctionNotificationShow', { detail });

    document.dispatchEvent(event);
  };

  private triggerRerender = () => {
    const { offer } = this.props;
    const detail = { offerId: offer.id };
    const event = new CustomEvent('auctionRightPanelRerender', { detail });

    document.dispatchEvent(event);
  };
}
