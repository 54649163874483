import * as React from 'react';

import { CustomEventCreator } from '../../../../utils/custom_events';

const style = require('./index.css');

const LINE_STYLE = {
  strokeStyle: '#DB1D1D',
  lineWidth: 3,
  lineCap: 'round',
  lineJoin: 'round',
};

interface ICanvasProps {
  onDrawFinished(points: YMaps.TCoord[]): void;
}

export class Canvas extends React.Component<ICanvasProps> {
  private canvas: HTMLCanvasElement | null = null;
  private ctx: CanvasRenderingContext2D | null = null;

  private isDrawing = false;
  private points: YMaps.TCoord[] = [];

  private customEventCreator: CustomEventCreator;

  public componentDidMount() {
    if (this.canvas) {
      this.bindEvents();
      this.ctx = this.canvas.getContext('2d');
      this.resizeCtx();
      this.setCtxStyle();
    }
  }

  public componentWillUnmount() {
    this.customEventCreator.unsubscribe();
  }

  public render() {
    return (
      <canvas
        className={style['mapCanvas']}
        ref={canvas => (this.canvas = canvas)}
        onMouseDown={this.onMouseDown}
        onMouseMove={this.onMouseMove}
        onMouseUp={this.onMouseUp}
      />
    );
  }

  private onMouseUp = () => {
    if (this.ctx) {
      this.ctx.closePath();
      this.props.onDrawFinished(this.points);
      this.isDrawing = false;
    }
  };

  private onMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (this.ctx) {
      // there are no offsetX/offsetY props in nativeEvent typings
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mouseEvent = event.nativeEvent as any;

      this.ctx.beginPath();
      this.ctx.moveTo(mouseEvent.offsetX, mouseEvent.offsetY);

      this.isDrawing = true;
    }
  };

  private onMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (this.isDrawing) {
      // there are no offsetX/offsetY props in nativeEvent typings
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const mouseEvent = event.nativeEvent as any;

      this.drawLineTo(mouseEvent.offsetX, mouseEvent.offsetY);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.points as any).push([event.pageX, event.pageY]);
    }
  };

  private drawLineTo = (x: number, y: number) => {
    if (this.ctx) {
      this.ctx.lineTo(x, y);
      this.ctx.stroke();
    }
  };

  private setCtxStyle = () => {
    if (this.ctx) {
      this.ctx.strokeStyle = LINE_STYLE.strokeStyle;
      this.ctx.lineWidth = LINE_STYLE.lineWidth;
      this.ctx.lineCap = LINE_STYLE.lineCap as CanvasLineCap;
      this.ctx.lineJoin = LINE_STYLE.lineJoin as CanvasLineJoin;
    }
  };

  private resizeCtx = () => {
    if (this.canvas && this.ctx) {
      this.ctx.canvas.width = this.canvas.offsetWidth;
      this.ctx.canvas.height = this.canvas.offsetHeight;
    }
  };

  private onResize = () => {
    this.resizeCtx();
    this.setCtxStyle();
  };

  private bindEvents = () => {
    this.customEventCreator = new CustomEventCreator('resize', this.onResize);
    this.customEventCreator.subscribe();
  };
}
