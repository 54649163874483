import { IAppState, TActions } from '../../../common/state';

const removeBbox = (state: IAppState) => ({
  ...state,
  filters: {
    ...state.filters,
    jsonQuery: {
      ...state.filters.jsonQuery,
      bbox: undefined,
    },
  },
});

export const bboxReducer = (state: IAppState, action: TActions): IAppState => {
  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type !== 'geo-bbox') {
        return state;
      }

      return removeBbox(state);

    case 'filters/tags/ALL_TAGS_REMOVED':
      return removeBbox(state);

    default:
      return state;
  }
};
