import { plural } from '@cian/utils';

import * as React from 'react';

import { IOffer } from 'shared/offer';

import { AreaLink } from '../AreaLink';

export interface IMoreAreasPartsLinkProps {
  offer: IOffer;
  handleClick(): void;
  numberOfPartsToShow: number;
}

export const MoreAreasPartsLink: React.FC<IMoreAreasPartsLinkProps> = props => {
  const { offer, handleClick, numberOfPartsToShow } = props;
  const { areaParts } = offer;
  const numberOfAreaParts = areaParts && areaParts.length;

  if (!numberOfAreaParts) {
    return null;
  }

  const hasMoreThanMaxNumberOfAreas = numberOfAreaParts > numberOfPartsToShow;

  if (!hasMoreThanMaxNumberOfAreas) {
    return null;
  }

  const areaText = plural(numberOfAreaParts - numberOfPartsToShow, ['площадь', 'площади', 'площадей']);
  const restPartsText = `Ещё ${numberOfAreaParts - numberOfPartsToShow} ${areaText}`;

  return (
    <AreaLink handleClick={handleClick} href={`${offer.fullUrl}#/area-parts`}>
      {restPartsText}
    </AreaLink>
  );
};
