import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const HAS_PROFITBASE_BOOKING_TAG_TYPE = 'has-profitbase-booking';

export interface IHasProfitbaseBookingTag {
  type: 'has-profitbase-booking';
}

function isHasProfitbaseBookingTag(tag: TTag): tag is IHasProfitbaseBookingTag {
  return tag.type === HAS_PROFITBASE_BOOKING_TAG_TYPE;
}

export const hasProfitbaseBookingTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_profitbase_booking && jsonQuery.has_profitbase_booking.value;

    if (value) {
      return ensureSingleTagExists(tags, isHasProfitbaseBookingTag, () => ({
        type: HAS_PROFITBASE_BOOKING_TAG_TYPE,
      }));
    } else {
      return ensureTagNotExists(tags, isHasProfitbaseBookingTag);
    }
  },
};
