import { IAppState, TActions } from '../../common/state';
import { ECurrencyType, jq } from '../../json_query';

export interface ICurrencyTypeChangedAction {
  type: 'filters/currencyType/CURRENCY_TYPE_CHANGED';
  currencyType: ECurrencyType;
}

export function setCurrencyType(currencyType: ECurrencyType): ICurrencyTypeChangedAction {
  return {
    type: 'filters/currencyType/CURRENCY_TYPE_CHANGED',
    currencyType,
  };
}

export function currencyTypeReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/currencyType/CURRENCY_TYPE_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setCurrencyType(action.currencyType),
        },
      };
    default:
      return state;
  }
}
