import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IHasHeatingTagType = 'advanced-has-heating';

export interface IHasHeatingTag {
  type: 'advanced-has-heating';
}

function isHasHeatingTag(tag: TTag): tag is IHasHeatingTag {
  return tag.type === IHasHeatingTagType;
}

export const hasHeatingTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_heating && jsonQuery.has_heating.value;

    if (value) {
      return ensureSingleTagExists(tags, isHasHeatingTag, () => ({ type: IHasHeatingTagType, value }));
    } else {
      return ensureTagNotExists(tags, isHasHeatingTag);
    }
  },
};
