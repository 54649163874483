import { IOffer } from '../../types/offer';
import { showAgentHonestlyProofs } from '../AgentWorkHonestlyProofs';

export const isBaseOrProfi = (userTariff?: string[]): boolean => {
  return !!(userTariff || []).find(t => t === 'base' || t === 'profi');
};

export const isDealCompany = (agentAccountType?: string): boolean => {
  return !!agentAccountType && ['managementCompany', 'rentDepartment'].includes(agentAccountType);
};

export const isDealAndProfi = (userTariff?: string[], agentAccountType?: string): boolean => {
  return isBaseOrProfi(userTariff) && isDealCompany(agentAccountType);
};

export const isAgentBrandShow = (offer: IOffer): boolean => {
  const { isPaid, isTop3, isPremium } = offer;
  const isHonestwork = showAgentHonestlyProofs(offer);

  // Любое Премиум-объявление или Платное + РЧ
  return !!(isTop3 || isPremium || (isPaid && isHonestwork));
};

export const isPublishedUser = (offer: IOffer): boolean => {
  if (!offer.user) {
    return false;
  }

  return !offer.user.isHidden;
};
