import { IAdFoxBannerContext } from '@cian/adfox/build/react';
import { Button, IconClose16 } from '@cian/ui-kit';
import { UIText2 } from '@cian/ui-kit/typography';

import { Component } from 'react';

import { UndergroundIcon } from 'shared/common/components/underground_icon';
import { ScrollFade } from 'shared/components/ScrollFade';
import { IWithTrackingProps } from 'shared/serp/hoc/withTracking';
import { trackAdFoxMortgage } from 'shared/tracking/adFoxMortgage';
import { sendAdsEvent } from 'shared/tracking/sendAdsEvent';
import { ISimplifieldTGBBannerContext } from 'shared/types/adfox';

import { isMortgageCampaign } from '../../../utils/mortgage';

import * as styles from './TGSimplifiedBanner.css';

export interface ITGSimplifiedBannerOwnProps {
  context: ISimplifieldTGBBannerContext | IAdFoxBannerContext;
  locationId: number | undefined;
}

export interface ITGSimplifiedBannerState {
  isClosed: boolean;
}

export type TTGSimplifiedBannerProps = IWithTrackingProps & ITGSimplifiedBannerOwnProps;

export class TGSimplifiedBanner extends Component<TTGSimplifiedBannerProps, ITGSimplifiedBannerState> {
  public state = {
    isClosed: false,
  };

  public componentDidMount(): void {
    this.track(true);
  }

  public render() {
    if (this.state.isClosed) {
      return <div className={styles['empty']} />;
    }

    const { title, location, description, annotation, imageURL, image, metro, reference, site, url, label } =
      this.props.context;

    return (
      <div className={styles['container']} onClick={this.onClick}>
        <a className={styles['main-link']} href={reference || url} rel="noreferrer" target="_blank">
          <div className={styles['photo-container']}>
            <img className={styles['photo']} src={imageURL || image} />
          </div>
          <div className={styles['main-content']}>
            <div className={styles['title']}>{title}</div>
            <div className={styles['links-container']}>
              <div className={styles['goto-button-container']}>{this.renderGotoButton()}</div>
              <div className={styles['link-view']}>{site}</div>
            </div>
            <div className={styles['address-container']}>
              {metro ? this.renderMetro() : null}
              <div className={styles['address']}>{location}</div>
            </div>
            <div className={styles['description']}>{description}</div>
          </div>
          <div className={styles['ad-info']}>
            {label && <span className={styles['ad-label']}>{label}</span>}
            <div className={styles['ad-description']}>
              <UIText2 color="gray40_100">{annotation}</UIText2>
              <ScrollFade size={14} />
            </div>
          </div>
        </a>
        <button aria-label="Закрыть" className={styles['close']} onClick={this.close}>
          <IconClose16 />
        </button>
      </div>
    );
  }

  private track = (isShow: boolean) => {
    const {
      tracking: { user, page },
      context: { bannerId, campaignId },
      shouldSendAdsEvent,
      realtyUserId,
    } = this.props;

    if (!isMortgageCampaign(campaignId)) {
      if (shouldSendAdsEvent && !!bannerId) {
        sendAdsEvent({
          realtyUserId,
          action: isShow ? 'show' : 'click',
          bannerId,
          bannerPlace: 'offersSerp',
        });
      }

      return;
    }
    trackAdFoxMortgage(isShow ? 'show' : 'click', 'LinkIpoteka', bannerId, user, page);
  };

  public onClick = () => {
    this.track(false);
  };

  private renderMetro = () => {
    return (
      <div className={styles['metro-container']}>
        {this.props.context['metroColor'] && (
          <div className={styles['metro-icon']}>
            <UndergroundIcon
              color={`${this.props.context['metroColor']}`}
              height={this.props.locationId ? 16 : 14}
              regionId={this.props.locationId}
              width={this.props.locationId ? 16 : 12}
            />
          </div>
        )}
        <div className={styles['metro-name']}>
          {this.props.context['metro']} • <span>{this.props.context['metroDistance']}</span>
        </div>
      </div>
    );
  };

  private renderGotoButton = () => {
    const { button } = this.props.context;
    const buttonTitle = button || 'Узнать подробнее';

    return (
      <Button size="XS" theme="fill_secondary">
        {buttonTitle}
      </Button>
    );
  };

  private close = () => this.setState({ isClosed: true });
}
