import { useDisablePageScroll } from '@cian/ui-kit/internal/hooks/useDisablePageScroll';

import * as React from 'react';

type DisablePageScrollComponentProps = {
  isFetching: boolean;
};

export const DisablePageScrollComponent: React.FC<DisablePageScrollComponentProps> = props => {
  const { isFetching } = props;

  useDisablePageScroll(isFetching);

  return null;
};
