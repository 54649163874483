import { IAbUseExperiment } from '../types/abUseExperiments';

import { isAbUseExperimentGroup } from './isAbUseExperimentGroup';

export const COWORKING_SNIPPETS_EXPERIMENT_NAME = 'coworking_snippets';

export const SHOW_OFFICE_IN_COWORKING = 'B';
export const COWORKING_CARD_SAME_AS_OFFICE = 'C';
export const HIDE_COWORKING_CARD_DESCRIPTION = 'D';

export const isOfficeInCoworkingTitle = (experiments?: IAbUseExperiment[]): boolean => {
  if (!experiments || !experiments.length) {
    return false;
  }

  return isAbUseExperimentGroup(experiments, COWORKING_SNIPPETS_EXPERIMENT_NAME, SHOW_OFFICE_IN_COWORKING);
};

export const isFlexOfficeCardSameAsOffice = (experiments?: IAbUseExperiment[]): boolean => {
  if (!experiments || !experiments.length) {
    return false;
  }

  return isAbUseExperimentGroup(experiments, COWORKING_SNIPPETS_EXPERIMENT_NAME, COWORKING_CARD_SAME_AS_OFFICE);
};

export const isCoworkingCardDescriptionHidden = (experiments?: IAbUseExperiment[]): boolean => {
  if (!experiments || !experiments.length) {
    return false;
  }

  return isAbUseExperimentGroup(experiments, COWORKING_SNIPPETS_EXPERIMENT_NAME, HIDE_COWORKING_CARD_DESCRIPTION);
};
