import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { IMakeRequest } from '../utils/request';

export interface IDeveloperInfo {
  name: string;
  url: string;
  since?: number;
  newobjectsUrl: string;
  logoUrl?: string;
  flatsStat: {
    url: string;
    flats: {
      count: number;
      url: string;
      roomCountType:
        | 'oneRoom'
        | 'twoRoom'
        | 'threeRoom'
        | 'fourRoom'
        | 'fiveRoom'
        | 'multiRoom'
        | 'studio'
        | 'openPlan';
    }[];
    minPrice?: number;
    maxPrice?: number;
    total: number;
  };
  newobjectsStats: {
    url: string;
    name: string;
  }[];
}

export function getDeveloperInfo(makeRequest: IMakeRequest, id: number) {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-developer-info/',
    parameters: {
      developerId: id,
    },
    method: 'GET',
  })
    .then((response: { data: { data: IDeveloperInfo } }) => response.data.data)
    .catch(ex => {
      if (ex instanceof HttpBadStatusCodeError) {
        if (ex.statusCode === 404) {
          return null;
        }
      }

      throw ex;
    });
}
