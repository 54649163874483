import { isBSCenterPage, isNewobjectPage } from '../filters/helpers';
import { IJsonQuery } from '../json_query';
import { IOffer, TOfferCategory } from '../offer';
import { ILocation, TLocation } from '../types/location';

import { FDealType, FOfferType, isSuburban, offerTypeFromJsonQuery } from './category';

export interface ITrackingLocation {
  fbRegion: string;
  fbCity: string;
}

export type TCategoryObjectType = TOfferCategory | 'businessSale' | 'businessRent' | 'buildingSale' | 'buildingRent';
export type TObjectTypeWithCategory =
  | 'flat_new'
  | 'flat_old'
  | 'commercial'
  | 'suburban'
  | 'suburban_dev'
  | 'flat_old,flat_new';

/**
 * Городские типы недвижимости, вторичка
 */
export const URBAN_OLD_OBJECT_TYPES = [
  'flatRent',
  'flatSale',
  'flatShareRent',
  'flatShareSale',
  'bedRent',
  'bedSale',
  'dailyFlatRent',
  'dailyBedRent',
  'dailyRoomRent',
  'dailyHouseRent',
];

/**
 * Городские типы недвижимости, новостройки
 */
export const URBAN_NEW_OBJECT_TYPES = ['newBuildingFlatRent', 'newBuildingFlatSale'];

/**
 * Коммерческие типы объектов
 */
export const COMMERCIAL_OBJECT_TYPES = [
  'businessRent',
  'businessSale',
  'buildingRent',
  'buildingSale',
  'commercialLandRent',
  'commercialLandSale',
  'freeAppointmentObjectRent',
  'freeAppointmentObjectSale',
  'garageRent',
  'garageSale',
  'industryRent',
  'industrySale',
  'officeRent',
  'officeSale',
  'shoppingAreaRent',
  'shoppingAreaSale',
  'warehouseRent',
  'warehouseSale',
  'publicCateringRent',
  'publicCateringSale',
  'domesticServicesRent',
  'domesticServicesSale',
  'carServiceRent',
  'carServiceSale',
];

/**
 * Загородные типы недвижимости
 */
export const SUBURBAN_OBJECT_TYPES = [
  'houseRent',
  'houseSale',
  'houseShareRent',
  'houseShareSale',
  'townhouseRent',
  'townhouseSale',
  'landRent',
  'landSale',
  'cottageRent',
  'cottageSale',
];

/**
 * 1 - Москва
 * 2 - Санкт-Петербург
 */
export const CITY_WITHOUT_PARENT_ID = [1, 2];

export function getTrackingLocation(currentLocation: TLocation, regions: ILocation[]): ITrackingLocation {
  const trackingLocation: ITrackingLocation = {
    fbRegion: '',
    fbCity: '',
  };

  if (currentLocation === 'moscow_mo') {
    trackingLocation.fbRegion = 'Московская область';
    trackingLocation.fbCity = 'Москва';
  } else if (currentLocation === 'spb_lo') {
    trackingLocation.fbRegion = 'Ленинградская область';
    trackingLocation.fbCity = 'Санкт-Петербург';
  } else {
    /**
     * Если Москва и Санкт-Петербург - отправляем только город, у них нет parentId
     *
     * Если у текущего гео есть parentId, значит текущее гео это город,
     * и нужно попробовать найти его регион по этому parentId.
     *
     * Иначе текущее гео это и есть регион, тогда город не отправляем в аналитику.
     */
    if (CITY_WITHOUT_PARENT_ID.includes(currentLocation.id)) {
      trackingLocation.fbCity = currentLocation.displayName;
    } else if (currentLocation.parentId) {
      const parentRegion = regions.find(region => region.id === (currentLocation as ILocation).parentId);

      trackingLocation.fbRegion = parentRegion ? parentRegion.displayName : '';
      trackingLocation.fbCity = currentLocation.displayName;
    } else {
      trackingLocation.fbRegion = currentLocation.displayName;
    }
  }

  return trackingLocation;
}

const DEFAULT_TRACKING_PARAM_VALUE = 'flat_old,flat_new';

/**
 * Определяет общий objectType на страницу
 */
export const getObjectTypeWithJsonQuery = (jsonQuery: IJsonQuery) => {
  const type = jsonQuery._type;
  const offerType = offerTypeFromJsonQuery(jsonQuery);

  if (type.startsWith('flat')) {
    if (offerType === FOfferType.FlatNew) {
      return 'flat_new';
    } else if (offerType === FOfferType.FlatOld) {
      return 'flat_old';
    } else if (offerType === FOfferType.Flat) {
      return 'flat_old,flat_new';
    } else if (isNewobjectPage(jsonQuery)) {
      return 'JK';
    } else if (isBSCenterPage(jsonQuery)) {
      return 'BC';
    } else {
      return DEFAULT_TRACKING_PARAM_VALUE;
    }
  } else if (type.startsWith('suburban')) {
    if (jsonQuery.suburban_offer_filter && jsonQuery.suburban_offer_filter.value === 1) {
      return 'suburban_dev';
    }

    return 'suburban';
  } else if (type.startsWith('commercial')) {
    return 'commercial';
  } else {
    return DEFAULT_TRACKING_PARAM_VALUE;
  }
};

/**
 * Определяет objectType для конкретного объявления
 */
export const getObjectTypeWithCategory = (
  category: TCategoryObjectType | null,
  fromDeveloper: boolean = false,
): TObjectTypeWithCategory => {
  if (category) {
    if (URBAN_OLD_OBJECT_TYPES.includes(category)) {
      return 'flat_old';
    }

    if (URBAN_NEW_OBJECT_TYPES.includes(category)) {
      return 'flat_new';
    }

    if (SUBURBAN_OBJECT_TYPES.includes(category)) {
      return fromDeveloper ? 'suburban_dev' : 'suburban';
    }

    if (COMMERCIAL_OBJECT_TYPES.includes(category)) {
      return 'commercial';
    }
  }

  return 'flat_old,flat_new';
};

export const getDealType = (dealType: FDealType) =>
  (dealType === FDealType.Sale && 'sale') ||
  (dealType === FDealType.RentDaily && 'rent_day') ||
  (dealType === FDealType.RentLongterm && 'rent_long') ||
  (dealType === FDealType.Rent && 'rent_long') ||
  'other';

export const getPaymentType = (accountType?: 'natural' | 'legal') =>
  (accountType === 'natural' && 'fizlico') || (accountType === 'legal' && 'urlico') || undefined;

export const getOfferParentId = (offer: IOffer, jsonQuery: IJsonQuery): number | null => {
  const notSuburban = !isSuburban(offerTypeFromJsonQuery(jsonQuery));

  const businessShoppingCenterId =
    offer.businessShoppingCenter && (offer.businessShoppingCenter.parentId || offer.businessShoppingCenter.id);

  const parentId =
    (notSuburban && offer.newbuilding && offer.newbuilding.id) ||
    (notSuburban && businessShoppingCenterId) ||
    (offer.kp && offer.kp.id);

  return parentId ? Number(parentId) : null;
};

export const getHeadline = (offer: IOffer): boolean => {
  const { isTop3, isPremium, title } = offer;

  return !!((isTop3 || isPremium) && title);
};
