import { CustomTooltip } from '@cian/ui-kit';
import { IStyleConfig, mergeStyles } from '@cian/utils';

import * as PropTypes from 'prop-types';
import * as React from 'react';

import { TAgentRating } from 'shared/api/agent';
import { IOffer } from 'shared/offer';
import { IJsonQuery } from 'shared/repositories/common/json_query';

import { IOfferHelper } from '../../presenter';
import { ShowMoreOffers } from '../showMoreOffers';

import { IconBlacklist, IconWhitelist } from './icons';

import * as styles from './UserInfoBrandPopup.css';

export interface IUserInfoBrandPopupAPI {
  ratingBlockedAgents: number[];
  userIsAuthenticated: boolean;
  onAgentRatingChanged(offer: IOffer, rating: TAgentRating): void;
  onPopupOpen?(): void;
  onPopupClose?(): void;
}

export interface IUserInfoBrandPopupProps {
  content: React.ReactNode;
  avatar?: React.ReactNode | null;
  containerStyle?: IStyleConfig;
  userInfoStyle?: IStyleConfig;
  userInfoStyleAction?: IStyleConfig;
  isReverseOrder?: boolean;
}

export interface IUserInfoBrandPopupState {
  isPopupVisible: boolean;
}

export interface IUserInfoBrandPopupContext {
  baseUrl: string;
  jsonQuery: IJsonQuery;
  offer: IOffer;
  offerHelper: IOfferHelper;

  api: { userInfo: IUserInfoBrandPopupAPI };
}

export class UserInfoBrandPopup extends React.Component<IUserInfoBrandPopupProps, IUserInfoBrandPopupState> {
  private userInfoRef = React.createRef<HTMLDivElement>();
  public state = {
    isPopupVisible: false,
  };

  public context: IUserInfoBrandPopupContext;

  public static contextTypes = {
    api: PropTypes.object,
    baseUrl: PropTypes.string,
    jsonQuery: PropTypes.object,
    offer: PropTypes.object,
    offerHelper: PropTypes.object,
  };

  public componentDidUpdate(prevProps: IUserInfoBrandPopupProps, prevState: IUserInfoBrandPopupState) {
    if (prevState.isPopupVisible !== this.state.isPopupVisible) {
      if (this.state.isPopupVisible) {
        if (this.context.api.userInfo.onPopupOpen) {
          this.context.api.userInfo.onPopupOpen();
        }
      } else {
        if (this.context.api.userInfo.onPopupClose) {
          this.context.api.userInfo.onPopupClose();
        }
      }
    }
  }

  public render() {
    const { userIsAuthenticated } = this.context.api.userInfo;
    const { avatar, content, userInfoStyle, userInfoStyleAction, isReverseOrder } = this.props;
    const { isPopupVisible } = this.state;

    const offer = this.context.offerHelper;

    const userPersonalRating = offer.presenters.getUserPersonalRating();

    return (
      <div
        {...mergeStyles([
          styles['container'],
          isReverseOrder && styles['container-reverse'],
          this.props.containerStyle,
        ])}
      >
        <div>
          <div
            {...mergeStyles([styles['user_info'], userInfoStyle, isPopupVisible && userInfoStyleAction])}
            ref={this.userInfoRef}
            onClick={this.handleInfoClick}
          >
            {content}
          </div>
          <CustomTooltip
            anchorRef={this.userInfoRef}
            arrow={true}
            content={
              <div className={styles['popup']}>
                {isReverseOrder && this.renderLink()}
                {isReverseOrder && userIsAuthenticated && <hr className={styles['delimiter']} />}
                {userIsAuthenticated && (
                  <ul className={styles['links']}>
                    <li className={styles['links-link']}>
                      <a
                        className={styles['link-item']}
                        href="#"
                        onClick={event =>
                          this.handleListClick(event, userPersonalRating !== 'negative' ? 'negative' : 'neutral')
                        }
                      >
                        <IconBlacklist isActive={userPersonalRating === 'negative'} />
                        {userPersonalRating !== 'negative' ? 'В чёрный список' : 'Убрать из чёрного списка'}
                      </a>
                    </li>
                    <li className={styles['links-link']}>
                      <a
                        className={styles['link-item']}
                        href="#"
                        onClick={event =>
                          this.handleListClick(event, userPersonalRating !== 'positive' ? 'positive' : 'neutral')
                        }
                      >
                        <IconWhitelist isActive={userPersonalRating === 'positive'} />
                        {userPersonalRating !== 'positive' ? 'В белый список' : 'Убрать из белого списка'}
                      </a>
                    </li>
                  </ul>
                )}
                {!isReverseOrder && userIsAuthenticated && <hr className={styles['delimiter']} />}
                {!isReverseOrder && this.renderLink()}
              </div>
            }
            flip={false}
            open={isPopupVisible}
            outside={true}
            placement="bottom-start"
            theme="white"
            onClose={this.handleOutsideClick}
          />
        </div>
        {avatar}
      </div>
    );
  }

  private renderLink() {
    const { isReverseOrder } = this.props;
    const { baseUrl } = this.context;
    const profileUri = this.getProfileUri();

    return (
      <div className={styles['all_offers']}>
        {isReverseOrder && profileUri && (
          <a className={styles['offer-link']} href={`${baseUrl || ''}${profileUri}`} rel="noreferrer" target="_blank">
            Профиль агента
          </a>
        )}
        <ShowMoreOffers containerStyle={styles['offer-link']}>Все объявления агента</ShowMoreOffers>
      </div>
    );
  }

  private getProfileUri = () => {
    const { offer } = this.context;
    const user = offer.user;

    if (!user) {
      return null;
    }

    if (user.masterAgent) {
      return user.masterAgent.profileUri;
    }

    return user.profileUri;
  };

  private handleListClick = (event: React.MouseEvent<HTMLAnchorElement>, rating: TAgentRating) => {
    event.preventDefault();

    this.context.api.userInfo.onAgentRatingChanged(this.context.offer, rating);
  };

  private handleInfoClick = () => {
    this.setState({
      isPopupVisible: !this.state.isPopupVisible,
    });
  };

  private handleOutsideClick = () => {
    if (!this.state.isPopupVisible) {
      return;
    }

    this.setState({
      isPopupVisible: false,
    });
  };
}
