import { mergeStyles } from '@cian/utils/lib/shared/style';

import * as React from 'react';

const style = require('./index.css');

interface ITextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  onValueChange?(value: string): void;
}

export class Textarea extends React.Component<ITextareaProps> {
  private textarea: HTMLTextAreaElement | null = null;

  public render() {
    const { onValueChange, ...sharedProps } = this.props;

    return (
      <textarea
        ref={textarea => (this.textarea = textarea)}
        {...sharedProps}
        {...mergeStyles(style['element'], sharedProps.className, sharedProps.style)}
        onChange={event => {
          if (sharedProps.onChange) {
            sharedProps.onChange(event);
          }

          if (!event.defaultPrevented && onValueChange) {
            onValueChange(event.currentTarget.value);
          }
        }}
      />
    );
  }

  public focus = () => this.textarea && this.textarea.focus();
}
