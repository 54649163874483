import { ModalWindow } from '@cian/ui-kit';

import { Component } from 'react';

import { IMapAreaModels, Map } from '../../../common/components/map/';
import { IJsonQueryGeo } from '../../../json_query';
import { TMapObjectData } from '../../state/map/map_changed';

const style = require('./index.css');

export interface IMapModalProps {
  center: [number, number];
  mapRegions: IJsonQueryGeo;

  close(): void;
  onChanged(data: TMapObjectData[]): void;
  onAreaDeleted(area: TMapObjectData): void;
}

// Не используется, нужно удалить в будущем
export class MapModal extends Component<IMapModalProps> {
  public render() {
    return (
      <ModalWindow
        open
        // Весь файл будет удален при удалении старых фильтров, оставил чтобы было легко восстановить, если
        // вдруг потребуется
        footer={null}
        maxWidth={1192}
        width="85%"
        onClose={this.props.close}
      >
        <div className={style.mapModal}>
          <Map
            areaModels={this.getAreaObjects()}
            center={this.props.center}
            onChange={this.props.onChanged}
            onDelete={this.props.onAreaDeleted}
          />
        </div>
      </ModalWindow>
    );
  }

  private getAreaObjects = (): IMapAreaModels | null => {
    if (!this.props.mapRegions) {
      return null;
    }

    const areaObjects = this.props.mapRegions.value.reduce(
      (acc: IMapAreaModels, value) => {
        switch (value.type) {
          case 'polygon':
            acc.polygons.push(value);
            break;
          case 'distance':
            acc.circles.push(value);
            break;
          default:
            break;
        }

        return acc;
      },
      {
        polygons: [],
        circles: [],
      },
    );

    return areaObjects;
  };
}
