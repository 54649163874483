import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IMortgagePossibleTagType = 'advanced-saleType-mortgagePossible';

export interface IMortgagePossibleTag {
  type: 'advanced-saleType-mortgagePossible';
}

export const isMortgagePossibleTag = (tag: TTag): tag is IMortgagePossibleTag => tag.type === IMortgagePossibleTagType;

export const mortgagePossibleTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.ipoteka && jsonQuery.ipoteka.value;

    if (value) {
      return ensureSingleTagExists(tags, isMortgagePossibleTag, () => ({ type: IMortgagePossibleTagType }));
    } else {
      return ensureTagNotExists(tags, isMortgagePossibleTag);
    }
  },
};
