import { ca } from '@cian/analytics';

export function trackingShowPopup() {
  ca('event', {
    name: 'oldevent',
    category: 'SyncFavorites_popup',
    action: 'show',
    label: '',
  });
}

export function trackingEnter() {
  ca('event', {
    name: 'oldevent',
    category: 'SyncFavorites_popup',
    action: 'click',
    label: 'login',
  });
}

export function trackingCreate() {
  ca('event', {
    name: 'oldevent',
    category: 'SyncFavorites_popup',
    action: 'click',
    label: 'registration',
  });
}
