import { call, put, select, take } from 'redux-saga/effects';

import { IQsToUris } from '../../api/offers';
import { getSuggestionsDistances } from '../../api/suggestions';
import { IAppState, TActions } from '../../common/state';

export interface ISuggestionsDistancesState {
  isLoad: boolean;
  data?: ISuggestionsDistances[] | null;
}

export interface ISuggestionsDistances {
  offerId: number;
  distance: number;
}

export interface ICoordinates {
  lat: number;
  lng: number;
}

export interface ICoordinatesOffers {
  offerId: number;
  coordinates: ICoordinates;
}

export interface ISuggestionsGetDistaces {
  type: 'serp/suggestion_list/SUGGESTIONS_GET_DISTANCES';
  coordinatesOffers?: ICoordinatesOffers[];
}

export interface ISuggestionsSuccessDistaces {
  type: 'serp/suggestion_list/SUGGESTIONS_SUCCESS_DISTANCES';
  payload: {
    suggestionsDistances: ISuggestionsDistances[] | null;
  };
}

export interface ISuggestionsGetQsToUris {
  type: 'serp/suggestion_list/QS_TO_URIS';
  payload: {
    qsToUris: IQsToUris;
  };
}

export interface ISetSuggestionDistancesSeoText {
  type: 'serp/suggestion_list/SET_SUGGESTION_DISTANCES_SEO_TEXT';
  payload: {
    text: string;
  };
}

export function setQsToUris(qsToUris: IQsToUris): ISuggestionsGetQsToUris {
  return {
    payload: {
      qsToUris,
    },
    type: 'serp/suggestion_list/QS_TO_URIS',
  };
}

export function setSuggestionDistancesSeoText(text: string): ISetSuggestionDistancesSeoText {
  return {
    payload: {
      text,
    },
    type: 'serp/suggestion_list/SET_SUGGESTION_DISTANCES_SEO_TEXT',
  };
}

export function requestSuggestionsDistances(coordinatesOffers?: ICoordinatesOffers[]): ISuggestionsGetDistaces {
  if (coordinatesOffers) {
    return {
      coordinatesOffers,
      type: 'serp/suggestion_list/SUGGESTIONS_GET_DISTANCES',
    };
  }

  return {
    type: 'serp/suggestion_list/SUGGESTIONS_GET_DISTANCES',
  };
}

export function* suggestionsDistacesSaga() {
  while (true) {
    const { coordinatesOffers } = yield take('serp/suggestion_list/SUGGESTIONS_GET_DISTANCES');

    const state: IAppState = yield select();
    const { makeRequest, results } = state;
    const { suggestions } = results;

    if (!suggestions) {
      yield put({
        type: 'serp/suggestion_list/SUGGESTIONS_SUCCESS_DISTANCES',
        payload: {
          suggestionsDistances: null,
        },
      });

      return;
    }

    const reqBody = coordinatesOffers
      ? { queryString: results.queryString, coordinatesOffers }
      : {
          queryString: results.queryString,
          coordinatesOffers: suggestions
            .map(suggestion => {
              const { geo } = suggestion;

              if (!geo) {
                return null;
              }

              return {
                offerId: suggestion.id,
                coordinates: {
                  lat: geo.coordinates.lat,
                  lng: geo.coordinates.lng,
                },
              };
            })
            .filter(Boolean),
        };

    const suggestionsDistances: ISuggestionsDistances[] = yield call(getSuggestionsDistances, makeRequest, reqBody);

    yield put({
      type: 'serp/suggestion_list/SUGGESTIONS_SUCCESS_DISTANCES',
      payload: {
        suggestionsDistances,
      },
    });
  }
}

export function suggestionsDistancesReducer(state: IAppState, action: TActions) {
  switch (action.type) {
    case 'serp/suggestion_list/SUGGESTIONS_GET_DISTANCES':
      return {
        ...state,
        suggestionsDistances: {
          ...state.suggestionsDistances,
          isLoad: true,
        },
      };

    case 'serp/suggestion_list/SUGGESTIONS_SUCCESS_DISTANCES':
      return {
        ...state,
        suggestionsDistances: {
          ...state.suggestionsDistances,
          isLoad: false,
          data:
            state.suggestionsDistances && state.suggestionsDistances.data
              ? [...state.suggestionsDistances.data, ...(action.payload.suggestionsDistances || [])]
              : action.payload.suggestionsDistances,
        },
      };

    case 'serp/suggestion_list/QS_TO_URIS':
      return {
        ...state,
        results: {
          ...state.results,
          qsToUris: state.results.qsToUris
            ? Object.assign({}, state.results.qsToUris, action.payload.qsToUris)
            : action.payload.qsToUris,
        },
      };

    case 'serp/suggestion_list/SET_SUGGESTION_DISTANCES_SEO_TEXT':
      return {
        ...state,
        suggestionDistancesSeoText: action.payload.text,
      };

    default:
      return state;
  }
}
