import { IAppState, TActions } from '../../../common/state';
import { EConditionType, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TManufactureConditionChangedActionType = 'filters/manufacture_condition/MANUFACTURE_CONDITION_CHANGED';
export const IManufactureConditionChangedActionType =
  'filters/manufacture_condition/MANUFACTURE_CONDITION_CHANGED' as TManufactureConditionChangedActionType;

export interface IManufactureConditionChangedAction {
  type: 'filters/manufacture_condition/MANUFACTURE_CONDITION_CHANGED';
  paramType: EConditionType;
  value: boolean;
}

export const changeManufactureCondition = getParamChangedAction<EConditionType, TManufactureConditionChangedActionType>(
  IManufactureConditionChangedActionType,
);

export function manufactureConditionTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IManufactureConditionChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setManufactureCondition(action.paramType)
            : jq(state.filters.jsonQuery).unsetManufactureCondition(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-manufactureCondition') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetManufactureCondition(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetManufactureConditions(),
        },
      };

    default:
      return state;
  }
}
