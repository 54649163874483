import { plural } from '@cian/utils';

export const getTitle =
  (workplaceCount: number | null) =>
  (pluralVariants: [string, string, string], defaultValue: string): string => {
    if (workplaceCount) {
      return `${workplaceCount} ${plural(workplaceCount, pluralVariants)}`;
    }

    return defaultValue;
  };
