import { IAppState, TActions } from '../../../common/state';

export const IOfferIdChangedActionType = 'filters/offer_id/OFFER_ID_CHANGED';

export interface IOfferIdChangedAction {
  type: 'filters/offer_id/OFFER_ID_CHANGED';
  offerId: number | void;
}

export function changeOfferId(offerId: number | void): IOfferIdChangedAction {
  return {
    type: IOfferIdChangedActionType,
    offerId,
  };
}

export function offerIdReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IOfferIdChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            id: action.offerId
              ? {
                  type: 'term',
                  value: action.offerId,
                }
              : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-offerId') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              id: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            id: undefined,
          },
        },
      };

    default:
      return state;
  }
}
