import { useSelector } from 'react-redux';

import { IAppState } from 'shared/common/state';

import { useApplicationContext } from '../../../../serp/containers/ApplicationContext';
import { MULTI_ADS_OFFICE_TYPES } from '../constants';

export function useIsMultiAdsBannerEnabled() {
  const { config } = useApplicationContext();

  /**
   * @todo удалить фича-тоггл isMultiAdsBannerEnabled
   * @description Данный функционал появился в задаче CD-154315, будет удалён в задаче CD-154416
   */
  const isMultiAdsBannerEnabled = config.get<boolean>('Commercial.MultiAdsBanner.Enabled');

  const isBannerVisible = useSelector<IAppState, boolean>(state => {
    const {
      results: {
        jsonQuery: { office_type, coworking_offer_type },
      },
    } = state;

    const isCoworking = Boolean(coworking_offer_type?.value?.length);

    return MULTI_ADS_OFFICE_TYPES.some(el => office_type?.value.includes(el)) && !isCoworking;
  });

  return isBannerVisible && isMultiAdsBannerEnabled;
}
