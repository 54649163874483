import { Button, IconHide16 } from '@cian/ui-kit';

import classNames from 'classnames';
import * as React from 'react';

interface IHideButtonProps {
  isFetching?: boolean;
  isDisabled?: boolean;
  className?: string;
  hideOffer?(): void;
  restoreOffer?(): void;
}

export const HideButton: React.VFC<IHideButtonProps> = props => {
  const { isDisabled, hideOffer, className, isFetching } = props;

  const onHideClick = React.useCallback(() => {
    if (isFetching || !hideOffer) {
      return;
    }

    hideOffer();
  }, [isFetching, hideOffer]);

  return (
    <div className={classNames(className)} title="Скрыть объявление">
      <Button
        beforeIcon={<IconHide16 />}
        disabled={isDisabled}
        loading={isFetching}
        size="XS"
        theme="stroke_secondary"
        onClick={onHideClick}
      />
    </div>
  );
};
