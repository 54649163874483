import { ca } from '@cian/analytics';

export function trackMapViewButtonClicked() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Skin',
    action: 'MAP',
    label: 'LIST',
  });
}

export function trackTableViewButtonClicked() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Skin',
    action: 'TABLE',
    label: 'LIST',
  });
}
