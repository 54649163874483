import { connect } from 'react-redux';

import { TBSCentersSuggestionShort } from '../../../api/geosuggest/bs_centers';
import { Dispatch, IAppState } from '../../../common/state';
import { ILocation, TLocation } from '../../../types/location';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from '../../../utils/category';
import { IDirection } from '../../api/directions';
import { selectBSCenter } from '../../state/bs_center';
import { ICoworkingSuggestShort, setCoworkingId } from '../../state/coworking_id';
import { handleReceiveDirectionsData, selectHighways } from '../../state/directions';
import { IGeoObject, IPolygonObject, selectGeo } from '../../state/geo';
import { ISelectKPParams, selectKP } from '../../state/kp_id';
import { makeSearch } from '../../state/search';

import { getSuburbanOfferFilterValue } from './helpers';

import { GeoSuggest } from './index';

export interface IGeoSuggestContainerOwnProps {
  rightAddon?: React.ReactNode;
  redesign?: boolean;
  onHighwaySelected?(): void;
  onGeoSelected?(): void;
  onBSCenterSelected?(): void;
  onKPSelected?(id: number): void;
  onCoworkingSelected?(id: number): void;
}

export function mapStateToProps(state: IAppState) {
  const { jsonQuery, currentLocation, regions, directionsModal } = state.filters;
  const suburbanOfferFilterValue = jsonQuery.suburban_offer_filter && jsonQuery.suburban_offer_filter.value;

  return {
    makeRequest: state.makeRequest,
    logger: state.logger,
    dealType: dealTypeFromJsonQuery(jsonQuery),
    offerType: offerTypeFromJsonQuery(jsonQuery),
    suburbanOfferFilter: getSuburbanOfferFilterValue(suburbanOfferFilterValue),
    highwaysData: directionsModal.data,
    currentLocation,
    boundedBy: getBoundedBy(currentLocation, regions),
    httpApi: state.httpApi,
    isCoworkingSearch: !!jsonQuery.coworking_offer_type?.value?.length,
  };
}

export function mapDispatchToProps(dispatch: Dispatch, ownProps: IGeoSuggestContainerOwnProps) {
  const { redesign, onGeoSelected, onHighwaySelected, onBSCenterSelected, onKPSelected, onCoworkingSelected } =
    ownProps;

  return {
    onGeoSelected: (value: IGeoObject | IPolygonObject, userInput: string) => {
      if (onGeoSelected) {
        onGeoSelected();
      }

      dispatch(selectGeo(value, userInput));
      if (redesign && value.type === 'location') {
        dispatch(makeSearch());
      }
    },
    onHighwaySelected: (highwaysIds: number[], regionId?: number) => {
      if (onHighwaySelected) {
        onHighwaySelected();
      }

      dispatch(selectHighways(highwaysIds, regionId));
    },
    directionsDataReceived: (directionsData: IDirection[], regionId: number) => {
      dispatch(handleReceiveDirectionsData(directionsData, regionId));
    },
    onBSCenterSelected: (bsCenter: TBSCentersSuggestionShort) => {
      if (onBSCenterSelected) {
        onBSCenterSelected();
      }

      dispatch(selectBSCenter(bsCenter));
    },
    onKPSelected: (params: ISelectKPParams) => {
      if (onKPSelected) {
        onKPSelected(params.id);
      }

      dispatch(selectKP(params));
    },
    onCoworkingSelected: (coworkingSuggested: ICoworkingSuggestShort) => {
      if (onCoworkingSelected) {
        onCoworkingSelected(coworkingSuggested.id);
      }

      dispatch(setCoworkingId(coworkingSuggested));
    },
  };
}

export const GeoSuggestContainer = connect(mapStateToProps, mapDispatchToProps)(GeoSuggest);

export function getBoundedBy(currentLocation: TLocation, regions: ILocation[]) {
  let location: ILocation | undefined;

  if (currentLocation === 'moscow_mo') {
    const region = regions.filter(r => r.id === 4593).shift();

    if (region) {
      location = region;
    }
  } else if (currentLocation === 'spb_lo') {
    const region = regions.filter(r => r.id === 4588).shift();

    if (region) {
      location = region;
    }
  } else if (!currentLocation.boundedBy && currentLocation.parentId) {
    const region = regions.filter(r => r.id === currentLocation.parentId).shift();

    if (region) {
      location = region;
    }
  } else {
    location = currentLocation;
  }

  if (!location || !location.boundedBy) {
    return undefined;
  }

  return [
    [location.boundedBy.upperCorner.lng, location.boundedBy.upperCorner.lat],
    [location.boundedBy.lowerCorner.lng, location.boundedBy.lowerCorner.lat],
  ] as [YMaps.TCoord, YMaps.TCoord];
}
