import { ca } from '@cian/analytics';

export function trackSubscribed(subscribeToNews?: boolean) {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Send',
    label: 'From_cat_save/Hourly',
  });

  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Send',
    label: 'From_cat_save_infinite',
  });

  if (subscribeToNews) {
    ca('event', {
      name: 'oldevent',
      category: 'subscription',
      action: 'Send',
      label: 'news_From_cat_save',
    });
  }
}
