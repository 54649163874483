import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { prepareOffers } from 'shared/mappers/prepareOffers';
import {
  IOffersGetOffersRequest,
  IOffersGetOffersResponse200,
  IOffersGetOffersResponse400,
  fetchOffersGetOffers,
} from 'shared/repositories/commercial-search-offers/v1/offers-get-offers';
import { IApplicationContext } from 'shared/types/applicationContext';
import { IOffersData } from 'shared/types/offersData';

export async function fetchOffers(
  { httpApi, logger }: Pick<IApplicationContext, 'httpApi' | 'logger'>,
  parameters: IOffersGetOffersRequest,
): Promise<IOffersData> {
  const result = await fetchOffersGetOffers<IOffersGetOffersResponse200, IOffersGetOffersResponse400>({
    httpApi,
    parameters,
  });

  switch (result.statusCode) {
    case 200:
      return prepareOffers(result.response);
    case 400:
    default: {
      const err = new HttpBadStatusCodeError({
        domain: 'src/shared/services/fetchOffers/fetchOffers.ts',
        message: result.response.message,
        details: {
          error: result.response.errors,
        },
        statusCode: result.statusCode,
      });

      logger.error(err);

      throw err;
    }
  }
}
