import { IAppState, TActions } from '../../../common/state';
import { jq } from '../../../json_query';

type TAccessSystemChangedActionType = 'filters/access_system/ACCESS_SYSTEM_CHANGED';
export const IAccessSystemChangedActionType =
  'filters/access_system/ACCESS_SYSTEM_CHANGED' as TAccessSystemChangedActionType;

export interface IAccessSystemChangedAction {
  type: 'filters/access_system/ACCESS_SYSTEM_CHANGED';
  value: boolean | undefined;
}

export const changeAccessSystem = (value: boolean | undefined): IAccessSystemChangedAction => ({
  type: IAccessSystemChangedActionType,
  value,
});

export const accessSystemReducer = (state: IAppState, action: TActions): IAppState => {
  switch (action.type) {
    case IAccessSystemChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setAccessSystem(action.value),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-accessSystem') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setAccessSystem(undefined),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setAccessSystem(undefined),
        },
      };

    default:
      return state;
  }
};
