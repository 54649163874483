import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IAccessSystemTagType = 'advanced-accessSystem';

export interface IAccessSystemTag {
  type: 'advanced-accessSystem';
  value: boolean;
}

const isAccessSystemTag = (tag: TTag): tag is IAccessSystemTag => tag.type === IAccessSystemTagType;

export const accessSystemTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.enter && jsonQuery.enter.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isAccessSystemTag, () => ({ type: IAccessSystemTagType, value }));
    } else {
      return ensureTagNotExists(tags, isAccessSystemTag);
    }
  },
};
