import { ca } from '@cian/analytics';

export function trackShowPopup() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Profile_user_comm_popup',
    action: 'Show',
    label: '',
  });
}
