import { PureComponent } from 'react';

import { trackPreInfiniteEmptyShow } from './tracking';

import * as styles from './styles.css';

export class EmptySearchContent extends PureComponent<{}> {
  public componentDidMount() {
    trackPreInfiniteEmptyShow();
  }

  public render() {
    return (
      <>
        <h3 className={styles['banner-text-bold']}>Упс... По такому запросу объявления еще не разместили</h3>
        <p>{'>'} Попробуйте что-нибудь изменить в фильтрах</p>
        <p>{'>'} Посмотрите похожие предложения ниже</p>
      </>
    );
  }
}
