import { IAddressesTag, IGeoTagsData, TGeoTagsData } from '../filters/api';
import { IDirection } from '../filters/api/directions';

export function getSuggestionDistancesSeoText(geoTagsData: IGeoTagsData | null, directions: IDirection[] | null) {
  let suggestionDistancesSeoText = '';

  if (geoTagsData) {
    const tagKeys = Object.keys(geoTagsData.geoTags).filter(
      (key: keyof typeof geoTagsData.geoTags) => geoTagsData.geoTags[key].length !== 0,
    );

    //если несколько тегов, не делаем предположение о расстоянии
    if (tagKeys.length === 1) {
      const tagKey = tagKeys[0];
      const tag = geoTagsData.geoTags[tagKey as TGeoTagsData][0];

      if (tagKey === 'metro_tags') {
        suggestionDistancesSeoText = `метро ${tag.text}`;
      } else if (['district_tags', 'developer_tags', 'newobject_tags'].includes(tagKey)) {
        suggestionDistancesSeoText = tag.text;
      } else if (tagKey === 'address_tags') {
        const tagGeoData = (tag as IAddressesTag).addressNodes[0];
        switch (tagGeoData.geoType) {
          case 'Street':
            suggestionDistancesSeoText = `ул. ${tagGeoData.name}`;
            break;
          case 'Underground':
            suggestionDistancesSeoText = `метро ${tagGeoData.name}`;
            break;
          case 'House':
            suggestionDistancesSeoText = tag.text.split(', ').slice(-2).join(', ');
            break;
          case 'Country':
          case 'Location':
          case 'Road':
          case 'District':
            suggestionDistancesSeoText = tagGeoData.name;
            break;
          default:
        }
      }
    }
  }

  if (directions && directions.length) {
    suggestionDistancesSeoText = directions.length === 1 ? directions[0].name : '';
  }

  return suggestionDistancesSeoText;
}
