import { FC } from 'react';

import { CoworkingProfitBanner } from '../../common/components/CoworkingProfitBanner';

import { useIsCoworkingProfitBannerEnabled } from './internal/hooks';
import { ICoworkingProfitBannerContainerProps } from './types';

export const CoworkingProfitBannerContainer: FC<ICoworkingProfitBannerContainerProps> = props => {
  const { containerClassName } = props;

  const isCoworkingProfitBannerEnabled = useIsCoworkingProfitBannerEnabled();

  if (isCoworkingProfitBannerEnabled) {
    return <CoworkingProfitBanner containerClassName={containerClassName} />;
  }

  return null;
};

CoworkingProfitBannerContainer.displayName = 'CoworkingProfitBannerContainer';
