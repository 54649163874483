import { IQuestionField, useFormField } from '@cian/frontend-request-form-widget';
import { IMultiSelectProps } from '@cian/ui-kit';

import * as React from 'react';

import { Multiselect as Component } from '../Multiselect/Multiselect';

export const MultiselectWithoutEmptyChoice: React.FC<IQuestionField> = props => {
  const {
    field: { value },
    helpers: { setValue },
  } = useFormField<string[]>({ name: props.name });

  const options = React.useMemo(
    () =>
      props.answers?.map(answer => ({
        label: answer.label,
        value: answer.value,
      })) || [],
    [],
  );

  const handleChange = React.useCallback<NonNullable<IMultiSelectProps['onChange']>>(value => {
    if (value.length) {
      setValue(value as string[]);
    }
  }, []);

  return (
    <Component
      label={props.label}
      options={options}
      placeholder={props.placeholder}
      value={value}
      onChange={handleChange}
    />
  );
};

MultiselectWithoutEmptyChoice.displayName = 'MultiselectWithoutEmptyChoiceContainer';
