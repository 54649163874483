import { PureComponent } from 'react';

import { EObjectType } from 'shared/repositories/common/json_query';
import { IKpOffersByCategoryCounter } from 'shared/repositories/search-offers.legacy/v2/search-offers-desktop.data';

import { FOfferType } from '../../../../utils/category';

import { trackEmptyListingLinkClick } from './tracking';

import * as styles from './styles.css';

export interface IEmptyKpSearchStoreProps {
  url: string;
  objectType: EObjectType[];
  offersCounter: IKpOffersByCategoryCounter | null;
  kpId: number;
}

type TKpOffersCounter = Pick<IKpOffersByCategoryCounter, 'houseSale' | 'houseShareSale' | 'townhouseSale' | 'landSale'>;

const COUNTERS_LABELS_MAP: { [key: string]: string } = {
  townhouseSale: 'Таунхаусы',
  houseSale: 'Дома',
  houseShareSale: 'Части дома',
  landSale: 'Участки',
};

const COUNTERS_KEYS_OBJECT_TYPE_MAP: { [key: string]: number } = {
  townhouseSale: EObjectType.Townhouse,
  houseSale: EObjectType.House,
  houseShareSale: EObjectType.Housepart,
  landSale: EObjectType.Land,
};

const OBJECT_TYPE_LABELS_MAP: { [key: number]: string } = {
  [EObjectType.House]: 'домов',
  [EObjectType.Housepart]: 'частей дома',
  [EObjectType.Townhouse]: 'таунхаусов',
  [EObjectType.Land]: 'участков',
};

const COUNTERS_OFFER_TYPE_MAP: { [key: string]: number } = {
  townhouseSale: FOfferType.Townhouse,
  houseSale: FOfferType.House,
  houseShareSale: FOfferType.HousePart,
  landSale: FOfferType.Land,
};

export class EmptyKpSearch extends PureComponent<IEmptyKpSearchStoreProps> {
  public render() {
    const { kpId } = this.props;
    const offersCounter = this.getOffersCounter();
    const totalItems = this.getTotalItems();
    if (!kpId || !offersCounter || !totalItems) {
      return null;
    }
    const offersCounterKeys = Object.keys(offersCounter);

    return (
      <div className={styles['container']}>
        <div className={styles['title']}>В этом котт. поселке {this.objectsLabel} не найдено, зато есть:</div>
        <div className={styles['links']}>
          {offersCounterKeys.map((key: keyof TKpOffersCounter) => (
            <div className={styles['link-container']} key={key}>
              <a
                className={styles['link']}
                href={this.getLink(COUNTERS_KEYS_OBJECT_TYPE_MAP[key])}
                rel="noreferrer"
                target="_blank"
                onClick={this.onLinkClick(key)}
              >
                {COUNTERS_LABELS_MAP[key]}
              </a>
              <span className={styles['counter']}>{offersCounter[key]}</span>
            </div>
          ))}
          {offersCounterKeys.length > 1 && (
            <div className={styles['link-container']}>
              <span className={styles['dot']}>·</span>
              <a
                className={styles['link']}
                href={this.getLink()}
                rel="noreferrer"
                target="_blank"
                onClick={this.onLinkClick()}
              >
                Все предложенные
              </a>
              <span className={styles['counter']}>{totalItems}</span>
            </div>
          )}
        </div>
      </div>
    );
  }

  private onLinkClick = (key?: keyof TKpOffersCounter) => () => {
    const offerType = key ? COUNTERS_OFFER_TYPE_MAP[key] : FOfferType.Suburban;
    trackEmptyListingLinkClick(offerType, this.props.kpId);
  };

  private getOffersCounter(): TKpOffersCounter | undefined {
    const { offersCounter } = this.props;
    if (!offersCounter) {
      return undefined;
    }
    const { cottageSale, ...rest } = offersCounter;
    if (cottageSale) {
      rest.houseSale = cottageSale + (rest.houseSale || 0);
    }

    return Object.keys(rest).reduce((acc: TKpOffersCounter, key: keyof TKpOffersCounter) => {
      const value = Number(rest[key]);

      return value ? { ...acc, [key]: value } : acc;
    }, {});
  }

  private get objectsLabel() {
    return this.props.objectType.map(value => OBJECT_TYPE_LABELS_MAP[value]).join(', ');
  }

  /* Данное решение временное пока фича под экспериментом и бэк не присылает нужных ссылок */
  private getLink(objectType?: EObjectType) {
    const objectTypeQuery = objectType
      ? `object_type%5B0%5D=${objectType}`
      : `object_type%5B0%5D=1&object_type%5B1%5D=2&object_type%5B2%5D=3&object_type%5B3%5D=4`;

    return this.props.url.replace(/object_type.*&offer_type/, `${objectTypeQuery}&offer_type`);
  }

  private getTotalItems = () => {
    const offersCounter = this.getOffersCounter();
    if (!offersCounter) {
      return 0;
    }

    return Object.keys(offersCounter).reduce((acc: number, key: keyof TKpOffersCounter) => {
      const delta = offersCounter[key] || 0;

      return acc + delta;
    }, 0);
  };
}
