export abstract class RemoveAllComponentCopiesService {
  private static readonly callbackCollection = new Map<ThisType<RemoveAllComponentCopiesService>, VoidFunction>();

  public executeEachOtherInCallbackCollection() {
    Array.from(RemoveAllComponentCopiesService.callbackCollection).forEach(([key, callback]) => {
      if (this !== key) {
        callback();
      }
    });

    RemoveAllComponentCopiesService.callbackCollection.clear();
  }

  public constructor(callback: VoidFunction) {
    RemoveAllComponentCopiesService.callbackCollection.set(this, callback);
  }
}
