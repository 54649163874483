import { mergeStyles } from '@cian/utils';

import { Component } from 'react';

const style = require('./index.css');

export interface ICloseBtnProps {
  onClick(): void;
  className?: string;
  isTransparent?: boolean;
}

export class CloseBtn extends Component<ICloseBtnProps> {
  public render() {
    return (
      <div
        {...mergeStyles(this.props.className, this.props.isTransparent ? style.button : style.buttonWhite)}
        onClick={this.props.onClick}
      >
        {/* eslint-disable-next-line react/forbid-elements */}
        <svg height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.193.78C2.113.7 1.99.696 1.91.776L.776 1.91c-.078.077-.07.21.002.283L5.585 7 .78 11.807c-.08.08-.083.204-.003.284l1.132 1.134c.076.078.21.07.282-.002L7 8.415l4.807 4.807c.08.08.204.083.284.003l1.134-1.132c.078-.077.07-.21-.002-.283L8.415 7l4.807-4.807c.08-.08.083-.204.003-.284L12.09.775c-.077-.078-.21-.07-.283.002L7 5.585 2.193.78z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
      </div>
    );
  }
}
