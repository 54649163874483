import { EBuildingHeatingType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBuildingHeatingTypesTagType = 'advanced-buildingHeating';

export interface IBuildingHeatingTypesTag {
  type: 'advanced-buildingHeating';
  value: EBuildingHeatingType;
}

const isBuildingHeatingTypesTag = (tag: TTag): tag is IBuildingHeatingTypesTag =>
  tag.type === IBuildingHeatingTypesTagType;

export const buildingHeatingTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.building_heating_type && jsonQuery.building_heating_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IBuildingHeatingTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EBuildingHeatingType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IBuildingHeatingTypesTag => isBuildingHeatingTypesTag(tag) && tag.value === value,
            () => ({ type: IBuildingHeatingTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
