import { IHttpApi } from '@cian/http-api/shared';

import { createHideOfferModel } from './helpers';
import { IHideOfferRequestScheme, THideOfferResponseScheme } from './types';

export function postHideOffer(
  httpApi: IHttpApi,
  parameters?: IHideOfferRequestScheme,
): Promise<THideOfferResponseScheme> {
  return httpApi.fetch(createHideOfferModel({ parameters }), undefined);
}
