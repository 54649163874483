import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export const INewbuildingHouseChangedActionType = 'filters/newbuildig_houses/SET_NEWBUILDING_HOUSE_IDS';

export interface INewbuildingHouseChangedAction {
  type: 'filters/newbuildig_houses/SET_NEWBUILDING_HOUSE_IDS';
  houseIds: number[];
  newbuildingId: number;
}

export function newbuildingHouseReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/newbuildig_houses/SET_NEWBUILDING_HOUSE_IDS':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setNewbuildingHouses(action.newbuildingId, action.houseIds),
        },
      };

    default:
      return state;
  }
}
