export const COMMERCIAL_SAVE_SEARCH_MODAL_OPENED = 'modal/commercial_save_search/MODAL_OPENED';
export const COMMERCIAL_SAVE_SEARCH_MODAL_CLOSED = 'modal/commercial_save_search/MODAL_CLOSED';

export type TCommercialSaveSearchModalOpened = {
  type: typeof COMMERCIAL_SAVE_SEARCH_MODAL_OPENED;
};

export const setCommercialSaveSearchModalOpened = (): TCommercialSaveSearchModalOpened => ({
  type: COMMERCIAL_SAVE_SEARCH_MODAL_OPENED,
});

export type TCommercialSaveSearchModalClosed = {
  type: typeof COMMERCIAL_SAVE_SEARCH_MODAL_CLOSED;
};

export const setCommercialSaveSearchModalClosed = (): TCommercialSaveSearchModalClosed => ({
  type: COMMERCIAL_SAVE_SEARCH_MODAL_CLOSED,
});
