import { IHttpApi } from '@cian/http-api/shared/http';
import { ILogger } from '@cian/logger/shared';

import { Requireable, Validator, any, array, bool, func, number, object, shape, string } from 'prop-types';

import { CommercialCardContext__DEPRICATED } from '../../CommercialCard';
import { CommercialOwner } from '../../utils/CommercialOwner';

export const contextTypes: Record<keyof CommercialCardContext__DEPRICATED, Requireable<unknown>> = {
  abUseExperiments: array,
  api: object,
  auctionMaxBet: number,
  auctionMinBet: number,
  baseUrl: string,
  captchaSpamSiteKey: string,
  commercialOwner: CommercialOwner.contextTypes,
  currentSubdomain: string,
  httpApi: shape<Record<keyof IHttpApi, Validator<NonNullable<unknown>>>>({
    fetch: func.isRequired,
    httpRequest: func.isRequired,
  }),
  isMlSearchForAll: bool,
  isSimilar: bool,
  jsonQuery: object,
  logger: shape<Record<keyof ILogger, Validator<NonNullable<unknown>>>>({
    debug: func.isRequired,
    error: func.isRequired,
    info: func.isRequired,
    warning: func.isRequired,
  }),
  maxAuctionBet: number,
  maxAuctionService: any,
  mlSearchRegions: array,
  offer: object,
  offerHelper: object,
  user: object,
  validateStep: bool,
};
