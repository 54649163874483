import { IAppState, TActions } from '../../../common/state';

const IRoomsInDealChangedActionType = 'filters/rooms_in_deal/ROOMS_IN_DEAL_CHANGED';

export interface IRoomsInDealChangedAction {
  type: 'filters/rooms_in_deal/ROOMS_IN_DEAL_CHANGED';
  roomsInDeal: number;
}

export function changeRoomsInDeal(roomsInDeal: number): IRoomsInDealChangedAction {
  return {
    type: IRoomsInDealChangedActionType,
    roomsInDeal,
  };
}

export function roomsInDealReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IRoomsInDealChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            rooms_for_sale:
              action.roomsInDeal !== 0
                ? {
                    type: 'range',
                    value: { gte: action.roomsInDeal },
                  }
                : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-roomsInDeal') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              rooms_for_sale: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            rooms_for_sale: undefined,
          },
        },
      };

    default:
      return state;
  }
}
