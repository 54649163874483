import * as PropTypes from 'prop-types';

import { EAccountType } from '../types/identicalOffers';
import { IOffer } from '../types/offer';

type TagType = EAccountType.ManagementCompany | EAccountType.RentDepartment | 'yeoman' | null;

export interface ICommercialOwner {
  readonly showOwner: boolean;
  readonly showRosreestrVerified: boolean;
  readonly tagType: TagType;
}

export class CommercialOwner implements ICommercialOwner {
  public static readonly contextTypes = PropTypes.exact({
    showOwner: PropTypes.bool.isRequired,
    showRosreestrVerified: PropTypes.bool.isRequired,
    tagType: PropTypes.oneOf([EAccountType.RentDepartment, EAccountType.ManagementCompany, 'yeoman', null]),
  });

  public static readonly noneInstance = new CommercialOwner(false, false, null);

  public static getInstance(offer: IOffer): CommercialOwner {
    const { user, commercialOwnership, rosreestrCheck } = offer;

    if (!commercialOwnership?.isFromOwner) {
      return CommercialOwner.noneInstance;
    }

    const isRosreestCheckSuccess = rosreestrCheck?.status === 'success';

    switch (user?.accountType) {
      case EAccountType.ManagementCompany:
      case EAccountType.RentDepartment: {
        return new CommercialOwner(true, isRosreestCheckSuccess, user.accountType);
      }

      default: {
        return new CommercialOwner(true, isRosreestCheckSuccess, 'yeoman');
      }
    }
  }

  public readonly showOwner: boolean;
  public readonly showRosreestrVerified: boolean;
  public readonly tagType: TagType;

  private constructor(showOwner: boolean, showRosreestrVerified: boolean, tagType: TagType) {
    this.showOwner = showOwner;
    this.showRosreestrVerified = showRosreestrVerified;
    this.tagType = tagType;
  }
}
