/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TWebCreateSavedSearchModel,
  IWebCreateSavedSearchRequest,
  IMappers,
  IWebCreateSavedSearchResponse,
  IWebCreateSavedSearchResponseError,
  TWebCreateSavedSearchResponse,
} from './types';

const defaultConfig: TWebCreateSavedSearchModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'saved-searches',
  pathApi: '/v1/web/create-saved-search/',
  hostType: 'ud-api',
} as TWebCreateSavedSearchModel;

function createWebCreateSavedSearchModel(parameters: IWebCreateSavedSearchRequest): TWebCreateSavedSearchModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IWebCreateSavedSearchOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IWebCreateSavedSearchRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchWebCreateSavedSearch<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IWebCreateSavedSearchOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TWebCreateSavedSearchResponse
> {
  const { response, statusCode } = await httpApi.fetch(createWebCreateSavedSearchModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IWebCreateSavedSearchResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IWebCreateSavedSearchResponseError);
    }
  }

  return { response, statusCode } as TWebCreateSavedSearchResponse;
}

export { defaultConfig, createWebCreateSavedSearchModel, fetchWebCreateSavedSearch };
