import { Outside } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import { IOffer } from 'shared/repositories/search-offers.legacy/v2/types';

import { Button } from '../button';

import { Comment } from './comment';

const style = require('./index.css');

export interface ICommentsAPI {
  onOfferCommentsChanged(offer: IOffer, commentOffer: string | undefined, commentAgent: string | undefined): void;

  isAuthenticated: boolean;
  commentingBlockedOffers: number[];
  commentingBlockedAgents: number[];
  commentingErroneousOffers: number[];
  commentingErroneousAgents: number[];
}

export interface ICommentsProps extends ICommentsAPI {
  isSimplified?: boolean;
  offer: IOffer;
  onCompletion(): void;
}

interface ICommentsState {
  isEditing: boolean;
  commentOffer?: string;
  commentAgent?: string;
}

export class Comments extends React.Component<ICommentsProps, ICommentsState> {
  private commentOffer: Comment | null = null;

  public constructor(props: ICommentsProps) {
    super(props);

    this.state = {
      commentAgent: (props.offer.notes.realtor && props.offer.notes.realtor.text) || undefined,
      commentOffer: (props.offer.notes.offer && props.offer.notes.offer.text) || undefined,
      isEditing: false,
    };
  }

  public componentDidMount() {
    if (!this.state.commentOffer && !this.state.commentAgent && this.commentOffer) {
      const textarea = this.commentOffer.getTextfield();
      if (textarea) {
        textarea.focus();
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ICommentsProps) {
    const { offer } = this.props;

    let nextState = {} as ICommentsState;

    if (this.props.offer !== nextProps.offer) {
      nextState = Object.assign(nextState, {
        commentAgent: (nextProps.offer.notes.realtor && nextProps.offer.notes.realtor.text) || undefined,
        commentOffer: (nextProps.offer.notes.offer && nextProps.offer.notes.offer.text) || undefined,
      });
    }

    if (this.state.isEditing) {
      if (
        (this.props.commentingBlockedOffers.indexOf(offer.cianId || offer.id) > -1 &&
          nextProps.commentingBlockedOffers.indexOf(offer.cianId || offer.id) === -1) ||
        (offer.user &&
          this.props.commentingBlockedAgents.indexOf(offer.user.cianUserId as number) > -1 &&
          nextProps.commentingBlockedAgents.indexOf(offer.user.cianUserId as number) === -1)
      ) {
        nextState = Object.assign(nextState, {
          isEditing: false,
        });
      }
    }

    if (Object.keys(nextState).length) {
      this.setState(nextState, () => {
        if (typeof nextState.isEditing !== 'undefined') {
          this.props.onCompletion();
        }
      });
    }
  }

  public render() {
    const { offer, isAuthenticated, commentingBlockedOffers, commentingBlockedAgents, isSimplified } = this.props;
    const { isEditing, commentOffer, commentAgent } = this.state;

    const isLoading = this.isLoading();
    const isForceShown = isEditing || (!commentOffer && !commentAgent);

    /**
     * Если нет комментариев от пользователя,
     * или есть комментарий от агента, но пользователь не авторизован -
     * не выводим блок
     */
    if (!commentOffer && !commentAgent && !isForceShown) {
      return null;
    }

    return (
      <Outside onOutside={this.cancel}>
        <div
          {...mergeStyles(
            style['container'],
            offer.isTop3 && style['container--top3'],
            offer.isColorized && style['container--colorized'],
            isSimplified && style['simplified'],
            isEditing && style['simplified-editing'],
          )}
        >
          <div className={style['title']}>Комментарий</div>
          <div {...mergeStyles(style['content'], isEditing && style['content--editing'])}>
            {(commentOffer || isForceShown) && (
              <Comment
                isSimplified
                disabled={commentingBlockedOffers.indexOf(offer.cianId || offer.id) > -1}
                editing={isEditing}
                label="Про объявление"
                ref={commentOfferRef => (this.commentOffer = commentOfferRef)}
                value={commentOffer}
                onChange={value =>
                  this.setState({
                    commentOffer: value,
                  })
                }
                onDelete={() => {
                  this.setState(
                    {
                      commentOffer: undefined,
                    },
                    this.handleSave,
                  );
                }}
                onFocus={this.handleFocus}
                onKeyDown={this.handleKeyDown}
              />
            )}
            {isAuthenticated && (commentAgent || isForceShown) && (
              <Comment
                isSimplified
                disabled={
                  (offer.user && commentingBlockedAgents.indexOf(offer.user.cianUserId as number) > -1) || false
                }
                editing={isEditing}
                label="Про агента"
                value={commentAgent}
                onChange={value =>
                  this.setState({
                    commentAgent: value,
                  })
                }
                onDelete={() => {
                  this.setState(
                    {
                      commentAgent: undefined,
                    },
                    this.handleSave,
                  );
                }}
                onFocus={this.handleFocus}
                onKeyDown={this.handleKeyDown}
              />
            )}
          </div>
          {isEditing && (
            <Button
              className={style['save']}
              disabled={isLoading}
              mode="light"
              theme="primary"
              onClick={this.handleSave}
            >
              Сохранить
            </Button>
          )}
        </div>
      </Outside>
    );
  }

  private isLoading(): boolean {
    const { offer, commentingBlockedOffers, commentingBlockedAgents } = this.props;

    return (
      commentingBlockedOffers.indexOf(offer.cianId || offer.id) > -1 ||
      (!!offer.user && commentingBlockedAgents.indexOf(offer.user.cianUserId as number) > -1)
    );
  }

  private handleFocus = () => {
    this.setState({
      isEditing: true,
    });
  };

  private handleKeyDown = (e: React.KeyboardEvent<EventTarget>) => {
    // Копипаста из @cian/components
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const code = e.nativeEvent.code || e.nativeEvent.key || (e.nativeEvent as any).keyIdentifier;

    if (code === 'Enter') {
      this.handleSave();
    }
  };

  private cancel = () => {
    if (this.isLoading()) {
      return;
    }

    const { notes } = this.props.offer;

    this.setState({
      commentAgent: (notes.realtor && notes.realtor.text) || undefined,
      commentOffer: (notes.offer && notes.offer.text) || undefined,
      isEditing: false,
    });

    this.props.onCompletion();
  };

  private handleSave = () => {
    if (this.isLoading()) {
      return;
    }

    this.props.onOfferCommentsChanged(this.props.offer, this.state.commentOffer, this.state.commentAgent);
  };
}
