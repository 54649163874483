import { Component } from 'react';

import { loadYmapsApi } from 'shared/utils/ymaps';

import { ICoordinates } from '../../types/offer';

import { PIN_PROPS } from './settings';

const style = require('./index.css');

interface IMapProps {
  coordinates: ICoordinates;
}

export class Map extends Component<IMapProps> {
  private container: Element | null = null;

  public componentDidMount() {
    loadYmapsApi({
      require: ['Map', 'Placemark', 'control.ZoomControl'],
    }).then(ymaps => {
      if (this.container) {
        const { lat, lng } = this.props.coordinates;

        // Смещение центра карты для того, чтобы иконка оказалась по центру
        const OFFSET_BY_ICON_SIZE = 0.001;

        const map = new ymaps.Map(this.container, {
          behaviors: ['drag', 'dblClickZoom', 'rightMouseButtonMagnifier'],
          center: [lng, lat + OFFSET_BY_ICON_SIZE] || [0, 0],
          zoom: 14,
        });

        map.controls.add('zoomControl', {
          position: {
            left: 10,
            top: 92,
          },
        });

        const myPlacemark = new ymaps.Placemark([lng, lat], {}, PIN_PROPS);

        map.geoObjects.add(myPlacemark);
      }
    });
  }

  public render() {
    return <div className={style.mapContainer} ref={container => (this.container = container)} />;
  }
}
