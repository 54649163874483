/**
 * @todo Удалить файл с экспериментом
 * @description Данный функционал появился в задаче CD-128336, будет удалён в задаче CD-128344
 * Если эксперимент будет удачным, то оставить дирексторию, но удалить комментарий,
 * если эксперимент будет не удачным, то удалить полностью директорию с компонентом
 */

import { Label, Tooltip, UIHeading4, UIText2 } from '@cian/ui-kit';

import { FC } from 'react';

import styles from './GoodPriceBadge.css';

export const GoodPriceBadge: FC = () => {
  return (
    <Tooltip
      arrow={false}
      placement="bottom-start"
      theme="white"
      title={
        <div className={styles['content']}>
          <UIHeading4>Почему цена хорошая</UIHeading4>
          <UIText2>
            Цена соответствует рыночной: при расчёте мы учитываем стоимость похожих объектов в&nbsp;этом же регионе
          </UIText2>
        </div>
      }
    >
      <Label background="success_10" color="success_100">
        Хорошая цена
      </Label>
    </Tooltip>
  );
};

GoodPriceBadge.displayName = 'GoodPriceBadge';
