import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import useResizeObserver from 'use-resize-observer';

import { IAppState } from '../../common/state';
import { FiltersSection } from '../../components/FiltersSection';
import { EPosition } from '../../enums/filters/EPosition';
import { TTag } from '../../filters/state/tags';
import { CommercialFiltersContainer } from '../CommercialFiltersContainer';

export const FiltersContainer: FC = () => {
  const tags = useSelector<IAppState, TTag[]>(state => state.filters.tags);

  const [filtersSectionPosition, setFiltersSectionPosition] = useState<EPosition>(EPosition.default);
  const updateFiltersHeightCb = useRef<() => void | undefined>();
  const { ref: commercialFiltersRef, height: commercialFiltersHeight } = useResizeObserver<HTMLDivElement>();

  const setUpdateHeightCb = useCallback((cb: () => void) => {
    updateFiltersHeightCb.current = cb;
  }, []);

  useEffect(() => {
    // при изменении кол-ва тегов, запускаем пересчет мин-высоты плавающих фильтров
    if (updateFiltersHeightCb.current) {
      updateFiltersHeightCb.current();
    }
  }, [tags]);

  useEffect(() => {
    if (updateFiltersHeightCb.current) {
      updateFiltersHeightCb.current();
    }
  }, [commercialFiltersHeight]);

  return (
    <FiltersSection
      position={filtersSectionPosition}
      setUpdateHeightCb={setUpdateHeightCb}
      onChangePosition={setFiltersSectionPosition}
    >
      <CommercialFiltersContainer ref={commercialFiltersRef} />
    </FiltersSection>
  );
};
