/* eslint-disable max-lines */
import * as R from 'ramda';

import {
  ECategoryType,
  ECoworkingOfferType,
  EOfficeType,
  ERentTime,
  IJsonQuery,
  IJsonQueryType,
} from 'shared/repositories/common/json_query';

import { setJsonQueryPriceType } from '../../packages/JsonQuery/setJsonQueryPriceType';
import { FDealType, FOfferType, dealTypeFromJsonQuery } from '../../utils/category';

import { removeNewbuildingHouses, setRootType, setTerm, setTerms } from './helpers';

export function selectOfferType(jsonQuery: IJsonQuery): (nextOfferType: FOfferType) => IJsonQuery {
  let nextJsonQuery = R.clone(jsonQuery);

  return nextOfferType => {
    const dealType = dealTypeFromJsonQuery(jsonQuery);

    /**
     * @description Удаляет тип прайса если выбран тип здания не коворкинг и есть тип прайса
     */
    if ((nextOfferType & FOfferType.Coworking) !== 0 && nextJsonQuery.price_type?.value) {
      nextJsonQuery = setJsonQueryPriceType(nextJsonQuery)(null);
    }

    /**
     * @description Удаляет корпус ЖК если выбран тип здания не новстройка
     */
    if ((nextOfferType & FOfferType.FlatNew) !== 0) {
      nextJsonQuery = removeNewbuildingHouses()(nextJsonQuery);
    }

    if (dealType & FDealType.Rent) {
      nextJsonQuery = setTerm('for_day')(nextJsonQuery)(ERentTime.NotDaily);
    }
    nextJsonQuery = setTerm('category')(nextJsonQuery)(undefined);
    nextJsonQuery = setTerm('object_type')(nextJsonQuery)(undefined);
    nextJsonQuery = setTerm('room')(nextJsonQuery)(undefined);
    nextJsonQuery = setTerm('building_status')(nextJsonQuery)(undefined);

    if (
      !(
        nextOfferType &
        (FOfferType.Office | FOfferType.Warehouse | FOfferType.TradeArea | FOfferType.FreeAppointmentObject)
      )
    ) {
      nextJsonQuery = setTerm('from_offrep')(nextJsonQuery)(undefined);
    }
    if (nextJsonQuery.coworking_offer_type && nextOfferType !== FOfferType.Coworking) {
      nextJsonQuery = setTerm('coworking_offer_type')(nextJsonQuery)(undefined);
      nextJsonQuery = setTerm('office_type')(nextJsonQuery)(undefined);
    }
    if (
      nextJsonQuery.office_type &&
      nextJsonQuery.office_type.value.includes(EOfficeType.Garage) &&
      nextOfferType !== FOfferType.Garage
    ) {
      nextJsonQuery = setTerm('office_type')(nextJsonQuery)(undefined);
    }

    switch (nextOfferType) {
      case FOfferType.Office:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Office);
        break;
      case FOfferType.TradeArea:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.TradeArea);
        break;
      case FOfferType.Warehouse:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Warehouse);
        break;
      case FOfferType.FreeAppointmentObject:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.FreeAppointmentObject);
        break;
      case FOfferType.PublicCatering:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.PublicCatering);
        break;
      case FOfferType.Manufacture:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Manufacture);
        break;
      case FOfferType.AutoService:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.AutoService);
        break;
      case FOfferType.Business:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Business);
        break;
      case FOfferType.Building:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.Building);
        break;
      case FOfferType.DomesticServices:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = toggleOfficeType(nextJsonQuery)(EOfficeType.DomesticServices);
        break;
      case FOfferType.CommercialLand:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = setTerm('office_type')(nextJsonQuery)(undefined);
        if (dealType & FDealType.Rent) {
          nextJsonQuery = setTerms('category')(nextJsonQuery)([ECategoryType.CommercialLandRent]);
        } else if (dealType & FDealType.Sale) {
          nextJsonQuery = setTerms('category')(nextJsonQuery)([ECategoryType.CommercialLandSale]);
        }
        break;
      case FOfferType.Coworking:
        nextJsonQuery = setOfferType(nextJsonQuery)('commercial');
        nextJsonQuery = setTerms('coworking_offer_type')(nextJsonQuery)([ECoworkingOfferType.Office]);
        nextJsonQuery = setTerms('office_type')(nextJsonQuery)([EOfficeType.Coworking]);
        break;
      default:
    }

    return nextJsonQuery;
  };
}

export function toggleOfficeType(jsonQuery: IJsonQuery) {
  return (officeType: EOfficeType): IJsonQuery => {
    if (jsonQuery.office_type) {
      if (!jsonQuery.office_type.value.includes(officeType)) {
        if (jsonQuery.office_type.value.includes(EOfficeType.Coworking)) {
          const nextOfficeType = jsonQuery.office_type.value.filter(type => !(type & EOfficeType.Coworking));

          return setTerms('office_type')(jsonQuery)(nextOfficeType.concat(officeType));
        } else if (officeType === EOfficeType.Coworking) {
          return setTerms('office_type')(jsonQuery)([officeType]);
        }

        return setTerms('office_type')(jsonQuery)(jsonQuery.office_type.value.concat(officeType));
      } else {
        const nextOfficeType = jsonQuery.office_type.value.filter(type => type !== officeType);

        if (!nextOfficeType.length && officeType === EOfficeType.Business) {
          return setTerms('office_type')(jsonQuery)([EOfficeType.Office]);
        }

        if (
          officeType === EOfficeType.Office &&
          jsonQuery.coworking_offer_type &&
          jsonQuery.coworking_offer_type.value.length > 0
        ) {
          return setTerms('office_type')(jsonQuery)(nextOfficeType.concat([officeType]));
        }

        return setTerms('office_type')(jsonQuery)(nextOfficeType);
      }
    }

    return setTerms('office_type')(jsonQuery)([officeType]);
  };
}

function setOfferType(jsonQuery: IJsonQuery) {
  return (offerType: 'flat' | 'commercial' | 'suburban' | 'newobject'): IJsonQuery => {
    const currentType = jsonQuery._type;
    const nextType: IJsonQueryType = currentType.replace(
      /flat|commercial|suburban|newobject/,
      offerType,
    ) as IJsonQueryType;

    return setRootType(nextType)(jsonQuery);
  };
}

export function selectCoworkingOfferType(jsonQuery: IJsonQuery) {
  return (coworkingOfferType: ECoworkingOfferType): IJsonQuery => {
    const coworkingOfferTypes = jsonQuery.coworking_offer_type && jsonQuery.coworking_offer_type.value;
    if (coworkingOfferTypes && coworkingOfferTypes.length >= 0) {
      let nextValue: ECoworkingOfferType[] = coworkingOfferTypes;

      if (coworkingOfferTypes.includes(coworkingOfferType) && coworkingOfferTypes.length > 1) {
        nextValue = coworkingOfferTypes.filter(f => f !== coworkingOfferType);
      }

      if (!coworkingOfferTypes.includes(coworkingOfferType)) {
        nextValue = coworkingOfferTypes.concat([coworkingOfferType]);
      }

      return setTerms('coworking_offer_type')(jsonQuery)(nextValue);
    }

    return jsonQuery;
  };
}
