import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import { ArrowIcon } from './ArrowIcon';
import { computeNextLeftSlidePosition } from './utils/computeNextLeftSlidePosition';
import { computeNextRightSlidePosition } from './utils/computeNextRightSlidePosition';
import { groupElements } from './utils/groupElements';
import { preventDoubleClick } from './utils/preventDoubleClick';

import * as styles from './Slider.css';

interface IClasses {
  groupsContainer?: string;
  group?: string;
}

interface IProps {
  children: React.ReactNodeArray;
  visibleSlidesCount: number;
  step: number;
  className?: string;
  classes?: IClasses;
  onNextButtonClick(): void;
  onPrevButtonClick(): void;
}

interface IState {
  offset: number;
  isMinPositionReached: boolean;
  isMaxPositionReached: boolean;
}

interface ISlidePosition {
  groupPosition: number;
  slidePositionInsideGroup: number;
}

/**
 * @description Принимает на вход массив реакт-элементов, которые рендерит в контейнере с overflow: hidden.
 * При клике по prev/next кнопкам высчитывает расстояние, на которое требуется сдвинуть элементы и
 * применяет сдвиг с помощью transform: translate.
 * Позволяет использовать элементы с разной шириной.
 * Чувствителен к ресайзу страницы.
 * Элементы распределяются по контейнерам. Каждый контейнер содержит visibleSlidesCount число элементов.
 * Контейнеры имеют 100% ширину от родителя.
 * В случае, если в последнем контейнере остается менее чем visibleSlidesCount элементов - последний контейнер
 * заполняется элементами-заглушками, которые никогда не попадают во viewport зону (см. функцию groupElements).
 * Такой подход гарантирует, что ширина элементов в последнем контейнере будет эквивалентна ширине соответствующих
 * элементов в предыдущих контейнерах, если элементы являются адаптивными.
 *
 * @property visibleSlidesCount - количество видимых элементов в слайдере
 * @property step - количество элементов, на которое требуется сдвинуть список при нажатии prev/next кнопок
 *
 * Алгоритм сдвига:
 *   а) при клике по next кнопке сдвигает элементы на заданный шаг влево таким образом,
 *      чтобы правая граница последнего видимого элемента совпадала с правой границей внешнего контейнера
 *   б) при клике по prev кнопке сдвигает элементы на заданный шаг вправо таким образом,
 *      чтобы левая граница первого видимого элемента совпадала с левой границей внешнего контейнера
 *
 * Основные понятия:
 *   leftSlide - первый видимый слайд
 *   rightSlide - последний видимый слайд
 *   leftSlidePosition - номер первого видимого слайда относительно всего списка элементов
 *   rifhtSlidePosition - номер последнего видимого слайда относительно всего списка элементов
 *   groupPosition - номер группы относительно всего списка групп
 *   slidePositionInsideGroup - номер слайда относительно своей группы
 *   leftSlidePositionInsideGroup - номер первого видимого слайда относительно своей группы
 *   rightSlidePositionInsideGroup - номер последнего видимого слайда относительно своей группы
 *
 * Схема передвижения элементов:
 *    visibleSlidesCount == 3;
 *    step == 2;
 *    |L| - левая граница видимой части
 *    |R| - правая граница видимой части
 *
 *   1) [|L| элемент 0, элемент 1, элемент 2 |R|, элемент 3, элемент 4, элемент 5]
 *      кликаем next кнопку =>
 *
 *   2) [элемент 0, элемент 1, |L| элемент 2, элемент 3, элемент 4 |R|, элемент 5]
 *      кликаем next кнопку =>
 *
 *   3) [элемент 0, элемент 1, элемент 2, |L| элемент 3, элемент 4, элемент 5 |R|]
 *      кнопка next не доступна, кликаем prev кнопку =>
 *
 *   4) [элемент 0, |L| элемент 1, элемент 2 , элемент 3 |R|, элемент 4, элемент 5]
 *      кликаем prev кнопку =>
 *
 *   5) [|L| элемент 0, элемент 1, элемент 2 |R|, элемент 3, элемент 4, элемент 5]
 *      кнопка prev не доступна
 */
export class Slider extends React.Component<IProps, IState> {
  private leftSlidePosition = 0;
  private rightSlidePosition = this.leftSlidePosition + this.props.visibleSlidesCount - 1;

  public static defaultProps: Partial<IProps> = {
    visibleSlidesCount: 1,
    step: 1,
    onNextButtonClick: () => {},
    onPrevButtonClick: () => {},
  };

  public state: IState = {
    offset: 0,
    isMinPositionReached: true,
    isMaxPositionReached: false,
  };

  private overflowContainerRef = React.createRef<HTMLDivElement>();
  private groupsContainerRef = React.createRef<HTMLDivElement>();

  private getSlideNodeByPosition = ({
    groupPosition,
    slidePositionInsideGroup,
  }: ISlidePosition): null | HTMLElement => {
    const groupsContainerNode = this.groupsContainerRef.current;

    if (!groupsContainerNode) {
      return null;
    }

    const groupNode = groupsContainerNode.children[groupPosition] as HTMLElement | void;

    if (!groupNode) {
      return null;
    }

    const slideNode = groupNode.children[slidePositionInsideGroup] as HTMLElement | void;

    return slideNode || null;
  };

  private goToPrevSlide = preventDoubleClick(() => {
    const { children, step, visibleSlidesCount, onPrevButtonClick } = this.props;
    const { leftSlidePosition } = this;

    const next = computeNextLeftSlidePosition({ leftSlidePosition, visibleSlidesCount, step });

    this.leftSlidePosition = next.leftSlidePosition;
    this.rightSlidePosition = next.rightSlidePosition;

    const overflowContainerNode = this.overflowContainerRef.current;

    const nextLeftSlideNode = this.getSlideNodeByPosition({
      groupPosition: next.groupPosition,
      slidePositionInsideGroup: next.leftSlidePositionInsideGroup,
    });

    if (!nextLeftSlideNode || !overflowContainerNode) {
      return;
    }

    /**
     * Вычисление getBoundingClientRect для overflowContainer внутри goToPrevSlide
     * дает возможность ресайзить страницу без потери точности расчетов слайдера.
     * При возникновении проблемы перформанса - вынести в componentDidMount и добавить слушателя на ресайз.
     */
    const overflowContainerNodeRect = overflowContainerNode.getBoundingClientRect();
    const nextLeftSlideNodeRect = nextLeftSlideNode.getBoundingClientRect();
    const newOffset = overflowContainerNodeRect.left - nextLeftSlideNodeRect.left;

    this.setState(
      ({ offset }) => ({
        offset: offset - newOffset,
        isMinPositionReached: next.leftSlidePosition === 0,
        isMaxPositionReached: next.rightSlidePosition === children.length - 1,
      }),
      onPrevButtonClick,
    );
  });

  private goToNextSlide = preventDoubleClick(() => {
    const { children, step, visibleSlidesCount, onNextButtonClick } = this.props;
    const { rightSlidePosition } = this;

    const next = computeNextRightSlidePosition({
      rightSlidePosition,
      visibleSlidesCount,
      step,
      slidesCount: children.length,
    });

    this.leftSlidePosition = next.leftSlidePosition;
    this.rightSlidePosition = next.rightSlidePosition;

    const overflowContainerNode = this.overflowContainerRef.current;

    const nextRightSlideNode = this.getSlideNodeByPosition({
      groupPosition: next.groupPosition,
      slidePositionInsideGroup: next.rightSlidePositionInsideGroup,
    });

    if (!nextRightSlideNode || !overflowContainerNode) {
      return;
    }

    /**
     * Вычисление getBoundingClientRect для overflowContainer внутри goToNextSlide
     * дает возможность ресайзить страницу без потери точности расчетов слайдера.
     * При возникновении проблемы перформанса - вынести в componentDidMount и добавить слушателя на ресайз.
     */
    const overflowContainerNodeRect = overflowContainerNode.getBoundingClientRect();
    const nextRightSlideNodeRect = nextRightSlideNode.getBoundingClientRect();
    const newOffset = nextRightSlideNodeRect.right - overflowContainerNodeRect.right;

    this.setState(
      ({ offset }) => ({
        offset: offset + newOffset,
        isMinPositionReached: next.leftSlidePosition === 0,
        isMaxPositionReached: next.rightSlidePosition === children.length - 1,
      }),
      onNextButtonClick,
    );
  });

  public render() {
    const { offset, isMaxPositionReached, isMinPositionReached } = this.state;
    const { classes = {}, children, visibleSlidesCount, step } = this.props;
    const groupsContainerStyle = { transform: `translate3d(${-offset}px, 0, 0)` };
    const { className: groupsContainerClassName } = mergeStyles(styles['groups-container'], classes.groupsContainer);
    const { className: groupClassName } = mergeStyles(styles['group'], classes.group);
    const { className: prevButtonClassName } = mergeStyles(styles['button'], styles['prev']);
    const { className: nextButtonClassName } = mergeStyles(styles['button'], styles['next']);
    const groupedElements: React.ReactNode[][] = groupElements({
      elements: children,
      countInGroup: visibleSlidesCount,
    });

    return (
      <div className={styles['relative-container']}>
        <div className={styles['overflow-container']} ref={this.overflowContainerRef}>
          <div className={groupsContainerClassName} ref={this.groupsContainerRef} style={groupsContainerStyle}>
            {groupedElements.map((elements, index) => (
              <div className={groupClassName} key={index}>
                {elements}
              </div>
            ))}
          </div>
        </div>
        {!isMinPositionReached && (
          <button className={prevButtonClassName} type="button" onClick={this.goToPrevSlide}>
            <ArrowIcon direction="left" />
          </button>
        )}
        {!isMaxPositionReached && children.length > step && (
          <button className={nextButtonClassName} type="button" onClick={this.goToNextSlide}>
            <ArrowIcon direction="right" />
          </button>
        )}
      </div>
    );
  }
}
