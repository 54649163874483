/* eslint-disable max-lines */

import { numberToPrettyString, plural, shortenNumber } from '@cian/utils';

import * as React from 'react';

import { IOffer } from 'shared/offer';

import {
  BargainTermsSaleTypePresenter,
  BuildingMaterialTypePresenter,
  BuildingQuarter,
  IMasterAgent,
  IOfferAnalyticsInfo,
  IPhone,
  IUtilitiesTerms,
  LeaseTerm,
  PrimaryMetroRegions,
  UserTrustLevel,
} from '../types/offer';

import { gatherAnalyticsInfo } from './analytics_info';
import { ICommercialHelper, commercialHelper } from './commercial';
import { ISimplifiedCardHelper, simplifiedCardHelper } from './simplified_card';
import { ISuburbanHelper, suburbanHelper } from './suburban';

function isFlat(offer: IOffer): boolean {
  return offer.offerType === 'flat';
}

function isRent(offer: IOffer): boolean {
  return offer.dealType === 'rent';
}

function isDailyRent(offer: IOffer): boolean {
  return Boolean(offer.category && offer.category.indexOf('daily') !== -1);
}

function isSale(offer: IOffer): boolean {
  return offer.dealType === 'sale';
}

function isCommercial(offer: IOffer) {
  return offer.offerType === 'commercial';
}

function isGarage(offer: IOffer): boolean {
  return offer.category === 'garageSale' || offer.category === 'garageRent';
}

function isRosreestrVerified(offer: IOffer): boolean {
  return !!(offer.rosreestrCheck && offer.rosreestrCheck.status === 'success');
}

function isPassportVerified(offer: IOffer): boolean {
  return Boolean(offer.user && offer.user.isPassportVerified);
}

function isHomeownerVerified(offer: IOffer): boolean {
  return isRosreestrVerified(offer) || isPassportVerified(offer);
}

function isPrimaryMetroRegion(offer: IOffer): boolean {
  if (offer.geo && offer.geo.undergrounds && offer.geo.address) {
    return PrimaryMetroRegions.indexOf(offer.geo.address[0].id) >= 0;
  }

  return false;
}

/* Modificators */

const getWithSquareMeters = (data: string): React.ReactNode => {
  return (
    data && (
      <div>
        {data}
        {'м'}
        <sup>2</sup>
      </div>
    )
  );
};

/* Presenters */

function getTotalArea(offer: IOffer) {
  if (offer.totalArea) {
    return numberToPrettyString(Number(offer.totalArea));
  }

  return null;
}

function getMinArea(offer: IOffer): string | null {
  if (offer.minArea) {
    return numberToPrettyString(Number(offer.minArea));
  }

  return null;
}

function getRoomArea(offer: IOffer): string | null {
  if (offer.roomArea) {
    return numberToPrettyString(Number(offer.roomArea));
  }

  return null;
}

function getLivingArea(offer: IOffer): string | null {
  if (offer.livingArea) {
    return `жилая ${numberToPrettyString(Number(offer.livingArea))}`;
  }

  return null;
}

function getKitchenArea(offer: IOffer): string | null {
  if (offer.kitchenArea) {
    return `кухня ${numberToPrettyString(Number(offer.kitchenArea))}`;
  }

  return null;
}

function getSalePrice(offer: IOffer): string | null {
  let formattedPrice = shortenNumber(offer.bargainTerms.priceRur);
  formattedPrice = formattedPrice.replace(/\d+(.)\d+/, t => t.replace('.', ','));

  return `${formattedPrice} руб.`;
}

function getSalePriceWithTotalArea(offer: IOffer): string {
  const totalArea = parseFloat(offer.totalArea);
  const priceForArea = shortenNumber(offer.bargainTerms.priceRur * totalArea).replace(/\d+(.)\d+/, t =>
    t.replace('.', ','),
  );

  return `${priceForArea} руб.`;
}

function getMinSalePrice(offer: IOffer): string | null {
  const { minPriceTotalRur } = offer;
  const price = minPriceTotalRur && shortenNumber(minPriceTotalRur);
  const formatted = price && price.replace(/\d+(.)\d+/, t => t.replace('.', ','));

  return formatted ? `${formatted} руб.` : null;
}

function getSalePricePerMeter(offer: IOffer): React.ReactNode | null {
  if (!offer.bargainTerms) {
    return null;
  }

  if (offer.bargainTerms.priceType !== 'all') {
    const totalArea = parseFloat(offer.totalArea);

    if (offer.bargainTerms.priceType === 'squareMeter' && totalArea > 0) {
      return getWithSquareMeters(`${numberToPrettyString(offer.bargainTerms.priceRur)} руб. за `);
    }

    return `${numberToPrettyString(offer.bargainTerms.priceRur)} руб.`;
  } else if (offer.totalArea) {
    const totalArea = parseFloat(offer.totalArea);

    if (totalArea > 0) {
      const pricePerMeter = Math.round(offer.bargainTerms.priceRur / totalArea);
      const pricePerMeterFormatted = numberToPrettyString(pricePerMeter);

      return getWithSquareMeters(`${pricePerMeterFormatted} руб. за `);
    }
  }

  return null;
}

function getSaleType(offer: IOffer): string | null {
  if (offer.bargainTerms && offer.bargainTerms.saleType) {
    return BargainTermsSaleTypePresenter[offer.bargainTerms.saleType] || null;
  }

  return null;
}

function getLeaseType(offer: IOffer) {
  switch (offer.bargainTerms.leaseType) {
    case 'direct':
      return 'прямая аренда';
    case 'sublease':
      return 'субаренда';
    case 'jointVenture':
      return 'совместная аренда';
    default:
      return undefined;
  }
}

function getOfferLayout(offer: IOffer) {
  switch (offer.layout) {
    case 'mixed':
      return 'смешанная';
    case 'cabinet':
      return 'кабинетная';
    case 'openSpace':
      return 'открытая';
    default:
      return undefined;
  }
}

function getAccessType(offer: IOffer) {
  const type = offer.accessType || (offer.building && offer.building.accessType);

  if (!offer.category || !offer.category.startsWith('office')) {
    return undefined;
  }

  switch (type) {
    case 'free':
      return 'свободный вход';
    case 'passSystem':
      return 'вход по пропускам';
    default:
      return undefined;
  }
}

function getRentPrice(offer: IOffer, excludedUtilitiesTermsRegions: number[] = []): string {
  let price = offer.bargainTerms.priceRur;
  const regionId = offer.geo && offer.geo.address && offer.geo.address[0] && offer.geo.address[0].id;

  if (
    offer.bargainTerms.utilitiesTerms &&
    !offer.bargainTerms.utilitiesTerms.includedInPrice &&
    (!regionId || !excludedUtilitiesTermsRegions.includes(regionId))
  ) {
    price += offer.bargainTerms.utilitiesTerms.price;
  }

  return `${numberToPrettyString(price)} руб./${getPaymentPeriod(offer)}`;
}

function getMoreRentPrice(offer: IOffer): string | null {
  const { utilitiesTerms } = offer.bargainTerms;

  if (isDailyRent(offer)) {
    return null;
  }

  /**
   * Если utilitiesTerms с бекенда приходит null, значит ком. платежи включены,
   * так исторически сложилось
   */
  if (utilitiesTerms === null) {
    return 'Комм. платежи включены (без счётчиков)';
  }

  if (utilitiesTerms) {
    if (utilitiesTerms.includedInPrice) {
      return `Комм. платежи включены ${getFlowMetersInfo(utilitiesTerms)}`;
    }

    if (!utilitiesTerms.includedInPrice && utilitiesTerms.price === 0) {
      return 'Комм. платежи не включены';
    }

    if (!utilitiesTerms.includedInPrice && utilitiesTerms.price > 0) {
      const priceRent = offer.bargainTerms.priceRur;
      const priceUtilities = utilitiesTerms.price;

      return `${numberToPrettyString(priceRent)} \u20bd + ${numberToPrettyString(
        priceUtilities,
      )} \u20bd комм. платежи ${getFlowMetersInfo(utilitiesTerms)}`;
    }
  }

  return '';
}

export function getFlowMetersInfo(terms: IUtilitiesTerms) {
  if (terms.flowMetersNotIncludedInPrice === false) {
    return '(счётчики включены)';
  }

  return '(без счётчиков)';
}

function getCommercialRentPrice(offer: IOffer) {
  const price = offer.priceTotalPerMonthRur;

  if (!price) {
    return null;
  }

  return `${numberToPrettyString(price)} руб./${getPaymentPeriod(offer)}`;
}

function getCommercialMinRentPrice(offer: IOffer): string | null {
  const { minPriceTotalPerMonthRur } = offer;

  return minPriceTotalPerMonthRur
    ? `${numberToPrettyString(minPriceTotalPerMonthRur)} руб./${getPaymentPeriod(offer)}`
    : null;
}

function getCommercialPerMeterByYear(offer: IOffer) {
  const { pricePerUnitAreaPerYearRur } = offer;

  if (!pricePerUnitAreaPerYearRur) {
    return null;
  }

  return `${numberToPrettyString(pricePerUnitAreaPerYearRur)} руб. за м\u00b2 в год`;
}

function getPaymentPeriod(offer: IOffer): string {
  return offer.category && offer.category.indexOf('daily') !== -1 ? 'сутки' : 'мес.';
}

function getClientFee(offer: IOffer): string {
  return offer.bargainTerms.clientFee ? `комиссия ${offer.bargainTerms.clientFee}%` : 'без комиссии';
}

function getFee(offer: IOffer): string {
  const { agentFee, clientFee } = offer.bargainTerms;
  const fee: string[] = [];

  if (agentFee != null) {
    fee.push(`аг.\u00A0${agentFee}%`);
  }

  if (clientFee != null) {
    fee.push(`кл.\u00A0${clientFee}%`);
  }

  return fee.join(', ');
}

function getDeposit(offer: IOffer): string {
  if (offer.bargainTerms.deposit) {
    switch (offer.bargainTerms.currency) {
      case 'eur':
        return `залог \u20AC${numberToPrettyString(offer.bargainTerms.deposit)}`;
      case 'rur':
        return `залог ${numberToPrettyString(offer.bargainTerms.deposit)} руб.`;
      case 'usd':
        return `залог $${numberToPrettyString(offer.bargainTerms.deposit)}`;
    }
  }

  return 'без залога';
}

function getLift(offer: IOffer): string {
  let text = 'нет лифта';

  if (offer.building && offer.building.cargoLiftsCount) {
    text = 'лифт грузовой';
  } else if (offer.building && offer.building.passengerLiftsCount) {
    text = 'лифт пассажирский';
  }

  return text;
}

function getType(offer: IOffer) {
  return findTypeById(
    [
      createSearchType('flat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? (offer.roomsCount >= 6 ? 'Многокомнатная' : `${offer.roomsCount}-комнатная`) : '';
      }),
      createSearchType('newBuildingFlat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? (offer.roomsCount >= 6 ? 'Многокомнатная' : `${offer.roomsCount}-комнатная`) : '';
      }),
      createSearchType('dailyFlat', () => {
        if (offer.flatType === 'studio') {
          return 'Студия';
        }

        if (offer.flatType === 'openPlan') {
          return 'Своб. планировка';
        }

        return offer.roomsCount ? (offer.roomsCount >= 6 ? 'Многокомнатная' : `${offer.roomsCount}-комнатная`) : '';
      }),
      createSearchType(
        'room',
        () =>
          (offer.roomsForSaleCount &&
            `${offer.roomsForSaleCount} ${plural(offer.roomsForSaleCount, ['комната', 'комнаты', 'комнат'])}`) ||
          '1 комната',
      ),
      createSearchType('dailyRoom', () => 'Комната'),
      createSearchType('dailyBed', () => 'Койко-место'),
      createSearchType('bed', () => 'Койко-место'),
      createSearchType('office', () => 'Офис'),
      createSearchType('shoppingArea', () => 'Торговая площадь'),
      createSearchType('warehouse', () => 'Склад'),
      createSearchType('freeAppointmentObject', () => 'Своб. назнач.'),
      createSearchType('industry', () => 'Помещение под производство'),
      createSearchType('house', () => 'Дом'),
      createSearchType('cottage', () => 'Коттедж'),
      createSearchType('dailyHouse', () => {
        return offer.isRentByParts && offer.rentByPartsDescription ? offer.rentByPartsDescription + ' дома' : 'Дом';
      }),
      createSearchType('townhouse', () => 'Таунхаус'),
      createSearchType('land', () => 'Участок'),
      createSearchType('commercialLand', () => 'Участок'),
      createSearchType('houseShare', () => {
        return offer.shareAmount ? offer.shareAmount + ' дома' : 'часть дома';
      }),
      createSearchType('flatShare', () => {
        return offer.shareAmount ? offer.shareAmount + ' квартиры' : 'доля';
      }),
      createSearchType('garage', () => {
        if (!offer.garage) {
          return 'Гараж';
        }

        switch (offer.garage.type) {
          case 'box':
            return 'Бокс';
          case 'garage':
            return 'Гараж';
          case 'parkingPlace':
            return 'Машиноместо';
          default:
            return '';
        }
      }),
    ],
    offer,
  );
}

const isPro = (offer: IOffer): boolean => offer.isPro || false;

const isTop3 = (offer: IOffer): boolean => offer.isTop3 || false;

const isPremium = (offer: IOffer): boolean => offer.isPremium || false;

const isByHomeowner = (offer: IOffer): boolean => offer.isByHomeowner || false;

const isFromDeveloper = (offer: IOffer): boolean => offer.fromDeveloper || false;

const isFromBuilder = (offer: IOffer): boolean => (offer.newbuilding && offer.newbuilding.isFromBuilder) || false;

const isFromSeller = (offer: IOffer): boolean => (offer.newbuilding && offer.newbuilding.isFromSeller) || false;

const isLeadFactory = (offer: IOffer): boolean => (offer.newbuilding && offer.newbuilding.isFromLeadFactory) || false;

const isAgency = (offer: IOffer): boolean => (offer.user ? offer.user.accountType === 'agency' : false);

const isAgent = (offer: IOffer): boolean => (offer.user ? offer.user.isAgent : false);

const getNewbuildingName = (offer: IOffer): string => (offer.newbuilding && offer.newbuilding.name) || '';

const getUserTrustLevel = (offer: IOffer): UserTrustLevel | undefined =>
  offer.user ? offer.user.userTrustLevel : undefined;

const getUserPersonalRating = (offer: IOffer) => (offer.user ? offer.user.personalRating : undefined);

const getCianUserId = (offer: IOffer): string | number | undefined =>
  offer.user ? offer.user.cianUserId : offer.cianUserId || undefined;

const getAgentName = (offer: IOffer): string | undefined => (offer.user && offer.user.agencyName) || undefined;

const getAccountType = (offer: IOffer): string | undefined => (offer.user && offer.user.accountType) || undefined;

const getCompanyName = (offer: IOffer): string | undefined =>
  (offer.user && offer.user.subAgentCompanyName) || undefined;

const getAgentAvatarUrl = (offer: IOffer): string | null | undefined => offer.user?.agentAvatarUrl;

const getMasterAgent = (offer: IOffer): IMasterAgent | null | undefined => (offer.user ? offer.user.masterAgent : null);

const getExperienceString = (offer: IOffer): string | null => {
  const user = offer.user;

  if (!user) {
    return null;
  }

  if (!isFlat(offer)) {
    return null;
  }

  if (getMasterAgent(offer)) {
    return null;
  }

  if (isByHomeowner(offer)) {
    return null;
  }

  const experience = user.experience;

  if (!experience) {
    return null;
  }

  const experienceNumber = Number(experience);
  let experienceString = null;

  switch (true) {
    case isFromSeller(offer):
      experienceString = isNaN(experienceNumber) ? `На рынке ${experience}` : `Год основания: ${experience}`;
      break;

    case isAgency(offer):
      experienceString = isNaN(experienceNumber) ? `На рынке ${experience}` : `На рынке с ${experience} г.`;
      break;

    case isAgent(offer):
      experienceString = isNaN(experienceNumber) ? `Опыт: ${experience}` : `Опыт: с ${experience} г.`;
      break;

    default:
      break;
  }

  return experienceString;
};

const getAvatarUrl = (offer: IOffer): string | null | undefined => {
  const masterAgent = getMasterAgent(offer);

  if (masterAgent) {
    return masterAgent.absoluteAvatarUrl;
  }

  const user = offer.user;

  return user ? user.agentAvatarUrl : null;
};

const getDisplayName = (offer: IOffer): string | null => {
  const masterAgent = getMasterAgent(offer);
  if (masterAgent) {
    return masterAgent.name;
  }

  const user = offer.user;
  if (!user) {
    return null;
  }

  return getAgentName(offer) || `ID ${getCianUserId(offer)}`;
};

function getLeasePeriod(offer: IOffer): string | undefined {
  const leaseTermType = offer.bargainTerms.leaseTermType;
  let leasePeriod;

  if (offer.category && offer.category.startsWith('daily')) {
    leasePeriod = 'посуточно';
  } else if (leaseTermType) {
    switch (leaseTermType) {
      case LeaseTerm.Long:
        leasePeriod = 'от года';
        break;
      case LeaseTerm.FewMonths:
        leasePeriod = 'на несколько месяцев';
        break;
      default:
        break;
    }
  }

  return leasePeriod;
}

interface IOfferSearchType {
  name: string;
  getRightName(): string;
}

function createSearchType(name: string, getRightName: () => string): IOfferSearchType {
  return {
    getRightName,
    name,
  };
}

function findTypeById(values: IOfferSearchType[], offer: IOffer) {
  return values.reduce((acc, item) => {
    if (offer.category === item.name + 'Rent') {
      return item.getRightName();
    } else if (offer.category === item.name + 'Sale') {
      return item.getRightName();
    }

    return acc;
  }, '');
}

function getFloorRange(offer: IOffer): string | null {
  let floorInfo = null;

  if (offer.floorNumber) {
    floorInfo = `${offer.floorNumber} этаж`;

    if (offer.building && offer.building.floorsCount) {
      floorInfo += ` из ${offer.building.floorsCount}`;
    }
  }

  return floorInfo;
}

function getFloorCount(offer: IOffer) {
  if (offer.building && offer.building.floorsCount) {
    return offer.building.floorsCount + ' ' + plural(offer.building.floorsCount, ['этаж', 'этажа', 'этажей']);
  }

  return undefined;
}

function getBalcony(offer: IOffer): string {
  return offer.loggiasCount ? 'есть лоджия' : offer.balconiesCount ? 'есть балкон' : 'нет балкона';
}

function getBargainAllowed({ bargainTerms }: IOffer): string {
  return bargainTerms.bargainAllowed ? 'возможен торг' : '';
}

function getPhone(offer: IOffer): IPhone | null {
  if (offer.phones && offer.phones.length) {
    const phone = Object.assign({}, offer.phones && offer.phones[0]);
    if (phone.number) {
      phone.number.replace(/[^0-9.]/g, '');
    }

    return phone;
  } else {
    return null;
  }
}

function getPhones(offer: IOffer): IPhone[] | null {
  const phones = offer.phones?.map(offerPhone => {
    const phone = Object.assign({}, offerPhone);

    if (phone.number) {
      phone.number = phone.number.replace(/[^0-9.]/g, '');
    }

    return phone;
  });

  return phones?.length ? phones : null;
}

function getMaterialType(offer: IOffer): string | undefined {
  if (offer.building && offer.building.materialType) {
    return BuildingMaterialTypePresenter[offer.building.materialType];
  }

  return undefined;
}

function getBuildingDeadline(offer: IOffer): string | null {
  let text = '';

  if (offer.building && offer.building.deadline) {
    if (offer.building.deadline.isComplete) {
      text = 'дом сдан';
    } else {
      if (offer.building.deadline.quarter) {
        text = `сдача ГК: ${BuildingQuarter[offer.building.deadline.quarter]} кв. `;
      }
      if (offer.building.deadline.year) {
        text += `${offer.building.deadline.year} года`;
      }
      if (!offer.building.deadline.year && offer.building.buildYear) {
        text += `${offer.building.buildYear} года`;
      }
    }
  }

  return text === '' ? null : text;
}

function getBedroomsCount(offer: IOffer): string | null {
  if (offer.bedroomsCount) {
    return `${offer.bedroomsCount} ${plural(offer.bedroomsCount, ['спальня', 'спальни', 'спален'])}`;
  }

  return null;
}

/**
 * Возвращает заголовок объявления
 * @param offer объект объявления
 */
function getOfferTitle(offer: IOffer): string | null {
  if (offer.title) {
    return offer.title;
  }

  return null;
}

/* presenters. */

export interface IOfferPresenters {
  getAccessType(): string | void;
  getAccountType(): string | undefined;
  getAgentAvatarUrl(): string | undefined | null;
  getAgentName(): string | undefined;
  getBalcony(): string;
  getBargainAllowed(): string;
  getBedroomsCount(): string | null;
  getBuildingDeadline(): string | null;
  getCianUserId(): string | number | undefined;
  getClientFee(): string;
  getCommercialPerMeterByYear(): string | null;
  getCommercialRentPrice(): string | null;
  getCompanyName(): string | void;
  getDeposit(): string;
  getFee(): string;
  getFloorCount(): string | void;
  getFloorRange(): string | null;
  getKitchenArea(): string | null;
  getLeasePeriod(): string | void;
  getLeaseType(): string | void;
  getLift(): string;
  getLivingArea(): string | null;
  getMaterialType(): string | void;
  getMoreRentPrice(): string | null;
  getNewbuildingName(): string;
  /** возвращает заголовок объявления */
  getOfferTitle(): string | null;
  getOfferLayout(): string | void;
  getPaymentPeriod(): string;
  getPhone(): IPhone | null;
  getPhones(): IPhone[] | null;
  getRentPrice(regions: number[]): string;
  getRoomArea(): string | null;
  getSalePrice(): string | null;
  getSalePricePerMeter(): React.ReactNode | null;
  getSaleType(): string | null;
  getTotalArea(): string | null;
  getType(): string | null;
  getUserPersonalRating(): 'negative' | 'positive' | null | void;
  getUserTrustLevel(): UserTrustLevel | void;
  getMinArea(): string | null;
  getMinSalePrice(): string | null;
  getCommercialMinRentPrice(): string | null;
  getExperienceString(): string | null;
  getAvatarUrl(): string | null | undefined;
  getDisplayName(): string | null;
  getSalePriceWithTotalArea(): string;
}

export interface IOfferHelper {
  commercial: ICommercialHelper;
  presenters: IOfferPresenters;
  simplifiedCard: ISimplifiedCardHelper;
  suburban: ISuburbanHelper;

  getAnalyticsInfo(): IOfferAnalyticsInfo;

  isAgency(): boolean;
  isAgent(): boolean;
  isByHomeowner(): boolean;
  isCommercial(): boolean;
  isDailyRent(): boolean;
  isFlat(): boolean;
  isFromBuilder(): boolean;
  isFromDeveloper(): boolean;
  isFromSeller(): boolean;
  isLeadFactory(): boolean;
  isGarage(): boolean;
  isPassportVerified(): boolean;
  isHomeownerVerified(): boolean;
  isPremium(): boolean;
  isPrimaryMetroRegion(): boolean;
  isPro(): boolean;
  isRent(): boolean;
  isSale(): boolean;
  isTop3(): boolean;
}

/**
 * @todo Удалить параметр функции с RS
 * @description Данный функционал появился в задаче CD-143093, будет удалён в задаче CD-143213
 */
export function offerHelper(offer: IOffer, isCommercialNewVatTextEnabled?: boolean): IOfferHelper {
  return {
    commercial: commercialHelper(offer, isCommercialNewVatTextEnabled),
    getAnalyticsInfo: () => gatherAnalyticsInfo(offer),
    isAgency: () => isAgency(offer),
    isAgent: () => isAgent(offer),
    isByHomeowner: () => isByHomeowner(offer),
    isCommercial: () => isCommercial(offer),
    isDailyRent: () => isDailyRent(offer),
    isFlat: () => isFlat(offer),
    isFromBuilder: () => isFromBuilder(offer),
    isFromDeveloper: () => isFromDeveloper(offer),
    isFromSeller: () => isFromSeller(offer),
    isLeadFactory: () => isLeadFactory(offer),
    isGarage: () => isGarage(offer),
    isPassportVerified: () => isPassportVerified(offer),
    isHomeownerVerified: () => isHomeownerVerified(offer),
    isPremium: () => isPremium(offer),
    isPrimaryMetroRegion: () => isPrimaryMetroRegion(offer),
    isPro: () => isPro(offer),
    isRent: () => isRent(offer),
    isSale: () => isSale(offer),
    isTop3: () => isTop3(offer),
    presenters: {
      getAccessType: () => getAccessType(offer),
      getAccountType: () => getAccountType(offer),
      getAgentAvatarUrl: () => getAgentAvatarUrl(offer),
      getAgentName: () => getAgentName(offer),
      getBalcony: () => getBalcony(offer),
      getBargainAllowed: () => getBargainAllowed(offer),
      getBedroomsCount: () => getBedroomsCount(offer),
      getBuildingDeadline: () => getBuildingDeadline(offer),
      getCianUserId: () => getCianUserId(offer),
      getClientFee: () => getClientFee(offer),
      getCommercialMinRentPrice: () => getCommercialMinRentPrice(offer),
      getCommercialPerMeterByYear: () => getCommercialPerMeterByYear(offer),
      getCommercialRentPrice: () => getCommercialRentPrice(offer),
      getCompanyName: () => getCompanyName(offer),
      getDeposit: () => getDeposit(offer),
      getFee: () => getFee(offer),
      getFloorCount: () => getFloorCount(offer),
      getFloorRange: () => getFloorRange(offer),
      getKitchenArea: () => getKitchenArea(offer),
      getLeasePeriod: () => getLeasePeriod(offer),
      getLeaseType: () => getLeaseType(offer),
      getLift: () => getLift(offer),
      getLivingArea: () => getLivingArea(offer),
      getMaterialType: () => getMaterialType(offer),
      getMinArea: () => getMinArea(offer),
      getMinSalePrice: () => getMinSalePrice(offer),
      getMoreRentPrice: () => getMoreRentPrice(offer),
      getNewbuildingName: () => getNewbuildingName(offer),
      getOfferLayout: () => getOfferLayout(offer),
      getOfferTitle: () => getOfferTitle(offer),
      getPaymentPeriod: () => getPaymentPeriod(offer),
      getPhone: () => getPhone(offer),
      getPhones: () => getPhones(offer),
      getRentPrice: (excludedRegions: number[] = []) => getRentPrice(offer, excludedRegions),
      getRoomArea: () => getRoomArea(offer),
      getSalePrice: () => getSalePrice(offer),
      getSalePricePerMeter: () => getSalePricePerMeter(offer),
      getSaleType: () => getSaleType(offer),
      getTotalArea: () => getTotalArea(offer),
      getType: () => getType(offer),
      getUserPersonalRating: () => getUserPersonalRating(offer),
      getUserTrustLevel: () => getUserTrustLevel(offer),
      getExperienceString: () => getExperienceString(offer),
      getAvatarUrl: () => getAvatarUrl(offer),
      getDisplayName: () => getDisplayName(offer),
      getSalePriceWithTotalArea: () => getSalePriceWithTotalArea(offer),
    },
    simplifiedCard: simplifiedCardHelper(offer),
    suburban: suburbanHelper(offer),
  };
}
