import { IAppState, TActions } from '../../common/state';

export interface IPushSubscribedSetValueAction {
  type: 'IPushSubscribedSetValueAction';
  value: boolean;
}

export function setKeyPushNotificationValue(value: boolean): IPushSubscribedSetValueAction {
  return {
    type: 'IPushSubscribedSetValueAction',
    value,
  };
}

export function pushSubscribedReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'IPushSubscribedSetValueAction':
      return {
        ...state,
        showPushNotification: action.value,
      };

    default:
      return state;
  }
}
