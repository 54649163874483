import { IAppState, TActions } from '../../../common/state';
import { ETenant, IJsonQueryTerms } from '../../../json_query';

const ITenantsChangedActionType = 'filters/tenants/TENANTS_CHANGED';

export interface ITenantsChangedAction {
  type: 'filters/tenants/TENANTS_CHANGED';
  tenant: ETenant;
  value: boolean;
}

export function changeTenant(tenant: ETenant, value: boolean): ITenantsChangedAction {
  return {
    type: ITenantsChangedActionType,
    tenant,
    value,
  };
}

export function tenantsReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ITenantsChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            composition: changeTenants(
              state.filters.jsonQuery.composition && state.filters.jsonQuery.composition.value,
              action.tenant,
              action.value,
            ),
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-tenants') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              composition: changeTenants(
                state.filters.jsonQuery.composition && state.filters.jsonQuery.composition.value,
                action.tag.value,
                false,
              ),
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            composition: undefined,
          },
        },
      };

    default:
      return state;
  }
}

function changeTenants(
  tenants: ETenant[] | undefined,
  tenant: ETenant,
  value: boolean,
): IJsonQueryTerms<ETenant> | undefined {
  if (!value) {
    if (tenants && tenants.length > 1) {
      return {
        type: 'terms',
        value: tenants.filter(item => item !== tenant),
      };
    }

    return undefined;
  } else {
    return {
      type: 'terms',
      value: (tenants && tenants.concat(tenant)) || [tenant],
    };
  }
}
