import { IAppState, TActions } from '../../../common/state';
import { EBedroomsCount, jq } from '../../../json_query';

const IBedroomsChangedActionType = 'filters/bedroomsCount/BEDROOMS_CHANGED';

export interface IBedroomsChangedAction {
  type: 'filters/bedroomsCount/BEDROOMS_CHANGED';
  bedroomsCount: EBedroomsCount;
}

export function changeBedroomsCount(bedroomsCount: EBedroomsCount): IBedroomsChangedAction {
  return {
    type: IBedroomsChangedActionType,
    bedroomsCount,
  };
}

export function bedroomsReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IBedroomsChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setMinBedroomsCount(action.bedroomsCount),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-bedroomsCount') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setMinBedroomsCount(undefined),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setMinBedroomsCount(undefined),
        },
      };

    default:
      return state;
  }
}
