import { IAppState, TActions } from '../../../common/state';
import { EGarageMaterial, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TGarageMaterialChangedActionType = 'filters/garage/GARAGE_MATERIAL_CHANGED';
export const IGarageMaterialChangedActionType =
  'filters/garage/GARAGE_MATERIAL_CHANGED' as TGarageMaterialChangedActionType;

export interface IGarageMaterialChangedAction {
  type: 'filters/garage/GARAGE_MATERIAL_CHANGED';
  paramType: EGarageMaterial;
  value: boolean;
}

export const changeGarageMaterial = getParamChangedAction<EGarageMaterial, TGarageMaterialChangedActionType>(
  IGarageMaterialChangedActionType,
);

export function garageMaterialReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IGarageMaterialChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setGarageMaterial(action.paramType)
            : jq(state.filters.jsonQuery).unsetGarageMaterial(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-garageMaterial') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetGarageMaterial(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetGarageMaterials(),
        },
      };

    default:
      return state;
  }
}
