import { IHttpApi } from '@cian/http-api/shared';

import { createModerationComplaintsModel } from './helpers';
import { TAddFeedbackModelRequestSchema, TAddFeedbackModelResponseSchema } from './types';

export function sendFeedbackFrom(
  httpApi: IHttpApi,
  parameters: TAddFeedbackModelRequestSchema,
): Promise<TAddFeedbackModelResponseSchema> {
  return httpApi.fetch(createModerationComplaintsModel({ hostType: 'ud-api', parameters }), {
    requestConfig: {
      headers: [['Content-Type', 'application/json']],
      withCredentials: false,
    },
  });
}
