/**
 * @todo Удалить файл с RS
 * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117942
 */

let userProfilingEnabled: null | boolean = null;

export function setUserProfilingEnabled(enabled: boolean) {
  userProfilingEnabled = enabled;
}

export function getUserProfilingEnabled() {
  return !!userProfilingEnabled;
}
