/* eslint-disable max-lines */
import { ca } from '@cian/analytics';
import { IConfig } from '@cian/config/shared';

import { IFiltersState, IResultsState, IUserGADataLayerData } from '../common/state';
import { IJsonQuery } from '../json_query';
import { mlSearchSession } from '../mlSearchSession';
import { EOfferExtensionType, IBreadcrumb, IOffer, offerHelper } from '../offer';
import { EType } from '../repositories/search-offers.legacy/v2/types';
import { getOfferExtra } from '../tracking/getOfferExtra';
import { IAbUseExperiment } from '../types/abUseExperiments';
import { TUser } from '../types/user';
import { dealTypeFromJsonQuery } from '../utils/category';
import { isSaleFromSuburbanBuilder } from '../utils/isSaleFromSuburbanBuilder';
import { isExtended } from '../utils/offer/tracking';
import {
  getDealType,
  getHeadline,
  getObjectTypeWithCategory,
  getObjectTypeWithJsonQuery,
  getOfferParentId,
} from '../utils/trackings';

import { enhanceSearchConditions } from './helpers';

interface IActionInfo {
  action_id: number;
  action_type: EType;
  visible: number;
}

interface IPageViewExtra {
  identicalOffers?: { id: number; offerType: 'offer' }[];
  rankingTags?: { name: string }[];
  extended_type?: string[];
  parentId?: number;
  actions_info?: IActionInfo[];
}

interface IExtraData {
  extended_type: EOfferExtensionType[] | undefined;
  mlSearchSessionGuid: string | null;
  searchRequestId: string;
}

export const getCriteoProducts = (offers: IOffer[]) => offers.slice(0, 4).map(offer => Number(offer.id));

export const isPublished = (offer: IOffer) => offer.status === 'published';

export function getDealTypeForCustomUrl(type: string) {
  if (type.indexOf('rent') >= 0) {
    return 'rent';
  }

  if (type.indexOf('sale') >= 0) {
    return 'sale';
  }

  return '';
}

/* istanbul ignore next */
export function getOfferTypeorCustomUrl(type: string) {
  if (type.indexOf('flat') >= 0) {
    return 'flat';
  }

  if (type.indexOf('commercial') >= 0) {
    return 'commercial';
  }

  if (type.indexOf('suburban') >= 0) {
    return 'suburban';
  }

  return '';
}

/* istanbul ignore next */
export function getCustomUrl(jsonQuery: IJsonQuery) {
  const customUrl: Array<string | undefined | boolean | number> = [];

  const customUrlDealType = getDealTypeForCustomUrl((jsonQuery && jsonQuery._type) || '');
  const customUrlOfferType = getOfferTypeorCustomUrl((jsonQuery && jsonQuery._type) || '');

  if (customUrlDealType) {
    customUrl.push(customUrlDealType);
  }

  if (customUrlOfferType) {
    customUrl.push(customUrlOfferType);
  }

  return `/${customUrl.join('/')}/`;
}

interface IGetGTMMetaParams {
  filters: IFiltersState;
  results: IResultsState;
  breadcrumbs: IBreadcrumb[] | null;
  user: TUser;
  userGALayerData: IUserGADataLayerData | null;
  profileSessionKey: string;
  fbRegion: string | undefined;
  fbCity: string | undefined;
  searchGuid: string | null;
  mlRankingGuid: string | null;
  mlRankingModelVersion: string | null;
  mlSearchSessionGuid: string | null;
  searchRequestId: string;
  extensions?: EOfferExtensionType[];
  abUseExperiments?: IAbUseExperiment[];
}

interface IGetGTMMetaParamsContext {
  config: IConfig;
}

/**
 * @returns объект данных для отправки в аналитику
 * @param filters
 * @param results
 * @param breadcrumbs
 * @param user
 * @param userGALayerData
 * @param profileSessionKey
 * @param fbRegion
 * @param fbCity
 * @param searchGuid уникальный id поискового запроса
 * @param mlRankingGuid уникальный id ML-ранжирования
 * @param mlRankingModelVersion версия модели ML-ранжирования
 * @param mlSearchSessionGuid уникальный идентификатор поиска пользователя для ml-ранжирования.
 * Хранится на стороне браузера (sessionStorage). См. класс MlSearchSession.
 * @param searchRequestId - id запроса search-offers-desktop. На каждый запрос приходит новый id
 * @param extensions
 * @param abUseExperiments
 * @param ctx
 */
export const getGTMObjectData = (
  {
    filters,
    results,
    breadcrumbs,
    user,
    userGALayerData,
    fbRegion,
    fbCity,
    searchGuid,
    mlRankingGuid,
    mlRankingModelVersion,
    mlSearchSessionGuid,
    searchRequestId,
    extensions,
  }: IGetGTMMetaParams,
  ctx: IGetGTMMetaParamsContext,
) => {
  const { jsonQueryUrl, jsonQuery: jsonQueryFromFilters } = filters;
  const { jsonQuery, offers, totalOffers, queryString } = results;

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const pageNumber = jsonQuery.page && jsonQuery.page.value ? Number(jsonQuery.page.value) : 1;
  const region =
    jsonQuery && jsonQuery.region
      ? String(jsonQuery.region.value[0])
      : jsonQueryFromFilters && jsonQueryFromFilters.region
        ? String(jsonQueryFromFilters.region.value[0])
        : '1';
  const hasExtensions = extensions && extensions.length;

  // позиции обычных и расширенных офферов считаются отдельно
  let extendedOfferPosition = 1;
  let regularOfferPosition = 1;

  const products = offers.map(offer => {
    const parentId = getOfferParentId(offer, jsonQuery);
    const { id, price, brand, variant, top3Place } = offerHelper(offer).getAnalyticsInfo();

    let extra: IPageViewExtra = {};
    const isCountrysideDeveloper = isSaleFromSuburbanBuilder(offer);
    const isOfferExtended = hasExtensions && isExtended(offer);
    const position = isOfferExtended ? extendedOfferPosition : regularOfferPosition;

    if (isOfferExtended) {
      extra.extended_type = offer.extensionLabels ? offer.extensionLabels.map(({ labelType }) => labelType) : [];
      extendedOfferPosition += 1;
    } else {
      regularOfferPosition += 1;
    }

    if (offer.identicalOffers) {
      const { duplicatesOffersIds } = offer.identicalOffers;
      extra.identicalOffers = duplicatesOffersIds.map(id => ({ id, offerType: 'offer' }));
    }

    if (parentId) {
      extra.parentId = parentId;
    } else {
      extra.parentId = 0;
    }

    if (offer.photoLabels) {
      extra.rankingTags = offer.photoLabels.map(({ text }) => ({ name: text }));
    }

    if (offer.kp && offer.kp.promotions && offer.kp.promotions.length) {
      extra.actions_info = offer.kp.promotions.map((promo, index) => {
        return {
          action_id: promo.id,
          action_type: promo.type,
          visible: index === 0 ? 1 : 0,
        };
      });
    }

    const cplExtra = getOfferExtra(offer);
    if (cplExtra) {
      extra = {
        ...extra,
        ...cplExtra,
      };
    }

    return {
      brand,
      cianId: offer.cianId,
      dealType: getDealType(dealType),
      headline: getHeadline(offer),
      hiddenBase: Boolean(offer.isInHiddenBase),
      id,
      objectType: getObjectTypeWithCategory(offer.category, isCountrysideDeveloper),
      owner: Boolean(offer.isByHomeowner),
      ownerCianId: offer.cianUserId,
      ownerId: offer.userId,
      parentId: parentId || undefined,
      photosCount: offer.photos ? offer.photos.length : 0,
      podSnos: Boolean(offer.demolishedInMoscowProgramm),
      position,
      price,
      published: isPublished(offer),
      variant: variant ? variant.split('/') : [],
      ...(top3Place && { top3Place }),
      consultant: offer.isDealRequestSubstitutionPhone,
      extra,
    };
  });

  const extraData: IExtraData = {
    extended_type: hasExtensions ? extensions : undefined,
    mlSearchSessionGuid,
    searchRequestId,
  };

  return {
    name: 'newpage',
    action: 'newpage',
    category: 'page',
    label: jsonQueryUrl || '',
    searchConditions: enhanceSearchConditions(jsonQuery, region, ctx.config),
    user: {
      userId: user.isAuthenticated && user.userId ? user.userId : undefined,
      cianUserId: user.isAuthenticated && user.id ? user.id : undefined,
      email: user.isAuthenticated && user.email ? user.email : undefined,
      type: userGALayerData && userGALayerData.userType ? userGALayerData.userType : 'not_authorized',
      abGroup: (userGALayerData && Number(userGALayerData.abGroup)) || 0,
    },
    page: {
      breadCrumbs: breadcrumbs ? breadcrumbs.map(breadcrumb => breadcrumb.title) : [],
      pageNumber,
      pageType: 'Listing',
      region,
      objectType: getObjectTypeWithJsonQuery(jsonQuery),
      dealType: getDealType(dealType),
      siteType: 'desktop',
      offersQty: Number(totalOffers),
      sortType: (jsonQuery.sort && jsonQuery.sort.value) || 'default',
      listingType: hasExtensions ? 'extended' : 'list',
      queryString: queryString ? `/cat.php?${encodeURIComponent(queryString)}` : undefined,
      searchGuid,
      mlRankingGuid,
      mlRankingModelVersion,
      extra: extraData,
    },
    partner: {
      CriteoProducts: getCriteoProducts(offers),
      CriteoEmail: (userGALayerData && userGALayerData.criteoEmail) || undefined,
      CriteoNewCustomer: (userGALayerData && userGALayerData.criteoNewCustomer) || undefined,
      FB_City: fbRegion || undefined,
      FB_Region: fbCity || undefined,
    },
    products,
  };
};

interface ITrackPageViewParams {
  filters: IFiltersState;
  results: IResultsState;
  breadcrumbs: IBreadcrumb[] | null;
  user: TUser;
  userGALayerData: IUserGADataLayerData | null;
  profileSessionKey: string;
  fbRegion: string | undefined;
  fbCity: string | undefined;
  searchGuid: string | null;
  mlRankingGuid: string | null;
  mlRankingModelVersion: string | null;
  searchRequestId: string;
  extensions?: EOfferExtensionType[];
}

interface ITrackPageViewContext {
  config: IConfig;
}

/* istanbul ignore next */
export const trackPageView = (
  {
    filters,
    results,
    breadcrumbs,
    user,
    userGALayerData,
    profileSessionKey,
    fbRegion,
    fbCity,
    searchGuid,
    mlRankingGuid,
    mlRankingModelVersion,
    searchRequestId,
    extensions,
  }: ITrackPageViewParams,
  ctx: ITrackPageViewContext,
) => {
  const mlSearchSessionGuid = mlSearchSession.getSessionGuid(results.jsonQuery);

  return ca(
    'pageviewSite',
    getGTMObjectData(
      {
        filters,
        results,
        breadcrumbs,
        user,
        userGALayerData,
        profileSessionKey,
        fbRegion,
        fbCity,
        searchGuid,
        mlRankingGuid,
        mlRankingModelVersion,
        mlSearchSessionGuid,
        searchRequestId,
        extensions,
      },
      ctx,
    ),
  );
};
