import * as React from 'react';

import { IBSCenterInfo } from '../../../api/bs_centers';
import { IJsonQuery } from '../../../json_query';

import { HeaderBSCenterContainer } from './bs_center/container';
import { HeaderDefaultContainer } from './default/container';

export interface IHeaderProps {
  jsonQuery: IJsonQuery;
  bsCenterInfo: IBSCenterInfo | null;
}

export const Header: React.FC<IHeaderProps> = ({ bsCenterInfo }) => {
  if (bsCenterInfo) {
    return <HeaderBSCenterContainer />;
  }

  return <HeaderDefaultContainer />;
};
