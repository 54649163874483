// Список ипотечных кампаний AdFox
export const mortgageCampaignIds = ['842366', '837912', '867942', '986245', '990363', '966997'];

/**
 * Указывает, является ли данная кампания кампанией ипотеки
 * @param campaignId
 */
export function isMortgageCampaign(campaignId: string) {
  return mortgageCampaignIds.includes(campaignId);
}
