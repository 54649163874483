import * as PropTypes from 'prop-types';
import { Component } from 'react';

import { getAgentLevelInfo } from 'shared/utils/getAgentLevelInfo';
import { showAgentLevel } from 'shared/utils/showAgentLevel';

import { ECianProfileStatus } from '../../types/offer';
import { CommercialOwner } from '../../utils/CommercialOwner';
import { AgentLevelView } from '../AgentLevelView';
import { AgentWorkHonestlyProofs, showAgentHonestlyProofs } from '../AgentWorkHonestlyProofs';
import { PartnerTooltip } from '../PartnerTooltip';
import { RosreestrVerified } from '../RosreestrVerified';

import { isAgentBrandShow as getIsAgentBrandShow } from './helpers';
import { IconBlacklist, IconWhitelist } from './icons';

import { IUserInfoContext, TUserInfoType } from './index';

import * as style from './index.css';

export class UserInfoVertical extends Component {
  public context: IUserInfoContext;

  public static contextTypes: Record<keyof UserInfoVertical['context'], PropTypes.Requireable<unknown>> = {
    offer: PropTypes.object,
    offerHelper: PropTypes.object,
    user: PropTypes.object,
    commercialOwner: CommercialOwner.contextTypes,
    abUseExperiments: PropTypes.arrayOf(
      PropTypes.shape({ experimentName: PropTypes.string, string: PropTypes.string }),
    ),
  };

  public static defaultProps = {
    type: 'vertical' as TUserInfoType,
  };

  public render() {
    const { offer, offerHelper, commercialOwner } = this.context;
    const { isByHomeowner } = offer;

    const userPersonalRating = offerHelper.presenters.getUserPersonalRating();
    const userId = offerHelper.presenters.getCianUserId();
    const agentName = offerHelper.presenters.getAgentName();
    const companyName = offerHelper.presenters.getCompanyName();
    const isFromBuilder = offerHelper.isFromBuilder();
    const isFromSeller = offerHelper.isFromSeller();
    const isRepresentative = !!(offer.businessShoppingCenter && offer.businessShoppingCenter.fromRepresentative);
    const isAgentBrandShow = getIsAgentBrandShow(offer);
    const isAgentNameShow = offer.user && offer.user.cianProfileStatus === ECianProfileStatus.Approved;
    const isCianPartner = offer.user && offer.user.isCianPartner;
    const userLevelInfo = getAgentLevelInfo(offer);

    return (
      <div className={style['userInfoContainer']}>
        {isAgentNameShow && (
          <div className={style['userInfo-name']}>
            <noindex>{agentName || `ID ${userId}`}</noindex>
          </div>
        )}

        {!!(isAgentBrandShow && companyName) && (
          <div className={style['userInfo-company']}>
            <noindex>{companyName}</noindex>
          </div>
        )}

        {isFromBuilder && (
          <div
            className={`${style['userInfo-textBadge']} ${style['userInfo-textBadge--green']}`}
            title="Объявления, размещаемые самим застройщиком, либо агентством недвижимости с официальным правом продажи по ценам застройщика"
          >
            Застройщик
          </div>
        )}

        {!isFromBuilder && isFromSeller && (
          <div className={`${style['userInfo-textBadge']} ${style['userInfo-textBadge--green']}`}>
            <span>
              Представитель
              <br />
              застройщика
            </span>
          </div>
        )}

        <div className={`${style['userInfo-badges']}`}>
          {showAgentLevel(offer) && !!userLevelInfo && (
            <div className={style['userInfo-badge']} data-testid="honest-realtor-new-commercial-wrapper">
              <AgentLevelView extendedView agentLevelInfo={userLevelInfo} />
            </div>
          )}

          {showAgentHonestlyProofs(offer) && (
            <div className={style['userInfo-badge']} data-testid="agent-work-honestly-proofs-wrapper">
              <AgentWorkHonestlyProofs offer={offer} />
            </div>
          )}

          {commercialOwner?.showRosreestrVerified && (
            <div className={style['rosreestr-verified-wrapper']} data-testid="rosreestr-verified-wrapper">
              <RosreestrVerified />
            </div>
          )}

          {isCianPartner && (
            <PartnerTooltip
              master={
                <div className={style['userInfo-badge']}>
                  <div className={`${style['userInfo-textBadge']} ${style['greenLight']}`}>Партнёр</div>
                </div>
              }
            />
          )}

          {isByHomeowner && (
            <div className={style['userInfo-badge']}>
              <div className={`${style['userInfo-textBadge']}`}>Собственник</div>
            </div>
          )}

          {isRepresentative && (
            <div className={style['userInfo-badge']}>
              <div className={`${style['userInfo-textBadge']} ${style['greenLight']}`}>Оф. представитель</div>
            </div>
          )}

          {userPersonalRating === 'positive' && (
            <div className={style['userInfo-badge']}>
              <IconWhitelist isActive />
            </div>
          )}

          {userPersonalRating === 'negative' && (
            <div className={style['userInfo-badge']}>
              <IconBlacklist isActive />
            </div>
          )}
        </div>
      </div>
    );
  }
}
