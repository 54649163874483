import { EOfficeType, IJsonQuery } from 'shared/json_query';

import { USER_PROFILING_STEPS_SCHEMA } from '../constants';

type TStepName = keyof typeof USER_PROFILING_STEPS_SCHEMA;

export const getNextStepByOfficeType = (jsonQuery: IJsonQuery): TStepName => {
  const officeType = jsonQuery.office_type?.value || [];

  if (officeType.length > 1) {
    return 'commonObjectPage';
  }

  if (jsonQuery.coworking_offer_type?.value) {
    return 'coworkingPage';
  }

  switch (officeType[0]) {
    case EOfficeType.Business:
      return 'businessPage';

    case EOfficeType.FreeAppointmentObject:
      return 'freeAppointmentObjectPage';

    case EOfficeType.Warehouse:
      return 'warehousePage';

    default:
      return 'commonObjectPage';
  }
};
