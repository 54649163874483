/**
 * @todo Удалить файл с RS
 * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117942
 */

let userProfilingAvalablesEmails: string[] = [];

export function setUserProfilingAvalablesEmails(emails: string[] = []): void {
  userProfilingAvalablesEmails = emails;
}

export function getUserProfilingAvalablesEmails(): string[] {
  return userProfilingAvalablesEmails;
}
