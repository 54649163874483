import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';

import { TGeoType } from '../../filters/state/geo';
import { ILocation } from '../../types/location';
import { repeatTask } from '../../utils/repeat_task';
import { IMakeRequest } from '../../utils/request';
import { loadYmapsApi } from '../../utils/ymaps';

import { createSuggestRailwaysModel, createUnderConstructionModel } from './helpers';
import { ISuggestRailwayData, ISuggestRailwaysModelRequest, IUnderConstructiontionInfo } from './types';

export interface IGeocodeCachedResult {
  text: string;
  name: string;
  kind: YMaps.TGeoKind;
  coordinates: YMaps.TCoord;
  boundedBy: [YMaps.TCoord, YMaps.TCoord];
}

export interface IGeocodeForSearchRequest {
  text: string;
  kind: YMaps.TGeoKind;
  coordinates: YMaps.TCoord;
}

export interface IGeoLocationCatalogLink {
  id: number;
  objectType: TGeoType;
}

export type TGeocodeForSearchGeoType =
  | 'Country'
  | 'Location'
  | 'Street'
  | 'Road'
  | 'District'
  | 'Underground'
  | 'House';

export interface IGeocodeForSearchResponse {
  geo: {
    lat: number;
    lng: number;
  };
  geoLocationCatalogLink: IGeoLocationCatalogLink | null;
  text: string;
  isParsed: boolean;
  details: IGeoDetail[];
  countryId: number;
  regionId: number;
  microDistricts: [
    {
      fullName: string;
      hasBoundaries: boolean;
      id: number;
      locationFullName: string;
      locationId: number;
      name: string;
      nativeName: string;
    },
  ];
}

export interface IGeoDetail {
  id: number;
  name: string;
  fullName: string;
  geoType: TGeocodeForSearchGeoType;
  locationInfo: {
    text: string;
    hasMetro: boolean;
    hasHighway: boolean;
    hasDistricts: boolean;
    hasNeighbors: boolean;
    parentId: number | null;
    mainTownId: number | null;
    lat: number;
    lng: number;
    boundedBy: {
      lowerCorner: {
        lat: number;
        lng: number;
      };
      upperCorner: {
        lat: number;
        lng: number;
      };
    };
  };
}

export interface IGeoDistrict {
  id: number;
  name: string;
  type: 'Raion' | 'Okrug' | 'MikroRaion';
  childs: IGeoDistrict[];
  direction: {
    code: number | null;
    name: string | null;
  };
}

export interface IGeoDistrictsTreeResponse {
  data: IGeoDistrict[];
}

function isScriptError(item: {}): item is YMaps.IScriptError {
  return item && 'message' in item && (item as { message?: string }).message === 'scriptError';
}

export function suggest(request: string, options?: YMaps.ISuggestOptions): Promise<YMaps.ISuggestResult[]> {
  return repeatTask(3, () =>
    loadYmapsApi({ require: ['suggest'] })
      .then(ymaps => ymaps.suggest(request, options))
      .then(items => {
        if (isScriptError(items)) {
          throw new Error(items.message);
        }

        return items || [];
      }),
  );
}

export function geocodeCached(makeRequest: IMakeRequest, request: string): Promise<IGeocodeCachedResult[]> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-cian-realty',
    pathApi: '/api/geo/geocode-cached/',
    parameters: {
      request,
    },
    method: 'GET',
  }).then((res: { items: IGeocodeCachedResult[] }) => res.items);
}

export function geocode(request: string, options?: YMaps.IGeocodeOptions) {
  return loadYmapsApi({ require: ['geocode'] })
    .then(ymaps => ymaps.geocode(request, options))
    .then(items => items || []);
}

export function geocodeForSearch(
  makeRequest: IMakeRequest,
  request: IGeocodeForSearchRequest,
): Promise<IGeocodeForSearchResponse> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-cian-realty',
    pathApi: '/api/geo/geocoded-for-search/',
    headers: [['Content-Type', 'application/x-www-form-urlencoded']],
    parameters: {
      Lng: request.coordinates[0],
      Lat: request.coordinates[1],
      Kind: request.kind,
      Address: request.text,
    },
    bodyAsEncodeString: true,
    method: 'POST',
  });
}

export function getUnderConstruction(httpApi: IHttpApi): Promise<IUnderConstructiontionInfo[]> {
  return httpApi.fetch(createUnderConstructionModel({})).then(resp => {
    return resp.response.undergrounds;
  });
}

export function requestDistrictsTree(makeRequest: IMakeRequest, locationId: number): Promise<IGeoDistrict[]> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-cian-realty',
    pathApi: '/api/geo/get-districts-tree/',
    parameters: {
      locationId,
    },
    method: 'GET',
  }).then((res: IGeoDistrict[]) => {
    return res;
  });
}

export function getSuggestRailways(
  httpApi: IHttpApi,
  parameters: ISuggestRailwaysModelRequest,
  logger: ILogger,
): Promise<ISuggestRailwayData[]> {
  return httpApi.fetch(createSuggestRailwaysModel({ parameters })).then(resp => {
    if (resp.statusCode === 200) {
      return resp.response.data;
    }

    const { regionId } = parameters;

    const dataError = new Error(`Failed to get railways for regionId = ${regionId}`);
    logger.error(dataError);

    return [];
  });
}

export interface IGetLocationResponse {
  data: ILocation;
  status: 'ok';
}

export interface IGeocodeError {
  message: string;
  errors: {
    key: string;
    message: string;
  }[];
}

export function isSuccessResponse<S, E>(response: S | E, key: string): response is S {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return key in (response as any);
}

export function getLocationById(makeRequest: IMakeRequest, regionOrMaintownId: number): Promise<ILocation> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-location/',
    parameters: {
      regionId: regionOrMaintownId,
    },
    method: 'GET',
  }).then((response: IGetLocationResponse | IGeocodeError) => {
    if (!isSuccessResponse<IGetLocationResponse, IGeocodeError>(response, 'data')) {
      throw new Error(`Failed to get location for regionOrMaintownId = ${regionOrMaintownId}`);
    }

    return response.data;
  });
}
