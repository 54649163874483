import { IAppState, TActions } from '../../common/state';
import { setTerm, setTerms } from '../../json_query/reducers/helpers';

export function amenityReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/tags/TAG_REMOVED': {
      // @ts-expect-error тесты
      const nextValue = action.tag.value;
      if (
        action.tag.type === 'advanced-amenity' &&
        state.filters.jsonQuery.amenity &&
        state.filters.jsonQuery.amenity.value &&
        state.filters.jsonQuery.amenity.value.length > 0 &&
        nextValue > 0
      ) {
        const nextAmenity = state.filters.jsonQuery.amenity.value.filter(value => value !== nextValue);

        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: setTerms('amenity')(state.filters.jsonQuery)(nextAmenity),
          },
        };
      }

      return state;
    }

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: setTerm('amenity')(state.filters.jsonQuery)(undefined),
        },
      };

    default:
      return state;
  }
}
