import { ca } from '@cian/analytics';

import { ITrackingUserData } from '../common/state';
import { ITrackingPageData } from '../serp/state/tracking';

type TEventCategory = 'LinkIpoteka' | 'BannerIpoteka';
type TEventAction = 'show' | 'click';

export const trackAdFoxMortgage = (
  action: TEventAction,
  category: TEventCategory,
  label: string,
  user: ITrackingUserData,
  page: ITrackingPageData,
) => {
  ca('eventSite', {
    name: 'ipotekaevent',
    action,
    category,
    label,
    user,
    page,
  });
};
