import * as React from 'react';

import { IconAnalytics } from '../IconAnalytics';

import * as styles from './index.css';

export interface IAnalyticsButton {
  onClick(): void;
}

export const AnalyticsButton: React.FC<IAnalyticsButton> = ({ onClick }) => (
  <div className={styles['analytics-button']} role="button" onClick={onClick}>
    <IconAnalytics className={styles['icon']} />
    <div className={styles['button-text']}>
      <span className={styles['button-text-title']}>Доступна аналитика по объекту</span>
      <span className={styles['button-text-link']}>Посмотреть отчёт</span>
    </div>
  </div>
);

AnalyticsButton.displayName = 'AnalyticsButton';
