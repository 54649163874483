import { ModalWindow, Spinner } from '@cian/ui-kit';

import { Component } from 'react';

import { IJsonQueryRangeValue } from 'shared/json_query';

import { IDirection } from '../../../api/directions';

import { Direction } from './direction';

const style = require('./index.css');

export interface IDirectionModalProps {
  directions: IDirection[];
  fromMKAD: IJsonQueryRangeValue;
  isLoading: boolean;
  selectedHighwayIds: number[];
  label: string;
  changeMinFromMKAD(value: number | undefined): void;
  changeMaxFromMKAD(value: number | undefined): void;
  close(): void;
  deselectHighways(highwaysIds: number[]): void;
  requestDirectionsData(): void;
  searchBySelectedHighways(): void;
  selectHighways(highwaysIds: number[]): void;
}

// Не используется, нужно удалить в будущем
export class DirectionsModal extends Component<IDirectionModalProps> {
  public componentDidMount() {
    const { directions, requestDirectionsData } = this.props;

    if (!directions.length) {
      requestDirectionsData();
    }
  }

  public render() {
    const { close, directions, isLoading } = this.props;

    return (
      <ModalWindow
        open
        // В дальнейшем будет удален весь файл, оставил на всякий случай
        footer={null}
        // В дальнейшем будет удален весь файл, оставил на всякий случай
        header={null}
        width={1000}
        onClose={close}
      >
        {directions.length > 0 && this.renderColumns()}
        {isLoading && (
          <div className={style['preloader-wrapper']}>
            <div className={style['preloader']}>
              {/* Заменил Preloader из cian/components на Spinner
                  В дальнейшем будет удален весь файл, оставил на всякий случай */}
              <Spinner size={48} />
            </div>
          </div>
        )}
      </ModalWindow>
    );
  }

  private renderColumns = () => {
    return (
      <div className={style['directions']}>
        {this.getColumns().map((column, index) => (
          <div className={style['directions-column']} key={`directions-modal-column-${index}`}>
            {this.renderColumn(column)}
          </div>
        ))}
      </div>
    );
  };

  private renderColumn(column: IDirection[]) {
    return column.map(({ name, highways }, index) => (
      <Direction
        deselectHighways={this.props.deselectHighways}
        highways={highways}
        key={`directions-modal-item-${index}`}
        name={name}
        selectHighways={this.props.selectHighways}
        selectedHighwayIds={this.props.selectedHighwayIds}
      />
    ));
  }

  private getDirectionsSize = (direction: IDirection[]) => {
    // Высота заголовка относительно детей
    const REL_TITLE_HEIGHT = 2.5;

    // Размер колонки с учётом количества шоссе и названия направления
    return direction.reduce((prev, curr) => {
      return prev + curr.highways.length + REL_TITLE_HEIGHT;
    }, 0);
  };

  private getColumnSize = () => {
    const COLUMNS_COUNT = 4;
    const allDirectionsSize = this.getDirectionsSize(this.props.directions);

    return Math.round(allDirectionsSize / COLUMNS_COUNT);
  };

  /**
   * Возвращает отсортированный по убыванию массив направлений
   */
  private getSortedDirections = () => {
    const directionsClone = Array.from(this.props.directions);

    return directionsClone.sort((a, b) => {
      return b.highways.length - a.highways.length;
    });
  };

  private getColumns() {
    const directions = this.getSortedDirections();
    const columnSize = this.getColumnSize();
    const columns: IDirection[][] = [];

    directions.forEach(direction => {
      const index = columns.findIndex(column => {
        const currentColumnSize = this.getDirectionsSize(column);

        return currentColumnSize + direction.highways.length <= columnSize;
      });

      if (index >= 0) {
        columns[index].push(direction);
      } else {
        columns.push([direction]);
      }
    });

    return columns;
  }
}
