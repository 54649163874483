import { IQuestionField, useFormField } from '@cian/frontend-request-form-widget';

import * as React from 'react';
import { useSelector } from 'react-redux';

import { TBSCentersSuggestionShort } from 'shared/api/geosuggest/bs_centers';
import { IAppState } from 'shared/common/state';
import { getBoundedBy } from 'shared/filters/components/geo_suggest/container';
import { getSuburbanOfferFilterValue } from 'shared/filters/components/geo_suggest/helpers';
import { ICoworkingSuggestShort } from 'shared/filters/state/coworking_id';
import { IGeoObject, IPolygonObject } from 'shared/filters/state/geo';
import { ISelectKPParams } from 'shared/filters/state/kp_id';
import { dealTypeFromJsonQuery, offerTypeFromJsonQuery } from 'shared/utils/category';

import { GeoSuggest } from './GeoSuggest';

export const GeoSuggestContainer: React.FC<IQuestionField> = props => {
  const state = useSelector<IAppState, IAppState>(state => state);
  const { jsonQuery, currentLocation, regions, directionsModal } = state.filters;
  const suburbanOfferFilterValue = jsonQuery.suburban_offer_filter && jsonQuery.suburban_offer_filter.value;

  const mapState = {
    makeRequest: state.makeRequest,
    logger: state.logger,
    dealType: dealTypeFromJsonQuery(jsonQuery),
    offerType: offerTypeFromJsonQuery(jsonQuery),
    suburbanOfferFilter: getSuburbanOfferFilterValue(suburbanOfferFilterValue),
    highwaysData: directionsModal.data,
    currentLocation,
    boundedBy: getBoundedBy(currentLocation, regions),
    httpApi: state.httpApi,
    isCoworkingSearch: false,
    label: props.label,
    placeholder: props.placeholder,
  };

  const {
    field: { value },
    helpers: { setValue },
  } = useFormField<string>({ name: props.name });

  const mapDispatch = {
    onGeoSelected: (value: IGeoObject | IPolygonObject, userInput: string) => {
      setValue(userInput);
    },
    onBSCenterSelected: (bsCenter: TBSCentersSuggestionShort) => {
      setValue(bsCenter.name);
    },
    onKPSelected: (params: ISelectKPParams) => {
      setValue(params.name);
    },
    onCoworkingSelected: (coworkingSuggested: ICoworkingSuggestShort) => {
      setValue(coworkingSuggested.title);
    },
    onReset: () => {
      setValue('');
    },
  };

  return <GeoSuggest {...mapState} {...mapDispatch} initialSelectedValue={value} />;
};
