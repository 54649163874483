import { isSuburban, offerTypeFromJsonQuery } from '../../../../utils/category';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IKpIdTypeTagType = 'kp-id';

export interface IKpIdTag {
  type: 'kp-id';
  value: number;
  text?: string;
}

export const isKpIdTag = (tag: TTag): tag is IKpIdTag => tag.type === IKpIdTypeTagType;

export const kpIdTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.kp_id && jsonQuery.kp_id.value;
    const offerType = offerTypeFromJsonQuery(jsonQuery);

    if (value !== undefined && isSuburban(offerType)) {
      return ensureSingleTagExists(tags, isKpIdTag, () => ({ type: IKpIdTypeTagType, value }));
    } else {
      return ensureTagNotExists(tags, isKpIdTag);
    }
  },
};
