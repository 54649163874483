import { FC, useMemo } from 'react';

import { BestPlaceAnalyticsBadgeContainer } from 'shared/containers/BestPlaceAnalyticsBadgeContainer';
import { useCardOfferContext } from 'shared/context/CardContext';
import { ElectronicTradingBagdes } from 'shared/packages/search-card-fragment/components/ElectronicTradingBagdes';
import { OwnerBadge } from 'shared/packages/search-card-fragment/components/OwnerBadge';
import { CommercialOwner } from 'shared/packages/search-card-fragment/utils/CommercialOwner';
import { getOfferHasBestPlaceAnalytics } from 'shared/packages/search-card-fragment/utils/getOfferHasBestPlaceAnalytics';
import { useApplicationContext } from 'shared/serp/containers/ApplicationContext';

import { GoodPriceBadge } from '../GoodPriceBadge';
import { UniqueForCianBadge } from '../UniqueForCianBadge';
import { WrapElementsSpacer } from '../WrapElementsSpacer';

export const PublishersBadges: FC = () => {
  const offer = useCardOfferContext();
  const { config } = useApplicationContext();
  const { hasFairPrice, isUniqueForCian, user } = offer;

  const textsMap = config.get<Record<string, string>>('sellingTypes.agentLists');

  const electronicTradesTexts = useMemo(() => {
    if (!textsMap || !user?.agentLists) {
      return [];
    }

    return user.agentLists.map(agentList => textsMap?.[agentList]).filter(Boolean) as string[];
  }, [user?.agentLists, textsMap]);

  const commercialOwner = CommercialOwner.getInstance(offer);

  const isOfferHasBestPlaceAnalytics = getOfferHasBestPlaceAnalytics(offer);

  return (
    <WrapElementsSpacer horizontalSpace={8} verticalSpace={8}>
      {isUniqueForCian && <UniqueForCianBadge />}
      {isOfferHasBestPlaceAnalytics && <BestPlaceAnalyticsBadgeContainer />}
      {commercialOwner.showOwner && <OwnerBadge />}
      {
        /**
         * @todo Удалить строку с экспериментом
         * @description Данный функционал появился в задаче CD-128337, будет удалён в задаче CD-128347
         * Если эксперимент будет удачным, то оставить строку, но удалить комментарий,
         * если эксперимент будет не удачным, то удалить полностью строку
         */
        !!hasFairPrice && <GoodPriceBadge />
      }
      {<ElectronicTradingBagdes texts={electronicTradesTexts} />}
    </WrapElementsSpacer>
  );
};
