import { Button, IconHeartOff16, IconHeartOn16, UIText2 } from '@cian/ui-kit';
import { IStyleProps } from '@cian/utils';

import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { Component } from 'react';

import { IAbUseExperiment } from 'shared/types/abUseExperiments';
import { TUser } from 'shared/types/user';

import * as style from './index.css';
export interface ILikeButtonProps {
  isActive: boolean;

  buttonClassName?: string;
  buttonStyle?: IStyleProps;

  toggleActivity(value: boolean): void;
}

export interface ILikeButtonContext {
  user: TUser;
  api: {
    favoritesLimitForUnauthUser?: number;
  };
  abUseExperiments: IAbUseExperiment[];
}

export interface ILikeButtonState {
  isActive: boolean;
}

export class LikeButton extends Component<ILikeButtonProps, ILikeButtonState> {
  public context: ILikeButtonContext;

  public static contextTypes = {
    api: PropTypes.object,
    user: PropTypes.object,
    abUseExperiments: PropTypes.arrayOf(
      PropTypes.shape({ experimentName: PropTypes.string, string: PropTypes.string }),
    ),
  };

  public constructor(props: ILikeButtonProps) {
    super(props);

    this.state = {
      isActive: props.isActive,
    };
  }

  public render() {
    const { buttonClassName, buttonStyle } = this.props;
    const { isActive } = this.state;

    return (
      <Button
        beforeIcon={
          <span className={classNames(style['button'], buttonClassName, !isActive && buttonStyle)}>
            {isActive ? <IconHeartOn16 color="error_100" /> : <IconHeartOff16 color="gray_icons_100" />}
            <UIText2 color="black_100" fontWeight="700">
              {isActive ? 'В избранном' : 'В избранное'}
            </UIText2>
          </span>
        }
        size="XS"
        theme="stroke_secondary"
        onClick={this.handleClick}
      />
    );
  }

  protected handleClick = () => {
    this.setState({
      isActive: !this.state.isActive,
    });

    this.props.toggleActivity(!this.state.isActive);
  };
}
