import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { AdFoxTemplates } from '@cian/adfox-templates';

import { Component } from 'react';

import { trackAdFoxMortgage } from '../../../../tracking/adFoxMortgage';
import { sendAdsEvent } from '../../../../tracking/sendAdsEvent';
import { IWithTrackingProps } from '../../../hoc/withTracking';
import { isMortgageCampaign } from '../utils/mortgage';

export type TAdFoxBannerTrackerProps = IWithTrackingProps & TAdFoxBannerTemplate;

export class AdFoxBannerTracker extends Component<TAdFoxBannerTrackerProps> {
  public trackOnMountSent = false;

  public componentDidUpdate(): void {
    const { bundleParams } = this.props;

    if (!this.trackOnMountSent && bundleParams && bundleParams.campaignId) {
      // Отправляем статистику по показу не на componentDidMount, потому что
      // фактически баннер подгружается лишь через какое-то время.
      this.track(true);
      this.trackOnMountSent = true;
    }
  }

  public render() {
    return (
      <div onClick={this.onClick}>
        <AdFoxTemplates {...this.props} />
      </div>
    );
  }

  /**
   * Отправляет аналитику в SOPR
   * @param isShow
   */
  public track = (isShow?: boolean) => {
    const { tracking, bundleParams, shouldSendAdsEvent } = this.props;

    if (!bundleParams) {
      return;
    }

    if (isMortgageCampaign(bundleParams.campaignId)) {
      trackAdFoxMortgage(
        isShow ? 'show' : 'click',
        'BannerIpoteka',
        bundleParams.bannerId,
        tracking.user,
        tracking.page,
      );
    } else if (shouldSendAdsEvent) {
      sendAdsEvent({
        realtyUserId: tracking.user.userId,
        action: isShow ? 'show' : 'click',
        bannerId: bundleParams.bannerId,
        bannerPlace: 'offersSerp',
      });
    }
  };

  private onClick = () => {
    this.track(false);
  };
}
