import { Header2 } from '@cian/ui-kit';

import { ILink } from 'shared/offer';

import * as styles from './SeoBotCatcherLinks.css';

interface ISeoBotCatcherLinksProps {
  title: string;
  links: ILink[];
}

export function SeoBotCatcherLinks({ title, links }: ISeoBotCatcherLinksProps) {
  return (
    <div className={styles['container']}>
      <Header2>{title}</Header2>

      <div className={styles['links']}>
        {links.map(item => (
          <a className={styles['link']} href={item.url} key={item.url} title={item.title}>
            {item.title}
          </a>
        ))}
      </div>
    </div>
  );
}
