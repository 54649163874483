export function getCoords(elem: HTMLElement): { top: number; left: number } {
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = box.top + scrollTop - clientTop;
  const left = box.left + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}

export function isPositionStickySupported(): boolean {
  const style = document.createElement('test').style;
  style.cssText = ['-webkit-', '-ms-', ''].map(prefix => `position: ${prefix}sticky`).join(';');

  return Boolean(style.position && style.position.includes('sticky'));
}

export function getValueFromComputedStyle(computedStyle: CSSStyleDeclaration, key: keyof CSSStyleDeclaration): number {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return Math.round(parseFloat(computedStyle[key as any]) || 0);
}

export function checkTouchDevice() {
  return 'ontouchstart' in window;
}
