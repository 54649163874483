import { getConfig } from '@cian/config/browser';
import { getLogger } from '@cian/logger/browser';

export function getTeaserApiUrl() {
  const config = getConfig();
  const logger = getLogger();

  const soprExperimentsForTeaserUrl = config.get<string>('soprExperimentsForTeaserUrl');

  if (!soprExperimentsForTeaserUrl) {
    logger.error('soprExperimentsForTeaserUrl is not defined', {
      domain: 'getTeaserApiUrl',
    });

    return null;
  }

  return `${soprExperimentsForTeaserUrl}teaser/`;
}
