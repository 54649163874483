import { getConfig } from '@cian/config/browser';
import { getLogger } from '@cian/logger/browser';
import { ISubscriberEvent, sendBeacon, userInteractiveTime } from '@cian/user-interactive-time/browser';
import { parseCookies } from '@cian/utils';

import { Store } from 'redux';

import { IAppState } from 'shared/common/state';
import { mlSearchSession } from 'shared/mlSearchSession';

interface IDependencies {
  store: Store<IAppState>;
}

export function subscribeToInteractiveTime(dependencies: IDependencies) {
  const config = getConfig();
  const logger = getLogger();

  try {
    const schedulerInterval = config.getStrict<number>('userInteractiveTime.schedulerInterval');
    const inactiveTime = config.getStrict<number>('userInteractiveTime.inactiveTime');
    const debug = config.get<boolean>('userInteractiveTime.debug.enabled') || false;
    const userInteractiveTimeEnabled = config.get<boolean>('userInteractiveTime.enabled');

    if (!userInteractiveTimeEnabled) {
      return;
    }

    userInteractiveTime.subscribe({
      schedulerInterval,
      inactiveTime,
      debug,
      subscriber: createSubscriber(dependencies),
    });
  } catch (error) {
    logger.error(error, { domain: 'services#subscribeToInteractiveTime()' });
  }
}

export function createSubscriber(dependencies: IDependencies) {
  return async ({ interactiveTime, isUserInitiator }: ISubscriberEvent) => {
    const { store } = dependencies;
    const config = getConfig();
    const logger = getLogger();
    const { results, user } = store.getState();

    try {
      const cid = parseCookies(document.cookie)['_CIAN_GK'];
      const userId = user.isAuthenticated ? user.userId : null;
      const mlSearchSessionGuid = mlSearchSession.getSessionGuid(results.jsonQuery);
      const soprUrl = config.getStrict<string>('soprExperiments.browserUrl');

      const parameters = {
        cid,
        user_id: userId,
        t: interactiveTime,
        ml_search_session_guid: mlSearchSessionGuid,
      };

      sendBeacon(`${soprUrl}listing-user-activity-time/`, JSON.stringify(parameters), isUserInitiator);
    } catch (error) {
      logger.error(error, { domain: 'services#createSubscriber()' });
    }
  };
}
