import { mergeStyles } from '@cian/utils';

import { IOfferExtensionLabel } from 'shared/offer';

const styles = require('./index.css');

export interface IHiddenBaseLabelProps {
  labels?: IOfferExtensionLabel[];
  labelStyle?: string;
}

export const ExtendedOfferLabel = (props: IHiddenBaseLabelProps) => {
  const { labels } = props;

  return labels && labels.length ? (
    <div {...mergeStyles(props.labelStyle, styles['label'])}>
      {labels.map(({ value }, index) => (
        <span key={`label-${index}`}>{value}</span>
      ))}
    </div>
  ) : null;
};
