import { mergeStyles } from '@cian/utils';

import { equals } from 'ramda';
import { PureComponent } from 'react';
import { createPortal } from 'react-dom';

import { CloseIcon } from './icons';

import * as styles from './index.css';

export interface ITopPopupOwnProps {
  children: string | JSX.Element;
  isError?: boolean;
}

export interface ITopPopupDispatchProps {
  toggleTopPopup(isOpened: boolean): () => void;
}

type ITopPopupProps = ITopPopupOwnProps & ITopPopupDispatchProps;

export class TopPopup extends PureComponent<ITopPopupProps, {}> {
  private timeoutId: number;

  public componentDidMount() {
    this.timeoutId = window.setTimeout(this.props.toggleTopPopup(false), 4000);
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ITopPopupProps) {
    if (!equals(this.props.children, nextProps.children)) {
      clearTimeout(this.timeoutId);
      this.timeoutId = window.setTimeout(this.props.toggleTopPopup(false), 4000);
    }
  }

  public componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  public render() {
    const { isError, toggleTopPopup } = this.props;

    return createPortal(
      <div {...mergeStyles(styles['top-popup'], isError && styles['error'])}>
        <div className={styles['close-outer']} onClick={toggleTopPopup(false)}>
          <CloseIcon />
        </div>
        <span>{this.props.children}</span>
      </div>,
      document.body,
    );
  }
}
