import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const TAG_TYPE = 'roomArea';

export interface IRoomAreaTag {
  type: 'roomArea';
  min: number | undefined;
  max: number | undefined;
}

function isRoomAreaTag(tag: TTag): tag is IRoomAreaTag {
  return tag.type === TAG_TYPE;
}

export const roomAreaTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.room_area && jsonQuery.room_area.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isRoomAreaTag, () => ({ type: TAG_TYPE, min: value.gte, max: value.lte }));
    } else {
      return ensureTagNotExists(tags, isRoomAreaTag);
    }
  },
};
