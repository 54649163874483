import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

/**
 * identicalOffersId - id группы дублирующих объявлений.
 * Приходит с бека в jsonQuery.identical_id
 */
export function identicalOffersIdReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'identicalOffersId') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.setIdenticalId(undefined),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
