/* eslint-disable max-lines */

// Файл сложно отрефакторить. Всё нужно.
import { getTelemetry } from '@cian/telemetry/browser';

import { compose } from 'ramda';
import { call, put, select, take } from 'redux-saga/effects';

import { IGetVillageForMapResponse as IVillage } from 'shared/types/village';

import { IBSCenterInfo, getBSCenterInfo } from '../../api/bs_centers';
import { IDeveloperInfo, getDeveloperInfo } from '../../api/developers';
import { INewbuildingInfo, getNewbuildingInfo } from '../../api/newbuildings';
import { getQuickLinks } from '../../api/quick_links';
import { IAppState, IQuickLinks, IUserGADataLayerData, TActions } from '../../common/state';
import { IJsonQuery, IJsonQueryDeveloper, IJsonQueryNewbuilding } from '../../json_query';
import { defaultHideOfferState } from '../../serp/state/hide_offer';
import { fetchOffers } from '../../services/fetchOffers';
import { IOffersData } from '../../types/offersData';
import { cutDescriptionFromOffers } from '../../utils';
import { triggerHeaderCategoryChanged, triggerHeaderRegionChanged } from '../../utils/events';
import { getRegionIds } from '../../utils/geo';
import { getRandomBoolean } from '../../utils/getRandomBoolean';
import { saveHistorySearch } from '../../utils/history_search';
import { offersDecorator } from '../../utils/offersDecorator';
import { getTrackingLocation } from '../../utils/trackings';
import {
  ISimilarNewobjectsData,
  ISimilarNewobjectsFromDeveloperData,
  getSimilarNewobjectsFromDeveloperPromise,
  getSimilarNewobjectsPromise,
  timeoutRequestUserGADataLayer,
} from '../api';
import {
  getBSCenterId,
  getSortReplaceCoworkingOfficeType,
  isDeveloperPage,
  isNewobjectPage,
  jsonQueryReadyBusinessFilter,
} from '../helpers';
import { trackPageView } from '../tracking';

export interface ISearchRequestStartRequestedAction {
  type: 'filters/search/SEARCH_REQUEST_START_REQUESTED';
}

export interface ISearchRequestFetchingAction {
  type: 'filters/search/SEARCH_REQUEST_FETCHING';
}

export interface ISearchSaveTooltipHidderAction {
  type: 'filters/save_tooltip/TOOLTIP_HIDDEN';
}

export interface ISearchRequestFinishedAction {
  type: 'filters/search/SEARCH_REQUEST_FINISHED';
  data: IOffersData;
  newbuildingInfo: INewbuildingInfo | null;
  bsCenterInfo: IBSCenterInfo | null;
  developerInfo: IDeveloperInfo | null;
  villageInfo: IVillage | null;
  similarNewobjects: ISimilarNewobjectsData[] | null;
  similarNewobjectsFromDeveloper: ISimilarNewobjectsFromDeveloperData[] | null;
  quickLinks: IQuickLinks | null;
  userGALayerData: IUserGADataLayerData;
  // Идентификатор поискового запроса
  searchGuid: string | null;
}

export const FILTERS_SEARCH_SEARCH_REQUEST_START_REQUESTED = 'filters/search/SEARCH_REQUEST_START_REQUESTED';
export const FILTERS_SEARCH_SEARCH_REQUEST_FETCHING = 'filters/search/SEARCH_REQUEST_FETCHING';
export const FILTERS_SEARCH_SEARCH_REQUEST_FINISHED = 'filters/search/SEARCH_REQUEST_FINISHED';

export function makeSearch(): ISearchRequestStartRequestedAction {
  return {
    type: FILTERS_SEARCH_SEARCH_REQUEST_START_REQUESTED,
  };
}

export function setLoading(): ISearchRequestFetchingAction {
  return {
    type: FILTERS_SEARCH_SEARCH_REQUEST_FETCHING,
  };
}

let prevRegionsValue: number[] = [];

/* istanbul ignore next */
export function* searchSaga() {
  while (true) {
    yield take(FILTERS_SEARCH_SEARCH_REQUEST_START_REQUESTED);

    let state: IAppState = yield select();

    if (state.filters.jsonQueryCountRefresing && !state.commercialFiltersMarkup) {
      // Block search while meta is loading
      yield take(['filters/meta/META_REFRESH_COMPLETE', 'filters/meta/META_REFRESH_FAILED']);
    }

    state = yield select();
    const {
      httpApi,
      makeRequest,
      filters: { jsonQuery, jsonQueryFullUrl },
      config,
      logger,
    } = state;

    if (
      jsonQueryFullUrl &&
      config.get('redirectToOldFilters') === 'enabled' &&
      isRedirectNeeded(jsonQuery, jsonQueryFullUrl)
    ) {
      window.location.assign(jsonQueryFullUrl);

      return;
    }

    const isAdditionalTop3Disabled = config.get('serp.isAdditionalTop3Enabled') !== 'true';

    const isRegionChanged = (value: number[]) => {
      let isChanged = false;

      if (prevRegionsValue.length === value.length) {
        const prev = prevRegionsValue.sort();
        const next = value.sort();

        prev.forEach((n, i) => {
          if (n !== next[i]) {
            isChanged = true;
          }
        });
      } else {
        isChanged = true;
      }

      prevRegionsValue = value;

      return isChanged;
    };

    if (window.__reloadHeader__ && jsonQuery.region && isRegionChanged(jsonQuery.region.value)) {
      window.__reloadHeader__();
    }

    const nextJsonQuery = compose(getSortReplaceCoworkingOfficeType, jsonQueryReadyBusinessFilter)(jsonQuery);

    const data: IOffersData = yield call(
      fetchOffers,
      { httpApi, logger },
      {
        jsonQuery: nextJsonQuery,
      },
    );

    /**
     * Сохранение текущего поиска для истории поисков
     */
    saveHistorySearch(data.seoData.h1, data.fullUrl, data.jsonQuery);

    /*
      searchUuid - сохраненный id текущего поиска из стора
      data.searchUuid - новый id нового поиска (но также может сгенериться на старый поиск)

      При изменении фильтров сбрасывается id в стор, значит смотрим на новый id с бека

      Если вообще никаких данных нет по каким-то причинам, то можем отправлять поле пустым
    */
    const searchGuid = data.searchUuid;

    const suggestions = data.suggestOffersSerializedList;

    data.offersSerialized = cutDescriptionFromOffers(data.offersSerialized, state.userAgent);

    if (suggestions) {
      data.suggestOffersSerializedList = cutDescriptionFromOffers(suggestions, state.userAgent);
    }

    // Обработка объявлений
    offersDecorator(data.offersSerialized, isAdditionalTop3Disabled);

    let newbuildingInfo: INewbuildingInfo | null = null;
    if (data.jsonQuery.geo && isNewobjectPage(data.jsonQuery)) {
      newbuildingInfo = yield call(
        getNewbuildingInfo,
        makeRequest,
        (data.jsonQuery.geo.value[0] as IJsonQueryNewbuilding).id,
      );
    }

    const villageInfo: IVillage | null = null;

    let bsCenterInfo: IBSCenterInfo | null = null;
    const bsCenterId = getBSCenterId(data.jsonQuery);
    if (bsCenterId) {
      bsCenterInfo = yield call(getBSCenterInfo, makeRequest, bsCenterId);
    }

    let developerInfo: IDeveloperInfo | null = null;
    if (data.jsonQuery.geo && isDeveloperPage(data.jsonQuery)) {
      developerInfo = yield call(
        getDeveloperInfo,
        makeRequest,
        (data.jsonQuery.geo.value[0] as IJsonQueryDeveloper).id,
      );
    }

    const similarNewobjects: ISimilarNewobjectsData | null = yield call(getSimilarNewobjectsPromise, makeRequest, data);
    const similarNewobjectsFromDeveloper: ISimilarNewobjectsFromDeveloperData | null = yield call(
      getSimilarNewobjectsFromDeveloperPromise,
      makeRequest,
      data,
    );
    const quickLinks: IQuickLinks | null = yield call(getQuickLinks, makeRequest, data.jsonQuery);
    const telemetry = getTelemetry();
    const timeoutRequestUserGADataLayerParams = {
      makeRequest,
      telemetry,
      logger,
    };

    let userGALayerData = state.userGADataLayerData;
    if (!userGALayerData) {
      userGALayerData = yield call(timeoutRequestUserGADataLayer, timeoutRequestUserGADataLayerParams);
    }

    state = yield select();

    const {
      filters: { jsonQueryUrl },
    } = state;

    document.title = data.seoData.title || document.title;

    window.history.pushState({}, document.title, jsonQueryUrl);

    const trackingLocation = getTrackingLocation(state.filters.currentLocation, state.filters.regions);

    yield put({
      type: FILTERS_SEARCH_SEARCH_REQUEST_FINISHED,
      data,
      newbuildingInfo,
      bsCenterInfo,
      developerInfo,
      villageInfo,
      similarNewobjects,
      similarNewobjectsFromDeveloper,
      quickLinks,
      userGALayerData,
      searchGuid,
    });

    state = yield select();

    trackPageView(
      {
        filters: state.filters,
        results: state.results,
        breadcrumbs: data.breadcrumbs,
        user: state.user,
        userGALayerData,
        profileSessionKey: state.profileSessionKey,
        fbRegion: trackingLocation.fbRegion,
        fbCity: trackingLocation.fbCity,
        searchGuid,
        mlRankingGuid: data.mlRankingGuid,
        mlRankingModelVersion: data.mlRankingModelVersion,
        searchRequestId: data.searchRequestId,
        extensions: data.extensionTypes || undefined,
      },
      { config },
    );
  }
}

export function searchReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case FILTERS_SEARCH_SEARCH_REQUEST_START_REQUESTED:
    case FILTERS_SEARCH_SEARCH_REQUEST_FETCHING:
      return {
        ...state,
        isFetching: true,
      };

    case FILTERS_SEARCH_SEARCH_REQUEST_FINISHED:
      triggerHeaderCategoryChanged(action.data.jsonQuery);
      triggerHeaderRegionChanged(state.filters.currentLocation);

      return {
        ...state,
        results: {
          ...state.results,
          avgPriceInformer: action.data.avgPriceInformer,
          queryString: action.data.queryString,
          quickLinks: action.quickLinks,
          jsonQuery: {
            ...action.data.jsonQuery,
            region: action.data.jsonQuery.region || {
              type: 'terms',
              value: getRegionIds(state.filters.currentLocation),
            },
          },
          aggregatedOffers: action.data.aggregatedCount,
          extendedOffersCount: action.data.extendedOffersCount,
          bsCenterInfo: action.bsCenterInfo,
          developerInfo: action.developerInfo,
          fastLinks: action.data.topHitsLinks,
          fullUrl: action.data.fullUrl,
          newbuildingInfo: action.newbuildingInfo,
          offers: action.data.offersSerialized,
          seo: action.data.seoData,
          seoLinks: action.data.seoLinks,
          totalOffers: action.data.offerCount,
          suggestions: action.data.suggestOffersSerializedList,
          suggestionsQuery: action.data.suggestionsQuery,
          qsToUris: action.data.qsToUris,
          villageInfo: action.villageInfo,
          kp: action.data.kp,
          extensionTypes: action.data.extensionTypes || [],
        },
        similarNewobjects: action.similarNewobjects,
        breadcrumbs: action.data.breadcrumbs,
        isFetching: false,
        userGADataLayerData: action.userGALayerData,
        hideOffer: defaultHideOfferState,
        /*
          Если пришел новый uuid, значит пишем новый и считаем, что начался новый поиск
        */
        searchUuid: action.searchGuid,
        mlRankingGuid: action.data.mlRankingGuid,
        mlRankingModelVersion: action.data.mlRankingModelVersion,
        newbuildingAdPath: action.data.newbuildingAdPath,
        newbuildingIdsForBuildersPromoSlider: action.data.newbuildingIdsForBuildersPromoSlider,
        isNewbuildingsOnly: (action.data.jsonQuery.building_status || { value: undefined }).value === 2,
        collections: action.data.collections,
        newbuildingSimilarOffersBlock: action.data.newbuildingSimilarOffersBlock,
        isNewbuildingBannerRandomRotationEnabled: getRandomBoolean(),
        newbuildingInformation: action.data.newbuilding || null,
      };
    default:
      return state;
  }
}

export function isRedirectNeeded(jsonQuery: IJsonQuery, fullUrl?: string): boolean {
  let queryHasMapObjects = false;

  if (!fullUrl) {
    return false;
  }

  if (jsonQuery.geo && jsonQuery.geo.value) {
    queryHasMapObjects = jsonQuery.geo.value.some(geoObject => {
      return geoObject.type === 'polygon' || geoObject.type === 'distance';
    });
  }

  if (!queryHasMapObjects) {
    const parser = document.createElement('a');
    parser.href = fullUrl;

    return parser.hostname !== window.location.hostname;
  }

  return false;
}
