import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IHasExtinguishingSystemTagType = 'advanced-has-extinguishing-system';

export interface IHasExtinguishingSystemTag {
  type: 'advanced-has-extinguishing-system';
}

function isHasExtinguishingSystemTag(tag: TTag): tag is IHasExtinguishingSystemTag {
  return tag.type === IHasExtinguishingSystemTagType;
}

export const hasExtinguishingSystemTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_extinguishing_system && jsonQuery.has_extinguishing_system.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isHasExtinguishingSystemTag, () => ({
        type: IHasExtinguishingSystemTagType,
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isHasExtinguishingSystemTag);
    }
  },
};
