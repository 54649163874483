import { ca } from '@cian/analytics';

/**
 * Требования для аналитики:
 * https://conf.cian.tech/pages/viewpage.action?pageId=1255738378
 */
export function trackSwipeCollection() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'swipe_collections',
  });
}
