import { InlineText2 } from '@cian/ui-kit/typography';
import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import { CheckboxSuccess12 } from '../CheckboxSuccess12';

const styles = require('./Amenity.css');

interface IAmenity {
  children: React.ReactNode;
  className?: string;
}

export function Amenity({ children, className }: IAmenity) {
  return (
    <div {...mergeStyles(styles['root'], className)}>
      <CheckboxSuccess12 />{' '}
      <span className={styles['title']}>
        <InlineText2>{children}</InlineText2>
      </span>
    </div>
  );
}
