import * as React from 'react';
import { useSelector } from 'react-redux';

import { BestPlaceAnalyticsBadge } from 'shared/components/BestPlaceAnalyticsBadge';
import { useCardOfferContext } from 'shared/context/CardContext';
import { offerHelper } from 'shared/offer';
import {
  breadcrumbsSelector,
  filtersSelector,
  resultsSelector,
  userGADataLayerDataSelector,
} from 'shared/selectors/commonSelectors';
import { prepareTrackingData } from 'shared/utils/prepareTrackingData';

import { ITrackBadgeClickOptions, makeTrackBadgeClick } from './tracking';

export const BestPlaceAnalyticsBadgeContainer: React.VFC = () => {
  const offer = useCardOfferContext();

  const results = useSelector(resultsSelector);
  const filters = useSelector(filtersSelector);
  const breadcrumbs = useSelector(breadcrumbsSelector);
  const userGADataLayerData = useSelector(userGADataLayerDataSelector);

  const trackingOptions = React.useMemo<ITrackBadgeClickOptions>(() => {
    const { id } = offerHelper(offer).getAnalyticsInfo();
    const { page, user } = prepareTrackingData(filters, results, breadcrumbs, userGADataLayerData);

    return {
      products: [{ id }],
      page: {
        listingType: 'list',
        pageNumber: page.pageNumber,
        pageType: 'Listing',
      },
      user: {
        userId: user.userId,
      },
    };
  }, [offer, breadcrumbs, filters, results, userGADataLayerData]);

  const trackBadgeClick = React.useCallback(() => {
    makeTrackBadgeClick(trackingOptions);
  }, [trackingOptions]);

  return <BestPlaceAnalyticsBadge fullUrl={`${offer.fullUrl}?with_best_place=1`} onBadgeClick={trackBadgeClick} />;
};

BestPlaceAnalyticsBadgeContainer.displayName = 'BestPlaceAnalyticsBadgeContainer';
