import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const INewbuildingCompletedTagType = 'advanced-doneYear-isDone';

export interface INewbuildingCompletedTag {
  type: 'advanced-doneYear-isDone';
  value: boolean;
}

function isBuildingDoneTag(tag: TTag): tag is INewbuildingCompletedTag {
  return tag.type === INewbuildingCompletedTagType;
}

export const newbuildingCompletedTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.hand_over && jsonQuery.hand_over.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isBuildingDoneTag, () => ({
        type: INewbuildingCompletedTagType,
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isBuildingDoneTag);
    }
  },
};
