import { ca } from '@cian/analytics';

import { ICommercialTrackingProduct, ITrackingData } from 'shared/common/state';
import { ITrackingPageData, TListingType } from 'shared/serp/state/tracking';

export interface ITrackBadgeClickOptions {
  products: [{ id: ICommercialTrackingProduct['id'] }];
  page: {
    listingType: TListingType;
    pageNumber: ITrackingData['page']['pageNumber'];
    pageType: ITrackingPageData['pageType'];
  };
  user: {
    userId: ITrackingData['user']['userId'];
  };
}

export const makeTrackBadgeClick = (options: ITrackBadgeClickOptions) => {
  const params = {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_buy_report',
    label: 'geoanalytics_popup',
    ...options,
  };

  ca('eventSite', params);
};
