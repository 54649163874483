import { EConditionType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBuildingConditionsTagType = 'advanced-buildingCondition';

export interface IBuildingConditionsTag {
  type: 'advanced-buildingCondition';
  value: EConditionType;
}

const isBuildingConditionsTag = (tag: TTag): tag is IBuildingConditionsTag => tag.type === IBuildingConditionsTagType;

export const buildingConditionTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.condition_type__industry && jsonQuery.condition_type__industry.value;

    let nextTags = tags.filter(
      tag => tag.type !== IBuildingConditionsTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EConditionType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IBuildingConditionsTag => isBuildingConditionsTag(tag) && tag.value === value,
            () => ({ type: IBuildingConditionsTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
