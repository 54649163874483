import { InlineText3, Tooltip } from '@cian/ui-kit';

import { forwardRef } from 'react';

import { TooltipContent } from '../TooltipContent';

import { CoworkingProfitLabelViewProps } from './types';

import * as styles from './CoworkingProfitLabelView.css';

export const CoworkingProfitLabelView = forwardRef<HTMLButtonElement, CoworkingProfitLabelViewProps>((props, ref) => {
  const { onClose, onOpen, open } = props;

  return (
    <Tooltip open={open} placement="bottom" theme="white" title={<TooltipContent />} onClose={onClose} onOpen={onOpen}>
      <button className={styles['button']} data-testid="profit-button" ref={ref}>
        <InlineText3 color="white_100">Почему это выгодно?</InlineText3>
      </button>
    </Tooltip>
  );
});

CoworkingProfitLabelView.displayName = 'CoworkingProfitLabelView';
