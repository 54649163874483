/* eslint-disable prefer-const */
/* Stack-based Douglas Peucker line simplification routine
   returned is a reduced GLatLng array
   After code by  Dr. Gary J. Robinson,
   Environmental Systems Science Centre,
   University of Reading, Reading, UK
*/

/*
 * Для работы с координатами Яндекс.Карт source.lng() изменено на source.getX(),
 * а source.lat() изменено на source.getY()
 */
const F = (Math.PI / 180.0) * 0.5;

export const approximatePath = (source: [number, number][], kink: number) => {
  /* source[] Input coordinates in GLatLngs 	*/
  /* kink	in metres, kinks above this depth kept  */
  /* kink depth is the height of the triangle abc where a-b and b-c are two consecutive line segments */

  let nSource;
  let nStack;
  let nDest;
  let start;
  let end;
  let i;
  let sig;
  let devSqr;
  let maxDevSqr;
  let bandSqr;
  let x12;
  let y12;
  let d12;
  let x13;
  let y13;
  let d13;
  let x23;
  let y23;
  let d23;

  const index = []; /* aray of indexes of source points to include in the reduced line */
  const sigStart = []; /* indices of start & end of working section */
  const sigEnd = [];

  /* check for simple cases */

  if (source.length < 3) {
    return source; /* one or two points */
  }

  /* more complex case. initialize stack */

  nSource = source.length;
  bandSqr = (kink * 360.0) / (2.0 * Math.PI * 6378137.0); /* Now in degrees */
  bandSqr *= bandSqr;
  nDest = 0;
  sigStart[0] = 0;
  sigEnd[0] = nSource - 1;
  nStack = 1;

  /* while the stack is not empty  ... */
  while (nStack > 0) {
    /* ... pop the top-most entries off the stacks */
    start = sigStart[nStack - 1];
    end = sigEnd[nStack - 1];
    nStack--;

    if (end - start > 1) {
      /* any intermediate points ? */

      /* ... yes, so find most deviant intermediate point to
              either side of line joining start & end points */

      x12 = source[end][0] - source[start][0];
      y12 = source[end][1] - source[start][1];

      if (Math.abs(x12) > 180.0) {
        x12 = 360.0 - Math.abs(x12);
      }

      x12 *= Math.cos(F * (source[end][1] + source[start][1])); /* use avg lat to reduce lng */
      d12 = x12 * x12 + y12 * y12;

      for (i = start + 1, sig = start, maxDevSqr = -1.0; i < end; i++) {
        x13 = source[i][0] - source[start][0];
        y13 = source[i][1] - source[start][1];

        if (Math.abs(x13) > 180.0) {
          x13 = 360.0 - Math.abs(x13);
        }

        x13 *= Math.cos(F * (source[i][1] + source[start][1]));
        d13 = x13 * x13 + y13 * y13;

        x23 = source[i][0] - source[end][0];
        y23 = source[i][1] - source[end][1];

        if (Math.abs(x23) > 180.0) {
          x23 = 360.0 - Math.abs(x23);
        }

        x23 *= Math.cos(F * (source[i][1] + source[end][1]));
        d23 = x23 * x23 + y23 * y23;

        if (d13 >= d12 + d23) {
          devSqr = d23;
        } else if (d23 >= d12 + d13) {
          devSqr = d13;
        } else {
          devSqr = ((x13 * y12 - y13 * x12) * (x13 * y12 - y13 * x12)) / d12; // solve triangle
        }

        if (devSqr > maxDevSqr) {
          sig = i;
          maxDevSqr = devSqr;
        }
      }

      if (maxDevSqr < bandSqr) {
        /* is there a sig. intermediate point ? */
        /* ... no, so transfer current start point */
        index[nDest] = start;
        nDest++;
      } else {
        /* ... yes, so push two sub-sections on stack for further processing */
        nStack++;
        sigStart[nStack - 1] = sig;
        sigEnd[nStack - 1] = end;
        nStack++;
        sigStart[nStack - 1] = start;
        sigEnd[nStack - 1] = sig;
      }
    } else {
      /* ... no intermediate points, so transfer current start point */
      index[nDest] = start;
      nDest++;
    }
  }

  /* transfer last point */
  index[nDest] = nSource - 1;
  nDest++;

  /* make return array */
  const r = [];

  for (let j = 0; j < nDest; j++) {
    r.push(source[index[j]]);
  }

  return r;
};
