import { EEntranceType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IEntranceTypesTagType = 'advanced-entrance';

export interface IEntranceTypesTag {
  type: 'advanced-entrance';
  value: EEntranceType;
}

const isEntranceTypesTag = (tag: TTag): tag is IEntranceTypesTag => tag.type === IEntranceTypesTagType;

export const entranceTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.input_type && jsonQuery.input_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IEntranceTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EEntranceType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IEntranceTypesTag => isEntranceTypesTag(tag) && tag.value === value,
            () => ({ type: IEntranceTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
