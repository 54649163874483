import { ILabelProps, Label } from '../label';

const style = require('./index.css');

interface IOfferLabelsProps {
  offersList: Array<ILabelProps>;
}

export const OfferLabels = (props: IOfferLabelsProps) => (
  <div className={style['saved-search-labels']}>
    {props.offersList.map(({ text, textColor, backgroundColor }) => (
      <Label backgroundColor={backgroundColor} key={text} text={text} textColor={textColor} />
    ))}
  </div>
);
