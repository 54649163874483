import { Dispatch, IAppState, TActions } from '../../../common/state';

export const DistrictsModalSavedActionType = 'filters/districts/DISTRICT_MODAL_SAVED';

export interface IDistrictsModalSavedAction {
  type: 'filters/districts/DISTRICT_MODAL_SAVED';
}

export function saveDistrictsModal() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: DistrictsModalSavedActionType,
    });

    dispatch({
      type: 'filters/search/SEARCH_REQUEST_START_REQUESTED',
    });
  };
}

export function saveDistrictsModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case DistrictsModalSavedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: undefined,
        },
        isDistrictModalVisible: false,
      };
    default:
      return state;
  }
}
