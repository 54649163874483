import { IOffer } from '../types/offer';

export function getAgentPhone(offer: IOffer) {
  const { user, isDealRequestSubstitutionPhone } = offer;

  if (
    !isDealRequestSubstitutionPhone &&
    user &&
    user.isCallbackUser &&
    Array.isArray(user.phoneNumbers) &&
    user.phoneNumbers.length > 0
  ) {
    const firstPhoneNumber = user.phoneNumbers[0];

    return `${firstPhoneNumber.countryCode}${firstPhoneNumber.number}`;
  }

  return undefined;
}
