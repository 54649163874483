import { FOfferType, offerTypeFromJsonQuery } from '../../../../utils/category';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IHasWaterTagType = 'advanced-has-water';

export interface IHasWaterTag {
  type: 'advanced-has-water';
}

function isHasWaterTag(tag: TTag): tag is IHasWaterTag {
  return tag.type === IHasWaterTagType;
}

export const hasWaterTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const offerType = offerTypeFromJsonQuery(jsonQuery);
    const value = jsonQuery.has_water && jsonQuery.has_water.value;

    if (value && offerType === FOfferType.Garage) {
      return ensureSingleTagExists(tags, isHasWaterTag, () => ({ type: IHasWaterTagType }));
    } else {
      return ensureTagNotExists(tags, isHasWaterTag);
    }
  },
};
