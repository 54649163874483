import { IOffer } from '../offer';
import { IMakeRequest } from '../utils/request';

export function updateOfferComment(
  makeRequest: IMakeRequest,
  offer: IOffer,
  text: string | undefined,
): Promise<{ status: string }> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: `/ajax/${offer.dealType}/${offer.offerType}/notes`,
    parameters: {
      action: text ? 'save' : 'delete',
      text: text || undefined,
      oid: offer.cianId || offer.id,
    },
    headers: [['Content-Type', 'application/x-www-form-urlencoded']],
    bodyAsEncodeString: true,
    method: 'POST',
  }).then((response: { status: string }) => {
    return response;
  });
}

export function updateAgentComment(
  makeRequest: IMakeRequest,
  offer: IOffer,
  text: string | undefined,
): Promise<{ status: string }> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: `/ajax/${offer.dealType}/${offer.offerType}/realtor_notes`,
    parameters: {
      action: text ? 'save' : 'delete',
      text: text || undefined,
      rid: offer.user && offer.user.cianUserId,
    },
    headers: [['Content-Type', 'application/x-www-form-urlencoded']],
    bodyAsEncodeString: true,
    method: 'POST',
  }).then((response: { status: string }) => {
    return response;
  });
}
