import { ca } from '@cian/analytics';

export function trackCountrysideTrapCLick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'banner_listingKP_click',
    label: 'banner_in_listing',
  });
}
