import { mergeStyles, plural } from '@cian/utils';

import { Component } from 'react';

import { getAccountTypeTitle } from './utils';

const style = require('./index.css');

export interface ISimilarProps {
  count: number;
  url: string;
  isTop3?: boolean;
  isColorized?: boolean;
  trackAllDuplicatesClick?(): void;
  agencyName?: string;
  accountType?: string;
}

export class Similar extends Component<ISimilarProps> {
  public render() {
    const { isColorized, isTop3, url } = this.props;

    return (
      <a
        href={url}
        target="_blank"
        onClick={this.trackClickEvent}
        {...mergeStyles(style['similar'], isTop3 && style['similar--top3'], isColorized && style['similar--colorized'])}
        rel="noreferrer"
      >
        {this.renderCommercialLinkText()}
      </a>
    );
  }

  private renderCommercialLinkText() {
    const { count, agencyName, accountType } = this.props;
    const offersText = `${plural(count, ['предложение', 'предложения', 'предложений'])}`;
    const userText = agencyName ? getAccountTypeTitle(accountType, agencyName) : 'агента';

    return `Ещё ${count} ${offersText} от ${userText} в этом здании`;
  }

  private trackClickEvent = () => {
    const { trackAllDuplicatesClick } = this.props;
    if (trackAllDuplicatesClick) {
      trackAllDuplicatesClick();
    }
  };
}
