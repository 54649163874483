import { IJsonQuery, ISession } from './types';
import { assertBrowser } from './utils/assertBrowser';
import { assertSession } from './utils/assertSession';
import { compareJsonQuery } from './utils/compareJsonQuery';
import { generateSession, restoreSession } from './utils/sessionApi';

/**
 * @description MlSearchSession хранит сессию поиска для ml-ранжирования.
 * Сессия поиска нужна для того, чтобы связывать поиски объявлений пользователя с одинаковыми фильтрами.
 * Сессия описывается 36-значным уникальным идентификатором и хранится в sessionStorage.
 * MlSearchSession должен использоваться ТОЛЬКО на стороне браузера, поскольку,
 * во-первых, он напрямую связан с sessionStorage, а во-вторых,
 * он хранит уникальную сессию для конкретного пользователя.
 *
 * Функциональные требования:
 * 1) идентификатор сессии меняется, когда выполняется хотя бы одно из условий:
 *   - вкладка или браузер были закрыты пользователем, а затем снова открыты;
 *   - пользователь открыл новую вкладку (идентификатор поменяется на новой вкладке);
 *   - пользователь перезагрузил страницу И
 *     пользователь попал на ранжированную выдачу (mlRankingGuid != null) И
 *     выдача не была закэширована ml-сервисом (isMlRankingResponseCached != true);
 *   - пользователь изменил фильтры поиска объявлений;
 *   - пользователь изменил сортировку объявлений;
 *   - пользователь ввел в поисковую строку урл с другими параметрами поиска
 *   - пользователь нажал кнопку назад/вперед И новая загруженная страница имеет отличные параметры поиска от предыдущей
 * 2) идентификатор сессии не меняется, когда выполняется хотя бы одно из условий:
 *   - пользователь перешел на другую страницу выдачи объявлений (пагинация);
 *   - пользователь перезагрузил страницу И
 *     пользователь не попал на ранжированную выдачу (mlRankingGuid == null);
 *   - пользователь перезагрузил страницу И
 *     пользователь попал на ранжированную выдачу (mlRankingGuid != null) И
 *     выдача была закэширована ml-сервисом (isMlRankingResponseCached == true);
 *   - пользователь ввел в поисковую строку урл с теми же параметрами поиска. Номер страницы выдачи может не сопадать.
 *   - пользователь нажал кнопку назад/вперед И новая загруженная страница имеет
 *     те же параметры поиска что и предыдущая. Номер страницы выдачи может не сопадать.
 */
export class MlSearchSession {
  private session: ISession;

  public init(jsonQuery: IJsonQuery) {
    assertBrowser();

    const session = restoreSession();

    if (session && compareJsonQuery(session.jsonQuery, jsonQuery)) {
      this.session = session;
    } else {
      this.session = generateSession(jsonQuery);
    }
  }

  public getSessionGuid(jsonQuery: IJsonQuery): string {
    assertSession(this.session);

    if (!compareJsonQuery(this.session.jsonQuery, jsonQuery)) {
      this.session = generateSession(jsonQuery);
    }

    return this.session.guid;
  }

  public updateSession(jsonQuery: IJsonQuery): string {
    assertSession(this.session);

    this.session = generateSession(jsonQuery);

    return this.session.guid;
  }
}

export const mlSearchSession = new MlSearchSession();
