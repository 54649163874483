import { IJsonQueryDistrict } from 'shared/json_query';

import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

const IGeoDistrictTagType = 'geo-district';

export interface IGeoDistrictTag {
  type: 'geo-district';
  value: number;
  text: string;
}

function isGeoDistrictTag(tag: TTag): tag is IGeoDistrictTag {
  return tag.type === IGeoDistrictTagType;
}

export const geoDistrictTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values =
      jsonQuery.geo &&
      (jsonQuery.geo.value.filter(geo => geo.type === 'district') as IJsonQueryDistrict[]).map(geo => geo.id);

    let nextTags = tags.filter(
      tag => tag.type !== IGeoDistrictTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.districts[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is IGeoDistrictTag => isGeoDistrictTag(tag) && tag.value === value,
          () => ({
            type: IGeoDistrictTagType,
            value,
            text: tagData || 'Неизвестный микрорайон',
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
