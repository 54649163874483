import { connect } from 'react-redux';

import { Dispatch, IAppState } from '../../../common/state';
import { ILocation, TLocation } from '../../../types/location';
import {
  applySelectedLocation,
  closeLocationSwitcher,
  selectCity,
  selectCountry,
  selectRegion,
} from '../../state/location_switcher';

import { LocationSwither } from './index';

const LocationSwitherContainer = connect(
  (state: IAppState) => {
    return {
      // Весь файл контейнера будет удален при удалении старых фильтров, оставил чтобы было легко восстановить, если
      // вдруг потребуется
      autocomplete: null,
      selectedRegion: state.filters.locationSwitcher.selectedRegion,
      selectedCity: state.filters.locationSwitcher.selectedCity,
      isDataLoading: state.filters.locationSwitcher.isCitiesLoading,
      regions: state.filters.regions,
      cities: state.filters.locationSwitcher.cities,
      queryString: state.filters.queryString,
    };
  },
  (dispatch: Dispatch) => ({
    applySelectedLocation: () => {
      dispatch(applySelectedLocation());
    },
    onCountrySelected: () => {
      dispatch(selectCountry());
    },
    onRegionSelected: (region: TLocation) => {
      dispatch(selectRegion(region));
    },
    onCitySelected: (city: ILocation) => {
      dispatch(selectCity(city));
    },
    onClose: () => {
      dispatch(closeLocationSwitcher());
    },
  }),
)(LocationSwither);

// eslint-disable-next-line import/no-default-export
export default LocationSwitherContainer;
