import * as React from 'react';

import { PartnerTooltipContent } from './PartnerTooltipContent';

import * as styles from './PartnerTooltip.css';

export enum EAnimationClassnames {
  LEAVE = 'leave',
  ENTERING = 'entering',
  ENTER = 'enter',
}

export enum ETooltipDirection {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface IPartnerTooltipProps {
  direction: ETooltipDirection;
  master: React.ReactNode | string;
}

export interface IPartnerTooltipState {
  isPopupVisible: boolean;
  animationClassname: EAnimationClassnames;
}

export class PartnerTooltip extends React.Component<IPartnerTooltipProps, IPartnerTooltipState> {
  private enteringTimer: number | null = null;
  private leavingTimer: number | null = null;

  public constructor(props: IPartnerTooltipProps) {
    super(props);

    this.state = {
      isPopupVisible: false,
      animationClassname: EAnimationClassnames.LEAVE,
    };
  }

  public static defaultProps: Partial<IPartnerTooltipProps> = {
    direction: ETooltipDirection.BOTTOM,
  };

  public componentWillUnmount() {
    this.clearAnimationTimeout(this.enteringTimer);
    this.clearAnimationTimeout(this.leavingTimer);
  }

  private clearAnimationTimeout = (timerId: number | null) => {
    if (timerId) {
      window.clearInterval(timerId);
    }
  };

  private togglePopup(isPopupVisible: boolean) {
    this.setState({ isPopupVisible });
  }

  private handleAnimationChange(animationClassname: EAnimationClassnames) {
    this.setState({ animationClassname });
  }

  private animateEntering = () => {
    this.enteringTimer = window.setTimeout(() => {
      this.setState({ animationClassname: EAnimationClassnames.ENTER });
    }, 300);
  };

  private animateLeaving = () => {
    this.leavingTimer = window.setTimeout(() => {
      this.togglePopup(false);
    }, 300);
  };

  private showPopup = () => {
    this.clearAnimationTimeout(this.leavingTimer);
    this.togglePopup(true);
    this.handleAnimationChange(EAnimationClassnames.ENTERING);
    this.animateEntering();
  };

  private hidePopup = () => {
    this.clearAnimationTimeout(this.enteringTimer);
    this.handleAnimationChange(EAnimationClassnames.LEAVE);
    this.animateLeaving();
  };

  public render() {
    const { isPopupVisible, animationClassname } = this.state;
    const { direction, master } = this.props;

    return (
      <div className={styles['wrapper']} onMouseEnter={this.showPopup} onMouseLeave={this.hidePopup}>
        {isPopupVisible && <PartnerTooltipContent animationClassname={animationClassname} direction={direction} />}
        {master}
      </div>
    );
  }
}
