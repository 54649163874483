import { ISerializableAppState, createAppStore } from 'shared/common/state';
import { IApplicationContext } from 'shared/types/applicationContext';
import { createMakeRequest } from 'shared/utils/request';

export function createApplicationState(context: IApplicationContext) {
  const { config, httpApi } = context;

  const initialState = config.getStrict<ISerializableAppState>('initialState');

  /**
   * Legacy
   * @deprecated
   */
  const subdomain = config.getStrict<string>('subdomain');
  /**
   * Legacy
   * @deprecated
   */
  const makeRequest = createMakeRequest(httpApi, subdomain);

  return createAppStore(context, initialState, { makeRequest });
}
