import { IOffer } from '../offer/types';

import { isGoogleBot, isYandexBot } from './user_agent';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArrayWithItems<T>(value: any): value is T[] {
  return Array.isArray(value) && value.length > 0;
}

export function cutDescriptionFromOffers(offers: IOffer[], userAgent: string): IOffer[] {
  if (isYandexBot(userAgent) || isGoogleBot(userAgent)) {
    return offers.map(offer => ({
      ...offer,
      description: offer.isDuplicatedDescription ? null : offer.description,
    }));
  }

  return offers;
}
