import { FC, ReactNode } from 'react';

import styles from './FavoritesFolders.css';

interface IFavoritesFoldersProps {
  children: ReactNode;
}

export const FavoritesFolders: FC<IFavoritesFoldersProps> = ({ children }) => (
  <div className={styles['container']}>{children}</div>
);
