import { EFacility } from 'shared/json_query/types';

import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const TAG_TYPE = 'advanced-facilities-facility';

export interface IFacilityTag {
  type: 'advanced-facilities-facility';
  value: EFacility;
}

export function isFacilityTag(tag: TTag): tag is IFacilityTag {
  return tag.type === TAG_TYPE;
}

export const facilityTagDefinition = [
  createFacilityTag(EFacility.Bath),
  createFacilityTag(EFacility.Conditioner),
  createFacilityTag(EFacility.Dishwasher),
  createFacilityTag(EFacility.Fridge),
  createFacilityTag(EFacility.Garage),
  createFacilityTag(EFacility.Internet),
  createFacilityTag(EFacility.KidsAllowed),
  createFacilityTag(EFacility.KitchenFurniture),
  createFacilityTag(EFacility.PetsAllowed),
  createFacilityTag(EFacility.Phone),
  createFacilityTag(EFacility.Pool),
  createFacilityTag(EFacility.Sauna),
  createFacilityTag(EFacility.Security),
  createFacilityTag(EFacility.Shower),
  createFacilityTag(EFacility.TV),
  createFacilityTag(EFacility.Washer),
];

export function createFacilityTag(facility: EFacility): ITagDefinition {
  return {
    reduce: (tags, jsonQuery) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const value: boolean | undefined = (jsonQuery as any)[facility] && (jsonQuery as any)[facility].value;

      if (value) {
        return ensureSingleTagExists(
          tags,
          (tag: TTag): tag is IFacilityTag => isFacilityTag(tag) && tag.value === facility,
          () => ({ type: TAG_TYPE, value: facility }),
        );
      } else {
        return ensureTagNotExists(
          tags,
          (tag: TTag): tag is IFacilityTag => isFacilityTag(tag) && tag.value === facility,
        );
      }
    },
  };
}
