import { IWorkTimeInfo } from 'shared/offer';

import * as styles from './WorkTimeInfo.css';

export interface IWorkTimeInfoProps {
  workTimeInfo?: IWorkTimeInfo | null;
}

export function WorkTimeInfo({ workTimeInfo }: IWorkTimeInfoProps) {
  let callFrom = '';
  let callTo = '';

  if (workTimeInfo && workTimeInfo.callFrom && workTimeInfo.callTo) {
    callFrom = workTimeInfo.callFrom.slice(0, 5);
    callTo = workTimeInfo.callTo.slice(0, 5);
  }

  return callFrom && callTo ? (
    <div className={styles['work-time']}>
      Работает с {callFrom} до {callTo}
    </div>
  ) : null;
}
