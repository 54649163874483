import { Header0, Paragraph1 } from '@cian/ui-kit';

import * as React from 'react';

import { Form } from '../Form';

import { TContentLayoutProps } from './types';

import * as styles from './styles.css';

export const ContentLayout: React.VFC<TContentLayoutProps> = props => {
  const { title, subtitle } = props;

  return (
    <div className={styles['root']}>
      <div className={styles['left-column']}>
        <div className={styles['title-block']}>
          <div className={styles['main-title']}>
            <Header0 color="white_100">{title}</Header0>
          </div>
          <Paragraph1 as="div" color="white_100">
            {subtitle}
          </Paragraph1>
        </div>
      </div>
      <div className={styles['right-column']}>
        <Form />
      </div>
    </div>
  );
};

ContentLayout.displayName = 'ContentLayout';
