/**
 * @description Объявления-дубликаты текущего объявления.
 */
export interface IIdenticalOffers {
  /** имя кнопки "У других продавцов" / "У других арендаторов" **/
  title: string;
  /** ссылка выдачи дубликатов-объявлений **/
  url: string;
  /** объявления-дубликаты не ML **/
  items: IIdenticalOffer[];
  /** id объявлений-дубликатов **/
  duplicatesOffersIds: number[];
}

export interface IIdenticalOffer {
  /** Realty ID объявления **/
  id: number;
  /** Cian ID объявления **/
  cianId: number;
  /** ссылка объявления **/
  url: string;
  /** лейбл для аналитики **/
  gaLabel: string;
  /** медиа-ресурсы **/
  media: IMedia;
  /** цена объявления **/
  price: IPrice;
  /** автор объявления **/
  author: IAuthor;
  /** преимущества объявления **/
  feauters: IFeatures;
}

export interface IMedia {
  /** фото объявления **/
  photoUrl?: string | null;
  /** количество фото в объявлении **/
  photoCount: number;
  /** количество видео в объявлении **/
  videoCount: number;
}

export interface IPrice {
  /** Валюта **/
  currency: ECurrency;
  /** Сутки, месяц **/
  period?: EPeriod | null;
  /** Цена, число **/
  value: number;
}

export enum ECurrency {
  /** € **/
  Eur = 'eur',
  /** ₽ **/
  Rur = 'rur',
  /** $ **/
  Usd = 'usd',
}

export enum EPeriod {
  /** В сутки **/
  Day = 'day',
  /** В месяц **/
  Month = 'month',
}

export interface IAuthor {
  /** Id автора **/
  id: number;
  /** Ссылка на аватар **/
  avatarUrl?: string | null;
  /** Отображаемое имя **/
  name?: string | null;
  /** Тип акканута **/
  accountType?: EAccountType | null;
  /** объявление от собственника **/
  isHomeowner: boolean;
}

export enum EAccountType {
  /** Специалист **/
  Specialist = 'specialist',
  /** Агенство недвижимости **/
  Agency = 'agency',
  /** Управляющая компания **/
  ManagementCompany = 'managementCompany',
  /** Отдел аренды **/
  RentDepartment = 'rentDepartment',
}

export interface IFeatures {
  /** Присутствует онлайн показ **/
  hasOnlineViewing: boolean;
  /** Присутствует планировка **/
  hasLayout: boolean;
  /** Честная игра **/
  hasFairPlay: boolean;
}
