/* eslint-disable max-lines */
import { equals } from 'ramda';

import { IAppState, ITagsData } from '../../../common/state';
import { IJsonQuery } from '../../../json_query';
import { TLocation } from '../../../types/location';

import { IAccessSystemTag, accessSystemTagDefinition } from './definitions/access_system';
import { IAgentCallsForbiddenTag, agentCallsForbiddenTagDefinition } from './definitions/agent_calls_forbidden';
import { IAmenityTag, amenityTagDefinition } from './definitions/amenity';
import { IApartmentTag, apartmentTagDefinition } from './definitions/apartment';
import { IBalconyTag, balconyTagDefinition } from './definitions/balcony';
import { IBedroomsMinCountTag, bedroomsMinCountTagDefinition } from './definitions/bedrooms_min_count';
import { IBSCenterTag, bsCenterTagDefinition } from './definitions/bs_center';
import { IBuildingClassTypesTag, buildingClassTypesTagDefinition } from './definitions/building_class';
import {
  IWarehouseBuildingClassTypesTag,
  warehouseBuildingClassTypesTagDefinition,
} from './definitions/building_class_warehouse';
import { IBuildingConditionsTag, buildingConditionTypesTagDefinition } from './definitions/building_condition';
import { IBuildingCraneTypesTag, buildingCraneTypesTagDefinition } from './definitions/building_crane';
import { IBuildingElevatorTypesTag, buildingElevatorTypesTagDefinition } from './definitions/building_elevator';
import { IBuildingGatesTypesTag, buildingGatesTypesTagDefinition } from './definitions/building_gates';
import { IBuildingHeatingTypesTag, buildingHeatingTypesTagDefinition } from './definitions/building_heating';
import { IBuildingTypeTag, buildingTypeTagDefinition } from './definitions/building_type';
import { IBuildingTypeBuildingTag, buildingTypeBuildingTagDefinition } from './definitions/building_type.building';
import { ICeilingHeightTag, ceilingHeightTagDefinition } from './definitions/ceiling_height';
import { IComissionTypeTag, comissionTypeTagDefinition } from './definitions/comission_type';
import { ICommercialSpecialtyTag, commercialSpecialtyTagDefinition } from './definitions/commercial_specialty';
import { IContractTypeTag, contractTypeTagDefinition } from './definitions/contract';
import { ICoworkinAccessTag, coworkingAccessTagDefinition } from './definitions/coworking_access';
import { ICoworkingIdTag, coworkingIDTagDefinition } from './definitions/coworking_id';
import { IDecorationTag, decorationTagDefinition } from './definitions/decoration';
import {
  IDemolishedInMoscowProgrammTag,
  demolishedInMoscowProgrammTagDefinition,
} from './definitions/demolished_in_moscow_programm';
import { IDomrfTag, domrfTagDefinition } from './definitions/domrf';
import { IElevatorsHasServiceTag, elevatorsHasServiceTagDefinition } from './definitions/elevators_has_service';
import { IElevatorsMinCountTag, elevatorsMinCountTagDefinition } from './definitions/elevators_min_count';
import { IEntranceTypesTag, entranceTypesTagDefinition } from './definitions/entrance';
import { IEstateTypeTag, estateTypeTagDefinition } from './definitions/estate_type';
import { IFacilityTag, facilityTagDefinition } from './definitions/facilities';
import { IFloorTag, floorTagDefinition } from './definitions/floor';
import { IFurnitureTag, furnitureTagDefinition } from './definitions/furniture';
import { IGarageKindsTag, garageKindsTagDefinition } from './definitions/garage_kind';
import { IGarageMaterialsTag, garageMaterialsTagDefinition } from './definitions/garage_material';
import { IGarageStatusesTag, garageStatusesTagDefinition } from './definitions/garage_status';
import { IGarageTypesTag, garageTypesTagDefinition } from './definitions/garage_type';
import {
  TGeoTag,
  geoBboxTagDefinition,
  geoCircleTagDefinition,
  geoDeveloperTagDefinition,
  geoDistrictTagDefinition,
  geoHighwayTagDefinition,
  geoHouseTagDefinition,
  geoLocationTagDefinition,
  geoNewbuildingTagDefinition,
  geoPolygonTagDefinition,
  geoRailwayTagDefinition,
  geoStreetTagDefinition,
  geoUndergroundTagDefinition,
} from './definitions/geo';
import { IHasElectricityTag, hasElectricityTagDefinition } from './definitions/has_electricity';
import { IHasEquipmentTag, hasEquipmentTagDefinition } from './definitions/has_equipment';
import {
  IHasExtinguishingSystemTag,
  hasExtinguishingSystemTagDefinition,
} from './definitions/has_extinguishing_system';
import { IHasFlatTourBookingTag, hasFlatTourBookingTagDefinition } from './definitions/has_flat_tour_booking';
import { IHasHeatingTag, hasHeatingTagDefinition } from './definitions/has_heating';
import { IHasLightTag } from './definitions/has_light';
import { IHasProfitbaseBookingTag, hasProfitbaseBookingTagDefinition } from './definitions/has_profitbase_booking';
import { IHasWaterTag, hasWaterTagDefinition } from './definitions/has_water';
import { IHeatingTypesTag, heatingTypesTagDefinition } from './definitions/heating';
import { IHiddenBaseTag, hiddenBaseTagDefinition } from './definitions/hidden_base';
import { IHouseLineBuildingTag, houseLineBuildingTag } from './definitions/house_line.building';
import { IHouseLineShoppingAreaTag, houseLineShoppingAreaTagDefinition } from './definitions/house_line.shopping_area';
import { IHouseMaterialTag, houseMaterialTagDefinition } from './definitions/house_material';
import { IHouseYearTag, houseYearTagDefinition } from './definitions/house_year';
import { IIdenticalOffersIdTag, identicalOffersIdTagDefinition } from './definitions/identical_offers_id';
import { IIsBasementFloorTag, isBasementFloorTagDefinition } from './definitions/is_basement_floor';
import { IByHomeOwnerTag, isByHomeOwnerTagDefinition } from './definitions/is_by_homeowner';
import { IIsFirstFloorTag, isFirstFloorTagDefinition } from './definitions/is_first_floor';
import { IIsSemibasementFloorTag, isSemibasementFloorTagDefinition } from './definitions/is_semibasement_floor';
import { IKitchenAreaTag, kitchenAreaTagDefinition } from './definitions/kitchen_area';
import { IKpIdTag, kpIdTagDefinition } from './definitions/kp_id';
import { ILandStatusTypesTag, landStatusTypesTagDefinition } from './definitions/land_status';
import { ILayoutTypeTag, layoutTypeTagDefinition } from './definitions/layout';
import { ILivingAreaTag, livingAreaTagDefinition } from './definitions/living_area';
import { ILoggiaTag, loggiaTagDefinition } from './definitions/loggia';
import { IManufactureConditionsTag, manufactureConditionTypesTagDefinition } from './definitions/manufacture_condition';
import { IMaxPrepayTag, maxPrepayTagDefinition } from './definitions/max_prepay';
import { IMortgagePossibleTag, mortgagePossibleTagDefinition } from './definitions/mortgage_possible';
import { IMultiIdTag, multiIdTagDefinition } from './definitions/multi_id';
import { IIncludeNewMoscowTag, includeNewMoscowTagDefinition } from './definitions/new_moscow';
import { INewbuildingCompletedTag, newbuildingCompletedTagDefinition } from './definitions/newbuilding_completed';
import {
  INewbuildingCompletionAfterTag,
  buildingDoneYearsTagDefinition,
} from './definitions/newbuilding_completion_after';
import {
  INewbuildingCompletionYearTag,
  newbuildingCompletionYearTagDefinition,
} from './definitions/newbuilding_completion_year';
import { INotLastFloorTag, notLastFloorTagDefinition } from './definitions/not_last_floor';
import { IOfferIdTag, offerIdTagDefinition } from './definitions/offer_id';
import { IOfficeConditionsTag, officeConditionTypesTagDefinition } from './definitions/office_condition';
import { IParkingTypesTag, parkingTypesTagDefinition } from './definitions/parking';
import { IPenthouseTag, penthouseTagDefinition } from './definitions/penthouse';
import { IPhoneTag, phoneTagDefinition } from './definitions/phone';
import { IPlacementTypeTag, placementTypeTagDefinition } from './definitions/placement_type';
import { IPromoTag, promoTagDefinition } from './definitions/promo';
import { IPropertyTypeTag, propertyTypeTagDefinition } from './definitions/property_type';
import { IPublishPeriodTag, publishPeriodTagDefinition } from './definitions/publish_period';
import { IRentTimeTag, rentTimeTagDefinition } from './definitions/rent_time';
import { IRepairTag, repairTagDefinition } from './definitions/repair';
import { IRoomAreaTag, roomAreaTagDefinition } from './definitions/room_area';
import { IRoomsInDealTag, roomsInDealTagDefinition } from './definitions/rooms_in_deal';
import { IRoomsTotalTag, roomsTotalTagDefinition } from './definitions/rooms_total';
import { ISaleTypesTag, saleTypesTagDefinition } from './definitions/sale_type';
import { ISiteAreaTag, siteAreaTagDefinition } from './definitions/site_area';
import { ISuburbanUtilityTag, suburbanUtilityTagDefinition } from './definitions/suburban_utilities';
import { ISuburbanWCTypeTag, suburbanWCTypeTagDefinition } from './definitions/suburban_wc';
import { ITenantsTag, tenantsTagDefinition } from './definitions/tenants';
import { ITotalAreaTag, totalAreaTagDefinition } from './definitions/total_area';
import { ITotalFloorsTag, totalFloorsTagDefinition } from './definitions/total_floors';
import { ITour3dTag, tour3dDefinition } from './definitions/tour_3d';
import { IUserIdTag, userIdTagDefinition } from './definitions/user_id';
import { IHasVideoTag, hasVideoTagDefinition } from './definitions/video';
import { IWCCountTag, wcCountTagDefinition } from './definitions/wc_count';
import { IWCTypeTag, wcTypeTagDefinition } from './definitions/wc_type';
import { IWindowsTypeTag, windowsTagDefinition } from './definitions/windows';
import { IWithLayoutTag, withLayoutTagDefinition } from './definitions/with_layout';
import { IWithPhotoTag, withPhotoTagDefinition } from './definitions/with_photo';
import { IWithoutDepositTag, withoutDepositTagDefinition } from './definitions/without_deposit';
import { IWordsExcludeTag, wordsExcludeTagDefinition } from './definitions/words_exclude';
import { IWordsIncludeTag, wordsIncludeTagDefinition } from './definitions/words_include';

export interface ITagDefinition {
  reduce(tags: TTag[], jsonQuery: IJsonQuery, tagsData: ITagsData, currentLocation: TLocation): TTag[];
}

export type TMultiSelectTag =
  | IBuildingClassTypesTag
  | IBuildingConditionsTag
  | IBuildingCraneTypesTag
  | IBuildingElevatorTypesTag
  | IBuildingGatesTypesTag
  | IBuildingHeatingTypesTag
  | IContractTypeTag
  | ICommercialSpecialtyTag
  | IEntranceTypesTag
  | IEstateTypeTag
  | IGarageKindsTag
  | IGarageMaterialsTag
  | IGarageStatusesTag
  | IGarageTypesTag
  | IHouseLineBuildingTag
  | IHouseLineShoppingAreaTag
  | IManufactureConditionsTag
  | IPlacementTypeTag
  | IOfficeConditionsTag
  | IWarehouseBuildingClassTypesTag;

export type TRadioSelectTag = IAccessSystemTag;

export type TTag =
  | ITour3dTag
  | TMultiSelectTag
  | TRadioSelectTag
  | IAgentCallsForbiddenTag
  | IApartmentTag
  | IBSCenterTag
  | IBalconyTag
  | IBedroomsMinCountTag
  | IBuildingTypeBuildingTag
  | IBuildingTypeTag
  | IByHomeOwnerTag
  | ICeilingHeightTag
  | IComissionTypeTag
  | IDecorationTag
  | IDemolishedInMoscowProgrammTag
  | IDomrfTag
  | IElevatorsHasServiceTag
  | IElevatorsMinCountTag
  | IFacilityTag
  | IFloorTag
  | IFurnitureTag
  | IHasElectricityTag
  | IHasEquipmentTag
  | IHasExtinguishingSystemTag
  | IHasFlatTourBookingTag
  | IHasHeatingTag
  | IHasLightTag
  | IHasProfitbaseBookingTag
  | IHasVideoTag
  | IHasWaterTag
  | IHeatingTypesTag
  | IHiddenBaseTag
  | IHouseMaterialTag
  | IHouseYearTag
  | IIncludeNewMoscowTag
  | IIsBasementFloorTag
  | IIsFirstFloorTag
  | IIsSemibasementFloorTag
  | IKitchenAreaTag
  | ILandStatusTypesTag
  | ILayoutTypeTag
  | ILivingAreaTag
  | ILoggiaTag
  | IMaxPrepayTag
  | IMortgagePossibleTag
  | IMultiIdTag
  | INewbuildingCompletedTag
  | INewbuildingCompletionAfterTag
  | INewbuildingCompletionYearTag
  | INotLastFloorTag
  | IOfferIdTag
  | IParkingTypesTag
  | IPenthouseTag
  | IPhoneTag
  | IPromoTag
  | IPropertyTypeTag
  | IPublishPeriodTag
  | IRentTimeTag
  | IRepairTag
  | IRoomAreaTag
  | IRoomsInDealTag
  | IRoomsTotalTag
  | ISaleTypesTag
  | ISiteAreaTag
  | ISuburbanUtilityTag
  | ISuburbanWCTypeTag
  | ITenantsTag
  | ITotalAreaTag
  | ITotalFloorsTag
  | IUserIdTag
  | IWCCountTag
  | IWCTypeTag
  | IWindowsTypeTag
  | IWithPhotoTag
  | IWithLayoutTag
  | IWithoutDepositTag
  | IWordsExcludeTag
  | IWordsIncludeTag
  | TGeoTag
  | IKpIdTag
  | IIdenticalOffersIdTag
  | IAmenityTag
  | ICoworkinAccessTag
  | ICoworkingIdTag;

export interface ITagRemovedAction {
  type: 'filters/tags/TAG_REMOVED';
  tag: TTag;
  index: number;
}

export interface IAllTagsRemovedAction {
  type: 'filters/tags/ALL_TAGS_REMOVED';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tagDefinitions: ITagDefinition[] = [
  ...facilityTagDefinition,
  ...suburbanUtilityTagDefinition,
  tour3dDefinition,
  accessSystemTagDefinition,
  agentCallsForbiddenTagDefinition,
  apartmentTagDefinition,
  balconyTagDefinition,
  bedroomsMinCountTagDefinition,
  bsCenterTagDefinition,
  buildingClassTypesTagDefinition,
  buildingConditionTypesTagDefinition,
  buildingCraneTypesTagDefinition,
  buildingDoneYearsTagDefinition,
  buildingElevatorTypesTagDefinition,
  buildingGatesTypesTagDefinition,
  buildingHeatingTypesTagDefinition,
  buildingTypeBuildingTagDefinition,
  buildingTypeTagDefinition,
  ceilingHeightTagDefinition,
  comissionTypeTagDefinition,
  commercialSpecialtyTagDefinition,
  contractTypeTagDefinition,
  decorationTagDefinition,
  demolishedInMoscowProgrammTagDefinition,
  domrfTagDefinition,
  elevatorsHasServiceTagDefinition,
  elevatorsMinCountTagDefinition,
  entranceTypesTagDefinition,
  estateTypeTagDefinition,
  floorTagDefinition,
  furnitureTagDefinition,
  garageKindsTagDefinition,
  garageMaterialsTagDefinition,
  garageStatusesTagDefinition,
  garageTypesTagDefinition,
  geoCircleTagDefinition,
  geoDeveloperTagDefinition,
  geoDistrictTagDefinition,
  geoHighwayTagDefinition,
  geoHouseTagDefinition,
  geoLocationTagDefinition,
  geoNewbuildingTagDefinition,
  geoPolygonTagDefinition,
  geoBboxTagDefinition,
  geoStreetTagDefinition,
  geoUndergroundTagDefinition,
  geoRailwayTagDefinition,
  hasElectricityTagDefinition,
  hasEquipmentTagDefinition,
  hasExtinguishingSystemTagDefinition,
  hasFlatTourBookingTagDefinition,
  hasHeatingTagDefinition,
  hasProfitbaseBookingTagDefinition,
  hasVideoTagDefinition,
  hasWaterTagDefinition,
  heatingTypesTagDefinition,
  hiddenBaseTagDefinition,
  houseLineBuildingTag,
  houseLineShoppingAreaTagDefinition,
  houseMaterialTagDefinition,
  houseYearTagDefinition,
  includeNewMoscowTagDefinition,
  isBasementFloorTagDefinition,
  isByHomeOwnerTagDefinition,
  isFirstFloorTagDefinition,
  isSemibasementFloorTagDefinition,
  kitchenAreaTagDefinition,
  kpIdTagDefinition,
  landStatusTypesTagDefinition,
  layoutTypeTagDefinition,
  livingAreaTagDefinition,
  loggiaTagDefinition,
  manufactureConditionTypesTagDefinition,
  maxPrepayTagDefinition,
  mortgagePossibleTagDefinition,
  multiIdTagDefinition,
  newbuildingCompletedTagDefinition,
  newbuildingCompletionYearTagDefinition,
  notLastFloorTagDefinition,
  offerIdTagDefinition,
  officeConditionTypesTagDefinition,
  parkingTypesTagDefinition,
  penthouseTagDefinition,
  phoneTagDefinition,
  placementTypeTagDefinition,
  promoTagDefinition,
  propertyTypeTagDefinition,
  publishPeriodTagDefinition,
  rentTimeTagDefinition,
  repairTagDefinition,
  roomAreaTagDefinition,
  roomsInDealTagDefinition,
  roomsTotalTagDefinition,
  saleTypesTagDefinition,
  siteAreaTagDefinition,
  suburbanWCTypeTagDefinition,
  tenantsTagDefinition,
  totalAreaTagDefinition,
  totalFloorsTagDefinition,
  userIdTagDefinition,
  warehouseBuildingClassTypesTagDefinition,
  wcCountTagDefinition,
  wcTypeTagDefinition,
  windowsTagDefinition,
  withPhotoTagDefinition,
  withLayoutTagDefinition,
  withoutDepositTagDefinition,
  wordsExcludeTagDefinition,
  wordsIncludeTagDefinition,
  identicalOffersIdTagDefinition,
  amenityTagDefinition,
  coworkingAccessTagDefinition,
  coworkingIDTagDefinition,
];

export const restoreTagsFromJsonQuery = (jsonQuery: IJsonQuery, tagsData: ITagsData, location: TLocation): TTag[] =>
  tagDefinitions.reduce((acc, tagDefinition) => {
    return tagDefinition.reduce(acc, jsonQuery, tagsData, location);
  }, [] as TTag[]);

export function tagsReducer(state: IAppState): IAppState {
  const tags = tagDefinitions.reduce((acc, tagDefinition) => {
    return tagDefinition.reduce(acc, state.filters.jsonQuery, state.filters.tagsData, state.filters.currentLocation);
  }, state.filters.tags);

  if (!equals(state.filters.tags, tags)) {
    return {
      ...state,
      filters: {
        ...state.filters,
        tags,
      },
    };
  }

  return state;
}
