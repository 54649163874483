import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IWithLayoutTagType = 'advanced-withLayout';

export interface IWithLayoutTag {
  type: 'advanced-withLayout';
}

function isWithLayoutTag(tag: TTag): tag is IWithLayoutTag {
  return tag.type === IWithLayoutTagType;
}

export const withLayoutTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.with_layout && jsonQuery.with_layout.value;

    if (value) {
      return ensureSingleTagExists(tags, isWithLayoutTag, () => ({ type: IWithLayoutTagType }));
    } else {
      return ensureTagNotExists(tags, isWithLayoutTag);
    }
  },
};
