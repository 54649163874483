import { Outside } from '@cian/ui-kit';

import * as React from 'react';
import { throttle } from 'throttle-debounce';

import { CoworkingProfitLabelService } from '../../services';
import { CoworkingProfitLabelView } from '../CoworkingProfitLabelView';

import { CoworkingProfitLabelPops, CoworkingProfitLabelState } from './types';

export class CoworkingProfitLabelControlled extends React.PureComponent<
  CoworkingProfitLabelPops,
  CoworkingProfitLabelState
> {
  private readonly coworkingProfitBannerService: CoworkingProfitLabelService;
  private readonly buttonRef: React.RefObject<HTMLButtonElement>;

  public constructor(props: CoworkingProfitLabelPops) {
    super(props);

    const { setIsHoverBehavior } = props;

    this.state = {
      tooltipVisible: false,
    };

    this.buttonRef = React.createRef<HTMLButtonElement>();

    this.coworkingProfitBannerService = new CoworkingProfitLabelService(() => setIsHoverBehavior(true));
  }

  public componentDidMount(): void {
    const { setIsHoverBehavior } = this.props;

    if (CoworkingProfitLabelService.isCoworkingProfitWasShown) {
      setIsHoverBehavior(true);
    } else {
      if (CoworkingProfitLabelService.isElementInViewport(this.buttonRef)) {
        this.componentInViewPortMountActions();
      } else {
        this.componentOutViewPortMountActions();
      }
    }
  }

  public componentWillUnmount(): void {
    this.coworkingProfitBannerService.removeAllListeners();
  }

  public render(): JSX.Element {
    const { tooltipVisible } = this.state;

    return (
      <Outside active insideRefs={[]} onOutside={this.onTooltipClose}>
        <CoworkingProfitLabelView open={tooltipVisible} ref={this.buttonRef} onOpen={this.onTooltipOpen} />
      </Outside>
    );
  }

  private readonly setTooltipVisibility = (tooltipVisible: boolean, callback?: VoidFunction) => () =>
    this.setState({ tooltipVisible }, callback);

  /**
   * @method componentInViewPortMountActions
   * @private
   * @description данный метод вызывается только при монтировании компонента, если баннер не был ни разу показан
   * и баннер находится в области видимости окна браузера
   */
  private componentInViewPortMountActions(): void {
    this.coworkingProfitBannerService.coworkingProfitBannerWasShown();

    this.onTooltipOpen();
  }

  /**
   * @method componentOutViewPortMountActions
   * @private
   * @description данный метод вызывается только при монтировании компонента, если баннер не был ни разу показан
   * и баннер не находится в поле видимости окна браузера
   */
  private componentOutViewPortMountActions(): void {
    const onScroll = throttle(500, () => {
      if (
        !CoworkingProfitLabelService.isCoworkingProfitWasShown &&
        CoworkingProfitLabelService.isElementInViewport(this.buttonRef)
      ) {
        this.coworkingProfitBannerService.coworkingProfitBannerWasShown();

        this.onTooltipOpen();
      }
    });

    this.coworkingProfitBannerService.addListener(onScroll);
  }

  private readonly onScrollAfterShowTooltip = throttle(500, (): void => {
    if (!CoworkingProfitLabelService.isElementInViewport(this.buttonRef)) {
      this.onTooltipClose();
    }
  });

  private readonly onTooltipClose = (): void => {
    const { setIsHoverBehavior } = this.props;

    this.coworkingProfitBannerService.removeListener(this.onScrollAfterShowTooltip);

    setIsHoverBehavior(true);

    this.setTooltipVisibility(false)();
  };

  private readonly onTooltipOpen = (): void => {
    this.setTooltipVisibility(true)();

    this.coworkingProfitBannerService.addListener(this.onScrollAfterShowTooltip);
  };
}
