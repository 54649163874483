import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const WITH_PHOTO_TAG_TYPE = 'advanced-withPhoto';

export interface IWithPhotoTag {
  type: 'advanced-withPhoto';
}

function isWithPhotoTag(tag: TTag): tag is IWithPhotoTag {
  return tag.type === WITH_PHOTO_TAG_TYPE;
}

export const withPhotoTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.wp && jsonQuery.wp.value;

    // PM-5572
    if (value) {
      return ensureSingleTagExists(tags, isWithPhotoTag, () => ({ type: WITH_PHOTO_TAG_TYPE }));
    } else {
      return ensureTagNotExists(tags, isWithPhotoTag);
    }
  },
};
