import { call, fork, put, select, take } from 'redux-saga/effects';

import { updateAgentComment, updateOfferComment } from 'shared/api/comments';
import { IAppState, TActions } from 'shared/common/state';
import { IAgent, IOffer } from 'shared/offer';

export interface IOfferCommentsSaveRequestedAction {
  type: 'serp/offer_card/OFFER_COMMENTS_SAVE_REQUESTED';
  offer: IOffer;
  commentOffer: string | undefined;
  commentAgent: string | undefined;
}

export interface IOfferCommentsSaveFinishedAction {
  type: 'serp/offer_card/OFFER_COMMENTS_SAVE_FINISHED';
  offer: IOffer;
  commentOffer: string | undefined;
  commentAgent: string | undefined;
  isAgentComment?: boolean;
  isOfferComment?: boolean;
}

export function changeOfferComments(
  offer: IOffer,
  commentOffer: string | undefined,
  commentAgent: string | undefined,
): IOfferCommentsSaveRequestedAction {
  return {
    type: 'serp/offer_card/OFFER_COMMENTS_SAVE_REQUESTED',
    offer,
    commentOffer,
    commentAgent,
  };
}

const getChangedState = (state: IAppState, action: IOfferCommentsSaveFinishedAction): IAppState => {
  return {
    ...state,
    results: {
      ...state.results,
      offers: state.results.offers.map(offer => {
        let newOffer = offer;

        if (offer.cianId === action.offer.cianId || offer.id === action.offer.id) {
          const notes = { ...newOffer.notes };

          if (action.commentOffer) {
            notes.offer = {
              text: action.commentOffer,
              timestamp: new Date().toISOString(),
            };
          } else if (notes.offer) {
            notes.offer = null;
          }

          newOffer = Object.assign({}, newOffer, {
            notes,
          });
        }

        if (
          state.user.isAuthenticated &&
          offer.user &&
          action.offer.user &&
          offer.user.cianUserId === action.offer.user.cianUserId
        ) {
          const notes = { ...newOffer.notes };

          if (action.commentAgent) {
            notes.realtor = {
              text: action.commentAgent,
              timestamp: new Date().toISOString(),
            };
          } else if (notes.realtor) {
            notes.realtor = null;
          }

          newOffer = Object.assign({}, newOffer, {
            notes,
          });
        }

        return newOffer;
      }),
    },
  };
};

function* updateOfferComments(offer: IOffer, commentOffer: string, commentAgent: string) {
  let offerResponse: { status: string };
  let agentResponse: { status: string } | undefined;

  try {
    const state: IAppState = yield select();
    const {
      makeRequest,
      user: { isAuthenticated },
    } = state;

    offerResponse = yield call(updateOfferComment, makeRequest, offer, commentOffer);
    if (isAuthenticated) {
      agentResponse = yield call(updateAgentComment, makeRequest, offer, commentAgent);
    }

    yield put({
      type: 'serp/offer_card/OFFER_COMMENTS_SAVE_FINISHED',
      offer,
      commentOffer,
      commentAgent,
      isAgentComment: agentResponse && agentResponse.status === 'ok',
      isOfferComment: offerResponse && offerResponse.status === 'ok',
    } as IOfferCommentsSaveFinishedAction);
  } catch (error) {
    // Error
  }
}

export function* offerCommentsSaga() {
  while (true) {
    const { offer, commentOffer, commentAgent } = yield take('serp/offer_card/OFFER_COMMENTS_SAVE_REQUESTED');
    yield fork(updateOfferComments, offer, commentOffer, commentAgent);
  }
}

export function offerCommentsReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'serp/offer_card/OFFER_COMMENTS_SAVE_REQUESTED':
      return {
        ...state,
        results: {
          ...state.results,
          commentingBlockedOffers: state.results.commentingBlockedOffers.concat(action.offer.cianId || action.offer.id),

          commentingBlockedAgents:
            state.user.isAuthenticated && action.offer.user
              ? state.results.commentingBlockedAgents.concat(action.offer.user.cianUserId)
              : state.results.commentingBlockedAgents,
        },
      };
    case 'serp/offer_card/OFFER_COMMENTS_SAVE_FINISHED': {
      const newState = getChangedState(state, action);
      const timestamp = new Date().toISOString();

      return {
        ...newState,
        results: {
          ...newState.results,
          offers: newState.results.offers.map(offer => {
            if (action.offer.id === offer.id) {
              const realtorNote = action.commentAgent
                ? {
                    text: action.commentAgent,
                    timestamp,
                  }
                : null;
              const offerNote = action.commentOffer
                ? {
                    text: action.commentOffer,
                    timestamp,
                  }
                : null;

              return {
                ...offer,
                notes: {
                  offer: action.isOfferComment ? offerNote : offer.notes.offer,
                  realtor: action.isAgentComment ? realtorNote : offer.notes.realtor,
                },
              } as IOffer;
            }

            return offer;
          }),

          commentingBlockedOffers: newState.results.commentingBlockedOffers.filter(
            offerId => offerId !== action.offer.cianId && offerId !== action.offer.id,
          ),
          commentingBlockedAgents:
            newState.user.isAuthenticated && action.offer.user
              ? newState.results.commentingBlockedAgents.filter(
                  agentId => agentId !== (action.offer.user as IAgent).cianUserId,
                )
              : newState.results.commentingBlockedAgents,
        },
      };
    }
    default:
      return state;
  }
}
