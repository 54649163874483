import { PureComponent } from 'react';

import { IAvgPriceInformer, IQuickLink } from '../../../common/state';
import { IJsonQuery } from '../../../json_query';

import { QuickLinksCommercialCalc } from './components/QuickLinksCommercialCalc';
import { QuickLinksList } from './components/QuickLinksList/QuickLinksList';
import { QuickLinksSuburbanTrap } from './components/QuickLinksSuburbanTrap';
import { trackQuickLinksView } from './utils/tracking';

import * as styles from './QuickLinks.css';

type TDealType =
  | 'flatrent'
  | 'flatsale'
  | 'newobjectsale'
  | 'commercialrent'
  | 'commercialsale'
  | 'suburbanrent'
  | 'suburbansale';

export interface IQuickLinksStateProps {
  avgPriceInformer: IAvgPriceInformer | null;
  jsonQuery: IJsonQuery;
  level: number | null;
  linkGroups: IQuickLink[][] | null;
  priceSm?: boolean;
  dealType: TDealType;
  isOfficeCalculatorButtonVisible: boolean;
  countrysideLink?: string;
  isCountrysideTrapEnabled: boolean;
}

export interface IQuickLinksDispatchProps {
  onOfficeCalculatorButtonClick(): void;
}

export interface IQuickLinksProps extends IQuickLinksStateProps, IQuickLinksDispatchProps {
  level: number;
  linkGroups: IQuickLink[][];
}

export class QuickLinks extends PureComponent<IQuickLinksProps> {
  public componentDidMount() {
    trackQuickLinksView(this.props.level);
  }

  public render() {
    const {
      avgPriceInformer,
      jsonQuery,
      dealType,
      isOfficeCalculatorButtonVisible,
      onOfficeCalculatorButtonClick,
      level,
      linkGroups,
      priceSm,
      countrysideLink,
      isCountrysideTrapEnabled,
    } = this.props;

    const coworkingOfferType = jsonQuery.coworking_offer_type && jsonQuery.coworking_offer_type.value;
    const isShowQuickLinks = !(coworkingOfferType && coworkingOfferType.length > 0);

    return (
      <div className={styles['wrapper']}>
        <QuickLinksCommercialCalc
          avgPriceInformer={avgPriceInformer}
          dealType={dealType}
          isOfficeCalculatorButtonVisible={isOfficeCalculatorButtonVisible}
          jsonQuery={jsonQuery}
          priceSm={priceSm}
          onOfficeCalculatorButtonClick={onOfficeCalculatorButtonClick}
        />

        {isShowQuickLinks && <QuickLinksList level={level} linkGroups={linkGroups} />}

        <QuickLinksSuburbanTrap
          countrysideLink={countrysideLink}
          isCountrysideTrapEnabled={isCountrysideTrapEnabled}
          jsonQuery={jsonQuery}
        />
      </div>
    );
  }
}
