import { IAppState, TActions } from '../../../common/state';
import { EWCCount, EWCType } from '../../../json_query';

export const IWCTypeChangedActionType = 'filters/wc/WC_TYPE_CHANGED';
export const IWCCountChangedActionType = 'filters/wc/WC_COUNT_CHANGED';

export interface IWCTypeChangedAction {
  type: 'filters/wc/WC_TYPE_CHANGED';
  wcType: EWCType;
  wcCount: EWCCount;
}

export interface IWCCountChangedAction {
  type: 'filters/wc/WC_COUNT_CHANGED';
  wcType: EWCType;
  wcCount: EWCCount;
}

export function changeWCType(wcType: EWCType, wcCount: EWCCount): IWCTypeChangedAction {
  return {
    type: IWCTypeChangedActionType,
    wcType,
    wcCount,
  };
}

export function changeWCCount(wcType: EWCType, wcCount: EWCCount): IWCCountChangedAction {
  return {
    type: IWCCountChangedActionType,
    wcType,
    wcCount,
  };
}

const cloneState = (state: IAppState): IAppState => {
  return {
    ...state,
    filters: {
      ...state.filters,
      jsonQuery: {
        ...state.filters.jsonQuery,
      },
    },
  };
};

export function wcReducer(state: IAppState, action: TActions): IAppState {
  const newState = cloneState(state);
  const jq = newState.filters.jsonQuery;

  const wcCount = (jq.wc && jq.wc.value.gte) || EWCCount.Any;
  const wcType = (jq.wc_type && jq.wc_type.value) || EWCType.Any;

  const wcCountWasFixed = jq.isWCfixed;
  jq.isWCfixed = undefined;

  switch (action.type) {
    case IWCTypeChangedActionType: {
      const newWCType = action.wcType;

      jq.wc_type =
        newWCType !== EWCType.Any
          ? {
              type: 'term',
              value: newWCType,
            }
          : undefined;

      if (wcCount === EWCCount.Any || (wcCount === EWCCount.One && wcCountWasFixed)) {
        if (newWCType === EWCType.Any) {
          jq.wc = undefined;
        } else {
          jq.isWCfixed = true;
          jq.wc = {
            type: 'range',
            value: {
              gte: EWCCount.One,
            },
          };
        }
      }

      return newState;
    }

    case IWCCountChangedActionType: {
      const newWCCount = action.wcCount;

      if (newWCCount === EWCCount.Any) {
        if (wcType !== EWCType.Any) {
          jq.isWCfixed = true;

          jq.wc = {
            type: 'range',
            value: {
              gte: EWCCount.One,
            },
          };
        } else {
          jq.wc = undefined;
        }
      } else {
        jq.wc = {
          type: 'range',
          value: {
            gte: newWCCount,
          },
        };
      }

      return newState;
    }

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-wc-wcType') {
        jq.wc_type = undefined;
        jq.wc = wcCount === EWCCount.One && wcCountWasFixed ? undefined : jq.wc;
      } else if (action.tag.type === 'advanced-wc-wcCount') {
        if (wcType !== EWCType.Any) {
          jq.isWCfixed = true;
          jq.wc = {
            type: 'range',
            value: {
              gte: EWCCount.One,
            },
          };
        } else {
          jq.wc = undefined;
        }
      }

      return newState;

    case 'filters/tags/ALL_TAGS_REMOVED':
      jq.wc = undefined;
      jq.wc_type = undefined;
      jq.isWCfixed = undefined;

      return newState;

    default:
      return state;
  }
}
