import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import jump from 'jump.js';

import { IJsonQuery } from '../../../json_query';
import { getEndPage, getPageList, getStartPage } from '../../../utils/pagination';
import { ButtonPrintExcelGroupContainer } from '../bottom_print_excel/bottom_print_excel_container';

import * as styles from './index.css';

export interface IPaginationProps {
  aggregatedOffers: number;
  jsonQuery: IJsonQuery;
  paginationUrls: string[];
  queryString: string;
  offersPerPage: number;
  totalPages: number;

  onSelect(page: number): void;
}

export interface IPaginationState {
  perPage: number;
  totalPages: number;
  page: number;
}

export class Pagination extends React.Component<IPaginationProps, IPaginationState> {
  public constructor(props: IPaginationProps) {
    super(props);

    const {
      jsonQuery: { page },
      offersPerPage,
      totalPages,
    } = this.props;

    this.state = {
      perPage: offersPerPage,
      totalPages,
      page: (page && page.value) || 1,
    };
  }

  /* istanbul ignore next */
  public UNSAFE_componentWillReceiveProps(nextProps: IPaginationProps) {
    if (nextProps.jsonQuery !== this.props.jsonQuery) {
      const {
        offersPerPage,
        totalPages,
        jsonQuery: { page },
      } = nextProps;

      /* istanbul ignore next */
      this.setState({
        perPage: offersPerPage,
        totalPages,
        /* istanbul ignore next */
        page: (page && page.value) || 1,
      });
    }
  }

  public render() {
    return (
      <div className={styles['wrapper']}>
        <div className={styles['container']}>
          <ul className={styles['list']}>{this.state.totalPages > 1 && this.renderPages()}</ul>
          <ButtonPrintExcelGroupContainer />
        </div>
      </div>
    );
  }

  private renderPages = () => {
    const { page, totalPages } = this.state;
    const startPage = getStartPage(page, totalPages);
    const endPage = getEndPage(page, totalPages);
    const pagesList = getPageList(page, totalPages);

    return pagesList.map((pageNumber, index) => {
      let text = String(pageNumber);

      if (index === 1 && startPage > 2) {
        text = '..';
      }

      if (index === pagesList.length - 1 && endPage < totalPages) {
        text = '..';
      }

      return (
        <li
          key={`page-${pageNumber}`}
          {...mergeStyles(styles['list-item'], pageNumber === page && styles['list-item--active'])}
        >
          {this.renderPageLink(pageNumber, text)}
        </li>
      );
    });
  };

  private renderPageLink = (pageNumber: number, text: string) => {
    if (pageNumber === this.state.page) {
      return <span>{pageNumber}</span>;
    }

    let pageUrl = this.getQueryStringForPage(pageNumber);
    if (pageNumber <= 2) {
      pageUrl = this.props.paginationUrls[pageNumber - 1];
    }

    /* istanbul ignore next */
    return (
      <a className={styles['list-itemLink']} href={pageUrl} onClick={event => this.onSelect(event, pageNumber)}>
        {text || pageNumber}
      </a>
    );
  };

  private getQueryStringForPage = (pageNumber: number): string => {
    let queryString = this.props.queryString;
    if (!queryString) {
      queryString = `p=${pageNumber}`;
    } else if (/((^|&)p=)[^&]+/.test(queryString)) {
      queryString = queryString.replace(/((^|&)p=)[^&]+/, `$1${pageNumber}`);
    } else {
      queryString += `&p=${pageNumber}`;
    }

    return `/cat.php?${queryString}`;
  };

  /* istanbul ignore next */
  private onSelect(event: React.MouseEvent<HTMLAnchorElement>, pageNumber: number) {
    event.preventDefault();

    jump(-window.scrollY, {
      duration: 300,
    });
    this.props.onSelect(pageNumber);
  }
}
