import { IMakeRequest } from '../utils/request';

export interface INewbuildingInfo {
  name: string;
  url: string;
  fullUrl?: string;
  address: string;
  status: string;
  metro?: IMetro;
  description?: string;
  seoDescription?: string;
  images?: string[];
  videos?: string[];
  developer?: IDeveloper;
  contacts?: IDeveloperContact[];
  trackingData: INewbuildingTrackingData;
  isFavorite?: boolean;
  id: number;
  isProblem?: boolean;
  isReliable?: boolean;
}

export interface IDeveloper {
  name: string;
  image: string;
  url: string;
  offers: number;
  newbuildings: number;
}

export interface IDeveloperContact {
  name: string;
  phone: string;
  avatarUrl?: string;
  isLeadFactory?: boolean;
  isBuilder?: boolean;
  newbuildingDynamicCalltracking?: {
    siteBlockId?: number | null;
  };
}

export interface INewbuildingTrackingData {
  cityId: number;
  moId: number;
  oblId: number;
}

export interface IMetro {
  regionId: number;
  name: string;
  color: string;
  travelTime?: number;
  isOnTransport?: boolean;
}

export function getNewbuildingInfo(makeRequest: IMakeRequest, id: number, cookies?: string) {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-newobject-info/',
    parameters: {
      newobject_id: id,
    },
    method: 'GET',
    headers: cookies ? [['Cookie', cookies]] : [],
  })
    .then((response: { data: { data: INewbuildingInfo } }) => response.data.data)
    .catch(ex => {
      // TODO: Заменить сравнение имени на instanceof
      if (ex.statusCode === 404) {
        return null;
      }

      throw ex;
    });
}

export function fetchAddNewbuildingToFavorites(makeRequest: IMakeRequest, newbuildingId: number) {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/add-newbuilding-to-favorites/',
    parameters: {
      newbuildingId,
    },
    method: 'POST',
  });
}

export function fetchRemoveNewbuildingFromFavorites(makeRequest: IMakeRequest, newbuildingId: number) {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/remove-newbuilding-from-favorites/',
    parameters: {
      newbuildingId,
    },
    method: 'POST',
  });
}
