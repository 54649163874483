import { parseURI } from '@cian/http-api';

import { isJsonQueryRedirect } from 'shared/api/json_query';
import { convertJsonQueriesToSeoUrls } from 'shared/api/pagination';
import { Dispatch, IAppState, TActions } from 'shared/common/state';
import { jq } from 'shared/json_query';
import { defaultHideOfferState } from 'shared/serp/state/hide_offer';
import { fetchOffers } from 'shared/services/fetchOffers';
import { IOffersData } from 'shared/types/offersData';
import { cutDescriptionFromOffers } from 'shared/utils';
import { getRegionIds } from 'shared/utils/geo';
import { saveHistorySearch } from 'shared/utils/history_search';
import { offersDecorator } from 'shared/utils/offersDecorator';
import { getTrackingLocation } from 'shared/utils/trackings';

import { trackPageView } from '../tracking';

export interface IPageChangeStartedAction {
  type: 'filters/pagination/PAGE_CHANGE_STARTED';
}

export interface IPageChangeFinishedAction {
  type: 'filters/pagination/PAGE_CHANGE_FINISHED';
  data: IOffersData;
  paginationUrls: string[];
}

export const FILTERS_PAGINATION_PAGE_CHANGE_STARTED = 'filters/pagination/PAGE_CHANGE_STARTED';
export const FILTERS_PAGINATION_PAGE_CHANGE_FINISHED = 'filters/pagination/PAGE_CHANGE_FINISHED';

export const pageChangeStarted = (): IPageChangeStartedAction => ({
  type: FILTERS_PAGINATION_PAGE_CHANGE_STARTED,
});

export const pageChangeFinished = (data: IOffersData, paginationUrls: string[]): IPageChangeFinishedAction => ({
  type: FILTERS_PAGINATION_PAGE_CHANGE_FINISHED,
  data,
  paginationUrls,
});

export function isJsonQueryRedirectOffers(data: IOffersData): boolean {
  return isJsonQueryRedirect(data) && !!(data.redirectData && data.redirectData.location);
}

export function changePage(page: number) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const {
      makeRequest,
      httpApi,
      results: { jsonQuery },
      isFetching,
      config,
      logger,
    } = state;

    if (isFetching) {
      return undefined;
    }

    dispatch(pageChangeStarted());

    const mutatedJsonQuery = jq(jsonQuery).setPageNumber(page);
    const isAdditionalTop3Disabled = config.get('serp.isAdditionalTop3Enabled') !== 'true';

    return fetchOffers({ httpApi, logger }, { jsonQuery: mutatedJsonQuery }).then(data => {
      if (!isJsonQueryRedirectOffers(data)) {
        /**
         * Сохранение текущего поиска для истории поисков
         */
        saveHistorySearch(data.seoData.h1, data.fullUrl, data.jsonQuery);

        const suggestions = data.suggestOffersSerializedList;

        data.offersSerialized = cutDescriptionFromOffers(data.offersSerialized, state.userAgent);

        if (suggestions) {
          data.suggestOffersSerializedList = cutDescriptionFromOffers(suggestions, state.userAgent);
        }

        // Обработка объявлений
        offersDecorator(data.offersSerialized, isAdditionalTop3Disabled);

        return convertJsonQueriesToSeoUrls(makeRequest, data.jsonQuery)
          .then((paginationUrls: string[]) => {
            return dispatch(pageChangeFinished(data, paginationUrls));
          })
          .then(() => {
            const newState = getState();

            try {
              const url = parseURI(data.fullUrl);
              const path = `${url.path}${url.query}`;

              window.history.pushState({}, document.title, path);
            } catch (error) {
              logger.warning(error, {
                description: 'Проблема парсинга урла выдачи',
                degradation: 'Ссылка в строке поиска не поменялась при пагинации',
              });
            }

            const trackingLocation = getTrackingLocation(newState.filters.currentLocation, newState.filters.regions);

            trackPageView(
              {
                filters: newState.filters,
                results: newState.results,
                breadcrumbs: data.breadcrumbs,
                user: newState.user,
                userGALayerData: newState.userGADataLayerData,
                profileSessionKey: newState.profileSessionKey,
                fbRegion: trackingLocation.fbRegion,
                fbCity: trackingLocation.fbCity,
                /*
                  При пагинации берем сгенерированный uuid поиска из стора, даже
                  если в search-offers-desktop сгенерировался новый uuid
                */
                searchGuid: newState.searchUuid,
                mlRankingGuid: newState.mlRankingGuid,
                mlRankingModelVersion: newState.mlRankingModelVersion,
                searchRequestId: newState.searchRequestId,
              },
              { config },
            );
          });
      }

      return Promise.reject(null);
    });
  };
}

export function paginationReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case FILTERS_PAGINATION_PAGE_CHANGE_STARTED:
      return {
        ...state,
        isFetching: true,
      };

    case FILTERS_PAGINATION_PAGE_CHANGE_FINISHED:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...action.data.jsonQuery,
            // TODO: CD-11057
            region: action.data.jsonQuery.region || {
              type: 'terms',
              value: getRegionIds(state.filters.currentLocation),
            },
            page: undefined,
          },
          jsonQueryCount: undefined,
          jsonQueryCountRefresing: false,
        },
        results: {
          ...state.results,
          queryString: action.data.queryString,
          jsonQuery: action.data.jsonQuery,
          offers: action.data.offersSerialized,
          aggregatedOffers: action.data.aggregatedCount,
          totalOffers: action.data.offerCount,
          seo: action.data.seoData,
          fullUrl: action.data.fullUrl,
          paginationUrls: action.paginationUrls,
          suggestions: action.data.suggestOffersSerializedList,
          suggestionsQuery: action.data.suggestionsQuery,
        },
        breadcrumbs: action.data.breadcrumbs,
        isFetching: false,
        newbuildingAdPath: action.data.newbuildingAdPath,
        hideOffer: defaultHideOfferState,
        mlRankingGuid: action.data.mlRankingGuid,
        mlRankingModelVersion: action.data.mlRankingModelVersion,
        searchRequestId: action.data.searchRequestId,
      };

    default:
      return state;
  }
}
