import { equals } from 'ramda';

import { IJsonQueryCircle, TCoordinate } from 'shared/json_query';

import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

const IGeoCircleTagType = 'geo-circle';

export interface IGeoCircleTag {
  type: 'geo-circle';
  center: TCoordinate;
  radius: number;
  text: string;
}

export const isGeoCircleTag = (tag: TTag): tag is IGeoCircleTag => tag.type === IGeoCircleTagType;

export const geoCircleTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.geo && (jsonQuery.geo.value.filter(geo => geo.type === 'distance') as IJsonQueryCircle[]);

    let nextTags = tags.filter(
      tag =>
        tag.type !== IGeoCircleTagType ||
        (values && values.some(value => equals(value.center, tag.center) && value.radius === tag.radius)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IGeoCircleTag =>
              isGeoCircleTag(tag) && equals(value.center, tag.center) && value.radius === tag.radius,
            () => ({
              type: IGeoCircleTagType,
              center: value.center,
              radius: value.radius,
              text: value.name,
            }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
