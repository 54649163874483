import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IMultiIdTagType = 'multiId';

export interface IMultiIdTag {
  type: 'multiId';
  value: number;
}

function isMultiIdTag(tag: TTag): tag is IMultiIdTag {
  return tag.type === IMultiIdTagType;
}

export const multiIdTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.multi_id && jsonQuery.multi_id.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isMultiIdTag, () => ({ type: IMultiIdTagType, value }));
    } else {
      return ensureTagNotExists(tags, isMultiIdTag);
    }
  },
};
