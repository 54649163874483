import { IAdFoxBannerContext } from '@cian/adfox/build/react';
import { mergeStyles } from '@cian/utils';

import { Component } from 'react';

import { CloseBtn } from 'shared/common/components/close_button/index';

const style = require('./index.css');

export interface IVideoBannerProps {
  context: IAdFoxBannerContext;
}

interface IVideoBannerState {
  isPlay: boolean;
  isVisible: boolean;
}

export class VideoBanner extends Component<IVideoBannerProps, IVideoBannerState> {
  private video: HTMLVideoElement | null = null;

  public constructor(props: IVideoBannerProps) {
    super(props);

    this.state = { isPlay: true, isVisible: true };
  }

  public render() {
    if (!this.state.isVisible) {
      return null;
    }

    return (
      <div className={style['videoContainer']}>
        {!this.state.isPlay && (
          <div className={style['buttonsContainer']}>
            <div {...mergeStyles(style['button'], style['button--restart'])} onClick={() => this.replayHandler()}>
              Посмотреть еще раз
            </div>
            <div {...mergeStyles(style['button'], style['button--toSite'])} onClick={() => this.goToSiteHandler()}>
              Перейти на сайт
            </div>
          </div>
        )}
        <video
          autoPlay
          muted
          className={this.state.isPlay ? style['play'] : style['paused']}
          loop={false}
          ref={videoElement => (this.video = videoElement)}
          onEnded={this.videoEndHandler}
        >
          <source src={this.props.context.mp4} type="video/mp4" />
          <source src={this.props.context.webm} type="video/webm" />
        </video>
        <CloseBtn
          isTransparent
          className={style.closeBtn}
          onClick={() => {
            this.setState({ isVisible: false });
          }}
        />
      </div>
    );
  }

  private videoEndHandler = () => {
    this.setState({
      isPlay: false,
    });
  };

  private replayHandler() {
    if (this.video) {
      this.video.play();
      this.setState({
        isPlay: true,
      });
    }
  }

  private goToSiteHandler() {
    window.open(this.props.context.url);
  }
}
