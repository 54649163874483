import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const INewbuildingCompletionAfterTagType = 'advanced-doneYear-after';

export interface INewbuildingCompletionAfterTag {
  type: 'advanced-doneYear-after';
  value: number;
}

function isNewbuildingCompletionAfterTag(tag: TTag): tag is INewbuildingCompletionAfterTag {
  return tag.type === INewbuildingCompletionAfterTagType;
}

export const buildingDoneYearsTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.yeargte && jsonQuery.yeargte.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isNewbuildingCompletionAfterTag, () => ({
        type: INewbuildingCompletionAfterTagType,
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isNewbuildingCompletionAfterTag);
    }
  },
};
