import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IHasEquipmentTagType = 'advanced-has-equipment';

export interface IHasEquipmentTag {
  type: 'advanced-has-equipment';
  value: boolean;
}

function isHasEquipmentTag(tag: TTag): tag is IHasEquipmentTag {
  return tag.type === IHasEquipmentTagType;
}

export const hasEquipmentTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_equipment && jsonQuery.has_equipment.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isHasEquipmentTag, () => ({ type: IHasEquipmentTagType, value }));
    } else {
      return ensureTagNotExists(tags, isHasEquipmentTag);
    }
  },
};
