import { LinkButton } from '@cian/ui-kit/button';
import { IconArrowUpRight16 } from '@cian/ui-kit/icons';

import { isSuburban, offerTypeFromJsonQuery } from 'shared/utils/category';

import { IQuickLinksProps } from '../../QuickLinks';
import { trackBannerKpLinkClick, trackTrapKpLinkClick } from '../../utils/tracking';
import { QuickLinksBanner } from '../QuickLinksBanner';
import { QuickLinksTitle } from '../QuickLinksTitle';

import * as styles from './QuickLinksSuburbanTrap.css';

type TQuickLinksSuburbanTrapProps = Pick<
  IQuickLinksProps,
  'isCountrysideTrapEnabled' | 'countrysideLink' | 'jsonQuery'
>;

export function QuickLinksSuburbanTrap({
  isCountrysideTrapEnabled,
  countrysideLink,
  jsonQuery,
}: TQuickLinksSuburbanTrapProps) {
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const isSuburbanListing = isSuburban(offerType);

  if (!isSuburbanListing) {
    return null;
  }

  if (isCountrysideTrapEnabled) {
    return (
      <QuickLinksBanner hasLeftBorder>
        <QuickLinksTitle>
          Дома и участки{' '}
          <a className={styles['link']} href={countrysideLink} onClick={trackTrapKpLinkClick}>
            в посёлках <IconArrowUpRight16 color="primary_100" width="22" />
          </a>
        </QuickLinksTitle>
        <div className={styles['content']}>Здесь больше информации про застройщика и условия проживания.</div>
      </QuickLinksBanner>
    );
  }

  return (
    <QuickLinksBanner hasLeftBorder>
      <QuickLinksTitle>Дома и участки в коттеджных посёлках</QuickLinksTitle>
      <div className={styles['content']}>
        <LinkButton href={countrysideLink} size="XS" theme="fill_secondary" onClick={trackBannerKpLinkClick}>
          Посмотреть
        </LinkButton>
      </div>
    </QuickLinksBanner>
  );
}
