import { IAppState, TActions } from '../../../common/state';
import { EBuildingClassType, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TWarehouseBuildingClassTypeChangedActionType = 'filters/building_class/WAREHOUSE_BUILDING_CLASS_TYPE_CHANGED';
export const IWarehouseBuildingClassTypeChangedActionType =
  'filters/building_class/WAREHOUSE_BUILDING_CLASS_TYPE_CHANGED' as TWarehouseBuildingClassTypeChangedActionType;

export interface IWarehouseBuildingClassTypeChangedAction {
  type: 'filters/building_class/WAREHOUSE_BUILDING_CLASS_TYPE_CHANGED';
  paramType: EBuildingClassType;
  value: boolean;
}

export const changeWarehouseBuildingClass = getParamChangedAction<
  EBuildingClassType,
  TWarehouseBuildingClassTypeChangedActionType
>(IWarehouseBuildingClassTypeChangedActionType);

export function warehouseBuildingClassTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IWarehouseBuildingClassTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setWarehouseBuildingClassType(action.paramType)
            : jq(state.filters.jsonQuery).unsetWarehouseBuildingClassType(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-warehouseBuildingClass') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetWarehouseBuildingClassType(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetWarehouseBuildingClassTypes(),
        },
      };

    default:
      return state;
  }
}
