import { EBuildingClassType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBuildingClassTypesTagType = 'advanced-buildingClass';

export interface IBuildingClassTypesTag {
  type: 'advanced-buildingClass';
  value: EBuildingClassType;
}

const isBuildingClassTypesTag = (tag: TTag): tag is IBuildingClassTypesTag => tag.type === IBuildingClassTypesTagType;

export const buildingClassTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.building_class_type && jsonQuery.building_class_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IBuildingClassTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EBuildingClassType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IBuildingClassTypesTag => isBuildingClassTypesTag(tag) && tag.value === value,
            () => ({ type: IBuildingClassTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
