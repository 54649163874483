/* eslint-disable max-lines */
import { Button, CustomTooltip, IconMore16, IconPin16, IconWarningSign16, UIText2 } from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import cn from 'classnames';
import * as PropTypes from 'prop-types';
import { Component, createRef } from 'react';

import { IComplaintsHelpers } from '../../../../serp/components/offers/commercial_card';
import { CommercialCardAPI } from '../../CommercialCard';
import { IOfferHelper } from '../../presenter';
import { IOffer } from '../../types/offer';
import { IUser } from '../../types/user';
import { ILoginMotivationPopupAPI, LoginMotivationPopup } from '../login_motivation_popup';
import { HideButton } from '../menu/hide_button';
import { LikeButton } from '../menu/like';
import { ReportPopup } from '../menu/more/report_popup';
import { IMoreButtonTracking } from '../menu/more/tracking';
import { Phone } from '../phone';
import { PushSubscription } from '../push_subscription';

import { IButtonsTracking } from './tracking';

import * as styles from './index.css';

export interface IButtonsProps {
  position: number;
  containerClassName?: string;
  buttonClassName?: string;
  dynamicButtonClassName?: string;
  isCallbackVisible?: boolean;
  isMapActive: boolean;
  isComplainOpen: boolean;
  isSimplified?: boolean;
  isPhoneHidden?: boolean;
  offer: IOffer;
  isSimilar: boolean;
  showPushNotification?: boolean;
  isHideOfferFetching?: boolean;
  isMoreInfoHidden?: boolean;
  complaintsHelpers: IComplaintsHelpers;
  isElectronicTrading: boolean;

  onFavoriteChange(isFavorite: boolean): void;
  onComplainClose(): void;
  onCommentClick?(): void;
  onCallbackClick?(): void;
  onMapButtonClick?(): void;
  onHideFromPrintClicked?(): void;
  onIdenticalOffersClick?(): void;
}

export interface IButtonsAPI {
  onPopupMoreOpen(offerid: number): void;
  onPopupReportOpen(offerid: number): void;
  onPopupMoreClose(offerid: number): void;
  onPopupReportClose(offerid: number): void;
  onHideOffer(): void;
}

export interface IButtonsContext {
  api: {
    card: CommercialCardAPI;
    buttons: IButtonsAPI;
    loginMotivationPopup: ILoginMotivationPopupAPI;
    tracking: IButtonsTracking & IMoreButtonTracking;
  };
  offerHelper: IOfferHelper;
  offer: IOffer;
  user: IUser;
}

export interface IButtonsState {
  isReportActive: boolean;
  isMorePopupOpen: boolean;
  isPushSubscriptionPopupOpen: boolean;
}

// Рендерит кнопки внизу карточки
/* istanbul ignore next */
export class Buttons extends Component<IButtonsProps, IButtonsState> {
  public complaintButtonRef = createRef<HTMLDivElement>();
  public moreButtonRef = createRef<HTMLDivElement>();
  public context: IButtonsContext;

  public static contextTypes: Record<keyof Buttons['context'], PropTypes.Requireable<unknown>> = {
    api: PropTypes.object,
    offer: PropTypes.object,
    offerHelper: PropTypes.object,
    user: PropTypes.object,
  };

  public constructor(props: IButtonsProps) {
    super(props);

    this.state = {
      isMorePopupOpen: false,
      isPushSubscriptionPopupOpen: false,
      isReportActive: false,
    };
  }

  public componentDidUpdate(prevProps: IButtonsProps, nextState: IButtonsState) {
    const { tracking } = this.context.api;

    if (prevProps.isMapActive !== this.props.isMapActive && tracking.trackMapOpened && tracking.trackMapClosed) {
      if (prevProps.isMapActive) {
        tracking.trackMapOpened();
      } else {
        tracking.trackMapClosed();
      }
    }

    if (nextState.isMorePopupOpen !== this.state.isMorePopupOpen) {
      if (nextState.isMorePopupOpen) {
        this.context.api.buttons.onPopupMoreOpen(prevProps.offer.id);
      } else {
        this.context.api.buttons.onPopupMoreClose(prevProps.offer.id);
      }
    }

    if (nextState.isReportActive !== this.state.isReportActive) {
      if (nextState.isReportActive) {
        this.context.api.buttons.onPopupReportOpen(prevProps.offer.id);
      } else {
        this.context.api.buttons.onPopupReportClose(prevProps.offer.id);
      }
    }
  }

  public UNSAFE_componentWillReceiveProps(nextProps: IButtonsProps) {
    if (!this.state.isReportActive && nextProps.isComplainOpen) {
      this.setState({
        isReportActive: nextProps.isComplainOpen,
      });
    }
  }

  public render() {
    const { containerClassName } = this.props;

    return (
      <div className={containerClassName || ''} onClick={e => e.stopPropagation()}>
        {this.renderPhoneButton()}
        {this.renderLikeButton()}
        {this.renderReport()}
        {this.renderIdenticalOffersButton()}
        {this.renderMap()}
        {this.renderHideButton()}
        {this.renderMore()}
      </div>
    );
  }

  private handlePhoneOpen = () => {
    const { tracking, card } = this.context.api;

    if (tracking && tracking.trackPhoneOpened) {
      tracking.trackPhoneOpened();
    } else if (card && card.onPhoneOpened) {
      card.onPhoneOpened();
    }
  };

  protected handleFavoriteClick = (value: boolean) => {
    const { onFavoriteChange } = this.props;

    onFavoriteChange(value);

    this.setState({ isPushSubscriptionPopupOpen: value });
  };

  protected handleMoreClick = () => {
    this.setState({
      isMorePopupOpen: !this.state.isMorePopupOpen,
    });
  };

  protected handleMorePopupClose = () => {
    this.setState({
      isMorePopupOpen: false,
    });
  };

  private handleMapButtonClick = () => {
    const onMapButtonClick = this.props.onMapButtonClick;

    if (onMapButtonClick) {
      onMapButtonClick();
    }
  };

  private handleHideFromPrintClick = () => {
    const onHideFromPrintClicked = this.props.onHideFromPrintClicked;
    const { tracking } = this.context.api;

    if (tracking && tracking.trackHidePrintButtonClick) {
      tracking.trackHidePrintButtonClick();
    }

    if (onHideFromPrintClicked) {
      onHideFromPrintClicked();
    }
  };

  protected renderPhoneButton() {
    if (this.props.isPhoneHidden || this.props.isElectronicTrading) {
      return null;
    }

    const { offer, buttonClassName, isSimplified } = this.props;
    const phone = this.context.offerHelper.presenters.getPhone();

    return (
      phone && (
        <div className={buttonClassName || ''}>
          <Phone
            isSimplified={isSimplified}
            offer={offer}
            phones={[phone]}
            workTimeInfo={offer.workTimeInfo}
            onPhoneOpened={this.handlePhoneOpen}
          />
        </div>
      )
    );
  }

  protected renderReport() {
    const {
      buttonClassName,
      complaintsHelpers: { sendComplaintFeedback, statusChanger, complaintsFormStatus },
    } = this.props;
    const { user } = this.context;
    const { isReportActive } = this.state;

    return (
      <div className={cn(buttonClassName, isReportActive && styles['button-active'])}>
        <div title="Пожаловаться на объявление">
          <Button
            beforeIcon={
              <span className={styles['report-button']}>
                <IconWarningSign16 />
                <span className={styles['report-button-text']}>
                  <UIText2 color="black_100" fontWeight="700">
                    Пожаловаться
                  </UIText2>
                </span>
              </span>
            }
            size="XS"
            theme="stroke_secondary"
            onClick={this.handleReportButtonClick}
          />
        </div>
        {isReportActive && (
          <div {...mergeStyles(styles['popup-container'], styles['popup-container--big'])}>
            <ReportPopup
              complaintsFormStatus={complaintsFormStatus}
              open={isReportActive}
              popupRef={this.complaintButtonRef}
              sendComplaintFeedback={sendComplaintFeedback}
              statusChanger={statusChanger}
              userEmail={user && user.isAuthenticated ? user.email : ''}
              onClose={this.handleReportClose}
            />
          </div>
        )}
      </div>
    );
  }

  protected renderIdenticalOffersButton() {
    const { buttonClassName, onIdenticalOffersClick } = this.props;
    const {
      offer: { identicalOffers },
    } = this.context;

    if (!identicalOffers || !identicalOffers.items.length) {
      return null;
    }

    const { title, items } = identicalOffers;

    return (
      <div className={buttonClassName || ''} title={title}>
        <Button
          beforeIcon={
            <>
              <span className={styles['identical-offers-count']}>{items.length}</span>
              <span className={styles['identical-offers-title']}>{title}</span>
            </>
          }
          size="XS"
          theme="stroke_secondary"
          onClick={onIdenticalOffersClick}
        />
      </div>
    );
  }

  protected renderMore() {
    const { buttonClassName, dynamicButtonClassName } = this.props;
    const { isMorePopupOpen } = this.state;

    return (
      <div className={cn(buttonClassName, isMorePopupOpen && styles['button-active'])} ref={this.moreButtonRef}>
        <span className={cn(this.isButtonHidden() && dynamicButtonClassName)}>
          <div title="Ещё">
            <Button beforeIcon={<IconMore16 />} size="XS" theme="stroke_secondary" onClick={this.handleMoreClick} />
          </div>
        </span>
        {this.renderMorePopup()}
      </div>
    );
  }

  private handleDownloadPDFClick = () => {
    const { tracking } = this.context.api;

    if (tracking && tracking.trackPDFLinkClicked) {
      tracking.trackPDFLinkClicked();
    }
    this.handleMorePopupClose();
  };

  private handleDownloadDOCClick = () => {
    const { tracking } = this.context.api;

    if (tracking && tracking.trackDocLinkClicked) {
      tracking.trackDocLinkClicked();
    }
    this.handleMorePopupClose();
  };

  protected renderMap() {
    const { buttonClassName, isMapActive, dynamicButtonClassName } = this.props;
    const title = isMapActive ? 'Скрыть карту' : 'Показать на карте';

    return (
      <div className={cn(buttonClassName, isMapActive && styles['button-active'])} title={title}>
        <span className={cn(this.isButtonHidden() && dynamicButtonClassName)}>
          <Button beforeIcon={<IconPin16 />} size="XS" theme="stroke_secondary" onClick={this.handleMapButtonClick} />
        </span>
      </div>
    );
  }

  protected handleReportClose = () => {
    this.props.onComplainClose();

    this.setState({
      isReportActive: false,
    });
  };

  protected handleReportButtonClick = () => {
    const { tracking } = this.context.api;

    if (tracking && tracking.trackReportPopupOpened) {
      tracking.trackReportPopupOpened();
    }
    this.setState({
      isReportActive: true,
    });
  };

  protected renderLikeButton() {
    const { loginMotivationPopup } = this.context.api;
    const { offer, buttonClassName } = this.props;
    const { isPushSubscriptionPopupOpen } = this.state;

    return (
      <div {...mergeStyles([buttonClassName || '', styles['like-container']])}>
        <LikeButton isActive={!!offer.isFavorite} toggleActivity={this.handleFavoriteClick} />
        <LoginMotivationPopup id={offer.id} {...loginMotivationPopup} />
        <PushSubscription isOpen={isPushSubscriptionPopupOpen} />
      </div>
    );
  }

  protected renderHideButton() {
    const {
      api: { buttons },
    } = this.context;
    const { buttonClassName, dynamicButtonClassName, isHideOfferFetching } = this.props;

    return (
      <div className={buttonClassName || ''}>
        <HideButton
          {...mergeStyles(this.isButtonHidden() && dynamicButtonClassName)}
          hideOffer={buttons.onHideOffer}
          isFetching={isHideOfferFetching}
        />
      </div>
    );
  }

  private handleCommentButtonClick = () => {
    const onCommentClick = this.props.onCommentClick;
    const { tracking } = this.context.api;

    if (tracking && tracking.trackAddCommentClick) {
      tracking.trackAddCommentClick();
    }

    if (onCommentClick) {
      onCommentClick();
    }

    this.handleMorePopupClose();
  };

  protected isButtonHidden() {
    const { isMorePopupOpen, isReportActive } = this.state;
    const { isMapActive } = this.props;

    return !isMorePopupOpen && !isReportActive && !isMapActive;
  }

  protected renderMorePopup() {
    const { offer } = this.props;
    const { isMorePopupOpen } = this.state;

    return (
      <CustomTooltip
        anchorRef={this.moreButtonRef}
        arrow={true}
        content={
          <ul className={styles['more-list']}>
            <li className={styles['more-item']}>
              <div onClick={this.handleCommentButtonClick}>Добавить комментарий</div>
            </li>
            <li className={styles['more-item']}>
              <div onClick={this.handleHideFromPrintClick}>Не показывать при печати</div>
            </li>
            <li className={styles['more-item']}>
              <a
                className={styles['more-link']}
                href={offer.fullUrl.replace('.ru/', '.ru/export/pdf/')}
                rel="noreferrer"
                target="_blank"
                onClick={this.handleDownloadPDFClick}
              >
                Скачать PDF
              </a>
            </li>
            <li className={styles['more-item']}>
              <a
                className={styles['more-link']}
                href={offer.fullUrl.replace('.ru/', '.ru/export/docx/')}
                rel="noreferrer"
                target="_blank"
                onClick={this.handleDownloadDOCClick}
              >
                Скачать DOC
              </a>
            </li>
          </ul>
        }
        flip={false}
        open={isMorePopupOpen}
        outside={true}
        placement="bottom-end"
        theme="white"
        onClose={this.handleMorePopupClose}
      />
    );
  }
}
