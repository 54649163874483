/**
 * @todo Удалить файл если эксперимент не заапрувится
 * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
 */
import { IAppState, TActions } from '../../../common/state';

export interface IUserProfilingOpenedAction {
  type: 'modals/user_profiling/MODAL_OPENED';
}

export interface IUserProfilingClosedAction {
  type: 'modals/user_profiling/MODAL_CLOSED';
}

export function openUserProfilingModal(): IUserProfilingOpenedAction {
  return {
    type: 'modals/user_profiling/MODAL_OPENED',
  };
}

export function closeUserProfilingModal(): IUserProfilingClosedAction {
  return {
    type: 'modals/user_profiling/MODAL_CLOSED',
  };
}

export function toggleUserProfilingModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'modals/user_profiling/MODAL_OPENED':
      return {
        ...state,
        isUserProfilingModalVisible: true,
      };
    case 'modals/user_profiling/MODAL_CLOSED':
      return {
        ...state,
        isUserProfilingModalVisible: false,
      };
    default:
      return state;
  }
}
