import { LinkButton } from '@cian/ui-kit/button';

import { compose } from 'ramda';
import { connect } from 'react-redux';

import { IAppState } from '../../../common/state';
import { canModerateAnnouncements } from '../../../user';
import { withEmptyRendering } from '../../../utils/hoc/withEmptyRendering';

export interface IModerateLinkButtonProps {
  userCanModerateAnnouncements: boolean;
  href: string;
}

export function ModerateLinkButton({ href }: IModerateLinkButtonProps) {
  return (
    <LinkButton href={href} size="XS" target="_blank" theme="fill_error_secondary">
      Проверять
    </LinkButton>
  );
}

export function mapStateToProps(state: IAppState): IModerateLinkButtonProps {
  const url = `https://cian.ru/cat.php?${state.results.queryString}`;

  return {
    userCanModerateAnnouncements: canModerateAnnouncements(state.user),
    href:
      `https://service.cian.ru/moderate-announcements/index` +
      `?QueryStringFilter.DoNotUseOtherFilters=true` +
      `&QueryStringFilter.SearchURL=${encodeURIComponent(url)}`,
  };
}

export function shouldHideContainer(props: IModerateLinkButtonProps): boolean {
  return !props.userCanModerateAnnouncements;
}

export const ModerateLinkButtonContainer = compose(
  connect(mapStateToProps),
  withEmptyRendering(shouldHideContainer),
)(ModerateLinkButton);
