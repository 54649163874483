import { EMessageResponseTypes, PopupManager, PushManager } from '@cian/push-manager';
import { ISaveSearchProps } from '@cian/save-search-widget';

import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from '../../../common/state';
import { IJsonQuery } from '../../../json_query';
import { useApplicationContext } from '../../../serp/containers/ApplicationContext';
import { TUser } from '../../../types/user';
import { ISaveSearchModalProps, SaveSearchModal } from '../../components/SaveSearchModal';

import { trackOpen, trackSubscribed } from './tracking';

type TSaveSearchModalContainerProps = Pick<ISaveSearchModalProps, 'open' | 'onClose'>;

export const SaveSearchModalContainer: FC<TSaveSearchModalContainerProps> = sharedProps => {
  const { open: isOpen } = sharedProps;
  const { config, logger, httpApi } = useApplicationContext();
  const pushBrowserUrl = config.get('push.browserUrl') as string;
  const applicationServerKey = config.get<string>('applicationServerKey') || '';
  const pushManager = useRef<PushManager>();
  const currentSubdomain = useSelector<IAppState, string>(state => state.currentSubdomain);
  const jsonQuery = useSelector<IAppState, IJsonQuery>(state => state.filters.jsonQuery);
  const title = useSelector<IAppState, string | null>(state => state.results.seo.h1);
  const user = useSelector<IAppState, TUser>(state => state.user);
  const [pushPermission, setPushPermission] = useState<NotificationPermission>('default');
  const [isSubscribed, setIsSubscribed] = useState(false);

  const handlePushSubscribe = useCallback(async () => {
    if (currentSubdomain !== 'www') {
      if (pushManager.current) {
        pushManager.current.showPopup({
          baseUrl: 'web-push-subscribe-popup',
          trackingLabel: 'From_cat_save',
        });
      }
    } else if (applicationServerKey) {
      const popup = new PopupManager({
        workerSrc: '/push-worker.js',
        applicationServerKey,
        httpApi,
      });

      try {
        await popup.register();
      } catch (error) {
        return;
      }

      try {
        await popup.subscribe();
      } catch (error) {
        return;
      }
    }
  }, [applicationServerKey, currentSubdomain, httpApi]);

  const handleSubscribed = useCallback(
    (parameters: Parameters<Required<ISaveSearchProps>['onSubscribed']>[0]) => {
      trackSubscribed({
        jsonQuery,
        subscribeToPushAvailable: !isSubscribed && pushPermission !== 'denied',
        ...parameters,
      });

      if (window.__reloadHeader__) {
        window.__reloadHeader__();
      }
    },
    [isSubscribed, jsonQuery, pushPermission],
  );

  const handlePushManagerMessage = useCallback((e: MessageEvent) => {
    try {
      const data = JSON.parse(e.data);

      if (data.type === EMessageResponseTypes.subscriptionStatus && !data.value) {
        setIsSubscribed(data.value);
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }, []);

  useEffect(() => {
    if (isOpen) {
      pushManager.current = new PushManager({
        baseUrl: pushBrowserUrl,
        hasSubdomain: currentSubdomain !== 'www',
      });

      pushManager.current.registerWorker();
      PushManager.ready().then(PushManager.checkSubscription);

      if ('Notification' in window && currentSubdomain === 'www') {
        setPushPermission(Notification.permission);
      }

      window.addEventListener('message', handlePushManagerMessage);

      trackOpen();
    }

    return () => window.removeEventListener('message', handlePushManagerMessage);
  }, [isOpen]);

  return (
    <SaveSearchModal
      {...sharedProps}
      hideLicense={user.isAuthenticated}
      httpApi={httpApi}
      jsonQuery={jsonQuery}
      logger={logger}
      subdomain={currentSubdomain}
      subscribeToPushAvailable={!isSubscribed && pushPermission !== 'denied'}
      title={title}
      userEmail={user.isAuthenticated ? user.email : null}
      onPushSubscribe={handlePushSubscribe}
      onSubscribed={handleSubscribed}
    />
  );
};
