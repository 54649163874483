import * as React from 'react';

export interface IIconWhitelistProps {
  isActive?: boolean;
}

export const IconWhitelist: React.FC<IIconWhitelistProps> = ({ isActive }) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 .5c4.137 0 7.5 3.36 7.5 7.5s-3.363 7.5-7.5 7.5S.5 12.14.5 8 3.863.5 8 .5z"
        fill="none"
        stroke="#2E9E00"
      />
      {isActive && (
        <path
          d="M8 .5C3.863.5.5 3.86.5 8s3.363 7.5 7.5 7.5 7.5-3.36 7.5-7.5S12.137.5 8 .5zm1.326 6.122c0-.48.375-.855.855-.856h.01c.48 0 .855.375.855.856.006.467-.367.85-.834.856h-.02c-.465.006-.85-.367-.854-.834v-.022zm-4.6 0c0-.48.374-.855.855-.856h.01c.48 0 .856.375.856.856.006.467-.367.85-.834.856h-.02c-.465.006-.85-.367-.854-.834v-.022zm7.055 4.33c-.05.03-1.73 1.15-3.9 1.15-2.11 0-3.66-1.124-3.71-1.178l-.003.003c-.214-.16-.27-.508-.162-.722.19-.24.51-.294.75-.107.028 0 1.338.964 3.104.964 1.845 0 3.29-.99 3.317-.99.24-.187.59-.107.75.133.186.24.106.586-.134.746z"
          fill="#2E9E00"
        />
      )}
      {!isActive && (
        <path
          d="M4.162 10.924c.053.054 1.604 1.177 3.717 1.177 2.16 0 3.85-1.12 3.9-1.15.24-.16.32-.5.13-.74-.16-.24-.51-.32-.75-.13-.03 0-1.47.99-3.32.99-1.768 0-3.078-.962-3.105-.962-.24-.187-.56-.133-.75.107-.106.214-.05.56.162.72l.004-.002zM5.58 5.766c.48 0 .855.375.855.856.006.467-.367.85-.834.856h-.02c-.46.006-.85-.367-.85-.834v-.022c0-.48.38-.856.86-.856h-.01zm4.6 0c.48 0 .855.375.855.856.006.467-.367.85-.834.856h-.02c-.46.006-.85-.367-.85-.834v-.022c0-.48.38-.856.86-.856z"
          fill="#2E9E00"
        />
      )}
    </svg>
  );
};

export interface IIconBlacklistProps {
  isActive?: boolean;
}

export const IconBlacklist: React.FC<IIconBlacklistProps> = ({ isActive }) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 .5c4.137 0 7.5 3.36 7.5 7.5s-3.363 7.5-7.5 7.5S.5 12.14.5 8 3.863.5 8 .5z"
        fill="none"
        stroke="#F00"
      />
      {isActive && (
        <path
          d="M8 .5C3.863.5.5 3.86.5 8s3.363 7.5 7.5 7.5c4.137 0 7.5-3.36 7.5-7.5S12.137.5 8 .5zm1.346 6.122c0-.48.375-.855.855-.856h.01c.48 0 .855.375.855.856.006.467-.367.85-.834.856h-.02c-.465.006-.85-.367-.854-.834v-.022zm-4.6 0c0-.48.374-.855.856-.856H5.6c.48 0 .855.375.855.856.006.467-.367.85-.834.856H5.6c-.465.006-.85-.367-.854-.834v-.022zm7.188 5.243c-.16.24-.508.32-.748.133-.028 0-1.472-.99-3.318-.99-1.766 0-3.076.963-3.103.963-.24.19-.562.14-.75-.1-.106-.21-.052-.56.162-.72h.005c.053-.055 1.604-1.18 3.718-1.18 2.16 0 3.85 1.125 3.9 1.15.24.16.32.51.13.75z"
          fill="#F00"
        />
      )}
      {!isActive && (
        <path
          d="M4.182 11.142c.053-.054 1.604-1.177 3.717-1.177 2.16 0 3.85 1.123 3.9 1.15.24.16.32.508.13.75-.16.24-.51.32-.75.133-.03 0-1.47-.99-3.32-.99-1.768 0-3.078.963-3.105.963-.24.19-.56.14-.75-.1-.106-.21-.05-.56.162-.72h.004zM5.6 5.766c.48 0 .855.375.855.856.006.467-.367.85-.834.856H5.6c-.465.006-.85-.367-.854-.834v-.022c0-.48.375-.856.857-.856H5.6zm4.6 0c.48 0 .855.375.855.856.006.467-.367.85-.834.856h-.02c-.46.006-.85-.367-.85-.834v-.022c0-.48.38-.856.86-.856h-.01z"
          fill="#F00"
        />
      )}
    </svg>
  );
};
