/**
 * Осторожно! Очень дикая логика!
 * Не править без утверждения Шамиля Якупова (@shamil).
 */
import { mergeStyles } from '@cian/utils/lib/shared/style';

import * as React from 'react';

const style = require('./index.css');

export interface ISliderProps extends Readonly<{ children?: React.ReactNode }> {
  defaultIndex?: number;

  onSliderInitialized?(): void;
  onSlideClick?(index: number): void;
  onSlideChange?(index: number): void;
}

export interface ISliderState {
  touched: boolean;
  slides: React.ReactNode[];
  currentIndex: number;
  currentPos: number;
  currentOffset: number;
  currentView: number[];
}

export class Slider extends React.Component<ISliderProps, ISliderState> {
  public static defaultProps = {
    defaultIndex: 0,
  } as ISliderProps;

  public constructor(props: ISliderProps) {
    super(props);

    const slides = React.Children.toArray(props.children);

    let defaultIndex = this.props.defaultIndex as number;
    if (defaultIndex < 0 || defaultIndex > slides.length - 1) {
      defaultIndex = 0;
    }

    this.state = {
      currentIndex: defaultIndex,
      currentOffset: 0,
      currentPos: 1,
      currentView: getDefaultView(slides.length, defaultIndex),
      slides,
      touched: false,
    };
  }

  public get currentIndex() {
    return this.state.currentIndex;
  }

  public UNSAFE_componentWillReceiveProps(nextProps: ISliderProps) {
    if (this.props.children !== nextProps.children) {
      const slides = React.Children.toArray(nextProps.children);

      let defaultIndex = nextProps.defaultIndex as number;
      if (defaultIndex < 0 || defaultIndex > slides.length - 1) {
        defaultIndex = 0;
      }

      this.setState({
        currentIndex: defaultIndex,
        currentOffset: 0,
        currentPos: 1,
        currentView: getDefaultView(slides.length, defaultIndex),
        slides,
      });
    }
  }

  public componentDidMount() {
    if (this.props.onSliderInitialized) {
      this.props.onSliderInitialized();
    }
  }

  public prev() {
    if (!this.state.touched) {
      this.setState(
        {
          touched: true,
        },
        this.prev,
      );
    } else {
      const { slides, currentPos, currentOffset, currentView } = this.state;
      const nextIndex = currentView[currentPos - 1];
      const nextOffset = currentOffset - 1;
      let nextView;

      if (currentView.length < slides.length) {
        if (currentPos - 1 > 0) {
          nextView = currentView;
        } else {
          const firstSlide = currentView[0];
          nextView = [firstSlide > 0 ? firstSlide - 1 : slides.length - 1].concat(currentView);
        }
      } else if (slides.length < 3 && currentView.length < 4) {
        nextView = [currentView[1]].concat(currentView);
      } else {
        nextView = [currentView[currentView.length - 1]].concat(currentView.slice(0, currentView.length - 1));
      }

      this.setState(
        {
          currentIndex: nextIndex,
          currentOffset: nextOffset,
          currentPos: currentPos - 1 > 0 ? currentPos - 1 : 1,
          currentView: nextView,
        },
        () => {
          if (this.props.onSlideChange) {
            this.props.onSlideChange(nextIndex);
          }
        },
      );
    }
  }

  public next() {
    if (!this.state.touched) {
      this.setState(
        {
          touched: true,
        },
        this.next,
      );
    } else {
      const { slides, currentPos, currentOffset, currentView } = this.state;
      const nextIndex = currentView[currentPos + 1];
      const nextOffset = currentOffset + 1;

      let nextView;

      if (currentView.length < slides.length) {
        if (currentPos + 1 < currentView.length - 1) {
          nextView = currentView;
        } else {
          const lastSlide = currentView[currentView.length - 1];
          nextView = currentView.concat(lastSlide < slides.length - 1 ? lastSlide + 1 : 0);
        }
      } else if (slides.length < 3 && currentView.length < 4) {
        nextView = currentView.concat(currentView[1]);
      } else {
        nextView = currentView.slice(1).concat(currentView[0]);
      }

      this.setState(
        {
          currentIndex: nextIndex,
          currentOffset: nextOffset,
          currentPos: currentPos + 1 < nextView.length - 1 ? currentPos + 1 : nextView.length - 2,
          currentView: nextView,
        },
        () => {
          if (this.props.onSlideChange) {
            this.props.onSlideChange(nextIndex);
          }
        },
      );
    }
  }

  public render() {
    return <div className={style['container']}>{this.renderSlider()}</div>;
  }

  private renderSlider() {
    const { slides } = this.state;

    if (slides.length === 0) {
      return null;
    }

    const sliderStyles: React.CSSProperties = {};
    if (this.state.touched) {
      const { currentPos } = this.state;
      sliderStyles.transform = `translateX(${-(currentPos + this.getOffset()) * 100}%)`;
    }

    return (
      <div
        {...mergeStyles(style['slides'], this.state.currentOffset === 0 && style['slides--noTransform'])}
        style={sliderStyles}
        onTransitionEnd={this.handleSlidesTransitionEnd}
      >
        {this.renderSlides()}
      </div>
    );
  }

  private renderSlides() {
    const { touched, slides, currentIndex, currentView } = this.state;

    if (slides.length === 0) {
      return null;
    }

    if (!touched) {
      return (
        <div className={style['slide']} onClick={() => this.onSlideClick(currentIndex)}>
          {slides[currentIndex]}
        </div>
      );
    }

    return currentView.map((slide, index) => {
      return (
        <div
          className={style['slide']}
          key={slides.length >= 3 ? `slide_${slide}` : `slide_${index}_${slide}`}
          style={{ left: `${(index + this.getOffset()) * 100}%` }}
          onClick={() => this.props.onSlideClick && this.props.onSlideClick(slide)}
        >
          {slides[slide]}
        </div>
      );
    });
  }

  private onSlideClick = (index: number) => {
    if (this.props.onSlideClick && this.state.slides.length > 1) {
      this.props.onSlideClick(index);
    }
  };

  private getOffset = () => {
    const { slides, currentOffset } = this.state;

    if (currentOffset > 0) {
      if (currentOffset > slides.length - 1) {
        return currentOffset - (slides.length - 1);
      } else {
        return 0;
      }
    }

    return currentOffset;
  };

  private handleSlidesTransitionEnd = () => {
    const { currentIndex, slides } = this.state;

    this.setState({
      currentOffset: 0,
      currentPos: 1,
      currentView: getDefaultView(slides.length, currentIndex),
    });
  };
}

function getDefaultView(total: number, current: number) {
  return [current > 0 ? current - 1 : total - 1, current, current < total - 1 ? current + 1 : 0];
}
