import { NumberInput, TooltipDesktop } from '@cian/ui-kit';
import { getNearestUpperMultiple, keepValueInRange } from '@cian/utils';

import * as PropTypes from 'prop-types';
import { Component } from 'react';

import { CommercialCardContext__DEPRICATED } from '../../CommercialCard';
import { TAuctionBetValidatorResult, auctionBetValidator } from '../../utils/auctionBetValidator';

import * as styles from './AuctionBetInput.css';

export interface IAuctionBetInputProps {
  initialBet: number;
  stepBet: number;
  validateStep: boolean;
  disabled?: boolean;
  trackChangeBetClick?(): void;
  onChange(v: number, i: boolean): void;
  auctionBetCalculator(value: number | null): number;
}

export interface IAuctionBetInputState {
  bet: number;
  isBetValid: boolean;
  betErrorText?: TAuctionBetValidatorResult;
}

export class AuctionBetInput extends Component<IAuctionBetInputProps, IAuctionBetInputState> {
  public static contextTypes = {
    auctionMinBet: PropTypes.number,
    auctionMaxBet: PropTypes.number,
  };

  public constructor(props: IAuctionBetInputProps) {
    super(props);

    this.state = {
      bet: props.initialBet,
      isBetValid: true,
    };
  }

  public render() {
    const { auctionMaxBet = null } = this.context as CommercialCardContext__DEPRICATED;
    const { disabled, trackChangeBetClick, auctionBetCalculator } = this.props;
    const { bet, isBetValid, betErrorText } = this.state;

    const maxBetLength = String(auctionBetCalculator(auctionMaxBet)).length;

    return (
      <div className={styles['container']}>
        <TooltipDesktop
          arrow={true}
          open={Boolean(betErrorText && betErrorText.message)}
          placement="top-end"
          theme="black"
          title={betErrorText ? betErrorText.message : ''}
        >
          <NumberInput
            disabled={disabled}
            invalid={!isBetValid}
            maxLength={maxBetLength}
            rightAdornment={
              <span className={styles['bet_increment']} onClick={this.handleIncreaseBet}>
                +
              </span>
            }
            value={bet}
            onBlur={trackChangeBetClick}
            onChange={this.handleBetChange}
          />
        </TooltipDesktop>
        <span className={styles['input-currency']}>₽</span>
      </div>
    );
  }

  private handleIncreaseBet = () => {
    const { trackChangeBetClick, stepBet, validateStep, auctionBetCalculator } = this.props;
    const { auctionMaxBet = null } = this.context as CommercialCardContext__DEPRICATED;
    const { bet } = this.state;

    const newBet = validateStep ? getNearestUpperMultiple(bet + 1, stepBet) || 0 : bet + 1;

    this.handleBetChange(keepValueInRange(newBet, 0, auctionBetCalculator(auctionMaxBet)));

    if (trackChangeBetClick) {
      trackChangeBetClick();
    }
  };

  private handleBetChange = (value: string | number = 0) => {
    if (this.props.disabled) {
      return;
    }

    const bet = typeof value === 'string' ? this.processBetValue(value) : value;

    const { auctionMinBet = null, auctionMaxBet = null } = this.context as CommercialCardContext__DEPRICATED;
    const { stepBet, validateStep, auctionBetCalculator } = this.props;

    const betErrorText = auctionBetValidator(bet, auctionMinBet || 0, auctionBetCalculator(auctionMaxBet), stepBet, {
      isZeroValid: true,
      validateStep,
    });
    const isBetValid = !betErrorText;

    this.setState({ bet, isBetValid, betErrorText });

    this.props.onChange(bet, isBetValid);
  };

  private processBetValue = (value: string): number => {
    const normalizedValue = value.replace(/\D+/gi, '');

    return Number(normalizedValue);
  };
}
