import { TGetApplyNewBetDateModel } from './types';

const defaultConfig: TGetApplyNewBetDateModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'auction',
  pathApi: '/v1/get-apply-new-bet-date-by-announcement/',
};

export function createGetApplyNewBetDateModel(config?: Partial<TGetApplyNewBetDateModel>): TGetApplyNewBetDateModel {
  return {
    ...defaultConfig,
    ...config,
  };
}
