import { ca } from '@cian/analytics';
import { EFrequency } from '@cian/save-search-widget';

export function trackOpen() {
  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Open',
    label: 'From_cat_save',
  });
}

interface ITrackSubscribedParameters {
  jsonQuery: unknown;
  frequency: EFrequency;
  subscribeToNews: boolean;
  subscribeToPushAvailable: boolean;
  subscribeToPush: boolean;
}

const notificationFrequencyMap: { [key in EFrequency]: string } = {
  [EFrequency.Asap]: 'Asap',
  [EFrequency.Hourly]: 'Hourly',
  [EFrequency.Daily]: 'Daily',
  [EFrequency.Weekly]: 'Weekly',
};

export function trackSubscribed({
  jsonQuery,
  frequency,
  subscribeToNews,
  subscribeToPushAvailable,
  subscribeToPush,
}: ITrackSubscribedParameters) {
  let label = 'From_cat_save';

  label += `/${notificationFrequencyMap[frequency]}`;

  if (subscribeToPushAvailable) {
    const pushOnOff = subscribeToPush ? 'PushOn' : 'PushOff';

    label += `/${pushOnOff}`;
  }

  ca('event', {
    name: 'oldevent',
    category: 'subscription',
    action: 'Send',
    label,
  });

  if (subscribeToNews) {
    ca('eventSite', {
      name: 'oldevent',
      action: 'Send',
      category: 'subscription',
      label: 'news',
      sc: jsonQuery,
    });
  }
}
