import { Button } from '@cian/ui-kit/button';
import { numberToPrettyString } from '@cian/utils';

import { isCommercial, offerTypeFromJsonQuery } from 'shared/utils/category';

import { IQuickLinksProps } from '../../QuickLinks';
import { QuickLinksBanner } from '../QuickLinksBanner';
import { QuickLinksTitle } from '../QuickLinksTitle';

import * as styles from './QuickLinksCommercialCalc.css';

export const formatNumber = (num: number): string =>
  num >= 1000000 ? (num / 1000000).toFixed(2).split('.').join(',') : numberToPrettyString(num);

const currencyDict = {
  rur: '\u20bd',
  usd: '$',
  eur: '€',
};

const rentPriceTypeMap = ['за м\u00B2 в год', 'за месяц'];

export type TQuickLinksCommercialCalcProps = Pick<
  IQuickLinksProps,
  | 'avgPriceInformer'
  | 'jsonQuery'
  | 'dealType'
  | 'priceSm'
  | 'isOfficeCalculatorButtonVisible'
  | 'onOfficeCalculatorButtonClick'
>;

export function QuickLinksCommercialCalc({
  avgPriceInformer,
  jsonQuery,
  dealType,
  priceSm,
  isOfficeCalculatorButtonVisible,
  onOfficeCalculatorButtonClick,
}: TQuickLinksCommercialCalcProps) {
  const offerType = offerTypeFromJsonQuery(jsonQuery);
  const isCommercialListing = isCommercial(offerType);

  if (!isCommercialListing || !avgPriceInformer) {
    return null;
  }

  return (
    <QuickLinksBanner hasRightBorder>
      <QuickLinksTitle>Средняя цена в результатах поиска</QuickLinksTitle>
      <div className={styles['price']}>
        <span className={styles['value']}>{formatNumber(avgPriceInformer.price)}</span>
        <span className={styles['currency']}>
          {`${avgPriceInformer.price >= 1000000 ? ' млн ' : ' '}`}
          {currencyDict[avgPriceInformer.currency]}&nbsp;
          {dealType === 'commercialrent'
            ? priceSm
              ? rentPriceTypeMap[0]
              : rentPriceTypeMap[1]
            : priceSm
              ? 'за м\u00B2'
              : ''}
        </span>
      </div>
      {isOfficeCalculatorButtonVisible && (
        <Button size="XS" theme="fill_secondary" onClick={onOfficeCalculatorButtonClick}>
          Калькулятор офиса
        </Button>
      )}
    </QuickLinksBanner>
  );
}
