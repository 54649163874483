import * as React from 'react';

import { wrapDisplayName } from '../../displayName';

export function withEmptyRendering<T extends object>(validator: (props: T) => boolean) {
  return function wrap(Component: React.ComponentType<T>): React.ComponentType<T> {
    function Wrapper(props: T) {
      const isInvalid = validator(props);

      if (isInvalid) {
        return null;
      }

      return <Component {...props} />;
    }

    Wrapper.displayName = wrapDisplayName(Component, 'withEmptyRendering');

    return Wrapper;
  };
}
