import { mergeStyles } from '@cian/utils';

import { Component } from 'react';

import { IFastLink } from '../../../common/state/app_state';
import { IJsonQuery } from '../../../json_query';
import { getTotalPagesCount } from '../../../utils/pagination';

import { trackFastLinkClick, trackFastLinksView } from './tracking';

import * as styles from './index.css';

export interface IFastLinksProps {
  fastLinks: IFastLink[];
  aggregatedOffers: number;
  jsonQuery: IJsonQuery;
  offersPerPage: number;
}

export class FastLinks extends Component<IFastLinksProps> {
  public componentDidMount() {
    trackFastLinksView(this.getTrackingEventLabel());
  }

  public render() {
    const { fastLinks, aggregatedOffers, offersPerPage } = this.props;

    if (!fastLinks || !fastLinks.length) {
      return null;
    }

    const totalPages = getTotalPagesCount(offersPerPage, aggregatedOffers);

    return (
      <div className={styles['wrapper']}>
        <div {...mergeStyles(styles['container'], totalPages > 1 && styles['container-shifted'])}>
          <span className={styles['header']}>Вместе с этим также ищут:</span>
          {fastLinks.map(({ title, uri }, index) => (
            <a className={styles['link']} href={uri} key={index} onClick={this.handleClick}>
              {title}
            </a>
          ))}
        </div>
      </div>
    );
  }

  private handleClick = () => {
    trackFastLinkClick(this.getTrackingEventLabel());
  };

  private getTrackingEventLabel = (): string => {
    const { aggregatedOffers, offersPerPage } = this.props;

    return aggregatedOffers < offersPerPage + 1 ? 'withoutPagination' : 'withPagination';
  };
}
