import { IAppState, TActions } from '../../../common/state';
import { EMaxPrepay } from '../../../json_query';

const IMaxPrepayChangedActionType = 'filters/rent_terms/MAX_PREPAY_CHANGED';
const IWithoutDepositChangedActionType = 'filters/rent_terms/WITHOUT_DEPOSIT_CHANGED';

export interface IMaxPrepayChangedAction {
  type: 'filters/rent_terms/MAX_PREPAY_CHANGED';
  maxPrepay: EMaxPrepay;
}

export interface IWithoutDepositChangedAction {
  type: 'filters/rent_terms/WITHOUT_DEPOSIT_CHANGED';
  withoutDeposit: boolean;
}

export function changeMaxPrepay(maxPrepay: EMaxPrepay): IMaxPrepayChangedAction {
  return {
    type: IMaxPrepayChangedActionType,
    maxPrepay,
  };
}

export function changeWithoutDeposit(withoutDeposit: boolean): IWithoutDepositChangedAction {
  return {
    type: IWithoutDepositChangedActionType,
    withoutDeposit,
  };
}

export function rentTermsReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IMaxPrepayChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            prepay:
              action.maxPrepay !== EMaxPrepay.Any
                ? {
                    type: 'term',
                    value: action.maxPrepay,
                  }
                : undefined,
          },
        },
      };

    case IWithoutDepositChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            zalog: action.withoutDeposit
              ? {
                  type: 'term',
                  value: !action.withoutDeposit,
                }
              : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-rentTerms-maxPrepay') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              prepay: undefined,
            },
          },
        };
      } else if (action.tag.type === 'advanced-rentTerms-withoutDeposit') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              zalog: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            prepay: undefined,
            zalog: undefined,
          },
        },
      };

    default:
      return state;
  }
}
