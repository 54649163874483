import cn from 'classnames';
import { Component } from 'react';

import { IAddressPathItemTracking } from './tracking';

const style = require('./index.css');

export type TAddressPathItem = 'address' | 'metro';

interface IAddresPathProps {
  label: string;
  qs: string;
  type: TAddressPathItem;
  url: string | null | undefined;
  className?: string;
  trackAddressClick?: IAddressPathItemTracking['trackAddressClick'];
}

export class AddressPathItem extends Component<IAddresPathProps> {
  public render() {
    const { label, qs, url } = this.props;
    const href = url || `/cat.php?${qs}`;

    return (
      <a
        className={cn(style['item'], this.props.className)}
        href={href}
        key={`offer-card-${qs}`}
        rel="noreferrer"
        target="_blank"
        onClick={this.trackClickEvent}
      >
        {label}
      </a>
    );
  }

  private trackClickEvent = () => {
    const { trackAddressClick, type } = this.props;

    if (trackAddressClick) {
      trackAddressClick(type);
    }
  };
}
