import { ModalWindow, RadioGroup } from '@cian/ui-kit';

import * as React from 'react';

import { Button } from '../../../../common/components/button';
import { IChangePrintOptions } from '../../../state/print';

const style = require('./index.css');

export interface IPrintPopupProps {
  showComments: boolean;
  showPhones: boolean;
  open: boolean;

  changePrintOptions(options: IChangePrintOptions): void;
  close(): void;
}

export const printPhonesValues = [
  {
    value: 1,
    label: 'С телефонами',
  },
  {
    value: 0,
    label: 'Без телефонов',
  },
];

export const printCommentValues = [
  {
    value: 1,
    label: 'С комментарием',
  },
  {
    value: 0,
    label: 'Без комментария',
  },
];

export class PrintPopup extends React.PureComponent<IPrintPopupProps> {
  public render() {
    const { close, showComments, showPhones, open } = this.props;

    return (
      <ModalWindow
        content={
          <form onSubmit={this.handleSubmit}>
            <div className={style['popup-triggers']}>
              <RadioGroup
                options={printPhonesValues}
                value={Number(showPhones)}
                onChange={this.handlePrintPhonesChange}
              />
              <RadioGroup
                options={printCommentValues}
                value={Number(showComments)}
                onChange={this.handlePrintCommentChange}
              />
            </div>
            <div className={style['popup-actions']}>
              <Button theme="primary">Распечатать</Button>
              <Button mode="outline" theme="primary" onClick={close}>
                Отменить
              </Button>
            </div>
          </form>
        }
        open={open}
        title="Распечатать все объявления на странице"
        onClose={close}
      />
    );
  }

  private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    this.props.changePrintOptions({
      enabled: true,
    });
    setTimeout(() => {
      this.props.close();
      window.print();
      this.props.changePrintOptions({
        enabled: false,
      });
    });
  };

  private handlePrintPhonesChange = (e: React.ChangeEvent<HTMLInputElement>, showPhones: number) => {
    this.props.changePrintOptions({
      showPhones: showPhones === 1,
    });
  };

  private handlePrintCommentChange = (e: React.ChangeEvent<HTMLInputElement>, showComments: number) => {
    this.props.changePrintOptions({
      showComments: showComments === 1,
    });
  };
}
