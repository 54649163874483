import { EBuildingClassType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IWarehouseBuildingClassTypesTagType = 'advanced-warehouseBuildingClass';

export interface IWarehouseBuildingClassTypesTag {
  type: 'advanced-warehouseBuildingClass';
  value: EBuildingClassType;
}

const isWarehouseBuildingClassTypesTag = (tag: TTag): tag is IWarehouseBuildingClassTypesTag =>
  tag.type === IWarehouseBuildingClassTypesTagType;

export const warehouseBuildingClassTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.building_class_type__warehouse && jsonQuery.building_class_type__warehouse.value;

    let nextTags = tags.filter(
      tag => tag.type !== IWarehouseBuildingClassTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EBuildingClassType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IWarehouseBuildingClassTypesTag =>
              isWarehouseBuildingClassTypesTag(tag) && tag.value === value,
            () => ({ type: IWarehouseBuildingClassTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
