import { IAdFoxBannerContext } from '@cian/adfox';

import classNames from 'classnames';
import * as React from 'react';

import { trackAdFoxMortgage } from 'shared/tracking/adFoxMortgage';

import { IWithTrackingProps } from '../../../../hoc/withTracking';

import * as style from './mortgage.css';

export interface IMortgageSimpleBannerOwnProps {
  context: IAdFoxBannerContext;
}

export type IMortgageSimpleBannerStateProps = IWithTrackingProps;

export type TMortgageSimpleBannerProps = IMortgageSimpleBannerStateProps & IMortgageSimpleBannerOwnProps;

export class MortgageSimpleBanner extends React.PureComponent<TMortgageSimpleBannerProps> {
  public static shownBannerIds: string[] = [];

  public componentDidMount(): void {
    this.track(true);
  }

  public render() {
    const {
      context: { reference, text },
    } = this.props;

    return (
      <a
        className={classNames(style['link'])}
        data-mark="MortgageSimpleBannerLink"
        href={reference}
        rel="noreferrer"
        target={'_blank'}
        onClick={this.onClick}
      >
        {text}
      </a>
    );
  }

  private onClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();
    this.track(false);
  };

  private track = (isShow: boolean) => {
    const {
      tracking: { user, page },
      context: { bannerId },
    } = this.props;

    // Костыль, на время, пока не исправится ПОЛНАЯ перерисовка всего листинга
    // при каждом чихе (даже открытии какого-то попапа в карточке). Каждый
    // раз вызывается componentDidMount, потому что по какой-то причине весь
    // листинг реинициализируется. Таким образом мы избавляемся от логической
    // ошибки, когда в реалии трекинг ссылки должен срабатывать один раз
    // при ее показе, но срабатывает КАЖДЫЙ, когда перерисовывается вся
    // страница.
    if (isShow) {
      if (MortgageSimpleBanner.shownBannerIds.includes(bannerId)) {
        return;
      }
      MortgageSimpleBanner.shownBannerIds.push(bannerId);
    }

    trackAdFoxMortgage(isShow ? 'show' : 'click', 'LinkIpoteka', bannerId, user, page);
  };
}
