import { IAppState, IQuickLink } from 'shared/common/state';

import { isArrayWithItems } from '../../../../utils';

import { getGroupedLinks } from './groupLinks';

export function linkGroupsSelector(state: IAppState): IQuickLink[][] | null {
  const links = state.results.quickLinks && state.results.quickLinks.links;
  if (links === null) {
    return null;
  }

  const formattedLinks = getGroupedLinks(links);

  return isArrayWithItems(formattedLinks) ? formattedLinks : null;
}
