import { IUser } from '../types/user';

export function canModerateAnnouncements({ permissions }: IUser): boolean {
  return (permissions && permissions.canModerateAnnouncements) || false;
}

export function canModerateUsers({ permissions }: IUser): boolean {
  return (permissions && permissions.canModerateUsers) || false;
}

export function canViewUsers({ permissions }: IUser): boolean {
  return (permissions && permissions.canViewUsers) || false;
}

export function canUseHiddenBase({ permissions }: IUser): boolean {
  return (permissions && permissions.canUseHiddenBase) || false;
}
