import { ca } from '@cian/analytics';

export function trackReportPopupOpened() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'complain',
    label: 'open',
  });
}
