import { path } from 'ramda';

import { rootStateBranchSelector } from '../utils/rootStateBranchSelector';

export const getHttpApi = path(['httpApi']);

export const getConfig = path(['config']);

export const getResults = path(['results']);

export const resultsSelector = rootStateBranchSelector('results');

export const filtersSelector = rootStateBranchSelector('filters');

export const breadcrumbsSelector = rootStateBranchSelector('breadcrumbs');

export const userGADataLayerDataSelector = rootStateBranchSelector('userGADataLayerData');

export const abUseExperimentsSelector = rootStateBranchSelector('abUseExperiments');
