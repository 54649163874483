import { MapAreaType } from '../../../common/components/map/draw_objects/base_draw_object';
import { ICircleObjectData } from '../../../common/components/map/draw_objects/circle';
import { IPolygonObjectData } from '../../../common/components/map/draw_objects/polygon';
import { IAppState, TActions } from '../../../common/state';
import { IJsonQueryCircle, IJsonQueryPolygon, TGeoValue } from '../../../json_query';
import { circlesAreEqual, coordsIsEqual } from '../../../utils/geo';

import { TMapObjectData } from './map_changed';

export const MapAreaDeletedActionType = 'filters/map/MAP_AREA_DELETED_ACTION';

export const isJsonQueryPolygonObject = (item: TGeoValue): item is IJsonQueryPolygon => item.type === 'polygon';

export const isJsonQueryCircleObject = (item: TGeoValue): item is IJsonQueryCircle => item.type === 'distance';

export const isPolygonMapAreaType = (area: TMapObjectData): area is IPolygonObjectData =>
  area.type === MapAreaType.Polygon;

export const isCircleMapAreaType = (area: TMapObjectData): area is ICircleObjectData =>
  area.type === MapAreaType.Circle;

export interface IAreaDeletedAction {
  type: 'filters/map/MAP_AREA_DELETED_ACTION';
  area: TMapObjectData;
}

export function deleteArea(area: TMapObjectData): IAreaDeletedAction {
  return {
    type: MapAreaDeletedActionType,
    area,
  };
}

export function deleteAreaReducer(state: IAppState, action: TActions): IAppState {
  if (action.type === MapAreaDeletedActionType) {
    let areas: TGeoValue[] = [];

    if (state.filters.jsonQuery.geo) {
      areas = state.filters.jsonQuery.geo.value.filter((value: TGeoValue) => {
        if (isPolygonMapAreaType(action.area)) {
          return isJsonQueryPolygonObject(value) && !coordsIsEqual(value.coordinates, action.area.coordinates);
        } else if (isCircleMapAreaType(action.area)) {
          return isJsonQueryCircleObject(value) && !circlesAreEqual(value, action.area);
        } else {
          return false;
        }
      });
    }

    return {
      ...state,
      filters: {
        ...state.filters,
        jsonQuery: {
          ...state.filters.jsonQuery,
          geo: {
            ...state.filters.jsonQuery.geo,
            type: 'geo',
            value: areas,
          },
        },
      },
    };
  }

  return state;
}
