/* eslint-disable max-lines */
import { Tooltip } from '@cian/ui-kit';

import cn from 'classnames';
import { Component } from 'react';

import { CommercialCardContext__DEPRICATED } from '../../CommercialCard';
import { ELocationTypeId } from '../../types/offer';
import { getUnderground } from '../../utils/getUnderground';
import { Coworking } from '../Coworking';
import { AddressPathItem, TAddressPathItem } from '../address_path_item';
import { ReliableIcon } from '../reliable_icon';
import { SuggestionAddressPathItem } from '../suggestion_address_path_item';
import { Underground } from '../underground';
import { UndergroundIcon } from '../underground_icon';

import { contextTypes } from './contextTypes';
import { AddressProps } from './types';

import * as style from './Address.css';

export class Address extends Component<AddressProps> {
  public readonly context: CommercialCardContext__DEPRICATED;

  public static readonly contextTypes = contextTypes;

  public render() {
    const { offerHelper } = this.context;
    const { containerStyle } = this.props;

    return (
      <div className={cn(style['address'], containerStyle)}>
        {this.renderAreaBlock()}
        {this.shouldShowExactAddress && this.renderBuildingUrl()}
        {this.renderAddressPath()}
        {!offerHelper.isPrimaryMetroRegion() && this.renderRegionUnderground()}
      </div>
    );
  }

  private renderAreaBlock = () => {
    const { offer, offerHelper } = this.context;
    const { geo, fullUrl } = offer;
    const underground = offerHelper.isPrimaryMetroRegion() ? getUnderground(offer) : undefined;

    if (underground) {
      const locationId = (geo && geo.address.length > 0 && geo.address[0].id) || undefined;

      return (
        <Underground
          locationId={locationId}
          underground={underground}
          url={fullUrl}
          onClick={this.props.onPageJumped.bind(null, 'card_header_in_newlist')}
        />
      );
    } else if (geo) {
      const location = [...geo.address].reverse().find(address => {
        return address.type === 'location';
      });

      if (location) {
        return (
          <a
            className={style['header-link']}
            href={fullUrl}
            rel="noreferrer"
            target="_blank"
            onClick={this.props.onPageJumped.bind(null, 'card_header_in_newlist')}
          >
            <div className={style['header']}>{location.fullName}</div>
          </a>
        );
      }
    }

    return null;
  };

  private renderBuildingUrl() {
    const { geo, businessShoppingCenter, newbuilding, coworking } = this.context.offer;
    const isReliable = newbuilding && newbuilding.showJkReliableFlag;

    if (coworking) {
      return <Coworking {...coworking} />;
    }

    const reliableIcon = (href: string) =>
      isReliable && (
        <Tooltip
          arrow={true}
          placement="bottom-start"
          theme="white"
          title={
            <div className={style['tooltip-container']}>
              <div>
                <ReliableIcon className={style['icon']} />
              </div>
              <strong className={style['title']}>Проверено ЦИАН</strong>
              <p>В ЖК есть корпуса, проверенные ЦИАН.</p>
              <a className={style['link']} href={`${href}#reliable`} rel="noreferrer" target="_blank">
                Узнать подробнее
              </a>
            </div>
          }
        >
          <div className={style['reliable-wrapper']}>
            <ReliableIcon className={style['reliable-icon']} />
          </div>
        </Tooltip>
      );

    if (
      businessShoppingCenter &&
      businessShoppingCenter.type &&
      ((businessShoppingCenter.useParentName && businessShoppingCenter.parentName) ||
        (!businessShoppingCenter.useParentName && businessShoppingCenter.name))
    ) {
      const bsCenterTypeName = {
        businessCenter: 'БЦ',
        shoppingCenter: 'ТЦ',
        warehouse: 'СК',
      };

      const name = `${bsCenterTypeName[businessShoppingCenter.type]} «${
        !businessShoppingCenter.useParentName ? businessShoppingCenter.name : businessShoppingCenter.parentName
      }»`;
      const url = !businessShoppingCenter.useParentName
        ? businessShoppingCenter.fullUrl
        : businessShoppingCenter.parentUrl;

      return (
        <div className={style['building']}>
          {!url && name}
          {url && (
            <div>
              {reliableIcon(url)}

              <a className={style['building-link']} href={url}>
                {name}
              </a>
            </div>
          )}
        </div>
      );
    } else if (geo && geo.jk && geo.jk.displayName) {
      const { fullUrl } = geo.jk;

      return (
        <div className={style['building']}>
          {!fullUrl && geo.jk.displayName}
          {fullUrl && (
            <div>
              {reliableIcon(fullUrl)}

              <a className={style['building-link']} href={fullUrl} onClick={this.props.onJKLinkClicked}>
                {geo.jk.displayName}
              </a>
            </div>
          )}
        </div>
      );
    }

    return null;
  }

  private renderAddressPath = () => {
    const {
      offer,
      jsonQuery,
      api: { tracking },
    } = this.context;

    const { currentPath, qsToUris, suggestionDistancesSeoText } = this.props;

    const { geo, suggestionDistances } = offer;
    if (!geo) {
      return null;
    }

    const additionalStyle = geo.jk || offer.businessShoppingCenter ? { paddingTop: 0 } : undefined;
    const undergroundStation = getUnderground(offer);

    let cityIndex = 1;
    let isGeoAddressWithMetro = false;

    geo.address.forEach(({ locationTypeId, type }, index) => {
      /**
       * Если в geo.address уже есть метро, то вручную
       * на фронте его подставлять не нужно
       */
      if (type === 'metro') {
        isGeoAddressWithMetro = true;
      }

      if (locationTypeId === ELocationTypeId.City) {
        cityIndex = index + 1;
      }
    });

    const addressLine =
      geo.address.map(({ fullName, qs, type }) => ({
        label: fullName,
        qs,
        type: type === 'metro' ? 'metro' : 'address',
        url: qsToUris && qsToUris[qs],
      })) || [];

    /**
     * Если есть undergroundStation, но нет метро внутри geo.address,
     * то добавляем метро вручную тут
     */
    if (undergroundStation && !isGeoAddressWithMetro) {
      addressLine.splice(cityIndex, 0, {
        label: `м.\u00A0${undergroundStation.name}`,
        qs: undergroundStation.qs,
        type: 'metro',
        url: qsToUris && qsToUris[undergroundStation.qs],
      });
    }

    /**
     * Пытаемся взять гео ID текущей выдачи
     */
    const jsonQueryGeoId =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      jsonQuery.geo && jsonQuery.geo.value.length === 1 ? (jsonQuery.geo.value[0] as any).id : undefined;

    /**
     * Если в гео карточки уже есть гео запроса из ЧПУ - дополнительно расстояние
     * от текущего гео ЧПУ выводить не нужно
     */
    const geoIdConcurrences = jsonQueryGeoId ? geo.address.find(({ id }) => id === jsonQueryGeoId) : false;
    const isVisibleSuggestSeoText = geoIdConcurrences === undefined;

    const addressString = addressLine.map(address => address.label).join(', ');

    const microData = {};

    return (
      <div {...microData} className={style['address-path']} style={additionalStyle}>
        <span {...{ itemProp: 'name', content: addressString }} />
        {addressLine.map(({ label, qs, type, url }, index) => {
          const item = (
            <AddressPathItem
              key={`address-path-item-${index}`}
              label={label}
              qs={qs}
              trackAddressClick={tracking && tracking.trackAddressClick}
              type={type as TAddressPathItem}
              url={url}
            />
          );

          return [index > 0 && ', ', item];
        })}
        {isVisibleSuggestSeoText && suggestionDistancesSeoText && (
          <SuggestionAddressPathItem
            currentPath={currentPath}
            distance={suggestionDistances}
            text={suggestionDistancesSeoText}
          />
        )}
      </div>
    );
  };

  private renderRegionUnderground = () => {
    const { offer } = this.context;

    if (!offer.geo) {
      return null;
    }

    const undergroundStation = getUnderground(offer);

    if (!undergroundStation) {
      return null;
    }

    return (
      <div className={style['region-metro']}>
        <UndergroundIcon color={`#${undergroundStation.lineColor}`} height={9} width={9} /> {undergroundStation.name}
      </div>
    );
  };

  private get shouldShowExactAddress() {
    return !this.props.isNeedHideExactAddress;
  }
}
