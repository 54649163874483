//tslint:disable:max-line-length
import { IAppState, TActions } from '../../../common/state';

const IMinCeilingHeightChangedActionType = 'filters/ceilingHeight/MIN_CEILING_HEIGHT_CHANGED';
const IMaxCeilingHeightChangedActionType = 'filters/ceilingHeight/MAX_CEILING_HEIGHT_CHANGED';

export interface IMinCeilingHeightChangedAction {
  type: 'filters/ceilingHeight/MIN_CEILING_HEIGHT_CHANGED';
  value: number | undefined;
}

export interface IMaxCeilingHeightChangedAction {
  type: 'filters/ceilingHeight/MAX_CEILING_HEIGHT_CHANGED';
  value: number | undefined;
}

export function changeMinCeilingHeight(value: number | undefined): IMinCeilingHeightChangedAction {
  return {
    type: IMinCeilingHeightChangedActionType,
    value,
  };
}

export function changeMaxCeilingHeight(value: number | undefined): IMaxCeilingHeightChangedAction {
  return {
    type: IMaxCeilingHeightChangedActionType,
    value,
  };
}

export function ceilingHeightReducer(state: IAppState, action: TActions): IAppState {
  const ceiling_height = state.filters.jsonQuery.ceiling_height;

  switch (action.type) {
    case IMinCeilingHeightChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            ceiling_height:
              action.value !== undefined || (ceiling_height && ceiling_height.value.lte !== undefined)
                ? {
                    type: 'range',
                    value: {
                      gte: action.value,
                      lte: ceiling_height && ceiling_height.value.lte,
                    },
                  }
                : undefined,
          },
        },
      };
    case IMaxCeilingHeightChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            ceiling_height:
              action.value !== undefined || (ceiling_height && ceiling_height.value.gte !== undefined)
                ? {
                    type: 'range',
                    value: {
                      gte: ceiling_height && ceiling_height.value.gte,
                      lte: action.value,
                    },
                  }
                : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-ceilingHeight') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              ceiling_height: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            ceiling_height: undefined,
          },
        },
      };

    default:
      return state;
  }
}
