import cn from 'classnames';
import * as React from 'react';

import * as styles from './QuickLinksBanner.css';

interface IQuickLinksBannerProps {
  hasLeftBorder?: boolean;
  hasRightBorder?: boolean;
}

export const QuickLinksBanner: React.FC<React.PropsWithChildren<IQuickLinksBannerProps>> = ({
  children,
  hasLeftBorder,
  hasRightBorder,
}) => {
  return (
    <div
      className={cn(styles['banner'], {
        [styles['left-border']]: hasLeftBorder,
        [styles['right-border']]: hasRightBorder,
      })}
    >
      {children}
    </div>
  );
};

QuickLinksBanner.displayName = 'QuickLinksBanner';
