import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const HAS_FLAT_TOUR_BOOKING_TAG_TYPE = 'has-flat-tour-booking';

export interface IHasFlatTourBookingTag {
  type: 'has-flat-tour-booking';
}

function isHasFlatTourBookingTag(tag: TTag): tag is IHasFlatTourBookingTag {
  return tag.type === HAS_FLAT_TOUR_BOOKING_TAG_TYPE;
}

export const hasFlatTourBookingTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_flat_tour_booking && jsonQuery.has_flat_tour_booking.value;

    if (value) {
      return ensureSingleTagExists(tags, isHasFlatTourBookingTag, () => ({
        type: HAS_FLAT_TOUR_BOOKING_TAG_TYPE,
      }));
    } else {
      return ensureTagNotExists(tags, isHasFlatTourBookingTag);
    }
  },
};
