import { IOffer } from '../types/offer';

/**
 * Возвращает кастомный лейбл
 * для мнемоники аукциона
 * @param offer
 * @param isMlSearch
 */
export function getVasLabel(offer: IOffer, isMlSearch?: boolean) {
  let auctionName;
  if (offer.isTop3) {
    auctionName = isMlSearch ? 'Топ' : 'Топ 3';
  }
  if (offer.isPremium) {
    auctionName = 'Премиум';
  }
  if (offer.isPaid) {
    auctionName = 'Платное';
  }

  return offer.isAuction ? auctionName : undefined;
}
