import * as React from 'react';

import { UserProfilingModal as UserProfilingModalComponent } from './UserProfilingModal';
import { trackOnModalClose } from './internal/components/tracks/trackOnModalClose';
import { IUserProfilingModal } from './types';

export const UserProfilingModal: React.FC<IUserProfilingModal> = props => {
  const { onClose } = props;

  const handleClose = React.useCallback(() => {
    if (onClose) {
      onClose();
    }

    trackOnModalClose();
  }, [onClose]);

  return <UserProfilingModalComponent {...props} onClose={handleClose} />;
};

UserProfilingModal.displayName = 'UserProfilingModal';
