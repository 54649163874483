import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBSCenterTagType = 'bs_center';

export interface IBSCenterTag {
  type: 'bs_center';
  value: number;
  text: string;
}

const isBSCenterTag = (tag: TTag): tag is IBSCenterTag => tag.type === IBSCenterTagType;

export const bsCenterTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    let values = jsonQuery.bs_center && jsonQuery.bs_center.value;
    if (jsonQuery.bs_center_id) {
      values = (values && values.concat(jsonQuery.bs_center_id.value)) || [jsonQuery.bs_center_id.value];
    }

    let nextTags = tags.filter(
      tag => tag.type !== IBSCenterTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.bsCenters[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is IBSCenterTag => isBSCenterTag(tag) && tag.value === value,
          () => ({
            type: IBSCenterTagType,
            value,
            text: tagData || 'Неизвестный БЦ',
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
