import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export interface IMinWorkPlaceCountChangedAction {
  type: 'filters/workplace_count/MIN_WORKPLACE_COUNT_CHANGED';
  minWorkPlaceCount: number | undefined;
}

export interface IMaxWorkPlaceCountChangedAction {
  type: 'filters/workplace_count/MAX_WORKPLACE_COUNT_CHANGED';
  maxWorkPlaceCount: number | undefined;
}

export function setMinWorkPlaceCount(minWorkPlaceCount: number | undefined): IMinWorkPlaceCountChangedAction {
  return {
    type: 'filters/workplace_count/MIN_WORKPLACE_COUNT_CHANGED',
    minWorkPlaceCount,
  };
}

export function setMaxWorkPlaceCount(maxWorkPlaceCount: number | undefined): IMaxWorkPlaceCountChangedAction {
  return {
    type: 'filters/workplace_count/MAX_WORKPLACE_COUNT_CHANGED',
    maxWorkPlaceCount,
  };
}

export function workPlaceCountReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/workplace_count/MIN_WORKPLACE_COUNT_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setMinWorkPlaceCount(action.minWorkPlaceCount),
        },
      };
    case 'filters/workplace_count/MAX_WORKPLACE_COUNT_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setMaxWorkPlaceCount(action.maxWorkPlaceCount),
        },
      };
    default:
      return state;
  }
}
