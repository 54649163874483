import { IFiltersState } from 'shared/common/state';
import { IBSCenterTag } from 'shared/filters/state/tags/definitions/bs_center';
import { COWORKING_ID_TAG_TYPE, ICoworkingIdTag } from 'shared/filters/state/tags/definitions/coworking_id';
import { IGeoLocationTag } from 'shared/filters/state/tags/definitions/geo';
import { IKpIdTag } from 'shared/filters/state/tags/definitions/kp_id';
import { FDealType, dealTypeFromJsonQuery } from 'shared/utils/category';

import { getCoworkingObjectType } from './getCoworkingObjectType';
import { getOfficeType } from './getOfficeType';

export const getFormInitialValue = (filters: IFiltersState) => {
  const { jsonQuery, tags } = filters;

  const dealType = dealTypeFromJsonQuery(jsonQuery);

  return {
    dealType: dealType === FDealType.Rent ? 'rent' : 'sale',
    officeType: getOfficeType(jsonQuery),
    coworkingObjectType: getCoworkingObjectType(jsonQuery),
    priceFrom: jsonQuery.price?.value?.gte,
    priceTo: jsonQuery.price?.value?.lte,
    areaFrom: jsonQuery.total_area?.value?.gte,
    areaTo: jsonQuery.total_area?.value?.lte,
    objectClass: jsonQuery.building_class_type?.value?.map(String),
    specialties: jsonQuery.specialty_types?.value?.map(String),
    geo: (
      tags.find(tag => ['geo-location', 'bs_center', COWORKING_ID_TAG_TYPE].includes(tag.type)) as
        | IGeoLocationTag
        | IBSCenterTag
        | IKpIdTag
        | ICoworkingIdTag
        | undefined
    )?.text,
  };
};
