import { EMaxPrepay } from 'shared/json_query';

import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IMaxPrepayTagType = 'advanced-rentTerms-maxPrepay';

export interface IMaxPrepayTag {
  type: 'advanced-rentTerms-maxPrepay';
  value: EMaxPrepay;
}

function isMaxPrepayTag(tag: TTag): tag is IMaxPrepayTag {
  return tag.type === IMaxPrepayTagType;
}

export const maxPrepayTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.prepay && jsonQuery.prepay.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isMaxPrepayTag, () => ({ type: IMaxPrepayTagType, value }));
    } else {
      return ensureTagNotExists(tags, isMaxPrepayTag);
    }
  },
};
