import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export interface IMultiIdChangedAction {
  type: 'filters/multi_id/MULTI_ID_CHANGED';
  id: number | undefined;
}

export function changeMultiId(id: number | undefined): IMultiIdChangedAction {
  return {
    type: 'filters/multi_id/MULTI_ID_CHANGED',
    id,
  };
}

export function multiIdReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/multi_id/MULTI_ID_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setMultiId(action.id),
        },
      };
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'multiId') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.setMultiId(undefined),
          },
        };
      }

      return state;
    default:
      return state;
  }
}
