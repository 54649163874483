import { IJsonQueryCircle, IJsonQueryPolygon, TCoordinate } from 'shared/json_query';
import { approximatePath } from 'shared/utils/path_approximation';

import { MapAreaType } from './base_draw_object';
import { ICircleObjectData } from './circle';
import { IPolygonObjectData } from './polygon';
import { YMAPS_PRESETS_CIRCLE, YMAPS_PRESETS_POLYGON } from './settings';

const clearSimilarPoints = (points: [string, string][]) => points;

export const stringToNumberPoints = (points: TCoordinate[]): YMaps.TCoord[] =>
  points.reduce((acc: [number, number][], value: TCoordinate) => {
    acc.push([Number(value[0]), Number(value[1])]);

    return acc;
  }, []);

export const createPolygonGeoObject = (ymaps: YMaps.IYMaps, polygonData: IPolygonObjectData): YMaps.IGeoObject =>
  new ymaps.GeoObject(
    {
      geometry: {
        type: 'Polygon',
        coordinates: [stringToNumberPoints(polygonData.from)],
      },
    },
    { preset: YMAPS_PRESETS_POLYGON.POLYGON },
  );

export const createCircleGeoObject = (ymaps: YMaps.IYMaps, areaObject: ICircleObjectData): YMaps.IGeoObject =>
  new ymaps.GeoObject(
    {
      geometry: {
        type: 'Circle',
        coordinates: [Number(areaObject.center[0]), Number(areaObject.center[1])],
        radius: areaObject.radius,
      },
    },
    { preset: YMAPS_PRESETS_CIRCLE.CIRCLE },
  );

export const circleModelToDrawObject = (name: string, circleModel?: IJsonQueryCircle): ICircleObjectData => ({
  type: MapAreaType.Circle,
  name: circleModel ? circleModel.name : name,
  center: circleModel ? circleModel.center : ['', ''],
  radius: circleModel ? circleModel.radius : 0,
});

export const polygonModelToDrawObject = (name: string, polygonModel?: IJsonQueryPolygon): IPolygonObjectData => {
  const points = polygonModel ? clearSimilarPoints(polygonModel.coordinates) : [];

  return {
    type: MapAreaType.Polygon,
    name: polygonModel ? polygonModel.name : name,
    from: points,
    coordinates: points,
  };
};

const pagePixelsToGeoCoords = (points: YMaps.TCoord[], map: IYmapsMap): YMaps.TCoord[] => {
  const projection = map.options.get('projection') as YMaps.IProjection;

  const geoCoords = points.map(point => projection.fromGlobalPixels(map.converter.pageToGlobal(point), map.getZoom()));

  return geoCoords;
};

const KINK: { [x: string]: number } = {
  5: 25500,
  6: 17500,
  7: 8500,
  8: 4400,
  9: 2200,
  10: 1000,
  11: 400,
  12: 250,
  13: 100,
  14: 50,
  15: 20,
  16: 10,
  17: 5,
};

const simplifyGeometry = (geoPoints: YMaps.TCoord[], map: IYmapsMap) =>
  approximatePath(geoPoints, KINK[Math.round(map.getZoom()).toString()]);

export const getPolygonGeometryFromMousePath = (pagePoints: YMaps.TCoord[], map: IYmapsMap) => {
  const geoPoints = pagePixelsToGeoCoords(pagePoints, map);

  return simplifyGeometry(geoPoints, map);
};
