import { IAppState, TActions } from '../../../common/state';

export interface IMapOpenedAction {
  type: 'filters/map/MAP_OPENED';
}

export interface IMapClosedAction {
  type: 'filters/map/MAP_CLOSED';
}

export function openMapModal(): IMapOpenedAction {
  return {
    type: 'filters/map/MAP_OPENED',
  };
}

export function closeMapModal(): IMapClosedAction {
  return {
    type: 'filters/map/MAP_CLOSED',
  };
}

export function toggleMapModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/map/MAP_OPENED':
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: {
            jsonQuery: state.filters.jsonQuery,
            tags: state.filters.tags,
          },
        },
        isMapModalVisible: true,
      };
    case 'filters/map/MAP_CLOSED':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters.backup,
          backup: undefined,
        },
        isMapModalVisible: false,
      };
    default:
      return state;
  }
}
