import { Component } from 'react';

import { IHighway } from '../../../../api/directions';
import { Highway } from '../highway';

const style = require('./index.css');

export interface IRoadProps {
  highways: IHighway[];
  name: string;
  selectedHighwayIds: number[];

  deselectHighways(highways: number[]): void;
  selectHighways(highwaysIds: number[]): void;
}

const directionMetaData: {
  [index: string]: string;
} = {
  Белорусское: 'запад',
  Курское: 'юг',
  Савеловское: 'север',
  Рижское: 'северо-запад',
  Ярославское: 'северо-восток',
  Горьковское: 'восток',
  Ленинградское: 'северо-запад',
  Казанское: 'юго-восток',
  Павелецкое: 'юго-восток',
  Киевское: 'юго-запад',
};

export class Direction extends Component<IRoadProps> {
  public render() {
    const { name } = this.props;

    return (
      <div className={style['direction']}>
        <div className={style['direction-title']} onClick={this.handleClick}>
          {name}
        </div>
        <div className={style['direction-meta']}>{directionMetaData[name]}</div>
        {this.renderHighways()}
      </div>
    );
  }

  private renderHighways = () => {
    const { deselectHighways, highways, selectedHighwayIds, selectHighways } = this.props;

    return (
      <div>
        {highways.map(highway => (
          <Highway
            deselectHighways={deselectHighways}
            highway={highway}
            key={`directions-modal-highway-${highway.id}`}
            selectHighways={selectHighways}
            selected={selectedHighwayIds.includes(highway.id)}
          />
        ))}
      </div>
    );
  };

  private handleClick = () => {
    const { highways, selectedHighwayIds } = this.props;

    const highwaysIds = highways.map(({ id }) => id);

    if (highwaysIds.every(id => selectedHighwayIds.includes(id))) {
      this.props.deselectHighways(highwaysIds);
    } else {
      this.props.selectHighways(highwaysIds);
    }
  };
}
