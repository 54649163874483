import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { changeSort } from 'shared/filters/state/sort';
import { ESortValue } from 'shared/repositories/common/json_query';
import { isRedesignExperimentEnabled } from 'shared/selectors/isRedesignExperimentEnabled';
import { IDropdownItem } from 'shared/serp/components/Summary/components/SummarySortDropdown';
import {
  FDealType,
  FOfferType,
  dealTypeFromJsonQuery,
  isCommercial,
  isCommercialCoworking,
  isFlat,
  isSuburban,
  isUrban,
  offerTypeFromJsonQuery,
} from 'shared/utils/category';
import { MOId } from 'shared/utils/geo';

import { Dispatch, IAppState } from '../../../../common/state';
import { SummarySortDropdown } from '../../../components/Summary';

import { items } from './constants';

export const SummarySortDropdownContainer: React.VFC = () => {
  const dispatch: Dispatch = useDispatch();
  const currentLocation = useSelector((state: IAppState) => state.filters.currentLocation);
  const jsonQuery = useSelector((state: IAppState) => state.results.jsonQuery);
  const isRedesignEnabled = useSelector(isRedesignExperimentEnabled);

  const { sort } = jsonQuery;

  const onSortChanged = React.useCallback(
    (sort: ESortValue) => {
      dispatch(changeSort(sort));
    },
    [dispatch],
  );

  const sortItems = React.useMemo(() => {
    const offerType = offerTypeFromJsonQuery(jsonQuery);
    const dealType = dealTypeFromJsonQuery(jsonQuery);

    return items
      .filter(({ value }) => {
        switch (value) {
          case 'price_square_order':
          case 'price_square_order_desc':
            return (
              (isCommercial(offerType) && !isCommercialCoworking(offerType)) ||
              (isFlat(offerType) && dealType === FDealType.Sale)
            );
          case 'area_order':
            return !isCommercialCoworking(offerType) && offerType !== FOfferType.Land;
          case 'site_order':
            return isSuburban(offerType);
          case 'walking_time':
            return !isSuburban(offerType) && (typeof currentLocation === 'string' || currentLocation.hasMetro);
          case 'mcad': {
            let isMosowArea = false;
            if (jsonQuery.region && Array.isArray(jsonQuery.region.value)) {
              isMosowArea = jsonQuery.region.value.includes(MOId);
            }

            return isSuburban(offerType) && isMosowArea;
          }
          case 'street_name':
            return isUrban(offerType);
          default:
            return true;
        }
      })
      .map<IDropdownItem>(({ value, label }) => {
        if (value === 'area_order') {
          return { label: isSuburban(offerType) ? 'По площади дома' : 'По общей площади', value };
        }

        return { value, label };
      });
  }, [currentLocation, jsonQuery]);

  const currentSort = React.useMemo<ESortValue>(() => {
    let activeItem = sortItems[0];

    if (sort && sort.value) {
      activeItem = sortItems.find(({ value }) => value === sort.value) || sortItems[0];
    }

    return activeItem.value as ESortValue;
  }, [sortItems, sort]);

  return (
    <SummarySortDropdown
      currentSort={currentSort}
      isRedesignEnabled={isRedesignEnabled}
      sortItems={sortItems}
      onSortChanged={onSortChanged}
    />
  );
};
