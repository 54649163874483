import { ca } from '@cian/analytics';

export function trackFastLinksView(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'view_alsoLooking',
    label,
  });
}

export function trackFastLinkClick(label: string) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_alsoLooking',
    label,
  });
}
