import { IAdFoxBannerContext } from '@cian/adfox/build/react';

import * as React from 'react';

const style = require('./index.css');

export interface IGoogleDefaultProps {
  context: IAdFoxBannerContext;
}

export const GoogleDefaultBanner: React.FC<IGoogleDefaultProps> = props => {
  return (
    <div className={style['bannerWrapper']}>
      <iframe
        frameBorder="0"
        height="90"
        marginHeight={0}
        marginWidth={0}
        scrolling="no"
        src={props.context.code}
        width="728"
      />
    </div>
  );
};
