import { IAppState, TActions } from '../../../common/state';
import { jq } from '../../../json_query';

import { SET_READY_BUSINESS_TYPE } from './constants';

export const readyBusinessReducer = (state: IAppState, action: TActions): IAppState => {
  switch (action.type) {
    case SET_READY_BUSINESS_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setReadyBusinessType(action.payload),
        },
      };

    default:
      return state;
  }
};
