import { numberToPrettyString } from '@cian/utils';

import { trackQuickLinkClick } from '../../../../utils/tracking';

import * as styles from './QuickLinkItem.css';

export interface IQuickLinkItemProps {
  level: number;
  url: string;
  offersCount: number | null;
  title: string;
}

export function QuickLinkItem(props: IQuickLinkItemProps) {
  const { url, title, offersCount } = props;
  const handleClick = () => {
    const { level, url } = props;
    trackQuickLinkClick(level, url);
  };

  if (!offersCount) {
    return null;
  }

  return (
    <a className={styles['link']} href={url} onClick={handleClick}>
      <span className={styles['link-title']}>{title}</span>
      &nbsp;
      <span className={styles['link-count']}>{numberToPrettyString(offersCount)}</span>
    </a>
  );
}
