import { EHouseLineType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IHouseLineShoppingAreaTagType = 'advanced-house-line-shopping-area';

export interface IHouseLineShoppingAreaTag {
  type: 'advanced-house-line-shopping-area';
  value: EHouseLineType;
}

function isHouseLineShoppingAreaTag(tag: TTag): tag is IHouseLineShoppingAreaTag {
  return tag.type === IHouseLineShoppingAreaTagType;
}

export const houseLineShoppingAreaTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values =
      jsonQuery.building_house_line_type__shopping_area && jsonQuery.building_house_line_type__shopping_area.value;

    let nextTags = tags.filter(
      tag => tag.type !== IHouseLineShoppingAreaTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EHouseLineType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IHouseLineShoppingAreaTag => isHouseLineShoppingAreaTag(tag) && tag.value === value,
            () => ({ type: IHouseLineShoppingAreaTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
