import { connect } from 'react-redux';

import { IAppState } from 'shared/common/state';

import { HeaderDefault } from './index';

export function mapStateToProps(state: IAppState) {
  return {
    seo: state.results.seo,
    breadcrumbs: state.breadcrumbs,
  };
}

export const HeaderDefaultContainer = connect(mapStateToProps)(HeaderDefault);
