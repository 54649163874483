import { IOffer } from '../../offer';

export function isExtended(offer: IOffer) {
  return offer.extensionLabels && offer.extensionLabels.length;
}

export function splitVariants(variants?: string): string[] {
  if (!variants) {
    return [];
  }

  return variants.split('/');
}
export function getExtendedOfferEventExtra(offer: IOffer) {
  return isExtended(offer)
    ? { extra: { extended_type: (offer.extensionLabels || []).map(({ labelType }) => labelType) } }
    : {};
}

export function getOfferListingType(offer: IOffer) {
  return isExtended(offer) ? 'extended' : 'list';
}
