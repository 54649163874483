export interface IParamChangedAction<TParamType, TActionType> {
  type: TActionType;
  paramType: TParamType;
  value: boolean;
}

export const getParamChangedAction =
  <TParamType, TActionType>(actionType: TActionType) =>
  (paramType: TParamType, value: boolean): IParamChangedAction<TParamType, TActionType> => ({
    type: actionType,
    paramType,
    value,
  });
