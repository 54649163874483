import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

export interface IMinPriceChangedAction {
  type: 'filters/price/MIN_PRICE_CHANGED';
  minPrice: number | undefined;
}

export interface IMaxPriceChangedAction {
  type: 'filters/price/MAX_PRICE_CHANGED';
  maxPrice: number | undefined;
}

export function setMinPrice(minPrice: number | undefined): IMinPriceChangedAction {
  return {
    type: 'filters/price/MIN_PRICE_CHANGED',
    minPrice,
  };
}

export function setMaxPrice(maxPrice: number | undefined): IMaxPriceChangedAction {
  return {
    type: 'filters/price/MAX_PRICE_CHANGED',
    maxPrice,
  };
}

export function priceReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/price/MIN_PRICE_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setMinPrice(action.minPrice),
        },
      };
    case 'filters/price/MAX_PRICE_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setMaxPrice(action.maxPrice),
        },
      };
    default:
      return state;
  }
}
