interface IPrepareFloorsRangeArgs {
  floorFrom: number;
  floorTo: number;
}

export const prepareFloorsRange = ({ floorFrom, floorTo }: IPrepareFloorsRangeArgs) => {
  if (floorTo === floorFrom) {
    return `${floorFrom} этаж`;
  }

  if (floorTo === floorFrom + 1) {
    return `${floorFrom} и ${floorTo} этаж`;
  }

  return `${floorFrom} \u2013 ${floorTo} этаж`;
};
