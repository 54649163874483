import { ca } from '@cian/analytics';

export function trackChangeSortEvent(newSortValue: string) {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'sorting',
    label: trackingMapSortName(newSortValue),
  });
}

function trackingMapSortName(name: string) {
  const map: {
    [index: string]: string;
  } = {
    price_object_order: 'price',
    price_square_order: 'price_per_metr',
    walking_time: 'time_to_metro_area',
  };

  return map[name] || name || 'default';
}
