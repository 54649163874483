import { IAdFoxBannerContext } from '@cian/adfox';
import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import * as styles from './index.css';

export interface IMortgageInListingProps {
  context: IAdFoxBannerContext;
}

export class MortgageInListing extends React.Component<IMortgageInListingProps> {
  public render() {
    const { context } = this.props;

    const backgroundStyles = {
      background: `url(${context.backgroundImage}) no-repeat`,
      backgroundSize: 'cover',
    };

    return (
      <div {...mergeStyles(styles['mortgage-in-listing-wrapper'], backgroundStyles)} onClick={this.onClickHandler} />
    );
  }

  private onClickHandler = (event: React.MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const { context } = this.props;
    window.open(context.reference);
  };
}
