import { ca } from '@cian/analytics';

export function trackPrintOffersClicked() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'print_ads',
  });
}

export function trackExcelSaveClicked() {
  ca('event', {
    name: 'oldevent',
    category: 'Listing',
    action: 'export_ads',
  });
}
