export const items = [
  {
    value: 'default',
    label: 'По умолчанию',
  },
  {
    value: 'price_object_order',
    label: 'По цене (сначала дешевле)',
  },
  {
    value: 'total_price_desc',
    label: 'По цене (сначала дороже)',
  },
  {
    value: 'price_square_order',
    label: 'По цене за м\u00b2 (сначала дешевле)',
  },
  {
    value: 'price_square_order_desc',
    label: 'По цене за м\u00b2 (сначала дороже)',
  },
  {
    value: 'area_order',
    label: 'По площади дома',
  },
  {
    value: 'site_order',
    label: 'По площади участка',
  },
  {
    value: 'walking_time',
    label: 'По времени до метро',
  },
  {
    value: 'mcad',
    label: 'По расстоянию до МКАД',
  },
  {
    value: 'street_name',
    label: 'По улице',
  },
  {
    value: 'creation_date_desc',
    label: 'По дате добавления (сначала новые)',
  },
  {
    value: 'creation_date_asc',
    label: 'По дате добавления (сначала старые)',
  },
];
