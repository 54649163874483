import { Dispatch, IAppState, TActions } from '../../../common/state';

export interface IMapAppliedAction {
  type: 'filters/map/MAP_APPLIED';
}

export function applyMapModal() {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'filters/map/MAP_APPLIED',
    });

    dispatch({
      type: 'filters/search/SEARCH_REQUEST_START_REQUESTED',
    });
  };
}

export function saveMapModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/map/MAP_APPLIED':
      return {
        ...state,
        filters: {
          ...state.filters,
          backup: undefined,
        },
        isMapModalVisible: false,
      };
    default:
      return state;
  }
}
