import { IQuestionField, useFormField } from '@cian/frontend-request-form-widget';

import { useFormikContext } from 'formik';
import * as React from 'react';

import { OneChoiceSwitcher as Component, IOneChoiceSwitcher } from './OneChoiceSwitcher';

export const OneChoiceSwitcher: React.FC<IQuestionField> = props => {
  const {
    field: { value },
    helpers: { setValue },
  } = useFormField<string>({ name: props.name });

  const { handleSubmit } = useFormikContext();

  const handleClick: IOneChoiceSwitcher['onClick'] = value => {
    setValue(value);
    handleSubmit();
  };

  return <Component options={props.answers || []} value={value} onClick={handleClick} />;
};

OneChoiceSwitcher.displayName = 'OneChoiceSwitcher';
