import * as React from 'react';

export interface IInfoIconProps {
  size: number;
}

export const InfoIcon: React.FC<IInfoIconProps> = ({ size }) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg height={size} viewBox={`0 0 ${size} ${size}`} width={size}>
      <circle cx="10" cy="10" data-fill-rule="fill" opacity=".5" r="10" />
      <path
        d="M9 13h2v2H9v-2zm.134-1.292l-.006-.432c0-.532.088-.97.264-1.314.176-.344.529-.73 1.057-1.16.529-.43.844-.711.947-.844.16-.211.239-.443.239-.696 0-.353-.14-.655-.422-.906s-.66-.377-1.138-.377c-.46 0-.844.131-1.153.393-.31.262-.522.662-.638 1.199L6.62 7.365c.047-.77.375-1.422.983-1.96C8.212 4.87 9.01 4.6 9.998 4.6c1.04 0 1.867.272 2.481.815.614.544.922 1.176.922 1.898 0 .4-.113.778-.339 1.134-.225.357-.707.842-1.446 1.457-.383.318-.62.573-.713.767-.092.193-.134.539-.125 1.037H9.134z"
        fill="#FFFFFF"
        fillRule="nonzero"
        stroke="transparent"
      />
    </svg>
  );
};
