import * as React from 'react';
import { useSelector } from 'react-redux';

import { SeoMarkedList } from '../../components/SeoMarkedList';
import { selectMarkedListItems } from '../../selectors/markedList';

export const SeoMarkedListContainer: React.VFC = () => {
  const items = useSelector(selectMarkedListItems);

  if (items.length === 0) {
    return null;
  }

  return <SeoMarkedList items={items} />;
};
