const style = require('./index.css');

export interface ILabelProps {
  text: string;
  backgroundColor?: string;
  textColor?: string;
}

export const Label = (props: ILabelProps) => (
  <div
    className={style['label']}
    style={{
      backgroundColor: props.backgroundColor,
      color: props.textColor,
    }}
  >
    {props.text}
  </div>
);

Label.defaultProps = {
  backgroundColor: '#FF9D00',
  textColor: '#FFFFFF',
};
