import { useSelector } from 'react-redux';

import { IAppState } from 'shared/common/state';
import { EOfficeType } from 'shared/repositories/common/json_query';

import { useApplicationContext } from '../../../../serp/containers/ApplicationContext';

export function useIsCoworkingProfitBannerEnabled() {
  const { config } = useApplicationContext();

  /**
   * @todo удалить фича-тоггл Commercial.MultiAdsBanner.Enabled
   * @description Данный функционал появился в задаче CD-154315, будет удалён в задаче CD-154416
   */
  const isMultiAdsBannerEnabled = config.get<boolean>('Commercial.MultiAdsBanner.Enabled');

  return useSelector<IAppState, boolean>(state => {
    const {
      results: {
        jsonQuery: { _type, office_type, coworking_offer_type },
      },
    } = state;

    const isCoworking = Boolean(coworking_offer_type?.value?.length);

    const isAvialableCategory =
      isCoworking ||
      /**
       * @todo удалить условие isMultiAdsBannerEnabled
       * @description Данный функционал появился в задаче CD-154315, будет удалён в задаче CD-154416
       */
      (!isMultiAdsBannerEnabled && office_type?.value[0] === EOfficeType.Office);

    return _type === 'commercialrent' && office_type?.value.length === 1 && isAvialableCategory;
  });
}
