import { EPropertyType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IPropertyTypeTagType = 'advanced-propertyType';

export interface IPropertyTypeTag {
  type: 'advanced-propertyType';
  value: EPropertyType;
}

const isPropertyTypeTag = (tag: TTag): tag is IPropertyTypeTag => tag.type === IPropertyTypeTagType;

export const propertyTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.property_type && jsonQuery.property_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IPropertyTypeTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EPropertyType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IPropertyTypeTag => isPropertyTypeTag(tag) && tag.value === value,
            () => ({ type: IPropertyTypeTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
