import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IAgentCallsForbiddenTagType = 'advanced-comission-agentCallsForbidden';

export interface IAgentCallsForbiddenTag {
  type: 'advanced-comission-agentCallsForbidden';
}

function isAgentCallsForbiddenTag(tag: TTag): tag is IAgentCallsForbiddenTag {
  return tag.type === IAgentCallsForbiddenTagType;
}

export const agentCallsForbiddenTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.cl && jsonQuery.cl.value;

    if (typeof value !== 'undefined' && !value) {
      return ensureSingleTagExists(tags, isAgentCallsForbiddenTag, () => ({
        type: IAgentCallsForbiddenTagType,
      }));
    } else {
      return ensureTagNotExists(tags, isAgentCallsForbiddenTag);
    }
  },
};
