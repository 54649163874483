import { useToggleState } from '@cian/react-utils';
import { Button, ModalWindow } from '@cian/ui-kit';

import * as React from 'react';

import { ElectronicTradingWidgetProps } from 'shared/packages/electronic-trading-widget';

import { ElectronicTradingWidgetLoadable } from '../ElectronicTradingWidgetLoadable';
import { ElectronicTradingWidgetLoadableFallback } from '../ElectronicTradingWidgetLoadableFallback';

const MODAL_WIDTHS = {
  narrow: 498,
  wide: 680,
};

type JoinElectronicTradingButtonProps = ElectronicTradingWidgetProps & {
  text: string;
  trackShowTradingWidgetModal: VoidFunction;
};

export const JoinElectronicTradingButton: React.FC<JoinElectronicTradingButtonProps> = props => {
  const { trackShowTradingWidgetModal, text, ...widgetProps } = props;
  const { state: isModalVisible, toggle: toggleModalVisibility } = useToggleState(false);

  /* istanbul ignore next */
  const modalWidth = widgetProps.publisherPhoneNumbers.length ? MODAL_WIDTHS.wide : MODAL_WIDTHS.narrow;

  const handleClick = () => {
    toggleModalVisibility();
    trackShowTradingWidgetModal();
  };

  return (
    <>
      {isModalVisible && (
        <ModalWindow
          fixed
          open
          content={
            <ElectronicTradingWidgetLoadable fallback={<ElectronicTradingWidgetLoadableFallback />} {...widgetProps} />
          }
          size="M"
          title="Переходим к электронным торгам"
          width={modalWidth}
          onClose={toggleModalVisibility}
        />
      )}
      <Button
        fullWidth
        data-testid="electronic-trading.join-trading"
        size="XS"
        theme="fill_primary"
        onClick={handleClick}
      >
        {text}
      </Button>
    </>
  );
};
