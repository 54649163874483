import { Component } from 'react';

export interface IGalleryItemProps {
  onStateChange?(status: EGalleryItemStatus): void;
}

export enum EGalleryItemStatus {
  Pending,
  Loading,
  Loaded,
  Erroneous,
}

export interface IGalleryItemState {
  status: EGalleryItemStatus;
}

export abstract class GalleryItem<P extends IGalleryItemProps, S extends IGalleryItemState> extends Component<P, S> {
  public abstract load(): void;
}
