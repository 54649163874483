import { IEvent, createConsumer, createSubscriber } from '@cian/events-log';
import { ConfigurationError } from '@cian/peperrors/shared';

import { batch } from 'react-redux';

import { IAppStore } from 'shared/common/state';
import { changeJsonQuery } from 'shared/filters/state/json_query';
import { updateMeta } from 'shared/filters/state/meta';
import { makeSearch } from 'shared/filters/state/search';
import { setCommercialSaveSearchModalOpened } from 'shared/serp/state/commercialSaveSearchModal';

import { TCommercialFiltersTopicHandler } from './types';

export class CommercialFiltersEventsLog {
  private static readonly COMMERCIAL_FILTERS = 'commercial_filters';

  public static events: CommercialFiltersEventsLog | null;

  private store: IAppStore;

  public static init(store: IAppStore): CommercialFiltersEventsLog {
    if (!CommercialFiltersEventsLog.events) {
      CommercialFiltersEventsLog.events = new CommercialFiltersEventsLog(store);
    }

    return CommercialFiltersEventsLog.events;
  }

  public static getEvents(): CommercialFiltersEventsLog {
    if (!CommercialFiltersEventsLog.events) {
      throw new ConfigurationError({
        message: 'Events must be created before accessing it. Forgot to call init() before accessing events?',
        domain: 'events.getEvents',
      });
    }

    return CommercialFiltersEventsLog.events;
  }

  public constructor(store: IAppStore) {
    this.store = store;

    const commercialFiltersConsumer = createConsumer({ topic: CommercialFiltersEventsLog.COMMERCIAL_FILTERS });

    // @ts-expect-error, поправить приведение типов или выпилить
    const commercialFiltersSubscriber = createSubscriber(this.commercialFiltersTopicHandler);
    commercialFiltersConsumer.subscribe(commercialFiltersSubscriber);
  }

  private commercialFiltersTopicHandler = (event: IEvent<TCommercialFiltersTopicHandler>) => {
    switch (event.type) {
      case 'FILTERS_UPDATED':
        batch(() => {
          this.store.dispatch(changeJsonQuery({ nextJsonQuery: event.value.jsonQuery }));
          if (event.value.meta) {
            this.store.dispatch(updateMeta(event.value.meta));
          }
          this.store.dispatch(makeSearch());
        });
        break;
      case 'SAVE_SEARCH_BUTTON_OPENED':
        this.store.dispatch(setCommercialSaveSearchModalOpened());
        break;
    }
  };
}
