import { IOffer } from '../types/offer';

/**
 * Возвращает список VAS в виде мнемоник
 * @param offer объявление
 */
export const getOfferVasList = (offer: IOffer) => {
  const vasList = [];

  if (offer.isAuction) {
    vasList.push('auction');
  }
  if (offer.isColorized) {
    vasList.push('colorized');
  }
  if (offer.isTop3) {
    vasList.push('top3');
  }
  if (offer.isPremium) {
    vasList.push('premium');
  }
  if (offer.isPaid) {
    vasList.push('payed');
  }
  if (offer.isStandard) {
    vasList.push('standard');
  }

  return vasList;
};
