import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IWordsExcludeTagType = 'advanced-wordsExclude';

export interface IWordsExcludeTag {
  type: 'advanced-wordsExclude';
  value: string;
}

function isWordsExcludeTag(tag: TTag): tag is IWordsExcludeTag {
  return tag.type === IWordsExcludeTagType;
}

export const wordsExcludeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.description_exclude && jsonQuery.description_exclude.value;

    let nextTags = tags.filter(
      tag => tag.type !== IWordsExcludeTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: string) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IWordsExcludeTag => isWordsExcludeTag(tag) && tag.value === value,
            () => ({ type: IWordsExcludeTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
