const ML_VAS_NAMES: { [index: string]: string } = {
  auction: 'Аукцион',
  colorized: '',
  payed: 'Платное',
  premium: 'Премиум',
  top3: 'Топ',
  standard: 'Стандарт',
};

const ML_FULL_VAS_NAMES: { [index: string]: string } = {
  auction: 'Аукцион',
  colorized: 'Выделение цветом',
  payed: 'Платное',
  premium: 'Премиум',
  top3: 'Топ',
  standard: 'Стандарт',
};

const ML_VAS_DESCRIPTIONS: { [index: string]: string } = {
  auction:
    'С помощью ставки сверх Топа можно привлечь ещё больше внимания. Чем выше ставка, тем чаще ваше объявление будет показано в результатах поиска.',
  colorized: 'Ваше объявление будет выделено ярким цветом на фоне других объявлений.',
  payed:
    'Платные объявления никак не выделяются в результатах поиска и показываются в 3 раза реже, чем Топ-объявления.',
  premium:
    'Объявления с продвижением Премиум заметнее за счёт авторского заголовка и выше Стандарта и Публикаций в результатах поиска.',
  top3: 'Объявления с продвижением Топ заметнее за счёт авторского заголовка и больших фотографий. В поиске они выше Премиума, Стандарта и обычных Публикаций.',
  standard: 'Стандарт ниже объявлений с продвижением Топ и Премиум, но выше Публикаций в результатах поиска.',
};
// tslint:enable:max-line-length

/**
 * Возвращает имя VAS с самым высоким приоритетом (с учетом ранжированной выдачи)
 * @param vasList список VAS'ов объявления, отсортированный по приоритету
 */
export function getMlTopVasName(vasList: string[]) {
  return ML_VAS_NAMES[vasList[0]];
}

/**
 * Возвращает имя VAS по заданной мнемонике (с учетом ранжированной выдачи)
 * например, premium => Премиум
 * @param vas мнемоника VAS
 * @param useFullNames указывать на необходимость возвращать полные имена всех VAS
 */
export function getMlVasName(vas: string, useFullNames: boolean) {
  return useFullNames ? ML_FULL_VAS_NAMES[vas] : ML_VAS_NAMES[vas];
}

/**
 * Возвращает описание VAS и его преимущества (с учетом ранжированной выдачи)
 * @param vas мнемоника VAS
 */
export function getMlVasDescription(vas: string) {
  return ML_VAS_DESCRIPTIONS[vas];
}
