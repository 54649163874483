import { EOfficeType, IJsonQuery } from 'shared/json_query';

const ALL_COMMERCIAL_OFFICE_TYPE = [
  EOfficeType.Office,
  EOfficeType.TradeArea,
  EOfficeType.Warehouse,
  EOfficeType.PublicCatering,
  EOfficeType.FreeAppointmentObject,
  EOfficeType.Manufacture,
  EOfficeType.AutoService,
  EOfficeType.Business,
  EOfficeType.Building,
  EOfficeType.DomesticServices,
];

export const getOfficeType = (jsonQuery: IJsonQuery): string[] | undefined => {
  if (jsonQuery.coworking_offer_type?.value?.length) {
    return [String(EOfficeType.Coworking)];
  }

  const officeType = !jsonQuery.office_type?.value ? ALL_COMMERCIAL_OFFICE_TYPE : jsonQuery.office_type?.value;

  return officeType?.filter(type => type !== EOfficeType.Garage).map(String);
};
