import * as React from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from '../../common/state';
import { PreInfiniteSaveSearch } from '../../components/PreInfiniteSaveSearch';
import { IJsonQuery } from '../../json_query';
import { useApplicationContext } from '../../serp/containers/ApplicationContext';
import { TUser } from '../../types/user';
import { isEmail } from '../../utils/email';

import { setEmail, setStatusFailed, setSubscribeToNews, subscribe, useState } from './state';
import { trackSubscribed } from './tracking';

export const PreInfiniteSaveSearchContainer: React.FC = () => {
  const context = useApplicationContext();
  const jsonQuery = useSelector<IAppState, IJsonQuery>(state => state.results.jsonQuery);
  const title = useSelector<IAppState, string | null>(state => state.results.seo.h1);
  const user = useSelector<IAppState, TUser>(state => state.user);
  const [state, dispatch] = useState();

  const handleEmailChange = React.useCallback((email: string) => {
    dispatch(setEmail(email));
  }, []);

  const handleSubscribeToNewsChange = React.useCallback((subscribeToNews: boolean) => {
    dispatch(setSubscribeToNews(subscribeToNews));
  }, []);

  const handleSubscribe = React.useCallback(async () => {
    if (!isEmail(state.email)) {
      dispatch(setStatusFailed(['Неверный email адрес']));

      return;
    }

    await subscribe({ context, title: title || 'Сохранённый поиск', jsonQuery, state, dispatch });

    trackSubscribed(state.subscribeToNews);
  }, [context, jsonQuery, state, dispatch, title]);

  React.useEffect(() => {
    if (user.isAuthenticated) {
      dispatch(setEmail(user.email));
    }
  }, []);

  return (
    <PreInfiniteSaveSearch
      email={state.email}
      emailConfirmationRequired={state.emailConfirmationRequired}
      errors={state.errors}
      isUserAuthenticated={user.isAuthenticated}
      status={state.status}
      subscribeToNews={state.subscribeToNews}
      onEmailChange={handleEmailChange}
      onSubscribe={handleSubscribe}
      onSubscribeToNewsChange={handleSubscribeToNewsChange}
    />
  );
};
