import { ITagsData } from 'shared/common/state';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export interface ICommercialSpecialtyTag {
  type: 'advanced-commercial-specialty';
  text: string;
  value: number;
}

function isCommercialSpecialtyTag(tag: TTag): tag is ICommercialSpecialtyTag {
  return tag.type === 'advanced-commercial-specialty';
}

export const commercialSpecialtyTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values = jsonQuery.specialty_types && jsonQuery.specialty_types.value;

    let nextTags = tags.filter(
      tag => tag.type !== 'advanced-commercial-specialty' || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: number) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is ICommercialSpecialtyTag => isCommercialSpecialtyTag(tag) && tag.value === value,
            () => ({
              type: 'advanced-commercial-specialty',
              text: getCommercialSpecialtyTagLabel(value, tagsData),
              value,
            }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};

function getCommercialSpecialtyTagLabel(value: number, tagsData: ITagsData): string {
  const DEFAULT_LABEL = 'Неизвестное назначение';
  const commercialSpecialty = tagsData.commercialSpecialties.find(({ id }) => id === value);

  if (commercialSpecialty) {
    return commercialSpecialty.rusName || DEFAULT_LABEL;
  }

  return DEFAULT_LABEL;
}
