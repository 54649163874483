import { EQuestionActionsTypes, IQuestion, IStep } from '@cian/frontend-request-form-widget';
import { IQuestionField } from '@cian/frontend-request-form-widget/es/types/questionField';

import { number, object, ref, string } from 'yup';

import { ExtendedFieldType } from '../extendedFieldType';

import { DEAL_DATE_STEP } from './dealDate';

export const BUSINESS_PAGE: IStep<IQuestion<IQuestionField<ExtendedFieldType>>> = {
  code: 'businessPage',
  question: {
    code: 'businessQuestion',
    title: 'Укажите параметры объекта',
    fields: [
      {
        type: ExtendedFieldType.GeoSuggest,
        name: 'geo',
        label: 'Местоположение',
        placeholder: 'Город, адрес, метро, район или шоссе',
      },
      {
        type: ExtendedFieldType.Price,
        name: 'priceFrom',
        unit: '₽',
        prefix: 'от',
        label: 'Цена',
        size: {
          xs: 12,
          m: 6,
        },
      },
      {
        type: ExtendedFieldType.Price,
        name: 'priceTo',
        unit: '₽',
        prefix: 'до',
        label: '\u00A0',
        size: {
          xs: 12,
          m: 6,
        },
      },
    ],
    actions: [{ type: EQuestionActionsTypes.NextQ, text: 'Далее' }],
    validation: object().shape(
      {
        geo: string().when(['priceFrom', 'priceTo'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: string().required('Укажите значение поля'),
          otherwise: string(),
        }),
        priceFrom: number().when(['geo', 'priceTo'], {
          is: (...fields: unknown[]) => fields.every(field => field == null),
          then: number().required('Укажите значение поля'),
          otherwise: number(),
        }),
        priceTo: number()
          .when(['geo', 'priceFrom'], {
            is: (...fields: unknown[]) => fields.every(field => field == null),
            then: number().required('Укажите значение поля'),
            otherwise: number(),
          })
          .when('priceFrom', {
            is: (priceFrom: number) => priceFrom != null,
            then: number().min(ref('priceFrom'), 'Некорректно указано значение'),
          }),
      },
      [
        ['geo', 'priceFrom'],
        ['geo', 'priceTo'],
        ['priceFrom', 'priceTo'],
      ],
    ),
  },
  nextStep: [
    {
      conditions: [],
      nextStepCode: DEAL_DATE_STEP.code,
    },
  ],
};
