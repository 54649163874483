/* eslint-disable max-lines */

import { Button, InlineText2 } from '@cian/ui-kit';
import { numberToPrettyString } from '@cian/utils';

import cn from 'classnames';
import * as React from 'react';

import { JoinElectronicTradingButtonContainer } from 'shared/containers/JoinElectronicTradingButtonContainer';
import { EBrandingLevel, ECoworkingOfferType } from 'shared/repositories/search-offers.legacy/v2/types';

import { PublishersBadges } from '../../../components/PublishersBadges';
import { extractUndefined } from '../../../utils/extractUndefined';
import { AnalyticsButton } from '../components/AnalyticsButton';
import { AreaPartsGalleryPlaceholder } from '../components/AreaPartsGalleryPlaceholder';
import { AreaPartsMultiAds } from '../components/AreaPartsMultiAds';
import { getMinAreaPrice } from '../components/AreaPartsMultiAds/helpers';
import { CommercialFactoid } from '../components/CommercialFactoid/CommercialFactoid';
import { CommercialFactoidsWrapper } from '../components/CommercialFactoidsWrapper/CommercialFactoidsWrapper';
import { CommercialTitle, ECommercialTitle } from '../components/CommercialTitle/CommercialTitle';
import { CommercialTitlePart } from '../components/CommercialTitlePart/CommercialTitlePart';
import { CommercialTitlePartsWrapper } from '../components/CommercialTitlePartsWrapper/CommercialTitlePartsWrapper';
import { ContactStatus } from '../components/ContactStatus';
import { CoworkingAmenities } from '../components/CoworkingAmenities';
import { CoworkingWorkplacesCountSubtitle as CoworkingSubtitle } from '../components/CoworkingWorkplacesCountSubtitle';
import { ExtendedOfferLabel } from '../components/ExtendedOfferLabel';
import { HorizontalAgentBrand } from '../components/HorizontalAgentBrand';
import { IconPreviewPlaceholder } from '../components/Icons/IconPreviewPlaceholder';
import { MultiAdsGalleryCounter } from '../components/MultiAdsGalleryCounter';
import { RightPanel } from '../components/RightPanel';
import { ShieldsOnPhoto } from '../components/ShieldsOnPhoto';
import { TopButtons } from '../components/TopButtons';
import { Address } from '../components/address';
import { CallbackModalContainer } from '../components/callback_modal';
import { StartChatPopup } from '../components/chats';
import { Comments } from '../components/comments';
import { Description } from '../components/description';
import { GalleryImage } from '../components/gallery';
import { GalleryItemPreloader } from '../components/gallery/preloader';
import { HiddenBaseLabel } from '../components/hidden_base_label';
import { HorizontalCard } from '../components/horizontal_card';
import { Map } from '../components/map';
import { SimplePhoneButton } from '../components/phone/simple_button';
import { Photos } from '../components/photos';
import { Similar } from '../components/similar';
import { Tooltip } from '../components/tooltip/Tooltip';
import { InfoIcon } from '../components/tooltip/icons/Info';
import { UserInfo } from '../components/user_info';
import { offerHelper } from '../presenter';
import { LandAreaUnitType } from '../types/offer';
import { CommercialOwner } from '../utils/CommercialOwner';
import { getAgentPhone } from '../utils/getAgentPhone';
import { getFormattedCoworkingTitle } from '../utils/getFormattedCoworkingTitle';
import {
  isCoworkingCardDescriptionHidden,
  isFlexOfficeCardSameAsOffice,
  isOfficeInCoworkingTitle,
} from '../utils/isCoworkingExperiment';
import { canUseHiddenBase } from '../utils/user';

import { childContextTypes } from './childContextTypes';
import {
  getCommercialAreaForMultiAds,
  getCommercialBuildingClass,
  getCommercialBuildingParkingPlaces,
  getCommercialFloorsCount,
  getCommercialTotalAreaForMultiAds,
  getCurrencySymbol,
  getMultiPSNString,
  prepareCommercialBscTitle,
  prepareCommercialBuildingMultiAdsAddress,
} from './helpers';
import type {
  CommercialCardContext__DEPRICATED,
  CommercialCardProps,
  CommercialCardState,
  TOfferMiniPhoto,
} from './types';

import * as multiAdsOfferStyles from './styles/MultiAdsOfferCard.css';
import * as topOfferStyles from './styles/TopOfferCard.css';
import * as style from './styles/commercial_card.css';
import * as commonStyle from './styles/index.css';
import * as offerStyles from './styles/offerCard.css';

function prepareDescription(str: string | undefined): string {
  return str
    ? // 264 - максимальное количество символов, которые влезают в три строки
      str.length >= 264
      ? str.slice(0, 264).replace(/\W$/, '').trim().concat('...')
      : str
    : '';
}

function getCommercialLandTitleArea(area: string | number | null, unitType: LandAreaUnitType | null): string {
  return area ? `${parseFloat(area as string)} ${unitType === 'sotka' ? 'сот.' : 'га.'}` : '';
}

function getCommercialTotalArea(totalArea: string | null, units: string): string {
  return totalArea ? `${totalArea} ${units}` : '';
}

export class CommercialOfferCard extends React.Component<CommercialCardProps, CommercialCardState> {
  public readonly context: CommercialCardContext__DEPRICATED;

  public static readonly childContextTypes = childContextTypes;

  public getChildContext(): CommercialCardContext__DEPRICATED {
    const {
      config,
      api,
      baseUrl,
      currentSubdomain,
      abUseExperiments,
      httpApi,
      isSimilar,
      jsonQuery,
      logger,
      offer,
      user,
      maxAuctionBet,
      maxAuctionService,
    } = this.props;

    const auctionMaxBet = offer.auction ? offer.auction.maxAvailableBet : 99;
    const auctionMinBetForMoscowRegion = Number(config.get<string>('auction.minBetForMoscowRegion'));
    const regionId = offer.geo && offer.geo.address && offer.geo.address.length ? offer.geo.address[0].id : 0;
    const isMoscowRegion = [1, 4593].includes(regionId);
    const auctionMinBet = isMoscowRegion ? auctionMinBetForMoscowRegion : 1;
    const captchaSpamSiteKey = config.get<string>('captchaSpamSiteKey') || '';

    return {
      api: {
        ...api,
        buttons: {
          onPopupMoreOpen: () => {},
          onPopupReportOpen: () => {},
          onPopupMoreClose: () => {},
          onPopupReportClose: () => {},
          onHideOffer: api.card.onHideOffer || (() => {}),
        },
      },
      commercialOwner: CommercialOwner.getInstance(offer),
      baseUrl,
      currentSubdomain,
      abUseExperiments,
      httpApi,
      /** ML-ранжирование, без проверки региона  */
      isMlSearchForAll: !!config.get<boolean>('isMlSearchForAll.Enabled'),
      isSimilar,
      jsonQuery,
      logger,
      /** Перечень регионов ранжированного поиска */
      mlSearchRegions: config.get<number[]>('mlSearchRegions') || [],
      offer,
      offerHelper: offerHelper(offer),
      user,
      maxAuctionBet,
      maxAuctionService,
      auctionMinBet,
      auctionMaxBet,
      validateStep: !!config.get<boolean>('validateStep'),
      captchaSpamSiteKey,
    };
  }

  public constructor(props: CommercialCardProps) {
    super(props);

    const {
      offer: { photos },
    } = props;

    /* Берем фото для превью с 1 по 5 */
    const miniPreviewPhotos: TOfferMiniPhoto[] = photos.slice(1, 5);
    /* Сохраняем, сколько фото действительно есть, чтобы реализовать плейсхолдеры */
    const previewPhotosLength = miniPreviewPhotos.length;
    /* Нам нужно 4 превью в виде фото или плейсхолдера */
    miniPreviewPhotos.length = 4;
    /* Заполняем массив до конца плейсхолдерами (null отрендерится как пустое фото) */
    miniPreviewPhotos.fill(null, previewPhotosLength, 4);

    this.state = {
      animateHide: false,
      galleryControlsHidden: true,
      isCallbackModalOpened: false,
      isCommentAreaOpened: false,
      isHidden: false,

      isReportActive: false,
      isMapActive: false,
      isMoreActive: false,
      isPhotoSlideChanged: false,
      isReporting: false,
      previewPhotos: miniPreviewPhotos,
      otherPhotosCount: photos.length - 4,
    };
  }

  public render() {
    const {
      api: { tracking, comments },
      offer,
      containerRef,
    } = this.props;

    const agentPhone = getAgentPhone(offer);
    const isRepresentative = !!(offer.businessShoppingCenter && offer.businessShoppingCenter.fromRepresentative);

    const isCommercialOfferWithMultiAds = this.isCommercialOfferWithMultiAds;

    return (
      <div
        className={cn(commonStyle['offer-container'], {
          [commonStyle['offer--hide']]: this.state.animateHide,
          [commonStyle['offer--hidden']]: this.state.isHidden,
          [commonStyle['hide-offer-animation']]: this.props.isOnHideAnimation,
          [commonStyle['represent']]: !offer.isTop3 && !offer.isColorized && isRepresentative,
        })}
        ref={containerRef}
      >
        <HorizontalCard
          containerStyle={cn(commonStyle['offer'], style['offer'], {
            [commonStyle['offer--top3']]: offer.isTop3,
            [commonStyle['offer--colorized']]: offer.isColorized,
          })}
          content={this.renderContent}
          contentStyle={multiAdsOfferStyles[isCommercialOfferWithMultiAds ? 'horizontal-card-content-multi-ads' : '']}
          isColorized={!!offer.isColorized}
          isPaid={!!offer.isPaid}
          isRepresentative={isRepresentative}
          isTop3={!!offer.isTop3}
          media={this.prepareMedia()}
          mediaStyle={cn(style['media'], isCommercialOfferWithMultiAds && style['media-multi-ads'])}
          onMouseEnter={this.onHoverOver}
          onMouseLeave={this.onHoverOut}
          onTouchMove={this.onHoverOut}
          onTouchStart={this.onHoverOver}
        />
        {!!offer.geo?.coordinates && this.state.isMapActive && <Map coordinates={offer.geo.coordinates} />}
        {(this.state.isCommentAreaOpened || offer.notes.offer || offer.notes.realtor) && (
          <Comments isSimplified offer={offer} onCompletion={this.handleCommentingCompletion} {...comments} />
        )}
        {!!offer.allFromOffrep && !!offer.allFromOffrep.count && !!offer.allFromOffrep.url && (
          <Similar
            accountType={offerHelper(offer).presenters.getAccountType()}
            agencyName={offerHelper(offer).presenters.getAgentName()}
            count={offer.allFromOffrep.count}
            isColorized={!!offer.isColorized}
            isTop3={!!offer.isTop3}
            trackAllDuplicatesClick={tracking ? tracking.trackAllDuplicatesClick : undefined}
            url={offer.allFromOffrep.url}
          />
        )}
        {this.state.isCallbackModalOpened && !!agentPhone && (
          <CallbackModalContainer
            agentPhone={agentPhone}
            callFrom="10:00"
            callTo="18:00"
            close={this.toggleCallbackModal}
            gaLabel={offer.gaLabel || ''}
            httpApi={this.props.httpApi}
            isAvailableToCall={true}
            timezoneText=""
            useOldWidget={true}
          />
        )}
      </div>
    );
  }

  public prepareMedia = () => {
    const { offer } = this.props;
    const { previewPhotos, otherPhotosCount } = this.state;

    const leftPhoto = offer.photos.length > 1 ? offer.photos[1] : null;
    const rightPhoto = offer.photos.length > 2 ? offer.photos[2] : null;

    const isCommercialOfferWithMultiAds = this.isCommercialOfferWithMultiAds;

    if (isCommercialOfferWithMultiAds) {
      return (
        <div className={multiAdsOfferStyles['media_wrapper']}>
          <div className={multiAdsOfferStyles['media']}>
            {this.getShieldsOnPhotoView()}
            {offer.photos.length === 0 ? (
              <div className={multiAdsOfferStyles['gallery-placeholder']}>
                <AreaPartsGalleryPlaceholder />
              </div>
            ) : (
              <Photos
                counter={MultiAdsGalleryCounter}
                counterHidden={false}
                galleryControlsHidden={this.state.galleryControlsHidden}
                offer={offer}
                useThumbnail2Url={true}
              />
            )}
          </div>

          <div className={multiAdsOfferStyles['horizontal_agent_brand']}>
            <HorizontalAgentBrand wrapperClass={multiAdsOfferStyles['agent-wrapper']} />
          </div>
        </div>
      );
    }

    return (
      <div>
        {offer.isTop3 ? (
          <div className={topOfferStyles['media_brand_wrapper']}>
            <div className={topOfferStyles['media_wrapper']}>
              <div
                className={cn(topOfferStyles['media'], {
                  [topOfferStyles['media--big']]: offer.photos.length <= 1,
                })}
              >
                {this.getShieldsOnPhotoView()}
                <Photos
                  galleryControlsHidden={this.state.galleryControlsHidden}
                  offer={this.props.offer}
                  photoContainerStyle={cn(topOfferStyles['photos_container'], {
                    [topOfferStyles['photos_container--big']]: offer.photos.length <= 1,
                  })}
                  useThumbnail2Url={true}
                />
              </div>
              {offer.photos.length > 1 && (
                <div className={topOfferStyles['preview_photos']}>
                  {previewPhotos.map((photo, index) => {
                    if (!photo) {
                      return (
                        <div className={topOfferStyles['preview_placeholder']} key={index}>
                          <IconPreviewPlaceholder />
                        </div>
                      );
                    }

                    return (
                      <div className={topOfferStyles['preview_photo']} key={index}>
                        {previewPhotos.length - 1 === index && otherPhotosCount > 1 && (
                          <div className={topOfferStyles['other_photos']}>
                            <div className={topOfferStyles['other_photos-content']}>
                              <div className={topOfferStyles['other_photos-text']}>Еще фото</div>
                            </div>
                          </div>
                        )}
                        <GalleryItemPreloader>
                          <GalleryImage alt="" src={photo.thumbnailUrl} />
                        </GalleryItemPreloader>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className={topOfferStyles['horizontal_agent_brand']}>
              <HorizontalAgentBrand />
            </div>
          </div>
        ) : (
          <div className={offerStyles['media']}>
            {this.getShieldsOnPhotoView()}
            <Photos
              galleryControlsHidden={this.state.galleryControlsHidden}
              layoutPhotoStyle={offer.fromDeveloper ? offerStyles['layout_image'] : undefined}
              offer={this.props.offer}
              photoContainerStyle={offer.isTop3 ? offerStyles['photos-container'] : ''}
            />
            {offer.isTop3 && (
              <div className={offerStyles['additional-photos']}>
                {leftPhoto && (
                  <div className={offerStyles['left-photo']}>
                    <GalleryItemPreloader>
                      <GalleryImage alt="" src={leftPhoto.thumbnailUrl} />
                    </GalleryItemPreloader>
                  </div>
                )}
                {rightPhoto && (
                  <div className={offerStyles['right-photo']}>
                    <GalleryItemPreloader>
                      <GalleryImage alt="" src={rightPhoto.thumbnailUrl} />
                    </GalleryItemPreloader>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  public readonly renderContent = () => {
    const {
      offer,
      user,
      config,
      api: { onOfferCardClick },
    } = this.props;
    const { extensionLabels } = offer;
    const areaParts = offer.areaParts;

    const showAnalyticsButton: boolean =
      !!offer.showCommercialAnalytics && !!config.get<boolean>('analyticButton.enabled');

    const isCommercialOfferWithMultiAds = this.isCommercialOfferWithMultiAds;

    const isFlexOfficeCardSameAsOffice = this.getFlexOfficeCardSameAsOffice();
    const isCoworkingCardDescriptionHidden = this.isCoworkingCardDescriptionHidden();

    return (
      <div
        className={cn(
          offer.isTop3 && style['content_wrapper'],
          isCommercialOfferWithMultiAds && multiAdsOfferStyles['content-wrapper'],
        )}
      >
        <div className={style['content']}>
          <div className={offer.isTop3 ? style['info'] : style['commonInfo']} onClick={onOfferCardClick}>
            {this.renderTitle()}
            <div className={style['shields-in-content-wrapper']}>
              <PublishersBadges />
            </div>
            <div>
              <Address
                containerStyle={style['address']}
                coworking={offer.coworking}
                currentPath={this.props.currentPath}
                isNeedHideExactAddress={offer.isNeedHideExactAddress}
                qsToUris={this.props.qsToUris}
                suggestionDistancesSeoText={this.props.suggestionDistancesSeoText}
                onJKLinkClicked={() => void 0}
                onPageJumped={this.props.api.card.onPageJumped}
              />
              {canUseHiddenBase(user) && Boolean(offer.isInHiddenBase) && (
                <HiddenBaseLabel containerStyle={style['hidden_base_label']} />
              )}
            </div>
            <ExtendedOfferLabel labelStyle={style['label']} labels={extensionLabels} />
            {isCommercialOfferWithMultiAds && (
              <AreaPartsMultiAds offer={offer} onPageJumped={this.props.api.card.onPageJumped} />
            )}

            {!isCoworkingCardDescriptionHidden && !isCommercialOfferWithMultiAds && !!offer.description && (
              <Description
                areaParts={areaParts}
                containerStyle={style[offer.isTop3 ? 'description-top' : 'description']}
                description={prepareDescription(offer.description)}
                descriptionWordsHighlighted={offer.descriptionWordsHighlighted}
                excludedFromActionStyle={style['recidivist']}
                isBot={this.props.isBot}
                isExcludedFromAction={!!offer.isExcludedFromAction}
                isRecidivist={!!offer.isRecidivist}
                recidivistStyle={style['recidivist']}
                onComplainStateChanged={this.onComplainStateChanged}
              />
            )}
            {!isFlexOfficeCardSameAsOffice && offer.coworking && (
              <CoworkingAmenities {...offer.coworking} className={style['coworking-amenities']} url={offer.fullUrl} />
            )}
          </div>
          <div className={cn(offer.isTop3 && style['agent_brand_wrapper'])}>{this.renderAuthorInfo()}</div>
        </div>
        <div className={style['bottom-wrapper']}>
          {showAnalyticsButton && <AnalyticsButton onClick={this.onAnalyticsButtonClick} />}
          <div className={cn(style['footer'], { [style['footer-top']]: offer.isTop3 })}>
            {this.renderFooterPanel()}
            <div className={commonStyle['footer-flags']}>
              <RightPanel />
            </div>
          </div>
        </div>
      </div>
    );
  };

  private handleHideFromPrintClick() {
    const { tracking } = this.props.api;

    this.setState({ animateHide: true });

    if (tracking && tracking.trackHidePrintButtonClick) {
      tracking.trackHidePrintButtonClick();
    }

    setTimeout(() => {
      this.setState({ isHidden: true });
    }, 500);
  }

  private renderFooterPanel() {
    const { offer, isHideOfferFetching, isOnHideAnimation, api } = this.props;
    const isPhoneHidden = !!offer.kp && !offer.kp.displayBuilderPhone;

    return (
      <TopButtons
        isSimplified
        buttonClassName={offerStyles['button']}
        complaintsHelpers={api.complaintsHelpers}
        containerClassName={offerStyles['left-panel']}
        dynamicButtonClassName={commonStyle['dinamic-buttons']}
        isCallbackVisible={!!getAgentPhone(offer)}
        isComplainOpen={this.state.isReportActive}
        isElectronicTrading={this.props.isElectronicTrading}
        isHideOfferFetching={isHideOfferFetching || isOnHideAnimation}
        isMapActive={this.state.isMapActive}
        isMoreInfoHidden={true}
        isPhoneHidden={isPhoneHidden}
        isSimilar={this.props.isSimilar}
        offer={offer}
        position={0}
        onCallbackClick={this.toggleCallbackModal}
        onCommentClick={this.handleCommentClick}
        onComplainClose={() => this.setState({ isReportActive: false })}
        onFavoriteChange={(value: boolean) => this.props.api.card.onFavoriteChange(value)}
        onHideFromPrintClicked={this.handleHideFromPrintClick}
        onMapButtonClick={this.onMapButtonClick}
      />
    );
  }

  private getOfficeInCoworkingTitleVisible = (): boolean => {
    const {
      abUseExperiments,
      isOfficeSearch,
      offer: { coworkingOfferType },
    } = this.props;

    if (!isOfficeSearch || coworkingOfferType !== ECoworkingOfferType.Office) {
      return false;
    }

    return isOfficeInCoworkingTitle(abUseExperiments);
  };

  private getFlexOfficeCardSameAsOffice = (): boolean => {
    const {
      abUseExperiments,
      isOfficeSearch,
      offer: { coworkingOfferType },
    } = this.props;

    if (!isOfficeSearch || coworkingOfferType !== ECoworkingOfferType.Office) {
      return false;
    }

    return isFlexOfficeCardSameAsOffice(abUseExperiments);
  };

  private readonly isCoworkingCardDescriptionHidden = (): boolean => {
    const {
      abUseExperiments,
      isOfficeSearch,
      offer: { coworkingOfferType },
    } = this.props;

    if (!isOfficeSearch || coworkingOfferType !== ECoworkingOfferType.Office) {
      return false;
    }

    return isCoworkingCardDescriptionHidden(abUseExperiments);
  };

  private renderTitle = () => {
    const square = '\u00B2';
    const { offer, jsonQuery, isOfficeSearch, knAuctionCompanies, config } = this.props;
    const { monthlyIncome } = offer;

    /**
     * @todo Удалить строку с RS
     * @description Данный функционал появился в задаче CD-143093, будет удалён в задаче CD-143213
     */
    const isCommercialNewVatTextEnabled = !!config.get<boolean>('Commercial.NewVatText.Enabled');

    /**
     * @todo Удалить параметр функции с RS
     * @description Данный функционал появился в задаче CD-143093, будет удалён в задаче CD-143213
     */
    const { commercial, presenters } = offerHelper(offer, isCommercialNewVatTextEnabled);
    const isFlexOfficeCardSameAsOffice = this.getFlexOfficeCardSameAsOffice();
    const commercialTypeTitle = commercial.getType({
      isOfficeSearch,
      isOfficeInCoworkingTitleVisible: this.getOfficeInCoworkingTitleVisible(),
      isFlexOfficeCardSameAsOffice,
    });
    const isReadyBusiness = commercial.isReadyBusiness();

    const term = (() => {
      switch (offer.dealType) {
        case 'rent':
          return presenters.getCommercialRentPrice();

        case 'sale': {
          const totalArea = parseFloat(offer.totalArea);

          if (offer.bargainTerms.priceType === 'squareMeter' && totalArea > 0) {
            return presenters.getSalePriceWithTotalArea();
          }

          return presenters.getSalePrice();
        }

        default:
          return presenters.getSalePrice();
      }
    })();

    const formattedTerm = term ? `за ${term}` : '';

    const minTerm = offer.dealType === 'rent' ? presenters.getCommercialMinRentPrice() : presenters.getMinSalePrice();
    let subTerm =
      offer.dealType === 'rent'
        ? commercial.isCommercialLand()
          ? commercial.getCommercialLandPerMeterByYear()
          : presenters.getCommercialPerMeterByYear()
        : presenters.getSalePricePerMeter();
    const classType = commercial.getBuildingClassType();
    const minArea =
      !commercial.isCommercialLand() && presenters.getMinArea() ? `${presenters.getMinArea()} м${square}` : null;

    const totalArea = commercial.isCommercialLand()
      ? `${getCommercialLandTitleArea(commercial.getCommercialLandArea(), commercial.getCommercialLandUnitType())}`
      : getCommercialTotalArea(presenters.getTotalArea(), `м${square}`);

    const minAreaMaxArea = minArea && totalArea ? `от ${minArea} до ${totalArea}` : totalArea;
    const vatPrice = commercial.getVatTypeWithVatPrice();
    let tooltipView: React.ReactNode;
    let textAuction = '';
    let textPSN = '';
    const cardTracking = this.props.api.tracking;

    const { cianUserId } = offer.user || {};
    const isPriceShow = !knAuctionCompanies?.includes(Number(cianUserId));

    if (!isPriceShow) {
      let trackTradePopupShow;
      let trackTradePopupClick;
      if (cardTracking) {
        trackTradePopupShow = cardTracking.trackTradePopupShow;
        trackTradePopupClick = cardTracking.trackTradePopupClick;
      }
      textAuction = ' · Электронные торги';
      tooltipView = !isPriceShow && (
        <Tooltip
          containerClassName={this.isCommercialOfferWithMultiAds ? style['tooltip'] : ''}
          customStyles={{
            display: 'inline-flex',
            verticalAlign: 'bottom',
          }}
          icon={<InfoIcon size={20} />}
          iconColor="#2b87db"
          iconSize={20}
          text={<span />}
          onOpen={trackTradePopupShow}
        >
          Объект реализуется через проведение торгов в соответствии с ГК РФ.&nbsp;
          <a
            href="https://support.cian.ru/ru/knowledge_base/art/195/cat/63/"
            rel="noreferrer"
            target="_blank"
            onClick={trackTradePopupClick}
          >
            Справочная информация
          </a>
        </Tooltip>
      );
    }

    if (
      offer.category?.startsWith('freeAppointmentObject') &&
      offer.specialty?.specialties &&
      offer.specialty.specialties.length > 0
    ) {
      textPSN = getMultiPSNString(jsonQuery, offer.specialty.specialties);
    }

    let minAreaPrice;

    if (this.isCommercialOfferWithMultiAds) {
      const { areaParts } = offer;
      const isRent = offer.dealType === 'rent';

      minAreaPrice = getMinAreaPrice(areaParts, offer.bargainTerms.paymentPeriod);

      const paymentPeriodTitle = isRent ? 'в год' : '';

      // Для мультиобъявок:
      // - "minPricePerMeterPerYearRur" есть всегда для объявки типа аренда
      // - "minPricePerMeterRur" есть всегда для объявки типа продажа
      const price = (isRent ? offer.minPricePerMeterPerYearRur : offer.minPricePerMeterRur) as number;

      subTerm = `от ${numberToPrettyString(Math.round(price))} ₽/м\u00b2 ${paymentPeriodTitle}`;
    }

    const incomeTitle = monthlyIncome
      ? `Прибыль ${numberToPrettyString(monthlyIncome.income)} ${getCurrencySymbol(monthlyIncome.currency)}/мес.`
      : '';

    const minTermView = this.isCommercialOfferWithMultiAds && minAreaPrice ? minAreaPrice : minTerm;

    if (!!offer.coworkingOfferType && !isFlexOfficeCardSameAsOffice) {
      return this.renderCoworkingTitle(commercialTypeTitle, offer.coworkingOfferType);
    }

    /**
     * Данные фактоидов для коммерческого мультиобъявления
     */
    const floorsCount = getCommercialFloorsCount(offer.building);
    const totalAreaText = getCommercialTotalAreaForMultiAds(offer.areaParts);
    const buildingClass = getCommercialBuildingClass(offer.building);
    const parkingPlaces = getCommercialBuildingParkingPlaces(offer.building);

    const offerTitleMultiAdsContent = (() => {
      const { title: offerTitle, fullUrl: offerFullUrl, geo, businessShoppingCenter, minArea, totalArea } = offer;
      const bscTitle = prepareCommercialBscTitle(businessShoppingCenter);
      const address = prepareCommercialBuildingMultiAdsAddress(geo);
      const area = getCommercialAreaForMultiAds(minArea, totalArea);
      const priceWithArea = (
        <>
          {area} от <div className={multiAdsOfferStyles['price-partial']}>{minTermView}</div>
        </>
      );

      return (
        <>
          <CommercialTitle
            handleClick={this.props.api.card.onPageJumped.bind(null, 'card_header_in_newlist')}
            href={offerFullUrl}
            type={ECommercialTitle.MultiAds}
          >
            <CommercialTitlePartsWrapper>
              <CommercialTitlePart>{commercialTypeTitle}</CommercialTitlePart>
              {bscTitle ? (
                <CommercialTitlePart>{bscTitle}</CommercialTitlePart>
              ) : (
                <CommercialTitlePart>{address}</CommercialTitlePart>
              )}
              {isPriceShow && <CommercialTitlePart>{priceWithArea}</CommercialTitlePart>}
              {!isPriceShow && <CommercialTitlePart>{area}</CommercialTitlePart>}
              {!isPriceShow && (
                <CommercialTitlePart>
                  Электронные торги
                  {tooltipView}
                </CommercialTitlePart>
              )}
            </CommercialTitlePartsWrapper>
          </CommercialTitle>

          {offerTitle && (
            <CommercialTitle
              handleClick={this.props.api.card.onPageJumped.bind(null, 'card_header_in_newlist')}
              href={offerFullUrl}
              type={ECommercialTitle.SubtitleMultiAds}
            >
              {offerTitle}
            </CommercialTitle>
          )}
        </>
      );
    })();

    const offerTitleContent = (() => {
      const { title: offerTitle, fullUrl: offerFullUrl } = offer;

      return offerTitle ? (
        <div className={style['offer-title-content-container']}>
          <div className={style['header-title']}>
            <CommercialTitle
              handleClick={this.props.api.card.onPageJumped.bind(null, 'card_header_in_newlist')}
              href={offerFullUrl}
              type={ECommercialTitle.Common}
            >
              {`${offerTitle}${textAuction}`}
            </CommercialTitle>
            {tooltipView}
          </div>
          <div className={style['header-subtitle']}>
            {minTerm && minAreaMaxArea
              ? `${commercialTypeTitle} ${minAreaMaxArea} от ${minTerm}${textPSN}`
              : `${commercialTypeTitle} ${totalArea}${isPriceShow ? ` ${formattedTerm}` : ''}${textPSN}`}
            &nbsp;
            {!!classType && <span className={style['header-classType']}>Класс {classType}</span>}
          </div>
        </div>
      ) : (
        <div className={style['offer-title-content-container']}>
          <div className={style['header-title']}>
            <CommercialTitle
              handleClick={this.props.api.card.onPageJumped.bind(null, 'card_header_in_newlist')}
              href={offerFullUrl}
              type={ECommercialTitle.Common}
            >
              {isPriceShow
                ? minTerm && minAreaMaxArea && `${commercialTypeTitle} ${minAreaMaxArea} от ${minTermView}`
                : `${commercialTypeTitle} ${minAreaMaxArea} ${textPSN}`}
              {isPriceShow
                ? (!minTerm || !minAreaMaxArea) && ` ${commercialTypeTitle} ${totalArea} ${formattedTerm}${textPSN}`
                : textAuction}
            </CommercialTitle>
            {tooltipView}
          </div>
          {!!classType && (
            <span className={style['header-subtitle']}>
              <span className={style['header-classType']}>Класс {classType}</span>
            </span>
          )}
        </div>
      );
    })();

    const shouldRenderFactoidsForMultiAdsCommercial = this.isCommercialOfferWithMultiAds;
    const shouldRenderFactoidsForRestCommercial = !this.isCommercialOfferWithMultiAds && isPriceShow;

    return (
      <div className={style['header']}>
        <h3 className={style['header-title']}>
          {this.isCommercialOfferWithMultiAds ? offerTitleMultiAdsContent : offerTitleContent}
        </h3>
        {shouldRenderFactoidsForMultiAdsCommercial && (
          <div className={style['header-subTerm']}>
            <ul className={style['header-subTerm-list']}>
              <CommercialFactoidsWrapper>
                <CommercialFactoid content={totalAreaText} />
                <CommercialFactoid content={floorsCount} />
                <CommercialFactoid content={buildingClass} />
                <CommercialFactoid
                  content={vatPrice as string | undefined}
                  shouldRenderContent={Boolean(!isReadyBusiness && vatPrice && isPriceShow)}
                />
                <CommercialFactoid content={parkingPlaces} />
                <CommercialFactoid
                  content={subTerm}
                  shouldRenderContent={Boolean(!isReadyBusiness && subTerm && isPriceShow)}
                />
                <CommercialFactoid
                  content={incomeTitle}
                  shouldRenderContent={Boolean(isReadyBusiness && incomeTitle && isPriceShow)}
                />
              </CommercialFactoidsWrapper>
            </ul>
          </div>
        )}
        {shouldRenderFactoidsForRestCommercial && (
          <div className={style['header-subTerm']}>
            <ul className={style['header-subTerm-list']}>
              <CommercialFactoidsWrapper>
                <CommercialFactoid
                  content={vatPrice as string | undefined}
                  shouldRenderContent={Boolean(!isReadyBusiness && vatPrice)}
                />
                <CommercialFactoid content={subTerm} shouldRenderContent={Boolean(!isReadyBusiness && subTerm)} />
                <CommercialFactoid
                  content={incomeTitle}
                  shouldRenderContent={Boolean(isReadyBusiness && incomeTitle)}
                />
              </CommercialFactoidsWrapper>
            </ul>
          </div>
        )}
      </div>
    );
  };

  private renderCoworkingTitle(coworkingTitle: string, coworkingOfferType: ECoworkingOfferType): React.ReactNode {
    const { offer, api } = this.props;
    const { title, fullUrl, workplaceCount, bargainTerms, priceTotalPerMonthRur } = offer;
    const { priceForWorkplace } = bargainTerms;

    const isOfficeSearchExperiment = this.getOfficeInCoworkingTitleVisible();
    const priceForWorkplaceForCoworkingSearch = isOfficeSearchExperiment ? null : priceForWorkplace;

    let formattedCoworkingTitle = getFormattedCoworkingTitle(
      coworkingOfferType,
      coworkingTitle,
      extractUndefined(workplaceCount),
      extractUndefined(priceForWorkplaceForCoworkingSearch),
    );

    if (isOfficeSearchExperiment && priceTotalPerMonthRur) {
      formattedCoworkingTitle += ` за ${numberToPrettyString(priceTotalPerMonthRur)} руб./мес.`;
    }

    return (
      <div className={style['header']}>
        <h3 className={style['header-title']}>
          <div>
            <CommercialTitle
              handleClick={api.card.onPageJumped.bind(null, 'card_header_in_newlist')}
              href={fullUrl}
              type={ECommercialTitle.Common}
            >
              {title || formattedCoworkingTitle}
            </CommercialTitle>
            {!!title && (
              <div className={style['header-subtitle-coworking']}>
                <InlineText2>{formattedCoworkingTitle}</InlineText2>
              </div>
            )}
            <div className={style['header-subtitle-count']}>
              <CoworkingSubtitle
                coworkingOfferType={coworkingOfferType}
                isOfficeSearchExperiment={isOfficeSearchExperiment}
                priceForWorkplace={priceForWorkplace}
                workplaceCount={workplaceCount || null}
              />
            </div>
          </div>
        </h3>
      </div>
    );
  }

  private renderAuthorInfo(): JSX.Element {
    const { offer } = this.props;

    const onlineShowLabel = this.renderOnlineShowLabel();

    const containerClassName = cn(style['author_info'], {
      [style['author_info-top']]: offer.isTop3,
      [style['author_info-multi-ads']]: this.isCommercialOfferWithMultiAds,
      [style['author_info--platinum']]: offer.brandingLevel === EBrandingLevel.ThirdLevel,
      [commonStyle['author_online-show']]: onlineShowLabel,
    });

    return (
      <div className={containerClassName}>
        {onlineShowLabel}
        {this.renderPhoneButton()}
        {this.renderElectronicTradingButton()}
        {offer.user && <UserInfo containerStyle={style['user_info']} type="vertical" />}
      </div>
    );
  }

  private renderOnlineShowLabel() {
    const { offer, config } = this.props;
    const onlineShowEnabled = Boolean(config.get<boolean>('onlineShow.isEnabled'));
    const canShowOnline = onlineShowEnabled && Boolean(offer.user && offer.user.canShowOnline);

    return canShowOnline ? (
      <div className={commonStyle['online-show']}>
        <ContactStatus
          labelText="Онлайн-показ"
          tooltipText="Вам могут провести онлайн-показ объекта при помощи видеозвонка в WhatsApp, Viber, FaceTime или другой программы"
          tooltipTitle="Выбирайте не выходя из дома"
        />
      </div>
    ) : null;
  }

  // Телефон справа
  private renderPhoneButton() {
    const {
      api: { tracking },
      offer,
      user,
      isElectronicTrading,
    } = this.props;
    const { chatId, id } = offer;

    const showChats = offer.user && offer.user.isChatsEnabled && !chatId;

    const showChatDialogs = offer.user && offer.user.isChatsEnabled && chatId;

    const canShowAvailability =
      !!offer.user?.agentAvailability?.availableFrom && !!offer.user.agentAvailability.availableTo;

    const phones = offerHelper(offer).presenters.getPhones();

    const gaProducts = JSON.stringify([offerHelper(offer).getAnalyticsInfo()]);

    if (!phones || isElectronicTrading) {
      return null;
    }

    return (
      <div className={style['chats_block']}>
        <StartChatPopup
          disabled={!(showChats && canShowAvailability)}
          gaLabel={offer.gaLabel}
          gaProducts={gaProducts}
          isAuthorised={user.isAuthenticated && canShowAvailability}
        >
          <SimplePhoneButton
            containerStyle={style['phone']}
            isFullWidth={false}
            phones={phones}
            onPhoneOpened={this.props.api.card.onPhoneOpened}
          />
        </StartChatPopup>
        {showChats && (
          <StartChatPopup gaLabel={offer.gaLabel} gaProducts={gaProducts} isAuthorised={user.isAuthenticated}>
            <Button
              beforeIcon={
                <span className={style['message-button']}>
                  Написать<span className={style['hideable']}>&nbsp;сообщение</span>
                </span>
              }
              size="XS"
              theme="fill_secondary"
            />
          </StartChatPopup>
        )}
        {showChatDialogs && (
          <Button
            beforeIcon={
              <>
                Посмотреть<span className={style['hideable']}>&nbsp;сообщение</span>
              </>
            }
            size="XS"
            theme="fill_secondary"
            onClick={e => {
              e.preventDefault();
              if (tracking && tracking.trackGoToMessages) {
                tracking.trackGoToMessages();
              }
              window.location.href = `/dialogs/?offerId=${id}`;
            }}
          />
        )}
      </div>
    );
  }

  private renderElectronicTradingButton() {
    return <JoinElectronicTradingButtonContainer text="Принять участие в торгах" />;
  }

  private onComplainStateChanged = (isReporting: boolean) => {
    this.setState({ isMoreActive: true, isReporting });
  };

  private handleCommentClick = () => {
    if (this.state.isCommentAreaOpened) {
      return;
    }

    this.setState({
      isCommentAreaOpened: true,
    });
  };

  private handleCommentingCompletion = () => {
    if (this.state.isCommentAreaOpened) {
      this.setState({ isCommentAreaOpened: false });
    }
  };

  private onHoverOver = () => {
    this.setState({ galleryControlsHidden: false });
  };

  private onHoverOut = () => {
    this.setState({ galleryControlsHidden: true });
  };

  private onMapButtonClick = () => {
    const { isMapActive } = this.state;
    const { card } = this.props.api;

    this.setState({
      isMapActive: !isMapActive,
    });

    if (isMapActive && card.onMapClosed) {
      card.onMapClosed();
    } else if (!isMapActive && card.onMapOpened) {
      card.onMapOpened();
    }
  };

  /**
   * Open/close callback modal window
   */
  private toggleCallbackModal = (): void => {
    this.setState(({ isCallbackModalOpened }) => ({ isCallbackModalOpened: !isCallbackModalOpened }));
  };

  private getShieldsOnPhotoView(): JSX.Element {
    const { jsonQuery, offer } = this.props;

    return (
      <div className={style['shield-wrapper']} data-testid="shield-wrapper">
        <ShieldsOnPhoto jsonQuery={jsonQuery} offer={offer} />
      </div>
    );
  }

  public readonly onAnalyticsButtonClick = (): void => {
    if (this.props.api.analyticsPopup && this.props.api.analyticsPopup.onButtonClick) {
      this.props.api.analyticsPopup.onButtonClick(this.props.offer.id);
    }
  };

  private get isCommercialOfferWithMultiAds(): boolean {
    return !!this.props.offer.areaParts?.length;
  }
}
