import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';
import { FOfferType } from '../../utils/category';

export const OFFER_TYPE_SELECTED = 'filters/offer_type/OFFER_TYPE_SELECTED';

export interface IOfferTypeChangedAction {
  type: typeof OFFER_TYPE_SELECTED;
  offerType: FOfferType;
}

export function selectOfferType(offerType: FOfferType): IOfferTypeChangedAction {
  return {
    type: OFFER_TYPE_SELECTED,
    offerType,
  };
}

export function offerTypeReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case OFFER_TYPE_SELECTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.selectOfferType(action.offerType),
        },
      };
    default:
      return state;
  }
}
