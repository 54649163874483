import { memo } from 'react';

interface IProps {
  direction: 'right' | 'left';
}

export const ArrowIcon = memo(({ direction }: IProps) => {
  const width = 7;
  const height = 11;
  const center = {
    x: width / 2,
    y: height / 2,
  };

  const transform = {
    left: `rotate(180, ${center.x}, ${center.y})`,
    right: '',
  }[direction];

  return (
    // eslint-disable-next-line react/forbid-elements
    <svg height={height} width={width} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M.293.293a1 1 0 0 0 0 1.414l3.535 3.536L.293 8.778a1 1 0 0 0 1.414 1.414L5.95 5.95a1 1 0 0 0 0-1.414L1.707.293a1 1 0 0 0-1.414 0z"
        fill="currentColor"
        fillRule="evenodd"
        transform={transform}
      />
    </svg>
  );
});

ArrowIcon.displayName = 'ArrowIcon';
