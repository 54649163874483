import classNames from 'classnames';
import * as React from 'react';

import { ISeoState } from 'shared/common/state';
import { IBreadcrumb } from 'shared/offer';

import { Breadcrumbs } from '../../Breadcrumbs';

import * as styles from './index.css';

export interface IHeaderDefaultProps {
  seo: ISeoState;
  breadcrumbs: IBreadcrumb[];
}

export const HeaderDefault: React.FC<IHeaderDefaultProps> = ({ breadcrumbs, seo }) => {
  const { h1 } = seo;

  return (
    <div className={classNames(styles['wrapper'], styles['wrapper_redesign'])}>
      <Breadcrumbs breadcrumbs={breadcrumbs} h1={h1} />
    </div>
  );
};
