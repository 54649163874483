import { IQuestionField, useFormField } from '@cian/frontend-request-form-widget';

import * as React from 'react';

import { NonUndefinable } from '../../../../../../../../packages/JsonQuery';

import { CustomCheckboxButtonGroup as Component, ICustomCheckboxButtonGroup } from './CustomCheckboxButtonGroup';

export const CustomCheckboxButtonGroup: React.FC<IQuestionField> = props => {
  const {
    field: { value },
    helpers: { setValue },
  } = useFormField<ICustomCheckboxButtonGroup['value']>({ name: props.name });

  const options = React.useMemo(
    () =>
      props.answers?.map(answer => ({
        label: answer.label,
        value: answer.value,
      })) || [],
    [],
  );

  const handleChange: ICustomCheckboxButtonGroup['onChange'] = React.useCallback<
    NonUndefinable<ICustomCheckboxButtonGroup['onChange']>
  >((_, values) => setValue(values), []);

  return <Component label={props.label} options={options} value={value} onChange={handleChange} />;
};

CustomCheckboxButtonGroup.displayName = 'CustomCheckboxButtonGroupContainer';
