import { IconSuccess16 } from '@cian/ui-kit/icons';
import { InlineText3 } from '@cian/ui-kit/typography';

import * as React from 'react';

import * as styles from './RosreestrVerified.css';

export const RosreestrVerified: React.FC = () => {
  return (
    <div className={styles['container']}>
      <div className={styles['success-icon-wrapper']}>
        <IconSuccess16 color="primary_100" display="inline-block" />
      </div>
      <div className={styles['text-wrapper']}>
        <InlineText3 color="black_60">Проверено в&nbsp;Росреестре</InlineText3>
      </div>
    </div>
  );
};

RosreestrVerified.displayName = 'RosreestrVerified';
