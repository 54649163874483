import { Label } from '@cian/ui-kit';

import * as React from 'react';

export const OwnerBadge: React.FC = () => {
  return (
    <Label background="success_10" color="success_100">
      Собственник
    </Label>
  );
};
