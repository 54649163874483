import { Label } from '@cian/ui-kit';

import * as React from 'react';

import { WrapElementsSpacer } from 'shared/components/WrapElementsSpacer';

type ElectronicTradingBagdesProps = {
  texts: string[];
};

export const ElectronicTradingBagdes: React.FC<ElectronicTradingBagdesProps> = props => {
  const { texts } = props;

  if (!texts.length) {
    return null;
  }

  return (
    <WrapElementsSpacer horizontalSpace={8} verticalSpace={8}>
      {texts.map((text, index) => (
        <Label background="gray10_100" color="black_100" key={text + index}>
          {text}
        </Label>
      ))}
    </WrapElementsSpacer>
  );
};
