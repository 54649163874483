import { ECompareType, EQuestionActionsTypes, IQuestion, IStep } from '@cian/frontend-request-form-widget';
import { IQuestionField } from '@cian/frontend-request-form-widget/es/types/questionField';

import { ECategoryType, EOfficeType } from 'shared/json_query';

import { ExtendedFieldType } from '../extendedFieldType';

import { BUSINESS_PAGE } from './businessPage';
import { COMMON_OBJECT_STEP } from './commonObjectPage';
import { COWORKING_PAGE } from './coworkingPage';
import { FREE_APPOINTMENT_OBJECT_PAGE } from './freeAppointmentObjectPage';
import { WARWHOUSE_PAGE } from './warehousePage';

export const OBJECT_TYPE_STEP: IStep<IQuestion<IQuestionField<ExtendedFieldType>>> = {
  code: 'objectType',
  question: {
    code: 'objectTypeQuestion',
    title: 'Что будем искать?',
    fields: [
      {
        name: 'dealType',
        type: ExtendedFieldType.RadioButtonGroup,
        label: 'Тип сделки',
        answers: [
          {
            label: 'Снять',
            value: 'rent',
          },
          {
            label: 'Купить',
            value: 'sale',
          },
        ],
        size: {
          l: 12,
          m: 12,
          xs: 12,
        },
      },
      {
        name: 'officeType',
        type: ExtendedFieldType.MultiselectWithoutEmptyChoice,
        label: 'Тип недвижимости',
        answers: [
          { label: 'Офис', value: String(EOfficeType.Office) },
          {
            label: 'Коворкинг',
            value: String(EOfficeType.Coworking),
          },
          { label: 'Торговая площадь', value: String(EOfficeType.TradeArea) },
          { label: 'Склад', value: String(EOfficeType.Warehouse) },
          { label: 'Помещение свободного назначения', value: String(EOfficeType.FreeAppointmentObject) },
          { label: 'Общепит', value: String(EOfficeType.PublicCatering) },
          { label: 'Производство', value: String(EOfficeType.Manufacture) },
          { label: 'Автосервис', value: String(EOfficeType.AutoService) },
          { label: 'Готовый бизнес', value: String(EOfficeType.Business) },
          { label: 'Здание', value: String(EOfficeType.Building) },
          { label: 'Бытовые услуги', value: String(EOfficeType.DomesticServices) },
          { label: 'Коммерческая земля', value: ECategoryType.CommercialLandRent },
        ],
        size: {
          l: 12,
          m: 12,
          xs: 12,
        },
      },
    ],
    actions: [{ type: EQuestionActionsTypes.NextQ, text: 'Далее' }],
  },
  nextStep: [
    {
      conditions: [
        {
          answerField: 'officeType',
          compareType: ECompareType.Eq,
          value: [String(EOfficeType.Coworking)],
        },
      ],
      nextStepCode: COWORKING_PAGE.code,
    },
    {
      conditions: [
        {
          answerField: 'officeType',
          compareType: ECompareType.Eq,
          value: [String(EOfficeType.Business)],
        },
      ],
      nextStepCode: BUSINESS_PAGE.code,
    },
    {
      conditions: [
        {
          answerField: 'officeType',
          compareType: ECompareType.Eq,
          value: [String(EOfficeType.FreeAppointmentObject)],
        },
      ],
      nextStepCode: FREE_APPOINTMENT_OBJECT_PAGE.code,
    },
    {
      conditions: [
        {
          answerField: 'officeType',
          compareType: ECompareType.Eq,
          value: [String(EOfficeType.AutoService)],
        },
      ],
      nextStepCode: FREE_APPOINTMENT_OBJECT_PAGE.code,
    },
    {
      conditions: [
        {
          answerField: 'officeType',
          compareType: ECompareType.Eq,
          value: [String(EOfficeType.PublicCatering)],
        },
      ],
      nextStepCode: FREE_APPOINTMENT_OBJECT_PAGE.code,
    },
    {
      conditions: [
        {
          answerField: 'officeType',
          compareType: ECompareType.Eq,
          value: [String(EOfficeType.DomesticServices)],
        },
      ],
      nextStepCode: FREE_APPOINTMENT_OBJECT_PAGE.code,
    },
    {
      conditions: [
        {
          answerField: 'officeType',
          compareType: ECompareType.Eq,
          value: [String(EOfficeType.Warehouse)],
        },
      ],
      nextStepCode: WARWHOUSE_PAGE.code,
    },
    {
      conditions: [],
      nextStepCode: COMMON_OBJECT_STEP.code,
    },
  ],
};
