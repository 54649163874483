import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const TOUR_3D_TAG_TYPE = 'tour-3d';

export interface ITour3dTag {
  type: 'tour-3d';
}

function isTour3dTag(tag: TTag): tag is ITour3dTag {
  return tag.type === TOUR_3D_TAG_TYPE;
}

export const tour3dDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery['tour_3d'] && jsonQuery['tour_3d'].value;

    if (value) {
      return ensureSingleTagExists(tags, isTour3dTag, () => ({ type: TOUR_3D_TAG_TYPE }));
    } else {
      return ensureTagNotExists(tags, isTour3dTag);
    }
  },
};
