import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export interface IDemolishedInMoscowProgrammTag {
  type: 'advanced-demolished-in-moscow-programm';
  value: boolean;
}

function isDemolishedInMoscowProgrammTag(tag: TTag): tag is IDemolishedInMoscowProgrammTag {
  return tag.type === 'advanced-demolished-in-moscow-programm';
}

export const demolishedInMoscowProgrammTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.demolished_in_moscow_programm && jsonQuery.demolished_in_moscow_programm.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isDemolishedInMoscowProgrammTag, () => ({
        type: 'advanced-demolished-in-moscow-programm',
        value,
      }));
    } else {
      return ensureTagNotExists(tags, isDemolishedInMoscowProgrammTag);
    }
  },
};
