import { IAppState } from '../../../common/state';

export interface ISimplifiedOfferCardPopupsState {
  [id: string]: TPopupTypes[];
}

export type TPopupTypes = 'more' | 'agent' | 'report';

export interface ISimplifiedOfferCardPopupOpen {
  type: 'ISimplifiedOfferCardPopupOpen';
  offerId: number;
  popupType: TPopupTypes;
}

export interface ISimplifiedOfferCardPopupClose {
  type: 'ISimplifiedOfferCardPopupClose';
  offerId: number;
  popupType: TPopupTypes;
}

export const simplifiedOfferCardPopupOpenAction = (offerId: number, popupType: TPopupTypes) => ({
  type: 'ISimplifiedOfferCardPopupOpen',
  offerId,
  popupType,
});

export const simplifiedOfferCardPopupCloseAction = (offerId: number, popupType: TPopupTypes) => ({
  type: 'ISimplifiedOfferCardPopupClose',
  offerId,
  popupType,
});

export type TAction = ISimplifiedOfferCardPopupOpen | ISimplifiedOfferCardPopupClose;

export function simplifiedOfferCardPopupsStateReducer(state: IAppState, action: TAction) {
  switch (action.type) {
    case 'ISimplifiedOfferCardPopupOpen':
      return {
        ...state,
        simplifiedOfferCardPopupsState: {
          ...state.simplifiedOfferCardPopupsState,
          [action.offerId]: [...(state.simplifiedOfferCardPopupsState[action.offerId] || []), action.popupType],
        },
      };
    case 'ISimplifiedOfferCardPopupClose':
      return {
        ...state,
        simplifiedOfferCardPopupsState: {
          ...state.simplifiedOfferCardPopupsState,
          [action.offerId]: [
            ...(state.simplifiedOfferCardPopupsState[action.offerId] || []).filter(item => item !== action.popupType),
          ],
        },
      };
    default:
      return state;
  }
}
