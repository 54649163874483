import { IAppState, TActions } from 'shared/common/state';

import { ClearComparisonNotificationType, SetComparisonNotificationType } from '../comparisonNotification';

export function comparisonNotificationReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case SetComparisonNotificationType:
      return {
        ...state,
        comparisonNotification: {
          text: action.text,
          url: action.url,
          category: action.category,
          offerId: action.offerId,
        },
      };
    case ClearComparisonNotificationType:
      return {
        ...state,
        comparisonNotification: null,
      };
    default:
      return state;
  }
}
