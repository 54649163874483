import { IAppState, TActions } from '../../../common/state';
import { EBuildingGatesType, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TBuildingGatesTypeChangedActionType = 'filters/building_gates/BUILDING_GATES_TYPE_CHANGED';
export const IBuildingGatesTypeChangedActionType =
  'filters/building_gates/BUILDING_GATES_TYPE_CHANGED' as TBuildingGatesTypeChangedActionType;

export interface IBuildingGatesTypeChangedAction {
  type: 'filters/building_gates/BUILDING_GATES_TYPE_CHANGED';
  paramType: EBuildingGatesType;
  value: boolean;
}

export const changeBuildingGates = getParamChangedAction<EBuildingGatesType, TBuildingGatesTypeChangedActionType>(
  IBuildingGatesTypeChangedActionType,
);

export function buildingGatesTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IBuildingGatesTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setBuildingGatesType(action.paramType)
            : jq(state.filters.jsonQuery).unsetBuildingGatesType(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-buildingGates') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetBuildingGatesType(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetBuildingGatesTypes(),
        },
      };

    default:
      return state;
  }
}
