import { ca } from '@cian/analytics';

export function trackMultiOfferClick(label: string) {
  ca('event', {
    action: 'to_multioffer_listing',
    category: 'Listing',
    label,
    name: 'oldevent',
  });
}
