import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IUserIdTagType = 'userId';

export interface IUserIdTag {
  type: 'userId';
  value: number;
}

export const isUserIdTag = (tag: TTag): tag is IUserIdTag => tag.type === IUserIdTagType;

export const userIdTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.id_user && jsonQuery.id_user.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isUserIdTag, () => ({ type: IUserIdTagType, value }));
    } else {
      return ensureTagNotExists(tags, isUserIdTag);
    }
  },
};
