import { IAppState, TActions } from '../../../common/state';

const IApartmentChangedActionType = 'filters/dwelling_status/APARTMENT_CHANGED';
const IPenthouseChangedActionType = 'filters/dwelling_status/PENTHOUSE_CHANGED';

export interface IApartmentChangedAction {
  type: 'filters/dwelling_status/APARTMENT_CHANGED';
  value: boolean | undefined;
}

export interface IPenthouseChangedAction {
  type: 'filters/dwelling_status/PENTHOUSE_CHANGED';
  value: boolean;
}

export function changeApartment(value: boolean | undefined): IApartmentChangedAction {
  return {
    type: IApartmentChangedActionType,
    value,
  };
}

export function changePenthouse(value: boolean): IPenthouseChangedAction {
  return {
    type: IPenthouseChangedActionType,
    value,
  };
}

export function dwellingStatusReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IApartmentChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            apartment:
              (action.value === true && {
                type: 'term',
                value: true,
              }) ||
              undefined,
            only_flat:
              (action.value === false && {
                type: 'term',
                value: true,
              }) ||
              undefined,
          },
        },
      };

    case IPenthouseChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            penthouse:
              (action.value && {
                type: 'term',
                value: action.value,
              }) ||
              undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-dwellingStatus-apartment') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              apartment: undefined,
              only_flat: undefined,
            },
          },
        };
      } else if (action.tag.type === 'advanced-dwellingStatus-penthouse') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              penthouse: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            apartment: undefined,
            only_flat: undefined,
            penthouse: undefined,
          },
        },
      };

    default:
      return state;
  }
}
