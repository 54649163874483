/* eslint-disable no-empty */
import { IJsonQuery } from '../json_query';

export function saveJsonQueryInLocalStorage(jsonQuery: IJsonQuery) {
  /* istanbul ignore next */
  if (typeof window === 'undefined') {
    return;
  }

  try {
    window.localStorage.setItem('__serp_jsonQuery__', JSON.stringify(jsonQuery));
    /* istanbul ignore next */
  } catch (e) {}
}
