import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const INewbuildingCompletionYearTagType = 'advanced-doneYear-years';

export interface INewbuildingCompletionYearTag {
  type: 'advanced-doneYear-years';
  value: number;
}

function isNewbuildingCompletionYearTag(tag: TTag): tag is INewbuildingCompletionYearTag {
  return tag.type === INewbuildingCompletionYearTagType;
}

export const newbuildingCompletionYearTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.year && jsonQuery.year.value;

    let nextTags = tags.filter(
      tag => tag.type !== INewbuildingCompletionYearTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is INewbuildingCompletionYearTag =>
              isNewbuildingCompletionYearTag(tag) && tag.value === value,
            () => ({ type: INewbuildingCompletionYearTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
