import { ArrowTopRight } from '../ArrowTopRight';

import { trackCountrysideTrapCLick } from './tracking';

import * as styles from './CountrysideTrapBanner.css';

interface ICountrysideTrapBannerProps {
  villagesLink: string;
}

export function CountrysideTrapBanner(props: ICountrysideTrapBannerProps) {
  return (
    <div className={styles['wrapper']}>
      <div className={styles['header']}>
        Попробуйте искать&nbsp;
        <a href={props.villagesLink} onClick={trackCountrysideTrapCLick}>
          по посёлкам <ArrowTopRight />
        </a>
      </div>
      <div className={styles['text']}>
        Так вы узнаете больше про застройщика и условия проживания.&nbsp;
        <div className={styles['emoji']} />
      </div>
    </div>
  );
}
