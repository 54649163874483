import { numberToPrettyString, plural } from '@cian/utils';

import { IAreaPart, IBuilding, IGeo, ISpecialty, TCurrency } from 'shared/offer/types';
import { IJsonQuery } from 'shared/repositories/common/json_query';
import { CommercialClassName } from 'shared/types/commercial';

import { OfficeType, TOfficeType } from '../types/json-query';
import { IOfferBSCenter } from '../types/offer';

export const customOfficeTitle = new Map<TOfficeType, string>([
  [OfficeType.Office, 'офис'],
  [OfficeType.TradeArea, 'торговую площадь'],
  [OfficeType.Warehouse, 'склад'],
  [OfficeType.Manufacture, 'производство'],
]);

export const TOP_OFFICE_TYPE: TOfficeType[] = [
  OfficeType.Office,
  OfficeType.TradeArea,
  OfficeType.Warehouse,
  OfficeType.Manufacture,
];

export const IGNORE_OFFICE_TYPE = OfficeType.FreeAppointmentObject as number;

export const MAP_SPECIALTY_OFFICE_TYPE = new Map<TOfficeType, number>([
  [OfficeType.Office, 7026],
  [OfficeType.TradeArea, 7128],
  [OfficeType.Warehouse, 7113],
  [OfficeType.Manufacture, 7104],
]);

/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export const getMultiPSNString = (
  jsonQuery: IJsonQuery,
  specialties: ISpecialty[],
  ignoreOfficeType = IGNORE_OFFICE_TYPE,
): string => {
  const typesFromQuery = (jsonQuery.office_type && jsonQuery.office_type.value.map(i => i as number)) || [];
  const specialtyIds = specialties.map(i => i.id);
  if (!typesFromQuery.includes(ignoreOfficeType)) {
    const topOfficeType = TOP_OFFICE_TYPE.map(officeType => {
      if (typesFromQuery.includes(officeType) && officeType) {
        const specialtyId = MAP_SPECIALTY_OFFICE_TYPE.get(officeType);

        if (specialtyId != null && specialtyIds.includes(specialtyId)) {
          const title = customOfficeTitle.get(officeType);

          if (title) {
            return title.toLowerCase();
          }

          return '';
        }

        return '';
      }

      return '';
    }).filter(f => f !== '');

    if (topOfficeType && topOfficeType.length > 0) {
      const specialtyString = topOfficeType.join(', ');

      return ` · Под ${specialtyString}`;
    }
  }

  return '';
};

/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export function getCurrencySymbol(currency: TCurrency): string {
  switch (currency) {
    case 'eur':
      return '€';
    case 'usd':
      return '$';
    case 'rur':
    default:
      return '₽';
  }
}

/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export function getCommercialTotalAreaForMultiAds(areaParts: IAreaPart[] | undefined): string {
  if (!areaParts) {
    return '';
  }

  const areas = areaParts.reduce((sum, { area }) => (area ? sum + area : sum), 0);

  if (!areas) {
    return '';
  }

  const areasRounded = Math.round(areas);

  return `Доступно ${numberToPrettyString(areasRounded)} м\u00b2`;
}

/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export function getCommercialFloorsCount(building: IBuilding | null): string {
  const floorsCount = building?.floorsCount;

  if (floorsCount) {
    const floorsCountPluralized = plural(floorsCount, ['этаж', 'этажа', 'этажей']);

    return `${floorsCount} ${floorsCountPluralized}`;
  }

  return '';
}

/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export function getCommercialBuildingClass(building: IBuilding | null): string {
  if (building?.classType) {
    const buildingClass = CommercialClassName.get(building?.classType);

    return buildingClass ? `Класс ${buildingClass}` : '';
  }

  return '';
}

/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export function getCommercialBuildingParkingPlaces(building: IBuilding | null): string {
  const placesCount = building?.parking?.placesCount;

  if (placesCount) {
    const placesCountPluralized = plural(placesCount, ['парковочное место', 'парковочных места', 'парковочных мест']);

    return `Доступно ${placesCount} ${placesCountPluralized}`;
  }

  return '';
}

/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export function prepareCommercialBuildingMultiAdsAddress(geo: IGeo | null) {
  const address = geo?.address?.find(({ type }) => type === 'street')?.shortName || null;

  return address;
}

/**
 * Возвращает строки типа:
 * 15 - 50 м2
 * 50 м2 (для случаев, когда minArea и maxArea равны)
 */
/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export function getCommercialAreaForMultiAds(minArea: string | null, maxArea: string | null): string {
  const minValue = discardDecimal(minArea);
  const maxValue = discardDecimal(maxArea);

  if (minValue && maxValue) {
    if (minValue === maxValue) {
      return `от ${numberToPrettyString(minValue)} м\u00B2`;
    }

    return `${numberToPrettyString(minValue)} \u2013 ${numberToPrettyString(maxValue)} м\u00B2`;
  }

  return '';
}

/**
 * Если по какой-то причине число вернулось в виде - 14.0 функция отбрасывает лишний ноль
 * и округляет до 2 знаков после запятой.
 */
/* istanbul ignore next */
// https://jira.cian.tech/browse/CD-157426
export function discardDecimal(value?: string | number | null, length: number = 2): number | null {
  const valueLikeNumber = Number(value);

  if (!value || isNaN(valueLikeNumber)) {
    return null;
  }

  return Number(valueLikeNumber.toFixed(length));
}

export function prepareCommercialBscTitle(businessShoppingCenter: IOfferBSCenter | null): string | null {
  if (!businessShoppingCenter) {
    return null;
  }

  const { name, parentName, useParentName } = businessShoppingCenter;

  if (useParentName) {
    return `${parentName} ${name}`;
  }

  return name;
}
