import { ILogger } from '@cian/logger';

import { mergeDeepRight } from 'ramda';

declare global {
  interface Window {
    ca: ICianAnalytics;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TEventQueueItem = [string, { [key: string]: any }];

interface ICianAnalytics {
  (): void;
  q: TEventQueueItem[];
}

interface IDependencies {
  logger: ILogger;
}

interface IParams {
  pageViewParams: object;
}

/**
 * Модифицирует параметры события pageview на стороне браузера, которое отправляется при перевом рендере страницы.
 * Поскольку объект для события pageview при перевом рендере формируется на ноде, возникают кейсы,
 * когда требуется добавить некоторые параметры на стороне бразуера.
 * Например, если данные параметры хранятся в localStorage/sessionStorage.
 */
export function modifyPageViewAnalyticsEvent({ logger }: IDependencies, { pageViewParams }: IParams) {
  try {
    const eventQueue = window.ca.q;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const pageViewEvent = eventQueue.find(([eventType, eventParams]) => eventParams.name === 'newpage');

    if (!pageViewEvent) {
      throw new Error('pageview analytics event was not found in the analytics event queue');
    }

    pageViewEvent[1] = mergeDeepRight(pageViewEvent[1], pageViewParams);
  } catch (error) {
    logger.error('pageview analytics event was not modified', {
      domain: 'browser/utils#modifyPageViewAnalyticsEvent()',
    });
  }
}
