import * as R from 'ramda';

import { geo, range, term, terms } from './constructors';
import { TJsonQueryKey } from './types';
import { NonUndefinable, Unpacked } from './types/common';
import {
  IJsonQuery,
  IJsonQueryRange,
  IJsonQueryTerm,
  IJsonQueryTerms,
  IJsonQueryType,
  TGeoValue,
} from './types/jsonQuery';

export function setRootType(value: IJsonQueryType): (jsonQuery: IJsonQuery) => IJsonQuery {
  return jsonQuery => ({
    ...jsonQuery,
    _type: value,
  });
}

export function setTerm<T, K extends TJsonQueryKey, V extends IJsonQuery[K] & IJsonQueryTerm<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => (value: NonUndefinable<V['value']> | null) => IJsonQuery {
  return jsonQuery => value => R.assoc(key, term(value), jsonQuery);
}

export function setTerms<T, K extends TJsonQueryKey, V extends IJsonQuery[K] & IJsonQueryTerms<T>>(
  key: K,
): (jsonQuery: IJsonQuery) => (value: NonUndefinable<Unpacked<V['value']>>[] | null) => IJsonQuery {
  return jsonQuery => value => R.assoc(key, terms(value), jsonQuery);
}

export function setRange<K extends TJsonQueryKey, V extends IJsonQuery[K] & IJsonQueryRange>(
  key: K,
): (
  jsonQuery: IJsonQuery,
) => (gte: NonUndefinable<V['value']['gte']> | null, lte: NonUndefinable<V['value']['lte']> | null) => IJsonQuery {
  return jsonQuery => (gte, lte) => R.assoc(key, range(gte, lte), jsonQuery);
}

export function setRangeMin<K extends TJsonQueryKey, V extends IJsonQuery[K] & IJsonQueryRange>(
  key: K,
): (jsonQuery: IJsonQuery) => (gte: NonUndefinable<V['value']['gte']> | null) => IJsonQuery {
  return jsonQuery => gte => R.assoc(key, range(gte, R.pathOr(null, [key, 'value', 'lte'], jsonQuery)), jsonQuery);
}

export function setRangeMax<K extends TJsonQueryKey, V extends IJsonQuery[K] & IJsonQueryRange>(
  key: K,
): (jsonQuery: IJsonQuery) => (lte: NonUndefinable<V['value']['lte']> | null) => IJsonQuery {
  return jsonQuery => lte => R.assoc(key, range(R.pathOr(null, [key, 'value', 'gte'], jsonQuery), lte), jsonQuery);
}

export function setGeo(): (jsonQuery: IJsonQuery) => (value: TGeoValue[]) => IJsonQuery {
  return jsonQuery => value => R.assoc('geo', geo(value), jsonQuery);
}

export function resetTerms<K extends TJsonQueryKey>(keys: K[]): (jsonQuery: IJsonQuery) => IJsonQuery {
  return jsonQuery => {
    let nextJsonQuery = { ...jsonQuery };

    keys.forEach(key => (nextJsonQuery = setTerm(key)(nextJsonQuery)(null)));

    return nextJsonQuery;
  };
}

export function isTerm<T>(value: unknown): value is IJsonQueryTerm<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'term';
}

export function isTerms<T>(value: unknown): value is IJsonQueryTerms<T> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'terms';
}

export function isRange(value: unknown): value is IJsonQueryRange {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof value === 'object' && value !== null && (value as any).type === 'range';
}

export function getTermValue<K extends TJsonQueryKey, V extends IJsonQuery[K] & IJsonQueryTerm<V['value']>>(
  key: K,
): (jsonQuery: IJsonQuery) => V['value'] | null {
  return jsonQuery => {
    const termField = jsonQuery[key];

    return isTerm<V['value']>(termField) ? termField.value : null;
  };
}

export function getTermsValue<K extends TJsonQueryKey, V extends IJsonQuery[K] & IJsonQueryTerms<V['value']>>(
  key: K,
): (jsonQuery: IJsonQuery) => V['value'] | null {
  return jsonQuery => {
    const termsField = jsonQuery[key];

    return isTerms<V['value']>(termsField) ? termsField.value : null;
  };
}

export function getRangeValue<K extends TJsonQueryKey, V extends IJsonQuery[K] & IJsonQueryRange>(
  key: K,
): (jsonQuery: IJsonQuery) => V['value'] | null {
  return jsonQuery => {
    const rangeField = jsonQuery[key];

    return isRange(rangeField) ? rangeField.value : null;
  };
}
