import { THideOfferModel } from './types';

export const defaultConfig: THideOfferModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'hiddenObjects',
  pathApi: '/v1/hide-offer-website/',
};

export function createHideOfferModel(config: Partial<THideOfferModel>): THideOfferModel {
  return { ...defaultConfig, ...config };
}
