import { IAppState, TActions } from '../../../common/state';
import { EConditionType, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TOfficeConditionChangedActionType = 'filters/office_condition/OFFICE_CONDITION_CHANGED';
export const IOfficeConditionChangedActionType =
  'filters/office_condition/OFFICE_CONDITION_CHANGED' as TOfficeConditionChangedActionType;

export interface IOfficeConditionChangedAction {
  type: 'filters/office_condition/OFFICE_CONDITION_CHANGED';
  paramType: EConditionType;
  value: boolean;
}

export const changeOfficeCondition = getParamChangedAction<EConditionType, TOfficeConditionChangedActionType>(
  IOfficeConditionChangedActionType,
);

export function officeConditionTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IOfficeConditionChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setOfficeCondition(action.paramType)
            : jq(state.filters.jsonQuery).unsetOfficeCondition(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-officeCondition') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetOfficeCondition(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetOfficeConditions(),
        },
      };

    default:
      return state;
  }
}
