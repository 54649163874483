import { ModalWindow } from '@cian/ui-kit';

import * as React from 'react';

import { ContentLayout } from './internal/components/ContentLayout';
import { IUserProfilingModal } from './types';

export const UserProfilingModal: React.FC<IUserProfilingModal> = props => (
  <ModalWindow {...props} content={<ContentLayout />} size="M" />
);

UserProfilingModal.displayName = 'UserProfilingModal';
