import * as React from 'react';

import { ETooltipContent } from './constants';

export const getTooltipContent = (n?: number | null): string => {
  switch (n) {
    case 2:
      return ETooltipContent.Second;
    case 3:
      return ETooltipContent.Third;
    case 4:
      return ETooltipContent.Fourth;
    default:
      return ETooltipContent.Nothing;
  }
};

export const stopPropagation = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation();
