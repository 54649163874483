import { IAdFoxBannerContext } from '@cian/adfox/build/react';

import { Component } from 'react';

import { CloseBtn } from 'shared/common/components/close_button';
import { ISimplifieldTGBBannerContext } from 'shared/types/adfox';

const style = require('./index.css');

export interface ITGBProps {
  context: IAdFoxBannerContext | ISimplifieldTGBBannerContext;
}

export interface ITGBState {
  isClosed: boolean;
}

export class TGFullBanner extends Component<ITGBProps, ITGBState> {
  public state = {
    isClosed: false,
  };

  public render() {
    const { annotation, imageURL, link1, link2, link3, metro, reference, site, image, url, label } = this.props.context;

    return this.state.isClosed ? null : (
      <div className={style['container']}>
        <a className={style['main-link']} href={reference || url} rel="noreferrer" target="_blank">
          <div className={style['photo-wrapper']}>
            <img className={style['photo']} src={imageURL || image} />
          </div>
          <div className={style['info']}>
            <div className={style['columns']}>
              {metro ? this.getMetro() : <div className={style['iconWrapper']} />}
              <div className={style['columnDescription']}>
                <div className={style['title']}>{this.props.context['title']}</div>
                <div className={style['description']}>{this.props.context['description']}</div>
                <div className={style['link']}>{link1}</div>
                <div className={style['link']}>{link2}</div>
                <div className={style['link']}>{link3}</div>
              </div>
              <div className={style['columnPhone']}>
                <div className={style['title']}>{this.props.context['phone']}</div>
              </div>
            </div>
            <div className={style['annotation-wrapper']}>
              <div className={style['annotationSite']}>{site}</div>
              <div className={style['rightColumn']}>
                <div className={style['annotation']}>{annotation}</div>
                {!!label && <div className={style['annotationDescription']}>{label}</div>}
              </div>
            </div>
          </div>
        </a>
        <CloseBtn isTransparent className={style['close']} onClick={this.close} />
      </div>
    );
  }

  private getMetro() {
    return (
      <div className={style['columnIndex']}>
        <div className={style['iconWrapper']}>
          {this.props.context['metroColor'] && (
            <div
              className={style['metroColor']}
              style={{
                backgroundColor: `${this.props.context['metroColor']}`,
              }}
            />
          )}
        </div>
        <div className={style['metroName']}>{this.props.context['metro']}</div>
        <div className={style['metroDistance']}>{this.props.context['metroDistance']}</div>
        <div className={style['location']}>{this.props.context['location']}</div>
      </div>
    );
  }

  private close = () => {
    this.setState({
      isClosed: true,
    });
  };
}
