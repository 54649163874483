import { IJsonQuery, jq } from '../json_query';
import { IMakeRequest } from '../utils/request';

interface IConvertJsonQueriesToSeoUrlsResponse {
  status: string;
  data: {
    urls: string[];
  };
}

export function convertJsonQueriesToSeoUrls(makeRequest: IMakeRequest, jsonQuery: IJsonQuery): Promise<string[]> {
  // ЧПУ в пагинации может быть только для первых двух страниц выдачи
  const jsonQueries = [1, 2].map(pageNumber => jq(jsonQuery).setPageNumber(pageNumber));

  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/convert-json-query-to-seo-url/',
    parameters: {
      jsonQueries,
    },
    method: 'POST',
  }).then((response: IConvertJsonQueriesToSeoUrlsResponse) => {
    if (response.status === 'ok') {
      return response.data.urls;
    }

    return [];
  });
}
