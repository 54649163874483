import { CustomTooltip, UIText3 } from '@cian/ui-kit';

import { Component, createRef } from 'react';

import { QuestionMark } from './QuestionMark';

import * as styles from './AuctionApplyNewBetHint.css';

export interface IAuctionApplyNewBetHintProps {
  /** Новая ставка (возвращается в случае понижения ставки) */
  nextBet: number;
  /** Дата применения новой ставки (возвращается в случае понижения ставки) */
  applyNewBetDate: string | null | undefined;
}

export interface IAuctionApplyNewBetHintState {
  isShow: boolean;
}

export class AuctionApplyNewBetHint extends Component<IAuctionApplyNewBetHintProps, IAuctionApplyNewBetHintState> {
  private hintWrapperRef = createRef<HTMLDivElement>();

  public state = {
    isShow: false,
  };

  public render() {
    const { nextBet, applyNewBetDate } = this.props;
    const { isShow } = this.state;

    return (
      <>
        <CustomTooltip
          anchorRef={this.hintWrapperRef}
          arrow={false}
          content={
            <div className={styles['popup_container']}>
              <UIText3>
                {applyNewBetDate ? (
                  <>
                    Новая ставка {nextBet} ₽ в сутки.
                    <br />
                    Применится в {applyNewBetDate}
                  </>
                ) : (
                  <>
                    Обновите результаты поиска, чтобы увидеть
                    <br />
                    своё объявление на новой странице
                  </>
                )}
              </UIText3>
            </div>
          }
          open={isShow}
          outside={true}
          placement="top-end"
          theme="white"
          onClose={this.setPopupState(false)}
        />
        <div className={styles['hint_wrapper']} ref={this.hintWrapperRef}>
          <div onClick={this.setPopupState(true)}>
            <QuestionMark />
          </div>
        </div>
      </>
    );
  }

  private setPopupState = (isShow: boolean) => () => {
    this.setState({ isShow });
  };
}
