import { numberToPrettyString } from '@cian/utils';
import { plural } from '@cian/utils/lib/shared/plural';

import { Component } from 'react';

import { IBSCenterInfo } from 'shared/api/bs_centers';
import { EBuildingType, IJsonQuery } from 'shared/json_query';
import { IBreadcrumb } from 'shared/offer';
import { BuildingTypeBackend, CommercialClassName } from 'shared/types/commercial';
import { FDealType, FOfferType, dealTypeFromJsonQuery, offerTypeFromJsonQuery } from 'shared/utils/category';

import { OfficialRepresentative } from './OfficialRepresentative';

const style = require('./index.css');

const DealTypeName = new Map<FDealType, string>([
  [FDealType.Sale, 'Купить'],
  [FDealType.Rent, 'Снять'],
]);

const OfferTypeName = new Map<FOfferType, string>([
  [FOfferType.AutoService, 'помещение под автосервис'],
  [FOfferType.Building, 'здание'],
  [FOfferType.Business, 'готовый бизнес'],
  [FOfferType.DomesticServices, 'помещение под бытовые услуги'],
  [FOfferType.FreeAppointmentObject, 'площадь свободного назначения'],
  [FOfferType.Garage, 'гараж'],
  [FOfferType.Manufacture, 'помещение под производство'],
  [FOfferType.Office, 'офис'],
  [FOfferType.PublicCatering, 'общепит'],
  [FOfferType.TradeArea, 'торговую площадь'],
  [FOfferType.Warehouse, 'склад'],
]);

const OfferTypeName2 = new Map<FOfferType, string>([
  [FOfferType.AutoService, 'помещений под автосервис'],
  [FOfferType.Building, 'зданий'],
  [FOfferType.Business, 'готового бизнеса'],
  [FOfferType.DomesticServices, 'помещений под бытовые услуги'],
  [FOfferType.FreeAppointmentObject, 'площадей свободного назначения'],
  [FOfferType.Garage, 'гаражей'],
  [FOfferType.Manufacture, 'помещений под производство'],
  [FOfferType.Office, 'офисов'],
  [FOfferType.PublicCatering, 'общепита'],
  [FOfferType.TradeArea, 'торговых площадей'],
  [FOfferType.Warehouse, 'складов'],
]);

export const BuildingTypeName = new Map<EBuildingType, string>([
  [EBuildingType.BusinessCenter, 'бизнес-центре'],
  [EBuildingType.ShoppingEntertainmentCenter, 'торгово-развлекательном центре'],
  [EBuildingType.OldFund, 'старом фонде'],
  [EBuildingType.OfficeBuilding, 'офисном здании'],
  [EBuildingType.BusinessPark, 'бизнес-парке'],
  [EBuildingType.OfficeIndustrialComplex, 'офисно-производственном комплексе'],
  [EBuildingType.OfficeWarehouseComplex, 'офисно-складском комплексе'],
  [EBuildingType.WarehouseComplex, 'складском комплексе'],
  [EBuildingType.IndustrialWarehouseComplex, 'производственно-складском комплексе'],
  [EBuildingType.LogisticsCenter, 'логистическом центре'],
  [EBuildingType.Warehouse, 'складе'],
  [EBuildingType.AdministrativeBuilding, 'административном здании'],
  [EBuildingType.IndustrialPark, 'индустриальном парке'],
  [EBuildingType.LogisticsComplex, 'логистическом комплексе'],
  [EBuildingType.ShoppingBusinessComplex, 'торгово-деловом комплексе'],
  [EBuildingType.MultifunctionalComplex, 'многофункциональном комплексе'],
  [EBuildingType.OfficeResidentialComplex, 'офисно-жилом комплексе'],
  [EBuildingType.TradingOfficeComplex, 'торгово-офисном комплексе'],
  [EBuildingType.OfficeHotelComplex, 'офисно-гостиничном комплексе'],
  [EBuildingType.BusinessCenter2, 'деловом центре'],
  [EBuildingType.Mansion, 'особняке'],
  [EBuildingType.Technopark, 'технопарке'],
  [EBuildingType.BusinessQuarter, 'бизнес-квартале'],
  [EBuildingType.StandaloneBuilding, 'отдельно стоящем здании'],
  [EBuildingType.ResidentialHouse, 'жилом доме'],
  [EBuildingType.ResidentialComplex, 'жилом комплексе'],
  [EBuildingType.ShoppingCenter, 'торговом центре'],
  [EBuildingType.SpecializedShoppingCenter, 'специализированном торговом центре'],
  [EBuildingType.ShoppingCommunityCenter, 'торгово-общественном центре'],
  [EBuildingType.Outlet, 'аутлете'],
  [EBuildingType.IndustrialComplex, 'производственном комплексе'],
  [EBuildingType.IndustrialSite, 'промплощадке'],
  [EBuildingType.ManufacturingFacility, 'производственном цеху'],
  [EBuildingType.FreeAppointmentObject, 'объекте свободного назначения'],
  [EBuildingType.PropertyComplex, 'имущественном комплексе'],
  [EBuildingType.ManufactureBuilding, 'производственном здании'],
  [EBuildingType.ModularBuilding, 'модульном здании'],
  [EBuildingType.FreeBuilding, 'свободном здании'],
  [EBuildingType.OfficeWarehouse, 'офисно-складском здании'],
  [EBuildingType.OfficeCenter, 'офисном центре'],
  [EBuildingType.OfficeQuarter, 'офисном квартале'],
  [EBuildingType.BusinessQuarter2, 'деловом квартале'],
  [EBuildingType.BusinessHouse, 'деловом доме'],
  [EBuildingType.TradingHouse, 'торговом доме'],
]);

export interface IHeaderBSCenterProps {
  jsonQuery: IJsonQuery;
  breadcrumbs: IBreadcrumb[];
  bsCenterInfo: IBSCenterInfo;
}

export class HeaderBSCenter extends Component<IHeaderBSCenterProps> {
  public render() {
    const { jsonQuery, bsCenterInfo } = this.props;
    const dealType = dealTypeFromJsonQuery(jsonQuery);
    const offerType = offerTypeFromJsonQuery(jsonQuery);

    const buildingType = BuildingTypeBackend.get(bsCenterInfo.buildingType) as EBuildingType;

    return (
      <div className={style.container}>
        <div itemScope className={style.wrapper} itemType="http://schema.org/BreadcrumbList">
          {this.props.breadcrumbs.length > 0 && (
            <div className={style['breadcrumbs']}>
              {this.props.breadcrumbs.map((breadcrumb, index, arr) => {
                return (
                  <span itemScope itemProp="itemListElement" itemType="http://schema.org/ListItem" key={index}>
                    <a
                      className={style['breadcrumbs-link']}
                      href={breadcrumb.url || undefined}
                      itemProp="url"
                      title={breadcrumb.title || ''}
                    >
                      <span itemProp="name">{breadcrumb.title}</span>
                      <meta content={`${index + 1}`} itemProp="position" />
                      {index < arr.length - 1 ? <div className={style['breadcrumbs-delimiter']} /> : null}
                    </a>
                  </span>
                );
              })}
            </div>
          )}
          <div className={style['content']}>
            <div className={style['content-info']}>
              <h1 className={style['content-title']}>
                {/* tslint:disable-next-line:max-line-length */}
                {DealTypeName.get(dealType)} {OfferTypeName.get(offerType)}{' '}
                {buildingType === EBuildingType.Warehouse || buildingType === EBuildingType.IndustrialSite ? 'на' : 'в'}{' '}
                <a href={bsCenterInfo.url} rel="noreferrer" target="_blank">
                  {BuildingTypeName.get(buildingType)} &laquo;{bsCenterInfo.name}&raquo;
                </a>
                {bsCenterInfo.seoCityName ? ` в ${bsCenterInfo.seoCityName}` : ''}
              </h1>
              {/* tslint:disable-next-line:max-line-length */}
              <div className={style['content-address']}>
                м. {bsCenterInfo.underground}, {bsCenterInfo.fullAddress} ・ Класс{' '}
                {CommercialClassName.get(bsCenterInfo.buildingClassType)}
                {bsCenterInfo.buildYear ? ` ・ ${bsCenterInfo.buildYear} год постройки` : ''} ・{' '}
                {numberToPrettyString(Number(bsCenterInfo.totalArea))} м&sup2;
              </div>
              <div className={style['content-desc']}>
                <p className={style['content-text']}>
                  {/* tslint:disable-next-line:max-line-length */}
                  Актуальные предложения об аренде и продаже {OfferTypeName2.get(offerType)}{' '}
                  {buildingType === EBuildingType.Warehouse || buildingType === EBuildingType.IndustrialSite
                    ? 'на'
                    : 'в'}{' '}
                  <a href={bsCenterInfo.url} rel="noreferrer" target="_blank">
                    {BuildingTypeName.get(buildingType)} &laquo;{bsCenterInfo.name}&raquo;
                  </a>{' '}
                  по адресу: {bsCenterInfo.fullAddress}: цены, фото, описание объектов, информация об инфраструктуре.
                </p>
                <ul className={style['content-offers']}>
                  {bsCenterInfo.offersRentCount > 0 && (
                    <li>
                      В аренду:&nbsp;
                      <a href={bsCenterInfo.offersRentLink} rel="noreferrer" target="_blank">
                        {bsCenterInfo.offersRentCount}&nbsp;
                        {plural(bsCenterInfo.offersRentCount, ['помещение', 'помещения', 'помещений'])}
                      </a>
                    </li>
                  )}
                  {bsCenterInfo.offersSaleCount > 0 && (
                    <li>
                      В продаже:&nbsp;
                      <a href={bsCenterInfo.offersSaleLink} rel="noreferrer" target="_blank">
                        {bsCenterInfo.offersSaleCount}&nbsp;
                        {plural(bsCenterInfo.offersSaleCount, ['помещение', 'помещения', 'помещений'])}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              {bsCenterInfo.primaryOfficialRepresentativeAgent && (
                <OfficialRepresentative agent={bsCenterInfo.primaryOfficialRepresentativeAgent} />
              )}
            </div>
            <div className={style['content-image']}>
              <img src={bsCenterInfo.fullUrlDefaultFoto} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
