import { IHttpApi } from '@cian/http-api';
import { IModel, IModelResponse } from '@cian/http-api/shared/model';

import { getBrowserFingerprint } from '../utils/fingerprint';

/**
 *  Данной апишки нет в swagger монолита.
 */

export interface IGetComplaintResponse200 extends IModelResponse<IComplaint[]> {
  statusCode: 200;
}

export type TGetComplaintModel = IModel<void, IGetComplaintResponse200>;

export const getComplaintModel: TGetComplaintModel = {
  apiType: 'legacy',
  assertStatusCodes: [200],
  method: 'GET',
  microserviceName: 'moderationComplaints',
  pathApi: '/v2/get-complaint-types/',
};

export interface IComplaint {
  name: string;
  title: string;
  items: IComplaint[];
  hint: string;
  blockType: EBlockType;
  id: string;
  categories: number[];
  isCommentRequired: boolean;
  url?: string | null;
}

export enum EBlockType {
  Transitional = 'transitional',
  Textarea = 'textarea',
  Finish = 'finish',
  Link = 'link',
}

export function getComplaints(httpApi: IHttpApi, category: number, region: number) {
  return httpApi
    .fetch<{ categoryId: number; regionId: number }, IGetComplaintResponse200>({
      ...getComplaintModel,
      pathApi: `${getComplaintModel.pathApi}`,
      parameters: { categoryId: category, regionId: region },
    })
    .then(res => res.response);
}

export interface IAddComplaintRequest {
  realty_id: number;
  block_id: string;
  name: string;
  message?: string;
}

export type TAddComplaintModel = IModel<IAddComplaintRequest, IModelResponse<IAddComplaintParamsResponse>>;

export const addComplaintModel: TAddComplaintModel = {
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'moderationComplaints',
  pathApi: '/v2/create-complaint/',
};

interface IAddComplaintParams {
  offerId: number;
  complaintId: string;
  message?: string;
}

interface IAddComplaintParamsResponse {
  status: 'ok';
  complaintId: number;
}

export function addComplaint(httpApi: IHttpApi, parameters: IAddComplaintParams) {
  return httpApi
    .fetch(
      {
        ...addComplaintModel,
        parameters: {
          complaintOnSiteId: parameters.complaintId,
          message: parameters.message ? parameters.message : null,
          realtyObjectId: parameters.offerId,
          userContextInfo: {
            uniqueId: getBrowserFingerprint(),
          },
        },
      },
      {
        requestConfig: {
          headers: [['Content-Type', 'application/json']],
        },
      },
    )
    .then(res => res.response);
}
