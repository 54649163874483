import { connect } from 'react-redux';

import { IAppState } from 'shared/common/state';

import { HeaderBSCenter } from './index';

export const HeaderBSCenterContainer = connect((state: IAppState) => ({
  jsonQuery: state.results.jsonQuery,
  breadcrumbs: state.breadcrumbs,
  bsCenterInfo: state.results.bsCenterInfo,
}))(HeaderBSCenter);
