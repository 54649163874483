import { IAppState, TActions } from '../../../common/state';
import { EMetroTravelType, jq } from '../../../json_query';
import { FDealType, dealTypeFromJsonQuery } from '../../../utils/category';
import { getDefaultTravelTimeForType } from '../../../utils/underground';

export interface IMetroOpenedAction {
  type: 'filters/map/METRO_OPENED';
}

export interface IMetroClosedAction {
  type: 'filters/map/METRO_CLOSED';
}

export function openMetroModal(): IMetroOpenedAction {
  return {
    type: 'filters/map/METRO_OPENED',
  };
}

export function closeMetroModal(): IMetroClosedAction {
  return {
    type: 'filters/map/METRO_CLOSED',
  };
}

export function toggleMetroModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/map/METRO_OPENED': {
      const dealType = dealTypeFromJsonQuery(state.filters.jsonQuery);
      let jsonQuery = state.filters.jsonQuery;

      if ((dealType & FDealType.Rent) !== 0 && !jsonQuery.only_foot) {
        jsonQuery = jq(state.filters.jsonQuery).setUndergroundTravel(
          EMetroTravelType.MapFoot,
          getDefaultTravelTimeForType(EMetroTravelType.MapFoot),
        );
      }

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery,
          backup: {
            jsonQuery: state.filters.jsonQuery,
            tags: state.filters.tags,
          },
        },
        isMetroModalVisible: true,
      };
    }
    case 'filters/map/METRO_CLOSED':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...state.filters.backup,
          backup: undefined,
        },
        isMetroModalVisible: false,
      };
    default:
      return state;
  }
}
