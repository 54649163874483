import { IAppState, TActions } from '../../../common/state';
import { EGarageKind, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TGarageKindChangedActionType = 'filters/garage/GARAGE_KIND_CHANGED';
export const IGarageKindChangedActionType = 'filters/garage/GARAGE_KIND_CHANGED' as TGarageKindChangedActionType;

export interface IGarageKindChangedAction {
  type: 'filters/garage/GARAGE_KIND_CHANGED';
  paramType: EGarageKind;
  value: boolean;
}

export const changeGarageKind = getParamChangedAction<EGarageKind, TGarageKindChangedActionType>(
  IGarageKindChangedActionType,
);

export function garageKindReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IGarageKindChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setGarageKind(action.paramType)
            : jq(state.filters.jsonQuery).unsetGarageKind(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-garageKind') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetGarageKind(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetGarageKinds(),
        },
      };

    default:
      return state;
  }
}
