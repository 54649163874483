import { connect } from 'react-redux';

import { IOffer } from 'shared/offer';

import { TAgentRating } from '../../../api/agent';
import { IQsToUris } from '../../../api/offers';
import { Dispatch, IAppState } from '../../../common/state';
import { changeFavorite } from '../../../filters/state/favorite';
import { isRedesignExperimentEnabled } from '../../../selectors/isRedesignExperimentEnabled';
import { normalizeAbGroup } from '../../../utils/normalizeAbGroup';
import { prepareTrackingData } from '../../../utils/prepareTrackingData';
import { isGoogleBot, isYandexBot } from '../../../utils/user_agent';
import { IHidingOfferInfo, hideOfferAction } from '../../state/hide_offer';
import { hideMotivationPopup } from '../../state/login_motivation_popup';
import { updateAgentRating } from '../../state/offer_card/agent';
import { changeOfferComments } from '../../state/offer_card/comments';
import { addOfferComplaint } from '../../state/offer_card/complaints';
import {
  EFeedbackComplaint,
  IComplaintFeedbackBody,
  sendFeedbackComplaint,
  statusChanger,
} from '../../state/offer_card/feedback_complaint';
import {
  simplifiedOfferCardPopupCloseAction,
  simplifiedOfferCardPopupOpenAction,
} from '../../state/offer_card/simplified_card_popups';
import { addOfferToComparison } from '../../state/offersComparison/actions/addOfferToComparison';
import { deleteOfferFromComparison } from '../../state/offersComparison/actions/deleteOfferFromComparison';
import {
  ICoordinatesOffers,
  requestSuggestionsDistances,
  setQsToUris,
  setSuggestionDistancesSeoText,
} from '../../state/suggestions';

import { ISuggestionsDispatchProps, ISuggestionsStateProps, Suggestions } from './index';

export const mapStateToProps = (state: IAppState): ISuggestionsStateProps => {
  const {
    aggregatedOffers: aggregatedOffersCount,
    extendedOffersCount,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    jsonQuery,
    offers,
    queryString,
    qsToUris,
    ratingBlockedAgents,
    totalOffers,
  } = state.results;
  const { abUseExperiments } = state;

  const { pathname: urlPath } = new URL(state.results.fullUrl);

  const {
    currentPath,
    currentSubdomain,
    loginMotivationPopupOnFavorite,
    simplifiedOfferCardPopupsState,
    userAgent,
    config,
    hideOffer,
    complaintFeedbackFrom,
    maxAuctionBet,
    maxAuctionService,
    userGADataLayerData,
    deviceType,
    soprApi,
    excludedUtilitiesTermsRegions,
    featureToggle,
    knAuctionCompanies,
  } = state;

  /* istanbul ignore next */
  const isPopupsOpened = () => {
    const popupsStateKeys = Object.keys(simplifiedOfferCardPopupsState).map(Number);

    return popupsStateKeys.some(offerId => Boolean(simplifiedOfferCardPopupsState[offerId].length));
  };

  return {
    abUseExperiments,
    aggregatedOffersCount,
    extendedOffersCount,
    abGroup: normalizeAbGroup(userGADataLayerData && userGADataLayerData.abGroup),
    offersQty: totalOffers,
    baseUrl: config.get('apiBaseUrl') || '',
    breadcrumbs: state.breadcrumbs,
    commentingBlockedOffers,
    commentingBlockedAgents,
    commentingErroneousOffers,
    commentingErroneousAgents,
    currentPageNumber: jsonQuery.page ? jsonQuery.page.value : 1,
    currentPath,
    currentSubdomain,
    isBot: isGoogleBot(userAgent) || isYandexBot(userAgent),
    // Является ли текущая выдача ЧПУ
    isPersonToPersonURL: Boolean(urlPath && !urlPath.startsWith('/cat.php')),
    isPrintEnabled: state.print.enabled,
    isPopupsOpened,
    jsonQuery,
    logger: state.logger,
    makeRequest: state.makeRequest,
    offers,
    offersPerPage: Number(config.get('serp.offersPerPage')),
    queryString,
    maxAuctionBet,
    maxAuctionService,
    qsToUris,
    ratingBlockedAgents,
    searchOffersApiBaseUrl: state.searchOffersApiBaseUrl,
    shownId: loginMotivationPopupOnFavorite.shownId,
    suggestions: state.results.suggestions,
    suggestionsDistances: state.suggestionsDistances,
    suggestionDistancesSeoText: state.suggestionDistancesSeoText,
    suggestionsQuery: state.results.suggestionsQuery,
    user: state.user,
    userGADataLayerData: state.userGADataLayerData,
    hideOffer,
    // лимит избранного для неавторизованного юзера
    favoritesLimitForUnauthUser: 5,
    directionsModal: state.filters.directionsModal,
    mlRankingGuid: state.mlRankingGuid,
    complaintsFormStatus: complaintFeedbackFrom.status,
    soprApi,
    deviceType,
    excludedUtilitiesTermsRegions,
    isRedesignEnabled: isRedesignExperimentEnabled(state),
    featureToggle,
    trackingData: prepareTrackingData(state.filters, state.results, state.breadcrumbs, userGADataLayerData),
    knAuctionCompanies,
  };
};

/* istanbul ignore next */
export const mapDispatchToProps = (dispatch: Dispatch): ISuggestionsDispatchProps => {
  return {
    onFavoriteChanged: (offer: IOffer, isFavorite: boolean) => {
      dispatch(changeFavorite(offer, isFavorite));
    },
    requestSuggestionsDistances: (coordinatesOffers?: ICoordinatesOffers[]) => {
      dispatch(requestSuggestionsDistances(coordinatesOffers));
    },
    closePopup: () => {
      dispatch(hideMotivationPopup());
    },
    onAgentRatingChanged: (offer: IOffer, rating: TAgentRating) => {
      dispatch(updateAgentRating(offer, rating));
    },
    onComplaintSent: (offerId: number, name: string, message?: string) => {
      dispatch(addOfferComplaint(offerId, name, message));
    },
    onOfferCommentsChanged: (offer: IOffer, commentOffer: string | undefined, commentAgent: string | undefined) => {
      dispatch(changeOfferComments(offer, commentOffer, commentAgent));
    },
    onPopupMoreOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'more'));
    },
    onPopupReportOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'report'));
    },
    onPopupMoreClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'more'));
    },
    onPopupReportClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'report'));
    },
    onUserInfoPopupOpen: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupOpenAction(offerId, 'agent'));
    },
    onUserInfoPopupClose: (offerId: number) => {
      dispatch(simplifiedOfferCardPopupCloseAction(offerId, 'agent'));
    },
    setQsToUris: (qsToUris: IQsToUris) => {
      dispatch(setQsToUris(qsToUris));
    },
    setSuggestionDistancesSeoText: (text: string) => {
      dispatch(setSuggestionDistancesSeoText(text));
    },
    hideOfferAction: (hidingOffer: IHidingOfferInfo) => {
      dispatch(hideOfferAction(hidingOffer));
    },
    sendComplaintFeedback: (body: IComplaintFeedbackBody) => {
      dispatch(sendFeedbackComplaint(body));
    },
    statusChanger: (status: EFeedbackComplaint) => {
      dispatch(statusChanger(status));
    },
    addToComparison: ({ offerId }) => {
      dispatch(addOfferToComparison({ offerId }));
    },
    deleteFromComparison: ({ offerId }) => {
      dispatch(deleteOfferFromComparison({ offerId }));
    },
  };
};

export const SuggestionsContainer = connect<ISuggestionsStateProps, ISuggestionsDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(Suggestions);
