import { UIHeading4, UIHeading5, UIText2 } from '@cian/ui-kit';

import { Component } from 'react';

import { getMlVasDescription, getMlVasName } from '../../../utils/name';

import * as styles from './index.css';

export interface IVasListProps {
  /** Отсортированный список мнемоник VAS'ов объявления */
  vasList: string[];
}

/**
 * Компонент для отображения списка примененных VAS с описанием каждого VAS
 */
export class VasList extends Component<IVasListProps, object> {
  public render() {
    const { vasList } = this.props;

    return (
      <div className={styles['container']}>
        <div className={styles['header']}>
          <UIHeading4>Действуют услуги</UIHeading4>
        </div>
        <div className={styles['list']}>
          {vasList.map(vas => {
            return (
              <div className={styles['vas']} key={vas}>
                <div className={styles['description']}>
                  <div className={styles['description-header']}>
                    <UIHeading5 color="warning_100">{getMlVasName(vas, true)}</UIHeading5>
                  </div>
                  <div className={styles['text']}>
                    <UIText2>{getMlVasDescription(vas)}</UIText2>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <a className={styles['link']} href="https://www.cian.ru/promo/adv/" rel="noreferrer" target="_blank">
          <UIText2 color="primary_100">Узнать подробнее</UIText2>
        </a>
      </div>
    );
  }
}
