export type TMapAreaType = 'polygon' | 'circle';
export const MapAreaType = {
  Polygon: 'polygon' as const,
  Circle: 'circle' as const,
};

export interface IBaseMapObjectData {
  type: TMapAreaType;
  name: string;
}

export abstract class BaseDrawObject<TDrawObjectData, TDrawObjectModel> {
  public name: string;
  protected map: IYmapsMap;
  protected ymaps: YMaps.IYMaps;

  protected areaObject: TDrawObjectData;
  protected areaGeoObject: YMaps.IGeoObject;
  protected editor: YMaps.IGeometryEditor;

  protected showMenu: (posX: number, posY: number, area: BaseDrawObject<TDrawObjectData, TDrawObjectModel>) => void;
  protected hideMenu: () => void;
  protected updateHandler: () => void;

  protected hideMenuBind: () => void;
  protected updateHandlerBind: () => void;
  protected drawingStopHandlerBind: () => void;

  public abstract getCoords: () => TDrawObjectData;

  protected abstract setOptions(): void;
  protected abstract createDrawObject(drawObjectModel?: TDrawObjectModel): TDrawObjectData;
  protected abstract createDrawGeoObject(ymaps: YMaps.IYMaps, drawObjectData: TDrawObjectData): YMaps.IGeoObject;

  public constructor(
    ymaps: YMaps.IYMaps,
    map: IYmapsMap,
    onRemove: (posX: number, posY: number, editedObject: BaseDrawObject<TDrawObjectData, TDrawObjectModel>) => void,
    onHide: () => void,
    onUpdate: () => void,
    name: string,
  ) {
    this.ymaps = ymaps;
    this.map = map;
    this.name = name;

    this.showMenu = onRemove;
    this.hideMenu = onHide;
    this.updateHandler = onUpdate;

    this.hideMenuBind = () => this.hideMenu();
    this.updateHandlerBind = () => this.updateHandler();
    this.drawingStopHandlerBind = () => this.drawingStopHandler();
  }

  public stopDraw() {
    this.editor.stopDrawing();
  }

  protected onStartDraw<T>(drawObjectModel?: TDrawObjectModel): Promise<T> {
    this.areaObject = this.createDrawObject(drawObjectModel);
    this.areaGeoObject = this.createDrawGeoObject(this.ymaps, this.areaObject);
    this.editor = this.areaGeoObject.editor;

    this.setOptions();
    this.bindEvents();

    this.map.geoObjects.add(this.areaGeoObject);

    return this.editor.startDrawing<T>();
  }

  public getGeoObject = (): YMaps.IGeoObject => this.areaGeoObject;

  public delete = () => {
    this.unbindEvents();
    this.editor.stopDrawing();
    this.map.geoObjects.remove(this.areaGeoObject);
  };

  protected bindEvents() {
    this.map.events.add('click', this.hideMenuBind);
    this.areaGeoObject.events.add('dragend', this.updateHandlerBind);
    this.areaGeoObject.events.add('beforedrag', this.hideMenuBind);
    this.editor.events.add('drawingstop', this.drawingStopHandlerBind);
  }

  protected unbindEvents() {
    this.map.events.remove('click', this.hideMenuBind);
    this.areaGeoObject.events.remove('dragend', this.updateHandlerBind);
    this.areaGeoObject.events.remove('beforedrag', this.hideMenuBind);
    this.editor.events.remove('drawingstop', this.drawingStopHandlerBind);
  }

  protected drawingStopHandler() {
    this.updateHandler();
  }
}
