import { NonUndefinable } from './types/common';
import { IJsonQueryGeo, IJsonQueryRange, IJsonQueryTerm, IJsonQueryTerms, TGeoValue } from './types/jsonQuery';

export function term<T>(value: NonUndefinable<T> | null): T extends null ? undefined : IJsonQueryTerm<T>;
export function term<T>(value: NonUndefinable<T> | null): IJsonQueryTerm<T> | undefined {
  if (value === null) {
    return undefined;
  }

  return {
    type: 'term',
    value,
  };
}

export function terms<T>(value: NonUndefinable<T>[] | null): T extends null ? undefined : IJsonQueryTerms<T>;
export function terms<T>(value: NonUndefinable<T>[] | null): IJsonQueryTerms<T> | undefined {
  if (!value || value.length === 0) {
    return undefined;
  }

  return {
    type: 'terms',
    value,
  };
}

export function range(gte: number | null, lte: number | null): IJsonQueryRange | undefined {
  if (gte === null && lte === null) {
    return undefined;
  }

  return {
    type: 'range',
    value: {
      gte: gte !== null ? gte : undefined,
      lte: lte !== null ? lte : undefined,
    },
  };
}

export function geo(value: TGeoValue[]): IJsonQueryGeo | undefined {
  if (value.length === 0) {
    return undefined;
  }

  return {
    type: 'geo',
    value,
  };
}
