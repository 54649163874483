// TODO: вынести функционал в разные файлы
import { equals } from 'ramda';

import { TCoordinate } from '../json_query';
import { ICookieRegion } from '../types/cookieRegion';
import { ILocation, TLocation } from '../types/location';

export const coordsNumberToString = (coord: YMaps.TCoord): TCoordinate => [String(coord[0]), String(coord[1])];

export const coordsArrayNumberToString = (coords: YMaps.TCoord[]): TCoordinate[] =>
  coords.map(coord => [String(coord[0]), String(coord[1])] as TCoordinate);

export const coordsIsEqual = (coords1: TCoordinate[], coords2: TCoordinate[]) =>
  equals(coords1.concat().sort(), coords2.concat().sort());

export const circlesAreEqual = (
  circle1: { radius: number; center: TCoordinate },
  circle2: { radius: number; center: TCoordinate },
): boolean =>
  circle1.radius === circle2.radius &&
  circle1.center[0] === circle2.center[0] &&
  circle1.center[1] === circle2.center[1];

export const MoscowId = 1;
export const SpbId = 2;

export const MoscowAndMOId = -1;
export const SpbAndLOId = -2;

export const MOId = 4593;
export const LOId = 4588;

export const moscowMoIds = [MoscowId, MOId];
export const spbLoIds = [SpbId, LOId];

export function getIsMoscowIds(ids: number[]): boolean {
  return ids.length === 1 && ids[0] === MoscowId;
}

export function getIsSpbIds(ids: number[]): boolean {
  return ids.length === 1 && ids[0] === SpbId;
}

export function getIsMoIds(ids: number[]): boolean {
  return ids.length === 1 && ids[0] === MOId;
}

export function getIsLoIds(ids: number[]): boolean {
  return ids.length === 1 && ids[0] === LOId;
}

export function isMoscowMoIds(ids: number[]): boolean {
  const sortedIds = ids.concat().sort();

  if (ids.length === 1) {
    return sortedIds[0] === MoscowAndMOId;
  } else if (ids.length === 2) {
    return sortedIds[0] === moscowMoIds[0] && sortedIds[1] === moscowMoIds[1];
  } else {
    return false;
  }
}

export function isSpbLoIds(ids: number[]): boolean {
  const sortedIds = ids.concat().sort();

  if (ids.length === 1) {
    return sortedIds[0] === SpbAndLOId;
  } else if (ids.length === 2) {
    return sortedIds[0] === spbLoIds[0] && sortedIds[1] === spbLoIds[1];
  } else {
    return false;
  }
}

export function getRegionIds(location: TLocation): number[] {
  if (location === 'moscow_mo') {
    return moscowMoIds;
  } else if (location === 'spb_lo') {
    return spbLoIds;
  } else {
    return [location.id];
  }
}

export const isGeoAvailable = (availableLocations: number[], currentLocationRaw: TLocation) =>
  tempNormalizeLocation(currentLocationRaw).some(current =>
    availableLocations.some(available => current.parentId === available || current.id === available),
  );

export const getRegionId = (location: TLocation): number =>
  (location === 'moscow_mo' && -1) ||
  (location === 'spb_lo' && -2) ||
  (location as ILocation).parentId ||
  (location as ILocation).id;

// https://cianru.atlassian.net/browse/CD-29666 - регионы взяты из этой таски.
export const commercialOffRepRegions = [
  -2,
  -1,
  1,
  2,
  MOId,
  LOId,
  4608,
  4966,
  4820,
  4998,
  4584,
  4885,
  4596,
  176245,
  4560,
  4777,
  4618,
  4959,
  4606,
];

// TODO: temporary solution, awaits for https://cianru.atlassian.net/browse/CD-11090
export const newbuildingsRegions = [
  -2, -1, 1, 2, 4560, 4561, 4566, 4567, 4574, 4580, 4584, 4585, 4588, 4593, 4596, 4598, 4603, 4606, 4608, 4609, 4612,
  4618, 4623, 4625, 4630, 4636,
];

export const isNewbuildingsRegion = (regionId: number) => newbuildingsRegions.includes(regionId);

export function locationHasHighways(location: TLocation) {
  if (location === 'spb_lo') {
    return false;
  }

  return location === 'moscow_mo' || location.hasHighway;
}

/**
 * Временное решение для конвертации `moscow_mo` и `spb_lo` в нормальный вид
 *
 * @export
 * @param {(ILocation | 'moscow_mo' | 'spb_lo')} location
 * @returns {ILocation}
 */
export function tempNormalizeLocation(location: TLocation): ILocation[] {
  if (location === 'moscow_mo') {
    return [
      {
        boundedBy: {
          upperCorner: {
            lat: 56.021224,
            lng: 37.967428,
          },
          lowerCorner: {
            lat: 55.142175,
            lng: 36.803101,
          },
        },
        hasMetro: true,
        hasNeighbors: false,
        displayName: 'Москва',
        name: 'Москва',
        hasHighway: false,
        hasDistricts: true,
        lng: 37.6213,
        lat: 55.7557,
        fullName: 'Москва',
        id: 1,
        parentId: null,
        mainTownId: null,
      },
      {
        boundedBy: {
          upperCorner: {
            lat: 56.958512,
            lng: 40.205688,
          },
          lowerCorner: {
            lat: 54.255696,
            lng: 35.148496,
          },
        },
        mainTownId: 1,
        hasMetro: false,
        hasNeighbors: false,
        displayName: 'Московская область',
        name: 'Московская',
        hasHighway: true,
        hasDistricts: false,
        lng: 37.6213,
        lat: 55.7557,
        fullName: 'Московская область',
        id: 4593,
        parentId: null,
      },
    ];
  } else if (location === 'spb_lo') {
    return [
      {
        boundedBy: {
          upperCorner: {
            lat: 60.244837,
            lng: 30.759691,
          },
          lowerCorner: {
            lat: 59.633783,
            lng: 29.42981,
          },
        },
        hasMetro: true,
        hasNeighbors: false,
        displayName: 'Санкт-Петербург',
        name: 'Санкт-Петербург',
        hasHighway: false,
        hasDistricts: true,
        lng: 30.3058,
        lat: 59.9385,
        fullName: 'Санкт-Петербург',
        id: 2,
        parentId: null,
        mainTownId: null,
      },
      {
        boundedBy: {
          upperCorner: {
            lat: 61.329293,
            lng: 35.701312,
          },
          lowerCorner: {
            lat: 58.417336,
            lng: 26.440169,
          },
        },
        mainTownId: 2,
        hasMetro: false,
        hasNeighbors: false,
        displayName: 'Ленинградская область',
        name: 'Ленинградская',
        hasHighway: false,
        hasDistricts: false,
        lng: 30.3058,
        lat: 59.9385,
        fullName: 'Ленинградская область',
        id: 4588,
        parentId: null,
      },
    ];
  }

  return [location];
}

const DEFAULT_VILLAGE_LINK = '/kottedzhnye-poselki/';

export const getVillagesQueryString = (currentLocation: TLocation): string => {
  let regionId = getRegionId(currentLocation);
  if ([-1, 1].includes(regionId)) {
    regionId = MOId;
  } else if ([-2, 2].includes(regionId)) {
    regionId = LOId;
  }

  return `locationId=${regionId}&locationType=location&objectType%5B0%5D=5`;
};

export const getVillagesLink = (qs: string) => `${DEFAULT_VILLAGE_LINK}?${qs.replace('&objectType%5B0%5D=5', '')}`;

export const getCookieRegion = (currentLocation: TLocation): ICookieRegion => {
  let sessionRegionId;
  let sessionMainTownRegionId;

  if (currentLocation === 'moscow_mo') {
    sessionRegionId = sessionMainTownRegionId = 1;
  } else if (currentLocation === 'spb_lo') {
    sessionRegionId = sessionMainTownRegionId = 2;
  } else {
    sessionRegionId = currentLocation.id;
    sessionMainTownRegionId = currentLocation.mainTownId ? currentLocation.mainTownId : currentLocation.id;
  }

  return { sessionRegionId, sessionMainTownRegionId };
};
