import classNames from 'classnames';
import * as React from 'react';

import * as styles from './CommercialTitle.css';

export enum ECommercialTitle {
  Common = 'common',
  MultiAds = 'multi-ads',
  SubtitleMultiAds = 'subtitle-multi-ads',
}

export interface ICommercialTitleProps {
  href: string;
  handleClick(): void;
  type?: ECommercialTitle;
}

export const CommercialTitle: React.FC<React.PropsWithChildren<ICommercialTitleProps>> = props => {
  const { children, href, handleClick, type = ECommercialTitle.Common } = props;

  return (
    <a
      className={classNames(styles['header-link'], styles[type])}
      href={href}
      rel="noreferrer"
      target="_blank"
      onClick={handleClick}
    >
      {children}
    </a>
  );
};
