import { TAdFoxBannerTemplate } from '@cian/adfox-component';

import { Base64 } from 'js-base64';
import * as React from 'react';

import { IMortgageBannerContext, ISimplifieldTGBBannerContext } from 'shared/types/adfox';

import { useApplicationContext } from '../../../../containers/ApplicationContext';
import { NativeTGBBannerContainer } from '../../../../containers/NativeTGBBanner';
import { MortgageSimpleBannerContainer } from '../../templates/mortgage';
import { AdFoxBannerTrackerContainer } from '../container';
import { TAdFoxBannerTrackerProps } from '../tracker';

const OWN_BUNDLE_NAMES = ['banner.transfer'];
const OWN_TYPES = ['tgb_nativedesc', 'mortgage_banner_new'];

export interface IAdFoxTemplateParams {
  type: string;
}

export type TAdFoxTemplatesParams = TAdFoxBannerTrackerProps &
  IAdFoxTemplateParams &
  (ISimplifieldTGBBannerContext | IMortgageBannerContext);

export const OwnTemplates: React.FC<TAdFoxBannerTemplate> = props => {
  const ctx = useApplicationContext();
  const { bundleName, bundleParams } = props;

  if (bundleName && OWN_BUNDLE_NAMES.includes(bundleName) && bundleParams) {
    if (bundleName === 'banner.transfer' && bundleParams.htmlBase64) {
      try {
        const decodedParams = Base64.decode(bundleParams.htmlBase64);
        const parsedDecodedParams = JSON.parse(decodedParams);

        if (isParsedParamsValid(parsedDecodedParams)) {
          if (OWN_TYPES.includes(parsedDecodedParams.type)) {
            switch (parsedDecodedParams.type) {
              case 'tgb_nativedesc':
                return (
                  <NativeTGBBannerContainer context={{ ...parsedDecodedParams, bannerId: bundleParams.bannerId }} />
                );
              case 'mortgage_banner_new': {
                const context = { text: parsedDecodedParams.text, reference: parsedDecodedParams.url };

                return <MortgageSimpleBannerContainer context={context} />;
              }
            }
          }
        }
      } catch (e) {
        ctx.logger.error(e, {
          message: '[AdFox] Failed to decode/parse bundleParams',
          bannerId: props.bundleParams?.bannerId,
        });
      }
    }
  }

  return <AdFoxBannerTrackerContainer {...props} />;
};

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
