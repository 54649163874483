import * as React from 'react';

import * as styles from './styles.css';

export const WrapperWithBorder: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles['root']} data-test-id="commercial-filter-wrapper">
      {children}
    </div>
  );
};

WrapperWithBorder.displayName = 'WrapperWithBorder';
