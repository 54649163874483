import { makeContextModule } from '@cian/react-utils';

import { IOffer } from '../repositories/search-offers.legacy/v2/types';
import { CardContext } from '../types/CardContext';

export const {
  Context: { Provider: CardContextProvider, Consumer: CardContextConsumer },
  useContext: useCardContext,
  withContext: withCardContext,
} = makeContextModule<CardContext>({
  contextName: 'CardContext',
});

export function useCardOfferContext(): IOffer {
  return useCardContext().offer;
}
