import { ca } from '@cian/analytics';

export function trackOpenOfficeCalculator() {
  ca('event', {
    name: 'oldevent',
    category: 'CalcOfis_popup',
    action: 'open',
    label: '',
  });
}

export function trackCloseOfficeCalculator() {
  ca('event', {
    name: 'oldevent',
    category: 'CalcOfis_popup',
    action: 'close',
    label: '',
  });
}

export function trackCalculateOfficeCalculator() {
  ca('event', {
    name: 'oldevent',
    category: 'CalcOfis_popup',
    action: 'calculate',
    label: '',
  });
}
