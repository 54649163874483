import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getJsonQuery } from '../../../selectors/getJsonQuery';
import { ISimplifieldTGBBannerContext } from '../../../types/adfox';
import { isCommercial, offerTypeFromJsonQuery } from '../../../utils/category';
import { TGSimplifieldBannerWrapper } from '../../components/adfox/new/TGSimplifieldBannerWrapper';
import { TGFullBanner } from '../../components/adfox/templates/tgb_in_card/full';
import { TGSimplifiedBannerContainer } from '../../components/adfox/templates/tgb_in_card/simplified';

export interface INativeTGBBannerContainerProps {
  context: ISimplifieldTGBBannerContext;
}

export function NativeTGBBannerContainer({ context }: INativeTGBBannerContainerProps) {
  const jsonQuery = useSelector(getJsonQuery);

  const isCommercialListing = useMemo(() => {
    const offerType = offerTypeFromJsonQuery(jsonQuery);

    return isCommercial(offerType);
  }, [jsonQuery]);

  const locationId = useMemo(() => (jsonQuery.region ? jsonQuery.region.value[0] : undefined), [jsonQuery]);

  if (isCommercialListing) {
    return <TGFullBanner context={context} />;
  }

  return (
    <TGSimplifieldBannerWrapper>
      <TGSimplifiedBannerContainer context={context} locationId={locationId} />
    </TGSimplifieldBannerWrapper>
  );
}
