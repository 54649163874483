import {
  IAddOfferToComparisonResponse400,
  IAddOfferToComparisonResponseError,
} from 'shared/repositories/offers-comparison/v1/add-offer-to-comparison';

export const mapDeleteOfferFromComparisonResponseError = (
  response: IAddOfferToComparisonResponseError,
): IAddOfferToComparisonResponse400 => {
  return {
    statusCode: 400,
    headers: [],
    response: {
      errors: response.errors,
      message: response.message,
    },
  };
};
