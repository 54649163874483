import { IRadioGroupProps, Paragraph2, RadioButtonGroup } from '@cian/ui-kit';

import * as React from 'react';

import * as styles from './styles.css';

export interface ICustomRadioButtonGroup {
  label?: string;
  onChange: IRadioGroupProps['onChange'];
  options: IRadioGroupProps['options'];
  value: IRadioGroupProps['value'];
}

export const CustomRadioButtonGroup: React.FC<ICustomRadioButtonGroup> = ({ label, onChange, options, value }) => {
  return (
    <div className={styles['root']}>
      {label && (
        <div className={styles['label']}>
          <Paragraph2>{label}</Paragraph2>
        </div>
      )}
      <RadioButtonGroup options={options} value={value} onChange={onChange} />
    </div>
  );
};

CustomRadioButtonGroup.displayName = 'CustomRadioButtonGroup';
