import * as React from 'react';

import * as styles from './CommercialTitlePart.css';

export const CommercialTitlePart: React.FC<React.PropsWithChildren> = props => {
  const { children } = props;

  const isEmptyContent = !!React.Children.map(children, child => child?.toString().trim())?.every(content => !content);

  if (!React.Children.count(children) || isEmptyContent) {
    return null;
  }

  return <li className={styles['title-part-item']}>{children}</li>;
};
