import { IconButton, IconClose16, Outside } from '@cian/ui-kit';

import * as PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import type { IStartChatButtonContext, IStartChatButtonProps, IStartChatButtonState } from './types';

import * as styles from './StartChatPopup.css';

export class StartChatPopup extends PureComponent<
  React.PropsWithChildren<IStartChatButtonProps>,
  IStartChatButtonState
> {
  public readonly context: IStartChatButtonContext;

  public static readonly contextTypes: Record<keyof StartChatPopup['context'], PropTypes.Requireable<unknown>> = {
    api: PropTypes.object,
    httpApi: PropTypes.object,
    offer: PropTypes.object,
    offerHelper: PropTypes.object,
    captchaSpamSiteKey: PropTypes.string,
  };

  public constructor(props: IStartChatButtonProps) {
    super(props);

    this.state = { showChats: false };
  }

  public render(): JSX.Element {
    const { children } = this.props;
    const { showChats } = this.state;

    return (
      <div>
        {showChats && (
          <Outside onOutside={this.handleClickOutside}>
            <div className={styles['container']}>
              <span className={styles['close']}>
                <IconButton icon={<IconClose16 />} onClick={this.handleClickOutside} />
              </span>
              <iframe className={styles['iframe']} src={this.chatsIframeSrc} />
            </div>
          </Outside>
        )}
        <div onClick={this.handleWriteMessageClick}>{children}</div>
      </div>
    );
  }

  private get chatsIframeSrc(): string {
    const { gaProducts, gaLabel } = this.props;

    const { offer } = this.context;
    const { id, dealType, offerType, cianUserId } = offer;

    const companionName = this.companionName;

    const urlSearchParams = new URLSearchParams({
      hostType: 'frame',
      offerId: id.toString(),
      dealType,
      offerType,
      pageType: 'Listing',
    });

    if (companionName) {
      urlSearchParams.set('companionName', encodeURIComponent(companionName));
    }

    if (cianUserId) {
      urlSearchParams.set('companionId', encodeURIComponent(cianUserId));
    }

    if (gaProducts) {
      urlSearchParams.set('gaProducts', encodeURIComponent(gaProducts));
    }

    if (gaLabel) {
      urlSearchParams.set('gaLabel', encodeURIComponent(gaLabel));
    }

    return `/dialogs/?${urlSearchParams.toString()}`;
  }

  private get companionName(): string {
    const { offerHelper } = this.context;

    const userId = offerHelper.presenters.getCianUserId();
    const agencyName = offerHelper.presenters.getAgentName();

    return agencyName || `ID ${userId}`;
  }

  private readonly handleWriteMessageClick: VoidFunction = () => {
    const { disabled, isAuthorised, gaLabel } = this.props;

    if (!disabled) {
      const { tracking } = this.context.api;

      tracking.trackFirstMessageClick?.(gaLabel);

      if (isAuthorised === false) {
        window.waitForLoginPopup?.('chats');
      } else {
        tracking.trackFirstMessageOpen?.(gaLabel);

        this.setState({ showChats: true });
      }
    }
  };

  private readonly handleClickOutside: VoidFunction = () => {
    this.setState({ showChats: false });
  };
}
