import { IQuestion, IStep } from '@cian/frontend-request-form-widget';
import { IQuestionField } from '@cian/frontend-request-form-widget/es/types/questionField';

import { ICommercialSpecialty } from 'shared/api/commercial_specialties';

import { ExtendedFieldType } from '../constants';
import { BUSINESS_PAGE } from '../constants/stepConfig';

export const getBusinessPageSchema = (
  specialties: ICommercialSpecialty[],
): IStep<IQuestion<IQuestionField<ExtendedFieldType>>> => ({
  ...BUSINESS_PAGE,
  question: {
    ...BUSINESS_PAGE.question,
    fields: [
      BUSINESS_PAGE.question.fields[0],
      {
        type: ExtendedFieldType.Multiselect,
        name: 'specialties',
        label: 'Назначение',
        placeholder: 'Выберите',
        answers:
          specialties?.map(specialty => ({ label: specialty.rusName || '', value: String(specialty.id) || '' })) || [],
      },
      ...BUSINESS_PAGE.question.fields.slice(1),
    ],
  },
});
