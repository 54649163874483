import { SummarySection } from '../../components/Summary';
import { ModerateLinkButtonContainer } from '../ModerateButtonContainer';

import { SummaryHeaderContainer } from './containers/SummaryHeaderContainer';
import { SummaryMapViewButtonContainer } from './containers/SummaryMapViewButtonContainer';
import { SummarySortDropdownContainer } from './containers/SummarySortDropdownContainer';

export function SummaryContainer() {
  return (
    <SummarySection
      leftColumn={
        <>
          <SummaryHeaderContainer />
          <SummarySortDropdownContainer />
          <SummaryMapViewButtonContainer />
        </>
      }
      rightColumn={<ModerateLinkButtonContainer />}
    />
  );
}
