import * as React from 'react';

/**
 * Иконка фотоаппарата для мока отсутствующих фотографий галереи царь-карточки
 */
export const IconPreviewPlaceholder: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg height="21" viewBox="0 0 24 21" width="24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.5 4.5h-3.75L16.2.6C15.9.3 15.45 0 15 0H9c-.45 0-.9.3-1.2.6L5.25 4.5H1.5C.6 4.5 0 5.1 0 6v13.5c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5V6c0-.9-.6-1.5-1.5-1.5zM12 16.5c-2.55 0-4.5-1.95-4.5-4.5S9.45 7.5 12 7.5s4.5 1.95 4.5 4.5-1.95 4.5-4.5 4.5z"
      fill="#E4E4E4"
      fillRule="nonzero"
      opacity=".9"
    />
  </svg>
);
