import { IJsonQuery } from '../json_query';

export interface IJsonQueryRedirect {
  redirectData: {
    location: string;
    statusCode?: number;
  };
}

export interface IJsonQueryNotFound {
  notFound: true;
}

export interface IJsonQuerySuccess {
  jsonQuery: IJsonQuery;
}

export type IJsonQueryResponse = IJsonQueryRedirect | IJsonQueryNotFound | IJsonQuerySuccess;

export function isJsonQueryRedirect(data: IJsonQueryResponse): data is IJsonQueryRedirect {
  return 'redirectData' in data && !!data.redirectData;
}

export function isJsonQueryNotFound(data: IJsonQueryResponse): data is IJsonQueryNotFound {
  return 'notFound' in data;
}
