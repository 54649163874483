import * as React from 'react';

type IAnalyticsIconProps = React.HTMLAttributes<SVGElement>;

export const IconAnalytics: React.FC<IAnalyticsIconProps> = props => (
  // eslint-disable-next-line react/forbid-elements
  <svg {...props} fill="none" viewBox="0 0 37 33" xmlns="http://www.w3.org/2000/svg">
    <path
      clipRule="evenodd"
      d="M27.6 2H24V0h7v7h-2V3.4L18.7 13.7l-.7.7-.7-.7-3.3-3.3-9.3 9.3-1.4-1.4 10-10 .7-.7.7.7 3.3 3.3L27.6 2zM25 14v17h-3V21a3 3 0 00-3-3h-2a3 3 0 00-3 3v10h-3v-4a3 3 0 00-3-3H6a3 3 0 00-3 3v4H0v2h36.7v-2H33V14a3 3 0 00-3-3h-2a3 3 0 00-3 3zm6 17V14c0-.6-.4-1-1-1h-2a1 1 0 00-1 1v17h4zm-11-2v2h-4V21c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v8zM9 31v-4c0-.6-.4-1-1-1H6a1 1 0 00-1 1v4h4z"
      fill="#2b87db"
      fillRule="evenodd"
    />
  </svg>
);

IconAnalytics.displayName = 'IconAnalytics';
