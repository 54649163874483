import { debounce } from 'throttle-debounce';

import { IMakeRequest } from '../utils/request';

export interface IErrorInfo {
  error: string;
  isAdblock: boolean;
  bannerType: string;
}

/**
 * Этот чудокод дергается в том месте, которое перерисовывается на каждое изменение redux стейта,
 * поэтому добавлен debounce, чтобы апишка пореже дергалась
 * **/
export const sendStats = debounce(1500, (makeRequest: IMakeRequest, errorType: string, bannerType: string) => {
  const headers: [string, string][] = [['Content-Type', 'application/json']];

  makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/ajax/stats/',
    parameters: [
      {
        name: `adfox.errors.${fixMetric(errorType)}.${fixMetric(bannerType)}`,
        type: 'counter',
        value: '1',
      },
    ],
    headers,
    method: 'POST',
  });
});

function fixMetric(value: string) {
  return value.replace(/\d+/g, '').replace(/_/g, '-');
}
