import { EBuildingElevatorType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBuildingElevatorTypesTagType = 'advanced-buildingElevator';

export interface IBuildingElevatorTypesTag {
  type: 'advanced-buildingElevator';
  value: EBuildingElevatorType;
}

const isBuildingElevatorTypesTag = (tag: TTag): tag is IBuildingElevatorTypesTag =>
  tag.type === IBuildingElevatorTypesTagType;

export const buildingElevatorTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.building_lift_types_type && jsonQuery.building_lift_types_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IBuildingElevatorTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EBuildingElevatorType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IBuildingElevatorTypesTag => isBuildingElevatorTypesTag(tag) && tag.value === value,
            () => ({ type: IBuildingElevatorTypesTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
