import { FormWidget, IFormWidgetProps } from '@cian/frontend-request-form-widget';

import * as React from 'react';

import { CustomCheckboxButtonGroup } from './internal/components/CustomCheckboxButtonGroup';
import { CustomRadioButtonGroupContainer } from './internal/components/CustomRadioButtonGroup';
import { MultiselectContainer } from './internal/components/Multiselect';
import { MultiselectWithoutEmptyChoice } from './internal/components/MultiselectWithoutEmptyChoice';
import { OneChoiceSwitcher } from './internal/components/OneChoiceSwitcher';
import { QuestionLayout } from './internal/components/QuestionLayout';
import { GeoSuggestContainer } from './internal/components/geoSuggest';
import { ExtendedFieldType } from './internal/constants';

export type TForm = Pick<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  IFormWidgetProps<any, any>,
  | 'firstStepCode'
  | 'initialFormValue'
  | 'skipSteps'
  | 'initialStepCode'
  | 'stepsSchema'
  | 'onSubmit'
  | 'onNextStep'
  | 'onPrevStep'
>;

export const Form: React.FC<TForm> = ({
  firstStepCode,
  initialFormValue,
  skipSteps,
  initialStepCode,
  stepsSchema,
  onSubmit,
  onNextStep,
  onPrevStep,
}) => (
  <FormWidget
    components={{
      QuestionLayoutComponent: QuestionLayout,
    }}
    fieldMapperExtension={{
      [ExtendedFieldType.RadioButtonGroup]: CustomRadioButtonGroupContainer,
      [ExtendedFieldType.Multiselect]: MultiselectContainer,
      [ExtendedFieldType.MultiselectWithoutEmptyChoice]: MultiselectWithoutEmptyChoice,
      [ExtendedFieldType.CheckboxButtonGroup]: CustomCheckboxButtonGroup,
      [ExtendedFieldType.OneChoice]: OneChoiceSwitcher,
      [ExtendedFieldType.GeoSuggest]: GeoSuggestContainer,
    }}
    firstStepCode={firstStepCode}
    initialFormValue={initialFormValue}
    initialStepCode={initialStepCode}
    skipSteps={skipSteps}
    stepsSchema={stepsSchema}
    onNextStep={onNextStep}
    onPrevStep={onPrevStep}
    onSubmit={onSubmit}
  />
);

Form.displayName = 'Form';
