import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IHiddenBaseTagType = 'is-in-hidden-base';

export interface IHiddenBaseTag {
  type: 'is-in-hidden-base';
}

function isHiddenBaseTag(tag: TTag): tag is IHiddenBaseTag {
  return tag.type === IHiddenBaseTagType;
}

export const hiddenBaseTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.is_in_hidden_base && jsonQuery.is_in_hidden_base.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isHiddenBaseTag, () => ({ type: IHiddenBaseTagType }));
    } else {
      return ensureTagNotExists(tags, isHiddenBaseTag);
    }
  },
};
