import { IJsonQuery } from '../json_query';
import { IOffer } from '../offer';
import { IMakeRequest } from '../utils/request';

export interface IQsToUris {
  [key: string]: string;
}

interface IGetOffersResponse {
  offersSerialized: IOffer[];
  qsToUris: IQsToUris;
}

export interface IGetSuggestionIds {
  jsonQuery: IJsonQuery;
  offerIds: number[];
  pageNumber: number;
  queryString: string;
}

export interface IInfiniteSearchResult {
  itemId: number;
  modelVersion: number;
  score: number;
}

export interface IGetSuggestionIdsResponse {
  infiniteSearchResult: IInfiniteSearchResult[];
}

export interface IOffersMLResponse {
  offersSerialized: IOffer[];
  qsToUris: IQsToUris;
}

export const getOffers = (
  makeRequest: IMakeRequest,
  searchOffersApiBaseUrl: string,
  cianOfferIds: number[],
  jsonQuery: IJsonQuery,
): Promise<IOffersMLResponse> => {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'search-offers',
    pathApi: '/v1/get-offers-by-ids-desktop/',
    parameters: {
      cianOfferIds,
      jsonQuery,
    },
    method: 'POST',
  }).then((response: IGetOffersResponse) => {
    const { offersSerialized, qsToUris } = response;

    return {
      offersSerialized,
      qsToUris,
    };
  });
};

export const getSuggestionIds = (
  makeRequest: IMakeRequest,
  searchOffersApiBaseUrl: string,
  params: IGetSuggestionIds,
): Promise<IInfiniteSearchResult[]> => {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'search-offers',
    pathApi: '/v1/get-infinite-search-result-desktop/',
    parameters: params,
    method: 'POST',
  }).then((response: IGetSuggestionIdsResponse) => {
    return response.infiniteSearchResult;
  });
};
