import { mergeStyles } from '@cian/utils';

import { Component } from 'react';

import { IOffer } from 'shared/offer';
import { IPhotoLabel } from 'shared/repositories/search-offers.legacy/v2/types/photoLabels';
import { isArrayWithItems } from 'shared/utils';

import { CommercialCardAPI } from '../../CommercialCard';
import { PhotoLabels } from '../PhotoLabels';
import { IGalleryProps } from '../gallery';
import { HasVideoBadge } from '../has_video_badge';
import { OfferLabels } from '../offer_labels';
import { Photo } from '../photo';
import { PromoInfo } from '../promo_info';

import { IPhotosTracking } from './tracking';

import * as styles from './index.css';

export interface IPhotosProps extends Partial<IPhotosTracking> {
  offer: IOffer;
  galleryControlsHidden: boolean;
  photoContainerStyle?: string;
  layoutPhotoStyle?: string;
  /** Использовать ли в галерее фото более высокого качества */
  useThumbnail2Url?: boolean;
  offerCardApi?: CommercialCardAPI;
  isOfferInSavedSearch?: boolean;
  counter?: IGalleryProps['counter'];
  counterHidden?: IGalleryProps['counterHidden'];
}

export interface IPhotosState {
  isPhotoSlideChanged: boolean;
}

export class Photos extends Component<IPhotosProps, IPhotosState> {
  public state = {
    isPhotoSlideChanged: false,
  };

  public render() {
    const {
      offer,
      galleryControlsHidden,
      photoContainerStyle,
      layoutPhotoStyle,
      useThumbnail2Url,
      isOfferInSavedSearch,
      counter,
      counterHidden,
    } = this.props;

    const hasVideo = isArrayWithItems(offer.videos);

    /**
     * Показываем лейблы только в случае если:
     *  - находимся на обычной выдаче (не сохраненный поиск)
     *  - отсутствует промо лейбл
     */
    const isShowPhotoLabels =
      offer.photoLabels && offer.photoLabels.length > 0 && !isOfferInSavedSearch && !offer.promoInfo;

    return (
      <div {...mergeStyles(styles['container'], photoContainerStyle)}>
        {offer.savedSearchLabels && offer.savedSearchLabels.length > 0 && (
          <OfferLabels offersList={offer.savedSearchLabels} />
        )}
        {offer.promoInfo && !isOfferInSavedSearch && (
          <PromoInfo
            containerStyle={mergeStyles(styles['promo-container'], offer.isNew && styles['promo-container-isnew'])}
            href={offer.fullUrl}
            isSpecProject={offer.promoInfo.specProject}
            name={offer.promoInfo.name}
          />
        )}
        <div className={styles['badges-container']}>{hasVideo && <HasVideoBadge />}</div>
        {isShowPhotoLabels && <PhotoLabels hasVideo={hasVideo} labels={offer.photoLabels as IPhotoLabel[]} />}
        <Photo
          containerStyle={styles['photos']}
          controlButtonsFullHeight={true}
          counter={counter}
          counterHidden={counterHidden}
          galleryControlsHidden={galleryControlsHidden}
          hasLayoutPhoto={!!offer.fromDeveloper}
          layoutPhotoStyle={layoutPhotoStyle}
          photos={offer.photos}
          useThumbnail2Url={useThumbnail2Url}
          onSlideChanged={this.handlePhotoSlideChangedOnce}
        />
      </div>
    );
  }

  private handlePhotoSlideChangedOnce = () => {
    const { trackPhotoSlideChanged } = this.props;
    const { isPhotoSlideChanged } = this.state;

    if (!isPhotoSlideChanged) {
      if (trackPhotoSlideChanged) {
        trackPhotoSlideChanged();
      }
      this.setState({
        isPhotoSlideChanged: true,
      });
    }
  };
}
