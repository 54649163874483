import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import { IPhotoLabel } from 'shared/repositories/search-offers.legacy/v2/types/photoLabels';

import * as styles from './PhotoLabels.css';

interface IPhotoLabelsProps {
  labels: IPhotoLabel[];
  hasVideo: boolean;
}

export const PhotoLabels: React.FC<IPhotoLabelsProps> = ({ labels, hasVideo }) => {
  return (
    <div {...mergeStyles(styles['container'], hasVideo && styles['has-video'])}>
      {labels.map(({ text, color }) => (
        <div key={text} {...mergeStyles(styles['label'], styles[`${color}-label`])}>
          {text}
        </div>
      ))}
    </div>
  );
};
