import { useSelector } from 'react-redux';

import { SeoCrossLinks } from '../../components/SeoCrossLinks';

import { crossLinksSelector } from './utils/selectors';

export function SeoCrossLinksContainer() {
  const crossLinks = useSelector(crossLinksSelector);

  if (!crossLinks || !crossLinks.length) {
    return null;
  }

  return <SeoCrossLinks seoBlocks={crossLinks} />;
}
