export type TDrawMode = '0' | '1' | '2' | '3';
export const DrawMode: { [key: string]: TDrawMode } = {
  None: '0',
  Polygon: '1',
  Circle: '2',
  Shape: '3',
};

const PolygonSVG = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg height="100%" viewBox="0 0 25 25" width="100%" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor">
      <path d="M17 2l5 11-9 9-11-6L4 4z" strokeWidth="2" />
      <path
        d="M3.3 5.348c.716.414 1.634.168 2.048-.55.414-.716.168-1.634-.55-2.048-.716-.414-1.634-.168-2.048.55-.414.716-.168 1.634.55 2.048zm13-2c.716.414 1.634.168 2.048-.55.414-.716.168-1.634-.55-2.048-.716-.414-1.634-.168-2.048.55-.414.716-.168 1.634.55 2.048zm5 11c.716.414 1.634.168 2.048-.55.414-.716.168-1.634-.55-2.048-.716-.414-1.634-.168-2.048.55-.414.716-.168 1.634.55 2.048zm-9 9c.716.414 1.634.168 2.048-.55.414-.716.168-1.634-.55-2.048-.716-.414-1.634-.168-2.048.55-.414.716-.168 1.634.55 2.048zm-11-6c.716.414 1.634.168 2.048-.55.414-.716.168-1.634-.55-2.048-.716-.414-1.634-.168-2.048.55-.414.716-.168 1.634.55 2.048z"
        fill="#FFF"
      />
    </g>
  </svg>
);

const CircleSVG = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" transform="translate(1 1)">
      <path
        d="M11 22c6.075 0 11-4.925 11-11S17.075 0 11 0C9.179 0 7.46.443 5.948 1.226A11 11 0 0 0 0 11c0 6.075 4.925 11 11 11z"
        strokeWidth="2"
      />
      <circle cx="18.5" cy="18.5" fill="#FFF" r="1.5" />
    </g>
  </svg>
);

const ShapeSVG = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg height="25" viewBox="0 0 28 25" width="28" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" stroke="currentColor" transform="rotate(90 12.5 14)">
      <path
        d="M5.9 3.273c2.466-2.195 13.518-5.957 14.01-.302.156 1.79-1.874 3.436-1.475 5.187.393 1.723 3.61 2.214 3.565 3.98-.042 1.61-3.015 1.694-3.75 3.135-1.863 3.658 4.84 5.727 2.15 8.984-3.06 3.714-20.53-5.01-20.398-7.9.164-3.59 4.257-1.693 6.206-3.797 2.53-2.73-8.662-6.157-2.704-8.322L5.9 3.273z"
        strokeWidth="2"
      />
      <circle cx="8.5" cy="22.5" fill="#FFF" r="1.5" />
    </g>
  </svg>
);

export const BUTTON_SVGS = {
  [DrawMode.Polygon]: PolygonSVG,
  [DrawMode.Circle]: CircleSVG,
  [DrawMode.Shape]: ShapeSVG,
};
