import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { TCommercialClass } from '../types/commercial';
import { IMakeRequest } from '../utils/request';

export interface IBSCenterInfo {
  buildYear?: string;
  buildingClassType: TCommercialClass;
  buildingType: string;
  fullAddress: string;
  fullUrlDefaultFoto: string;
  id: number;
  name: string;
  offersRentCount: number;
  offersRentLink: string;
  offersSaleCount: number;
  offersSaleLink: string;
  primaryOfficialRepresentativeAgent?: IOfficialRepresentativeAgent;
  seoCityName: string;
  totalArea: string;
  underground: string;
  url: string;
}

export interface IOfficialRepresentativeAgent {
  id: number;
  avatarUrl?: string;
  name: string;
  phones?: IPrimaryOfficialRepresentativeAgentPhone[];
  userId?: number;
  visiblePhones?: IPrimaryOfficialRepresentativeAgentPhone[];
}

export interface IPrimaryOfficialRepresentativeAgentPhone {
  code: string;
  confirmed: boolean;
  countryCode: string;
  id: number;
  number: string;
  visible: boolean;
}

export function getBSCenterInfo(makeRequest: IMakeRequest, id: number) {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-bs-center-info/',
    parameters: {
      bsCenterId: id,
    },
    method: 'GET',
  })
    .then((response: { data: { bsCenter: IBSCenterInfo } }) => response.data.bsCenter)
    .catch(ex => {
      if (ex instanceof HttpBadStatusCodeError) {
        if (ex.statusCode === 404) {
          return null;
        }
      }

      throw ex;
    });
}
