import { VFC, useState } from 'react';

import { getIsNodeBuild } from 'shared/utils/isBuildType';

import { CoworkingProfitLabelControlled, CoworkingProfitLabelView } from './internal/components';
import { CoworkingProfitLabelService } from './internal/services';

export const CoworkingProfitLabel: VFC = () => {
  const [isHoverBehavior, setIsHoverBehavior] = useState<boolean>(
    () => getIsNodeBuild() || CoworkingProfitLabelService.isCoworkingProfitWasShown,
  );

  if (isHoverBehavior) {
    return <CoworkingProfitLabelView />;
  }

  return <CoworkingProfitLabelControlled setIsHoverBehavior={setIsHoverBehavior} />;
};

CoworkingProfitLabel.displayName = 'CoworkingProfitLabel';
