import { IOffer } from 'shared/repositories/search-offers.legacy/v2/types';

export const getPosition = (offerId: number, offers: IOffer[]): number => {
  for (let i = 0; i < offers.length; i++) {
    const offer = offers[i];
    if (offer.id === offerId) {
      return i + 1;
    }
  }

  return 1;
};
