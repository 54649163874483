import { IAppState, TActions } from '../../../common/state';
import { EBuildingHeatingType, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TBuildingHeatingTypeChangedActionType = 'filters/building_heating/BUILDING_HEATING_TYPE_CHANGED';
export const IBuildingHeatingTypeChangedActionType =
  'filters/building_heating/BUILDING_HEATING_TYPE_CHANGED' as TBuildingHeatingTypeChangedActionType;

export interface IBuildingHeatingTypeChangedAction {
  type: 'filters/building_heating/BUILDING_HEATING_TYPE_CHANGED';
  paramType: EBuildingHeatingType;
  value: boolean;
}

export const changeBuildingHeating = getParamChangedAction<EBuildingHeatingType, TBuildingHeatingTypeChangedActionType>(
  IBuildingHeatingTypeChangedActionType,
);

export function buildingHeatingTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IBuildingHeatingTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setBuildingHeatingType(action.paramType)
            : jq(state.filters.jsonQuery).unsetBuildingHeatingType(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-buildingHeating') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetBuildingHeatingType(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetBuildingHeatingTypes(),
        },
      };

    default:
      return state;
  }
}
