import { IAppState, TActions } from '../../../common/state';

export interface IIsFitTagsInOneLineChanged {
  type: 'filters/tags/IS_FIT_TAGS_IN_ONE_LINE_CHANGED';
  value: boolean;
}

export function fitInOneLine(value: boolean): IIsFitTagsInOneLineChanged {
  return {
    type: 'filters/tags/IS_FIT_TAGS_IN_ONE_LINE_CHANGED',
    value,
  };
}

export function fitTagsInOneLineReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/tags/IS_FIT_TAGS_IN_ONE_LINE_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          tagsOptions: {
            ...state.filters.tagsOptions,
            isFitInOneLineEnabled: action.value,
          },
        },
      };
    default:
      return state;
  }
}
