/* eslint-disable max-lines */
import {
  Button,
  IControlOption,
  INumberInputProps,
  ModalWindow,
  NumberInput,
  RadioButtonGroup,
  TooltipDesktop,
} from '@cian/ui-kit';
import { mergeStyles } from '@cian/utils';

import classNames from 'classnames';
import * as React from 'react';

import * as track from './tracking';

import * as styles from './index.css';

export interface IRow {
  title: React.ReactNode;
  headStyles?: string;
}

export interface IInlineElement {
  children?: React.ReactNode;
  customStyles?: string;
}

const Row: React.FC<React.PropsWithChildren<IRow>> = ({ title, children, headStyles }) => (
  <div className={styles['row']}>
    <div {...mergeStyles(styles['cellHead'], headStyles)}>{title}</div>
    <div className={styles['cellContent']}>{children}</div>
  </div>
);

export interface IOfficeCalculatorProps {
  isOfficeCalculatorVisible: boolean;
  onClose(): void;
}

type TCoefficientFields =
  | 'room5man'
  | 'room10man'
  | 'room25man'
  | 'kitchen10man'
  | 'kitchen15man'
  | 'receptionSmall'
  | 'receptionBig';

type TNumberFields =
  | 'humansCount'
  | 'directorCabinetCount'
  | 'directorCabinetSquare'
  | 'managerCabinetCount'
  | 'managerCabinetSquare'
  | TCoefficientFields;

type TRadioFields = 'buildingClass';

type TGroup = 'common' | 'room' | 'kitchen' | 'reception';

type TNumberField = {
  fieldType: 'number';
  title: string;
  type: TNumberFields;
  required?: boolean;
  error?: string;
};

type TRadioFieldOption = IControlOption<TBuildingClass>;

type TRadioField = {
  fieldType: 'radio';
  title: string;
  type: TRadioFields;
  fields: TRadioFieldOption[];
};

type TNumberGroup = {
  fieldType: 'numbergroup';
  fields: [TNumberField, TNumberField];
  description: React.ReactNode;
};

type TGroupField = {
  type: TGroup;
  fields: Array<TNumberField | TRadioField | TNumberGroup>;
  header?: string;
};

type TBuildingClass = '0' | '1' | '2' | '3' | '4';
const BuildingClass: TRadioFieldOption[] = [
  { label: 'A', value: '1' },
  { label: 'B', value: '2' },
  { label: 'C', value: '3' },
  { label: 'Без класса', value: '4' },
];

const CalculatorFields: TGroupField[] = [
  {
    type: 'common',
    fields: [
      {
        title: 'Сотрудников в компании',
        type: 'humansCount',
        fieldType: 'number',
        required: true,
        error: 'Укажите количество сотрудников',
      },
      { title: 'Класс', type: 'buildingClass', fields: BuildingClass, fieldType: 'radio' },
      {
        fieldType: 'numbergroup',
        fields: [
          { title: 'Кабинеты «директора»', type: 'directorCabinetCount', fieldType: 'number' },
          { title: 'площадью', type: 'directorCabinetSquare', fieldType: 'number' },
        ],
        description: (
          <span className={styles['recomendation']}>
            Рекомендуемая <br />
            площадь 30−40 м²
          </span>
        ),
      },
      {
        fieldType: 'numbergroup',
        fields: [
          { title: 'Кабинеты «менеджера»', type: 'managerCabinetCount', fieldType: 'number' },
          { title: 'площадью', type: 'managerCabinetSquare', fieldType: 'number' },
        ],
        description: (
          <span className={styles['recomendation']}>
            Рекомендуемая <br />
            площадь 12−20 м²
          </span>
        ),
      },
    ],
  },
  {
    header: 'Переговорные',
    type: 'room',
    fields: [
      { title: 'Маленькие, на 5 чел.', type: 'room5man', fieldType: 'number' },
      { title: 'Средние, на 10 чел.', type: 'room10man', fieldType: 'number' },
      { title: 'Большие, на 25 чел.', type: 'room25man', fieldType: 'number' },
    ],
  },
  {
    header: 'Кухня',
    type: 'kitchen',
    fields: [
      { title: 'На 10–12 человек', type: 'kitchen10man', fieldType: 'number' },
      { title: 'На 15–18 человек', type: 'kitchen15man', fieldType: 'number' },
    ],
  },
  {
    header: 'Ресепшн',
    type: 'reception',
    fields: [
      { title: 'Стандартный', type: 'receptionSmall', fieldType: 'number' },
      { title: 'Большой', type: 'receptionBig', fieldType: 'number' },
    ],
  },
];

type TNumberCalculatorParams = { [T in TNumberFields]: number | void };
type TRadioCalculatorParams<K> = { [T in TRadioFields]: K };

export type TCalculatorParams = TNumberCalculatorParams & TRadioCalculatorParams<TBuildingClass>;

export interface IOfficeCalculatorState extends TCalculatorParams {
  showErrors: boolean;
}

const Coefficients = {
  buildingClass: (val: TBuildingClass) => (val === BuildingClass[0].value ? 9.56 : 7.17),
  room5man: 21.6,
  room10man: 36,
  room25man: 64.8,
  kitchen10man: 43.2,
  kitchen15man: 64.8,
  receptionSmall: 28.8,
  receptionBig: 43.2,
};

const calculate = (params: [number | void, number | void][]) =>
  params.reduce((result, param) => result + (param[0] || 0) * (param[1] || 0), 0);

export class OfficeCalculator extends React.Component<IOfficeCalculatorProps, IOfficeCalculatorState> {
  public state = {
    humansCount: undefined,
    directorCabinetCount: undefined,
    directorCabinetSquare: 30,
    managerCabinetCount: undefined,
    managerCabinetSquare: 12,
    room5man: undefined,
    room10man: undefined,
    room25man: undefined,
    kitchen10man: undefined,
    kitchen15man: undefined,
    receptionSmall: undefined,
    receptionBig: undefined,
    buildingClass: '0' as const,
    showErrors: false,
  };

  private resultElement: HTMLDivElement | null = null;
  private isFirstCalculate = true;

  public componentDidMount() {
    track.trackOpenOfficeCalculator();
  }

  public render() {
    const result = this.calculate();

    return (
      <ModalWindow maxHeight={975} open={this.props.isOfficeCalculatorVisible} onClose={this.onClose}>
        <div className={styles['content-wrapper']}>
          <div className={styles['title']}>
            Укажите параметры помещения и мы рассчитаем <br />
            его оптимальную площадь
          </div>
          <div className={styles['calculator']}>
            {CalculatorFields.map(({ type, header, fields }) => (
              <div key={`calculator-group-${type}`}>
                {header && (
                  <div className={styles['subtitle']} key={`calculator-subtitle-${type}`}>
                    {header}
                  </div>
                )}
                {fields.map(field => {
                  switch (field.fieldType) {
                    case 'numbergroup':
                      return this.renderNumberGroupRow(field);
                    case 'radio':
                      return this.renderRadioRow(field);
                    default:
                      return this.renderNumberRow(field);
                  }
                })}
              </div>
            ))}
          </div>
          <div
            ref={r => (this.resultElement = r)}
            {...mergeStyles(styles['result'], !result && styles['result--noResult'])}
          >
            {result && (
              <div className={styles['resultContainer']}>
                <div className={styles['resultSquare']}>
                  {`${typeof result === 'number' ? result : `${result[0]}-${result[1]}`}`} м&sup2;
                </div>
                <div className={styles['resultText']}>Рекомендуемая площадь офиса</div>
              </div>
            )}
          </div>
        </div>
        <div className={styles['footer']}>
          <Button size="XS" theme="fill_primary" onClick={this.onButtonClick}>
            Рассчитать
          </Button>
        </div>
      </ModalWindow>
    );
  }

  private renderRadioRow({ type, fields }: TRadioField) {
    return (
      <Row headStyles={styles['row']} key={`calculator-row-${type}`} title="Класс">
        <RadioButtonGroup
          options={fields}
          value={this.state[type]}
          onChange={(_, val: TBuildingClass) => this.onBuildingClassChange(val)}
        />
      </Row>
    );
  }

  private renderNumberGroupRow({ fields, description }: TNumberGroup) {
    const commonProps: INumberInputProps = {
      inline: true,
      maxWidth: 62,
      min: 0,
      placeholder: 'Кол-во',
    };

    return (
      <Row headStyles={styles['row']} key={`calculator-row-${fields[0].type}`} title={fields[0].title}>
        <NumberInput
          {...commonProps}
          value={this.state[fields[0].type]}
          onChange={val => this.onNumberFieldChange(fields[0].type, val)}
        />
        <span className={styles['sublabel']}>{fields[1].title}</span>
        <NumberInput
          {...commonProps}
          value={this.state[fields[1].type]}
          onChange={val => this.onNumberFieldChange(fields[1].type, val)}
        />
        <span className={styles['m2']}>м&sup2;</span>
        {description}
      </Row>
    );
  }

  private renderNumberRow({ title, type, required, error }: TNumberField) {
    const { showErrors } = this.state;
    const tooltipContent = showErrors && !this.state[type] ? error || '' : '';
    const isInvalid = Boolean(tooltipContent);
    const commonProps: INumberInputProps = {
      inline: true,
      maxWidth: 70,
      min: 0,
      placeholder: 'Кол-во',
      value: this.state[type],
      onChange: val => this.onNumberFieldChange(type, val),
    };

    return (
      <Row headStyles={styles['row']} key={`calculator-row-${type}`} title={title}>
        {required ? (
          <TooltipDesktop
            arrow={true}
            open={isInvalid}
            placement="bottom-start"
            theme="black"
            title={<span className={styles['tooltip-content']}>{tooltipContent}</span>}
          >
            <span className={classNames(required && styles['required-sign'])}>
              <NumberInput {...commonProps} invalid={isInvalid} required={required} />
            </span>
          </TooltipDesktop>
        ) : (
          <NumberInput {...commonProps} invalid={isInvalid} required={required} />
        )}
      </Row>
    );
  }

  private onClose = () => {
    track.trackCloseOfficeCalculator();
    this.props.onClose();
  };

  private onNumberFieldChange = (type: TNumberFields, val: number | void) => {
    const nextState = Object.assign({}, this.state, {
      [type]: val,
      showErrors: false,
    });
    this.setState(nextState);
  };

  private onBuildingClassChange = (buildingClass: TBuildingClass) => {
    this.setState({
      buildingClass,
    });
  };

  private onButtonClick = () => {
    if (!this.calculate()) {
      this.setState({ showErrors: true });

      return;
    }
    if (this.resultElement) {
      this.resultElement.scrollIntoView();
    }
  };

  private calculate() {
    const {
      buildingClass,
      directorCabinetCount,
      directorCabinetSquare,
      managerCabinetCount,
      managerCabinetSquare,
      humansCount,
      kitchen10man,
      kitchen15man,
      receptionSmall,
      receptionBig,
      room5man,
      room10man,
      room25man,
    } = this.state;

    if (!humansCount) {
      return null;
    }

    const result = calculate([
      [humansCount, Coefficients.buildingClass(buildingClass)],
      [directorCabinetCount, directorCabinetSquare],
      [managerCabinetCount, managerCabinetSquare],
      [room5man, Coefficients.room5man],
      [room10man, Coefficients.room10man],
      [room25man, Coefficients.room25man],
      [kitchen10man, Coefficients.kitchen10man],
      [kitchen15man, Coefficients.kitchen15man],
      [receptionSmall, Coefficients.receptionSmall],
      [receptionBig, Coefficients.receptionBig],
    ]);

    if (result === 0) {
      return null;
    }

    const percentage = result * 0.15;

    const range = [Math.round(result - percentage), Math.round(result + percentage)];

    if (this.isFirstCalculate) {
      track.trackCalculateOfficeCalculator();
      this.isFirstCalculate = false;
    }

    return range[0] < 1 || range[0] === range[1] ? range[1] : range;
  }
}
