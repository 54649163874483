import { QuestionLayout as BaseQuestionLayout, IQuestionLayoutProps } from '@cian/frontend-request-form-widget';

import * as React from 'react';

import { OBJECT_TYPE_STEP } from '../../constants/stepConfig';
import { ObjectTypeLayout } from '../../layouts/ObjectTypeLayout';

export const QuestionLayout: React.FC<IQuestionLayoutProps> = props => {
  if (props.question.code === OBJECT_TYPE_STEP.question.code) {
    return <ObjectTypeLayout {...props} />;
  }

  //пока удалил только AuthWidget чтобы отказаться от api-models
  // под полное удаление есть отдельная задача https://jira.cian.tech/browse/CD-117944
  // if (props.question.code === REGISTRATION_STEPS.question.code) {
  //   return (
  //     <AuthWidgetContainer
  //       onAuthenticationSuccess={trackAuthenticationSuccess}
  //       onEmailChecked={trackEmailChecked}
  //       onPhoneChecked={trackPhoneChecked}
  //       onRegisterSuccess={trackRegistratonSuccess}
  //     />
  //   );
  // }

  return <BaseQuestionLayout {...props} />;
};

QuestionLayout.displayName = 'QuestionLayout';
