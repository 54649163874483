import * as React from 'react';

export const QuestionMark: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg height="16" viewBox="0 0 16 16" width="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <circle cx="8" cy="8" fill="#2B87DB" opacity=".5" r="8" />
      <path
        d={
          'M7 11h2v2H7v-2zm.134-1.292l-.006-.432c0-.532.088-.97.264-1.314.176-.344.529-.73 1.057-1.16.529-.43.844-' +
          '.711.947-.844.16-.211.239-.443.239-.696 0-.353-.14-.655-.422-.906s-.66-.377-1.138-.377c-.46 0-.844.131-' +
          '1.153.393-.31.262-.522.662-.638 1.199L4.62 5.365c.047-.77.375-1.422.983-1.96C6.212 2.87 7.01 2.6 7.998 ' +
          '2.6c1.04 0 1.867.272 2.481.815.614.544.922 1.176.922 1.898 0 .4-.113.778-.339 1.134-.225.357-.707.842-1' +
          '.446 1.457-.383.318-.62.573-.713.767-.092.193-.134.539-.125 1.037H7.134z'
        }
        fill="#FFF"
      />
    </g>
  </svg>
);
