import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IComissionTypeTagType = 'advanced-comission-comissionType';

export interface IComissionTypeTag {
  type: 'advanced-comission-comissionType';
  value: number;
}

function isComissionTypeTag(tag: TTag): tag is IComissionTypeTag {
  return tag.type === IComissionTypeTagType;
}

export const comissionTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.commission_type && jsonQuery.commission_type.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isComissionTypeTag, () => ({ type: IComissionTypeTagType, value }));
    } else {
      return ensureTagNotExists(tags, isComissionTypeTag);
    }
  },
};
