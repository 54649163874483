export function normalizeUndefined<T>(x: T | void): T | undefined {
  if (x == null) {
    return undefined;
  }

  return x;
}

export function unique<T>(arr: T[]): T[] {
  return [...new Set(arr)];
}
