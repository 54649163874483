import { connect } from 'react-redux';

import { Dispatch, IAppState } from '../../../common/state';
import { closeModal, removeDistrict, selectDistrict, switchNewMoscowDistrict } from '../../state/districts/popup';
import { saveDistrictsModal } from '../../state/districts/popup_save';

import { DistrictsModal, IDispatchProps, IStateProps } from './';

export const DistrictsContainer = connect<IStateProps, IDispatchProps>(
  (state: IAppState) => {
    return {
      isDataLoading: state.filters.districtsSwitcher.isDataLoading,
      districts: state.filters.districtsSwitcher.districts,
      selectedLocation: state.filters.currentLocation,
      selectedDistricts: state.filters.districtsSwitcher.selectedDistricts,
      includeNewMoscow: state.filters.jsonQuery.include_new_moscow && state.filters.jsonQuery.include_new_moscow.value,
      isDistrictModalVisible: state.isDistrictModalVisible,
    };
  },
  (dispatch: Dispatch) => ({
    onClose: () => {
      dispatch(closeModal());
    },
    onSave: () => {
      dispatch(saveDistrictsModal());
    },
    onSelected: (id: number, name: string) => {
      dispatch(selectDistrict(id, name));
    },
    onRemoved: (id: number) => {
      dispatch(removeDistrict(id));
    },
    onNewMoscowSwitched: (includeNewMoscow: boolean | undefined) => {
      dispatch(switchNewMoscowDistrict(includeNewMoscow));
    },
    onSearchButtonClick: () => {
      dispatch(saveDistrictsModal());
    },
  }),
)(DistrictsModal);
