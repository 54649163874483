import { connect } from 'react-redux';

import { IAppState } from '../../../common/state';

import { Header, IHeaderProps } from './index';

export const HeaderContainer = connect<IHeaderProps>((state: IAppState) => ({
  villageInfo: state.results.villageInfo,
  jsonQuery: state.results.jsonQuery,
  newbuildingInfo: state.newbuildingInformation,
  bsCenterInfo: state.results.bsCenterInfo,
  developerInfo: state.results.developerInfo,
}))(Header);
