import { EFieldSubType, IStep } from '@cian/frontend-request-form-widget';

import { ExtendedFieldType } from '../extendedFieldType';

import { PERSON_TYPE_STEP } from './personType';

export const DEAL_DATE_STEP: IStep = {
  code: 'dealDate',
  question: {
    code: 'dealDateQuestion',
    title: 'Когда планируете совершить сделку?',
    fields: [
      {
        name: 'dealDate',
        type: ExtendedFieldType.OneChoice,
        subType: EFieldSubType.Icon,
        answers: [
          {
            label: 'В течение 3 месяцев',
            value: '3m',
          },
          {
            label: 'В течение 6 месяцев',
            value: '6m',
          },
          {
            label: 'В течение года или позже',
            value: 'year',
          },
        ],
      },
    ],
  },
  nextStep: [
    {
      conditions: [],
      nextStepCode: PERSON_TYPE_STEP.code,
    },
  ],
};
