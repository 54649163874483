import { ca } from '@cian/analytics';

export function trackMetroModalSaveEvent(metroCount: number) {
  ca(
    'event',
    Object.assign({
      action: 'popup_metro_save',
      category: 'Listing',
      label: metroCount,
      name: 'oldevent',
    }),
  );
}
