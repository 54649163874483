/* eslint-disable react/jsx-no-target-blank */
import { mergeStyles } from '@cian/utils';

import { equals } from 'ramda';
import * as React from 'react';

import * as styles from './Collection.css';

export interface ICollectionProps {
  href: string;
  title: string;
  label: string;
  imageKey: string;
  className?: string;
  cdnBaseUrl: string;
  color: string | null;
  backgroundColor: string | null;
  onClick(): void;
}

const CollectionComponent: React.FC<ICollectionProps> = props => {
  const { href, title, cdnBaseUrl, imageKey, onClick, className, backgroundColor, color, label } = props;

  return (
    <div className={className} onClick={onClick}>
      <a href={href} target="_blank" {...mergeStyles(styles['link'], { backgroundColor })}>
        <span {...mergeStyles(styles['title'], { color })}>{title}</span>
        <img
          className={styles['image']}
          src={`${cdnBaseUrl}${imageKey}.png`}
          srcSet={`${cdnBaseUrl}${imageKey}@2x.png 2x, ${cdnBaseUrl}${imageKey}@3x.png 3x`}
        />
        {label && <span className={styles['label']}>{label}</span>}
      </a>
    </div>
  );
};

export const Collection = React.memo(CollectionComponent, arePropsEqual);

export function arePropsEqual(prevProps: ICollectionProps, nextProps: ICollectionProps) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClick: _, ...prevPropsWithoutOnClick } = prevProps;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { onClick: __, ...nextPropsWithoutOnClick } = nextProps;

  return equals(prevPropsWithoutOnClick, nextPropsWithoutOnClick);
}
