import * as React from 'react';
import { useSelector } from 'react-redux';

import { IAppState } from 'shared/common/state';

import { Form as FormComponent, TForm } from './Form';
import {
  ABOUT_COMPANY_STEP,
  BUSINESS_PAGE,
  COMMON_OBJECT_STEP,
  COWORKING_PAGE,
  DEAL_DATE_STEP,
  FREE_APPOINTMENT_OBJECT_PAGE,
  NEWS_SUBSCRIPTION_STEP,
  OBJECT_TYPE_STEP,
  PERSON_TYPE_STEP,
  WARWHOUSE_PAGE,
} from './internal/constants/stepConfig';
import { getFormInitialValue } from './internal/utils/getFormInitialValue';
import { getFormStepsSchema } from './internal/utils/getFormStepsSchema';
import { getNextStepByOfficeType } from './internal/utils/getNextStepByOfficeType';
import { trackDealDateClick } from './tracks/trackDealDateClick';
import { trackNewsSubscriptionClick } from './tracks/trackNewsSubscriptionClick';
import { trackOnNextButtonClick } from './tracks/trackOnNextButtonClick';
import { trackPersonTypeClick } from './tracks/trackPersonTypeClick';
import { trackShowPopup } from './tracks/trackShowPopup';
import { trackOnPrevStepButtonClick } from './tracks/trackonPrevStepButtonClick';

export const Form: React.FC = () => {
  const state = useSelector<IAppState, IAppState>(state => state);

  const mapState = {
    firstStepCode: OBJECT_TYPE_STEP.code,
    initialStepCode: getNextStepByOfficeType(state.filters.jsonQuery),
    initialFormValue: getFormInitialValue(state.filters),
    skipSteps: [],
    stepsSchema: getFormStepsSchema({ specialties: state.specialties }),
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;

  React.useEffect(() => {
    trackShowPopup();
  }, []);

  const handleFormStep = React.useCallback<NonNullable<TForm['onNextStep']>>(state => {
    switch (state.fromStep.code) {
      case OBJECT_TYPE_STEP.code:
        trackOnNextButtonClick();
        break;

      case COMMON_OBJECT_STEP.code:
      case BUSINESS_PAGE.code:
      case COWORKING_PAGE.code:
      case FREE_APPOINTMENT_OBJECT_PAGE.code:
      case WARWHOUSE_PAGE.code:
        trackOnNextButtonClick(2);
        break;

      case DEAL_DATE_STEP.code:
        trackDealDateClick(state.formValue.dealDate);
        break;

      case PERSON_TYPE_STEP.code:
        trackPersonTypeClick(state.formValue.personType);
        break;

      case ABOUT_COMPANY_STEP.code:
        trackOnNextButtonClick(5);
        break;

      case NEWS_SUBSCRIPTION_STEP.code:
        trackNewsSubscriptionClick(state.formValue.needNewsSubscription);
        break;

      default:
        return;
    }
  }, []);

  const handlePrevStep = React.useCallback<NonNullable<TForm['onPrevStep']>>(() => {
    trackOnPrevStepButtonClick();
  }, []);

  return <FormComponent {...mapState} onNextStep={handleFormStep} onPrevStep={handlePrevStep} onSubmit={() => {}} />;
};

Form.displayName = 'Form';
