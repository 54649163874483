import { IAdFoxBannerContext } from '@cian/adfox/build/react';

import { Component } from 'react';

const style = require('./index.css');

export interface IHTML5BannerProps {
  context: IAdFoxBannerContext;
}

export class HTML5Banner extends Component<IHTML5BannerProps> {
  public render() {
    return (
      <div className={style['bannerWrapper']}>
        <iframe
          frameBorder="0"
          marginHeight={0}
          marginWidth={0}
          scrolling="no"
          srcDoc={this.props.context.html}
          width="100%"
        />
      </div>
    );
  }
}
