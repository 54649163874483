import { ca } from '@cian/analytics';

export function trackOnNextButtonClick(stepNumber: number = 1) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Profile_user_comm_popup',
    action: 'Click',
    label: `Step_${stepNumber}`,
  });
}
