export type AdFoxBannerType =
  | 'tgb_in_offers_list'
  | 'tgb_in_offers_list_new'
  | 'mortgage_in_price'
  | 'tgb_in_seo'
  | 'tgb_in_seo_new'
  | 'video'
  | 'parallax'
  | 'more_parameters_pik'
  | 'mortgage_banner'
  | 'mortgage_banner_new'
  | 'sale_between_top3_premium'
  | 'picture_banner'
  | 'mortgage_in_listing'
  | 'mortgage_in_listing_new';

export enum EAdFoxBannerTypes {
  seo = 'tgb_in_seo',
  seoNew = 'tgb_in_seo_new',
}

export interface IBanners {
  [key: string]: {
    [key: string]: string;
  };
}

export type BANNER_TEMPLATE_TYPE =
  | 'google_default'
  | 'commercial_mortgage'
  | 'more_parameters'
  | 'mortgage'
  | 'nativeTGB'
  | 'parallax'
  | 'seo'
  | 'stretch'
  | 'mortgage_banner'
  | 'mortgage_banner_new'
  | 'video'
  | 'picture_banner'
  | 'mortgage_in_listing';

const banners: IBanners = {
  tgb_in_offers_list: {
    p1: 'bvkkk',
    p2: 'fkhj',
    pct: 'b',
    pfb: 'a',
    pfc: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
    pt: 'b',
    random: '1453650711',
  },
  tgb_in_offers_list_new: {
    p1: 'crulw',
    p2: 'fkhj',
    pct: 'b',
    pfb: 'a',
    pfc: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
    pt: 'b',
    random: '1453650711',
  },
  mortgage_banner: {
    p1: 'cbtyi',
    p2: 'gbvb',
    random: '1768213213139593',
  },
  mortgage_banner_new: {
    p1: 'ctbng',
    p2: 'gbvb',
    random: '1768213213139593',
  },
  mortgage_in_price: {
    p1: 'bqajz',
    p2: 'ezvd',
    pct: 'a',
    pfb: 'a',
    pfc: 'a',
    plp: 'a',
    pli: 'a',
    pop: 'a',
    pt: 'b',
    random: '4294967295',
  },
  tgb_in_seo: {
    p1: 'bvkkh',
    p2: 'fkhh',
    pct: 'b',
    pfb: 'a',
    pfc: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
    random: '626294336',
  },
  tgb_in_seo_new: {
    p1: 'cqvyb',
    p2: 'fkhh',
    pct: 'b',
    pfb: 'a',
    pfc: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
  },
  video: {
    p1: 'bvkoa',
    p2: 'y',
    pfb: 'a',
    pfc: 'a',
    pli: 'a',
    plp: 'a',
    pop: 'a',
    pt: 'b',
    random: '1302602909',
  },
  parallax: {
    p1: 'bvkoa',
    p2: 'y',
  },
  picture_banner: {
    p1: 'crzye',
    p2: 'fmsa',
  },
  more_parameters_pik: {
    p1: 'bwgxy',
    p2: 'fmsa',
    random: '1471524203',
  },
  sale_between_top3_premium: {
    p1: 'bxqaw',
    p2: 'fqls',
    random: '149849617934298926',
  },
  mortgage_in_listing: {
    p1: 'bvkkk',
    p2: 'fkhj',
    random: '1234235432',
  },
  mortgage_in_listing_new: {
    p1: 'crulw',
    p2: 'fkhj',
    random: '1234235432',
  },
};

export function getBannerByType(type: AdFoxBannerType) {
  return banners[type];
}
