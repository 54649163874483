import {
  ABOUT_COMPANY_STEP,
  BUSINESS_PAGE,
  COMMON_OBJECT_STEP,
  COWORKING_PAGE,
  DEAL_DATE_STEP,
  FREE_APPOINTMENT_OBJECT_PAGE,
  NEWS_SUBSCRIPTION_STEP,
  OBJECT_TYPE_STEP,
  PERSON_TYPE_STEP,
  REGISTRATION_STEPS,
  WARWHOUSE_PAGE,
} from './stepConfig';

export const USER_PROFILING_STEPS_SCHEMA = {
  objectType: OBJECT_TYPE_STEP,
  dealDate: DEAL_DATE_STEP,
  personType: PERSON_TYPE_STEP,
  aboutCompany: ABOUT_COMPANY_STEP,
  newsSubscription: NEWS_SUBSCRIPTION_STEP,
  registration: REGISTRATION_STEPS,
  coworkingPage: COWORKING_PAGE,
  businessPage: BUSINESS_PAGE,
  freeAppointmentObjectPage: FREE_APPOINTMENT_OBJECT_PAGE,
  warehousePage: WARWHOUSE_PAGE,
  commonObjectPage: COMMON_OBJECT_STEP,
};
