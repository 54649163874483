// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const normalizeAbGroup = (value?: any): number => {
  let innerValue = value;

  if (innerValue === null) {
    return 100;
  }

  innerValue = Number(innerValue);

  if (innerValue >= 0 && innerValue < 100) {
    return innerValue;
  }

  return 100;
};
