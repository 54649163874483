import { useBooleanState } from '@cian/react-utils';
import { Button, CustomTooltip, IconSort16, IconSuccess16 } from '@cian/ui-kit';

import * as React from 'react';

import { ESortValue } from 'shared/json_query';

import { SummaryButtonWrapper } from '../SummaryButtonWrapper';

import { IDropdownItem, ISortDropdownProps } from './types';
import { trackChangeSortEvent } from './utils/tracking';

import * as styles from './SummarySortDropdown.css';

export const SummarySortDropdown: React.VFC<ISortDropdownProps> = props => {
  const { state: isDropdownOpen, setFalse: closeDropdown, setTrue: openDropdown } = useBooleanState();
  const buttonRef = React.useRef<HTMLDivElement>(null);

  const { onSortChanged, isRedesignEnabled, sortItems, currentSort } = props;

  const toggle = React.useCallback(() => {
    return isDropdownOpen ? closeDropdown() : openDropdown();
  }, [isDropdownOpen, closeDropdown, openDropdown]);

  const handleClickOnValue = React.useCallback(
    (newActiveItem: IDropdownItem) => () => {
      if (currentSort !== newActiveItem.value) {
        trackChangeSortEvent(newActiveItem.value as string);
        onSortChanged(newActiveItem.value as ESortValue);
      }

      closeDropdown();
    },
    [currentSort, onSortChanged, closeDropdown],
  );

  if (!sortItems.length) {
    return null;
  }

  const activeIndex = sortItems.findIndex(({ value }) => value === currentSort);
  const activeItem = sortItems[activeIndex];

  return (
    <SummaryButtonWrapper>
      <div ref={buttonRef}>
        <Button
          beforeIcon={<IconSort16 color="primary_100" />}
          data-mark="SortDropdownButton"
          size="XS"
          theme={isRedesignEnabled ? 'fill_white_primary' : 'fill_secondary'}
          onClick={toggle}
        >
          {activeItem.label}
        </Button>
        <CustomTooltip
          anchorRef={buttonRef}
          arrow={false}
          content={
            <ul className={styles['dropdown-container']}>
              {sortItems.map((sortItem, ind) => (
                <li className={styles['item']} key={sortItem.value} onClick={handleClickOnValue(sortItem)}>
                  <div className={styles['item-content']}>
                    {sortItem.label}
                    {activeIndex === ind && (
                      <div className={styles['item-selected-icon']}>
                        <IconSuccess16 color="primary_100" />
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          }
          flip={false}
          open={isDropdownOpen}
          outside={true}
          placement="bottom-start"
          theme="white"
          onClose={closeDropdown}
        />
      </div>
    </SummaryButtonWrapper>
  );
};
