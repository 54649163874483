import { IAppState, TActions } from '../../../common/state';

import { IAP_POPUP_REQUEST_CLOSED, IAP_POPUP_REQUEST_OPENED } from './actions';

export const iapRequestPopupReducer = (state: IAppState, action: TActions): IAppState => {
  switch (action.type) {
    case IAP_POPUP_REQUEST_OPENED:
      return {
        ...state,
        iapPopupRequestOpened: true,
      };

    case IAP_POPUP_REQUEST_CLOSED:
      return {
        ...state,
        iapPopupRequestOpened: false,
      };

    default:
      return state;
  }
};
