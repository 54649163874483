import { Button, ModalWindow, UIHeading2, UIText2 } from '@cian/ui-kit';
import { keepValueInRange } from '@cian/utils';

import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { Component, RefObject, createRef } from 'react';

import { CommercialCardContext__DEPRICATED } from '../../CommercialCard';
import { AuctionBetInput } from '../AuctionBetInput';
import { trackAuctionReduceBetPopupShow } from '../AuctionBetManager/tracking';

import * as styles from './AuctionReduceBetModal.css';

export interface IAuctionReduceBetModalProps {
  bet: number;
  stepBet: number;
  validateStep: boolean;
  onCancel(): void;
  onSubmit(b: number): void;
  auctionBetCalculator(value: number | null): number;
  open: boolean;
  setBetModalRefs: (refs: RefObject<HTMLElement>[]) => void;
}

export interface IAuctionReduceBetModalState {
  betValue: number;
  isPending: boolean;
  applyBetOperationId?: number;
  isBetValid: boolean;
}

export class AuctionReduceBetModal extends Component<IAuctionReduceBetModalProps, IAuctionReduceBetModalState> {
  public static contextTypes = {
    offer: PropTypes.object,
    auctionMinBet: PropTypes.number,
    auctionMaxBet: PropTypes.number,
  };

  private contentRef = createRef<HTMLDivElement>();

  public constructor(props: IAuctionReduceBetModalProps) {
    super(props);

    this.state = {
      betValue: props.bet,
      isPending: false,
      isBetValid: true,
    };
  }

  public componentDidMount() {
    trackAuctionReduceBetPopupShow();

    this.props.setBetModalRefs([this.contentRef]);
  }

  public componentWillUnmount() {
    this.props.setBetModalRefs([]);
  }

  public render() {
    const { offer } = this.context as CommercialCardContext__DEPRICATED;
    const { onCancel, stepBet, validateStep, auctionBetCalculator, open } = this.props;
    const { isBetValid, betValue, isPending } = this.state;

    return (
      <ModalWindow maxWidth={470} open={open} width={470} onClose={onCancel}>
        <div className={styles['content']} ref={this.contentRef}>
          <div className={styles['header']}>
            <UIHeading2>Вы понижаете ставку!</UIHeading2>
          </div>
          <div className={styles['input-row']}>
            <div className={styles['input-label']}>
              <UIText2>Действующая ставка</UIText2>
            </div>
            <div className={styles['input-max_bet']}>
              <UIText2 fontWeight="bold">{offer.auction?.currentBet || 0} ₽ в сутки</UIText2>
            </div>
          </div>

          <div className={styles['input-row']}>
            <div className={styles['input-label']}>
              <UIText2>Новая ставка</UIText2>
            </div>
            <AuctionBetInput
              auctionBetCalculator={auctionBetCalculator}
              initialBet={betValue}
              stepBet={stepBet}
              validateStep={validateStep}
              onChange={this.handleBetChange}
            />
          </div>
          <div className={styles['footer']}>
            <div className={classNames(styles['buttons'], styles['buttons-right'])}>
              <Button size="XS" theme="fill_secondary" type="button" onClick={onCancel}>
                Отменить
              </Button>
              <Button
                disabled={!isBetValid || isPending}
                size="XS"
                theme="stroke_primary"
                type="button"
                onClick={this.handlePlaceBetClick}
              >
                Применить
              </Button>
            </div>
          </div>
        </div>
      </ModalWindow>
    );
  }

  private handleBetChange = (bet: number) => {
    const { auctionMinBet = null, auctionMaxBet = null } = this.context as CommercialCardContext__DEPRICATED;
    const { validateStep, stepBet, auctionBetCalculator } = this.props;

    const betValue = keepValueInRange(bet, 0, auctionBetCalculator(auctionMaxBet));

    const isMultiplierValid = validateStep ? betValue % stepBet === 0 : true;
    const isBetValid = (betValue === 0 || (auctionMinBet || 0) <= betValue) && isMultiplierValid;

    this.setState({ betValue, isBetValid });
  };

  private handlePlaceBetClick = () => {
    const { betValue } = this.state;

    this.setState({ isPending: true });

    this.props.onSubmit(betValue);
  };
}
