import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';

import { FC, useMemo } from 'react';

import { useGetIsElectronicTrading } from 'shared/hooks/useGetIsElectronicTrading';

import { CardContextProvider } from '../../../context/CardContext';
import { useApplicationContext } from '../../../serp/containers/ApplicationContext';
import { CardContext } from '../../../types/CardContext';
import { extractUndefined } from '../../../utils/extractUndefined';

import { CommercialOfferCard } from './CommercialCard';
import { trackOfferCardShow } from './trackOfferCardShow';
import { CommercialCardProps } from './types';

export const CommercialOfferCardContainer: FC<
  Omit<CommercialCardProps, 'isElectronicTrading' | 'config' | 'logger' | 'httpApi'>
> = props => {
  const { offer, user, maxAuctionBet, maxAuctionService, jsonQuery, position = 0 } = props;

  const { config, logger, httpApi } = useApplicationContext();
  const isElectronicTrading = useGetIsElectronicTrading(offer);

  const contextValue = useMemo<CardContext>(
    (): CardContext => ({
      offer,
      user,
      maxAuctionService: extractUndefined(maxAuctionService),
      maxAuctionBet: extractUndefined(maxAuctionBet),
      auctionMaxBet: offer.auction ? offer.auction.maxAvailableBet : 99,
      validateStep: !!config.get<boolean>('validateStep'),
      isMlSearchForAll: !!config.get<boolean>('isMlSearchForAll.Enabled'),
    }),
    [config, maxAuctionBet, maxAuctionService, offer, user],
  );

  const handleCardTeaserShow = () => trackOfferCardShow({ jsonQuery, offerId: offer.id, position });

  return (
    <CardContextProvider value={contextValue}>
      <ActionAfterViewObserver
        callback={handleCardTeaserShow}
        callbackDelay={1000}
        percentVisible={0.5}
        triggerOnce={false}
      >
        <CommercialOfferCard
          config={config}
          httpApi={httpApi}
          logger={logger}
          {...props}
          isElectronicTrading={isElectronicTrading}
        />
      </ActionAfterViewObserver>
    </CardContextProvider>
  );
};
