import { Button } from '@cian/ui-kit';

import * as React from 'react';

import * as styles from './PhoneButton.css';

export interface IPhoneButtonProps {
  text: React.ReactNode;
  isFullWidth: boolean;
  onPhoneOpened(): void;
}

export function PhoneButton({ text = 'Показать телефон', isFullWidth, onPhoneOpened }: IPhoneButtonProps) {
  return (
    <Button
      beforeIcon={<span className={styles['button-text']}>{text}</span>}
      fullWidth={isFullWidth}
      size="XS"
      theme="fill_primary"
      onClick={onPhoneOpened}
    />
  );
}
