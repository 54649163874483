import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IApartmentTagType = 'advanced-dwellingStatus-apartment';

export interface IApartmentTag {
  type: 'advanced-dwellingStatus-apartment';
  value: boolean;
}

function isApartmentTag(tag: TTag): tag is IApartmentTag {
  return tag.type === IApartmentTagType;
}

export const apartmentTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.apartment ? jsonQuery.apartment.value : jsonQuery.only_flat && !jsonQuery.only_flat.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isApartmentTag, () => ({ type: IApartmentTagType, value }));
    } else {
      return ensureTagNotExists(tags, isApartmentTag);
    }
  },
};
