import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const TAG_TYPE = 'advanced-ceilingHeight';

export interface ICeilingHeightTag {
  type: 'advanced-ceilingHeight';
  min: number | undefined;
  max: number | undefined;
}

function isCeilingHeightTag(tag: TTag): tag is ICeilingHeightTag {
  return tag.type === TAG_TYPE;
}

export const ceilingHeightTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.ceiling_height && jsonQuery.ceiling_height.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isCeilingHeightTag, () => ({
        type: TAG_TYPE,
        min: value.gte,
        max: value.lte,
      }));
    } else {
      return ensureTagNotExists(tags, isCeilingHeightTag);
    }
  },
};
