/* eslint-disable max-lines */
import {
  ESuggestType,
  IItemClickParams,
  IMessageInfo,
  IMultiSuggestResult,
  GeoSuggest as StructuredGeoSuggest,
  TSuggestionCustomInputProps,
} from '@cian/geosuggest-widget';
import { IHttpApi } from '@cian/http-api/shared';
import { ILogger } from '@cian/logger';
import { Input, Paragraph2 } from '@cian/ui-kit';

import { isEmpty } from 'ramda';
import * as React from 'react';
import { debounce } from 'throttle-debounce';

import {
  IGeoDetail,
  IGeocodeCachedResult,
  IGeocodeForSearchResponse,
  geocode,
  geocodeCached,
  geocodeForSearch,
} from 'shared/api/geo/geo';
import { TBSCentersSuggestionShort } from 'shared/api/geosuggest/bs_centers';
import { IDirection } from 'shared/filters/api/directions';
import { geoTypeMapper, getMultiSuggest, getSuggestOfferType } from 'shared/filters/components/geo_suggest/api';
import { removeCountryName } from 'shared/filters/components/geo_suggest/helpers';
import { ICoworkingSuggestShort } from 'shared/filters/state/coworking_id';
import { IGeoObject, IPolygonObject, TGeoType } from 'shared/filters/state/geo';
import { ISelectKPParams } from 'shared/filters/state/kp_id';
import { EDealType, ESource, ESuburbanOfferFilter } from 'shared/repositories/geo-suggest/v2/suggest';
import { ILocation, TLocation } from 'shared/types/location';
import { FDealType, FOfferType } from 'shared/utils/category';
import { getRegionId } from 'shared/utils/geo';
import { getGeoSuggestLocation } from 'shared/utils/getGeoSuggestLocation';
import { IMakeRequest } from 'shared/utils/request';

import * as styles from './styles.css';

export type IGeocodeResult = IGeocodeCachedResult;

function getDistrict(result: IGeocodeForSearchResponse) {
  if (result.isParsed) {
    if (result.microDistricts.length > 0) {
      return {
        name: result.microDistricts[0].fullName,
        id: result.microDistricts[0].id,
      };
    }

    const detail = result.details[result.details.length - 1];
    if (detail.geoType === 'District') {
      return {
        name: detail.fullName,
        id: detail.id,
      };
    }
  }

  return undefined;
}

function getRegion(result: IGeocodeForSearchResponse) {
  return result.details.reduce((acc: IGeoDetail | undefined, value) => {
    return value.locationInfo ? value : acc;
  }, undefined);
}

const DEAL_TYPE_SUGGEST_MAP: { [key: number]: EDealType } = {
  [FDealType.Sale]: EDealType.Sale,
  [FDealType.RentLongterm]: EDealType.Rent,
  [FDealType.RentDaily]: EDealType.Rent,
  [FDealType.Rent]: EDealType.Rent,
};

export interface IGeoSuggestProps {
  makeRequest: IMakeRequest;
  logger: ILogger;

  highwaysData: IDirection[];
  dealType: FDealType;
  offerType: FOfferType;
  suburbanOfferFilter: ESuburbanOfferFilter | undefined;
  currentLocation: TLocation;
  boundedBy: [YMaps.TCoord, YMaps.TCoord] | undefined;
  isCoworkingSearch: boolean;

  httpApi: IHttpApi;

  rightAddon?: React.ReactNode;

  initialSelectedValue?: string;
  label?: string;
  placeholder?: string;

  onGeoSelected(value: IGeoObject | IPolygonObject, userInput: string): void;
  onBSCenterSelected(bsCenter: TBSCentersSuggestionShort): void;
  onKPSelected(params: ISelectKPParams): void;
  onCoworkingSelected(coworkingSuggested: ICoworkingSuggestShort): void;

  onReset(): void;
}

export interface IGeoSuggestState {
  value: string;
  selectedValue: string;
  loading: boolean;
  disabled: boolean;
  error: IMessageInfo;
  suggestData?: IMultiSuggestResult;
}

type TGeoModel = Partial<IGeoObject | IPolygonObject> & { isParsed?: boolean };

const ERROR = { title: 'Не удалось получить возможные варианты', text: 'Попробуйте еще раз' };
const NOT_FOUND = { title: 'Ничего не найдено', text: 'Укажите другой адрес' };
const YANDEX_RESULTS_MAX = 10;

export class GeoSuggest extends React.Component<IGeoSuggestProps, IGeoSuggestState> {
  public constructor(props: IGeoSuggestProps) {
    super(props);
    this.state = {
      value: props.initialSelectedValue || '',
      selectedValue: '',
      loading: false,
      disabled: false,
      error: {},
      suggestData: undefined,
    };
  }

  public renderSuggest() {
    const { label, placeholder } = this.props;
    const { disabled, loading, value, selectedValue, suggestData, error } = this.state;

    return (
      <>
        {label && (
          <div className={styles['label']}>
            <Paragraph2>{label}</Paragraph2>
          </div>
        )}
        <StructuredGeoSuggest
          disabled={disabled}
          error={error}
          inputStyle={styles['input']}
          isLoading={loading}
          placeholder={placeholder}
          renderInput={this.renderInput}
          selectedValue={selectedValue}
          suggestData={suggestData}
          value={value}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          onItemClick={this.onItemClick}
          onValueChange={this.onValueChange}
        />
      </>
    );
  }

  public render() {
    return this.renderSuggest();
  }

  private suggest = debounce(300, async (valueRaw: string) => {
    const { currentLocation, offerType, suburbanOfferFilter, httpApi, logger, boundedBy, dealType, isCoworkingSearch } =
      this.props;

    const value = valueRaw.trim();

    if (value) {
      this.setState({ loading: true });
      try {
        const result = await getMultiSuggest(
          httpApi,
          {
            structured: {
              offerType: getSuggestOfferType(offerType),
              query: value,
              regionId: getGeoSuggestLocation(currentLocation),
              dealType: DEAL_TYPE_SUGGEST_MAP[dealType],
              suburbanOfferFilter,
              source: ESource.Serp,
              isCoworkingSearch,
            },
            yandex: {
              options: { boundedBy, results: YANDEX_RESULTS_MAX },
              value: 'Россия, ' + value,
            },
          },
          logger,
        );

        let error: IMessageInfo = {};
        if (this.state.value) {
          if (isEmpty(result.suggestions)) {
            error = NOT_FOUND;
          }
          this.setState({ suggestData: result as IMultiSuggestResult, loading: false, error });
        } else {
          this.setState({ suggestData: undefined, loading: false });
        }
      } catch (e) {
        this.setState({ loading: false, error: ERROR });
      }
    }
  });

  private onFocus = () => {
    const { value } = this.state;

    this.setState({ error: {} });
    if (value.length > 2) {
      this.suggest(value);
    }
  };

  private onBlur = () => {
    this.resetSuggest();
  };

  private onValueChange = (value: string) => {
    this.setState({ value });
    if (value.length > 2) {
      this.suggest(value);
    } else {
      this.setState({ loading: false, error: {}, suggestData: undefined });
    }
  };

  private resetSuggest() {
    this.setState({ value: '', selectedValue: '', error: {}, loading: false, suggestData: undefined });
  }

  private onItemClick = (params: IItemClickParams) => {
    const { id, title, group, regionId } = params;
    const { currentLocation } = this.props;
    //структурированный саджест
    if (id) {
      switch (group) {
        case ESuggestType.businessCenter:
        case ESuggestType.shoppingCenter:
          this.props.onBSCenterSelected({ id, name: title });
          break;
        case ESuggestType.village:
          this.props.onKPSelected({ id, name: title, regionId });
          break;
        case ESuggestType.coworking:
          this.props.onCoworkingSelected({ id, title });
          break;
        default: {
          const geo: IGeoObject = {
            id,
            text: title,
            type: geoTypeMapper(group),
            regionId: regionId || getRegionId(currentLocation),
          };
          this.props.onGeoSelected(geo, title);
        }
      }
      this.setState({ value: title });
      //яндекс саджест
    } else {
      this.setState({
        loading: true,
        disabled: true,
      });

      this.geocode(title)
        .then(results => this.handleGeocodeResults(results, title, title))
        .then(() => this.setState({ value: title }))
        .catch(() => {
          this.setState({
            error: ERROR,
            loading: false,
            disabled: false,
          });
        });
    }
  };

  private async handleGeocodeResults(results: IGeocodeResult[] | void, value: string, userInput: string) {
    if (!results || results.length === 0) {
      this.props.logger.warning(`Empty geocode results for: ${value}`);

      this.setState({
        error: ERROR,
        loading: false,
        disabled: false,
      });

      return;
    }

    const geocodeResult = this.filterResults(results, value);
    const geocodeForSearchRequest = {
      text: geocodeResult.text,
      kind: geocodeResult.kind,
      coordinates: geocodeResult.coordinates,
    };

    try {
      const result = await geocodeForSearch(this.props.makeRequest, geocodeForSearchRequest);
      const objectDetails = result.details[result.details.length - 1];
      const regionInfo: IGeoDetail | undefined = getRegion(result);
      const district = getDistrict(result);

      const locationInfo: ILocation | undefined = regionInfo ? createLocationModel(regionInfo) : undefined;
      let geoModel: TGeoModel = {
        text: removeCountryName(result.details.map(item => item.name).join(', ')).trim(),
        coordinates: [result.geo.lng, result.geo.lat] as [number, number],
        locationId: regionInfo && regionInfo.id,
      };

      if (result.geoLocationCatalogLink) {
        geoModel = {
          ...geoModel,
          type: result.geoLocationCatalogLink.objectType.toLowerCase() as TGeoType,
          id: result.geoLocationCatalogLink.id,
        };
      } else {
        geoModel = {
          ...geoModel,
          // TODO: FUCK IT
          type: objectDetails.geoType.toLowerCase().replace('road', 'highway') as TGeoType,
          id: objectDetails.id,
          regionId: result.regionId,
          locationInfo,
          district,
          isParsed: result.isParsed,
        };
      }

      if (!result.isParsed && !result.geoLocationCatalogLink) {
        const bounds = geocodeResult.boundedBy;
        (geoModel as IPolygonObject).polygon = [
          [bounds[0][0], bounds[0][1]],
          [bounds[0][0], bounds[1][1]],
          [bounds[1][0], bounds[1][1]],
          [bounds[1][0], bounds[0][1]],
          [bounds[0][0], bounds[0][1]],
        ];
      }

      this.props.onGeoSelected(geoModel as IGeoObject, userInput);
      this.resetSuggest();
      this.setState({
        loading: false,
        disabled: false,
      });
    } catch (error) {
      this.props.logger.warning(
        `Failed to geocode using 'geocodeForSearch' for: ${JSON.stringify(geocodeForSearchRequest)}`,
      );

      this.setState({
        error: ERROR,
        loading: false,
        disabled: false,
      });
    }
  }

  private geocode(value: string): Promise<IGeocodeResult[] | void> {
    return this.geocodeCached(value).catch(() => {
      return this.geocodeDirect(value);
    });
  }

  private geocodeCached(value: string): Promise<IGeocodeResult[] | void> {
    return geocodeCached(this.props.makeRequest, value).catch(() => {
      this.props.logger.warning(`Failed to geocode using 'geocodeCached' for: ${value}`);
    });
  }

  private geocodeDirect(value: string): Promise<IGeocodeResult[] | void> {
    return geocode(value)
      .then(result => {
        return result.geoObjects.toArray().map(geoObject => {
          return {
            text: geoObject.properties.get('text') as string,
            name: geoObject.properties.get('name') as string,
            kind: geoObject.properties.get('metaDataProperty.GeocoderMetaData.kind') as YMaps.TGeoKind,
            coordinates: (geoObject.geometry && geoObject.geometry.getCoordinates()) || [0, 0],
            boundedBy: geoObject.properties.get('boundedBy') as [YMaps.TCoord, YMaps.TCoord],
          };
        });
      })
      .catch(() => {
        this.props.logger.warning(`Failed to geocode using 'geocode' for: ${value}`);
      });
  }

  private filterResults(results: IGeocodeResult[], value: string) {
    if (results.length === 1) {
      return results[0];
    }

    const filteredResults = results.filter(result => {
      return result.text === value;
    });

    if (filteredResults.length !== 1) {
      this.props.logger.warning(`Failed to filter geocode results for: ${value}`);

      return results[0];
    }

    return filteredResults[0];
  }

  private renderInput(props: TSuggestionCustomInputProps) {
    return <Input {...props} size="M" onChange={(_, value) => props.onChange(value)} />;
  }
}

function createLocationModel(detail: IGeoDetail) {
  return {
    ...detail.locationInfo,
    displayName: detail.fullName,
    name: detail.name,
    id: detail.id,
    fullName: detail.fullName,
  };
}
