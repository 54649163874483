import { IAppState, TActions } from '../../../common/state';
import { EBuildingClassType, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TBuildingClassTypeChangedActionType = 'filters/building_class/BUILDING_CLASS_TYPE_CHANGED';
export const IBuildingClassTypeChangedActionType =
  'filters/building_class/BUILDING_CLASS_TYPE_CHANGED' as TBuildingClassTypeChangedActionType;

export interface IBuildingClassTypeChangedAction {
  type: 'filters/building_class/BUILDING_CLASS_TYPE_CHANGED';
  paramType: EBuildingClassType;
  value: boolean;
}

export const changeBuildingClass = getParamChangedAction<EBuildingClassType, TBuildingClassTypeChangedActionType>(
  IBuildingClassTypeChangedActionType,
);

export function buildingClassTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IBuildingClassTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setBuildingClassType(action.paramType)
            : jq(state.filters.jsonQuery).unsetBuildingClassType(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-buildingClass') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetBuildingClassType(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetBuildingClassTypes(),
        },
      };

    default:
      return state;
  }
}
