/**
 * @todo Удалить файл если эксперимент не заапрувится
 * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
 */

import { IAppState } from '../common/state';
import { COMMERCIAL_USER_PROFILING_EXPERIMENT } from '../constants/experiments';
import { isAbUseExperimentGroup } from '../utils/experiments/isAbUseExperimentGroup';
import { getUserProfilingAvalablesEmails } from '../utils/userProfilingAvalablesEmails';
import { getUserProfilingEnabled } from '../utils/userProfilingEnabled';

import { getUserEmail } from './user/getUserEmail';

export function isUserProfilingModalVisible(state: IAppState): boolean {
  /**
   * @todo Удалить условие "getUserProfilingEnabled()" с RS
   * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117942
   */
  if (!getUserProfilingEnabled()) {
    return false;
  }

  /**
   * @todo Удалить условие, если эксперимент заапрувится
   * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
   */
  if (isAbUseExperimentGroup(state.abUseExperiments, COMMERCIAL_USER_PROFILING_EXPERIMENT, 'B')) {
    return state.isUserProfilingModalVisible && !state.user.isAuthenticated;
  }

  const userEmail = getUserEmail(state);
  const availableUsersEmails = getUserProfilingAvalablesEmails();

  return availableUsersEmails.includes(userEmail) && state.isUserProfilingModalVisible;
}
