import { getConfig } from '@cian/config/browser';
import { parseCookies } from '@cian/utils/lib/shared/cookie';

import { getTeaserApiUrl } from '../../utils/getTeaserApiUrl';

export interface ITeaserEventProps {
  realtyUserId?: number | string | null;
  bannerId: string;
  bannerPlace: string;
  action: 'click' | 'show';
}

export function sendAdsEvent(event: ITeaserEventProps) {
  const config = getConfig();

  const disableSendAdsEvents = !!config.get<boolean>('builders.disableSendAdsEvents');

  const cid = parseCookies(document.cookie)['_CIAN_GK'];
  const { action, bannerId, bannerPlace, realtyUserId } = event;
  const teaserApiUrl = getTeaserApiUrl();

  if (!teaserApiUrl || !bannerId || disableSendAdsEvents) {
    return;
  }

  const userId = Number(realtyUserId);

  fetch(teaserApiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_id: isFinite(userId) ? userId : undefined,
      device_type: 'desktop',
      cid,
      pageType: 'Listing',
      items: [
        {
          action,
          bannerId,
          bannerPlace,
          timestamp: Date.now(),
          dl: window.location.href,
        },
      ],
    }),
  });
}
