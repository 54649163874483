import { IHttpApi } from '@cian/http-api/shared/http';
import { Outside } from '@cian/ui-kit';

import { Component, RefObject, createRef } from 'react';

import { EMaxAuctionService, IAuction, IOffer } from 'shared/offer';

import { AuctionBetManager } from '../AuctionBetManager';
import { THintType, trackAuctionHint } from '../AuctionBetManager/tracking';

import * as styles from './AuctionPopup.css';

export enum AuctionPopupKind {
  /** Объявление принадлежит пользователю и ставку можно изменить  */
  MANAGE,
  /** Объявление не принадлежит пользователю, но есть на других страницах выдачи */
  AUCTION,
  /** Объявление не принадлежит пользователю, но его объявление есть на этой странице выдачи  */
  AUCTION_ON_THIS_PAGE,
  /** Объявление принадлежит пользователю и ставку нельзя изменить */
  NOT_PREMIUM,
  /**
   * Объявление не принадлежит пользователю, но его объявление есть на этой странице выдачи
   * и имеет отличный от текущего отбъявления пакет услуг
   */
  AUCTION_DIFFERENT_SERVICE,
}

export type TAuctionPopupKind = AuctionPopupKind | null;

export interface IAuctionPopupProps {
  auction: IAuction;
  auctionMaxBet: number;
  httpApi: IHttpApi;
  initialBet: number;
  isFromOwn?: boolean;
  isMlSearchForAll: boolean;
  kind: TAuctionPopupKind;
  maxAuctionBet: number | null;
  maxAuctionService: EMaxAuctionService | null;
  offer: IOffer;
  validateStep: boolean;
  onClose(): void;
}

interface IAuctionPopupState {
  betModalRefs: RefObject<HTMLElement>[];
}

const POPUP_ANALYTICS_MAP: { [index: number]: THintType } = {
  [AuctionPopupKind.MANAGE]: 'up_search',
  [AuctionPopupKind.AUCTION]: 'no_offer',
  [AuctionPopupKind.AUCTION_ON_THIS_PAGE]: 'with_offer',
  [AuctionPopupKind.NOT_PREMIUM]: 'not_premium',
};

export class AuctionPopup extends Component<IAuctionPopupProps, IAuctionPopupState> {
  public selectorRef = createRef<HTMLSpanElement>();
  public state: IAuctionPopupState = {
    betModalRefs: [],
  };

  private setBetModalRefs = (betModalRefs: RefObject<HTMLElement>[]) => {
    this.setState({ betModalRefs });
  };

  public componentDidMount() {
    const { kind, offer } = this.props;
    const hintType = POPUP_ANALYTICS_MAP[kind as AuctionPopupKind];

    trackAuctionHint(hintType, 'show', offer);
  }

  public render() {
    const {
      kind,
      initialBet,
      isFromOwn = false,
      onClose,
      offer,
      maxAuctionService,
      maxAuctionBet,
      httpApi,
      auctionMaxBet,
      validateStep,
      auction,
    } = this.props;
    const { betModalRefs } = this.state;

    const currentServiceType = this.getCurrentOfferServiceType();

    return (
      <Outside active={true} insideRefs={betModalRefs} onOutside={onClose}>
        <div className={styles['popup']}>
          <div className={styles['close']} onClick={onClose} />
          {((): JSX.Element | null => {
            switch (kind) {
              case AuctionPopupKind.MANAGE:
                return (
                  <AuctionBetManager
                    auction={auction}
                    auctionBetCalculator={this.getAuctionBet}
                    auctionMaxBet={auctionMaxBet}
                    httpApi={httpApi}
                    initialBet={this.getAuctionBet(initialBet)}
                    isFromOwn={isFromOwn}
                    offer={offer}
                    setBetModalRefs={this.setBetModalRefs}
                    validateStep={validateStep}
                    onClose={onClose}
                  />
                );
              case AuctionPopupKind.AUCTION_ON_THIS_PAGE:
                return (
                  <>
                    Чтобы опередить это
                    <br />
                    объявление,&nbsp;
                    <a className={styles['raise_bet_link']} onClick={this.handleRaiseBetLinkClick(auction)}>
                      повысьте ставку
                    </a>
                  </>
                );
              case AuctionPopupKind.AUCTION:
                return (
                  <>
                    Чтобы опередить это объявление,
                    <br />
                    опубликуйте {this.getAdTypeName()}объявление
                    <br />
                    со ставкой больше {auction.currentBet} ₽
                  </>
                );
              case AuctionPopupKind.AUCTION_DIFFERENT_SERVICE:
                return (
                  <>
                    Чтобы опередить это объявление,
                    <br />
                    <a
                      className={styles['raise_bet_link']}
                      href={this.getAdLink(
                        `${auction.userOfferId}`,
                        currentServiceType,
                        auction.currentBet + auction.stepBet,
                      )}
                      rel="noreferrer"
                      target="_blank"
                    >
                      опубликуйте
                    </a>{' '}
                    ваше объявление
                    <br />
                    со ставкой {auction.currentBet + auction.stepBet} ₽.
                  </>
                );
              case AuctionPopupKind.NOT_PREMIUM:
                return (
                  <>
                    Хотите поднять объявление на первую
                    <br />
                    страницу поиска?
                    <br />
                    <a
                      className={styles['raise_bet_link']}
                      href={this.getAdLink(`${offer.id}`, maxAuctionService, maxAuctionBet)}
                      rel="noreferrer"
                      target="_blank"
                      onClick={this.handlePublishOfferClick}
                    >
                      Опубликуйте его
                    </a>
                    &nbsp; как {this.getAuctionServiceName(maxAuctionService)}
                    <br />
                    со ставкой больше {this.getAuctionBet(maxAuctionBet)} ₽.
                  </>
                );
              default:
                return null;
            }
          })()}
        </div>
      </Outside>
    );
  }

  private getAdLink = (id: string, service: string | null, bet: number | null) => {
    const { isMlSearchForAll } = this.props;

    return `/razmestit-obyavlenie/${id}/?auctionBet=${bet}${isMlSearchForAll ? `&auctionService=${service}` : ''}`;
  };

  private getAuctionServiceName = (maxAuctionService: EMaxAuctionService | null): string => {
    switch (maxAuctionService) {
      case EMaxAuctionService.top:
        return 'Топ-объявление';
      case EMaxAuctionService.premium:
        return 'Премиум-объявление';
      case EMaxAuctionService.paid:
      default:
        return 'Платное объявление';
    }
  };

  private getAuctionBet = (value?: number | null): number => {
    const { auction } = this.props;

    if (value) {
      return value;
    }

    return auction.stepBet;
  };

  private getAdTypeName = () => {
    const { offer } = this.props;

    switch (true) {
      case offer.isPremium:
        return 'Премиум-';

      case offer.isTop3:
        return 'Топ-';

      case offer.isPaid:
        return 'Платное ';

      default:
        return '';
    }
  };

  public componentWillUnmount() {
    const { offer, kind } = this.props;
    const hintType = POPUP_ANALYTICS_MAP[kind as AuctionPopupKind];

    trackAuctionHint(hintType, 'close', offer);
  }

  private handleRaiseBetLinkClick = (auction: IAuction) => () => {
    const { userOfferId, currentBet } = auction;
    const { offer } = this.props;

    if (!userOfferId) {
      return;
    }

    const event = new CustomEvent('auctionBetManagerShow', { detail: { currentBet, userOfferId } });

    document.dispatchEvent(event);

    this.props.onClose();

    trackAuctionHint('with_offer', 'click', offer);
  };

  private handlePublishOfferClick = () => {
    const { offer } = this.props;

    trackAuctionHint('not_premium', 'click', offer);
  };

  private getCurrentOfferServiceType = () => {
    const { offer } = this.props;

    switch (true) {
      case offer.isTop3:
        return 'top3';

      case offer.isPremium:
        return 'premium';

      case offer.isPaid:
      default:
        return 'paid';
    }
  };
}
