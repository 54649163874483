import { compose } from 'ramda';
import { connect } from 'react-redux';

import { Dispatch, IAppState } from '../../../common/state';
import { FOfferType, offerTypeFromJsonQuery } from '../../../utils/category';
import { withEmptyRendering } from '../../../utils/hoc/withEmptyRendering';
import { IQuickLinksDispatchProps, IQuickLinksStateProps, QuickLinks } from '../../components/QuickLinks';
import { openOfficeCalculator } from '../../state/office_calculator';

import { linkGroupsSelector } from './utils/selectors';

export function mapStateToProps(state: IAppState): IQuickLinksStateProps {
  return {
    isCountrysideTrapEnabled: Boolean(state.isCountrysideTrapEnabled),
    avgPriceInformer: state.results.avgPriceInformer,
    countrysideLink: state.villagesLink,
    isOfficeCalculatorButtonVisible: offerTypeFromJsonQuery(state.results.jsonQuery) === FOfferType.Office,
    jsonQuery: state.results.jsonQuery,
    level: state.results.quickLinks && state.results.quickLinks.level,
    linkGroups: linkGroupsSelector(state),
    priceSm: state.results.jsonQuery.price_sm && state.results.jsonQuery.price_sm.value,
    dealType: state.results.jsonQuery._type,
  };
}

export function mapDispatchToProps(dispatch: Dispatch): IQuickLinksDispatchProps {
  return {
    onOfficeCalculatorButtonClick: () => {
      dispatch(openOfficeCalculator());
    },
  };
}

export function shouldHideQuickLinks(props: IQuickLinksStateProps) {
  return !props.level || !props.linkGroups;
}

export const QuickLinksContainer = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withEmptyRendering(shouldHideQuickLinks),
)(QuickLinks);
