import { IAppState, TActions } from '../../../common/state';
import { EElevators } from '../../../json_query';

const IElevatorsChangedActionType = 'filters/elevators/ELEVATORS_CHANGED';
const IServiceLiftChangedActionType = 'filters/elevators/SERVICE_LIFT_CHANGED';

export interface IElevatorsChangedAction {
  type: 'filters/elevators/ELEVATORS_CHANGED';
  elevatorsCount: EElevators;
}

export interface IServiceLiftChangedAction {
  type: 'filters/elevators/SERVICE_LIFT_CHANGED';
  hasServiceLift: boolean;
}

export function changeElevators(elevatorsCount: EElevators): IElevatorsChangedAction {
  return {
    type: IElevatorsChangedActionType,
    elevatorsCount,
  };
}

export function changeServiceLiftCheckbox(hasServiceLift: boolean): IServiceLiftChangedAction {
  return {
    type: IServiceLiftChangedActionType,
    hasServiceLift,
  };
}

export function elevatorsReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IElevatorsChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            lifts:
              action.elevatorsCount !== EElevators.Any
                ? {
                    type: 'term',
                    value: action.elevatorsCount,
                  }
                : undefined,
          },
        },
      };

    case IServiceLiftChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            lift_service: action.hasServiceLift
              ? {
                  type: 'range',
                  value: {
                    gte: 1,
                  },
                }
              : undefined,
          },
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-elevators-minCount') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              lifts: undefined,
            },
          },
        };
      } else if (action.tag.type === 'advanced-elevators-hasService') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              lift_service: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            lifts: undefined,
            lift_service: undefined,
          },
        },
      };

    default:
      return state;
  }
}
