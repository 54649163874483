import { EEstateType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IEstateTypeTagType = 'advanced-estate-type';

export interface IEstateTypeTag {
  type: 'advanced-estate-type';
  value: EEstateType;
}

function isEstateTypeTag(tag: TTag): tag is IEstateTypeTag {
  return tag.type === IEstateTypeTagType;
}

export const estateTypeTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.estate_type && jsonQuery.estate_type.value;

    let nextTags = tags.filter(
      tag => tag.type !== IEstateTypeTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EEstateType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IEstateTypeTag => isEstateTypeTag(tag) && tag.value === value,
            () => ({ type: IEstateTypeTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
