import { IAppState, TActions } from '../../../common/state';
import { ESuburbanWCType, jq } from '../../../json_query';

export const ISuburbanWCTypeChangedActionType = 'filters/suburban_wc/SUBURBAN_WC_TYPE_CHANGED';

export interface ISuburbanWCTypeChangedAction {
  type: 'filters/suburban_wc/SUBURBAN_WC_TYPE_CHANGED';
  suburbanWCType: ESuburbanWCType;
}

export function changeSuburbanWCType(suburbanWCType: ESuburbanWCType): ISuburbanWCTypeChangedAction {
  return {
    type: ISuburbanWCTypeChangedActionType,
    suburbanWCType,
  };
}

export function suburbanWCReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ISuburbanWCTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setSuburbanWCType(action.suburbanWCType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-suburbanWC') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetSuburbanWCType(),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetSuburbanWCType(),
        },
      };

    default:
      return state;
  }
}
