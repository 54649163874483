import { Dispatch, IAppState, TActions } from '../../common/state';

export interface IPrintChangedAction {
  type: 'serp/print/CHANGED';
  options: {
    enabled: boolean;
    showComments: boolean;
    showPhones: boolean;
  };
}

export interface IChangePrintOptions {
  enabled?: boolean;
  showComments?: boolean;
  showPhones?: boolean;
}

export function changePrintOptions(options: IChangePrintOptions) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const newOptions = Object.assign({}, state.print, options);

    dispatch({
      type: 'serp/print/CHANGED',
      options: newOptions,
    });
  };
}

export function offersPrintReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'serp/print/CHANGED': {
      const { enabled, showComments, showPhones } = action.options;

      return {
        ...state,
        print: {
          ...state.print,
          enabled,
          showComments,
          showPhones,
        },
      };
    }
    default:
      return state;
  }
}
