import { IAppState, TActions } from '../../../common/state';
import { EPropertyType } from '../../../json_query';

export const IPropertyTypeAddedActionType = 'filters/property_type/PROPERTY_TYPE_ADDED';
export const IPropertyTypeRemovedActionType = 'filters/property_type/PROPERTY_TYPE_REMOVED';

export interface IPropertyTypeAddedAction {
  type: 'filters/property_type/PROPERTY_TYPE_ADDED';
  value: EPropertyType;
}

export interface IPropertyTypeRemovedAction {
  type: 'filters/property_type/PROPERTY_TYPE_REMOVED';
  value: EPropertyType;
}

export function propertyTypeAdded(value: EPropertyType): IPropertyTypeAddedAction {
  return {
    type: IPropertyTypeAddedActionType,
    value,
  };
}

export function propertyTypeRemoved(value: EPropertyType): IPropertyTypeRemovedAction {
  return {
    type: IPropertyTypeRemovedActionType,
    value,
  };
}

export function propertyTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IPropertyTypeAddedActionType: {
      const propertyTypes =
        (state.filters.jsonQuery.property_type && state.filters.jsonQuery.property_type.value) || [];

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            property_type: {
              type: 'terms',
              value: propertyTypes.concat(action.value),
            },
          },
        },
      };
    }
    case IPropertyTypeRemovedActionType: {
      let propertyTypesNext =
        (state.filters.jsonQuery.property_type && state.filters.jsonQuery.property_type.value) || [];

      propertyTypesNext = propertyTypesNext.filter(propertyType => propertyType !== action.value);

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            property_type:
              propertyTypesNext.length > 0
                ? {
                    type: 'terms',
                    value: propertyTypesNext,
                  }
                : undefined,
          },
        },
      };
    }
    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-propertyType') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              property_type: undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            property_type: undefined,
          },
        },
      };
    default:
      return state;
  }
}
