import { EBedroomsCount } from 'shared/json_query';

import { ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

export const IBedroomsMinCountTagType = 'advanced-bedroomsCount';

export interface IBedroomsMinCountTag {
  type: 'advanced-bedroomsCount';
  value: EBedroomsCount;
}

export const isBedroomsMinCountTag = (tag: TTag): tag is IBedroomsMinCountTag => tag.type === IBedroomsMinCountTagType;

export const bedroomsMinCountTagDefinition: ITagDefinition = {
  reduce: tags => {
    return ensureTagNotExists(tags, isBedroomsMinCountTag);
  },
};
