import { IHttpApi, IModel, IModelResponse } from '@cian/http-api/shared';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TRequest = { [key: string]: any };

export interface IHTTPSimplifiedRequestConfig<TResponse = unknown>
  extends Omit<IModel<TRequest, IModelResponse<TResponse>>, 'assertStatusCodes'> {
  /**
   * @deprecated Необходимо использовать swagger-generator
   */
  microserviceName: 'monolith-python' | 'monolith-cian-realty' | 'search-offers';
  headers?: [string, string][];
  bodyAsEncodeString?: boolean;
  transformBodyToLegacyString?: boolean;
}

/**
 * @deprecated Необходимо использовать swagger-generator
 */
export interface IMakeRequest {
  <TResponse = unknown>(requestConfig: IHTTPSimplifiedRequestConfig<TResponse>, debug?: boolean): Promise<TResponse>;
}

/**
 * @deprecated Необходимо использовать swagger-generator
 */
export function createMakeRequest(
  httpApi: IHttpApi,
  subdomain?: string,
  proxyHeaders?: [string, string][],
): IMakeRequest {
  let headers: [string, string][] = [];

  if (proxyHeaders) {
    headers = headers.concat(proxyHeaders);
  }

  /**
   * @deprecated Необходимо использовать swagger-generator
   */
  return async <TResponse>(requestConfig: IHTTPSimplifiedRequestConfig<TResponse>): Promise<TResponse> => {
    const { headers: requestHeaders, bodyAsEncodeString, transformBodyToLegacyString, ...sharedConfig } = requestConfig;
    const nextHeaders = requestHeaders ? headers.concat(requestHeaders) : headers;

    const config: IModel<TRequest, IModelResponse<TResponse>> = {
      ...sharedConfig,
      /* Сознательно дропаем здесь обязательный assertStatusCodes */
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as any;

    const response = await httpApi.fetch<TRequest, IModelResponse<TResponse>>(config, {
      requestConfig: { headers: nextHeaders },
      bodyAsEncodeString,
      transformBodyToLegacyString,
      ...(['monolith-python', 'monolith-cian-realty'].includes(sharedConfig.microserviceName) ? { subdomain } : {}),
    });

    return response.response;
  };
}
