import { debounce } from 'throttle-debounce';

import { IJsonQueryCircle, TCoordinate } from 'shared/json_query';
import { coordsNumberToString } from 'shared/utils/geo';

import { BaseDrawObject, IBaseMapObjectData } from './base_draw_object';
import { circleModelToDrawObject, createCircleGeoObject } from './helpers';
import { YMAPS_PRESETS_CIRCLE } from './settings';

export interface ICircleObjectData extends IBaseMapObjectData {
  radius: number;
  center: TCoordinate;
}

export class Circle extends BaseDrawObject<ICircleObjectData, IJsonQueryCircle> {
  public startDraw = (circleModel?: IJsonQueryCircle) => this.onStartDraw(circleModel);

  public stopDraw() {
    super.stopDraw();
    this.drawingStopHandler();
  }

  public stopEditing = () => this.editor.stopEditing();

  public getCoords = (): ICircleObjectData => {
    const coordinates = this.areaGeoObject.geometry && this.areaGeoObject.geometry.getCoordinates();
    const radius = this.areaGeoObject.geometry && Math.round(this.areaGeoObject.geometry.getRadius());

    if (coordinates && radius) {
      this.areaObject.center = coordsNumberToString(coordinates);
      this.areaObject.radius = radius;
    }

    return { ...this.areaObject };
  };

  protected createDrawObject(circleModel?: IJsonQueryCircle): ICircleObjectData {
    return circleModelToDrawObject(this.name, circleModel);
  }

  protected createDrawGeoObject(ymaps: YMaps.IYMaps, areaObject: ICircleObjectData): YMaps.IGeoObject {
    return createCircleGeoObject(this.ymaps, areaObject);
  }

  protected setOptions() {
    this.editor.options.set(YMAPS_PRESETS_CIRCLE.CIRCLE);
  }

  protected bindEvents() {
    super.bindEvents();

    this.areaGeoObject.events.add('geometrychange', this.debouncedUpdateHandler);
    this.areaGeoObject.events.add('click', this.toggleEditingMode);
    this.editor.events.add('click', this.toggleEditingMode);
  }

  protected unbindEvents() {
    super.unbindEvents();

    this.areaGeoObject.events.remove('geometrychange', this.debouncedUpdateHandler);
    this.areaGeoObject.events.remove('click', this.onClick);
    this.areaGeoObject.events.remove('click', this.toggleEditingMode);
    this.editor.events.remove('click', this.toggleEditingMode);
  }

  protected drawingStopHandler() {
    this.stopEditing();
    this.areaGeoObject.events.add('click', this.onClick);
    this.map.events.add('click', this.stopEditing);
    super.drawingStopHandler();
  }

  protected onClick = (event: YMaps.IEvent) => this.showMenu(event.get('offsetX'), event.get('offsetY'), this);

  private isEditingMode = () => this.editor.state.get('editing');

  private startEditing = () => this.editor.startEditing();

  private toggleEditingMode = () => (this.isEditingMode() ? this.stopEditing() : this.startEditing());

  private debouncedUpdateHandler = debounce(500, this.updateHandlerBind);
}
