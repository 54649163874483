import { Spinner } from '@cian/ui-kit';

import * as React from 'react';

import { EGalleryItemStatus, GalleryItem } from '../item';

const style = require('./index.css');

export type IGalleryItemPreloaderProps = Readonly<{ children: React.ReactNode }>;

export interface IGalleryItemPreloaderState {
  status: EGalleryItemStatus;
}

export class GalleryItemPreloader extends React.Component<IGalleryItemPreloaderProps, IGalleryItemPreloaderState> {
  public state: IGalleryItemPreloaderState = {
    status: EGalleryItemStatus.Pending,
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private item: GalleryItem<any, any>;

  public componentDidMount() {
    if (this.state.status === EGalleryItemStatus.Pending) {
      this.item.load();
    }
  }

  public render() {
    return (
      <div className={style.container}>
        {this.renderLoader()}
        {this.props.children
          ? React.cloneElement(this.props.children as React.ReactElement, {
              onStateChange: (state: EGalleryItemStatus) => this.setState({ status: state }),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ref: (item: GalleryItem<any, any>) => (this.item = item),
            })
          : null}
      </div>
    );
  }

  private renderLoader() {
    if (this.state.status === EGalleryItemStatus.Loading) {
      return (
        <div
          style={{
            height: '20%',
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            width: '20%',
          }}
        >
          <Spinner size={32} />
        </div>
      );
    } else if (this.state.status === EGalleryItemStatus.Erroneous) {
      return (
        <div
          style={{
            left: '50%',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          Ошибка загрузки
        </div>
      );
    }

    return null;
  }
}
