import * as React from 'react';

export const IconClose: React.FC = () => (
  // eslint-disable-next-line react/forbid-elements
  <svg height="12" width="12" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.76.345A.999.999 0 0 0 .342.343.994.994 0 0 0 .345 1.76L4.585 6l-4.24 4.24a.999.999 0 0 0-.002 1.417.994.994 0 0 0 1.416-.002L6 7.415l4.24 4.24a.999.999 0 0 0 1.417.002.994.994 0 0 0-.002-1.416L7.415 6l4.24-4.24a.999.999 0 0 0 .002-1.417.994.994 0 0 0-1.416.002L6 4.585 1.76.346z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
