import { IOffer, IOfferServices } from 'shared/offer';

import { AuctionPopupKind } from '../../AuctionPopup';

import { getServiceName } from './getServiceName';

export const getAucionPopupTypeForNotOwn = (offer: IOffer): number | null => {
  const { auction } = offer;

  if (!auction) {
    return null;
  }

  const userOfferService = getServiceName<IOfferServices>(auction.userOfferServices);

  const isSameService = userOfferService && offer[userOfferService as keyof IOffer];

  switch (true) {
    // На этой странице есть объявление текущего пользователя
    // и у него такой же пакет услуг, как у текущего объявления
    case auction.userOfferId && isSameService:
      return AuctionPopupKind.AUCTION_ON_THIS_PAGE;

    // На этой странице есть объявление текущего пользователя,
    // но у него другой пакет услуг, как у текущего объявления
    case auction.userOfferId && !isSameService:
      return AuctionPopupKind.AUCTION_DIFFERENT_SERVICE;

    // На этой странице нет объявлений текущего пользователя
    default:
      return AuctionPopupKind.AUCTION;
  }
};
