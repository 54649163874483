import { setCookie } from '@cian/utils/lib/browser/cookie';

import { Dispatch, IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

import { makeSearch } from './search';

export interface IHiddenBaseSearchChangedAction {
  type: 'filters/is_in_hidden_base/CHANGED';
  value: boolean;
}

export interface IHiddenBaseSearchTooltipClosedAction {
  type: 'filters/is_in_hidden_base/TOOLTIP_CLOSED';
}

export function closeHiddenBaseTooltip() {
  return (dispatch: Dispatch) => {
    const tenYearsInSeconds = 10 * 365 * 24 * 3600;

    setCookie('hiddenBaseTooltipIsHidden', '1', {
      expires: tenYearsInSeconds,
      domain: '.cian.ru',
      secure: false,
    });

    dispatch({
      type: 'filters/is_in_hidden_base/TOOLTIP_CLOSED',
    });
  };
}

export function changeHiddenBaseSearch(value: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: 'filters/is_in_hidden_base/CHANGED',
      value,
    });

    dispatch(makeSearch());
  };
}

export function hiddenBaseSearchReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/is_in_hidden_base/CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setHiddenBaseSearch(action.value),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'is-in-hidden-base') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.unsetHiddenBaseSearch(),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.unsetHiddenBaseSearch(),
        },
      };

    case 'filters/is_in_hidden_base/TOOLTIP_CLOSED':
      return {
        ...state,
        results: {
          ...state.results,
          isHiddenBaseSearchTooltipVisible: false,
        },
      };
    default:
      return state;
  }
}
