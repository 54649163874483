import { IAppState, TActions } from '../../../common/state';
import { jq } from '../../../json_query';

export const IDecorationTypeChangedActionType = 'filters/decoration/DECORATION_TYPE_CHANGED';

export interface IDecorationTypeChangedAction {
  type: 'filters/decoration/DECORATION_TYPE_CHANGED';
  value: boolean | undefined;
}

export function changeDecorationType(value: boolean | undefined): IDecorationTypeChangedAction {
  return {
    type: IDecorationTypeChangedActionType,
    value,
  };
}

export function decorationTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IDecorationTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setDecorationType(action.value),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-decoration') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).setDecorationType(undefined),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setDecorationType(undefined),
        },
      };

    default:
      return state;
  }
}
