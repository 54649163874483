import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IDecorationTagType = 'advanced-decoration';

export interface IDecorationTag {
  type: 'advanced-decoration';
  value: boolean;
}

const isDecorationTag = (tag: TTag): tag is IDecorationTag => tag.type === IDecorationTagType;

export const decorationTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_decoration && jsonQuery.has_decoration.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isDecorationTag, () => ({ type: IDecorationTagType, value }));
    } else {
      return ensureTagNotExists(tags, isDecorationTag);
    }
  },
};
