import { FOfferType, offerTypeFromJsonQuery } from '../../../../utils/category';
import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const ITotalFloorsTagType = 'totalFloors';

export interface ITotalFloorsTag {
  type: 'totalFloors';
  offerType: FOfferType;
  min: number | undefined;
  max: number | undefined;
}

function isTotalFloorsTag(tag: TTag): tag is ITotalFloorsTag {
  return tag.type === ITotalFloorsTagType;
}

export const totalFloorsTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.floorn && jsonQuery.floorn.value;

    if (value !== undefined) {
      const offerType = offerTypeFromJsonQuery(jsonQuery);

      return ensureSingleTagExists(tags, isTotalFloorsTag, () => ({
        type: ITotalFloorsTagType,
        offerType,
        min: value.gte,
        max: value.lte,
      }));
    } else {
      return ensureTagNotExists(tags, isTotalFloorsTag);
    }
  },
};
