import { ensureSingleTagExists, ensureTagNotExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IFurnitureTagType = 'advanced-facilities-furniture';

export interface IFurnitureTag {
  type: 'advanced-facilities-furniture';
  value: boolean;
}

function isFurnitureTag(tag: TTag): tag is IFurnitureTag {
  return tag.type === IFurnitureTagType;
}

export const furnitureTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const value = jsonQuery.has_furniture && jsonQuery.has_furniture.value;

    if (value !== undefined) {
      return ensureSingleTagExists(tags, isFurnitureTag, () => ({ type: IFurnitureTagType, value }));
    } else {
      return ensureTagNotExists(tags, isFurnitureTag);
    }
  },
};
