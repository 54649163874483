import { EOfficeType, IJsonQuery } from '../json_query';

export const isOfficeRentSearch = (jsonQuery: IJsonQuery): boolean => {
  const isCommercialRent = jsonQuery._type === 'commercialrent';
  const officeTypeFilter = jsonQuery.office_type?.value;
  const isOfficeSearch =
    !!officeTypeFilter && officeTypeFilter.length === 1 && officeTypeFilter.includes(EOfficeType.Office);
  const isCoworkingSearch = !!jsonQuery.coworking_offer_type?.value?.length;

  return isCommercialRent && isOfficeSearch && !isCoworkingSearch;
};
