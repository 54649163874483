import { connect } from 'react-redux';

import { Dispatch, IAppState } from '../../../common/state';
import { changePage } from '../../../filters/state/pagination';
import { getTotalPagesCount } from '../../../utils/pagination';

import { Pagination } from './index';

export const mapStateToProps = (state: IAppState) => {
  const {
    config,
    results: { queryString, jsonQuery, aggregatedOffers, paginationUrls },
  } = state;

  const offersPerPage = Number(config.get('serp.offersPerPage'));

  return {
    queryString,
    jsonQuery,
    aggregatedOffers,
    paginationUrls,
    offersPerPage,
    totalPages: getTotalPagesCount(offersPerPage, aggregatedOffers),
  };
};

export const mapDispatchToProps = (dispatch: Dispatch) => ({
  onSelect: (page: number) => {
    dispatch(changePage(page));
  },
});

export const PaginationContainer = connect(mapStateToProps, mapDispatchToProps)(Pagination);
