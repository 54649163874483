/* eslint-disable max-lines */
import { ILogger } from '@cian/logger';
import { HttpBadStatusCodeError, HttpTimeoutError } from '@cian/peperrors/shared';
import { ITelemetryLogger } from '@cian/telemetry/shared';

import { IUserGADataLayerData } from '../../common/state';
import { IJsonQuery, IJsonQueryNewbuilding } from '../../json_query';
import { offerHelper } from '../../offer';
import { IOffer } from '../../offer/types';
import { ILocation } from '../../types/location';
import { IOffersData } from '../../types/offersData';
import { RegularResponse, returnResponseDataIfOk } from '../../utils/api';
import { IMakeRequest } from '../../utils/request';

export interface ISearchReqBody {
  _subdomain: string;
  _path: string;
}

export interface ISimilarNewobjectsData {
  priceDisplay: string;
  addressDisplay: string;
  name: string;
  imageUrl: string;
  fullUrl: string;
  undergroundInfo: {
    timeDisplay: string;
    name: string;
    lineColor: string;
  };
  buildersDisplay: string | null;
  minPrice: number;
  id: number;
  statusDisplay: string;
}

export type ISimilarNewobjectsFromDeveloperData = ISimilarNewobjectsData;

export function requestSimilarNewobjects(
  makeRequest: IMakeRequest,
  newobjectId: number,
): Promise<ISimilarNewobjectsData[] | null> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-similar-newobjects/',
    parameters: {
      newobjectId,
    },
    method: 'GET',
  })
    .then((res: { data: { newobjects: ISimilarNewobjectsData[] } }) => res.data.newobjects)
    .catch(ex => {
      if (ex instanceof HttpBadStatusCodeError) {
        if (ex.statusCode === 404) {
          return null;
        }
      }

      throw ex;
    });
}

export function requestSimilarNewobjectsFromDeveloper(
  makeRequest: IMakeRequest,
  newobjectId: number,
): Promise<ISimilarNewobjectsFromDeveloperData[] | null> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-newobjects-builder/',
    parameters: {
      newobjectId,
    },
    method: 'GET',
  })
    .then((res: { data: { newobjects: ISimilarNewobjectsFromDeveloperData[] } }) => {
      return res.data.newobjects;
    })
    .catch(ex => {
      if (ex instanceof HttpBadStatusCodeError) {
        if (ex.statusCode === 404) {
          return null;
        }
      }

      throw ex;
    });
}

export const getSimilarNewobjectsPromise = (
  makeRequest: IMakeRequest,
  offersData: IOffersData,
): Promise<ISimilarNewobjectsData[] | null> => {
  const newbuildingTags =
    offersData.jsonQuery.geo &&
    (offersData.jsonQuery.geo.value.filter(geo => geo.type === 'newobject') as IJsonQueryNewbuilding[]);

  if (offersData.offerCount > 0 && offersData.offerCount < 11 && newbuildingTags && newbuildingTags.length > 0) {
    return requestSimilarNewobjects(makeRequest, newbuildingTags[0].id);
  }

  return Promise.resolve(null);
};

export const getSimilarNewobjectsFromDeveloperPromise = (
  makeRequest: IMakeRequest,
  offersData: IOffersData,
): Promise<ISimilarNewobjectsFromDeveloperData[] | null> => {
  const newbuildingTags =
    offersData.jsonQuery.geo &&
    (offersData.jsonQuery.geo.value.filter(geo => geo.type === 'newobject') as IJsonQueryNewbuilding[]);

  if (offersData.offerCount > 0 && offersData.offerCount < 11 && newbuildingTags && newbuildingTags.length > 0) {
    return requestSimilarNewobjectsFromDeveloper(makeRequest, newbuildingTags[0].id);
  }

  return Promise.resolve(null);
};

export interface IBSCentersData {
  id: number;
  name: string;
}

export const requestBSCentersDataByIds = (
  makeRequest: IMakeRequest,
  bsCentersIds: number[],
): Promise<IBSCentersData[] | null> => {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-bscenters-by-ids/',
    parameters: {
      bsCentersIds,
    },
    method: 'POST',
  }).then((res: { data: { bsCenters: IBSCentersData[] } }) => {
    return res.data.bsCenters;
  });
};

export interface IRequestMetaResponse {
  count: number;
  url: string;
  fullUrl: string;
  fullUrlWithoutSeo: string;
  h1: string;
}

export function requestMeta(makeRequest: IMakeRequest, reqBody: IJsonQuery): Promise<IRequestMetaResponse> {
  return makeRequest<RegularResponse<IRequestMetaResponse>>({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/offers/search/meta/',
    parameters: reqBody,
    method: 'POST',
  }).then(returnResponseDataIfOk);
}

export interface IFavoriteChangeResponse {
  status: string;
  count: {
    total: number;
  };
  folderInfo: {
    folder: {
      id?: number;
      name?: string;
    };
    hasFolders: boolean;
  };
  newbuilding_id?: number;
}

export const changeFavorite = (
  makeRequest: IMakeRequest,
  offer: IOffer,
  isFavorite: boolean,
): Promise<IFavoriteChangeResponse> => {
  const helper = offerHelper(offer);

  const dealType = helper.isSale() ? 'sale' : 'rent';

  let offerType = 'flat';
  if (helper.isCommercial()) {
    offerType = 'commercial';
  }

  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: `/ajax/${dealType}/${offerType}/favorites`,
    parameters: {
      action: isFavorite ? 'assign' : 'remove',
      oid: offer.cianId || offer.id,
      addToFolder: isFavorite ? true : undefined,
    },
    headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
    bodyAsEncodeString: true,
    method: 'POST',
  }).then(
    (res: IFavoriteChangeResponse) => {
      return res;
    },
    err => {
      return err;
    },
  );
};

export interface IPoygonGeometry {
  coordinates: [YMaps.TCoord[]];
  type: 'Polygon';
}

export interface ICircleGeometry {
  coordinates: YMaps.TCoord;
  radius: number;
  type: 'Circle';
}

export interface IGeoTagsDataGeoObject<T> {
  options: {
    preset: string;
  };
  source: {
    geometry: T;
    properties: {
      isHighlighted: boolean;
    };
  };
}

export interface INewobjectTag {
  id: string;
  lat: 'lon';
  lng: 'lat';
  text: string;
  type: 'newobject';
}

export interface IPolygonTag {
  geoObject: IGeoTagsDataGeoObject<IPoygonGeometry>;
  text: string;
  type: 'polygon';
}

export interface INewbuildingHouseTag {
  key: string;
  text: string;
  type: 'newbuilding-house';
}

export interface ICircleTag {
  geoObject: IGeoTagsDataGeoObject<ICircleGeometry>;
  text: string;
  type: 'distance';
}

export type TGeoTagsData = 'address_tags' | 'developer_tags' | 'district_tags' | 'newobject_tags' | 'metro_tags';

export interface IAddressesTag {
  addressNodes: [
    {
      fullName: string;
      geoType: 'Country' | 'Location' | 'Street' | 'Road' | 'District' | 'Underground' | 'House';
      id: number;
      name: string;
    },
  ];
  geoObject: IGeoTagsDataGeoObject<IPoygonGeometry>;
  text: string;
  type: 'address';
}

export interface IRailwayTags {
  id: number;
  text: string;
  type: 'railway';
}

export interface IGeoTags {
  address_tags: IAddressesTag[];
  developer_tags: {
    id: string;
    text: string;
    type: 'builder';
  }[];
  district_tags: {
    id: number;
    parentId: number | null;
    text: string;
    type: 'district';
  }[];
  highway_tags: {}[]; //TODO:
  metro_tags: {
    color: string;
    text: string;
    type: 'metro';
    id: number;
  }[];
  nb_house_tags: INewbuildingHouseTag[];
  newobject_tags: INewobjectTag[];
  polygon_tags: IPolygonTag[];
  circle_tags: ICircleTag[];
  railway_tags: IRailwayTags[];
}

export interface IGeoTagsData {
  geoTags: IGeoTags;
  region: {
    id: number;
    fullname: string;
  };
}

export function requestGeoTagsData(
  makeRequest: IMakeRequest,
  reqBody: { jsonQuery: IJsonQuery },
): Promise<IGeoTagsData> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/query-to-geo-tags/',
    parameters: reqBody,
    method: 'POST',
  }).then((res: { data: IGeoTagsData }) => {
    return res.data;
  });
}

export const getGeoTagPromise = (makeRequest: IMakeRequest, jsonQuery: IJsonQuery): Promise<IGeoTagsData | null> => {
  let geoTagsPromise: Promise<IGeoTagsData | null> = Promise.resolve(null);

  if (jsonQuery.geo) {
    geoTagsPromise = requestGeoTagsData(makeRequest, { jsonQuery });
  }

  return geoTagsPromise;
};

export interface ITimeoutRequestUserGADataLayerParams {
  makeRequest: IMakeRequest;
  cookie?: string;
  telemetry?: ITelemetryLogger;
  logger: ILogger;
}

export const timeoutRequestUserGADataLayer = (params: ITimeoutRequestUserGADataLayerParams) => {
  const { makeRequest, cookie, telemetry, logger } = params;

  return requestUserGADataLayerData(makeRequest, cookie).catch(error => {
    if (error instanceof HttpTimeoutError && telemetry) {
      telemetry.increment('user_ga_data_layer_serp_status');
    } else {
      logger.error(error);
    }

    return { isAuthenticated: false };
  });
};

export function requestUserGADataLayerData(makeRequest: IMakeRequest, cookie?: string): Promise<IUserGADataLayerData> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-user-ga-data-layer-data/',
    method: 'GET',
    headers: cookie ? [['Cookie', cookie]] : [],
  }).then((res: { data: IUserGADataLayerData }) => res.data);
}

export interface IRequestCitiesResponse {
  items: ILocation[];
}

export function requestCities(makeRequest: IMakeRequest, regionId: number): Promise<IRequestCitiesResponse> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/get-region-cities/',
    parameters: {
      regionId,
    },
    method: 'GET',
  }).then((res: { data: IRequestCitiesResponse }) => {
    return res.data;
  });
}

export interface ISearchLocationsResponse {
  items: ILocation[];
}

export function searchLocations(makeRequest: IMakeRequest, text: string): Promise<ISearchLocationsResponse> {
  return makeRequest({
    apiType: 'legacy',
    microserviceName: 'monolith-python',
    pathApi: '/cian-api/site/v1/search-regions-cities/',
    parameters: {
      text,
    },
    method: 'GET',
  }).then((res: { data: ISearchLocationsResponse }) => {
    return res.data;
  });
}
