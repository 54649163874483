import { connect } from 'react-redux';

import { IAppState } from '../../../state';

import { EmptyKpSearch, IEmptyKpSearchStoreProps } from './EmptyKpSearch';

function mapStateToProps(state: IAppState): IEmptyKpSearchStoreProps {
  const {
    results: {
      kp,
      queryString,
      jsonQuery: { kp_id, object_type },
    },
  } = state;

  return {
    url: `/cat.php?${queryString}`,
    offersCounter: kp && kp.offers_by_category_counter,
    kpId: (kp_id && kp_id.value) || 0,
    objectType: (object_type && object_type.value) || [],
  };
}

export const EmptyKpSearchContainer = connect<IEmptyKpSearchStoreProps>(mapStateToProps)(EmptyKpSearch);
