import { ModalWindow, Spinner } from '@cian/ui-kit';

import * as React from 'react';

import { ILocation, TLocation } from '../../../types/location';

import { Areas } from './areas';
import { Breadcrumbs } from './breadcrumb';
import { Capitals } from './capitals/';

const style = require('./index.css');

export interface ILocationSwitherProps {
  autocomplete: React.ReactNode;
  cities: ILocation[] | undefined;
  isDataLoading: boolean;
  regions: ILocation[];
  selectedCity: ILocation | undefined;
  selectedRegion: TLocation | undefined;
  queryString: string | undefined;

  applySelectedLocation(): void;
  onCitySelected(city: ILocation): void;
  onClose(): void;
  onCountrySelected(): void;
  onRegionSelected(region: TLocation): void;
}

// Не используется, нужно удалить в будущем
export class LocationSwither extends React.Component<ILocationSwitherProps> {
  public render() {
    const { selectedRegion, selectedCity, isDataLoading } = this.props;

    return (
      <ModalWindow
        open
        footer={
          // Удалил "SearchButtonContainer", в дальнейшем будет удален весь файл, оставил "null" на всякий случай
          <div className={style.footer}>{selectedCity || selectedRegion ? null : this.renderCancelButton()}</div>
        }
        header={
          <div className={style.header}>
            <div className={style.autoComplete}>
              {this.props.autocomplete}
              {this.renderBreadcrumbs()}
            </div>
          </div>
        }
        width="85%"
        onClose={this.props.onClose}
      >
        <div className={style.locationSwitcher}>
          {isDataLoading && (
            <div className={style.preloaderWrapper}>
              <div className={style['preloader']}>
                {/* Заменил Preloader из cian/components на Spinner
                  В дальнейшем будет удален весь файл, оставил на всякий случай */}
                <Spinner size={48} />
              </div>
            </div>
          )}
          <Capitals
            regions={this.props.regions}
            selectedCity={selectedCity}
            selectedRegion={selectedRegion}
            onRegionSelected={this.props.onRegionSelected}
          />
          {selectedCity ? this.renderCities() : selectedRegion ? this.renderCities() : this.renderRegions()}
        </div>
      </ModalWindow>
    );
  }

  private renderBreadcrumbs() {
    const { selectedRegion, selectedCity } = this.props;

    return (
      <Breadcrumbs
        regions={this.props.regions}
        selectedCity={selectedCity}
        selectedRegion={selectedRegion}
        onCitySelected={this.props.onCitySelected}
        onCountrySelected={this.props.onCountrySelected}
        onRegionSelected={this.props.onRegionSelected}
      />
    );
  }

  private renderRegions() {
    return (
      <Areas
        areas={this.props.regions}
        onRegionSelected={this.props.onRegionSelected}
        onSelected={this.props.onRegionSelected}
      />
    );
  }

  private renderCities() {
    return (
      <Areas
        areas={this.props.cities}
        selectedCity={this.props.selectedCity}
        selectedRegion={this.props.selectedRegion}
        onRegionSelected={this.props.onRegionSelected}
        onSelected={this.props.onCitySelected}
      />
    );
  }

  private renderCancelButton() {
    return (
      // Заменил Button из @cian/components на нативный button, в дальнейшем этот компонент будет целиком удален
      <button disabled={true} onClick={this.props.onClose}>
        Выберите регион
      </button>
    );
  }
}
