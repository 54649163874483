import { IAppState } from '../../common/state';

export function selectProfessionalSearchUrl(state: IAppState) {
  const mcsRunEnv = state.config.get<string>('mcsRunEnv') || 'UNKNOWN';

  let url = 'https://my.cian.ru/';

  switch (true) {
    case /PROD/.test(mcsRunEnv):
      break;
    case /BETA-\d+/.test(mcsRunEnv):
      url = `https://${mcsRunEnv.replace('-', '')}.customer.stage.cian.ru/`;
      break;
    case /master/.test(mcsRunEnv):
    case /local/.test(mcsRunEnv):
    case /[Cc][Dd]-\d+/.test(mcsRunEnv):
      url = 'https://master.customer.dev3.cian.ru/';
      break;
    default:
      break;
  }

  return `${url}offers`;
}
