import { Spinner } from '@cian/ui-kit';

import * as React from 'react';

import * as styles from './WidgetFallback.css';

export const WidgetFallback: React.FC = () => {
  return (
    <div className={styles['container']}>
      <Spinner color="primary_100" size={24} />
    </div>
  );
};
