import { IAppState, TActions } from '../../../common/state';
import { EBuildingElevatorType, jq } from '../../../json_query';

import { getParamChangedAction } from './fabrics/multiselect_param_reducer';

type TBuildingElevatorTypeChangedActionType = 'filters/building_elevator/BUILDING_ELEVATOR_TYPE_CHANGED';
export const IBuildingElevatorTypeChangedActionType =
  'filters/building_elevator/BUILDING_ELEVATOR_TYPE_CHANGED' as TBuildingElevatorTypeChangedActionType;

export interface IBuildingElevatorTypeChangedAction {
  type: 'filters/building_elevator/BUILDING_ELEVATOR_TYPE_CHANGED';
  paramType: EBuildingElevatorType;
  value: boolean;
}

export const changeBuildingElevator = getParamChangedAction<
  EBuildingElevatorType,
  TBuildingElevatorTypeChangedActionType
>(IBuildingElevatorTypeChangedActionType);

export function buildingElevatorTypeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IBuildingElevatorTypeChangedActionType:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: action.value
            ? jq(state.filters.jsonQuery).setBuildingElevatorType(action.paramType)
            : jq(state.filters.jsonQuery).unsetBuildingElevatorType(action.paramType),
        },
      };

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-buildingElevator') {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jq(state.filters.jsonQuery).unsetBuildingElevatorType(action.tag.value),
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetBuildingElevatorTypes(),
        },
      };

    default:
      return state;
  }
}
