import { IHttpApi } from '@cian/http-api';

import { createGetApplyNewBetDateModel } from './helpers';
import { IGetApplyNewBetDateErrorResponseSchema, IGetApplyNewBetDateSuccessResponseSchema } from './types';

export const getApplyNewBetDate = (httpApi: IHttpApi, announcementId: number) => {
  return httpApi
    .fetch(createGetApplyNewBetDateModel({ parameters: { announcementId } }))
    .then(({ response, statusCode }) => {
      if (statusCode === 400) {
        throw response as IGetApplyNewBetDateErrorResponseSchema;
      }

      return response as IGetApplyNewBetDateSuccessResponseSchema;
    });
};
