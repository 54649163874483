export const MOSCOW_ID = 1;
export const MOSCOW_REGION_ID = 4593;
export const MOSCOW_AND_MOSCOW_AREA_ID = -1;
export const MOSCOW_AND_REGION = [MOSCOW_ID, MOSCOW_REGION_ID, MOSCOW_AND_MOSCOW_AREA_ID];
export const REGIONS_WITH_UNDERGROUND = [MOSCOW_ID, MOSCOW_REGION_ID, 2, 4743, 4777, 4885, 4897, 4966];
export const LO_REGION_ID = 4588;
export const PETERSBURG_ID = 2;
export const PETERSBURG_AND_REGION_ID = -2;
export const PETERSBURG_AND_REGION = [PETERSBURG_ID, LO_REGION_ID, PETERSBURG_AND_REGION_ID];
export const MAIN_REGIONS_IDS = [
  MOSCOW_ID,
  MOSCOW_REGION_ID,
  MOSCOW_AND_MOSCOW_AREA_ID,
  LO_REGION_ID,
  PETERSBURG_ID,
  PETERSBURG_AND_REGION_ID,
];
