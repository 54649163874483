import { connect } from 'react-redux';

import { Dispatch, IAppState } from 'shared/common/state';

import { IChangePrintOptions, changePrintOptions } from '../../../state/print';

import { PrintPopup } from './index';

export const PrintPopupContainer = connect<
  {
    showComments: boolean;
    showPhones: boolean;
  },
  {
    changePrintOptions(options: IChangePrintOptions): void;
  },
  {
    close(): void;
    open: boolean;
  }
>(
  (state: IAppState) => {
    const { showComments, showPhones } = state.print;

    return {
      showComments,
      showPhones,
    };
  },
  (dispatch: Dispatch) => ({
    changePrintOptions: (options: IChangePrintOptions) => {
      dispatch(changePrintOptions(options));
    },
  }),
)(PrintPopup);
