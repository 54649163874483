import { useCallback } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SaveSearchModalContainer } from '../../filters/containers/SaveSearchModalContainer';
import {
  getisCommercialSaveSearchModalOpened,
  setCommercialSaveSearchModalClosed,
} from '../../serp/state/commercialSaveSearchModal';

export const CommercialSaveSearchModalContainer: React.VFC = () => {
  const isOpened = useSelector(getisCommercialSaveSearchModalOpened);

  const dispatch = useDispatch();
  const handleClose = useCallback(() => {
    dispatch(setCommercialSaveSearchModalClosed());
  }, [dispatch]);

  return <SaveSearchModalContainer open={isOpened} onClose={handleClose} />;
};
