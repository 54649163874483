import { IStyleProps, mergeStyles } from '@cian/utils';

import * as PropTypes from 'prop-types';
import React, { Component } from 'react';

import { IOfferHelper } from '../../presenter';
import { IOffer } from '../../types/offer';

import * as styles from './ShowMoreOffers.css';

interface IShowMoreOffersContext {
  offer: IOffer;
  offerHelper: IOfferHelper;
}

interface IShowMoreOffersProps {
  containerStyle?: IStyleProps | string;
}

export class ShowMoreOffers extends Component<React.PropsWithChildren<IShowMoreOffersProps>> {
  public context: IShowMoreOffersContext;

  public static contextTypes = {
    offer: PropTypes.object,
    offerHelper: PropTypes.object,
  };

  public render() {
    return (
      // eslint-disable-next-line react/jsx-no-target-blank
      <a
        href={this.getShowMoreOffersLink()}
        target="_blank"
        {...mergeStyles(styles['other_offers'], this.props.containerStyle)}
      >
        {this.props.children ? this.props.children : 'Ещё объекты'}
      </a>
    );
  }

  private getShowMoreOffersLink = () => {
    const { offer, offerHelper } = this.context;
    const user = offer.user;
    const masterAgent = user ? user.masterAgent : null;
    const userIdForLink = masterAgent ? masterAgent.id : offerHelper.presenters.getCianUserId();
    const userIdQuery = userIdForLink ? `id_user=${userIdForLink}&` : '';

    return `/cat.php?${userIdQuery}deal_type=${offer.dealType}&offer_type=office&engine_version=2`;
  };
}
