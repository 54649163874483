import { IAdFoxBannerContext } from '@cian/adfox/build/react';
import { mergeStyles } from '@cian/utils/lib/shared/style';

import * as React from 'react';

const style = require('./index.css');

export interface IMortgageProps {
  context: IAdFoxBannerContext;
  isCommercial?: boolean;
}

export const MortgageBanner: React.FC<IMortgageProps> = ({ context, isCommercial }) => {
  return (
    <a
      href={context.link}
      target="_blank"
      {...mergeStyles([style['link'], !isCommercial && style['link-simplified']])}
      rel="nofollow noopener noreferrer"
    >
      {context.name}
      <span className={style['title']}>Рассчитать ипотеку</span>
    </a>
  );
};
