import { IQuestion, IStep } from '@cian/frontend-request-form-widget';

export const REGISTRATION_STEPS: IStep<IQuestion> = {
  code: 'registration',
  question: {
    code: 'registrationStep',
    title: 'Регистрация',
    fields: [],
    actions: [],
  },
  nextStep: [],
};
