export class CustomEventCreator {
  private eventTarget: EventTarget = window;
  private eventName: keyof WindowEventMap;
  private customEventName: string;
  private listener: () => void;

  private running = false;

  public constructor(eventName: keyof WindowEventMap, listener: () => void, eventTarget?: EventTarget) {
    if (eventTarget) {
      this.eventTarget = eventTarget;
    }

    this.eventName = eventName;
    this.customEventName = 'optimized' + this.eventName;
    this.listener = listener;
  }

  public subscribe() {
    this.throttleEvent();
    this.eventTarget.addEventListener(this.customEventName, this.listener);
  }

  public unsubscribe() {
    this.eventTarget.removeEventListener(this.customEventName, this.listener);
  }

  private throttleEvent = () => {
    this.eventTarget.addEventListener(this.eventName, this.eventHandler);
  };

  private eventHandler = () => {
    if (this.running) {
      return;
    }
    this.running = true;

    requestAnimationFrame(() => {
      // IE 10 compatibility
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const eventTarget = this.eventTarget as any;

      if (eventTarget.dispatchEvent) {
        eventTarget.dispatchEvent(new CustomEvent(this.customEventName));
      } else if (eventTarget.fireEvent) {
        eventTarget.fireEvent(new CustomEvent(this.customEventName));
      }

      this.running = false;
    });
  };
}
