interface IGetMaskParameters {
  opacityTop: number;
  opacityBottom: number;
  size: number;
}

export const getMask = ({ size, opacityTop, opacityBottom }: IGetMaskParameters) =>
  `linear-gradient(180deg, rgba(255, 255, 255, ${opacityTop}), black) center top / 100% ${size}px no-repeat,
  linear-gradient(180deg, black, black) center ${size}px / 100% calc(100% - ${size * 2}px) no-repeat,
  linear-gradient(180deg, black, rgba(255, 255, 255, ${opacityBottom})) center bottom / 100% ${size}px no-repeat`;
