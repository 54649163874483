import { hydrateRoot } from 'react-dom/client';

import { IAppStore } from 'shared/common/state';
import { Application } from 'shared/containers/App';
import { IApplicationContext } from 'shared/types/applicationContext';

interface IDependencies {
  store: IAppStore;
  context: IApplicationContext;
}

export function renderApplication(dependencies: IDependencies) {
  const { store, context } = dependencies;
  const { config } = context;

  const projectName = config.getStrict<string>('projectName');
  const rootElement = document.getElementById(projectName);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  hydrateRoot(rootElement, <Application context={context} reduxStore={store} />);
}
