/**
 * @todo Удалить директорию
 * @description Данный функционал появился в задаче CD-162829, будет удалён в задаче CD-162909
 */
import { Button, UIHeading2, UIText1 } from '@cian/ui-kit';

import * as React from 'react';

import * as styles from './BestPlaceBanner.css';

interface IBestPlaceBannerBannerProps {
  onClick: VoidFunction;
}

export const BestPlaceBanner: React.VFC<IBestPlaceBannerBannerProps> = props => {
  return (
    <div className={styles['container']} onClick={props.onClick}>
      <div className={styles['content']}>
        <div className={styles['heading-wrapper']}>
          <UIHeading2>Проверьте, подходит ли место для вашего бизнеса</UIHeading2>
        </div>
        <UIText1>Получите аналитический отчёт по торговым площадям и помещениям свободного назначения</UIText1>
        <div className={styles['button']}>
          <Button size="XS" theme="fill_primary">
            Хочу проверить
          </Button>
        </div>
      </div>
      <div className={styles['image-container']} />
    </div>
  );
};
