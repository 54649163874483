import { EBuildingType } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IBuildingTypeBuildingTagType = 'advanced-buildingTypeBuilding';

export interface IBuildingTypeBuildingTag {
  type: 'advanced-buildingTypeBuilding';
  value: EBuildingType;
}

const isBuildingTypeBuildingTag = (tag: TTag): tag is IBuildingTypeBuildingTag =>
  tag.type === IBuildingTypeBuildingTagType;

export const buildingTypeBuildingTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.building_type__building && jsonQuery.building_type__building.value;

    let nextTags = tags.filter(
      tag => tag.type !== IBuildingTypeBuildingTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: EBuildingType) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IBuildingTypeBuildingTag => isBuildingTypeBuildingTag(tag) && tag.value === value,
            () => ({ type: IBuildingTypeBuildingTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
