import { EHideOfferState } from '../serp/state/hide_offer';

export const parseHash = (hash: string): { [key: string]: string } => {
  const result: { [key: string]: string } = {};
  if (hash.includes('HIDE')) {
    if (hash.includes('HIDE_SUCCES')) {
      result['HIDE'] = EHideOfferState.SUCCESS;
    } else if (hash.includes('HIDE_ERROR')) {
      result['HIDE'] = EHideOfferState.ERROR;
    }
  }

  return result;
};
