import { IAppState, TActions } from '../../../common/state';
import { IWordsExcludeTag } from '../tags/definitions/words_exclude';

export const IWordExcludeAddedActionType = 'filters/words_exclude/WORD_EXCLUDE_ADDED';
export const IWordExcludeRemovedActionType = 'filters/words_exclude/WORD_EXCLUDE_REMOVED';

export interface IWordExcludeAddedAction {
  type: 'filters/words_exclude/WORD_EXCLUDE_ADDED';
  tag: string;
}

export interface IWordExcludeRemovedAction {
  type: 'filters/words_exclude/WORD_EXCLUDE_REMOVED';
  tag: string;
}

export function wordExcludeAdded(tag: string): IWordExcludeAddedAction {
  return {
    type: IWordExcludeAddedActionType,
    tag,
  };
}

export function wordExcludeRemoved(tag: string): IWordExcludeRemovedAction {
  return {
    type: IWordExcludeRemovedActionType,
    tag,
  };
}

export function wordsExcludeReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case IWordExcludeAddedActionType: {
      const words =
        (state.filters.jsonQuery.description_exclude && state.filters.jsonQuery.description_exclude.value) || [];

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            description_exclude: {
              type: 'terms',
              value: words.concat(action.tag),
            },
          },
        },
      };
    }

    case IWordExcludeRemovedActionType: {
      let prevWords =
        (state.filters.jsonQuery.description_exclude && state.filters.jsonQuery.description_exclude.value) || [];

      prevWords = prevWords.filter(word => word !== action.tag);

      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            description_exclude: prevWords.length
              ? {
                  type: 'terms',
                  value: prevWords,
                }
              : undefined,
          },
        },
      };
    }

    case 'filters/tags/TAG_REMOVED':
      if (action.tag.type === 'advanced-wordsExclude') {
        let oldWords =
          (state.filters.jsonQuery.description_exclude && state.filters.jsonQuery.description_exclude.value) || [];

        oldWords = oldWords.filter(word => word !== (action.tag as IWordsExcludeTag).value);

        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: {
              ...state.filters.jsonQuery,
              description_exclude: oldWords.length
                ? {
                    type: 'terms',
                    value: oldWords,
                  }
                : undefined,
            },
          },
        };
      }

      return state;

    case 'filters/tags/ALL_TAGS_REMOVED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: {
            ...state.filters.jsonQuery,
            description_exclude: undefined,
          },
        },
      };

    default:
      return state;
  }
}
