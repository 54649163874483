import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';
import { locationHasNeighbors } from '../../utils/neighbors';

export interface IWithNeighborsChangedAction {
  type: 'filters/with_neighbors/WITH_NEIGHBORS_CHANGED';
  value: boolean;
}

export function getValue(value: boolean) {
  return value ? undefined : value;
}

export function changeWithNeighbors(value: boolean): IWithNeighborsChangedAction {
  return {
    type: 'filters/with_neighbors/WITH_NEIGHBORS_CHANGED',
    value,
  };
}

export function withNeighborsReducer(state: IAppState, action: TActions): IAppState {
  const jsonQuery = jq(state.filters.jsonQuery);

  switch (action.type) {
    case 'filters/with_neighbors/WITH_NEIGHBORS_CHANGED':
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jsonQuery.setWithNeighbors(getValue(action.value)),
        },
      };

    // Костыль для попапа смены региона, иначе мета запрашивается с тегом всегда
    case 'filters/location_switcher/REGION_SELECTED':
      if (!locationHasNeighbors(action.region)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.setWithNeighbors(undefined),
          },
        };
      }

      return state;

    // Костыль для попапа смены региона, иначе мета запрашивается с тегом всегда
    case 'filters/location_switcher/CITY_SELECTED':
      if (!locationHasNeighbors(action.city)) {
        return {
          ...state,
          filters: {
            ...state.filters,
            jsonQuery: jsonQuery.setWithNeighbors(undefined),
          },
        };
      }

      return state;

    default:
      return state;
  }
}
