import { Dispatch, IAppState } from 'shared/common/state';
import { IDeleteOfferFromComparisonRequest } from 'shared/repositories/offers-comparison/v1/delete-offer-from-comparison';

import { deleteOfferFromComparisonRequest } from '../../../../services/offersComparison';
import { EComparisonNotificationCategory, SetComparisonNotificationType } from '../comparisonNotification';
import { DeleteFromComparisonActionType } from '../offersComparison';

export const deleteOfferFromComparison =
  (parameters: IDeleteOfferFromComparisonRequest) => async (dispatch: Dispatch, getState: () => IAppState) => {
    const { httpApi } = getState();
    const { offerId } = parameters;
    const response = await deleteOfferFromComparisonRequest({ httpApi, parameters: { offerId } });

    if (response) {
      const { text, comparisonUrl, offerId } = response;

      dispatch({
        type: DeleteFromComparisonActionType,
        offerId,
      });

      dispatch({
        type: SetComparisonNotificationType,
        text,
        url: comparisonUrl,
        category: EComparisonNotificationCategory.Remove,
        offerId,
      });
    }
  };
