import { RefObject } from 'react';

import { RemoveAllComponentCopiesService } from './RemoveAllComponentCopiesService';

type OnScrollCallback = (event: Event) => void;
type OnScrollCallbackCollection = OnScrollCallback[];

export class CoworkingProfitLabelService extends RemoveAllComponentCopiesService {
  private static readonly COWORKING_PROFIT_BANNER_AUTO_SHOWN = 'CoworkingProfitBannerAutoShown';

  public static get isCoworkingProfitWasShown(): boolean {
    const coworkingProfitBannerAutoShown =
      sessionStorage.getItem(CoworkingProfitLabelService.COWORKING_PROFIT_BANNER_AUTO_SHOWN) ?? 'false';

    return JSON.parse(coworkingProfitBannerAutoShown);
  }

  public static isElementInViewport(el: RefObject<HTMLElement>): boolean | null {
    if (el.current) {
      const rect = el.current.getBoundingClientRect();
      /**
       * @description Не требуется тестирования ветвления, так как данные состоят только из сайд эффектов
       */
      /* istanbul ignore next */
      const height = window.innerHeight || document.documentElement.clientHeight;
      /**
       * @description Не требуется тестирования ветвления, так как данные состоят только из сайд эффектов
       */
      /* istanbul ignore next */
      const width = window.innerWidth || document.documentElement.clientWidth;

      return rect.top >= 0 && rect.left >= 0 && rect.bottom <= height && rect.right <= width;
    }

    return null;
  }

  private static setCoworkingProfitWasShown(): void {
    if (!CoworkingProfitLabelService.isCoworkingProfitWasShown) {
      sessionStorage.setItem(CoworkingProfitLabelService.COWORKING_PROFIT_BANNER_AUTO_SHOWN, 'true');
    }
  }

  private readonly listeners = new Map<OnScrollCallback, OnScrollCallback>();

  public addListener(...listeners: OnScrollCallbackCollection): void {
    listeners.forEach(listener => {
      window.addEventListener('scroll', listener);

      this.listeners.set(listener, listener);
    });
  }

  public removeListener(...listeners: OnScrollCallbackCollection): void {
    listeners.forEach(listener => {
      window.removeEventListener('scroll', listener);

      this.listeners.delete(listener);
    });
  }

  public removeAllListeners(): void {
    Array.from(this.listeners).forEach(mapListener => {
      window.removeEventListener('scroll', mapListener[1]);
    });

    this.listeners.clear();
  }

  public coworkingProfitBannerWasShown(): void {
    CoworkingProfitLabelService.setCoworkingProfitWasShown();
    this.executeEachOtherInCallbackCollection();

    this.removeAllListeners();
  }

  public hasListener(listener: OnScrollCallback) {
    return this.listeners.has(listener);
  }
}
