import * as React from 'react';

import * as styles from './AreaLink.css';

export interface IAreaLinkProps {
  href: string;
  handleClick(): void;
}

export const AreaLink: React.FC<React.PropsWithChildren<IAreaLinkProps>> = props => {
  const { href, handleClick, children } = props;

  return (
    <a className={styles['area-link']} href={href} rel="noreferrer" target="_blank" onClick={handleClick}>
      {children}
    </a>
  );
};
