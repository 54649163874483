import { VFC } from 'react';

import { IMarkedListItem } from 'shared/repositories/search-offers.legacy/v2/search-offers-desktop.data';

import { SeoMarkedListItem } from './components/SeoMarkedListItem';

import * as styles from './SeoMarkedList.css';

interface ISeoMarkedListProps {
  items: IMarkedListItem[];
}

export const SeoMarkedList: VFC<ISeoMarkedListProps> = ({ items }) => (
  <div className={styles['container']}>
    {items.map((item, index) => {
      const id = `SeoMarkedListItem_${index}`;

      return <SeoMarkedListItem id={id} key={id} {...item} />;
    })}
  </div>
);
