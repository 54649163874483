import { CheckboxButtonGroup, ICheckboxGroupProps, Paragraph2 } from '@cian/ui-kit';

import * as React from 'react';

import * as styles from './styles.css';

export interface ICustomCheckboxButtonGroup extends ICheckboxGroupProps {
  label?: string;
}

export const CustomCheckboxButtonGroup: React.FC<ICustomCheckboxButtonGroup> = ({ label, ...props }) => (
  <div className={styles['root']}>
    {label && (
      <div className={styles['label']}>
        <Paragraph2>{label}</Paragraph2>
      </div>
    )}
    <CheckboxButtonGroup {...props} />
  </div>
);

CustomCheckboxButtonGroup.displayName = 'CustomCheckboxButtonGroup';
