import { ERepair } from 'shared/json_query';

import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IRepairTagType = 'repair';

export interface IRepairTag {
  type: 'repair';
  value: ERepair;
}

function isRepairTag(tag: TTag): tag is IRepairTag {
  return tag.type === IRepairTagType;
}

export const repairTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery) => {
    const values = jsonQuery.repair && jsonQuery.repair.value;

    let nextTags = tags.filter(
      tag => tag.type !== IRepairTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value: ERepair) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IRepairTag => isRepairTag(tag) && tag.value === value,
            () => ({ type: IRepairTagType, value }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
