import { mergeStyles } from '@cian/utils';

import * as React from 'react';

import { formatNumberToPrettyString } from 'shared/utils/formatNumberToPrettyString';

import { getMlTopVasName } from '../../../utils/name';

import * as styles from './VasLabel.css';

export interface IVasLabelProps {
  /** Отсортированный список мнемоник VAS'ов объявления */
  vasList: string[];
  /** Переопределенный лейбл */
  label?: string;
  /** Наведен ли курсор на компонент VAS (флаг рендеринга состояния наведения) */
  isHovered?: boolean;
  /** Ставка в аукционе */
  auctionBet?: number;
  /** Стиль для лейбла */
  labelStyle?: string;
}

/**
 * Компонент для отображения примененных VAS в виде иконок примененных VAS и названия приоритетного VAS
 */
export class VasLabel extends React.Component<IVasLabelProps, object> {
  public render() {
    const { label, vasList, isHovered, auctionBet, labelStyle } = this.props;

    const auctionBetText = !!auctionBet && ` + ${formatNumberToPrettyString(auctionBet)} ₽`;
    const computedLabel = getMlTopVasName(vasList);

    return (
      <div className={styles['container']}>
        <div {...mergeStyles(styles['name'], isHovered && styles['name--hovered'], labelStyle)}>
          {label || computedLabel}
          {auctionBetText}
        </div>
      </div>
    );
  }
}
