import { IJsonQueryHighway } from 'shared/json_query';

import { ensureSingleTagExists } from '../../helpers';
import { ITagDefinition, TTag } from '../../index';

import { normalizeHighwaysData } from './highway_helper';

export interface IGeoHighwayTag {
  type: 'geo-highway';
  value: number;
  text: string;
}

export function isGeoHighwayTag(tag: TTag): tag is IGeoHighwayTag {
  return tag.type === 'geo-highway';
}

export const geoHighwayTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values =
      jsonQuery.geo &&
      normalizeHighwaysData(
        (jsonQuery.geo.value.filter(geo => geo.type === 'highway') as IJsonQueryHighway[]).map(geo => geo.id),
        tagsData.directions,
      );

    let nextTags = tags.filter(
      tag => tag.type !== 'geo-highway' || (values && values.some(value => value.id === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce(
        (result, value) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IGeoHighwayTag => isGeoHighwayTag(tag) && tag.value === value.id,
            () => ({
              type: 'geo-highway',
              value: value.id,
              text: value.text,
            }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
