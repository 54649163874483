import { ITagDefinition, TTag } from '..';
import { ensureSingleTagExists } from '../helpers';

export const COWORKING_ID_TAG_TYPE = 'coworking_id';

export interface ICoworkingIdTag {
  type: typeof COWORKING_ID_TAG_TYPE;
  value: number;
  text: string;
}

export const isCoworkingIDTag = (tag: TTag): tag is ICoworkingIdTag => tag.type === COWORKING_ID_TAG_TYPE;

export const coworkingIDTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const values = jsonQuery.coworking_id && jsonQuery.coworking_id.value;

    let nextTags = tags.filter(
      tag => tag.type !== COWORKING_ID_TAG_TYPE || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined) {
      nextTags = values.reduce((result, value) => {
        const tagData = tagsData.coworkings[value];

        return ensureSingleTagExists(
          result,
          (tag: TTag): tag is ICoworkingIdTag => isCoworkingIDTag(tag) && tag.value === value,
          () => ({
            type: COWORKING_ID_TAG_TYPE,
            value,
            text: tagData || 'Неизвестный коворкинг',
          }),
        );
      }, nextTags);
    }

    return nextTags;
  },
};
