import { accessSystemReducer } from '../../filters/state/advanced/access_system';
import { areaReducer } from '../../filters/state/advanced/area';
import { bboxReducer } from '../../filters/state/advanced/bbox';
import { bedroomsReducer } from '../../filters/state/advanced/bedrooms_min_count';
import { buildingClassTypeReducer } from '../../filters/state/advanced/building_class';
import { warehouseBuildingClassTypeReducer } from '../../filters/state/advanced/building_class_warehouse';
import { buildingElevatorTypeReducer } from '../../filters/state/advanced/building_elevator';
import { buildingGatesTypeReducer } from '../../filters/state/advanced/building_gates';
import { buildingHeatingTypeReducer } from '../../filters/state/advanced/building_heating';
import { ceilingHeightReducer } from '../../filters/state/advanced/ceiling_height';
import { coworkingAccessReducer } from '../../filters/state/advanced/coworking_access';
import { decorationTypeReducer } from '../../filters/state/advanced/decoration';
import { doneYearReducer } from '../../filters/state/advanced/done_year';
import { dwellingStatusReducer } from '../../filters/state/advanced/dwelling_status';
import { elevatorsReducer } from '../../filters/state/advanced/elevators';
import { entranceTypeReducer } from '../../filters/state/advanced/entrance';
import { facilitiesReducer } from '../../filters/state/advanced/facilities';
import { floorReducer } from '../../filters/state/advanced/floor';
import { floorsTotalReducer } from '../../filters/state/advanced/floors_total';
import { fromMKADReducer } from '../../filters/state/advanced/from_mkad';
import { garageKindReducer } from '../../filters/state/advanced/garage_kind';
import { garageMaterialReducer } from '../../filters/state/advanced/garage_material';
import { garageStatusReducer } from '../../filters/state/advanced/garage_status';
import { garageTypeReducer } from '../../filters/state/advanced/garage_type';
import { heatingReducer } from '../../filters/state/advanced/heating';
import { houseMaterialReducer } from '../../filters/state/advanced/house_material';
import { houseYearReducer } from '../../filters/state/advanced/house_year';
import { infrastructureReducer } from '../../filters/state/advanced/infrastructure';
import { landStatusReducer } from '../../filters/state/advanced/land_status';
import { layoutTypeReducer } from '../../filters/state/advanced/layout';
import { manufactureConditionTypeReducer } from '../../filters/state/advanced/manufacture_condition';
import { offerIdReducer } from '../../filters/state/advanced/offer_id';
import { officeConditionTypeReducer } from '../../filters/state/advanced/office_condition';
import { parkingReducer } from '../../filters/state/advanced/parking';
import { phoneReducer } from '../../filters/state/advanced/phone';
import { propertyTypeReducer } from '../../filters/state/advanced/property_type';
import { publishPeriodReducer } from '../../filters/state/advanced/publish_period';
import { rentTermsReducer } from '../../filters/state/advanced/rent_terms';
import { rentTimeReducer } from '../../filters/state/advanced/rent_time';
import { repairReducer } from '../../filters/state/advanced/repair';
import { roomsInDealReducer } from '../../filters/state/advanced/rooms_in_deal';
import { roomsTotalReducer } from '../../filters/state/advanced/rooms_total';
import { saleTypeReducer } from '../../filters/state/advanced/sale_type';
import { commercialSpecialtyTypesReducer } from '../../filters/state/advanced/specialty_types';
import { suburbanUtilitiesReducer } from '../../filters/state/advanced/suburban_utilities';
import { suburbanWCReducer } from '../../filters/state/advanced/suburban_wc';
import { tenantsReducer } from '../../filters/state/advanced/tenants';
import { advancedTermReducer } from '../../filters/state/advanced/term';
import { advancedTermsReducer } from '../../filters/state/advanced/terms';
import { toggleAdvancedModalReducer } from '../../filters/state/advanced/toggle_modal';
import { wcReducer } from '../../filters/state/advanced/wc';
import { windowsTypeReducer } from '../../filters/state/advanced/windows';
import { wordsExcludeReducer } from '../../filters/state/advanced/words_exclude';
import { wordsIncludeReducer } from '../../filters/state/advanced/words_include';
import { amenityReducer } from '../../filters/state/amenity';
import { bsCenterReducer } from '../../filters/state/bs_center';
import { coworkingIdReducer } from '../../filters/state/coworking_id';
import { coworkingOfferTypeReducer } from '../../filters/state/coworking_offer_type';
import { currencyTypeReducer } from '../../filters/state/currency_type';
import { directionsModalReducer } from '../../filters/state/directions';
import { toggleDistrictsModalReducer } from '../../filters/state/districts/popup';
import { saveDistrictsModalReducer } from '../../filters/state/districts/popup_save';
import { domrfReducer } from '../../filters/state/domrf';
import { favoriteReducer } from '../../filters/state/favorite';
import { geoSuggestReducer } from '../../filters/state/geo';
import { hasFlatTourBookingReducer } from '../../filters/state/has_flat_tour_booking';
import { hasProfitbaseBookingReducer } from '../../filters/state/has_profitbase_booking';
import { hiddenBaseSearchReducer } from '../../filters/state/hidden_base_search';
import { identicalOffersIdReducer } from '../../filters/state/identical_offers_id';
import { isByCommercialOwnerReducer } from '../../filters/state/is_by_commercial_owner';
import { changeJsonQueryReducer } from '../../filters/state/json_query';
import { jsonQueryPriceTypeReducer } from '../../filters/state/json_query_price_type';
import { kpIdReducer } from '../../filters/state/kp_id';
import {
  locationSwitcherAutocompleteReducer,
  locationSwitcherPopupReducer,
  locationSwitcherReducer,
} from '../../filters/state/location_switcher';
import { deleteAreaReducer } from '../../filters/state/map/area_delete';
import { changeMapReducer } from '../../filters/state/map/map_changed';
import { saveMapModalReducer } from '../../filters/state/map/save_modal';
import { toggleMapModalReducer } from '../../filters/state/map/toggle_modal';
import { metaReducer } from '../../filters/state/meta';
import { saveMetroModalReducer } from '../../filters/state/metro/save_modal';
import { toggleMetroModalReducer } from '../../filters/state/metro/toggle_modal';
import { multiIdReducer } from '../../filters/state/multi_id';
import { newbuildingHouseReducer } from '../../filters/state/newbuilding_house';
import { offerTypeReducer } from '../../filters/state/offer_type';
import { paginationReducer } from '../../filters/state/pagination';
import { priceReducer } from '../../filters/state/price';
import { priceTypeReducer } from '../../filters/state/price_type';
import { readyBusinessReducer } from '../../filters/state/ready_business_types';
import { roomReducer } from '../../filters/state/room';
import { searchReducer } from '../../filters/state/search';
import { sortReducer } from '../../filters/state/sort';
import { suburbanOfferFilterReducer } from '../../filters/state/suburban_offer_filter';
import { tagsReducer } from '../../filters/state/tags';
import { fitTagsInOneLineReducer } from '../../filters/state/tags/fit_tags_in_one_line';
import { tour3dReducer } from '../../filters/state/tour_3d';
import { undergroundTravelReducer } from '../../filters/state/underground_travel';
import { userIdReducer } from '../../filters/state/user_id';
import { withLayoutReducer } from '../../filters/state/with_layout';
import { withNeighborsReducer } from '../../filters/state/with_neighbors';
import { withPhotoReducer } from '../../filters/state/with_photo';
import { workPlaceCountReducer } from '../../filters/state/workplace_count';
import { favoritesAssignReducer } from '../../reducers/favoritesAssign';
import { mortgageDataReducer } from '../../reducers/mortgageData';
import { newbuildingInformationReducer } from '../../reducers/newbuildingInformation/reducer';
import { commercialSaveSearchModalReducer } from '../../serp/state/commercialSaveSearchModal';
import { hideOfferReducer } from '../../serp/state/hide_offer';
import { iapRequestPopupReducer } from '../../serp/state/iapRequestPopup';
import { showMotivationPopupReducer } from '../../serp/state/login_motivation_popup';
import { newbuildingInfoReducer } from '../../serp/state/newbuilding_info';
import { agentRatingReducer } from '../../serp/state/offer_card/agent';
import { offerCommentsReducer } from '../../serp/state/offer_card/comments';
import { offerComplaintsReducer } from '../../serp/state/offer_card/complaints';
import { complaintFeedbackReducer } from '../../serp/state/offer_card/feedback_complaint';
import { simplifiedOfferCardPopupsStateReducer } from '../../serp/state/offer_card/simplified_card_popups';
import {
  comparisonNotificationReducer,
  offerComparisonStatusReducer,
} from '../../serp/state/offersComparison/reducers';
import { toggleOfficeCalculatorReducer } from '../../serp/state/office_calculator';
import { offersPrintReducer } from '../../serp/state/print';
import { pushSubscribedReducer } from '../../serp/state/push_subscribed';
import { suggestionsDistancesReducer } from '../../serp/state/suggestions';
import { toggleUserProfilingModalReducer } from '../../serp/state/user_profiling/toggle_modal';

import { IAppStore } from './store';

export function attachReducers(store: IAppStore) {
  store.addReducer(accessSystemReducer);
  store.addReducer(advancedTermReducer);
  store.addReducer(advancedTermsReducer);
  store.addReducer(agentRatingReducer);
  store.addReducer(areaReducer);
  store.addReducer(bboxReducer);
  store.addReducer(bedroomsReducer);
  store.addReducer(newbuildingHouseReducer);
  store.addReducer(bsCenterReducer);
  store.addReducer(buildingClassTypeReducer);
  store.addReducer(buildingElevatorTypeReducer);
  store.addReducer(buildingGatesTypeReducer);
  store.addReducer(buildingHeatingTypeReducer);
  store.addReducer(ceilingHeightReducer);
  store.addReducer(changeMapReducer);
  store.addReducer(commercialSpecialtyTypesReducer);
  store.addReducer(currencyTypeReducer);
  store.addReducer(decorationTypeReducer);
  store.addReducer(deleteAreaReducer);
  store.addReducer(directionsModalReducer);
  store.addReducer(domrfReducer);
  store.addReducer(doneYearReducer);
  store.addReducer(dwellingStatusReducer);
  store.addReducer(elevatorsReducer);
  store.addReducer(entranceTypeReducer);
  store.addReducer(facilitiesReducer);
  store.addReducer(favoriteReducer);
  store.addReducer(fitTagsInOneLineReducer);
  store.addReducer(floorReducer);
  store.addReducer(floorsTotalReducer);
  store.addReducer(fromMKADReducer);
  store.addReducer(garageKindReducer);
  store.addReducer(garageMaterialReducer);
  store.addReducer(garageStatusReducer);
  store.addReducer(garageTypeReducer);
  store.addReducer(geoSuggestReducer);
  store.addReducer(hasFlatTourBookingReducer);
  store.addReducer(hasProfitbaseBookingReducer);
  store.addReducer(heatingReducer);
  store.addReducer(hiddenBaseSearchReducer);
  store.addReducer(houseMaterialReducer);
  store.addReducer(houseYearReducer);
  store.addReducer(infrastructureReducer);
  store.addReducer(suburbanOfferFilterReducer);
  store.addReducer(kpIdReducer);
  store.addReducer(landStatusReducer);
  store.addReducer(layoutTypeReducer);
  store.addReducer(locationSwitcherAutocompleteReducer);
  store.addReducer(locationSwitcherPopupReducer);
  store.addReducer(locationSwitcherReducer);
  store.addReducer(manufactureConditionTypeReducer);
  store.addReducer(metaReducer);
  store.addReducer(multiIdReducer);
  store.addReducer(newbuildingInfoReducer);
  store.addReducer(offerCommentsReducer);
  store.addReducer(offerComplaintsReducer);
  store.addReducer(offerIdReducer);
  store.addReducer(offerTypeReducer);
  store.addReducer(offersPrintReducer);
  store.addReducer(officeConditionTypeReducer);
  store.addReducer(paginationReducer);
  store.addReducer(parkingReducer);
  store.addReducer(phoneReducer);
  store.addReducer(priceReducer);
  store.addReducer(priceTypeReducer);
  store.addReducer(jsonQueryPriceTypeReducer);
  store.addReducer(propertyTypeReducer);
  store.addReducer(publishPeriodReducer);
  store.addReducer(rentTermsReducer);
  store.addReducer(rentTimeReducer);
  store.addReducer(repairReducer);
  store.addReducer(roomReducer);
  store.addReducer(roomsInDealReducer);
  store.addReducer(roomsTotalReducer);
  store.addReducer(saleTypeReducer);
  store.addReducer(saveDistrictsModalReducer);
  store.addReducer(saveMapModalReducer);
  store.addReducer(saveMetroModalReducer);
  store.addReducer(searchReducer);
  store.addReducer(showMotivationPopupReducer);
  store.addReducer(simplifiedOfferCardPopupsStateReducer);
  store.addReducer(sortReducer);
  store.addReducer(suburbanUtilitiesReducer);
  store.addReducer(suburbanWCReducer);
  store.addReducer(suggestionsDistancesReducer);
  store.addReducer(hideOfferReducer);
  store.addReducer(tenantsReducer);
  store.addReducer(toggleAdvancedModalReducer);
  store.addReducer(toggleDistrictsModalReducer);
  store.addReducer(toggleMapModalReducer);
  store.addReducer(toggleMetroModalReducer);
  store.addReducer(toggleOfficeCalculatorReducer);
  store.addReducer(undergroundTravelReducer);
  store.addReducer(userIdReducer);
  store.addReducer(warehouseBuildingClassTypeReducer);
  store.addReducer(wcReducer);
  store.addReducer(windowsTypeReducer);
  store.addReducer(withPhotoReducer);
  store.addReducer(tour3dReducer);
  store.addReducer(withNeighborsReducer);
  store.addReducer(withLayoutReducer);
  store.addReducer(wordsExcludeReducer);
  store.addReducer(wordsIncludeReducer);
  store.addReducer(pushSubscribedReducer);
  store.addReducer(complaintFeedbackReducer);
  store.addReducer(identicalOffersIdReducer);
  store.addReducer(coworkingOfferTypeReducer);
  store.addReducer(amenityReducer);
  store.addReducer(coworkingAccessReducer);
  store.addReducer(workPlaceCountReducer);
  store.addReducer(changeJsonQueryReducer);
  store.addReducer(favoritesAssignReducer);
  store.addReducer(coworkingIdReducer);
  store.addReducer(isByCommercialOwnerReducer);
  store.addReducer(readyBusinessReducer);
  store.addReducer(mortgageDataReducer);
  /**
   * @todo Удалить строку если эксперимент не заапрувится
   * @description Данный функционал появился в задаче CD-116486, будет удалён в задаче CD-117944
   */
  store.addReducer(toggleUserProfilingModalReducer);
  store.addReducer(offerComparisonStatusReducer);
  store.addReducer(comparisonNotificationReducer);
  store.addReducer(newbuildingInformationReducer);
  store.addReducer(tagsReducer);
  store.addReducer(commercialSaveSearchModalReducer);
  store.addReducer(iapRequestPopupReducer);
}
