import { IAppState, TActions } from '../../common/state';
import { jq } from '../../json_query';

import { OFFER_TYPE_SELECTED } from './offer_type';

export const IS_BY_COMMERCIAL_OWNER_ACTION_TYPE = 'filters/is_by_commercial_owner/IS_BY_COMMERCIAL_OWNER_SELECTED';

export interface IIsByCommercialOwnerAction {
  type: typeof IS_BY_COMMERCIAL_OWNER_ACTION_TYPE;
}

export const setIsByCommercialOwnerSelected = (): IIsByCommercialOwnerAction => ({
  type: IS_BY_COMMERCIAL_OWNER_ACTION_TYPE,
});

export const isByCommercialOwnerReducer = (state: IAppState, action: TActions) => {
  switch (action.type) {
    case IS_BY_COMMERCIAL_OWNER_ACTION_TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).setIsByCommercialOwner(),
        },
      };

    case OFFER_TYPE_SELECTED:
      return {
        ...state,
        filters: {
          ...state.filters,
          jsonQuery: jq(state.filters.jsonQuery).unsetIsByCommercialOwnerForSomeOfficeTypes(),
        },
      };
    default:
      return state;
  }
};
